import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { DivCenter } from "../../../GlobalStyles";

export function SkeletonLoader1() {
  return (
    <Stack spacing={1}>
      <Skeleton variant="text" width={210} sx={{ fontSize: "1rem" }} />
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={60} />
      <Skeleton variant="rounded" width={210} height={60} />
    </Stack>
  );
}

export function SkeletonLoader2() {
  return (
    <Box sx={{ width: 300 }}>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </Box>
  );
}

export function BatchTrackerSkeleton() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem", marginTop: "1rem" }}>
      <Skeleton variant="rounded" width={"100%"} height="100px" sx={{ borderRadius: "5px" }} animation="wave" />
      <Skeleton variant="rounded" width={"100%"} height="400px" sx={{ borderRadius: "5px" }} animation="wave" />
      <Box sx={{ display: "flex", gap: "2rem" }}>
        <Skeleton variant="rounded" width={"100%"} height="400px" sx={{ borderRadius: "5px" }} animation="wave" /> <Skeleton variant="rounded" width={"100%"} height="400px" sx={{ borderRadius: "5px" }} animation="wave" />
      </Box>
      <Skeleton variant="rounded" width={"100%"} height="200px" sx={{ borderRadius: "5px" }} animation="wave" />
    </Box>
  );
}

export function AllApplicantsSkeletonLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: 'center',
        // borderTop: "1px solid #E6E6E6",
        // borderBottom: "1px solid #E6E6E6",
      }}>
      {/* 1st */}
      <Box
        variant="rounded"
        width="30%"
        height="70px"
        sx={{ ...DivCenter, borderRadius: "5px", marginTop: "auto", marginBottom: "auto" }}>
        <Skeleton
          variant="rounded"
          width="80%"
          height="20px"
          sx={{ borderRadius: "5px", }}
          animation="wave"
        />{" "}
      </Box>
      {/* 2nd */}
      <Box
        variant="rounded"
        width="30%"
        height="70px"
        sx={{ ...DivCenter, borderRadius: "5px" }}>
        <Skeleton
          variant="rounded"
          width="80%"
          height="20px"
          sx={{ borderRadius: "5px", }}
          animation="wave"
        />{" "}
      </Box>
      {/* 3rd */}
      <Box
        variant="rounded"
        width="30%"
        height="70px"
        sx={{ ...DivCenter, borderRadius: "5px" }}>
        <Skeleton
          variant="rounded"
          width="80%"
          height="20px"
          sx={{ borderRadius: "5px", }}
          animation="wave"
        />{" "}
      </Box>
    </Box >
  );
}
