import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessRights : [],
  userDetails:{
    user_id:null
  },
  tooltipText:"User access denied"
};

export const actionSlice = createSlice({
  name: "rights",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userDetails = action.payload;
    },
    setChatPage: (state, action) => {
     
    },
    setCompanyDetails: (state, action) => {
    
    },

    updateActionRights: (state, action) =>{
      state.accessRights = action.payload;
    }
  },
});

export const { setUser, setChatPage, setCompanyDetails,updateActionRights } = actionSlice.actions;

export default actionSlice.reducer;
