import { Box, Button, IconButton, Input, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { DivCenter, Color } from "../../../../GlobalStyles";
import { AccountCircle, Apartment, DescriptionOutlined, EditOutlined, FileUpload } from "@mui/icons-material";
import ProfilePictureSelection from "../../../reusables/ImageCropperComponent/profilePictureSelection";
import "./LeftBar.css";
import ConfirmationAndValidationModal from "../../../reusables/ConfirmationAndValidationModal/ConfirmationAndValidationModal";
const LeftBar = ({ postedJob, page_stepper, setStepperChange, fetchedImage, job_id, setImageURL, basicInfoData, allCompanies }) => {
  
  

  const company = allCompanies?.find((item) => item.id === basicInfoData.company_id);

  console.log("LeftBar ~ page_stepper:", company);

  const [stepper, setStepper] = useState([
    { number: 1, title: "Basic Information", subtitle: "Fill in basic details of company and the role" },
    { number: 2, title: "Eligibility", subtitle: "Mention all eligibility parameters and ask by the company" },
    { number: 3, title: "Opportunity Info", subtitle: "Input detailed profile information like job description and CTC" },
  ]);
  const [picture, setPicture] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png");
  const [movePicture, setMovePicture] = useState();
  const [imageErrorModal, setImageErrorModal] = useState(false);

  //FUNCTIONS
  function triggerImagePicker() {
    // Use a browser API, library, or framework's image picker component
    const fileInput = document.getElementById("file-input");
    fileInput.click(); // Simple example for browser API
  }

  const getProfileImage = (image, image_url) => {
    setPicture(image);
    setImageURL(image_url);
  };

  const movePofilePicture = (move) => {
    // setPicture(image)
    // setLoader(false);
    setMovePicture(false);
  };

  const handleUploadProofFileChange = ({ event }) => {
    console.log("handleUploadProofFileChange ~ event:", event);
  };

  const handleStepperClick = (step_index) => {
    if (postedJob.po_job_id) {
      if (step_index <= postedJob.last_page + 1) setStepperChange((prev) => ({ ...prev, index: prev !== step_index ? step_index : prev }));
      //else dont change the page
    }
    //else dont change the page
  };

  return (
    <Box sx={{ ...DivCenter, width: "100%", height: "100vh", flexDirection: "column", alignItems: "space-between", borderRight: "1px solid black" }}>
      <Box id="box1__leftBar" sx={{ ...DivCenter, width: "100%", height: "33%", alignItems: "flex-end" }}>
        {/* Company Logo */}
       
        <Box className="box__CompanyLogo" sx={{ ...DivCenter }}>
          {[undefined, null].includes(fetchedImage) ? (
            <Box sx={{ width: "180px", height: "180px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", background:"#E8505B" }}>
                <Typography sx={{fontSize:"80px", color:"#fff", fontWeight:"600"}}>{basicInfoData.company_name[0].toUpperCase() || "C"}</Typography>
            </Box>
          ) : (
            <img src={fetchedImage} style={{ width: "180px", aspectRatio: "1/1", borderRadius: "50%" }} />
          )}
        </Box>
      </Box>

      {/* Page Changes */}
      {/* Basic Info */}
      <Box id="box2__leftBar" sx={{ ...DivCenter, alignItems: "flex-start", height: "33%", padding: "" }}>
        {/* number */}
        <Box id="box__numbers">
          {stepper.map((step, step_index) => (
            <Box key={step_index} sx={{ ...DivCenter, flexDirection: "column", justifyContent: "flex-start" }}>
              <Box
                className="box__circle_number"
                onClick={() => handleStepperClick(step_index)}
                sx={{
                  ...DivCenter,
                  flexDirection: "column",
                  cursor: "pointer",
                  color: page_stepper + 1 === step.number ? Color.primary1 : Color.neutralDarkGrey,
                  background: page_stepper + 1 === step.number ? "linear-gradient(0deg, rgba(56, 193, 223, 0.10) 0%, rgba(56, 193, 223, 0.10) 100%), #FFF" : Color.white,
                }}
              >
                {step.number}
              </Box>
              {step_index !== stepper.length - 1 && <div style={{ height: "57px", borderRight: `1px dotted ${Color.primary1}` }} />}
            </Box>
          ))}
        </Box>
        {/* typographies */}
        <Box id="box__typographies">
          {stepper.map((step, step_index) => (
            <Box key={step_index} sx={{ ...DivCenter, flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", padding: "15px", gap: "8px" }}>
              <Typography className="typography__stepperTitle" onClick={() => handleStepperClick(step_index)} sx={{ cursor: postedJob.po_job_id && step_index <= postedJob.last_page + 1 ? "pointer" : "default", fontWeight: 600, color: page_stepper + 1 === step.number ? Color.primary1 : Color.neutralBlack }}>
                {step.title}
              </Typography>
              <Typography className="typography__steppersubtitle">{step.subtitle}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box id="box3__leftBar" sx={{ ...DivCenter, height: "33%" }}></Box>
      <ConfirmationAndValidationModal open={imageErrorModal} onClose={() => setImageErrorModal(false)} header={"Image size exceeds 10 MB"} primaryButton={"Ok"} handleConfirmation={() => setImageErrorModal(false)} />
    </Box>
  );
};

export default LeftBar;
