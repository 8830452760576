import React from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { Color, PrimaryButtonOutlined } from "../../../GlobalStyles";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

export const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    // Styles for the switch base
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      // Styles applied when the switch is checked
      transform: "translateX(16px)", // Moves the thumb to the 'on' position
      color: Color.white, // Color for the 'on' position
      "& + .MuiSwitch-track": {
        // Styles for the track when the switch is checked
        backgroundColor: theme.palette.mode === "dark" ? Color.white : Color.green,
        border: `1px solid ${Color.white}`,
        borderColor: Color.white,
        opacity: 1,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        // Styles for the track when the switch is checked and disabled
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      // Styles for the thumb when switch is focused
      color: Color.white,
      border: `6px solid ${Color.white}`,
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      // Styles for the thumb when the switch is disabled
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      // Styles for the track when the switch is disabled
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    // Styles for the thumb
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    // Styles for the track
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ComingSoonComponent = (props) => {
  const { header, settingsTabs, tabIndex, onToggleSwitch, handleGeneralSettings , id} = props;
  const { accessRights , tooltipText} = useSelector((store) => store.rights)
  return (
    <div>
      <Box class="settingsHeader" style={{ borderBottom: `1px solid ${Color.neutralMidGrey}` }}>
        <Typography class="settingsHeadingsTypography" style={{ color: Color.primary1 }} variant="h1">
          {header}
        </Typography>
         <Tooltip title={accessRights.find((item) => item.access_right_id === id)?.is_activated===1 ?"":tooltipText}>
<Box>
<Button
          variant="outlined"
          sx={{
            textTransform: "none",
            borderColor: Color.primary1,
            color: Color.primary1,
            borderRadius: 0,
            boxShadow: "none",
            width: "90px",
            "&:hover": {
              borderColor: Color.primary1,
              color: Color.primary1,
            },
            "&:disabled": {
              background: Color.neutralMidGrey,
              color: '#000',
            },
          }}
          onClick={() => handleGeneralSettings()}
          disabled={accessRights.find((item) => item.access_right_id === id)?.is_activated===1 ? false:true}
        >
          Save
        </Button>
</Box>
         </Tooltip>
      </Box>

      {settingsTabs && settingsTabs.length > 0 ? (
        settingsTabs.map((item, index) => {
          const settingKey = Object.keys(item)[0];
          const settingValue = item[settingKey];
          return (
            <Box key={index} display="flex" alignItems="center" mt={2} justifyContent="space-between" width="90%">
              <Typography>{settingKey}</Typography>
               <Tooltip title={accessRights.find((item) => item.access_right_id === 6)?.is_activated===1 ? '':tooltipText}> 
                 <Box>
                 <IOSSwitch disabled={accessRights.find((item) => item.access_right_id === 6)?.is_activated===1 ? false:true} checked={settingValue} onChange={() => onToggleSwitch(tabIndex, index)} />
                 </Box>
               </Tooltip>
            </Box>
          );
        })
      ) : (
        <Typography className="italic_font">Coming soon</Typography>
      )}
    </div>
  );
};

export default ComingSoonComponent;
