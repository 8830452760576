import { Close, Send } from "@mui/icons-material";
import { Box, IconButton, Skeleton, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { Color } from "../../../GlobalStyles";
import { months } from "../../../ReusableModals/Calender";
import Service from "../../../services/httpService";

function StudentChatModal({ selectedStudent, userDetails, close, batch_id, setStudentData, setSelectedStudent }) {
  const services = new Service();
  const socketRef = useRef();
  const chatDivRef = useRef();
  const [textValue, setTextValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [loader, setLoader] = useState(false);
  const inputRef = useRef(null)
  const convertToTimeFormat = (time) => {
    const date = new Date(time);
    let day = String(date.getDate()).padStart(2, "0");
    let month = months[date.getMonth()]; // Months are zero-based

    let hours = String(date.getHours()).padStart(2, "0");
    let minutes = String(date.getMinutes()).padStart(2, "0");
    let AMPM = "AM";

    if (day === "01") {
      day = day + "st";
    } else if (day === "02") {
      day = day + "nd";
    } else if (day === "03") {
      day = day + "rd";
    } else day = day + "th";

    if (parseInt(hours) > 12) {
      hours = `${parseInt(hours) - 12}`;
      AMPM = "PM";
    } else if (parseInt(hours) === 12) {
      AMPM = "PM";
    } else if (parseInt(hours) === 0) {
      hours = 12;
    }

    return ` ${day} ${month}, ${hours}:${minutes}${AMPM}`;
  };

  const handleSendMesage = () => {
 
    if(textValue.trim() === ""){
      console.log("ddshfjhsdfj", textValue)
    }else{
      const body = {
        message: textValue,
        user_id: userDetails.user_id,
        is_admin: 1,
        id: selectedStudent.id,
      };
      socketRef.current.emit("poAssigneeSendMessage", body, (err, res) => {
        if (err) {
          console.log("animes", err);
        } else {
          setTextValue("");
        }
      });
    }
   
  };

  useEffect(() => {
    const handleEffect = async () => {
      setLoader(true);
      socketRef.current.connect();
      if (selectedStudent.id === null) {
        socketRef.current.emit("poAssigneeCreateRoom", { ug_id: batch_id, user_id: selectedStudent.user_id }, (err, res) => {
       
        });
      } else {
        socketRef.current.emit("poAssigneeFindRoom", { id: selectedStudent.id });
      }

      socketRef.current.on("poAssigneeFoundRoom", (message) => {
 
        setSelectedStudent((prev) => {
          return {
            ...prev,
            id: message.id,
          };
        });
        setStudentData((prev) => {
          return prev.map((i) => {
            if (i.user_id === selectedStudent.user_id) {
              return {
                ...i,
                id: message.id,
              };
            } else {
              return i;
            }
          });
        });

        setMessages(message.chat === null ? [] : JSON.parse(message.chat).messages);

        setLoader(false);
      });
    };

    socketRef.current = io(process.env.REACT_APP_SOCKET_URL, {
      autoConnect: false,
    });
    handleEffect();

    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
   
    socketRef.current.on("poAssigneeReceiveMessage", (chatMessages) => {
      
      setMessages(prev => [...prev , ...JSON.parse(chatMessages).messages]);
    });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatDivRef.current) {
      chatDivRef.current.scrollTo({
        top: chatDivRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };


  return (
    <Box sx={{ background: "#fff", borderRadius: "12px", height: "450px", width: "400px", display: "flex", flexDirection: "column" }}>
      <Box sx={{ padding: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography sx={{ fontSize: "24px" }}>
          {selectedStudent.first_name} {selectedStudent.last_name}
        </Typography>
        <IconButton sx={{ padding: "0px" }} onClick={() => close()}>
          <Close />
        </IconButton>
      </Box>

      <Box
        ref={chatDivRef}
        sx={{
          height: "100%",
          overflowY: "auto",
          padding: "5px 8px 8px 8px",
          background: Color.gainsboro_200,
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
            borderRadius: "15px",
            height: "30%",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: Color.redhex.concat(60),
            borderRadius: "15px",
            backgroundClip: "padding-box",
          },
          "&::-webkit-scrollbar": {
            width: "4px",
          },
        }}
      >
        {!loader ? (
          messages.length > 0 ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
             
              {messages.map((msg, index) =>
                msg.user_id ===  userDetails.user_id ? (
                  <Box sx={{ display: "flex", justifyContent: "flex-end"}}>
                    <Box sx={{ padding: "4px 8px", background: "#E8505B", width: "40%", borderRadius: "8px" }}>
                      {/* <Typography sx={{ fontSize: "9px", color: Color.gainsboro_200 }}>{msg.name}</Typography> */}
                      
                      <Typography>
                      <p style={{wordBreak:"break-word", overflowWrap:"break-word", color:"#fff", fontSize:"16px"}} dangerouslySetInnerHTML={{ __html: msg.message.replace(/\n/g, "<br />") }} />
                      </Typography>
                      
                      <Typography sx={{ color: Color.gainsboro_200, fontSize: "8px", textAlign: "right" }}>{convertToTimeFormat(msg.time)}</Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ padding: "4px 8px", background: Color.white, width: "40%", borderRadius: "8px" }}>
                      <Typography sx={{ fontSize: "9px", color: Color.neutralMidGrey }}>{msg.name}</Typography>
                      <Typography >
                      <p style={{wordBreak:"break-word", overflowWrap:"break-word", color:"#000", fontSize:"16px"}} dangerouslySetInnerHTML={{ __html: msg.message.replace(/\n/g, "<br />") }} />
                      </Typography>
                      <Typography sx={{ color: Color.neutralMidGrey, fontSize: "10px", textAlign: "right" }}>{convertToTimeFormat(msg.time)}</Typography>
                    </Box>
                  </Box>
                )
              )}
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "flex-end", height: "100%" }}>
              <Typography sx={{ background: "#fff", padding: "6px 16px", fontSize: "12px", borderRadius: "12px" }}>Start chat.</Typography>
            </Box>
          )
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {[1, 2, 3].map((item, i) =>
              i % 2 !== 0 ? (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <TextLoader />
                </Box>
              ) : (
                <Box sx={{ display: "flex" }}>
                  <TextLoader />
                </Box>
              )
            )}
          </Box>
        )}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", gap: "8px", alignItems: "flex-end", boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px", borderRadius: "12px", padding: "8px" }}>
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "100px",
            width: "100%",
            paddingRight: "5px",
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
              borderRadius: "15px",
              height: "30%",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: Color.redhex.concat(60),
              borderRadius: "15px",
              backgroundClip: "padding-box",
            },
            "&::-webkit-scrollbar": {
              width: "4px",
            },
          }}
        >
          <TextField multiline value={textValue}  onChange={(e) => {
           
           setTextValue(e.target.value)
             
          }} fullWidth sx={{ borderRadius: "12px" }} InputProps={{ style: { borderRadius: "20px", padding: "4px 8px" } }} onKeyDown={(e)=>{
            if(e.key === "Enter" && !e.shiftKey){
              handleSendMesage()
            }

          }} />
        </Box>
        <IconButton sx={{ background: Color.redhex.concat(30), color: Color.redhex, "&:disabled": { background: Color.neutralLightGrey, color: Color.neutralMidGrey } }} onClick={() => handleSendMesage()} disabled={textValue === "" ? true : false}>
          <Send sx={{ fontSize: "20px" }} />
        </IconButton>
      </Box>
    </Box>
  );
}

export default StudentChatModal;

const TextLoader = () => {
  return (
    <Box sx={{ background: Color.white, padding: "8px", width: "fit-content", borderRadius: "8px" }}>
      <Skeleton width={60} height={20} />
      <Skeleton width={100} height={40} />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Skeleton width={40} height={10} />
      </Box>
    </Box>
  );
};
