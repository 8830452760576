import { Color } from "../../../GlobalStyles";

export const student_status =
    [{
        "id": 8,
        frontend_status_name: "Unplaced",
        backend_status_name: "Unplaced",
        color: Color.accent,
        options: [
            {
                "id": 7,
                "name": "Mark as Placed"
            },
            {
                "id": 10,
                "name": "Opt-out"
            },
            {
                "id": 9,
                "name": "Add to Blacklist"
            },
        ],
    }, {
        "id": 7,
        frontend_status_name: "Offered",
        backend_status_name: "Placed",
        color: Color.green,
        options: [{
            "id": 8,
            "name": "Mark as Unplaced"
        }],
    }, {
        "id": 9,
        frontend_status_name: "Blacklisted",
        backend_status_name: "Blacklist",
        color: Color.neutralBlack,
        options: [{
            "id": 8,
            "name": "Remove from Blacklist"
        }],
    }, {
        "id": 10,
        frontend_status_name: "Opted out",
        backend_status_name: "Opt_out",
        color: Color.neutralMidGrey,
        options: [{
            "id": 8,
            "name": "Remove from Opt outs"
        }],
    }
    ]
