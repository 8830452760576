import React from "react";
import { Typography } from "@mui/material";
const ComingSoonAnalytics = () => {
    return (
        <div style={{ width: "100%", height:"100vh", margin: "auto", overflow:"hidden" }}>
            <div
                style={{
                    display: "Flex",
                    flexDirection: "column",
                    margin: "auto",
                    alignItems: "center",
                    marginTop: "15%",
                    overflowY: "hidden",
                }}
            >
                <img
                    src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/7079771_3324619+1.png"
                    style={{ width: "200px", height: "200px" }}
                />
                <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                    Coming Soon!
                </Typography>
                <Typography>
                    We're working behind the scenes to bring you a great report. Stay tuned!
                </Typography>
            </div>
        </div>
    );
};

export default ComingSoonAnalytics;
