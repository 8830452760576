import React, { useState, useEffect } from "react";
import { Box, Checkbox, Input, MenuItem, TextField, Typography, ToggleButton, ToggleButtonGroup, Button } from "@mui/material";
import { DivCenter, Color } from "../../../../GlobalStyles";
import "./POOpportunityInfo.css";
import { DeleteOutlined, DescriptionOutlined, EditOutlined, FileUpload, InfoOutlined, PaymentsOutlined } from "@mui/icons-material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SelectCurrency from "react-select-currency";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CCService from "../../../../services/httpServiceWithoutSuperAdmin";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import InputLabel from "@mui/material/InputLabel";
import { convertToIndianFormat } from "./currency";
const POOpportunityInfo = (props) => {
  const service = new CCService();
  const navigate = useNavigate();

  const { opportunityInfo, setOpportunityInfo, basicInfoData, formErrors } = props;
  const [companyLogoFile, setCompanyLogoFile] = useState([]);
  console.log("companyLogoFile:", opportunityInfo, companyLogoFile, formErrors);
  const [selected_placement_type, setSelected_placement_type] = useState(opportunityInfo.placement_type);
  const [selected_job_mode, setSelected_job_mode] = useState(opportunityInfo.job_mode);
  const [interval, setInterval] = useState([
    { interval_id: 1, interval_name: "Per Annum" },
    { interval_id: 12, interval_name: "Per Month" },
    { interval_id: 4, interval_name: "Per Quarter" },
    { interval_id: 2, interval_name: "half Yearly" },
  ]);
  const [sector, setSector] = useState([]);
  const [toBeAnnounced, setToBeAnnounced] = useState(false);
  const onChange = (ev) => {
    const { value } = ev.target;
    console.log("value is", value);
    setOpportunityInfo((prevData) => ({ ...prevData, currency: value }));
  };

  const handleImageUploadSubSection = async (selectedFileName) => {
    console.log("selectedFileName", selectedFileName[0]);
    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "multipart/form-data",
    };

    const blob_image = new Blob([selectedFileName[0]], {
      type: "application/pdf",
    });

    const image_data = new FormData();
    image_data.append("image$$" + `${selectedFileName[0].name}`, blob_image, `${selectedFileName[0].name}`);

    try {
      const imageRes = await axios.post(`${process.env.REACT_APP_API_URL}/blog/blog/upload`, image_data, { headers });

      if (imageRes && imageRes.data && imageRes.data.fileInfoArray && imageRes.data.fileInfoArray.length > 0) {
        if (imageRes.data.fileInfoArray[0].image_name !== "undefined") {
          const imageUrl = imageRes.data.fileInfoArray[0].url;
          console.log("Image uploaded:", imageUrl);
          return imageUrl;
        } else {
          console.error("Invalid image upload response: no URL");
          return null;
        }
      } else {
        console.error("Invalid response format for image upload.");
        return null;
      }
    } catch (err) {
      console.error("Error uploading image:", err);
      return null;
    }
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    console.log("files...", files);
    if (files) {
      try {
        const fileUrl = await handleImageUploadSubSection(files);
        console.log("fileUrl", fileUrl);
        setCompanyLogoFile((prevFiles) => [...prevFiles, fileUrl]);
        setOpportunityInfo((prevData) => ({
          ...prevData,
          other_docs: [...prevData.other_docs, { url: fileUrl }],
        }));
      } catch (error) {
        console.error("Error handling image upload:", error);
      }
    }
  };

  const handleFileRemove = (index) => {
    setCompanyLogoFile((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
    setOpportunityInfo((prevData) => {
      // Create a new array excluding the element at the specified index
      const newOtherDocs = prevData.other_docs.filter((_, i) => i !== index);

      return {
        ...prevData,
        other_docs: newOtherDocs,
      };
    });
  };

  const handleTabChangePlacementType = (e, value) => {
    setSelected_placement_type(value);
    setOpportunityInfo((prevData) => ({ ...prevData, placement_type: value }));
  };

  const handleTabChangeJobMode = (e, value) => {
    setSelected_job_mode(value);
    setOpportunityInfo((prevData) => ({ ...prevData, job_mode: value }));
  };

  const handleFormChange = ({ target }) => {
    const { name, value } = target;

    if (["min_ctc", "max_ctc"].includes(name)) {
      let temp = value.replaceAll(",", "");
      const numberPattern = new RegExp("^\\d+$");

      if (numberPattern.test(temp)) {
        setOpportunityInfo((prev) => ({ ...prev, [name]: parseFloat(temp) }));
      } else if (value === "") {
        setOpportunityInfo((prev) => ({ ...prev, [name]: 0 }));
      }
    } else {
      setOpportunityInfo((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleChangeCriteria = async (event) => {
    const { name, value } = event.target;
    console.log("name of sector", value, name, event);
    if (name === "sector") {
      const selectedValue = value;
      setOpportunityInfo((prevData) => ({ ...prevData, sector_id: selectedValue }));
    } else {
      const selectedValue = value;
      setOpportunityInfo((prevData) => ({ ...prevData, interval: selectedValue.interval_name, interval_per_year: selectedValue.interval_id }));
    }
  };

  useEffect(() => {
    const handleEffect = async () => {
      console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL);
      if (Cookies.get("token") === null || Cookies.get("token") === undefined) {
        navigate("/");
      }

      let sector_getter = await sectorDataGetter();
      setSector(sector_getter);
    };
    handleEffect();
  }, [selected_placement_type]);

  const sectorDataGetter = async () => {
    try {
      const res = await service.get(`/po/ca/sector?section_type_id=${selected_placement_type === "Finals" ? 8 : 7}`);
      console.log("posting opportunity data response:", res.eligibilityCriterias);
      return res.sectors;
    } catch (err) {
      console.log(err);
    }
  };

  const handleIsUnpaid = (e) => {
    const temp_checked = e.target.checked;
    console.log("temp unpaid checked", temp_checked);
    setOpportunityInfo((prevData) => ({ ...prevData, is_unpaid: temp_checked === true ? 1 : 0 }));
    setOpportunityInfo((prevData) => ({ ...prevData, min_ctc: "", max_ctc: "", currency: "", interval: "" }));
    setToBeAnnounced(false);
  };

  const handleCheckToBeAnnounced = (e) => {
    const temp_checked = e.target.checked;
    console.log("to be announced true", temp_checked);
    setToBeAnnounced(temp_checked);
    setOpportunityInfo((prevData) => ({ ...prevData, is_unpaid: e.target.checked ? 2 : 0, min_ctc: "", max_ctc: "", currency: "", interval: "" }));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", gap: "20px", overflowY: "auto", overflowX: "hidden", padding: "66px 79px" }}>
      <TextField sx={{ background: Color.neutralLightGrey }} disabled className="textfield__opInfo" name="profile_designation" label="Profile (Designation)" required value={basicInfoData.designation}></TextField>

      <hr style={{ width: "100%", margin: "49px 0 49px 0" }} />

      <Box sx={{ width: "100%", mb: "16px" }}>
        <Typography>
          Placement Type<span style={{ color: "red" }}>*</span>
        </Typography>
      </Box>
      <ToggleButtonGroup color="primary" value={selected_placement_type} exclusive onChange={(e, value) => handleTabChangePlacementType(e, value)}>
        <ToggleButton
          value="Finals"
          sx={{
            width: "100%",
            borderRadius: "20px 0px 0px 20px",
            textTransform: "none",
          }}
        >
          {" "}
          <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}>
            {selected_placement_type === "Finals"} <Typography>Finals</Typography>
          </Box>
        </ToggleButton>

        <ToggleButton
          value="Summers"
          sx={{
            width: "100%",
            borderRadius: "0px 20px 20px 0px",
            textTransform: "none",
          }}
        >
          <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}>
            {selected_placement_type === "Summers"} <Typography>Summers</Typography>
          </Box>
        </ToggleButton>
      </ToggleButtonGroup>
      <Typography sx={{ fontSize: "12px", color: Color.red }}>{formErrors.placement_type ? "Placement Type should be selected" : ""}</Typography>
      <Box sx={{ width: "100%", mb: "16px" }}>
        <Typography>Job Mode</Typography>
      </Box>
      <ToggleButtonGroup color="primary" value={selected_job_mode} exclusive onChange={(e, value) => handleTabChangeJobMode(e, value)}>
        <ToggleButton
          value="Work from Office"
          sx={{
            width: "100%",
            borderRadius: "20px 0px 0px 20px",
            textTransform: "none",
          }}
        >
          {" "}
          <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}>
            {selected_job_mode === "Work from Office"} <Typography>Work from Office</Typography>
          </Box>
        </ToggleButton>

        <ToggleButton value="Work from Home" sx={{ width: "100%", textTransform: "none" }}>
          <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}>
            {selected_job_mode === "Work from Home"} <Typography>Work from Home</Typography>
          </Box>
        </ToggleButton>
        <ToggleButton
          value="Hybrid"
          sx={{
            width: "100%",
            borderRadius: "0px 20px 20px 0px",
            textTransform: "none",
          }}
        >
          {" "}
          <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}>
            {selected_job_mode === "Hybrid"} <Typography>Hybrid</Typography>
          </Box>
        </ToggleButton>
      </ToggleButtonGroup>
      <Box sx={{ width: "100%", mb: "16px" }}>
        <Typography>Sector</Typography>
      </Box>
      <FormControl fullWidth sx={{ height: "35px" }}>
        <InputLabel id="demo-simple-select-label">Sector</InputLabel>
        <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Sector" onChange={handleChangeCriteria} value={opportunityInfo.status_id} name="sector" sx={{ "& label": { color: "black" } }}>
          {sector.map((sectorItem, index) => (
            <MenuItem key={index} value={sectorItem.id}>
              {sectorItem.sector_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <hr style={{ width: "100%", margin: "49px 0 49px 0" }} />
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box sx={{ ...DivCenter, justifyContent: "space-between" }}>
          <Typography>
            Job Description<span style={{ color: "red" }}>*</span>
          </Typography>
          <Box sx={{ ...DivCenter, gap: "8px" }}>
            {/* <Checkbox />
                        Is this a dream job? */}
            <InfoOutlined />
          </Box>
        </Box>
        <Box sx={{ width: "100%", height: "100%" }}>
          <ReactQuill
            theme="snow"
            style={{ marginBottom: "20px", height: "100px" }}
            value={opportunityInfo.job_description}
            onChange={(value) => setOpportunityInfo((prevData) => ({ ...prevData, job_description: value }))}
            error={formErrors.job_description}
            modules={{
              clipboard: {
                matchVisual: false,
              },
            }}
          />
        </Box>
        <Typography sx={{ fontSize: "12px", color: Color.red, marginTop: ".5rem" }}>{formErrors.job_description ? "Job Description cannot be empty" : ""}</Typography>
      </Box>

      <hr style={{ width: "100%", margin: "49px 0 49px 0" }} />

      <Box sx={{ width: "100%", height: "100%" }}>
        <Box sx={{ ...DivCenter, width: "100%", justifyContent: "space-between" }}>
          <Typography>
            Cost to Company (CTC)<span style={{ color: "red" }}>*</span>
          </Typography>
          <Box sx={{ ...DivCenter, gap: "8px" }}>
            <Checkbox checked={opportunityInfo.is_unpaid === 2} onChange={(e) => handleCheckToBeAnnounced(e)} />
            To be announced
            <InfoOutlined />
            <Checkbox checked={opportunityInfo.is_unpaid === 0 ? false : opportunityInfo.is_unpaid === 1 ? true : false} onChange={(e) => handleIsUnpaid(e)} />
            Unpaid
            <InfoOutlined />
          </Box>
        </Box>
        <Box sx={{ ...DivCenter, flexDirection: "column", width: "100%", height: "100%", gap: "24px" }}>
          <Box sx={{ ...DivCenter, width: "100%", gap: "24px" }}>
            <Box sx={{ width: "100%" }}>
              <Typography>
                Fixed Component<span style={{ color: "red" }}>*</span>
              </Typography>

              <TextField disabled={opportunityInfo.is_unpaid === 1 || toBeAnnounced ? true : false} style={{ background: opportunityInfo.is_unpaid === 1 || toBeAnnounced ? Color.neutralLightGrey : "transparent" }} sx={{ width: "100%" }} name="min_ctc" value={new Intl.NumberFormat("en-IN").format(opportunityInfo.min_ctc)} onChange={(e) => handleFormChange(e)} InputProps={{ startAdornment: <Box sx={{ paddingRight: "12px", marginRight: "8px", borderRight: "1px solid #8B8B8B" }}>₹</Box> }}></TextField>
              <Typography sx={{ fontSize: "12px", color: Color.red }}>{formErrors.min_ctc ? "Min CTC cannot be empty" : ""}</Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography>Variable Component</Typography>
              <TextField disabled={opportunityInfo.is_unpaid === 1 || toBeAnnounced ? true : false} style={{ background: opportunityInfo.is_unpaid === 1 || toBeAnnounced ? Color.neutralLightGrey : "transparent" }} sx={{ width: "100%" }} name="max_ctc" value={new Intl.NumberFormat("en-IN").format(opportunityInfo.max_ctc)} onChange={(e) => handleFormChange(e)} InputProps={{ startAdornment: <Box sx={{ paddingRight: "12px", marginRight: "8px", borderRight: "1px solid #8B8B8B" }}>₹</Box> }}></TextField>
            </Box>
          </Box>
          <Box sx={{ ...DivCenter, width: "100%", gap: "24px" }}>
            <Box sx={{ width: "100%", gap: "24px" }}>
              <Typography>
                Currency<span style={{ color: "red" }}>*</span>
              </Typography>
              <form onSubmit={(e) => e.preventDefault()}>
                <SelectCurrency disabled={true} id="select-currency" name="currency" value={"INR"} onChange={onChange} style={{ height: "55px", width: "100%", background: opportunityInfo.is_unpaid === 1 || toBeAnnounced ? Color.neutralLightGrey : "transparent" }} />
              </form>
              <Typography sx={{ fontSize: "12px", color: Color.red }}>{formErrors.currency ? "Currency should be selected" : ""}</Typography>
            </Box>
            <Box sx={{ width: "100%", gap: "24px" }}>
              <Typography>Interval</Typography>
              {console.log("ANIMESH!@##", opportunityInfo)}
              <FormControl fullWidth sx={{}} disabled={opportunityInfo.is_unpaid === 1 || toBeAnnounced ? true : false} style={{ background: opportunityInfo.is_unpaid === 1 || toBeAnnounced ? Color.neutralLightGrey : "transparent" }}>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={handleChangeCriteria} value={interval.find((item) => item.interval_id === opportunityInfo.interval_per_year)}>
                  {interval.map((IntervalItem, index) => (
                    <MenuItem key={IntervalItem.interval_id} value={IntervalItem}>
                      {IntervalItem.interval_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography sx={{ fontSize: "12px", color: Color.red }}>{formErrors.interval ? "Interval should be selected" : ""}</Typography>
            </Box>
          </Box>
          {console.log("ANIMESHH", opportunityInfo)}
          {![null, ""].includes(opportunityInfo.min_ctc) && (
            <Box sx={{ display: "flex", alignItems: "center", width: "100%", gap: "8px" }}>
              <PaymentsOutlined />
              <Typography>
                {/* ₹{convertToIndianFormat(opportunityInfo.min_ctc)} {opportunityInfo.max_ctc ? ` - ₹${convertToIndianFormat(opportunityInfo.max_ctc + opportunityInfo.min_ctc)}` : ""} {opportunityInfo.interval} */}
                {`₹${convertToIndianFormat(opportunityInfo.max_ctc + opportunityInfo.min_ctc)}`} {opportunityInfo.interval}
              </Typography>
            </Box>
          )}
          <Box sx={{ width: "100%", gap: "24px" }}>
            <Typography>Other incentives and benefits</Typography>
            <TextField multiline rows={5} name="other_incentive" placeholder="Mention all other instructions regarding CTC you want to convey to the student" sx={{ width: "100%" }} value={opportunityInfo.other_incentive} onChange={(e) => handleFormChange(e)}></TextField>
          </Box>
        </Box>
      </Box>

      <hr style={{ width: "100%", margin: "49px 0 49px 0" }} />

      <Box sx={{ ...DivCenter, flexDirection: "column", width: "100%", height: "100%", gap: "24px" }}>
        <Box sx={{ ...DivCenter, width: "100%", justifyContent: "space-between" }}>
          <Typography>Attach Documents</Typography>
          <Box sx={{ ...DivCenter, gap: "8px" }}>
            <Box sx={{ ...DivCenter }}>
              <input type="file" accept=".pdf" multiple onChange={handleFileChange} style={{ display: "none" }} id="fileInput" />
              <Button
                sx={{ textTransform: "none", color: Color.primary1 }}
                onClick={() => {
                  document.getElementById("fileInput").click();
                }}
              >
                + Add New File
              </Button>
            </Box>
          </Box>
        </Box>
        {opportunityInfo?.other_docs.length ? (
          opportunityInfo?.other_docs.map((file, index) => (
            <Box className="box__fileUpload">
              <Typography sx={{ ml: "5px", textTransform: "none", color: Color.neutralBlack, fontSize: "14px" }}>
                {index + 1}. {file.url && file.url.includes("undefined/") ? file.url.split("undefined/")[1]?.split("/")[0] : file.url}
              </Typography>
              <DeleteOutlined onClick={() => handleFileRemove(index)} sx={{ cursor: "pointer" }} />
            </Box>
          ))
        ) : (
          <>
            <Typography sx={{ color: Color.neutralMidGrey }}>You have not attached any documents yet</Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default POOpportunityInfo;
