import { Box, Button, Input, Tooltip, Typography, Modal, TextField, IconButton } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { DivCenter, Color, PrimaryButton, PrimaryButtonOutlined } from "../../../../GlobalStyles";
import { Close, KeyboardArrowDown } from "@mui/icons-material";
import "../LeftBar/LeftBar.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Cookies from "js-cookie";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CCService from "../../../../services/httpServiceWithoutSuperAdmin";
import { SubComponentsCompanyInfo } from "./SubComponents.js/SubComponents";
import { SubComponentsOpportunityOutline } from "./SubComponents.js/SubComponents";
import { SubComponentsJobDescription } from "./SubComponents.js/SubComponents";
import { SubComponentsSkillsRequired } from "./SubComponents.js/SubComponents";
import ApplicationDeadlineModal from "../ApplicationDeadlineModal/ApplicationDeadlineModal";
import { SubComponentsAdditionalInformation } from "./SubComponents.js/SubComponents";
import Loader, { ActivityLoader } from "../../../reusables/Loader/Loader";
import { SubComponentsCostToCompany } from "./SubComponents.js/SubComponents";
import { SubComponentsOtherDocuments } from "./SubComponents.js/SubComponents";
import CloseApplicationModal from "../../CloseApplicationModal/CloseApplicationModal";
import { SubComponentsEligibilityCriteria } from "./SubComponents.js/SubComponents";
import { get_specialCharReplace } from "../../../../services/specialCharReplace";
import ForumIcon from "@mui/icons-material/Forum";
import GroupChatModal from "../../GroupChatModal/GroupChatModal";
import { ddmmm, convertEpochToNormalTime } from "../../../../services/specialCharReplace";
import LinearProgress from "@mui/material/LinearProgress";
import NullOrVoidModal from "./NullOrVoidModal";
import { scrollToTargetId } from "../../../reusables/ScrollToTargetId/ScrollToTargetId";
import { useSelector } from "react-redux";
import { FaLastfmSquare } from "react-icons/fa";
import SideNavigationBar from "../../../reusables/SideNavigationBar/SideNavigationBar";
const POReview = (props) => {
  const navigate = useNavigate();
  const service = new CCService();
  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  const { accessRights, tooltipText } = useSelector((store) => store.rights);
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const [loading, setLoading] = useState({ page_loader: false, activity_loader: false });
  const [searchParams, setSearchParams] = useSearchParams();
  const [closeApplications, setCloseApplications] = useState(false);
  const [nullOrVoid, setNullOrVoid] = useState(false);
  const batch_id = location.state?.batch_id ? location.state?.batch_id : searchParam.get("batch_id");
  const job_id = ![null, "null", undefined, "undefined"].includes(searchParams.get("job_id")) ? searchParams.get("job_id") : location.state.job_id;
  console.log("job id", job_id, batch_id);
  const [flags, setFlags] = useState({ downloadResumeDropDown: false });
  const [groupChat, setGroupChat] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  //confiramtion
  const [publishConfirmationModal, setPublishConfirmationModal] = useState({
    open: false,
    onClose: () => setPublishConfirmationModal((prev) => ({ ...prev, open: false })),
    extraData: {
      value: "",
      time: "",
    },
  });
  const [publishLoading, setPublishLoading] = useState({ activity_loader: false });
  console.log("POReview ~ publishConfirmationModal:", publishConfirmationModal);
  const [showBox, setShowBox] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [allCompanies, setAllCompanies] = useState([]);

  const handleMoreOptionsClick = () => {
    setShowBox(true);
  };
  const [latestShortlistStudentCount, setLatestShortlistStudentCount] = useState(null);
  const [cancelSchedulePublishModal, setCancelSchedulePublishModal] = useState(false);
  const [review, setReview] = useState({
    ug_id: null,
    po_job_id: null,
    status_id: null,
    status_name: "",
    designation: "",
    company_id: null,
    company_location: [],
    date_of_visit: null,
    skills: {
      skills: [""],
    },
    eligibility_criteria: [],
    eligibility_description: "",
    job_mode: "",
    placement_type: "",
    sector_id: null,
    sector_name: "",
    dream_role_criteria: null,
    is_unpaid: 0,
    job_description: "",
    currency: "INR",
    min_ctc: null,
    max_ctc: null,
    interval: "",
    other_incentive: "",
    other_docs: null,
    schedulerTime: null,
    application_deadline: null,
    notification_gorup_id: null,
    image_url: null,
    last_page: "",
  });
  const [applicationDeadlineModal, setApplicationDeadlineModal] = useState(false);
  const [openOrExtendText, setOpenOrExtendText] = useState("");
  const [stepper, setStepper] = useState([
    { compId: "CompanyInfo", title: "Company Info", isEdit: false, selected: true },
    { compId: "OpportunityOutline", title: "Opportunity Outline", isEdit: false, selected: false },
    { compId: "JobDescription", title: "Job Description", isEdit: false, selected: false },
    { compId: "SkillsRequired", title: "Skills", isEdit: false, selected: false },
    { compId: "EligibilityCriteria", title: "Eligibility Criteria", isEdit: false, selected: false },
    { compId: "AdditionalInformation", title: "Cost to Company", isEdit: false, selected: false },
    { compId: "CostToCompany", title: "Documents", isEdit: false, selected: false },
    { compId: "OtherDocuments", title: "Additional Information", isEdit: false, selected: false },
  ]);

  const [refresh, setRefresh] = useState(false);

  const [status, setStatus] = useState([
    { title: "Incomplete", color: Color.red },
    { title: "Review", color: Color.accent },
    { title: "Accepting Applications", color: Color.primary1 },
    { title: "Extended Applications", color: Color.primary1 },
    { title: "Closed Applications", color: Color.neutralDarkGrey },
    { title: "In process", color: Color.accent },
    { title: "Completed", color: Color.green },
  ]);

  const componentRefs = {
    CompanyInfo: useRef(null),
    OpportunityOutline: useRef(null),
    JobDescription: useRef(null),
    SkillsRequired: useRef(null),
    EligibilityCriteria: useRef(null),
    AdditionalInformation: useRef(null),
    CostToCompany: useRef(null),
    OtherDocuments: useRef(null),
  };
  const handleEffect = async () => {
    setLoading((prev) => ({ ...prev, page_loader: true }));
    console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL);
    if (Cookies.get("token") === null || Cookies.get("token") === undefined) {
      navigate("/");
    }

    let opportunity_review_getter = await OpportunityReviewDataGetter();
    opportunity_review_getter = {
      ...opportunity_review_getter,
      min_ctc: opportunity_review_getter.min_ctc / opportunity_review_getter.interval_per_year,
      max_ctc: opportunity_review_getter.max_ctc / opportunity_review_getter.interval_per_year,
    };
    const companies = await service.get(`/po/ca/applications/company`);

    setAllCompanies(companies.data);
    const applicantCount = await service.get(`/po/ca/applicantCount?po_job_id=${job_id}`);

    setLatestShortlistStudentCount(applicantCount.count);
    setReview(opportunity_review_getter);
    setWhereYouStand({ applied: opportunity_review_getter?.applied_count });
    console.log("the entire josn i got", opportunity_review_getter);
    setLoading((prev) => ({ ...prev, page_loader: false }));
  };
  useEffect(() => {
    if (applicationDeadlineModal === false) {
      handleEffect();
    }
    // handleEffect();
  }, [applicationDeadlineModal]);

  useEffect(() => {
    if (refresh) {
      handleEffect();
      setRefresh(false);
    }
    // handleEffect();
  }, [refresh]);

  const OpportunityReviewDataGetter = async () => {
    try {
      const res = await service.get(`/po/ca/applications?po_job_id=${job_id}`);
      console.log("getting opportunity data response:", res.applications[0]);
      return res.applications[0];
    } catch (err) {
      console.log(err);
    }
  };

  const handleSideNavClick = (index) => {
    console.log("index provided", index);
    // Update stepper state with selected step
    const updatedStepper = stepper.map((step, step_index) => ({
      ...step,
      selected: step_index === index,
    }));
    console.log("updatedStepper", updatedStepper);
    setStepper(updatedStepper);
    console.log("componentRefs", componentRefs);
    const selectedComponentRef = index === 0 ? null : Object.values(componentRefs)[index - 1];
    console.log("handleSideNavClick ~ selectedComponentRef:", selectedComponentRef, stepper[index].compId);

    scrollToTargetId(stepper[index].compId);
    // if (selectedComponentRef && selectedComponentRef.current) {
    //     selectedComponentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // } else if (index === 0) {
    //     window.scrollTo({ top: 0, behavior: 'smooth' });
    // } else {
    //     console.warn(`Selected component ref at index ${index} is either null or does not have a current value.`);
    // }
  };

  const handleApplicationModalOpen = (applicationStatus) => {
    setApplicationDeadlineModal(true);
    setOpenOrExtendText(applicationStatus);
  };

  const handleApplicationModalClose = () => {
    setApplicationDeadlineModal(false);
    if (openOrExtendText === "Extend Applications") {
      // window.location.reload()
    }
  };

  const handleCloseApplicationsModalOpen = () => {
    setCloseApplications(true);
  };

  const handleCloseApplicationsModalClose = () => {
    setCloseApplications(false);
  };

  const handleNullOrVoidModalOpen = () => {
    setNullOrVoid(true);
  };

  const handleNullOrVoidModalClose = () => {
    setNullOrVoid(false);
  };

  const handleNullOrVoid = async () => {
    try {
      await service.delete(`/po/ca/applications?po_job_id=${review.po_job_id}`).then((res) => {
        console.log("response", res);
        handleNullOrVoidModalClose();
      });
      navigate(`/JobsPostingPage?batch_id=${batch_id}&job_id=${review.po_job_id}`, { state: { batch_id: batch_id } });
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseApplications = async () => {
    try {
      await service
        .put(`/po/ca/jobStatusUpdate`, {
          po_job_id: review.po_job_id,
          job_status_id: 5,
        })
        .then((res) => {
          console.log("response", res);
          handleCloseApplicationsModalClose();
        });
      navigate(`/JobsPostingPage?batch_id=${batch_id}&job_id=${review.po_job_id}`, { state: { batch_id: batch_id } });
    } catch (err) {
      console.log(err);
    }
  };

  const handleAllApplicants = () => {
    navigate(`/AllApplicants?batch_id=${batch_id}&job_id=${review.po_job_id}`, { state: { review: review, batch_id: batch_id } });
  };

  // handle open and close of chat modal
  const handleGroupChatOpenInReview = () => {
    setGroupChat(true);
  };

  const handleGroupChatCloseInReview = () => {
    setGroupChat(false);
  };

  function PublishConfirmationModal({ open, onClose }) {
    const modalBoxStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "white",
      padding: "2rem",
      borderRadius: "40px",
      width: "35%",
    };

    //FUNCTIONS
    const handleConfirmation = async (value, time) => {
      console.log("handleConfirmation ~ value, time:", value, time);
      const date = new Date(`${value["$d"].getFullYear()}-${value["$d"].getUTCMonth() + 1}-${value["$d"].getDate()} ${time}`);
      const epochTime = date.getTime();
      setLoading((prev) => ({ ...prev, activity_loader: true }));
      try {
        const res = await service.get(`/po/ca/publish?po_job_id=${job_id}&application_deadline=${epochTime}`);
        console.log("verify", res);
        onClose();
        setLoading((prev) => ({ ...prev, activity_loader: false }));
        navigate(`/JobsPostingPage?batch_id=${batch_id}&job_id=${review.po_job_id}`, { state: { batch_id: batch_id } });
        return res;
      } catch (err) {
        setLoading((prev) => ({ ...prev, activity_loader: false }));
        console.log(err);
      }
    };

    return (
      <Modal open={open} onClose={onClose}>
        <Box sx={{ ...modalBoxStyle }}>
          <Box sx={{ ...DivCenter, width: "100%", alignItems: "space-between", justifyContent: "space-between", marginBottom: "8px" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Publish Job
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "normal",
              marginBottom: "8px",
            }}
          >
            Are you sure you want to publish this job?
          </Typography>
          <Box
            sx={{
              ...DivCenter,
              alignItems: "flex-start",
              justifyContent: "flex-end",
              flexDirection: "row",
              width: "100%",
              mt: "16px",
            }}
          >
            <Box sx={{ ...DivCenter, width: "100%", gap: "16px" }}>
              <PrimaryButtonOutlined
                variant="outlined"
                onClick={() => onClose()}
                sx={{
                  textTransform: "none",
                  borderColor: Color.neutralMidGrey,
                  color: Color.neutralMidGrey,
                  borderRadius: 0,
                  boxShadow: "none",
                  width: "90px",
                  "&:hover": {
                    borderColor: Color.neutralMidGrey,
                    color: Color.neutralMidGrey,
                  },
                }}
              >
                Cancel
              </PrimaryButtonOutlined>
              <PrimaryButton
                variant="outlined"
                onClick={() => handleConfirmation(publishConfirmationModal.extraData.value, publishConfirmationModal.extraData.time)}
                sx={{
                  textTransform: "none",
                  borderColor: Color.primary1,
                  background: Color.primary1,
                  color: Color.white,
                  borderRadius: 0,
                  boxShadow: "none",
                  width: "90px",
                  "&:hover": {
                    background: Color.primary1,
                    borderColor: Color.primary1,
                    color: Color.white,
                  },
                }}
              >
                Yes
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  }

  // hours timer
  const FeedbackRemainingHoursTimer = ({ startTime }) => {
    const [timerEnded, setTimerEnded] = useState(false);

    const calculateTimeRemaining = () => {
      const currentTimeUTC = new Date().getTime();
      const timeRemainingMilliseconds = review?.application_deadline - currentTimeUTC;
      const remainingHours = Math.max(0, Math.floor(timeRemainingMilliseconds / (60 * 60 * 1000)));
      return remainingHours;
    };

    useEffect(() => {
      const timer = setInterval(() => {
        const remainingTime = calculateTimeRemaining();
        setTimeRemaining(remainingTime);
        if (remainingTime <= 0) {
          setTimerEnded(true);
          clearInterval(timer);
        }
      }, 1000);
      return () => clearInterval(timer); // Cleanup on component unmount or re-render
    }, [startTime]); // Include startTime in the dependency array

    const formatTime = (time) => {
      return time === 1 ? `${time} hour` : `${time} hours`;
    };

    return <div>{timerEnded ? <p style={{ color: Color.primary1 }}>00:00</p> : <p style={{ color: Color.primary1 }}>{formatTime(timeRemaining)}</p>}</div>;
  };

  const handleCancelJOb = async () => {
    try {
      const requestCancel = await service.get(`/po/ca/cancelSchedulePublish?po_job_id=${job_id}`);
      setRefresh(true);
      setCancelSchedulePublishModal(false);
    } catch (error) {
      window.alert("error");
    }
  };

  const [whereYouStand, setWhereYouStand] = useState({
    applied: 0,
  });
  function pad(number) {
    return number < 10 ? "0" + number : number;
  }
  return (
    <>
      {loading.page_loader ? (
        <Loader />
      ) : (
        <div style={{ ...DivCenter, flexDirection: "column" }}>
          <ActivityLoader show={loading.activity_loader} />
          {/* Upper Part */}
          <Box sx={{ display: "none" }}>
            <SideNavigationBar />
          </Box>
          <Box
            sx={{
              ...DivCenter,
              flexDirection: "column",
              width: "100%",
              gap: "1.2rem",
              paddingTop: "1rem",
              height: "30%",
              position: "fixed",
              top: review.status_name !== "Review" ? 0 : 0,
              background: "#fff",
              zIndex: "1000",
            }}
          >
            <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
              <Box sx={{ width: "5%" }} />
              <Box id="box1__leftBar" sx={{ display: "flex", justifyContent: "space-between", width: "90%", background: "white", height: "auto" }}>
                <Box sx={{ display: "flex", background: "white", alignItems: "flex-start" }}>
                  {review.image_url ? (
                    <img src={review.image_url} alt={review.image_url} style={{ width: "100px", height: "100px", borderRadius: "100%", border: "1px solid #fff", objectFit: "cover", marginRight: "16px", boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.4)" }} />
                  ) : (
                    <Box sx={{ width: "100px", height: "100px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", background: "#E8505B", marginRight: "16px", boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.4)" }}>
                      {console.log("dlkfkldsf", review)}
                      <Typography sx={{ fontSize: "56px", color: "#fff", fontWeight: "600" }}>{![null, undefined].includes(review.company_name) ? review.company_name[0]?.toUpperCase() : "C"}</Typography>
                    </Box>
                  )}
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                    <Typography class="typographyOfHeader" style={{ marginBottom: "0px", fontSize: "32px", fontWeight: "400px" }}>
                      {review.designation}
                    </Typography>
                    <Typography style={{ color: Color.neutralMidGrey }}>
                      {get_specialCharReplace(review.company_name)} - {review.company_location?.join(", ")}
                    </Typography>
                    <Typography style={{ color: Color.neutralMidGrey }}>{review.placement_type}</Typography>
                    <Box sx={{ width: "auto" }}>
                      {status.map((statusItem, index) => {
                        if (statusItem.title === review.status_name) {
                          return (
                            <button className="submitBtn" type="button" style={{ borderRadius: "1px", height: "26px", width: "auto", paddingLeft: "1rem", paddingRight: "1rem", textAlign: "center", border: `1px solid ${statusItem.color}`, background: "transparent", color: statusItem.color, cursor: "default", fontSize: "12px", textTransform: "uppercase" }} key={index}>
                              {review.schedulerTime !== null && review.status_name === "Review" ? "Scheduled" : statusItem.title}
                            </button>
                          );
                        }
                        return null;
                      })}
                    </Box>
                  </Box>
                </Box>
                <Box style={{ alignItems: "flex-end" }}>
                  {review.status_name !== "Review" ? (
                    <Box sx={{ ...DivCenter, justifyContent: "space-between", flexDirection: "column", height: "auto", alignItems: "flex-end", marginTop: "0" }}>
                      <Box style={{ marginBottom: "1rem" }}>
                        <ForumIcon onClick={() => handleGroupChatOpenInReview()} style={{ color: Color.primary1, height: "30px", width: "30px", margin: "auto 0", marginRight: "1rem", cursor: "pointer" }} />
                        <PrimaryButton type="contained" className="Rephrasebutton" sx={{ width: "300px", height: "44px", alignItems: "right", textTransform: "none", color: "#fff", fontSize: "16px" }} onClick={() => handleAllApplicants()}>
                          {review?.applied_count} Applicants
                        </PrimaryButton>
                      </Box>
                      <Box sx={{ flexGrow: 1, position: "relative", marginBottom: "1rem" }}>
                        <LinearProgress
                          variant="determinate"
                          value={whereYouStand?.applied ? whereYouStand?.applied : 0}
                          sx={{
                            height: 18,
                            width: 300,
                            borderRadius: "8px",
                            "&.MuiLinearProgress-colorPrimary": {
                              backgroundColor: Color.neutralLightGrey,
                            },
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: Color.primary2,
                            },
                          }}
                        />
                        <Typography style={{ marginTop: "0rem", color: Color.neutralMidGrey, textAlign: "right", fontSize: "14px" }}>
                          {latestShortlistStudentCount?.all_applicant[0].all_applicants} applied out of {latestShortlistStudentCount?.eligible_not_applied[0].eligible_not_applied} eligible students
                        </Typography>
                      </Box>
                      <Box>
                        <Box sx={{ ...DivCenter, height: "44px", width: "100%" }}>
                          <Tooltip title={accessRights.find((item) => item.access_right_id === 12)?.is_activated === 1 ? "" : tooltipText}>
                            <Box sx={{ padding: "0px" }}>
                              <Button onClick={() => handleApplicationModalOpen("Extend Applications")} sx={{ ...DivCenter, cursor: "pointer", padding: "8px 8px 8px 8px", border: `1px solid ${Color.primary1}`, height: "100%", width: "200px", color: "#000", textTransform: "none" }} disabled={accessRights.find((item) => item.access_right_id === 12)?.is_activated === 1 ? false : true}>
                                Extend Deadline
                              </Button>
                            </Box>
                          </Tooltip>
                          <Button onClick={() => setFlags((prev) => ({ ...prev, downloadResumeDropDown: !prev.downloadResumeDropDown }))} sx={{ background: "rgba(56, 193, 223, 0.20)", height: "100%", border: `1px solid ${Color.primary1}` }}>
                            <KeyboardArrowDown />
                          </Button>
                        </Box>
                        {flags.downloadResumeDropDown && (
                          <Tooltip title={accessRights.find((item) => item.access_right_id === 13)?.is_activated === 1 ? "" : tooltipText}>
                            <Box>
                              <Button onClick={() => handleCloseApplicationsModalOpen()} sx={{ ...DivCenter, cursor: "pointer", background: "rgba(56, 193, 223, 0.20)", border: `1px solid ${Color.primary1}`, width: "100%", height: "35px", textTransform: "none", color: "#000" }} disabled={accessRights.find((item) => item.access_right_id === 13)?.is_activated === 1 ? false : true}>
                                Close Applications
                              </Button>
                            </Box>
                          </Tooltip>
                        )}
                        <Box>
                          <Typography style={{ marginTop: ".5rem", color: Color.neutralMidGrey, textAlign: "right" }}>Deadline: {[ddmmm(review?.application_deadline), ", ", convertEpochToNormalTime(review?.application_deadline)]}</Typography>
                          {timeRemaining <= 12 && (
                            <>
                              <Typography>
                                <div style={{ display: "flex", alignItems: "right", color: Color.neutralDarkGrey, textAlign: "right", justifyContent: "flex-end" }}>
                                  <FeedbackRemainingHoursTimer startTime={review ? review.application_deadline - 43200000 : 0} />
                                  <p
                                    style={{
                                      marginLeft: "5px", // Adjust margin as needed
                                      cursor: "none",
                                      color: Color.primary1,
                                    }}
                                  >
                                    to deadline
                                  </p>
                                </div>
                              </Typography>
                            </>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end", flexDirection: "column", gap: "16px" }}>
                      {[null, undefined, ""].includes(review.schedulerTime) ? (
                        <Tooltip title={accessRights.find((item) => item.access_right_id === 10)?.is_activated === 1 ? "" : tooltipText}>
                          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: "5px" }}>
                            <PrimaryButton type="contained" className="Rephrasebutton" sx={{ width: "70%", height: "44px", alignItems: "right", textTransform: "none", color: "#fff", fontSize: "16px" }} onClick={() => handleApplicationModalOpen("Open Applications")} disabled={accessRights.find((item) => item.access_right_id === 10)?.is_activated === 1 ? false : true}>
                              Publish
                            </PrimaryButton>

                            <Typography sx={{ color: Color.neutralMidGrey }}>Open the opportunity for the students to apply</Typography>
                          </Box>
                        </Tooltip>
                      ) : (
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: "5px" }}>
                          <Button type="contained" sx={{ width: "fit-content", height: "44px", alignItems: "right", textTransform: "none", color: Color.red, fontSize: "16px", border: `1px solid ${Color.red}`, borderRadius: "12px" }} onClick={() => setCancelSchedulePublishModal(true)}>
                            Cancel Job Schedule
                          </Button>
                          <Typography sx={{ color: Color.neutralMidGrey }}>
                            Application Opens at {pad(new Date(review.schedulerTime).getDate())} {months[new Date(review.schedulerTime).getMonth()]}, {new Date(review.schedulerTime).getFullYear()} {pad(new Date(review.schedulerTime).getHours() > 12 ? new Date(review.schedulerTime).getHours() - 12 : new Date(review.schedulerTime).getHours())}:{pad(new Date(review.schedulerTime).getMinutes())} {new Date(review.schedulerTime).getHours() > 12 ? "PM" : "AM"}
                          </Typography>
                          <Typography sx={{ color: Color.neutralMidGrey }}>
                            Application Closes at {pad(new Date(review.scheduler_application_deadline).getDate())} {months[new Date(review.scheduler_application_deadline).getMonth()]}, {new Date(review.scheduler_application_deadline).getFullYear()} {pad(new Date(review.scheduler_application_deadline).getHours() > 12 ? new Date(review.scheduler_application_deadline).getHours() - 12 : new Date(review.scheduler_application_deadline).getHours())}:{pad(new Date(review.scheduler_application_deadline).getMinutes())} {new Date(review.scheduler_application_deadline).getHours() > 12 ? "PM" : "AM"}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              <Box sx={{ width: "5%" }} />
            </Box>
          </Box>

          {/* lower 2 parts - left and right */}
          <Box sx={{ ...DivCenter, width: "100%", height: "80%", borderTop: `1px solid ${Color.neutralLightGrey}` }}>
            <Box sx={{ ...DivCenter, width: "90%", justifyContent: "flex-start", alignItems: "flex-start", borderTop: `1px solid ${Color.neutralLightGrey}` }}>
              {/* Left Part */}
              <Box sx={{ ...DivCenter, flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", height: "100%", width: "20%", marginTop: "6%", padding: "24px", gap: "30px", position: "fixed", top: "20%", zIndex: "1" }}>
                {stepper.map((step, step_index) => (
                  <Box key={step_index} onClick={() => handleSideNavClick(step_index)} sx={{ ...DivCenter, flexDirection: "column", gap: "40px", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "left" }}>
                    <Typography className="typography__stepperTitle" sx={{ ...DivCenter, color: step.selected === true ? Color.primary1 : Color.blueBlack, cursor: "pointer", fontSize: "16px!important", textAlign: "left", alignItems: "flex-start" }}>
                      {step.title}
                    </Typography>
                  </Box>
                ))}
              </Box>

              {/* Right Part */}
              {review && (
                <Box sx={{ ...DivCenter, width: "80%", right: 0, marginTop: "18%", float: "right", justifyContent: "flex-end", alignItems: "flex-end", position: "relative", overflow: "hidden", marginLeft: "auto", borderTop: `1px solid ${Color.neutralLightGrey}` }}>
                  <Box sx={{ display: "flex", flexDirection: "column", padding: "5px", gap: "32px", borderLeft: `1px solid ${Color.neutralLightGrey}`, paddingLeft: "1rem", width: "100%" }}>
                    <SubComponentsCompanyInfo reference={componentRefs.CompanyInfo} id="CompanyInfo" className="CompanyInfo" review={review} setReview={setReview} formErrors={formErrors} setFormErrors={setFormErrors} allCompanies={allCompanies} isEdit={accessRights.find((item) => item.access_right_id === 9)?.is_activated === 1 ? true : false} />
                    <SubComponentsOpportunityOutline reference={componentRefs.OpportunityOutline} id="OpportunityOutline" className="OpportunityOutline" review={review} setReview={setReview} formErrors={formErrors} setFormErrors={setFormErrors} isEdit={accessRights.find((item) => item.access_right_id === 9)?.is_activated === 1 ? true : false} />
                    <SubComponentsJobDescription reference={componentRefs.JobDescription} id="JobDescription" className="JobDescription" review={review} setReview={setReview} formErrors={formErrors} setFormErrors={setFormErrors} isEdit={accessRights.find((item) => item.access_right_id === 9)?.is_activated === 1 ? true : false} />
                    <SubComponentsSkillsRequired reference={componentRefs.SkillsRequired} id="SkillsRequired" className="SkillsRequired" review={review} setReview={setReview} formErrors={formErrors} setFormErrors={setFormErrors} isEdit={accessRights.find((item) => item.access_right_id === 9)?.is_activated === 1 ? true : false} />
                    <SubComponentsEligibilityCriteria reference={componentRefs.EligibilityCriteria} id="EligibilityCriteria" className="EligibilityCriteria" review={review} setReview={setReview} formErrors={formErrors} setFormErrors={setFormErrors} isEdit={accessRights.find((item) => item.access_right_id === 9)?.is_activated === 1 ? true : false} />
                    <SubComponentsCostToCompany reference={componentRefs.CostToCompany} id="CostToCompany" className="CostToCompany" review={review} setReview={setReview} formErrors={formErrors} setFormErrors={setFormErrors} isEdit={accessRights.find((item) => item.access_right_id === 9)?.is_activated === 1 ? true : false} />
                    <SubComponentsOtherDocuments reference={componentRefs.OtherDocuments} id="OtherDocuments" className="OtherDocuments" review={review} setReview={setReview} formErrors={formErrors} setFormErrors={setFormErrors} isEdit={accessRights.find((item) => item.access_right_id === 9)?.is_activated === 1 ? true : false} />
                    <SubComponentsAdditionalInformation reference={componentRefs.AdditionalInformation} id="AdditionalInformation" className="AdditionalInformation" review={review} setReview={setReview} formErrors={formErrors} setFormErrors={setFormErrors} isEdit={accessRights.find((item) => item.access_right_id === 9)?.is_activated === 1 ? true : false} />
                    {showBox ? (
                      <Box style={{ justifyContent: "center", alignItems: "center", margin: "0 auto", background: "#eee", padding: "20px", borderRadius: "15px" }}>
                        <Typography style={{ textAlign: "center", color: Color.primary1, marginBottom: "1rem" }}>More Options</Typography>
                        <Tooltip title={accessRights.find((item) => item.access_right_id === 21)?.is_activated === 1 ? "" : tooltipText}>
                          <Box>
                            <Button type="contained" className="Rephrasebutton" sx={{ width: "fit-content", height: "44px", alignItems: "right", textTransform: "none", color: Color.red, fontSize: "16px", background: "rgba(255, 87, 51, 10%)", "&:disabled": { width: "fit-content", padding: "8px", background: Color.neutralMidGrey } }} onClick={() => handleNullOrVoidModalOpen()} disabled={accessRights.find((item) => item.access_right_id === 21)?.is_activated === 1 ? false : true}>
                              Make this Opportunity Null and Void
                            </Button>
                          </Box>
                        </Tooltip>
                      </Box>
                    ) : (
                      <Box style={{ justifyContent: "center", alignItems: "center", margin: "0 auto", background: "#eee", padding: "20px", borderRadius: "15px", cursor: "pointer" }} onClick={handleMoreOptionsClick}>
                        <Typography style={{ textAlign: "center", color: Color.primary1 }}>More Options</Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <ApplicationDeadlineModal modalOpen={applicationDeadlineModal} handleModalClose={handleApplicationModalClose} job_id={job_id} setApplicationDeadlineModal={setApplicationDeadlineModal} batch_id={batch_id} headerText={openOrExtendText} setPublishConfirmationModal={setPublishConfirmationModal} review={review} />
          <CloseApplicationModal modalOpen={closeApplications} handleModalClose={handleCloseApplicationsModalClose} job_id={job_id} handleCloseApplications={handleCloseApplications} type="close" />
          <NullOrVoidModal modalOpen={nullOrVoid} handleModalClose={handleNullOrVoidModalClose} job_id={job_id} handleCloseApplications={handleNullOrVoid} />
          {groupChat && review.status_id !== 2 && review.status_id !== null && <GroupChatModal modalOpen={groupChat} handleModalClose={handleGroupChatCloseInReview} job_id={job_id} status_name={review.status_name} review={review} handleCloseApplications={handleGroupChatCloseInReview} />}
          <PublishConfirmationModal open={publishConfirmationModal.open} onClose={publishConfirmationModal.onClose} />
          <ActivityLoader show={loading.activity_loader} />

          <Modal open={cancelSchedulePublishModal} onClose={() => setCancelSchedulePublishModal(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ width: `min("400px", "30vw")`, background: "#fff", padding: "20px 40px", borderRadius: "12px", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "30px" }}>
              <Typography>Are you sure you would like to cancel Job schedule</Typography>
              <Box sx={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
                <Button sx={{ border: `1px solid ${Color.primary1}`, color: Color.primary1 }} onClick={() => setCancelSchedulePublishModal(false)}>
                  Go back
                </Button>

                <Button sx={{ color: Color.white, background: Color.primary1, "&:hover": { color: Color.white, background: Color.primary1 } }} onClick={() => handleCancelJOb()}>
                  {" "}
                  Cancel Job Request
                </Button>
              </Box>
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
};

export default POReview;
