import { Box, IconButton, Modal, TextField, Typography, CircularProgress, Select, MenuItem, InputAdornment } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility, Person, QuestionMark, AccountCircleOutlined } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SearchIcon from "@mui/icons-material/Search";
import SideNavigationBar from "../reusables/SideNavigationBar/SideNavigationBar";
import Service from "../../services/httpService";

const Eye = () => {
  return (
    <svg width="25" height="30" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1088_33378)">
        <path d="M8.51 5.26531C11.1891 5.26531 13.5783 6.77096 14.7446 9.15313C13.5783 11.5353 11.1961 13.0409 8.51 13.0409C5.82388 13.0409 3.44171 11.5353 2.27536 9.15313C3.44171 6.77096 5.83094 5.26531 8.51 5.26531ZM8.51 3.85156C4.97563 3.85156 1.95727 6.04994 0.734375 9.15313C1.95727 12.2563 4.97563 14.4547 8.51 14.4547C12.0444 14.4547 15.0627 12.2563 16.2856 9.15313C15.0627 6.04994 12.0444 3.85156 8.51 3.85156ZM8.51 7.38594C9.48549 7.38594 10.2772 8.17764 10.2772 9.15313C10.2772 10.1286 9.48549 10.9203 8.51 10.9203C7.53451 10.9203 6.74281 10.1286 6.74281 9.15313C6.74281 8.17764 7.53451 7.38594 8.51 7.38594ZM8.51 5.97219C6.75695 5.97219 5.32906 7.40008 5.32906 9.15313C5.32906 10.9062 6.75695 12.3341 8.51 12.3341C10.2631 12.3341 11.6909 10.9062 11.6909 9.15313C11.6909 7.40008 10.2631 5.97219 8.51 5.97219Z" fill="#525252" />
      </g>
      <defs>
        <clipPath id="clip0_1088_33378">
          <rect width="30" height="30" fill="white" transform="translate(0.03125 0.669922)" />
        </clipPath>
      </defs>
    </svg>
  );
};

function StudentProcess() {
  const navigate = useNavigate();
  const location = useLocation();
  const service = new Service();
  const program_id = location.state?.program_id;

  const college_id = location.state?.college_id;
  const college_name = location.state?.college_name;
  const program_name = location.state?.program_name;
  const activated_students = location.state?.activated_students;
  const non_activated_students = location.state?.non_activated_students;

  const initialPrompt = [
    { maxValue: ".25", selected: false, name: "< 25%", minValue: "0" },
    { maxValue: ".5", selected: false, name: "25% to 50%", minValue: ".25" },
    { maxValue: ".75", selected: false, name: "50% to 75%", minValue: ".5" },
    { maxValue: "1", selected: false, name: "> 75%", minValue: ".75" },
  ];

  const initialRAG = [
    { value: "R", selected: false, name: "Red" },
    { value: "A", selected: false, name: "Amber" },
    { value: "G", selected: false, name: "Green" },
  ];
  const [MainLoader, setMainLoader] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [allStudents, setAllStudents] = useState([]);
  const [batchProcesses, setbatchProcess] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [userGroup, setUserGroup] = useState(location.state.batch);

  const [AlluserGroup, setAllUserGroup] = useState([]);
  const [ragOptions, setRagOptions] = useState(initialRAG);
  const [promptnessFilter, setPromptnessFilter] = useState(initialPrompt);
  console.log("locationState", location.state);

  const handleData = (data) => {
    const temp_data = data?.map((item) => {
      return {
        user_id: item.user_id,
        name: item.name ? item.name : "",
        user_picture: item.user_picture ? item.user_picture : null,
        email: item.email_id ? item.email_id : "",
        ph_no: item.ph_no ? item.ph_no : "",
        roll_no: item.roll_no ? item.roll_no : null,
        all_process_data: item.all_process_data ? JSON.parse(item.all_process_data) : [],
      };
    });
    console.log("kdfhkj", temp_data);
    return temp_data;
  };

  console.log("allStudents", allStudents);

  const handleVisibility = (student, process, order_flag) => {
    let temp_student = {
      email: student.email,
      first_name: student.first_name,
      last_name: student.last_name,
      user_id: student.user_id,
      ph_no: student.ph_no,
      roll_no: student.roll_no,
      process: student.all_process_data.filter((item) => item.process_id === process.process_id)[0],
    };

    if (temp_student.process.is_absent === 1) {
      setOpenModal(true);
    } else {
      setCurrentPage(1);
      navigate(`/DiagnosticInterview?user_id=${student.user_id}&process_id=${process.process_id}&user_group_id=${userGroup.program_id}`, { state: { process_id: process.process_id, sku_name: process.sku_name } });
    }
  };

  const [selectedProcess, setSelectedProcess] = useState({
    process_id: null,
  });

  const handleProgressTracker = (student, progress_tracker, process_id=null) => {
    setCurrentPage(1);
    let studentProcess = student.all_process_data.filter((item) => item.order_flag === "Done");
    studentProcess = studentProcess.map((item) => item.process_id);
    //Filtering batched for with the process is in done state
    let batches = batchProcesses.map((item) => {
      if (studentProcess.includes(item.process_id)) {
        return item;
      } else {
        return null;
      }
    });

    batches = batches.filter((item) => item !== null);
    console.log("studentandProcess", student);
    if(progress_tracker) {
      navigate(`/StudentWiseAnalytics?user_id=${student.user_id}`, { state: { student_name: `${student.name}`, student_roll_no: `${student.roll_no}`, allProcess: batches, userGroupId: userGroup.program_id, program_name: program_name, college_name: college_name, college_id: college_id, image: student.user_picture, student_allProcess: student.all_process_data, activated_students: activated_students, non_activated_students: non_activated_students, progress_tracker : progress_tracker } });
    } else {
      navigate(`/StudentWiseAnalytics?user_id=${student.user_id}`, { state: { student_name: `${student.name}`, student_roll_no: `${student.roll_no}`, allProcess: batches, userGroupId: userGroup.program_id, program_name: program_name, college_name: college_name, college_id: college_id, image: student.user_picture, student_allProcess: student.all_process_data, activated_students: activated_students, non_activated_students: non_activated_students, progress_tracker: progress_tracker,process_id:process_id } });
    }
  };
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (Cookies.get("token") === null || Cookies.get("token") === undefined) {
        navigate("/");
      }
      const config = {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      };

      const userData = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/user/studentAnalysisData?user_group_id=${userGroup.program_id}&page=${currentPage}&size=20`, config);

      if (currentPage === 1) {
        setAllStudents(handleData(userData.data.studentAnalysis));
      } else {
        let previous_students = allStudents;
        let new_students = handleData(userData.data.studentAnalysis);
        new_students.map((item) => {
          previous_students.push(item);
        });

        setAllStudents(previous_students);
      }

      setLoading(false);
    };

    getData();
  }, [currentPage, userGroup]);

  console.log("usergroup", userGroup);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;

      // Check if the user has scrolled to the bottom of the page
      if (windowHeight + scrollTop + 10 > documentHeight) {
        // Increment the count by one when the user reaches the bottom
        setCurrentPage((prev) => {
          if (allStudents?.length > 0) {
            return prev + 1;
          } else {
            return prev;
          }
        });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [allStudents, currentPage]);

  useEffect(() => {
    const getBatchData = async () => {
      setMainLoader(true);

      const config = {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      };

      const batchProcess = await axios.get(`${process.env.REACT_APP_API_URL}/analysis/processByUg_id?user_group_id=${userGroup.program_id}`, config);
      console.log("batchProcess", batchProcess);
      setbatchProcess(batchProcess.data.getProcessAndSku);
      setMainLoader(false);
    };
    getBatchData();
  }, [userGroup]);

  useEffect(() => {
    const getData = async () => {
      const config = {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      };
      //FIXME:Need proper routes for the request
      // const allBatches = await axios.get(`https://dev9.careercarve.in/api/superAdmin/programs?college_id=${location.state.college_id}`, config);
      const res = await service.get(`/programs?college_id=${location.state.college_id}`);

      setAllUserGroup(res.data);
      const matchingItem = res.data?.find((item) => item?.program_id === program_id);

      if (matchingItem) {
        setUserGroup(matchingItem);
      }
    };
    getData();
  }, []);

  //FIXME: Handle SEARCHOPtions

  const handleSearch = async (e) => {
    // console.log("object", allStudents);
    setFilterLoader(true);
    const config = {
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };

    const searchedData = await axios.get(makeURl(1), config);
    setCurrentPage(1);

    setAllStudents(handleData(searchedData.data.studentAnalysis));
    setFilterLoader(false);
  };

  const handleEnterPressed = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleTextField = async (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      const config = {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      };
      setCurrentPage(1);
      setFilterLoader(true);
      const userData = await axios.get(makeURLforSearch(1), config);
      setAllStudents(handleData(userData.data.studentAnalysis));
      setFilterLoader(false);
    }
  };

  const handleRagAnalysis = async (item, index) => {
    setCurrentPage(1);
    if (item.selected === false) {
      setRagOptions((prev) => {
        let temp = prev.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              selected: true,
            };
          } else {
            return {
              ...item,
              selected: false,
            };
          }
        });

        return temp;
      });
    } else {
      setRagOptions((prev) => {
        let temp = prev.map((item, i) => {
          return {
            ...item,
            selected: false,
          };
        });

        return temp;
      });
    }
  };

  const handlePromptnessAnalysis = async (item, index) => {
    setCurrentPage(1);
    if (item.selected === false) {
      setPromptnessFilter((prev) => {
        let temp = prev.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              selected: true,
            };
          } else {
            return {
              ...item,
              selected: false,
            };
          }
        });

        return temp;
      });
    } else {
      setPromptnessFilter((prev) => {
        let temp = prev.map((item, i) => {
          return {
            ...item,
            selected: false,
          };
        });

        return temp;
      });
    }
  };

  const handleProcessFilter = (item) => {
    console.log("SelectedProcess", item);
    if (selectedProcess.process_id === item.process_id) {
      setSelectedProcess({
        process_id: null,
      });
    } else {
      setSelectedProcess(item);
    }
  };

  const handleResetFilter = () => {
    setSelectedProcess({
      process_id: null,
    });

    setPromptnessFilter((prev) => {
      let temp = prev.map((item) => {
        return {
          ...item,
          selected: false,
        };
      });

      return temp;
    });

    setRagOptions((prev) => {
      let temp = prev.map((item, i) => {
        return {
          ...item,
          selected: false,
        };
      });

      return temp;
    });
  };

  const goBack = () => {
    console.log("Back Button is Clicked Now");
    navigate(-1);
  };

  const handleBatchDataAnalyticsClick = () => {
    navigate("/BatchWiseAnalytics", { state: { college_id: college_id, college_name: college_name, program_id: program_id, program_name: program_name, activated_students: activated_students, non_activated_students: non_activated_students } });
  };

  const makeURl = (page) => {
    let url = `${process.env.REACT_APP_API_URL}/analytics/user/studentAnalysisData?user_group_id=${userGroup.program_id}&page=${page}&size=20`;
    let selectedRag = ragOptions.filter((item) => item.selected === true);
    let promptness = promptnessFilter.filter((item) => item.selected === true);
    setCurrentPage(page);
    if (searchValue !== "" && searchValue.length > 1) {
      url = url + `&searchParam=${searchValue}`;
    }

    if (selectedRag.length > 0) {
      url = url + `&filter_RAG=${selectedRag[0].value}`;
    }

    if (promptness.length > 0) {
      url = url + `&promptness_min=${promptness[0].minValue}&promptness_max=${promptness[0].maxValue}`;
    }

    if (selectedProcess.process_id !== null) {
      url = url + `&filter_process_id=${selectedProcess.process_id}`;
    }

    return url;
  };

  const makeURLforSearch = (page) => {
    let url = `${process.env.REACT_APP_API_URL}/analytics/user/studentAnalysisData?user_group_id=${userGroup.program_id}&page=${page}&size=20`;
    let selectedRag = ragOptions.filter((item) => item.selected === true);
    let promptness = promptnessFilter.filter((item) => item.selected === true);
    setCurrentPage(1);
    if (selectedRag.length > 0) {
      url = url + `&filter_RAG=${selectedRag[0].value}`;
    }

    if (promptness.length > 0) {
      url = url + `&promptness_min=${promptness[0].minValue}&promptness_max=${promptness[0].maxValue}`;
    }

    if (selectedProcess.process_id !== null) {
      url = url + `&filter_process_id=${selectedProcess.process_id}`;
    }

    return url;
  };
  useEffect(() => {
    const getFilteredData = async () => {
      setFilterLoader(true);
      const config = {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      };

      setCurrentPage(1);

      const userData = await axios.get(makeURl(1), config);
      setAllStudents(handleData(userData.data.studentAnalysis));
      setFilterLoader(false);
    };

    getFilteredData();
  }, [ragOptions, promptnessFilter, selectedProcess]);

  return (
    <Box sx={{ flexDirection: "column", display: "flex", gap: "10px", padding: "2rem 5rem 5rem 8rem" }}>
      <SideNavigationBar college_id={college_id} college_name={college_name} />
      <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2">Student Analytics Report</Typography>
        <button
          onClick={() => {
            handleBatchDataAnalyticsClick();
          }}
          className="batch-button"
          style={{ width: "auto", paddingLeft: ".5rem", paddingRight: ".5rem" }}
        >
          View Batch Data Analytics
        </button>
      </Box>
      <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
        <Box style={{ float: "left", width: "71%" }}>
          <AccountCircleOutlined sx={{ fontSize: "42px", marginRight: ".5rem" }} />
          <Select value={userGroup} onChange={(e) => setUserGroup(e.target.value)} sx={{ width: "20%", borderRadius: "12px", padding: "2px", fontSize: "18px", height: "40px" }}>
            {AlluserGroup.map((item) => (
              <MenuItem value={item}>{item.program_name}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box style={{ float: "right", color: "#41B8D5", textAlign: "right" }}>
          <Typography variant="h5">Select a particular activity report column to see RAG analysis filters </Typography>
        </Box>
      </Box>
      <TextField
        onChange={(e) => handleTextField(e)}
        onKeyDown={(e) => handleEnterPressed(e)}
        value={searchValue}
        width={"100%"}
        height={"20px"}
        sx={{ fontSize: "20px" }}
        placeholder="Search for a student name, roll number, email or phone number here"
        InputProps={{
          style: { borderRadius: "50px" },
          endAdornment: (
            <InputAdornment>
              <IconButton onClick={() => handleSearch()}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ display: "flex", gap: "2rem" }}>
          <Box>
            <Typography variant="h5" sx={{ marginBottom: "10px" }}>
              Promptness:
            </Typography>
            <Box sx={{ display: "flex", gap: "10px" }}>
              {promptnessFilter.map((item, promp_index) => (
                <button style={{ border: "2px solid #41B8D5", padding: "5px 20px", background: item.selected ? "#41B8D5" : "none", borderRadius: "20px", color: item.selected ? "#fff" : "#000" }} onClick={() => handlePromptnessAnalysis(item, promp_index)}>
                  <Typography variant="body1" sx={{ fontSize: "10px" }}>
                    {item.name}
                  </Typography>
                </button>
              ))}
            </Box>
          </Box>

          {selectedProcess.process_id !== null && (
            <Box>
              <Typography variant="h5" sx={{ marginBottom: "10px", color: "#41B8D5" }}>
                RAG Analysis
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                {ragOptions.map((item, rag_index) => (
                  <button style={{ border: "2px solid #41B8D5", padding: "5px 20px", background: item.selected ? "#41B8D5" : "none", borderRadius: "20px", color: item.selected ? "#fff" : "#41B8D5" }} onClick={() => handleRagAnalysis(item, rag_index)}>
                    <Typography variant="body1" sx={{ fontSize: "10px" }}>
                      {item.name}
                    </Typography>
                  </button>
                ))}
              </Box>
            </Box>
          )}
        </Box>

        <Box sx={{ alignSelf: "flex-end" }}>
          <button style={{ border: "2px solid #41B8D5", padding: "5px 20px", background: "none", borderRadius: "20px", color: "#000" }} onClick={() => handleResetFilter()}>
            <Typography variant="body1" sx={{ fontSize: "10px" }}>
              Reset All Filters
            </Typography>
          </button>
        </Box>
      </Box>
      {allStudents?.length > 0 ? (
        <>
          <Box sx={{ display: "flex", gap: "10px", marginTop: "10px" }}>
            <Box sx={{ color: "#7F7F7F", width: "20%", background: "#E8E8E8", padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography sx={{ fontSize: "16px" }}>Name, Phone No, Email</Typography>
            </Box>
            <Box sx={{ color: "#7F7F7F", width: "20%", background: "#E8E8E8", padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography sx={{ fontSize: "16px" }}>Roll No</Typography>
            </Box>{" "}
            <Box sx={{ color: "#7F7F7F", width: "20%", background: "#E8E8E8", padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography sx={{ fontSize: "16px" }}>Progress Tracker</Typography>
            </Box>
            {batchProcesses.map((item) => (
              <Box sx={{ color: "#7F7F7F", width: "20%", background: item.process_id === selectedProcess.process_id ? "#E0F9FF" : "#E8E8E8", padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography sx={{ fontSize: "16px", textAlign: "center", padding: "5px 20px", border: "1px solid #41B8D5", borderRadius: "20px", background: selectedProcess.process_id === item.process_id ? "#41B8D5" : "", color: selectedProcess.process_id === item.process_id ? "#fff" : "", "&:hover": { cursor: "pointer" } }} onClick={() => handleProcessFilter(item)}>
                  {item.sku_name}
                </Typography>
              </Box>
            ))}
          </Box>

          {MainLoader ? (
            <> </>
          ) : (
            <>
              {filterLoader ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  <CircularProgress sx={{ color: "#41B8D5" }} />
                </Box>
              ) : (
                <>
                  {allStudents.map((item, s_index) => (
                    <Box sx={{ display: "flex", gap: "10px", height: "auto" }}>
                      <Box sx={{ padding: "10px", bgcolor: "#FAFAFA", width: "20%" }}>
                        <Typography sx={{ fontSize: "16px", color: "#000", fontWeight: "700" }}>{item.name}</Typography>
                        <Typography sx={{ fontSize: "14px", color: "#000" }}>{item.ph_no}</Typography>
                        <Typography sx={{ fontSize: "14px", color: "#7F7F7F" }}>{item.email.length < 28 ? item.email : `${item.email.slice(0, 28)}...`}</Typography>
                      </Box>
                      <Box sx={{ color: "#7F7F7F", width: "20%", bgcolor: "#FAFAFA", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "16px" }}>{item.roll_no}</Typography>
                      </Box>
                      <Box sx={{ width: "20%", display: "flex", justifyContent: "center", alignContent: "center", bgcolor: "#FAFAFA" }}>
                        <IconButton onClick={() => handleProgressTracker(item, true)} sx={{ "&:hover": { color: "#41B8D5", bgcolor: "transparent" } }}>
                          <Eye />
                        </IconButton>
                      </Box>
                      {batchProcesses.map((process, p_index) => {
                        let student_process = item.all_process_data.filter((data) => data.process_id === process.process_id);

                        if (student_process.length > 0 && student_process[0].order_flag === "Done") {
                          return (
                            <Box sx={{ width: "20%", display: "flex", justifyContent: "center", alignContent: "center", bgcolor: process.process_id === selectedProcess.process_id ? "#E0F9FF" : "#FAFAFA" }}>
                              <IconButton onClick={() => handleProgressTracker(item, false, process.process_id)} sx={{ "&:hover": { bgcolor: "transparent" } }}>
                                <Eye />
                              </IconButton>
                            </Box>
                          );
                        } else {
                          return (
                            <Box sx={{ width: "20%", display: "flex", justifyContent: "center", alignContent: "center", bgcolor: process.process_id === selectedProcess.process_id ? "#E0F9FF" : "#FAFAFA" }}>
                              <IconButton sx={{ "&:hover": { bgcolor: "transparent" } }}>
                                <VisibilityOffIcon />
                              </IconButton>
                            </Box>
                          );
                        }
                      })}
                    </Box>
                  ))}
                </>
              )}
            </>
          )}
        </>
      ) : (
        !loading && <Typography sx={{ fontSize: "18px", marginTop: "20px" }}> No Students available for this </Typography>
      )}

      {loading && !filterLoader && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <CircularProgress sx={{ color: "#41B8D5" }} />
        </Box>
      )}
      <Modal open={openModal} onClose={() => setOpenModal(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "20px", background: "#FFF", width: "800px", height: "500px", borderRadius: "10px" }}>
          <Box sx={{ width: "200px", height: "200px", padding: "30px", borderRadius: "50%", background: "linear-gradient(144deg, rgba(170,29,29,1) 0%, rgba(233,31,31,1) 0%, rgba(129,26,26,1) 48%, rgba(230,13,13,1) 89%, rgba(255,0,0,1) 100%)", display: "flex" }}>
            <Person sx={{ fontSize: "150px", color: "#FFF" }} />
            <QuestionMark sx={{ position: "relative", right: "50px", fontSize: "40px", color: "#fff" }} />
          </Box>
          <Typography sx={{ color: "#FF3131", fontSize: "24px" }}>The Student was marked absent for this activity. </Typography>
        </Box>
      </Modal>
    </Box>
  );
}

export default StudentProcess;
