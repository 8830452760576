import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import '../../Dashboard/OngoingEvents/OngoingEvents.css';
import SideNavigationBar from "../../reusables/SideNavigationBar/SideNavigationBar";
import { Color } from "../../../GlobalStyles";
import Loader from "../../reusables/Loader/Loader";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
const Batches = () => {
    const service = new CCService()
    const navigate = useNavigate()
    const [batchData, setBatchData] = useState([])
    const [loading, setLoading] = useState(true)

    const goBack = () => {
        console.log("Back Button is Clicked Now");
        navigate(-1);
    };

    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {

            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }
            //batch data getter
            const batchData = await batchDataGetter();
            setBatchData(batchData)
            console.log("odonil", { batchData })
            setLoading(false)
        }

        handleEffect();
    }, [])

    const batchDataGetter = async () => {
        try {
            const res = await service.get("/po/ca/batches")
            console.log("batches get data RESPONSE:", res.batches)
            // setBatchData([...res.batches])
            return res.batches
        } catch (err) {
            console.log(err)
        }
    }

    const handleJobOpportunityClick = (batch_id, batch_name) => {
        navigate("/JobsPostingPage", { state: { batch_id: batch_id, batch_name: batch_name } })
    }

    return (
        <div>
            <SideNavigationBar />
            <div className='OngoingEventsCard' style={{ marginTop: "2rem", marginLeft: "10rem" }}>
                <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />

                <Typography style={{ marginBottom: "2rem", marginLeft: ".5rem", marginTop: "1rem" }} sx={{ fontSize: 22 }}>Batches</Typography>
                {loading ?
                    <Loader show={loading} />
                    : <>
                        <div className="cardsInsideFlex">
                            {batchData.map((batchInfo, i) => {
                                return (
                                    <Box sx={{ width: 255, marginRight: '1rem', boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)", borderRadius: "10px" }}>
                                        <CardContent className="cardContainer" disableGutters>
                                            <Typography variant="body2" sx={{ fontSize: 20 }} gutterBottom>
                                                {batchInfo?.user_group_name}
                                            </Typography>
                                            <Typography variant="h5" component="div"></Typography>
                                            <div className="container">
                                                <Typography variant="body2" color={Color.neutralDarkGrey}>Total Student:</Typography>
                                                <Typography variant="body2" color={Color.neutralDarkGrey} class="providingSpaceForSubDetails">
                                                    {batchInfo?.student_count}
                                                </Typography>
                                            </div>
                                            {/* <div className="container">
              <Typography variant="body2" color={Color.primary1}>Not Activated Students:</Typography>
              <Typography variant="body2" class="providingSpaceForSubDetails">{batchInfo?.non_activated_students}</Typography>
            </div> */}
                                            <CardActions sx={{ paddingBottom: "0px" }}>
                                                <button
                                                    className="submitBtn"
                                                    type="button"
                                                    style={{ height: "44px" }}
                                                    onClick={() => handleJobOpportunityClick(batchInfo?.id, batchInfo?.user_group_name)}
                                                >
                                                    Job Opportunities
                                                </button>

                                            </CardActions>
                                            <CardActions sx={{ paddingBottom: "0px" }}>
                                                <button
                                                    className="submitBtn"
                                                    type="button"
                                                    style={{ height: "44px", background: "transparent", border: "1px solid #38C1DF", color: "black" }}
                                                // onClick={() => handle1x1BatchDetails(batchInfo?.program_name, batchInfo?.id)}
                                                // onClick={() => handleActivatedStatusDetails(batchInfo?.program_name, batchInfo?.program_id)}
                                                >
                                                    Student list
                                                </button>

                                            </CardActions>
                                            <CardActions sx={{ paddingBottom: "0px" }}>
                                                <button
                                                    className="submitBtn"
                                                    type="button"
                                                    style={{ height: "44px", background: "transparent", border: "1px solid #38C1DF", color: "black" }}
                                                // onClick={() => handle1x1BatchDetails(batchInfo?.program_name, batchInfo?.id)}
                                                // onClick={() => handleActivatedStatusDetails(batchInfo?.program_name, batchInfo?.program_id)}
                                                >
                                                    Mark Student(s) Placed
                                                </button>
                                            </CardActions>
                                        </CardContent>
                                    </Box>
                                )
                            })}
                        </div>
                    </>}

            </div>

        </div>
    )
}

export default Batches
