import { Close, Notifications, NotificationsOutlined } from "@mui/icons-material";
import WarningIcon from "@mui/icons-material/Warning";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, IconButton, Modal, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Color, DivCenter, PrimaryButton } from "../../../../GlobalStyles";
import CCService from "../../../../services/httpServiceWithoutSuperAdmin";
import { convertEpochToNormalTime, ddmmm } from "../../../../services/specialCharReplace";
import { motion } from "framer-motion";
function ApplicationDeadlineModal(props) {
  const service = new CCService();
  const [isHovered, setIsHovered] = useState(false);
  let previousdate = new Date().toISOString().slice(0, 10);

  const navigate = useNavigate();
  let previousTime = `${new Date().getHours()}:${new Date().getMinutes()}`;

  let timeCheck = previousTime.split(":");
  if (timeCheck[0].length !== 2) {
    previousTime = `${timeCheck[0]}:${timeCheck[1]}`;
  }
  if (timeCheck[1].length !== 2) {
    previousTime = `${timeCheck[0]}:${timeCheck[1]}`;
  }

  const [value, setValue] = useState(dayjs(previousdate));
  const [postLaterValue, setPostLaterValue] = useState(dayjs(previousdate));
  const [postLaterTime, setPostLaterTime] = useState("12:00");

  const [ampm, setAmpm] = useState("AM");

  const [time, setTime] = useState(previousTime);

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const [alertModal, setAlertModal] = useState({
    open: false,
    onClose: () => setAlertModal((prev) => ({ ...prev, open: false })),
  });

  const [postLater, setPostLater] = useState(false);
  const [selectedType, setSelectedType] = useState("deadline");
  const [postLaterAmPm, setPostLaterAmPm] = useState("AM");

  function PublishConfirmationModal({ open, onClose }) {
    const modalBoxStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "white",
      padding: "2rem",
      borderRadius: "40px",
      width: "35%",
    };
    const [modalLoading, setModalLoading] = useState({ button: false });

    //FUNCTIONS
    const handleConfirmation = async () => {
      setModalLoading((prev) => ({ ...prev, button: true }));
      setModalLoading((prev) => ({ ...prev, button: false }));
      onClose();
    };

    return (
      <Modal open={open} onClose={!modalLoading.button ? onClose : {}}>
        <Box sx={{ ...modalBoxStyle }}>
          <Box sx={{ ...DivCenter, width: "100%", alignItems: "space-between", justifyContent: "space-between", marginBottom: "8px" }}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <WarningIcon style={{ color: "#ffcc00" }} />
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: Color.red,
                  // paddingLeft: "16px",
                }}
              >
                ERROR
              </Typography>
            </Box>
            <IconButton disabled={modalLoading.button} onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "normal",
              marginBottom: "8px",
              // paddingLeft: "16px",
            }}
          >
            Please select a date-time that comes after the already published deadline {[ddmmm(props.review?.application_deadline), ", ", convertEpochToNormalTime(props.review?.application_deadline)]}. You cannot move up or pre-pone a deadline.
          </Typography>
          <Box
            sx={{
              ...DivCenter,
              alignItems: "flex-start",
              justifyContent: "flex-end",
              flexDirection: "row",
              width: "100%",
              mt: "16px",
              // gap: "10px",
            }}
          >
            <Box sx={{ ...DivCenter, width: "100%", gap: "16px" }}>
             
              <PrimaryButton
                disabled={modalLoading.button}
                variant="outlined"
                onClick={() => onClose()}
                sx={{
                  textTransform: "none",
                  borderColor: Color.primary1,
                  background: Color.primary1,
                  color: Color.white,
                  borderRadius: 0,
                  boxShadow: "none",
                  width: "90px",
                  "&:hover": {
                    background: Color.primary1,
                    borderColor: Color.primary1,
                    color: Color.white,
                  },
                }}
              >
                {modalLoading.button ? <CircularProgress size={24} sx={{ color: Color.white }} /> : "Ok"}
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  }

  const handleSave = async () => {
    if (props.headerText === "Open Applications" && props?.setPublishConfirmationModal) {
      // props.handleModalClose(); //removed this coz i felt like the user should be able to see the chosen date

      props.setPublishConfirmationModal((prev) => ({ ...prev, open: true, extraData: { value: value, time: time } }));
    } else {
      const date = new Date(`${value["$d"].getFullYear()}-${value["$d"].getUTCMonth() + 1}-${value["$d"].getDate()} ${time}`);
      console.log("date", date);
      const epochTime = date.getTime();
      if ((props.headerText === "Extend Applications" || props.headerText === "Reopen Applications") && epochTime <= props.review?.application_deadline) {
        // window.alert("Extended failed")
        setAlertModal((prev) => ({ ...prev, open: true }));
      } else {
        try {
          const res = await service.get(`/po/ca/publish?po_job_id=${props.job_id}&application_deadline=${epochTime}`);
          props.handleModalClose();
          console.log("verify", res);
          if (props.headerText === "Extend Applications") {
            props.handleModalClose();
          } else {
            navigate("/JobsPostingPage", { state: { batch_id: props.batch_id } });
          }
          // handleEffect()
          return res;
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const handleAmPm = (val) => {
    let timeCheck = time.split(":");
    if (val === "PM" && parseInt(timeCheck[0]) < 12) {
      let newTime = `${(parseInt(timeCheck[0]) + 12) % 24}:${timeCheck[1]}`;
      setTime(newTime);
      setAmpm("PM");
    } else {
      let hour = parseInt(timeCheck[0]) % 12;
      hour = JSON.stringify(hour).length === 2 ? hour : `0${hour}`;
      let newTime = `${hour}:${timeCheck[1]}`;
      console.log("NEW TIME", newTime);
      setAmpm("AM");
      setTime(newTime);
    }
  };

  const handlePostLaterAMPM = (val) => {
    let timeCheck = postLaterTime.split(":");
    if (val === "PM" && parseInt(timeCheck[0]) < 12) {
      let newTime = `${(parseInt(timeCheck[0]) + 12) % 24}:${timeCheck[1]}`;
      setPostLaterTime(newTime);
      setPostLaterAmPm("PM");
    } else {
      let hour = parseInt(timeCheck[0]) % 12;
      hour = JSON.stringify(hour).length === 2 ? hour : `0${hour}`;
      let newTime = `${hour}:${timeCheck[1]}`;

      setPostLaterAmPm("AM");
      setPostLaterTime(newTime);
    }
  };

  const handleScheduleLater = async () => {
    let [hours, minutes] = time.split(":").map(Number);
    let [postLaterhour, setPostLaterMin] = postLaterTime.split(":").map(Number);
    let combinedDateTime = new Date(Date.UTC(value["$y"], value["$M"], value["$D"], hours, minutes));
    let PostingTime = new Date(Date.UTC(postLaterValue["$y"], postLaterValue["$M"], postLaterValue["$D"], postLaterhour, setPostLaterMin));

    if (combinedDateTime < PostingTime) {
      window.alert("Deadline cannot be less than Posting time");

      return;
    } else {
      const body = {
        po_job_id: parseInt(props.job_id),
        application_deadline: combinedDateTime.getTime() - 5.5 * 60 * 60 * 1000,
        scheduleTime: PostingTime.getTime() - 5.5 * 60 * 60 * 1000,
      };

      try {
        const postRequest = await service.post("/po/ca/schedulePublish", body);
        console.log("POSTTREQUEST", postRequest);
        props.handleModalClose();
      } catch (error) {
        console.log("SOME Error occurred");
      }
    }
  };

  useEffect(() => {
    if (parseInt(time.substring(0, 2)) > 11) {
      setAmpm("PM");
    } else {
      setAmpm("AM");
    }
  }, [time, value]);

  return (
    <Box>
      <Modal open={props.modalOpen} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ width: "50%", background: "#fff", borderRadius: "15px", display: "flex", flexDirection: "column", gap: "10px", padding: "16px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ fontSize: "20px" }}>{props.headerText}</Typography>
            {props.headerText === "Open Applications" && (
              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <Checkbox
                  checked={postLater}
                  onChange={(e) => {
                    setPostLater(e.target.checked);
                    if (!e.target.checked) {
                      setSelectedType("deadline");
                    }
                  }}
                />
                <Typography>Schedule post for later</Typography>
              </Box>
            )}
          </Box>
          {/* BOX 1 FOR DEADLINE */}
          <Accordion expanded={selectedType === "deadline" ? true : false} onChange={() => setSelectedType((prev) => (prev === "deadline" ? "" : "deadline"))} sx={{ margin: 0, borderRadius: "8px", border: "none" }}>
            <AccordionSummary>
              <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Typography sx={{ fontSize: "18px" }}>{props.headerText === "Open Applications" ? "Application Deadline" : "Extend Deadline"}</Typography>
                <Typography sx={{ color: Color.primary1, fontSize: "16px" }}>
                  {value["$d"].getDate()} {months[value["$d"].getUTCMonth()]} {time} {ampm}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ width: "45%", background: "#165983", borderRadius: "0px 0px 0px 0px", padding: "12px", display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                    <Box>
                      <Typography sx={{ color: "#fff", fontSize: "20px" }}>{value["$d"].getFullYear()}</Typography>
                    
                      <Typography sx={{ color: "#fff", fontSize: "32px" }}>
                        {days[value["$d"].getDay()]}, {months[value["$d"].getUTCMonth()]} {value["$d"].getDate()} 
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        type="time"
                        value={time}
                        InputProps={{
                          sx: {
                            height: "74px",
                            width: "fit-content",
                            m: 0,

                            fontSize: "30px",
                            color: "#fff",
                            borderRadius: "10px 0px 0px 10px",

                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                              borderRadius: "5px 5px 0 0",
                            },
                          },
                          endAdornment: null,
                        }}
                        onChange={(e) => setTime(e.target.value)}
                        sx={{
                          background: "#165983",
                          border: "1px solid #fff",
                          borderRight: "none",
                          borderRadius: "15px 0px 0px 15px",
                          '& input[type="time"]::-webkit-calendar-picker-indicator': {
                            filter: "invert(100%) sepia(5%) saturate(2066%) hue-rotate(199deg) brightness(92%) contrast(100%);",
                            color: "#165983",
                          },
                          "& .MuiFilledInput-root": {
                            background: "rgb(232, 241, 250)",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                            borderColor: "none!important",
                          },
                        }}
                      />
                      <Box ox sx={{ display: "flex", flexDirection: "column", gap: "2px", background: "#165983", padding: "5px", border: "1px solid #fff", borderLeft: "none", borderRadius: "0px 15px 15px 0px" }}>
                        <Typography onClick={() => handleAmPm("AM")} sx={{ fontSize: "14px", padding: "5px", background: ampm === "AM" ? "#fff" : "#165983", color: ampm === "AM" ? "#165983" : "#fff", "&:hover": { cursor: "pointer" }, borderRadius: "10px" }}>
                          AM
                        </Typography>
                        <Typography onClick={() => handleAmPm("PM")} sx={{ fontSize: "14px", padding: "5px", background: ampm === "PM" ? "#fff" : "#165983", color: ampm === "PM" ? "#165983" : "#fff", "&:hover": { cursor: "pointer" }, borderRadius: "10px" }}>
                          PM
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ width: "55%" }}>
                    <Box sx={{ height: "320px" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} views={["year", "month", "day"]} dayOfWeekFormatter={(day) => <Typography sx={{ fontSize: "12px", color: "#165983" }}>{day}</Typography>} disablePast />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                </Box>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* BOX 2 FOR POST DEADLINE */}
          {postLater && (
            <Accordion expanded={selectedType === "postLater" ? true : false} onChange={() => setSelectedType((prev) => (prev === "postLater" ? "" : "postLater"))} sx={{ borderRadius: "8px", margin: 0, border: "none" }}>
              <AccordionSummary>
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <Typography sx={{ fontSize: "18px" }}>Post Schedule</Typography>
                  <Typography sx={{ color: Color.primary1, fontSize: "16px" }}>
                    {postLaterValue["$d"].getDate()} {months[postLaterValue["$d"].getUTCMonth()]} {postLaterTime} {postLaterAmPm}
                  </Typography> 
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "45%", background: "#165983", borderRadius: "0px 0px 0px 0px", padding: "20px", display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                      <Box>
                        <Typography sx={{ color: "#fff", fontSize: "20px" }}>{postLaterValue["$d"].getFullYear()}</Typography>
                        <Typography></Typography>
                        <Typography sx={{ color: "#fff", fontSize: "32px" }}>
                          {days[postLaterValue["$d"].getDay()]}, {months[postLaterValue["$d"].getUTCMonth()]} {postLaterValue["$d"].getDate()} 
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <TextField
                          type="time"
                          value={postLaterTime}
                          InputProps={{
                            sx: {
                              height: "74px",
                              width: "fit-content",
                              m: 0,

                              fontSize: "30px",
                              color: "#fff",
                              borderRadius: "10px 0px 0px 10px",

                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                                borderRadius: "5px 5px 0 0",
                              },
                            },
                            endAdornment: null,
                          }}
                          onChange={(e) => setPostLaterTime(e.target.value)}
                          sx={{
                            background: "#165983",
                            border: "1px solid #fff",
                            borderRight: "none",
                            borderRadius: "15px 0px 0px 15px",
                            '& input[type="time"]::-webkit-calendar-picker-indicator': {
                              filter: "invert(100%) sepia(5%) saturate(2066%) hue-rotate(199deg) brightness(92%) contrast(100%);",
                              color: "#165983",
                            },
                            "& .MuiFilledInput-root": {
                              background: "rgb(232, 241, 250)",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&:hover fieldset": {
                              border: "none",
                              borderColor: "none!important",
                            },
                          }}
                        />
                        <Box ox sx={{ display: "flex", flexDirection: "column", gap: "2px", background: "#165983", padding: "5px", border: "1px solid #fff", borderLeft: "none", borderRadius: "0px 15px 15px 0px" }}>
                          <Typography onClick={() => handlePostLaterAMPM("AM")} sx={{ fontSize: "14px", padding: "5px", background: postLaterAmPm === "AM" ? "#fff" : "#165983", color: postLaterAmPm === "AM" ? "#165983" : "#fff", "&:hover": { cursor: "pointer" }, borderRadius: "10px" }}>
                            AM
                          </Typography>
                          <Typography onClick={() => handlePostLaterAMPM("PM")} sx={{ fontSize: "14px", padding: "5px", background: postLaterAmPm === "PM" ? "#fff" : "#165983", color: postLaterAmPm === "PM" ? "#165983" : "#fff", "&:hover": { cursor: "pointer" }, borderRadius: "10px" }}>
                            PM
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ width: "55%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box sx={{ height: "320px" }}>
                          <DateCalendar value={postLaterValue} onChange={(newValue) => setPostLaterValue(newValue)} views={["year", "month", "day"]} dayOfWeekFormatter={(day) => <Typography sx={{ fontSize: "12px", color: "#165983" }}>{day}</Typography>} disablePast />
                        </Box>
                      </LocalizationProvider>
                    </Box>
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
          )}

          <Box sx={{ marginTop: "8px", position: "relative" }}>
            <Box
              sx={{ padding: "16px", border: `1px dashed ${Color.neutralDarkGrey}`, background: "#fff", "&:hover": { boxShadow: "4px 8px 15.9px 6px rgba(0, 0, 0, 0.15)" }, zIndex: "999" }}
              onMouseEnter={(e) => {
                setIsHovered(true);
              }}
              onMouseLeave={(e) => {
                setIsHovered(false);
              }}
            >
              <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <Box style={{ textAlign: "center", background: Color.neutralMidGrey, padding: "2px 3px", borderRadius: "8px", marginRight: "5px" }}>
                  <Notifications sx={{ color: Color.white, transform: "rotate(30deg)", fontSize: "20px" }} />
                </Box>
                <Typography sx={{ color: Color.neutralDarkGrey, fontSize: "14px" }}>Notify students about this shortlist?</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox size="small" disabled />
                <Typography sx={{ color: Color.neutralDarkGrey, fontSize: "12px" }}>Only Eligible Students</Typography>

                <Checkbox size="small" sx={{ marginLeft: "5px" }} disabled />
                <Typography sx={{ color: Color.neutralDarkGrey, fontSize: "12px" }}>Entire Batch</Typography>
              </Box>
            </Box>

            <Box sx={{ zIndex: "1" }}>
              {isHovered && (
                <motion.div style={{ display: "flex", gap: "2px", position: "absolute", background: "rgba(232, 80, 91, 1)", padding: "5px 12px", right: 1, borderRadius: "8px 8px 0px 0px", zIndex: "-10" }} transition={{ duration: 0.5, type: "spring", delay: 0.1 }} initial={{ top: "0px" }} animate={isHovered ? { top: "-30px" } : { top: "0px" }}>
                  <img src="https://ccicons.s3.amazonaws.com/po/eggCracked.svg" style={{ objectFit: "contain", height: "20px", aspectRatio: "1/1" }} />
                  <Typography sx={{ color: Color.white, fontSize: "14px", fontWeight: "600" }}>Cracking Soon!</Typography>
                  <Typography sx={{ color: Color.white, fontSize: "14px" }}>Notification Control Center</Typography>
                </motion.div>
              )}
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", gap: "20px", width: "100%" }}>
            <Button sx={{ width: "40%", padding: "8px 15px", borderRadius: "15px", background: "#fff", color: "#165983", border: "1px solid #165983" }} onClick={() => props.handleModalClose()}>
              Cancel
            </Button>
            <Button sx={{ width: "40%", padding: "8px 15px", borderRadius: "15px", background: Color.primary1, color: "#fff", border: `1px solid ${Color.primary1}`, "&:hover": { background: Color.primary1, color: "#fff" } }} onClick={() => (postLater ? handleScheduleLater() : handleSave())}>
              {postLater ? "Schedule" : props.headerText}
            </Button>
          </Box>
        </Box>
      </Modal>
      <PublishConfirmationModal open={alertModal.open} onClose={alertModal.onClose} />
    </Box>
  );
}

export default ApplicationDeadlineModal;
