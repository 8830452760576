import { Box, Button, TextField, Typography } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
function Calender(props) {
  let currentDate = new Date().toISOString().slice(0, 10);
  let currentTime = `${new Date().getHours()}:${new Date().getMinutes()}`;
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  let timeCheck = currentTime.split(":");

  if (timeCheck[0].length !== 2) {
    currentTime = `0${timeCheck[0]}:${timeCheck[1]}`;
  }
  if (timeCheck[1].length !== 2) {
    currentTime = `${timeCheck[0]}:0${timeCheck[1]}`;
  }

  const [value, setValue] = useState(dayjs(currentDate));
  const [ampm, setAmpm] = useState("AM");
  const [time, setTime] = useState(currentTime);

  const handleAmPm = (val) => {
    let timeCheck = time.split(":");
    if (val === "PM" && parseInt(timeCheck[0]) < 12) {
      let newTime = `${(parseInt(timeCheck[0]) + 12) % 24}:${timeCheck[1]}`;
      setTime(newTime);
      setAmpm("PM");
    } else {
      let hour = parseInt(timeCheck[0]) % 12;
      hour = JSON.stringify(hour).length === 2 ? hour : `0${hour}`;
      let newTime = `${hour}:${timeCheck[1]}`;
      console.log("NEW TIME", newTime);
      setAmpm("AM");
      setTime(newTime);
    }
  };

  const handleSave = () => {
    let [hours, minutes] = time.split(":").map(Number);
    let combinedDateTime = new Date(Date.UTC(value["$y"], value["$M"], value["$D"], hours, minutes));

    let epochTime = combinedDateTime.getTime() - 5.5 * 60 * 60 * 1000;
    props.handleSave(epochTime);
  };

  useEffect(() => {
    if (parseInt(time.substring(0, 2)) > 11) {
      setAmpm("PM");
    } else {
      setAmpm("AM");
    }
  }, [time, value]);

  return (
    <Box sx={{ background: "#fff", borderRadius: "15px", display: "flex", flexDirection: "column", gap: "2rem", padding: "1.5rem" }}>
      <div>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "45%", background: "#165983", borderRadius: "0px 0px 0px 0px", padding: "16px", display: "flex", flexDirection: "column", gap: "1.5rem" }}>
            <Box>
              <Typography sx={{ color: "#fff", fontSize: "20px" }}>{value["$d"].getFullYear()}</Typography>
              <Typography></Typography>
              <Typography sx={{ color: "#fff", fontSize: "32px" }}>
                {days[value["$d"].getDay()]}, {months[value["$d"].getUTCMonth()]} {value["$d"].getDate()}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              {console.log("TIMEIEBLkjdsjkfjksdfkjsn", time)}
              <TextField
                type="time"
                value={time}
                InputProps={{
                  sx: {
                    height: "74px",
                    width: "fit-content",
                    m: 0,

                    fontSize: "30px",
                    color: "#fff",
                    borderRadius: "10px 0px 0px 10px",

                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      borderRadius: "5px 5px 0 0",
                    },
                  },
                  endAdornment: null,
                }}
                onChange={(e) => setTime(e.target.value)}
                sx={{
                  background: "#165983",
                  border: "1px solid #fff",
                  borderRight: "none",
                  borderRadius: "15px 0px 0px 15px",
                  '& input[type="time"]::-webkit-calendar-picker-indicator': {
                    filter: "invert(100%) sepia(5%) saturate(2066%) hue-rotate(199deg) brightness(92%) contrast(100%);",
                    color: "#165983",
                  },
                  "& .MuiFilledInput-root": {
                    background: "rgb(232, 241, 250)",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                    borderColor: "none!important",
                  },
                }}
              />
              <Box ox sx={{ display: "flex", flexDirection: "column", gap: "2px", background: "#165983", padding: "5px", border: "1px solid #fff", borderLeft: "none", borderRadius: "0px 15px 15px 0px" }}>
                <Typography onClick={() => handleAmPm("AM")} sx={{ fontSize: "14px", padding: "5px", background: ampm === "AM" ? "#fff" : "#165983", color: ampm === "AM" ? "#165983" : "#fff", "&:hover": { cursor: "pointer" }, borderRadius: "10px" }}>
                  AM
                </Typography>
                <Typography onClick={() => handleAmPm("PM")} sx={{ fontSize: "14px", padding: "5px", background: ampm === "PM" ? "#fff" : "#165983", color: ampm === "PM" ? "#165983" : "#fff", "&:hover": { cursor: "pointer" }, borderRadius: "10px" }}>
                  PM
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "55%" }}>
            <Box sx={{ height: "320px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} views={["year", "month", "day"]} dayOfWeekFormatter={(day) => <Typography sx={{ fontSize: "12px", color: "#165983" }}>{day}</Typography>} disablePast />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>
      </div>

      <Box sx={{ display: "flex", justifyContent: "space-between", gap: "20px", width: "100%" }}>
        <Button sx={{ width: "40%", padding: "8px 15px", borderRadius: "15px", background: "#fff", color: "#165983", border: "1px solid #165983" }} onClick={() => props.handleModalClose()}>
          Cancel
        </Button>
        <Button sx={{ width: "40%", padding: "8px 15px", borderRadius: "15px", background: "#165983", color: "#fff", border: `1px solid #165983`, "&:hover": { background: "#165983", color: "#fff" } }} onClick={() => handleSave()}>
          Save
        </Button>
      </Box>
    </Box>
  );
}

export default Calender;
