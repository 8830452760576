import React from 'react'
import { Box, Typography, Modal, IconButton } from '@mui/material'
import { DivCenter, Color, PrimaryButton, PrimaryButtonOutlined } from '../../../GlobalStyles'
import { Close } from '@mui/icons-material'

const ConfirmationAndValidationModal = (props) => {
    const {open, onClose, handleConfirmation, header, subHeader, primaryButton, secondaryButton} = props
    const modalBoxStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "white",
        padding: "2rem",
        borderRadius: "40px",
        width: "35%",
    };
    return (
        <div>
            <Modal open={open} onClose={onClose}>
                <Box sx={{ ...modalBoxStyle }}>
                    <Box sx={{ ...DivCenter, width: '100%', alignItems: 'space-between', justifyContent: "space-between", marginBottom: "8px", }}>
                        <Typography
                            sx={{
                                fontSize: "20px",
                                // fontWeight: "bold",
                                textAlign:'center',
                                margin:"auto 0"
                            }}
                        >
                            {header ? header : ""}
                        </Typography>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: "normal",
                            marginBottom: "8px",
                        }}
                    >
                        {subHeader ? subHeader : ""}
                    </Typography>
                    <Box
                        sx={{
                            ...DivCenter,
                            alignItems: "flex-start",
                            justifyContent: 'flex-end',
                            flexDirection: "row",
                            width: "100%",
                            mt: "16px",
                        }}
                    >
                        <Box sx={{ ...DivCenter, width: '100%', gap: '16px' }}>
                            {secondaryButton && <PrimaryButtonOutlined
                                variant="outlined"
                                onClick={() => onClose()}
                                sx={{
                                    textTransform: "none",
                                    borderColor: Color.neutralMidGrey,
                                    color: Color.neutralMidGrey,
                                    borderRadius: 0,
                                    boxShadow: "none",
                                    width: "90px",
                                    "&:hover": {
                                        borderColor: Color.neutralMidGrey,
                                        color: Color.neutralMidGrey,
                                    },
                                }}
                            >
                                {secondaryButton ? secondaryButton : ""}
                            </PrimaryButtonOutlined>}
                            
                            <PrimaryButton
                                variant="outlined"
                                onClick={() => handleConfirmation()}
                                sx={{
                                    textTransform: "none",
                                    borderColor: Color.primary1,
                                    background: Color.primary1,
                                    color: Color.white,
                                    borderRadius: 0,
                                    boxShadow: "none",
                                    width: "90px",
                                    "&:hover": {
                                        background: Color.primary1,
                                        borderColor: Color.primary1,
                                        color: Color.white,
                                    },
                                }}>
                                {primaryButton ? primaryButton : ""}
                            </PrimaryButton>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default ConfirmationAndValidationModal
