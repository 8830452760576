import Typography from '@mui/material/Typography';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { Color } from "../../../GlobalStyles";
import ServiceCM from "../../../services/cartmoduleServices";
import CollegeData from '../../../services/httpCollege';
import Service from "../../../services/httpService";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import Loader from '../../reusables/Loader/Loader';
import SideNavigationBar from "../../reusables/SideNavigationBar/SideNavigationBar";
import OngoingEvents from '../OngoingEvents/OngoingEvents';
import '../OngoingEvents/OngoingEvents.css';
import OnGoingGroupSessions from '../OnGoingGroupSessions/OnGoingGroupSessions';
import UpcomingPrograms from "../UpcomingPrograms/UpcomingPrograms";
import { setUser, updateActionRights } from "../../../store/reducers";
import BackendDownErrorHandling from '../../reusables/BackendDownErrorHandling/BackendDownErrorHandling';
const CollegeInfoPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const services = new CCService()
  const service = new Service()
  const cartService = new ServiceCM()
  const collegeService = new CollegeData()
  let college_data_name = location.state?.college_name || ""
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);
  const [college_id, setCollege_id] = useState(null)
  const [college_name, setCollege_name] = useState("")
  console.log("college id", college_id);
  const { userDetails, accessRights } = useSelector((store) => store.rights)
  const [entireGroupSessionData, setEntireGroupSessionData] = useState([])
  const [activityTypeData, setActivityTypeData] = useState([])
  const [durationData, setDurationData] = useState([])
  const [mentorData, setMentorData] = useState([])
  const [collegeData, setCollegeData] = useState([])
  const [batchData, setBatchData] = useState([])
  const [activity_name, setActivity_name] = useState()
  const [college_name1, setCollege_name1] = useState()
  const [duration_name, setDuration_name] = useState()
  const [mentor_name, setMentor_name] = useState()
  const [batch_name, setBatch_name] = useState()
  const [errorOccurred, setErrorOccurred] = useState(false);

  const activityTypeGetter = async () => {

    try {
      const res = await cartService.get('/sku?type=Group')
      console.log("Response from backend for activity name:", res.data)
      setActivityTypeData(res.data)
      return res.data
    } catch (err) {
      console.log(err)
      window.alert(err.response.data.message)
      if (err?.response.status === 501) {
        setErrorOccurred(true);
      }
    }

    console.log("Response activity type data", activityTypeData);
  }

  const DurationGetter = async () => {

    try {
      const res = await service.get('/duration')
      console.log("Response from backend for duration:", res.durations)

      // setDurationData(getTimeFormated(res.durations))
      return res.durations
    } catch (err) {
      console.log(err)
      window.alert(err.response.data.message)
      if (err?.response.status === 501) {
        setErrorOccurred(true);
      }
    }
    console.log("Response duration type data", durationData);

  }

  const CollegeGetter = async () => {

    try {
      const res = await collegeService.get('/college')
      console.log("Response from backend for college:", res.data)
      setCollegeData(res.data)
      return res.data
    } catch (err) {
      console.log(err)
      window.alert(err.response.data.message)
      if (err?.response.status === 501) {
        setErrorOccurred(true);
      }
    }
    console.log(" Response college type data", collegeData);

  }

  const mentorGetter = async () => {

    try {
      const res = await collegeService.get(`${process.env.REACT_APP_API_URL}/mentorModule/mentor/user`)
      console.log("Response from backend for mentor:", res.data)
      setMentorData(res.data)
      return res.data
    } catch (err) {
      console.log(err)
      window.alert(err.response.data.message)
      if (err?.response.status === 501) {
        setErrorOccurred(true);
      }
    }
    console.log("Response mentor type data", mentorData);

  }

  const batchGetter = async () => {

    try {
      const res = await collegeService.get(`${process.env.REACT_APP_API_URL}/collegeModule/userGroup`)
      console.log("Response from backend for batch:", res.data)
      setBatchData(res.data)
      return res.data
    } catch (err) {
      console.log(err)
      window.alert(err.response.data.message)
      if (err?.response.status === 501) {
        setErrorOccurred(true);
      }
    }
    console.log("Response bacth type data", batchData);

  }

  const handleUserDetails = async () => {
    try {
      const preUser = await services.get("/user/userDetail");
      //batch data getter
      return preUser.userDetail
    } catch (err) {
      console.log("Error: ", err)
      window.alert(err.response.data.message)
      if (err?.response.status === 501) {
        setErrorOccurred(true);
      }
    }
  }


  const data_manupulator_activity = (activityType) => {
    console.log("activity type", activityType);
    // let temp = activityType;
    let temp = activityType.filter(act => act.type === "Group");
    temp = temp.map(bat => {
      bat.key = bat.act_type_name;
      bat.id = bat.id;
      bat.type = bat.type
      return bat;
    });
    console.log("temp rows activity:", temp)
    setActivity_name(temp)
    console.log("activity group names", activity_name);
  }
  const data_manupulator_duration = (duration) => {
    console.log("duration name", duration);

    let temp = duration;
    temp = temp?.map(bat => {
      bat.key = bat.duration_name;
      // bat.id = bat.id;
      return bat;
    });
    console.log("temp rows batch:", temp)
    setDuration_name(temp)
  }
  const data_manupulator_mentor = (mentor) => {
    console.log("mentor type", mentor);

    let temp = mentor;
    temp = temp?.map(bat => {
      bat.key = bat.first_name;
      bat.id = bat.user_id;
      return bat;
    });
    console.log("temp rows mentor:", temp)
    setMentor_name(temp)
  }
  const data_manupulator_college = (college) => {
    console.log("college type", college);

    let temp = college;
    temp = temp?.map(bat => {
      bat.key = bat.college_name;
      bat.id = bat.id;
      return bat;
    });
    setCollege_name1(temp)
    console.log("temp rows batch:", temp)

  }

  const data_manupulator_batch = (batch) => {
    console.log("bactyh type", batch);

    let temp = batch;
    temp = temp?.map(bat => {
      bat.key = bat.user_group_name;
      bat.id = bat.id;
      return bat;
    });
    console.log("temp rows batch:", temp)
    setBatch_name(temp)
  }

  // function to get all the group sessions of a user from backend and store it in state variable
  const batchDataGetter = async (user_id) => {
    try {
      const res = await service.get(`/groupSession?ca_user_id=${user_id}`)
      console.log("group session info RESPONSE:", res.data)

      return res.data
    } catch (err) {
      console.log(err)
      window.alert(err.response.data.message)
      if (err?.response.status === 501) {
        setErrorOccurred(true);
      }
    }
  }

  useEffect(() => {
    const handleEffect = async () => {
      const headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
      };
      if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
        navigate('/');
      }
      console.log("headers...", headers);
      try {
        const preUser = await services.get("/collegeAdmin/college", { headers });

        if (preUser.collegDetail.length === 0) {
          Cookies.remove('token');
          navigate('/')
        } else {
          setCollege_id(preUser.collegDetail[0].college_id)
          setCollege_name(preUser.collegDetail[0].college_name)
        }

        const preUserDetails = await handleUserDetails()
        const batchData = await batchDataGetter(preUserDetails.user_id);


        let temp_data = batchData.filter((filtering_info, i) => filtering_info.college_id === parseInt(preUser.collegDetail[0].college_id))
        console.log("temp filtered college data", temp_data);
        setEntireGroupSessionData([...temp_data])

        const activityType = await activityTypeGetter();
        const duration = await DurationGetter();
        const mentor = await mentorGetter()
        const college = await CollegeGetter()
        const batch = await batchGetter()


        // const batchAccordingToCollege = await batchAccordingToCollegeGetter()

        data_manupulator_activity(activityType);
        data_manupulator_duration(duration);
        data_manupulator_mentor(mentor);
        data_manupulator_college(college);
        data_manupulator_batch(batch);
      } catch (err) {
        console.log(err);
        window.alert(err.response.data.message)
        if (err?.response.status === 501) {
          setErrorOccurred(true);
        }
        if (
          err?.response?.status === 401 &&
          err?.response?.data.message === "unauthorized"
        ) {
          console.log(err);
          navigate('/')
        }
      }
    }
    setLoading(false);
    handleEffect()

  }, [])
  console.log("college id", college_id);
  console.log("college name", college_name);
  return (
    <>
      {errorOccurred ? <BackendDownErrorHandling /> :
        <div className='OngoingEventsCard' style={{ marginTop: "2rem", marginLeft: "10rem" }}>
          <SideNavigationBar college_id={college_id} college_name={college_name} />
          {loading ? (
            <Loader show={loading} />
          ) : (<>
            <div className="topHeadings" style={{ display: "flex" }}>
              <p style={{ color: Color.neutralMidGrey, marginBottom: "1rem" }} onClick={() => navigate("/CollegeInfoPage")}>{college_name}</p>
            </div>
            {college_id && college_name && (
              <>
                <Typography style={{ marginBottom: "2rem" }} sx={{ fontSize: 20 }}>Ongoing Events</Typography>
                <Typography class="topHeadings"  >{college_data_name}</Typography>
                <OngoingEvents college_id={college_id} college_name={college_name} />
                <UpcomingPrograms college_id={college_id} college_name={college_name} />
                <OnGoingGroupSessions activityTypeData={activityTypeData} durationData={durationData} mentorData={mentorData} collegeData={collegeData} batchData={batchData} entireGroupSessionData={entireGroupSessionData} path={window.location.pathname} />
              </>
            )}
          </>)}
        </div>}

    </>
  )
}

export default CollegeInfoPage
