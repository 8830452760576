import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Color } from '../../../GlobalStyles'
import '../OngoingEvents/OngoingEvents.css'
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import Service from "../../../services/httpService";
import ActivatedStatus from '../ActivatedStatus/ActivatedStatus'
export default function UpcomingPrograms(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const collegeData = new Service()
  const withoutSuperAdmin = new CCService()
  const {college_id, college_name} = props
  console.log("college_name", college_name);
  // let college_id = location.state?.college_id
  // let college_name = location.state?.college_name || ""
  const [BatchData, setBatchData] = useState([])


  useEffect(() => {
    console.log("Brother")
    const handleEffect = async () => {

      if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
        navigate('/');
      }
      //batch data getter
      const batchData = await batchDataGetter();

      console.log("odonil", { batchData })

    }

    handleEffect();
  }, [])

  const batchDataGetter = async () => {
    const headers = {
      Authorization: `Bearer ${Cookies.get("token")}`,
    };
    try {
      const res = await withoutSuperAdmin.get("/clgAdmin/Program",{ headers })
      console.log("1x1 data RESPONSE:", res.data)
      setBatchData([...res.data])
      return res.data
    } catch (err) {
      console.log(err)
    }
  }
  console.log("batch data", BatchData);

  const handle1x1BatchDetails = (batchName, batchId) => {
    navigate("/College1x1BatchDetails?batchId=" + batchId, { state: { college_id: college_id, college_name: college_name, batchName: batchName, batchId: batchId } })
  }
  const handleActivatedStatusDetails = (batchName, batchId) => {
    navigate("/ActivatedStatus", { state: { college_id: college_id, college_name: college_name, batchName: batchName, batchId: batchId } })
  }

  return (
    <div className='OngoingEventsCard' style={{  marginTop: "2rem" }}>
      <Typography style={{ marginBottom: "2rem" }} sx={{fontSize: 20}}>Programs</Typography>
      <div className="cardsInsideFlex">
      {BatchData.map((batchInfo, i) => {
        return (
          <Box key={i} sx={{ width: 255,  marginRight: '1rem' ,boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)", borderRadius: "10px"}}>
          <CardContent className="cardContainer" disableGutters>
            <Typography variant="body2" sx={{ fontSize: 20 }} gutterBottom>
              {batchInfo?.program_name}
            </Typography>
            <Typography variant="h5" component="div"></Typography>
            <div className="container">
              <Typography variant="body2" color={Color.primary1}>Activated Students:</Typography>
              <Typography variant="body2" class="providingSpaceForSubDetails">{batchInfo?.activated_students}</Typography>
            </div>
            <div className="container">
              <Typography variant="body2" color={Color.primary1}>Not Activated Students:</Typography>
              <Typography variant="body2" class="providingSpaceForSubDetails">{batchInfo?.non_activated_students}</Typography>
            </div>
            <CardActions sx={{ paddingBottom: "0px" }}>
              <button
                className="submitBtn"
                type="button"
                style={{ height: "44px" }}
                onClick={() => handle1x1BatchDetails(batchInfo?.program_name, batchInfo?.program_id)}
              >
                View 1x1
              </button>
              
            </CardActions>
            <CardActions sx={{ paddingBottom: "0px" }}>
              <button
                className="submitBtn"
                type="button"
                style={{ height: "44px" }}
                // onClick={() => handle1x1BatchDetails(batchInfo?.program_name, batchInfo?.id)}
                onClick={() => handleActivatedStatusDetails(batchInfo?.program_name, batchInfo?.program_id)}
              >
                Student Activation Status
              </button>
              
            </CardActions>

          </CardContent>
      </Box>)
      })}
</div>
    </div>
  )
}

