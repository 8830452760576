
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// import Existingemail from '../Existingemail/Existingemail';
import axios from "axios";
import { useSearchParams, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, OAuthProvider, signInWithCredential, signInWithRedirect, getRedirectResult } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import Service from '../../services/httpService';
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

export default function SetPassword() {
  const service = new Service()
  const errRef = useRef();
  const initialValues = {password: "", confirmPwd: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [errMsg, setErrMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(show => !show);
  const [isLoading, setIsLoading] = useState(false);

  //   const [isLoading, setIsLoading] = useState(false);
  // const {email, token} = useParams();
  const { email, token } = useParams();
  console.log({email});
  console.log({token});
  const googleButtonWidth = 400;
  const text_alignment = "center";
  const clientId = '331878265619-n1b1ia44qjlp90amsdg2trohjqt9m51o.apps.googleusercontent.com';
  const firebaseConfig = {
    apiKey: "AIzaSyBbrNdmcQY1vAhXy3Zg0gHUJ3zWJCuAbRI",
    authDomain: "careercarvelogin.firebaseapp.com",
    projectId: "careercarvelogin",
    storageBucket: "careercarvelogin.appspot.com",
    messagingSenderId: "151843323088",
    appId: "1:151843323088:web:000a09c7a3d8067ae072d5"
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors("");
  };

  console.log("form inputs", formValues);

  const navigate = useNavigate();
  const handleNavToLogin = () => {
    navigate(`${"/Login"}`);
  }


  const handleSubmit = async (e) => {
    console.log("Form values now are:", formValues);
    e.preventDefault();
    // const formError = validateConfirmPassword(formValues)
    // setFormErrors(formError);
    setIsSubmit(true);
    console.log("Handling form submission");
    // if (Object.keys(formError).length === 0) {
    try {
      console.log("email is:", email);
      console.log("token is ", token);
      console.log("password is ", formValues.password.toString());
      console.log(formValues.password);
      setLoader(true);
      await axios.post(`${process.env.REACT_APP_API_URL}/newPassword`, { 'email': email.substring(1).toString(), 'token': token.substring(1).toString(), 'password': formValues.password.toString() })

        .then(res => {
          console.log(res);
          console.log(res.data.message);
          setLoader(false);

          if (res.status === 200) {
            Cookies.set('token', res.headers.jwt, { expires: 182.5 });
            navigate('/');
          }
        })

    } catch (err) {
      console.log(2);
      console.log(err);

      setLoader(false);

      setErrMsg(err.response.data.message);

    }

  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }

  }, []);

   // handle sign in click
   async function handleSignInClick() {
    setIsLoading(true);
    try {
      const result = await signInWithPopup(
        auth,
        new OAuthProvider("apple.com")
      );
      // The signed-in user info.
      const user = result?.user;
      console.log("apple user deatils", user);
      // Apple credential
      const credential = OAuthProvider?.credentialFromResult(result);
      const accessToken = credential.accessToken;
      console.log("apple accessToken deatils", accessToken);
      console.log("user name", result.user.displayName);
      console.log("user email", result.user.email);

      const idToken = credential.idToken;
      console.log("apple idToken deatils", idToken);
      if (idToken !== null && idToken !== undefined) {
        if (result.user.email !== null && result.user.email !== undefined) {
          const res = await service.post(`/appleAuth`, {
            id_token: idToken,
            signup_platform: "CareerCarve College Admin",
          });

          if (res.status === 200) {
            Cookies.set("token", res.headers.jwt, { expires: 182.5 });
            Cookies.set("user_type", res.data.user_type, { expires: 182.5 });
            console.log("user type right now at login", res.data.user_type);
            navigate("/CollegeInfoPage", {
              state: {
                formValues: formValues,
              },
            });
            // alert.box(<h1>DASHBOARD</h1>);
            console.log("Dashboard");
            setErrMsg("Login successful.");
            const cookies = res.headers.jwt;
            console.log({ cookies });
          }
          console.log(res);
        } else {
          if (result.user.email === null || result.user.email === undefined) {
            toast.error("Email ID is required ");
          }
        }
      }
    } catch (err) {
      if (
        err?.response.status === 400 &&
        err?.response.data.message === "Unverified account"
      ) {
        setErrMsg("This email is Unverified.");
      } else if (
        err?.response.status === 400 &&
        err?.response.data.message ===
        "Seems like your account dosn't exist with us. Please Signup using CareerCarve App."
      ) {
        // setChangeTemplate(true);
        setErrMsg(
          "Seems like your account doesn't exist with us. Please Signup using CareerCarve App."
        );
      } else if (
        err?.response.status === 400 &&
        err?.response.data.message === "Alternate email already existing."
      ) {
        setErrMsg("Alternate email already existing.");
      } else if (
        err?.response.status === 400 &&
        err?.response.data.message === "Email already exists as alternate."
      ) {
        setErrMsg(
          "This email already exists as an secondary email. Please login with your primary email."
        );
      } else if (
        err?.response.status === 500 &&
        err?.response.data.message === "Alternate email entry doesn't exists"
      ) {
        setErrMsg("Alternate email entry doesn't exists.");
      }else if (
        err?.response.status === 500 &&
        err?.response.data.message === "Unknown Error Occurred"
      ) {
        setErrMsg(<p>You are not a mentor</p>);
      }
      console.log("apple sign in error", err);
      const errorCode = err.code;
      console.log("apple sign in error", errorCode);

      const errorMessage = err.message;
      console.log("apple sign in error", errorMessage);

      const email = err.customData.email;
      console.log("apple sign in email", email);

      const credential = OAuthProvider.credentialFromError(err);
      console.log("apple sign in credential", credential);
    } finally {
      setIsLoading(false);
    }
  }

  return (


    <div className="login-Container">
      <div className="login-Resumes_Image">
        <img
          className="imageFlexible"
          src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/backgroundImage.png"
          alt="background"
          style={{
            backgroundRepeat: "none",
            float: "left",
            margin: "0",
            width: "100%",
            height: "100%",
            position: "static",
          }}
        />
        <img
          className="imageFlexible"
          src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/logo_welcome.png"
          alt="background"
          style={{
            position: "absolute",
            height: "150px",
            width: "150px",
            top: "max(30%,200px)",
            left: "20%",
          }}
        />
        <img
          src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/CareerCarve.png"
          alt="background"
          style={{
            position: "absolute",
            top: "max(52%,350px)",
            left: "13%",
          }}
        />
        <p
          style={{
            position: "absolute",
            top: "max(62%, 430px)",
            left: "13.5%",
            fontSize: "20px",
          }}
        >
          {" "}
          Placement | Preparedness | Perfected
        </p>
      </div>

      <div className="login-Main-content">
        <p className="login-name">Set Password</p>
        <div className="signup-l-form" style={{ backgroundColor: 'white', width: '100%', height: '100%', }} >
          <div className="login-l-form">
            <form action="" class="login-form">
              <p className='pwr-have-account-descr'>Welcome to CareerCarve! Kindly set your password for the account or continue with google/apple</p>
              <div className="outlined-basic">
                <TextField
                  className=""
                  onChange={handleChange}
                  value={email.substring(1)}
                  name="email"
                  disabled
                  id="outlined-basic"
                  label="Email "
                  variant="outlined"
                  sx={{ width: 400, mb: 3 }}
                  InputProps={{ sx: { height: 50.4 } }}
                  style={{
                    marginTop: "20px",
                    textAlign: "center",
                    borderRadius: "5px",
                  }}
                />

                <FormControl
                  sx={{ width: 400, mb: 3 }}
                  InputProps={{ sx: { height: 50.4 } }}
                  variant="outlined"
                  name="Password"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    onChange={handleChange}
                    value={formValues.password}
                    id="outlined-adornment-password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <br />

                <FormControl
                  sx={{ width: 400, mb: 3 }}
                  InputProps={{ sx: { height: 50.4 } }}
                  variant="outlined"
                  name="confirmPwd"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    onChange={handleChange}
                    value={formValues.confirmPwd}
                    id="outlined-adornment-password"
                    name="confirmPwd"
                    type={showConfirmPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="confirmPwd"
                  />
                </FormControl>

                <button
                  className="Login-button"
                  style={{
                    textAlign: "center",
                    marginBottom: "1.5rem",
                    width: "400px",
                  }}
                  onClick={e => handleSubmit(e)}
                >
                  Submit
                </button>

                <div>
                  <p
                    ref={errRef}
                    className="alert-errMsg-button"
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                </div>

                <div className="loginOrlogin">
                  <div className="line-login"></div>
                  <p
                    style={{
                      marginTop: "0",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  >
                    or
                  </p>
                  <div className="line-login"></div>
                </div>
              </div>

              <div>
                <div className="signInButton" id="signInGoogleButton">
                  <GoogleOAuthProvider
                    width={googleButtonWidth}
                    clientId={clientId}
                    text="Login with Google"
                  >
                    <GoogleLogin
                      width={googleButtonWidth}
                      text="Login with Google"
                      logo_alignment={text_alignment}
                      style={{ border: "1px solid #545454" }}
                      onSuccess={async credentialResponse => {
                        console.log("Google Auth Sucess.");
                        console.log(
                          "token that is sent to bcknd:",
                          credentialResponse.credential
                        );
                        console.log(
                          "token that is sent to bcknd:",
                          jwt_decode(credentialResponse.credential)
                        );
                        try {
                          const response = await service.post(`/googleAuth`, {
                            id_token: credentialResponse.credential,
                            signup_platform: "CareerCarve College Admin",
                          });
                          console.log({ response });

                          if (response.status === 200) {
                            Cookies.set("token", response.headers.jwt, {
                              expires: 182.5,
                            });
                            Cookies.set("user_type", response.data.user_type, {
                              expires: 182.5,
                            });
                            console.log(
                              "user type right now at login",
                              response.data.user_type
                            );
                            const cookies = response.headers.jwt;

                            navigate('/CollegeInfoPage');
                            // alert.box(<h1>DASHBOARD</h1>);
                            console.log("Dashboard");
                            setErrMsg("Login successful.");
                            console.log({ cookies });
                          }
                        } catch (err) {
                          console.log(err);
                          if (
                            err?.response.status === 400 &&
                            err?.response.data.message === "Unverified account"
                          ) {
                            setErrMsg("This email is Unverified.");
                          } else if (
                            err?.response.status === 400 &&
                            err?.response.data.message ===
                            "Alternate email already existing."
                          ) {
                            setErrMsg("Alternate email already existing.");
                          } else if (
                            err?.response.status === 400 &&
                            err?.response.data.message ===
                            "Seems like your account dosn't exist with us. Please Signup using CareerCarve App."
                          ) {
                            // setChangeTemplate(true);
                            setErrMsg(
                              "Seems like your account doesn't exist with us. Please Signup using CareerCarve App."
                            );

                            // console.log("sdfghjkl", changeTemplate);
                          } else if (
                            err?.response.status === 400 &&
                            err?.response.data.message ===
                            "Email already exists as alternate."
                          ) {
                            setErrMsg(
                              "This email already exists as an secondary email. Please login with your primary email."
                            );
                          } else if (
                            err?.response.status === 500 &&
                            err?.response.data.message ===
                            "Alternate email entry doesn't exists"
                          ) {
                            setErrMsg("Alternate email entry doesn't exists.");
                          }
                        }
                      }}
                      onError={() => {
                        window.alert("Login Failed");
                      }}
                    />
                  </GoogleOAuthProvider>
                </div>
              </div>

              <div
                style={{
                  marginTop: "2rem",
                  textAlign: "center",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              >
                <button
                  className="apple-sign-up-button"
                onClick={handleSignInClick}
                disabled={isLoading}
                >
                  <img className="appleIcon" src="apple.svg" />
                  Sign in with Apple
                </button>
              </div>
            </form>
          </div>

        </div>

      </div>

    </div>
  );
}