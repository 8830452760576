import React, { useState, useEffect } from "react";
import LeftBar from "./LeftBar/LeftBar";
import { Box, Button, IconButton } from "@mui/material";
import { DivCenter, Color } from "../../../GlobalStyles";
import POBasicInfo from "./BasicInfo/POBasicInfo";
import POOpportunityInfo from "./OpportunityInfo/POOpportunityInfo";
import POEligibility from "./EligibilityInfo/POEligibility";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import TimeService from "../../../services/epochTime";
import { ArrowBackIos } from "@mui/icons-material";
import Loader, { ActivityLoader } from "../../reusables/Loader/Loader";
import { get_specialCharReplace, send_specialCharReplace } from "../../../services/specialCharReplace";
import { Validations } from "../Validations/Validations";
const PostingOpportunity = () => {
  const services = new CCService();
  const timeServices = new TimeService();
  const location = useLocation();

  const querry = new URLSearchParams(location.search);
  const college_id = querry.get("college_id");

  const navigate = useNavigate();
  const [loading, setLoading] = useState({ page_loader: false, activity_loader: false });
  const [formErrors, setFormErrors] = useState({});
  const [isDreamRole, setIsDreamRole] = useState(false);
  //states
  const { batch_id, passed_postedJob, newJob } = location.state;
  const [postedJob, setPostedJob] = useState({ ...passed_postedJob });
  const [dreamRoleError, setDreamRoleError] = useState({});

  const [stepper, setStepper] = useState({ index: 0 });
  const [job_id, setJob_id] = useState(postedJob ? postedJob.po_job_id : null);
  const [BatchData, setBatchData] = useState([]);
  const [imageURL, setImageURL] = useState("");
  const [allDreamRoles, setAllDreamRoles] = useState([]);
  const [selectedDreamRoles, setSelectedDreamRoles] = useState({
    api_url: null,
    logic_id: null,
    field_name: "",
    logic_name: "",
    description: "",
    data_structure: {
      x: null,
      salary: "",
    },
    field_logic_id: null,
  });
  //DATA ~ states
  const [companyLogoFile, setCompanyLogoFile] = useState();
  const [allCompanies, setAllCompanies] = useState([]);
  const [basicInfoData, setBasicInfoData] = useState({
    designation: get_specialCharReplace(postedJob.designation) ?? "",
    company_name: get_specialCharReplace(postedJob.company_name) ?? "",
    company_id: postedJob.company_id,
    company_image: postedJob.image_url,
    company_location: get_specialCharReplace(postedJob.company_location) ?? [],
    date_of_visit: timeServices.convertEpochToDateString(postedJob.date_of_visit) ?? null,
  });
  const [eligibilityInfoData, setEligibilityInfoData] = useState({
    skills: postedJob.skills ? postedJob.skills.skills : [],
    eligibility_description: get_specialCharReplace(postedJob.eligibility_description) ?? "",
    eligibility_criteria: postedJob.eligibility_criteria ?? [],
    image_url: imageURL ?? "",
  });

  const [opportunityInfo, setOpportunityInfo] = useState({
    placement_type: postedJob.placement_type ?? "",
    job_mode: postedJob.job_mode ?? "",
    sector_id: postedJob.sector_id ?? null,
    job_description: get_specialCharReplace(postedJob.job_description) ?? "",
    currency: postedJob?.currency ?? "INR",
    min_ctc: postedJob.min_ctc ?? "",
    max_ctc: postedJob.max_ctc ?? "",
    interval: ![null, "", undefined].includes(postedJob.interval) ? postedJob.interval : "Per Annum",
    interval_per_year: ![null, "", undefined].includes(postedJob.interval_per_year) ? postedJob.interval_per_year : 1,
    other_incentive: postedJob.other_incentive ?? "",
    other_docs: [],
    is_unpaid: 0,
    image_url: imageURL ?? "",
  });

  useEffect(() => {
    const handleEffect = async () => {
      if ([null, undefined].includes(Cookies.get("token"))) {
        navigate("/");
      } else {
        setLoading((prev) => ({ ...prev, page_loader: true }));
        const batchData = await batchDataGetter();
        const allDreamRoles = await services.get("/po/ca/dreamRole/criteria");

        const companies = await services.get(`/po/ca/applications/company`);

        setAllCompanies(companies.data);

        setAllDreamRoles(allDreamRoles.DroleCriterias);
        setIsDreamRole([null, undefined].includes(passed_postedJob.dream_role_criteria) ? false : true);

        if (![null, undefined].includes(passed_postedJob.dream_role_criteria)) {
          setSelectedDreamRoles(passed_postedJob.dream_role_criteria);
        }

        setBatchData(batchData);
        if (newJob === "newJob") {
        } else {
          pageDecider();
        }
        if (postedJob.po_job_id) {
          const response_posted_job = await OpportunityReviewDataGetter(postedJob.po_job_id);
        }
        setLoading((prev) => ({ ...prev, page_loader: false }));
      }
    };
    handleEffect();
  }, []);

  const OpportunityReviewDataGetter = async (job_id) => {
    try {
      const res = await services.get(`/po/ca/applications?po_job_id=${job_id}`);

      return res.applications[0];
    } catch (err) {
      console.log("Error getting applications:", err);
    }
  };

  const pageDecider = () => {
    const check = parseInt(postedJob.last_page);
    console.log("check if number", check);
    if (![null, undefined].includes(check)) {
      if (postedJob) setStepper((prev) => ({ ...prev, index: typeof check === "number" ? check + 1 : 0 }));
    } else {
      navigate(`/JobsPostingPage?batch_id=${batch_id}&job_id=${job_id}`, { state: { batch_id: batch_id } });
    }
  };

  const batchDataGetter = async () => {
    const headers = {
      Authorization: `Bearer ${Cookies.get("token")}`,
    };
    try {
      const res = await services.get("/po/ca/batches", { headers });
      return res.batches;
    } catch (err) {}
  };

  const handleNextClick = async () => {
    let put_body = {};
    let temp_posted_job = {};
    setLoading((prev) => ({ ...prev, activity_loader: true }));

    //post or put
    if (stepper.index === 0) {
      let formError = Validations(basicInfoData);
      setFormErrors(formError);
      console.log({ formError });

      if (postedJob.po_job_id) {
        if (Object.keys(formError).length === 0) {
          try {
            let put_body = {
              po_job_id: postedJob.po_job_id,
              designation: send_specialCharReplace(basicInfoData.designation),
              // company_name: send_specialCharReplace(basicInfoData.company_name),
              company_id: basicInfoData.company_id,
              company_location: basicInfoData.company_location,
              ...(!["", null].includes(basicInfoData.date_of_visit) && {
                date_of_visit: timeServices.convertDateToEpoch(basicInfoData.date_of_visit),
              }),

              // "last_page": "0"
            };
            if (![null, undefined].includes(basicInfoData.company_image)) {
              put_body = {
                ...put_body,
                image_url: basicInfoData.company_image,
              };
            }
            await services.put(`/po/ca/applications`, put_body);
            setPostedJob((prev) => ({
              ...prev,
              po_job_id: postedJob.po_job_id,
              designation: basicInfoData.designation,
              company_id: basicInfoData.company_id,
              company_name: basicInfoData.company_name,
              company_location: basicInfoData.company_location,
              ...(!["", null].includes(basicInfoData.date_of_visit) && {
                date_of_visit: basicInfoData.date_of_visit,
              }),
              image_url: imageURL ?? null,
            }));
            //change page
            setStepper((prev) => ({ ...prev, index: prev.index + 1 }));
          } catch (error) {
            console.error("Error while posting job/basic_info:", error);
          }
        }
      } else {
        let formError = Validations(basicInfoData);
        setFormErrors(formError);
        console.log({ formError });
        if (Object.keys(formError).length === 0) {
          try {
            let post_body = {
              ug_id: batch_id,
              designation: send_specialCharReplace(basicInfoData.designation),
              // company_name: send_specialCharReplace(basicInfoData.company_name),
              company_id: basicInfoData.company_id,
              company_location: basicInfoData.company_location,
              ...(!["", null].includes(basicInfoData.date_of_visit) && {
                date_of_visit: timeServices.convertDateToEpoch(basicInfoData.date_of_visit),
              }),

              last_page: "0",
            };
            if (![null, undefined].includes(basicInfoData.company_image)) {
              post_body = {
                ...post_body,
                image_url: basicInfoData.company_image,
              };
            }
            const post_response = await services.post(`/po/ca/applications`, post_body);
            setJob_id(post_response.data.po_job_id);
            setPostedJob((prev) => ({
              ...prev,
              designation: basicInfoData.designation,
              company_name: basicInfoData.company_name,
              company_location: basicInfoData.company_location,
              ...(!["", null].includes(basicInfoData.date_of_visit) && {
                date_of_visit: basicInfoData.date_of_visit,
              }),
              last_page: "0",
              po_job_id: post_response.data.po_job_id,
            }));
            //change page
            setStepper((prev) => ({ ...prev, index: prev.index + 1 }));
          } catch (error) {
            console.error("Error while posting job/basic_info:", error);
          }
        }
      }
    } else {
      let formError = {};
      if (stepper.index === 1) {
        put_body = {
          po_job_id: job_id,
          ...(imageURL !== "" && {
            image_url: imageURL,
          }),
          ...(eligibilityInfoData?.skills?.length > 0 && {
            skills: eligibilityInfoData.skills,
          }),
          ...(eligibilityInfoData?.eligibility_description !== "" && {
            eligibility_description: send_specialCharReplace(eligibilityInfoData.eligibility_description),
          }),
          eligibility_criteria: eligibilityInfoData.eligibility_criteria,
          last_page: "1",
        };
        if (isDreamRole) {
          if ([28, 29].includes(selectedDreamRoles.field_logic_id)) {
            if ([null, undefined, ""].includes(selectedDreamRoles.data_structure.x)) {
              formError = {
                ...formError,
                X: "'X' is required",
              };
            }

            if ([null, undefined, ""].includes(selectedDreamRoles.data_structure.salary)) {
              formError = {
                ...formError,
                salary: "'Salary' is required",
              };
            }
          }
          setFormErrors(formError);
          put_body = {
            ...put_body,
            dream_role_criteria: { ...selectedDreamRoles, description: selectedDreamRoles.field_name.replace("X", selectedDreamRoles.data_structure.x).replaceAll("salary", selectedDreamRoles.data_structure.salary) },
          };
        }
        temp_posted_job = {
          ...(imageURL !== "" && {
            image_url: imageURL,
          }),
          ...(eligibilityInfoData?.skills?.length > 0 && {
            skills: eligibilityInfoData.skills,
          }),
          ...(eligibilityInfoData?.eligibility_description !== "" && {
            eligibility_description: eligibilityInfoData.eligibility_description,
          }),
          eligibility_criteria: eligibilityInfoData.eligibility_criteria,
          last_page: "1",
        };
      } else if (stepper.index === 2) {
        formError = Validations(opportunityInfo);
        setFormErrors(formError);

        put_body = {
          po_job_id: job_id,
          ...(imageURL !== "" && {
            image_url: imageURL,
          }),
          placement_type: opportunityInfo.placement_type,
          ...(opportunityInfo.job_mode !== "" && {
            job_mode: opportunityInfo.job_mode,
          }),
          ...(opportunityInfo.sector_id !== null && {
            sector_id: opportunityInfo.sector_id,
          }),
          job_description: send_specialCharReplace(opportunityInfo.job_description),
          ...(opportunityInfo?.currency !== "" && {
            currency: opportunityInfo?.currency,
          }),
          ...(opportunityInfo.min_ctc !== "" && {
            min_ctc: parseFloat(opportunityInfo.min_ctc) * opportunityInfo.interval_per_year,
          }),
          ...(opportunityInfo.max_ctc !== "" && {
            max_ctc: parseFloat(opportunityInfo.max_ctc) * opportunityInfo.interval_per_year,
          }),
          ...(opportunityInfo.interval !== "" && {
            interval: opportunityInfo.interval,
            interval_per_year: opportunityInfo.interval_per_year,
          }),
          ...(opportunityInfo.other_incentive !== "" && {
            other_incentive: opportunityInfo.other_incentive,
          }),
          is_unpaid: opportunityInfo.is_unpaid,
          last_page: "2",
          other_docs: opportunityInfo.other_docs,
          application_complete: 1,
        };
        temp_posted_job = {
          ...(imageURL !== "" && {
            image_url: imageURL,
          }),
          placement_type: opportunityInfo.placement_type,
          ...(opportunityInfo.job_mode !== "" && {
            job_mode: opportunityInfo.job_mode,
          }),
          ...(opportunityInfo.sector_id !== null && {
            sector_id: opportunityInfo.sector_id,
          }),
          job_description: opportunityInfo.job_description,
          ...(opportunityInfo?.currency !== "" && {
            currency: opportunityInfo?.currency,
          }),
          ...(opportunityInfo.min_ctc !== "" && {
            min_ctc: opportunityInfo.min_ctc,
          }),
          ...(opportunityInfo.max_ctc !== "" && {
            max_ctc: opportunityInfo.max_ctc,
          }),
          ...(opportunityInfo.interval !== "" && {
            interval: opportunityInfo.interval,
          }),
          ...(opportunityInfo.other_incentive !== "" && {
            other_incentive: opportunityInfo.other_incentive,
          }),
          is_unpaid: opportunityInfo.is_unpaid,
          last_page: "2",
          other_docs: opportunityInfo.other_docs,
          application_complete: 1,
        };
      }

      if (Object.keys(formError).length === 0) {
        try {
          const put_response = await services.put(`/po/ca/applications`, put_body);

          setPostedJob((prev) => ({ ...prev, ...temp_posted_job }));
          //change page
          setStepper((prev) => ({ ...prev, index: prev.index + 1 }));
        } catch (error) {
          console.error("Error while putting job/basic_info:", error);
        }
      } else {
        setDreamRoleError({});
      }
    }
    setLoading((prev) => ({ ...prev, activity_loader: false }));
  };

  return (
    <>
      {loading.page_loader ? (
        <Loader />
      ) : (
        <Box sx={{ display: "flex" }}>
          <ActivityLoader show={loading.activity_loader} />
          <Box sx={{ width: "30%" }}>
            <LeftBar page_stepper={stepper.index} setStepperChange={setStepper} fetchedImage={basicInfoData.company_image} job_id={job_id} setImageURL={setImageURL} postedJob={postedJob} basicInfoData={basicInfoData} allCompanies={allCompanies} />
          </Box>

          <Box sx={{ ...DivCenter, flexDirection: "column", width: "70%", height: "100vh", overflowY: "auto", overflowX: "hidden" }}>
            <Box sx={{ ...DivCenter, width: "100%", height: "90%" }}>{stepper.index === 0 ? <POBasicInfo basicInfoData={basicInfoData} setBasicInfoData={setBasicInfoData} formErrors={formErrors} allCompanies={allCompanies} college_id={college_id} setAllCompanies={setAllCompanies} /> : stepper.index === 1 ? <POEligibility postedJob={postedJob} job_id={job_id} BatchData={BatchData} basicInfoData={basicInfoData} setBasicInfoData={setBasicInfoData} eligibilityInfoData={eligibilityInfoData} setEligibilityInfoData={setEligibilityInfoData} batch_id={batch_id} formErrors={formErrors} allDreamRoles={allDreamRoles} setSelectedDreamRoles={setSelectedDreamRoles} selectedDreamRoles={selectedDreamRoles} setIsDreamRole={setIsDreamRole} isDreamRole={isDreamRole} /> : stepper.index === 2 ? <POOpportunityInfo opportunityInfo={opportunityInfo} setOpportunityInfo={setOpportunityInfo} basicInfoData={basicInfoData} setBasicInfoData={setBasicInfoData} batch_id={batch_id} last_page={postedJob.last_page} formErrors={formErrors} /> : navigate(`/POReview?batch_id=${batch_id}&job_id=${job_id}`, { state: { postedJob: postedJob, job_id: job_id, batch_id: batch_id } })}</Box>
            {/* BOTTOM BAR - Back and Next */}
            <Box
              sx={{
                ...DivCenter,
                display: "flex",
                justifyContent: "center",
                width: "100%",
                height: "10%",
                gap: "48px",
                boxShadow: "0px -10px 10px -10px rgba(0, 0, 0, 0.2)",
              }}
            >
              {stepper.index !== 0 && (
                <IconButton sx={{ ...DivCenter, width: "44px", height: "44px", color: Color.primary_gradient }} onClick={() => setStepper((prev) => ({ ...prev, index: prev.index - 1 }))}>
                  <ArrowBackIos sx={{ color: Color.primary1 }} />
                </IconButton>
              )}
              <Button
                sx={{
                  width: "223px",
                  height: "44px",
                  background: Color.primary_gradient,
                  color: Color.white,
                  textTransform: "none",
                  "&:hover": {
                    background: Color.primary_gradient,
                    color: Color.white,
                  },
                }}
                onClick={() => handleNextClick()}
              >
                Next : {stepper.index === 0 ? "Eligibility" : stepper.index === 1 ? "Opportunity Info" : stepper.index === 2 ? "Review" : ""}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PostingOpportunity;
