export function Validations(values) {
  let errors = {};
  console.log("VALUESSS", values);
  if (values.designation === "") errors.designation = "Designation cannot be empty";
  // if (values?.company_id === null) errors.company_name = "Company cannot be empty";
  // if (values?.company_location.length === 0) errors.company_location = "Company Location cannot be empty";
  if (values.placement_type === "") errors.placement_type = "Placement Type cannot be empty";
  if (values.job_description === "") errors.job_description = "Job Description cannot be empty";
  if (values.is_unpaid !== 1 && values.is_unpaid !== 2) {
    if (values.min_ctc === "") {
      errors.min_ctc = "Min CTC cannot be empty";
    }

    if (values.interval === "") {
      errors.interval = "Interval cannot be empty";
    }
  }
  return errors;
}
