// A function to replace special characters in a string for safe transmission or storage
import { Color } from "../GlobalStyles";
export const send_specialCharReplace = (text) => {
  console.log("text:", text);
  let replacedText;
  if (![null, undefined].includes(text)) {
    if (typeof text === "string") {
      replacedText = text
        .replace(/"/g, "%%") // Replace all double quotes (") with the string "%%"
        .replace(/'/g, "@@") // Replace all single quotes (') with the string "@@"
        // .replace(/\n/g, "||n") // Replace all newlines (\n) with the string "||n"
        .replace(/\//g, "[?]") // Replace all forward slashes (/) with the string "[?]"
        .replace(/\\/g, "||"); // Replace all backslashes (\) with the string "||"
    } else if (Array.isArray(text)) {
      replacedText = text.map((each) => {
        return (
          each
            ?.replace(/"/g, "%%") // Replace all double quotes (") with the string "%%"
            .replace(/'/g, "@@") // Replace all single quotes (') with the string "@@"
            // .replace(/\n/g, "||n") // Replace all newlines (\n) with the string "||n"
            .replace(/\//g, "[?]") // Replace all forward slashes (/) with the string "[?]"
            .replace(/\\/g, "||")
        ); // Replace all backslashes (\) with the string "||"
      });
    } else if (typeof text === "number") return text;
    console.log("replacedText:", replacedText);
    // Return the modified string or array of strings
    return replacedText;
  } else {
    return text;
  }
};

export const get_specialCharReplace = (text) => {
  console.log("text:", text);
  if (![null, undefined].includes(text)) {
    let replacedText;
    if (typeof text === "string") {
      replacedText = text
        ?.replace(/%%/g, '"') // Replace all occurrences of the string "%%" with double quotes (")
        .replace(/@@/g, "'") // Replace all occurrences of the string "@@" with single quotes (')
        .replace(/\|\|n/g, "\n") // Replace all occurrences of the string "||n" with a newline (\n)
        .replace(/\[\?\]/g, "/") // Replace all occurrences of the string "[?]" with a forward slash (/)
        .replace(/\|\|/g, "\\"); // Replace all occurrences of the string "||" with a backslash (\)
    } else if (Array.isArray(text)) {
      replacedText = text.map((each) => {
        return each
          ?.replace(/%%/g, '"') // Replace all occurrences of the string "%%" with double quotes (")
          .replace(/@@/g, "'") // Replace all occurrences of the string "@@" with single quotes (')
          .replace(/\|\|n/g, "\n") // Replace all occurrences of the string "||n" with a newline (\n)
          .replace(/\[\?\]/g, "/") // Replace all occurrences of the string "[?]" with a forward slash (/)
          .replace(/\|\|/g, "\\"); // Replace all occurrences of the string "||" with a backslash (\)
      });
    } else return text;
    console.log("replacedText:", replacedText);
    // Return the modified string or array of strings
    return replacedText;
  } else {
    return text;
  }
};



export const formatConvertermonthfirst = (date, date_month_year=false) => {
  const monthNames = ["Januray", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  // console.log("what might the date be" + date);
  const d = new Date(date);
  // console.log("what might the date be month:" + monthNames[d.getMonth(date)]);
  return monthNames[d.getMonth(date)] === undefined
    ? " "
    : d.getDate(date) +
        // (d.getDate(date) % 10 === 1
        //   ? " "
        //   : d.getDate(date) % 10 === 2
        //   ? " "
        //   : d.getDate(date) % 10 === 3
        //   ? " "
        //   : " ")
        " " +
        monthNames[d.getMonth(date)] +
        " " +
        d.getFullYear(date);
};

export function convertEpochToNormalTime(epochTime) {
  // Create a new Date object with the epoch time in milliseconds
  const date = new Date(epochTime);

  // Define the days of the week and months
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Get the day of the week, month, and time from the date object
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = months[date.getMonth()];
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine if it's AM or PM
  const amOrPm = hours < 12 ? "AM" : "PM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Construct the formatted date string
  const formattedDate = `${formattedHours}:${minutes.toString().padStart(2, "0")} ${amOrPm}`;

  // Return the formatted date
  return formattedDate;
}

export function ddmmm(time) {
  // Create a new Date object with the epoch time in milliseconds
  const date = new Date(new Date(time));
  // Define the days of the week and months
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Get the day of the week, month, and time from the date object
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = months[date.getMonth()];
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine if it's AM or PM
  const amOrPm = hours < 12 ? "AM" : "PM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Construct the formatted date string
  const formattedDate = `${date.getDate()} ${month}`;

  // Return the formatted date
  return formattedDate;
}

export function checkDate(dateString) {
  const today = new Date();
  const tomorrow = new Date(today);
  const yesterday = new Date(today);
  const dayBeforeYesterday = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  yesterday.setDate(yesterday.getDate() - 1);
  dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);
  const date = new Date(dateString);

  if (date.toDateString() === today.toDateString()) {
    return "today";
  } else if (date.toDateString() === tomorrow.toDateString()) {
    return "tomorrow";
  } else if (date.toDateString() === yesterday.toDateString()) {
    return "yesterday";
  } else if (date.toDateString() === dayBeforeYesterday.toDateString()) {
    return "day before yesterday";
  } else {
    return "invalid";
  }
}

export const HandleDownloadFile = (fileUrl) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.target = "_blank";
  link.download = "filename.xlsx";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const HandleResumeDownload = (e, fileUrl) => {
  if (e) e.stopPropagation();
  const link = document.createElement("a");
  link.href = fileUrl;
  link.target = "_blank";
  link.download = "filename.xlsx";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function calculateEpochTimeInLocalTime(selectedDate) {
  if (selectedDate) {
    console.log("selectedDate", selectedDate);
    const date = new Date(selectedDate);
    const selectedTime = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return selectedTime;
  } else {
    return <span style={{ color: Color.neutralLightGrey }}>N/A</span>;
  }
}

export function extractDateFromDateString(dateString) {
  if (dateString !== null) {
    console.log("date passed", dateString);
    const inputDate = new Date(dateString);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    console.log("what's returning", year, month, day);
    return `${year}-${month}-${day}`;
  } else {
    console.log("date passed null", dateString);
    return (<span style={{ color: Color.neutralLightGrey }}>Date not selected</span>)
  }

}
export const allmonths = ["Januray", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
