import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { COlor, Color } from '../../../GlobalStyles'
import "../OngoingEvents/OngoingEvents.css"
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../ActivatedStatus/ActivatedStatus.css'
import Service from '../../../services/httpService';
import Cookies from 'js-cookie';
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from "file-saver";
import CardActions from '@mui/material/CardActions';

import { ToastContainer, toast } from 'react-toastify';

// function createData(name, calories, fat, carbs, protein) {
//     return { name, calories, fat, carbs, protein };
// }

// const rows = [
//     createData('manish Jha', "manijha@iimb.com", "7760812308", "1OX19CS020", "Activated"),
//     createData('manish Jha', "manijha@iimb.com", "7760812308", "1OX19CS020", "Activated"),
// ];



export default function AdminsOfCollege() {
    const location = useLocation();
    const navigate = useNavigate();
    const service = new Service()
    const [activatedDetails, setActivatedDetails] = useState([])
    let college_id = location.state?.college_id
    let college_name = location.state?.college_name || ""
    let batchName = location.state?.batchName
    let batchId = location.state?.batchId
    console.log("batch name", college_name);
    const currentDate = new Date();

    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {
            console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }
            //college getter
            let student = await studentDataGetter();
            setActivatedDetails(student)
            // setLoading(false);
            // forming the data
            console.log("odonil", { student })
        }

        handleEffect();
    }, [])

    const studentDataGetter = async () => {
        try {
            const res = await service.get(`/studentDetailsOfABatch?batch_id=${batchId}`)
            console.log("activated response:", res.data)
            // setCardDetails([...res.data])
            return res.data
        } catch (err) {
            console.log(err)
        }
    }

    const [copyList, setCopyList] = useState(activatedDetails)

    const requestSearch = (searched) => {
        console.log("searched text", searched);
        const filteredList = activatedDetails.filter((item) => item.student_name?.toLowerCase().includes(searched) || item.student_roll_no?.includes(searched) || item.student_status?.includes(searched))
        if(filteredList.length === 0) {
            toast.error("No matching data found")
            setCopyList([])
        }
        setCopyList(filteredList)
    }

    const fileDownload = () => {
        console.log("Downloading the file");
        console.log("Rows data now is:  ", activatedDetails);
        const csvData = (copyList.length > 0 ? copyList : activatedDetails).map((row) => Object.values(row).join(",")).join("\n");
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, `${window.location.pathname}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);
    }
    const completeActivityDownload = () => {
        console.log("Downloading the file");
        console.log("Rows data now is:  ", activatedDetails);
        const csvData = activatedDetails.map((row) => Object.values(row).join(",")).join("\n");
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, `${window.location.pathname}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);
    }

    console.log("copied text", copyList);
    return (
        <div>
            <SideNavigationBar />
            <ToastContainer position="top-center" limit={1} />
            <div className='OngoingEventsCard' style={{ marginLeft: "10rem", marginTop: "2rem", overflowX: "hidden" }}>
                <div className="topHeadings" style={{ display: "flex" }}>
                    <p style={{ color: Color.neutralMidGrey }} onClick={() => navigate("/CollegeInfoPage")}>{college_name}</p>
                    <p style={{ marginLeft: '1rem', marginRight: '1rem' }}>{" "} {">"}{" "} </p>
                    <p>{batchName}</p></div>
                <CardActions sx={{ justifyContent: 'flex-start', bottom: 0, }}>
                    <button
                        className="submitBtn"
                        type="button" d
                        style={{ height: "44px", bottom: 0, width: "auto" }}
                        onClick={() => completeActivityDownload()}
                    >
                        Download Complete Activity Details
                    </button>
                </CardActions>
                <div style={{ display: "flex", marginTop: "2rem" }}>
                    <div className='searchTab' style={{ alignItems: 'center', width: "90%", height: "30px", marginBottom: ".5rem" }}>
                        <input style={{ border: "none", height: "30px" }}
                            className='searchingText searchTab'
                            type='text'
                            onChange={(e) => requestSearch(e.target.value)}
                            placeholder='Search for name, roll number, etc'
                        />
                    </div>
                    <DownloadIcon style={{ color: Color.primary1, marginLeft: ".5rem", cursor: "pointer" }} onClick={() => fileDownload()} />
                </div>
                <button
                    className="Optionsbutton"
                    style={{}}
                    onClick={() => requestSearch("Activated")}
                >
                    Activated
                </button>
                <button
                    className="Optionsbutton"
                    style={{}}
                    onClick={() => requestSearch("Non Activated")}
                >
                    Not Activated
                </button>
            </div>
            <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }} style={{ width: '100%', marginTop: "2rem", marginLeft: "10rem" }}>
                <Table sx={{ maxWidth: "80%", border: "none", borderBottom: 'none' }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow style={{ borderBottom: 'none' }} sx={{ fontSize: "16px" }}>
                            <TableCell align="center" className='adminTableCell' sx={{ fontSize: "16px" }} style={{ color: Color.neutralMidGrey }}>Name</TableCell>
                            <TableCell align="center" color='text.secondary' sx={{ fontSize: "16px" }} style={{ color: Color.neutralMidGrey }}>Email</TableCell>
                            <TableCell align="center" color='text.secondary' sx={{ fontSize: "16px" }} style={{ color: Color.neutralMidGrey }}>Number</TableCell>
                            <TableCell align="center" color='text.secondary' sx={{ fontSize: "16px" }} style={{ color: Color.neutralMidGrey }}>Roll No.</TableCell>
                            <TableCell align="center" color='text.secondary' sx={{ fontSize: "16px" }} style={{ color: Color.neutralMidGrey }}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(copyList.length > 0 ? copyList : activatedDetails).map((row) => (
                            <TableRow className='rowsDataTable'
                                // key={row.name} 
                                style={{ paddingBottom: "1rem", paddingTop: "1rem" }}
                                sx={{ '&:last-child td, &:last-child th': { border: "1px solid white", borderBottom: 'none' }, borderBottom: 'none' }}
                            >
                                <TableCell align="left" component="th" scope="row" style={{ paddingBottom: "1rem", paddingTop: "1rem" }} sx={{ fontSize: "16px" }}>
                                    {row.student_name}
                                </TableCell>
                                <TableCell align="center" sx={{ fontSize: "16px" }}>{row.student_email}</TableCell>
                                <TableCell align="center" sx={{ fontSize: "16px" }}>{row.student_phone}</TableCell>
                                <TableCell align="center" sx={{ fontSize: "16px" }}>{row.student_roll_no}</TableCell>
                                <TableCell align="center" sx={{ fontSize: "16px" }} style={{ color: row.student_status === "Non Activated" ? "red" : "black" }}>{row.student_status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}