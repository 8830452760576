import React from "react";
import { Modal } from "react-bootstrap";

export default function Alert(props) {
  const { title, body, alertResponse } = props;
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ border: "none" }}
    >
      <Modal.Body className="ModalBody">
        <p
          className="modalBodyText"
          style={{ color: "#000", fontSize: "24px" }}
        >
          {title}
        </p>
        {console.log("props.yesButton", props.yesButton)}
        {console.log("props.no", props.no)}
        {console.log("props.yes", props.yes)}
        <div>
          <p className="modalBodyText">{body}</p>

          <div className="PreviousAndSubmit">
            {props.no && (
              <button
                name="previous-button"
                style={{ margin: "auto", marginTop: "1rem" }}
                className="Previousbutton"
                onClick={() => props.alertResponse("No")}
              >
                No
              </button>
            )}

            {props.yes && (
              <button
                name="next-button"
                style={{ margin: "auto", marginTop: "1rem" }}
                className="Rephrasebutton"
                onClick={() => alertResponse("Yes")}
              >
                Yes
              </button>
            )}
            {props.yesButton && (
              <button
                type="submit"
                name="next-button"
                style={{ margin: "auto", marginTop: "1rem" }}
                className="Rephrasebutton"
                onClick={() => alertResponse()}
              >
                OK
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
