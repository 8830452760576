import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "../OngoingEvents/OngoingEvents.css";
import SideNavigationBar from "../../reusables/SideNavigationBar/SideNavigationBar";
import { Color } from "../../../GlobalStyles";
import CardActions from "@mui/material/CardActions";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Service from "../../../services/httpService";
import Divider from "@mui/material/Divider";
import Cookies from "js-cookie";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { extractDateFromDateString } from "../../../services/specialCharReplace";
export default function OngoingEvents(props) {
  const navigate = useNavigate();
  const location = useLocation();
  // const {college_id, college_name} = props
  let college_id = location.state?.college_id;
  let college_name = location.state?.college_name || "";
  let batchName = location.state?.batchName;
  let batchId = location.state?.batchId;
  console.log("batch name", college_name);
  const [loading, setLoading] = useState(true);

  const service = new Service();
  const services = new CCService();
  // const [isExpanded, setIsExpanded] = useState(false);
  // const [is1x1Expanded, setIs1x1Expanded] = useState(false);
  // const [notScheduledExpanded, setIsNotScheduledExpanded] = useState(false)
  // const [rescheduledExpanded, setIsRescheduledExpanded] = useState(false)
  const [cardDetails, setCardDetails] = useState([]);
  const [rds_student_list, setRds_student_list] = useState([]);
  const [verifyRd, setVerifyRd] = useState({ verification_required: false, supported_doc_required: false });
  console.log("verifyRd:", verifyRd);
  const [expandedCardDetails, setExpandedCardDetails] = useState({});
  const handleExpand = async (event, isAllDataExpanded, isExpanded, is1x1Expanded, notScheduledExpanded, index, process_id) => {
    event.stopPropagation();
    console.log("data i'm accessing", isExpanded, is1x1Expanded, notScheduledExpanded, process_id);
    let tempExpandedCardDetails = await expandedCardDetailsDataGetter(process_id, index);
    const updatedCardDetails = cardDetails.map((element, cardIndex) => {
      if (cardIndex === index) {
        return {
          ...element,
          done_count: tempExpandedCardDetails.done_count,
          feedback_count: tempExpandedCardDetails.feedback_count,
          in_process_count: tempExpandedCardDetails.in_process_count,
          initialized_count: tempExpandedCardDetails.initialized_count,
          initialized_expired_bookings_count: tempExpandedCardDetails.initialized_expired_bookings_count,
          mentor_absent_count: tempExpandedCardDetails.mentor_absent_count,
          purchased_count: tempExpandedCardDetails.purchased_count,
          scheduled_count: tempExpandedCardDetails.scheduled_count,
          student_absent_count: tempExpandedCardDetails.student_absent_count,
        };
      }
      return element;
    });

    setCardDetails(updatedCardDetails);
    setCardDetails((prevCardInfo) => {
      const updatedBundleInfo = [...prevCardInfo];
      updatedBundleInfo[index] = {
        ...updatedBundleInfo[index],
        isAllDataExpanded: !isAllDataExpanded,
        isExpanded: !isExpanded,
      };
      return updatedBundleInfo;
    });
    setExpandedCardDetails(tempExpandedCardDetails);
    console.log("details", process_id, tempExpandedCardDetails, updatedCardDetails);
  };

  const closeIs1x1Expanded = (event, is1x1Expanded, index) => {
    event.stopPropagation();
    // console.log("data i'm accessing", isExpanded, is1x1Expanded, notScheduledExpanded);
    setCardDetails((prevCardInfo) => {
      const updatedBundleInfo = [...prevCardInfo];
      updatedBundleInfo[index] = {
        ...updatedBundleInfo[index],
        is1x1Expanded: !is1x1Expanded,
      };
      return updatedBundleInfo;
    });
  };

  const closeNotScheduledExpanded = (event, notScheduledExpanded, index) => {
    event.stopPropagation();
    setCardDetails((prevCardInfo) => {
      const updatedBundleInfo = [...prevCardInfo];
      updatedBundleInfo[index] = {
        ...updatedBundleInfo[index],
        notScheduledExpanded: !notScheduledExpanded,
      };
      return updatedBundleInfo;
    });
  };

  const closeIsExpanded = (event, isExpanded, is1x1Expanded, notScheduledExpanded, index) => {
    event.stopPropagation();
    setCardDetails((prevCardInfo) => {
      const updatedBundleInfo = [...prevCardInfo];
      updatedBundleInfo[index] = {
        ...updatedBundleInfo[index],
        isExpanded: !isExpanded,
        // is1x1Expanded: false,
        // notScheduledExpanded: false
      };
      return updatedBundleInfo;
    });
  };

  const closeRescheduledExpanded = (event, isExpanded, is1x1Expanded, notScheduledExpanded, rescheduledExpanded, index) => {
    event.stopPropagation();
    setCardDetails((prevCardInfo) => {
      const updatedBundleInfo = [...prevCardInfo];
      updatedBundleInfo[index] = {
        ...updatedBundleInfo[index],
        rescheduledExpanded: !rescheduledExpanded,
      };
      return updatedBundleInfo;
    });
  };

  // useeffect'
  useEffect(() => {
    console.log("Brother");
    const handleEffect = async () => {
      console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL);
      if (Cookies.get("token") === null || Cookies.get("token") === undefined) {
        navigate("/");
      }
      //college getter
      let student = await studentDataGetter();
      let filtered_student_list = student.filter((item) => item.sku_name !== "Resumes.Digital 1 year");
      let rds_student_list = student.filter((item) => item.sku_name === "Resumes.Digital 1 year");

      filtered_student_list?.forEach((element) => {
        element.process_id = element.process_id;
        element.isExpanded = false;
        element.is1x1Expanded = false;
        element.notScheduledExpanded = false;
        element.isAllDataExpanded = false;
        element.rescheduledExpanded = false;
        element.loadingButton = false;
      });

      setRds_student_list(rds_student_list);
      let rd_process_id = rds_student_list[0]?.process_id;
      if (rd_process_id) {
        const verifyResumes = await processIdToParamsMapping(rd_process_id);
        console.log("ksdhfjsdjfj", verifyResumes);
        // if (verifyResumes.data[0].verification_required === 1) {
        //   if (verifyResumes.data[0].supported_doc_required === 1) setVerifyRd((prev) => ({ ...prev, verification_required: true, supported_doc_required: true }));
        //   else setVerifyRd((prev) => ({ ...prev, verification_required: true, supported_doc_required: false }));
        // }.

        setVerifyRd((prev) => ({ ...prev, verification_required: true, supported_doc_required: false }));
      }
      setCardDetails(filtered_student_list);
      setLoading(false);

      // forming the data
      console.log("odonil", { student });
    };

    handleEffect();
  }, []);

  const navigateToStudentDetails = (process_id, sku_name, features, searched) => {
    navigate("/College1x1StudentDetails", { state: { college_data_id: college_id, college_data_name: college_name, batchName: batchName, batchid: batchId, process_id: process_id, sku_name: sku_name, features: features, searched: searched } });
  };

  const studentDataGetter = async () => {
    try {
      const res = await service.get(`/batchWiseProcess?batch_id=${batchId}`);
      console.log("batch card details response:", res.data);
      // setCardDetails([...res.data])
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const processIdToParamsMapping = async (rd_process_id) => {
    try {
      const res = await services.get(`user/processIdToParamsMapping?process_id=${rd_process_id}`);
      console.log("processIdToParamsMapping", res);
      // setCardDetails([...res.data])
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  const expandedCardDetailsDataGetter = async (process_id, index) => {
    setCardDetails((prevCardInfo) => {
      const updatedBundleInfo = [...prevCardInfo];
      updatedBundleInfo[index] = {
        ...updatedBundleInfo[index],
        loadingButton: true,
      };
      return updatedBundleInfo;
    });
    try {
      const res = await service.get(`/CollegeOngoingEventsById?process_id=${process_id}`);
      console.log("Card details response:", res.data);
      // setCardDetails([...res.data])
      setCardDetails((prevCardInfo) => {
        const updatedBundleInfo = [...prevCardInfo];
        updatedBundleInfo[index] = {
          ...updatedBundleInfo[index],
          loadingButton: false,
        };
        return updatedBundleInfo;
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <SideNavigationBar />
      <div className="OngoingEventsCard" style={{ marginLeft: "10rem", marginTop: "2rem" }}>
        <div className="topHeadings" style={{ display: "flex", justifyContent: "space-between", marginRight: "12rem" }}>
          <div style={{ display: "flex" }}>
            <p style={{ color: Color.neutralMidGrey }} onClick={() => navigate("/CollegeInfoPage")}>
              {college_name}
            </p>
            <p style={{ marginLeft: "1rem", marginRight: "1rem" }}> {">"} </p>
            <p>{batchName}</p>
          </div>
          {verifyRd.verification_required ? (
            <button
              className="submitRdBtn"
              type="button"
              style={{ height: "44px", bottom: 0, width: "auto", paddingLeft: "3rem", fontSize: 14, paddingRight: "3rem" }}
              onClick={() =>
                navigate("/VerifyResumes", {
                  state: {
                    college_name: college_name,
                    user_group_id: batchId,
                    supported_doc_required: verifyRd.supported_doc_required,
                  },
                })
              }
            >
              Verify Resumes
            </button>
          ) : (
            <></>
          )}
        </div>
        <div style={{ marginLeft: ".2rem", marginBottom: ".2rem", marginTop: "2rem" }} className="cardsInsideFlex">
          {cardDetails?.map((details, index) => {
            return (
              <>
                <Box sx={{ width: 255, marginRight: "1rem", marginLeft: ".2rem", marginBottom: ".2rem", boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)", borderRadius: "10px", display: "flex", height: details.isAllDataExpanded ? "auto" : 240, cursor: "pointer" }} onClick={details?.isAllDataExpanded === true ? () => {} : () => navigateToStudentDetails(details.process_id, details.sku_name, details.features, "All")}>
                  <CardContent className="cardContainer" disableGutters style={{ width: index === 0 ? "255px" : "255px" }}>
                    <Typography variant="body2" sx={{ fontSize: 20, marginBottom: "1rem" }} gutterBottom>
                      {details.sku_name.substring(0, 15).concat(details.sku_name.length > 15 ? "..." : "")}
                    </Typography>
                    <Typography variant="h5" component="div"></Typography>
                    <div className="container">
                      <Typography variant="body2" class="providingSpace1">
                        Start Date:
                      </Typography>
                      <Typography variant="body2" class="providingSpace">
                        {extractDateFromDateString(details.start_date)}
                      </Typography>
                    </div>
                    <div className="container">
                      <Typography variant="body2" class="providingSpace1">
                        End Date:
                      </Typography>
                      <Typography variant="body2" class="providingSpace">
                        {extractDateFromDateString(details.end_date)}
                      </Typography>
                    </div>
                    <div className="container">
                      <Typography variant="body2" class="providingSpace1">
                        Total Students:
                      </Typography>
                      <Typography variant="body2" class="providingSpace">
                        {details.total_student}
                      </Typography>
                    </div>
                    {details.isAllDataExpanded && (
                      <>
                        {/* {expandedCardDetails.map(items => {
                                           
                                            return ( */}
                        <>
                          <div className="containerHeadings">
                            <Typography variant="body2" sx={{ fontSize: 18 }} color={Color.neutralDarkGrey}>
                              Key Updates
                            </Typography>
                            <button onClick={(e) => closeIsExpanded(e, details.isExpanded, details.is1x1Expanded, details.notScheduledExpanded, index)} className="show-hide-button" variant="body2" sx={{ fontSize: 16 }} color={Color.primary1}>
                              {details.isExpanded ? "Hide" : "Show"}
                            </button>
                          </div>
                          {details.isExpanded && (
                            <>
                              <div>
                                <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, details.features, "Done")}>
                                  <Typography variant="body2" color={Color.primary1}>
                                    1x1 Done:
                                  </Typography>
                                  <Typography variant="body2" class="providingSpaceForSubDetails">
                                    {details.done_count}
                                  </Typography>
                                </div>

                                <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, details.features, "Scheduled")}>
                                  <Typography variant="body2" color={Color.primary1}>
                                    Scheduled:
                                  </Typography>
                                  <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetails">
                                    {details.scheduled_count}
                                  </Typography>
                                </div>
                                <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, details.features, "Not Scheduled")}>
                                  <Typography variant="body2" color={Color.primary1}>
                                    Not Scheduled:
                                  </Typography>
                                  <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetails">
                                    {details.purchased_count + details.initialized_expired_bookings_count}
                                  </Typography>
                                </div>
                                <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, details.features, "Mentor Awaited")}>
                                  <Typography variant="body2" color={Color.primary1}>
                                    Mentor Awaited:
                                  </Typography>
                                  <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">
                                    {details.initialized_count - details.initialized_expired_bookings_count}
                                  </Typography>
                                </div>

                                <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, details.features, "Student Absent")}>
                                  <Typography variant="body2" color={Color.primary1}>
                                    Student Absent:
                                  </Typography>
                                  <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">
                                    {details.student_absent_count}
                                  </Typography>
                                </div>
                              </div>
                            </>
                          )}

                          <Divider component="li" sx={{ listStyle: "none", marginBottom: ".5rem", marginTop: ".5rem" }} />

                          <div className="containerHeadings">
                            <Typography variant="body2" sx={{ fontSize: 18 }} color={Color.neutralDarkGrey}>
                              Not Scheduled
                            </Typography>
                            <button onClick={(e) => closeNotScheduledExpanded(e, details.notScheduledExpanded, index)} className="show-hide-button" variant="body2" sx={{ fontSize: 16 }} color={Color.primary1}>
                              {details.notScheduledExpanded ? "Hide" : "Show"}
                            </button>
                          </div>
                          {details.notScheduledExpanded && (
                            <>
                              <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, details.features, "No Action Students")}>
                                <Typography variant="body2" color={Color.primary1}>
                                  No Action Students:{" "}
                                </Typography>
                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">
                                  {details.purchased_count}
                                </Typography>
                              </div>
                              <div className="container" onClick={() => navigateToStudentDetails(details.process_id, details.sku_name, details.features, "Expired Bookings")}>
                                <Typography variant="body2" color={Color.primary1}>
                                  Expired Bookings:
                                </Typography>
                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">
                                  {details.initialized_expired_bookings_count}
                                </Typography>
                              </div>
                            </>
                          )}

                          <Divider component="li" sx={{ listStyle: "none", marginBottom: ".5rem", marginTop: ".5rem" }} />
                        </>
                      </>
                    )}
                    {details.isAllDataExpanded === false && (
                      <CardActions sx={{ justifyContent: "flex-end", bottom: 0 }}>
                        <button className="submitBtn" type="button" d style={{ height: "44px", bottom: 0 }} onClick={(event) => handleExpand(event, details.isAllDataExpanded, details.isExpanded, details.is1x1Expanded, details.notScheduledExpanded, index, details.process_id)}>
                          {details.loadingButton ? <CircularProgress size={24} color="inherit" /> : "Load Details"}
                        </button>
                      </CardActions>
                    )}
                  </CardContent>
                </Box>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}
