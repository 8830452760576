import { ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

// login imports
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ForgotPassword/ResetPassword";
import Login from "./components/Login/Login";
import SetPassword from "./components/SetPassword/SetPassword";
// college admin dashboard
import ActivatedStatus from "./components/Dashboard/ActivatedStatus/ActivatedStatus";
import College1x1StudentDetails from "./components/Dashboard/College1x1StudentDetails/College1x1StudentDetails";
import College1x1BatchDetails from "./components/Dashboard/CollegeBatchDetails/College1x1BatchDetails";
import CollegeInfoPage from "./components/Dashboard/CollegeInfoPage/CollegeInfoPage";
import MentorStatus from "./components/Dashboard/MentorStatus/MentorStatus";
import OngoingEvents from "./components/Dashboard/OngoingEvents/OngoingEvents";
import UpcomingPrograms from "./components/Dashboard/UpcomingPrograms/UpcomingPrograms";

// analytics
import Analytics from "./components/Analytics/Analytics";
import ActivityReport from "./components/Analytics/BatchWiseAnalytics/ActivityReport";
import BatchProgressTracker from "./components/Analytics/BatchWiseAnalytics/BatchProgressTracker";
import BatchWiseAnalytics from "./components/Analytics/BatchWiseAnalytics/BatchWiseAnalytics";
import DiagnosticInterview_1 from "./components/Analytics/BatchWiseAnalytics/DiagnosticInterview_1";
import NewCollegeAnalytics from "./components/Analytics/CollegeWiseAnalytics/NewCollegeAnalytics";
import InterviewWiseAnalytics from "./components/Analytics/InterviewWiseAnalytics/InterviewWiseAnalytics";
import StudentProcess from "./components/Analytics/StudentProcess";
import StudentWiseAnalytics from "./components/Analytics/StudentWiseAnalytics/StudentWiseAnalytics";
import Verification from "./components/Dashboard/VerifyResumes/Verification";
import VerifyResumes from "./components/Dashboard/VerifyResumes/VerifyResumes";
import { theme } from "./muiTheme";

// placement ops
import NewAllApplicants from "./components/PlacementOps/AllApplicants/NewAllApplicants";
import Batches from "./components/PlacementOps/Batches";
import ClosedForApplication from "./components/PlacementOps/ClosedForApplication/ClosedForApplication";
import AllCompanies from "./components/PlacementOps/Company/AllCompanies";
import JobsPostingPage from "./components/PlacementOps/JobsPostingPage";
import PostingOpportunity from "./components/PlacementOps/PostingOpportunityModule/PostingOpportunity";
import POReview from "./components/PlacementOps/PostingOpportunityModule/PostingOpportunityReview/POReview";
import PVBatches from "./components/PlacementOps/ProfileVerification/PVBatches";
import PVStudentBatch from "./components/PlacementOps/ProfileVerification/PVStudentBatch";
import ProfileVerification from "./components/PlacementOps/ProfileVerification/ProfileVerification";
import Settings from "./components/PlacementOps/Settings/Settings";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import OfflineSessionList from "./components/Offlinesession/OfflineSessionList";
import StudentSummary from "./components/Offlinesession/StudentSummary";
import ResumesDigitalAnalytics from "./components/Analytics/ResumesDigitalAnalytics/ResumesDigitalAnalytics";
function App() {
  const {user_id} = useSelector((store) => {
    console.log("fjlkdlf", store)
    return store.rights
  })
  
  useEffect(()=>{
    console.log("USER_ID", user_id)
  },[user_id])
  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            {/* login pages */}
            <Route path="/Login" exact element={<Login />} />
            <Route path="/" exact element={<Login />} />
            <Route path="/ForgotPassword" exact element={<ForgotPassword />} />
            {/* college dashboard pages */}
            <Route path="/SetPassword">
              <Route path=":email/:token" exact element={<SetPassword />} />
            </Route>
            <Route path="/ResetPassword">
              <Route path=":email/:token" exact element={<ResetPassword />} />
            </Route>
            {/* <Route path="/SetPassword" exact element={<SetPassword />} /> */}
            <Route path="/CollegeInfoPage" exact element={<CollegeInfoPage />} />
            <Route path="/OngoingEvents" exact element={<OngoingEvents />} />
            <Route path="/UpcomingPrograms" exact element={<UpcomingPrograms />} />
            <Route path="/College1x1BatchDetails" exact element={<College1x1BatchDetails />} />
            <Route path="/College1x1StudentDetails" exact element={<College1x1StudentDetails />} />
            <Route path="/MentorStatus" exact element={<MentorStatus />} />
            <Route path="/ActivatedStatus" exact element={<ActivatedStatus />} />

            <Route path="/VerifyResumes" exact element={<VerifyResumes />} />
            <Route path="/VerifyResumes/Verify" exact element={<Verification />} />

            <Route path="/Analytics" exact element={<Analytics />} />
            <Route path="/BatchWiseAnalytics" exact element={<BatchWiseAnalytics />} />
            <Route path="/BatchProgressTracker" exact element={<BatchProgressTracker />} />
            <Route path="/ActivityReport" exact element={<ActivityReport />} />
            <Route path="/StudentWiseAnalytics" exact element={<StudentWiseAnalytics />} />
            <Route path="/StudentProcess" exact element={<StudentProcess />} />

            <Route path="/DiagnosticInterview" exact element={<DiagnosticInterview_1 />} />
            <Route path="/InterviewWiseAnalytics" exact element={<InterviewWiseAnalytics />} />
            <Route path="/ResumesDigitalAnalytics" exact element={<ResumesDigitalAnalytics />} />
            <Route path="/CollegeAnalytics" exact element={<NewCollegeAnalytics />} />
            <Route path="/ResumeAnalytics" exact element={<NewCollegeAnalytics />} />
            <Route path="/OfflineSession" exact element={<OfflineSessionList/>} />
            <Route path="/StudentSummary" exact element={<StudentSummary/>} />

            {/* placement ops */}
            <Route path="/PostingOpportunity" exact element={<PostingOpportunity />} />
            <Route path="/Batches" exact element={<Batches />} />
            <Route path="/JobsPostingPage" exact element={<JobsPostingPage />} />
            <Route path="/POReview" exact element={<POReview />} />
            <Route path="/AllApplicants" exact element={<NewAllApplicants />} />
            <Route path="/ClosedForApplication" exact element={<ClosedForApplication />} />

            {/* PlaceO -> Profile Verificaiton */}
            <Route path="/ProfileVerification" element={<ProfileVerification />} />
            <Route path="/ProfileVerification/Batches" element={<PVBatches />} />
            {/* <Route path="/ProfileVerification/Batches/Students" element={<PVStudents />} /> */}
            <Route path="/ProfileVerification/Batches/Students" element={<PVStudentBatch />} />

            {/* <Route path="/ProfileVerification/Batches/StudentPage" element={<PVStudentBatch />} /> */}

            <Route path="/Settings" element={<Settings />} />
            <Route path="/Companies" element={<AllCompanies />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
