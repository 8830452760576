import React, {useState} from 'react'
import { Box, IconButton, Modal, Slide, Typography } from "@mui/material"
import { Close } from '@mui/icons-material';
import BatchProfileExcelTemplate from './BatchProfileExcelTemplate';
import defaultSetting from '../Settings/DefaultSettings.json'
export const SlideInRightModal = ({ open, onClose, batch_id,job_id, tab }) => {
    const [defaultSettings, setDefaultSettings] = useState(defaultSetting)

  const [defaultSettingsArray, setDefaultSettingsArray] = useState(defaultSettings?.download_logic)
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });
    const style = {
        position: 'absolute',
        top: 0,
        right: 0,
        height: '100vh',
        width: '70%',
        bgcolor: 'background.paper',
        border: 'none',
        borderRadius: '5px 0px 0px 5px',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        transform: open ? 'translateX(0%)' : 'translateX(100%)',
        transition: 'transform 0.3s ease-out',
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={{ ...style, height: '100%', background: 'white' }}>
                <Box sx={{ width: '100%', height: '8%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '16px' }}>
                    <Typography sx={{ fontSize: '32px' }}>Choose Columns to be Displayed</Typography>
                    <IconButton
                        onClick={onClose}
                        color="inherit"
                        aria-label="close">
                        <Close />
                    </IconButton>
                </Box>
                <Box sx={{ width: '100%', height: '80%', display: 'flex', flexDirection: 'column', gap: '16px', overflowY: 'auto' }}>
                    <BatchProfileExcelTemplate origin="customize" batch_id={batch_id} job_id={job_id} onClose={onClose} defaultSettingsArray={defaultSettingsArray} setDefaultSettingsArray={setDefaultSettingsArray} tab={tab}/>
                </Box>
            </Box>
        </Modal >
    )
}