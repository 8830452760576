export const filterSameValues = (arr, key1, key2) => {
    const seen = new Set();
    return arr.filter((item) => {
        const value1 = item[key1];
        const value2 = item[key2];
        const combinedValue = `${value1}-${value2}`;

        if (!seen.has(combinedValue)) {
            seen.add(combinedValue);
            return true;
        }
        return false;
    });
};