import React, { useEffect, useState, useRef } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Color, DivCenter, PrimaryButton } from "../../../../../GlobalStyles";
import { Autocomplete, Box, Button, Checkbox, Chip, IconButton, MenuItem, Modal, TextField, ToggleButton, ToggleButtonGroup, Typography, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, ListItemText, Tooltip } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { extractDateFromDateString } from "../../../../../services/specialCharReplace";
import { makeTextBold } from "../RichTextEditor";
import { get_specialCharReplace, send_specialCharReplace } from "../../../../../services/specialCharReplace";
import { Close, InfoOutlined, DeleteOutlined, Cancel } from "@mui/icons-material";
import CCService from "../../../../../services/httpServiceWithoutSuperAdmin";
import ReactQuill from "react-quill";
import TimeService from "../../../../../services/epochTime";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SelectCurrency from "react-select-currency";
import axios from "axios";
import Cookies from "js-cookie";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { Validations } from "../../../Validations/Validations";
import { convertToIndianFormat } from "../../OpportunityInfo/currency";
import { useSelector } from "react-redux";
export const SubComponentsCompanyInfo = ({ review, setReview, reference, formErrors, setFormErrors, allCompanies, isEdit }) => {
  console.log("reference", review);
  const [modalData, setModalData] = useState({ open: false, close: () => setModalData((prev) => ({ ...prev, open: false })) });
  const { tooltipText} = useSelector((store) => store.rights)
  const handleModalData = () => {
    let temp_modalData = { ...modalData };
    temp_modalData.open = true;
    setModalData(temp_modalData);
  };

  function FieldEditModal() {
    const [formInput, setFormInput] = useState({ ...review });
    console.log("Comparing values:", formInput, review);

    const handleSaveChanges = async () => {
      const service = new CCService();
      const timeServices = new TimeService();
      let formError = Validations(formInput);
      try {
        setFormErrors(formError);
      } catch (error) {
        console.log("Error While form Error ", formError);
      }
      const put_body = {
        po_job_id: formInput.po_job_id,
        company_location: formInput.company_location,
        image_url: formInput.image_url ?? undefined,
        // company_name: formInput.company_name,
        company_id: formInput.company_id,
        ...(formInput.date_of_visit !== "" &&
          formInput.date_of_visit !== null && {
            date_of_visit: timeServices.convertDateToEpoch(formInput.date_of_visit),
          }),
      };
      if (Object.keys(formError).length === 0) {
        try {
          const put_response = await service.put(`/po/ca/applications`, put_body);
          console.log("put_response:", put_response);
          //change page
          setReview((prev) => (prev = formInput));
          setModalData((prev) => ({ ...prev, open: false }));
        } catch (error) {
          console.error("Error while putting job/basic_info:", error);
        }
      }
    };

    const handleChange = ({ target }) => {
      const { name, value } = target;
      setFormInput((prev) => ({ ...prev, [name]: value }));

      if (name === "company_id") {
        let temp_image = allCompanies.find((item) => item.id === value).company_image;
        if (temp_image !== null) {
          setFormInput((prev) => ({ ...prev, image_url: temp_image }));
        }
      }
    };

    return (
      <Modal open={modalData.open} onClose={modalData.close}>
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", padding: "2rem", borderRadius: "40px" }}>
          <Box sx={{ ...DivCenter, justifyContent: "space-between" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                m: "0 0 0 16px",
              }}
            >
              {modalData.open && `Edit`}
            </Typography>
            <IconButton onClick={modalData.close}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              ...DivCenter,
              width: "100%",
              justifyContent: "flex-start",
              gap: "1.5rem",
              overflowY: "auto",
              flexWrap: "wrap",
              padding: "16px",
            }}
          >
            {/* <TextField type="text" required name="company_name" label="Company" value={formInput.company_name} onChange={(e) => handleChange(e)} error={formErrors?.company_name}></TextField> */}

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Company</InputLabel>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" name="company_id" value={formInput.company_id} label="Company" onChange={(e) => handleChange(e)}>
                {allCompanies.map((item, index) => (
                  <MenuItem value={item.id}>{item.company_name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <TextField type="text" required name="company_location" label="Company Location" value={formInput.company_location} onChange={(e) => handleChange(e)} error={formErrors?.company_location}></TextField> */}

            {console.log("FORMINPUTSSS", formInput)}

            <Box sx={{ width: "100%" }}>
              <Typography>Location *</Typography>
              <Autocomplete
                multiple
                autoComplete="off"
                sx={{ width: "100%" }}
                placeholder="Type Skills..."
                options={[]}
                value={formInput.company_location ?? []}
                freeSolo
                renderTags={(value, getTagProps) => value.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)}
                renderInput={(params) => <TextField {...params} />}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(e, values) => {
                  setFormInput((prev) => ({ ...prev, company_location: values }));
                }}
              />
            </Box>

            <TextField sx={{ width: "100%" }} type="date" required name="date_of_visit" label="Date of Visit" value={formInput.date_of_visit ? extractDateFromDateString(formInput.date_of_visit) : "dd-mm-yy"} onChange={(e) => handleChange(e)}></TextField>
          </Box>
          <PrimaryButton variant="contained" className="Rephrasebutton" onClick={handleSaveChanges} sx={{ m: "16px 0 0 16px" }}>
            Save Changes
          </PrimaryButton>
        </Box>
      </Modal>
    );
  }

  return (
    <div ref={reference} id="CompanyInfo">
      <Box sx={{ ...DivCenter, gap: "24px", justifyContent: "flex-start", pb: "16px" }}>
        <Typography
          style={{
            color: Color.neutralDarkGrey,
            fontSize: "18px",
            fontWeight: 400,
          }}
        >
          Company Info
        </Typography>
        <Tooltip title = {isEdit ? '' : tooltipText}>
        <Box>
        <IconButton onClick={() => handleModalData()} sx={{ height: "32px", weight: "32px", background: "rgba(56, 193, 223, 0.10)",color: Color.primary1 ,'&:disabled':{background:Color.neutralLightGrey,color: Color.neutralDarkGrey } }} disabled={!isEdit}>
          <EditOutlinedIcon sx={{ fontSize: "14px",  }} /> 
        </IconButton>
        </Box>

        </Tooltip>
       
      </Box>
      <div
        // key={fieldIndex}
        style={{
          ...DivCenter,
          flexDirection: "column",
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "5rem",
            textAlign: "left",
            height: "auto",
            paddingTop: ".5rem",
            justifyContent: "space-between",
            alignItems: "left",
            paddingBottom: ".5rem",
            borderBottom: `1px solid ${Color.neutralLightGrey}`,
          }}
        >
          <Typography
            style={{
              width: "30%",
              color: Color.neutralMidGrey,
            }}
          >
            Company
          </Typography>
          <Typography sx={{ width: "65%" }}>{allCompanies?.find((item) => item.id === review?.company_id)?.company_name}</Typography>
        </Box>
      </div>
      <div
        // key={fieldIndex}
        style={{
          ...DivCenter,
          flexDirection: "column",
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "5rem",
            textAlign: "left",
            height: "auto",
            paddingTop: ".5rem",
            justifyContent: "space-between",
            alignItems: "left",
            paddingBottom: ".5rem",
            borderBottom: `1px solid ${Color.neutralLightGrey}`,
          }}
        >
          <Typography
            style={{
              width: "30%",
              color: Color.neutralMidGrey,
            }}
          >
            Location
          </Typography>
          {console.log("INCOMINFAA", review)}

          <Box sx={{ width: "65%", gap: "8px", display: "flex" }}>
            {/* {review?.company_location?.map((item, i) => (
              <Typography sx={{}}>{item}</Typography>
            ))} */}

            {Array.isArray(review?.company_location) ?review?.company_location.join(", "): review?.company_location}
          </Box>
        </Box>
      </div>
      <div
        // key={fieldIndex}
        style={{
          ...DivCenter,
          flexDirection: "column",
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "5rem",
            textAlign: "left",
            height: "auto",
            paddingTop: ".5rem",
            justifyContent: "space-between",
            alignItems: "left",
            paddingBottom: ".5rem",
            borderBottom: `1px solid ${Color.neutralLightGrey}`,
          }}
        >
          <Typography
            style={{
              width: "30%",
              color: Color.neutralMidGrey,
            }}
          >
            Date of Visit
          </Typography>
          <Typography sx={{ width: "65%", color: review?.date_of_visit !== null ? Color.blueBlack : Color.neutralMidGrey }}>{review?.date_of_visit !== null ? extractDateFromDateString(review?.date_of_visit) : "No date provided"}</Typography>
        </Box>
      </div>
      {/* Modal Component */}
      <FieldEditModal />
    </div>
  );
};

export const SubComponentsOpportunityOutline = ({ review, setReview, reference, formErrors, setFormErrors , isEdit}) => {
  const [modalData, setModalData] = useState({ open: false, close: () => setModalData((prev) => ({ ...prev, open: false })) });
  const { tooltipText} = useSelector((store) => store.rights)
  const handleModalData = () => {
    let temp_modalData = { ...modalData };
    temp_modalData.open = true;
    setModalData(temp_modalData);
  };

  function FieldEditModal() {
    const service = new CCService();
    const [sectorData, setSectorData] = useState([{}]);
    const [formInput, setFormInput] = useState({ ...review });
    console.log("Comparing values:", formInput, review);
    useEffect(() => {
      const handleModalEffect = async () => {
        const temp_sectorData = await sectorDataGetter();
        console.log("temp_sectorData:", temp_sectorData);
        setSectorData(temp_sectorData);
      };
      handleModalEffect();
    }, [formInput?.placement_type]);

    const sectorDataGetter = async () => {
      try {
        const res = await service.get(`/po/ca/sector?section_type_id=${formInput.placement_type === "Finals" ? 8 : 7}`);
        return res.sectors;
      } catch (err) {
        console.log(err);
      }
    };

    const handleSaveChanges = async () => {
      const service = new CCService();
      let formError = Validations(formInput);
      try {
        setFormErrors(formError);
      } catch (error) {
        console.log("Error While form Error ", formError);
      }
      const put_body = {
        po_job_id: formInput.po_job_id,
        designation: formInput.designation,
        placement_type: formInput.placement_type,
        job_mode: formInput.job_mode,
        sector_id: formInput.sector_id,
      };
      if (Object.keys(formError).length === 0) {
        try {
          const put_response = await service.put(`/po/ca/applications`, put_body);
          console.log("put_response:", put_response);
          //change page
          setReview((prev) => (prev = formInput));
          setModalData((prev) => ({ ...prev, open: false }));
        } catch (error) {
          console.error("Error while putting job/basic_info:", error);
        }
      }
    };

    const handleChange = ({ target }) => {
      const { name, value } = target;
      if (name === "sector_id") {
        const temp_sector_name = sectorData.find((sector) => sector.id === value);
        setFormInput((prev) => ({ ...prev, sector_name: temp_sector_name ? temp_sector_name.sector_name : "N/A" })); //setting sector_name
      }
      setFormInput((prev) => ({ ...prev, [name]: value })); //setting sector_id and other fields automatically
    };

    const handleTabChangeJobMode = (e, value) => {
      setFormInput((prev) => ({ ...prev, job_mode: value }));
    };

    const handleTabChangePlacementType = (e, value) => {
      setFormInput((prev) => ({ ...prev, placement_type: value }));
    };

    return (
      <Modal open={modalData.open} onClose={modalData.close}>
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", padding: "2rem", borderRadius: "40px" }}>
          <Box sx={{ ...DivCenter, justifyContent: "space-between" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                m: "0 0 0 16px",
              }}
            >
              {modalData.open && `Edit`}
            </Typography>
            <IconButton onClick={modalData.close}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              ...DivCenter,
              width: "100%",
              justifyContent: "flex-start",
              overflowY: "auto",
              flexWrap: "wrap",
              padding: "16px",
              gap: "24px",
            }}
          >
            <TextField disabled required type="text" label="Profile (Designation)" name="designation" value={formInput.designation} onChange={(e) => handleChange(e)} error={formErrors?.designation} sx={{ width: "100%" }}></TextField>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "16px" }}>
              <Typography>
                Placement Type<span style={{ color: "red" }}>*</span>
              </Typography>
            </Box>
            <ToggleButtonGroup color="primary" value={formInput?.placement_type} exclusive onChange={(e, value) => handleTabChangePlacementType(e, value)} sx={{ width: "100%" }}>
              <ToggleButton
                value="Finals"
                sx={{
                  width: "100%",
                  borderRadius: "20px 0px 0px 20px",
                  textTransform: "none",
                }}
              >
                {" "}
                <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}>
                  {formInput?.placement_type === "Finals"} <Typography>Finals</Typography>
                </Box>
              </ToggleButton>

              <ToggleButton
                value="Summers"
                sx={{
                  width: "100%",
                  borderRadius: "0px 20px 20px 0px",
                  textTransform: "none",
                }}
              >
                <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}>
                  {formInput?.placement_type === "Summers"} <Typography>Summers</Typography>
                </Box>
              </ToggleButton>
            </ToggleButtonGroup>
            <Typography sx={{ fontSize: "12px", color: Color.red }}>{formErrors?.placement_type ? "Placement Type should be selected" : ""}</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "16px" }}>
              <Typography>Job Mode</Typography>
              <ToggleButtonGroup color="primary" value={formInput.job_mode} exclusive onChange={(e, value) => handleTabChangeJobMode(e, value)} sx={{ width: "100%" }}>
                <ToggleButton
                  value="Work from Office"
                  sx={{
                    width: "100%",
                    borderRadius: "20px 0px 0px 20px",
                    textTransform: "none",
                  }}
                >
                  {" "}
                  <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}>
                    {formInput.job_mode === "Work from Office"} <Typography>Work from Office</Typography>
                  </Box>
                </ToggleButton>

                <ToggleButton value="Work from Home" sx={{ width: "100%", textTransform: "none" }}>
                  <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}>
                    {formInput.job_mode === "Work from Home"} <Typography>Work from Home</Typography>
                  </Box>
                </ToggleButton>
                <ToggleButton
                  value="Hybrid"
                  sx={{
                    width: "100%",
                    borderRadius: "0px 20px 20px 0px",
                    textTransform: "none",
                  }}
                >
                  {" "}
                  <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}>
                    {formInput.job_mode === "Hybrid"} <Typography>Hybrid</Typography>
                  </Box>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <TextField select required label="Sector" name="sector_id" value={formInput.sector_id} onChange={(e) => handleChange(e)} sx={{ width: "100%" }}>
              {sectorData?.map((sector, index) => (
                <MenuItem key={index} value={sector.id}>
                  {sector.sector_name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <PrimaryButton variant="contained" className="Rephrasebutton" onClick={handleSaveChanges} sx={{ m: "16px 0 0 16px" }}>
            Save Changes
          </PrimaryButton>
        </Box>
      </Modal>
    );
  }

  return (
    <div ref={reference} id="OpportunityOutline">
      <Box sx={{ ...DivCenter, gap: "24px", justifyContent: "flex-start", pb: "16px" }}>
        <Typography
          style={{
            color: Color.neutralDarkGrey,
            fontSize: "18px",
            fontWeight: 400,
          }}
        >
          Opportunity Outline
        </Typography>
        <Tooltip title = {isEdit ? '' : tooltipText}>
          <Box>

        <IconButton onClick={() => handleModalData()} sx={{ height: "32px", weight: "32px", background: "rgba(56, 193, 223, 0.10)",color: Color.primary1 ,'&:disabled':{background:Color.neutralLightGrey,color: Color.neutralDarkGrey } }} disabled={!isEdit}>
          <EditOutlinedIcon sx={{ fontSize: "14px",}} />
        </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <div
        style={{
          ...DivCenter,
          flexDirection: "column",
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "5rem",
            textAlign: "left",
            height: "auto",
            paddingTop: ".5rem",
            justifyContent: "space-between",
            alignItems: "left",
            paddingBottom: ".5rem",
            borderBottom: `1px solid ${Color.neutralLightGrey}`,
          }}
        >
          <Typography
            style={{
              width: "30%",
              color: Color.neutralMidGrey,
            }}
          >
            Profile (Designation)
          </Typography>
          <Typography sx={{ width: "65%" }}>{review.designation}</Typography>
        </Box>
      </div>
      <div
        style={{
          ...DivCenter,
          flexDirection: "column",
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "5rem",
            textAlign: "left",
            height: "auto",
            paddingTop: ".5rem",
            justifyContent: "space-between",
            alignItems: "left",
            paddingBottom: ".5rem",
            borderBottom: `1px solid ${Color.neutralLightGrey}`,
          }}
        >
          <Typography
            style={{
              width: "30%",
              color: Color.neutralMidGrey,
            }}
          >
            Placement Type
          </Typography>
          <Typography sx={{ width: "65%" }}>{review?.placement_type}</Typography>
        </Box>
      </div>
      <div
        style={{
          ...DivCenter,
          flexDirection: "column",
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "5rem",
            textAlign: "left",
            height: "auto",
            paddingTop: ".5rem",
            justifyContent: "space-between",
            alignItems: "left",
            paddingBottom: ".5rem",
            borderBottom: `1px solid ${Color.neutralLightGrey}`,
          }}
        >
          <Typography
            style={{
              width: "30%",
              color: Color.neutralMidGrey,
            }}
          >
            Job Mode
          </Typography>
          <Typography sx={{ width: "65%" }}>{review?.job_mode}</Typography>
        </Box>
      </div>
      <div
        style={{
          ...DivCenter,
          flexDirection: "column",
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "5rem",
            textAlign: "left",
            height: "auto",
            paddingTop: ".5rem",
            justifyContent: "space-between",
            alignItems: "left",
            paddingBottom: ".5rem",
            borderBottom: `1px solid ${Color.neutralLightGrey}`,
          }}
        >
          <Typography
            style={{
              width: "30%",
              color: Color.neutralMidGrey,
            }}
          >
            Sector
          </Typography>
          <Typography sx={{ width: "65%" }}>{review?.sector_name}</Typography>
        </Box>
      </div>
      <FieldEditModal />
    </div>
  );
};

export const SubComponentsJobDescription = ({ review, setReview, reference, formErrors, setFormErrors, isEdit   }) => {
  const [modalData, setModalData] = useState({ open: false, close: () => setModalData((prev) => ({ ...prev, open: false })) });
  const handleModalData = () => {
    let temp_modalData = { ...modalData };
    temp_modalData.open = true;
    setModalData(temp_modalData);
  };
  const { tooltipText} = useSelector((store) => store.rights)
  function FieldEditModal() {
    const [formInput, setFormInput] = useState({ ...review });
    console.log("Comparing values:", formInput, review);

    const handleSaveChanges = async () => {
      const service = new CCService();
      let formError = Validations(formInput);
      try {
        setFormErrors(formError);
      } catch (error) {
        console.log("Error While form Error ", formError);
      }
      const put_body = {
        po_job_id: formInput.po_job_id,
        job_description: send_specialCharReplace(formInput.job_description),
      };
      if (Object.keys(formError).length === 0) {
        try {
          const put_response = await service.put(`/po/ca/applications`, put_body);
          console.log("put_response:", put_response);
          //change page
          setReview((prev) => (prev = formInput));
          setModalData((prev) => ({ ...prev, open: false }));
        } catch (error) {
          console.error("Error while putting job/basic_info:", error);
        }
      }
    };

    return (
      <Modal open={modalData.open} onClose={modalData.close}>
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", padding: "2rem", borderRadius: "40px", width: "700px", height: "360px" }}>
          <Box sx={{ ...DivCenter, justifyContent: "space-between", height: "10%" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                m: "0 0 0 16px",
              }}
            >
              {modalData.open && `Edit`}
            </Typography>
            <IconButton onClick={modalData.close}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              ...DivCenter,
              width: "100%",
              height: "70%",
              justifyContent: "flex-start",
              gap: "1.5rem",
              flexWrap: "wrap",
              padding: "16px",
            }}
          >
            <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", gap: "16px" }}>
              <Box sx={{ ...DivCenter, justifyContent: "space-between" }}>
                <Typography>
                  Job Description<span style={{ color: "red" }}>*</span>
                </Typography>
                <Box sx={{ ...DivCenter, gap: "8px" }}>
                  <InfoOutlined />
                </Box>
              </Box>
              <Box sx={{ width: "100%", height: "100%" }}>
                <ReactQuill
                  theme="snow"
                  style={{ marginBottom: "20px", height: "100px" }}
                  value={get_specialCharReplace(formInput.job_description)}
                  onChange={(value) => setFormInput((prevData) => ({ ...prevData, job_description: value }))}
                  modules={{
                    clipboard: {
                      matchVisual: false,
                    },
                  }}
                />
              </Box>
              <Typography sx={{ fontSize: "12px", color: Color.red, marginTop: ".5rem" }}>{formErrors?.job_description ? "Job Description cannot be empty" : ""}</Typography>
            </Box>
          </Box>
          <Box sx={{ width: "100%", height: "20%", m: "16px 0 0 16px" }}>
            <PrimaryButton variant="contained" className="Rephrasebutton" onClick={handleSaveChanges} sx={{}}>
              Save Changes
            </PrimaryButton>
          </Box>
        </Box>
      </Modal>
    );
  }

  return (
    <div ref={reference} id="JobDescription">
      <Box sx={{ ...DivCenter, gap: "24px", justifyContent: "flex-start", pb: "16px" }}>
        <Typography
          style={{
            color: Color.neutralDarkGrey,
            fontSize: "18px",
            fontWeight: 400,
          }}
        >
          Job Description (JD)
        </Typography>

        <Tooltip title = {isEdit ? '' : tooltipText}>
          <Box>


        <IconButton onClick={() => handleModalData()} sx={{ height: "32px", weight: "32px", background: "rgba(56, 193, 223, 0.10)" ,color: Color.primary1 ,'&:disabled':{background:Color.neutralLightGrey,color: Color.neutralDarkGrey }}} disabled={!isEdit}>
          <EditOutlinedIcon sx={{ fontSize: "14px",  }} />
        </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <div
        // key={fieldIndex}
        style={{
          ...DivCenter,
          flexDirection: "column",
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "5rem",
            textAlign: "left",
            height: "auto",
            paddingTop: ".5rem",
            justifyContent: "space-between",
            alignItems: "left",
            paddingBottom: ".5rem",
            borderBottom: `1px solid ${Color.neutralLightGrey}`,
          }}
        >
          <Typography sx={{ width: "100%" }}>{makeTextBold(get_specialCharReplace(review?.job_description))}</Typography>
        </Box>
      </div>
      {/* Modal Component */}
      <FieldEditModal />
    </div>
  );
};

export const SubComponentsSkillsRequired = ({ review, setReview, reference ,isEdit }) => {
  const [modalData, setModalData] = useState({ open: false, close: () => setModalData((prev) => ({ ...prev, open: false })) });
  const handleModalData = () => {
    let temp_modalData = { ...modalData };
    temp_modalData.open = true;
    setModalData(temp_modalData);
  };
  const { tooltipText} = useSelector((store) => store.rights)
  function FieldEditModal() {
    const [formInput, setFormInput] = useState({ ...review });
    console.log("Comparing values:", formInput, review);

    const handleSaveChanges = async () => {
      const service = new CCService();
      const put_body = {
        po_job_id: formInput.po_job_id,
        ...(formInput.skills.skills.length > 0 && {
          skills: formInput.skills.skills,
        }),
      };
      try {
        const put_response = await service.put(`/po/ca/applications`, put_body);
        console.log("put_response:", put_response);
        //change page
        setReview((prev) => (prev = formInput));
        setModalData((prev) => ({ ...prev, open: false }));
      } catch (error) {
        console.error("Error while putting job/basic_info:", error);
      }
    };

    return (
      <Modal open={modalData.open} onClose={modalData.close}>
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", padding: "2rem", borderRadius: "40px", width: "700px", height: "auto" }}>
          <Box sx={{ ...DivCenter, justifyContent: "space-between" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                m: "0 0 0 16px",
              }}
            >
              {modalData.open && `Edit`}
            </Typography>
            <IconButton onClick={modalData.close}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              ...DivCenter,
              width: "100%",
              justifyContent: "flex-start",
              gap: "1.5rem",
              overflowY: "auto",
              flexWrap: "wrap",
              padding: "16px",
            }}
          >
            <Box className="textfield__basicInfo">
              <Typography class="typographyOfHeader">
                Skills Required<span style={{ color: "red" }}>*</span>
              </Typography>
              {/* <Autocomplete
                                multiple
                                autoComplete="off"
                                placeholder='Type Skills...'
                                options={[]}
                                value={formInput.skills ?? formInput?.skills?.skills}
                                freeSolo
                                onChange={(e, value) =>
                                    setFormInput((prev) => {
                                        const skills = value.map((value) => value.split(',')).flat();
                                        return {
                                            ...prev,
                                            skills: { skills: skills },
                                        };
                                    })
                                }
                                getOptionLabel={(option) => option}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            label={option}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                filterOptions={(options, params) => {
                                    const filtered = options.filter(
                                        (option) => option.toLowerCase().includes(params.inputValue.toLowerCase())
                                    );

                                    // Add the input value as an option if it doesn't exist
                                    if (params.inputValue !== "" && !filtered.includes(params.inputValue)) {
                                        filtered.push(params.inputValue);
                                    }

                                    return filtered;
                                }}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) => <TextField {...params} />}
                            /> */}
              <Autocomplete
                multiple
                autoComplete="off"
                placeholder="Type Skills..."
                options={[]}
                value={formInput?.skills ? formInput?.skills?.skills : []}
                freeSolo
                renderTags={(value, getTagProps) => value.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)}
                renderInput={(params) => <TextField {...params} />}
                getOptionLabel={(option) => option}
                filterOptions={(options, params) => {
                  const filtered = options.filter((option) => option.toLowerCase().includes(params.inputValue.toLowerCase()));

                  // Add the input value as an option if it doesn't exist
                  if (params.inputValue !== "" && !filtered.includes(params.inputValue)) {
                    filtered.push(params.inputValue);
                  }

                  return filtered;
                }}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(e, values) => {
                  // Use the split function to separate values by comma
                  const skills = values.map((value) => value.split(",")).flat();
                  setFormInput((prev) => {
                    return {
                      ...prev,
                      skills: { skills: skills },
                    };
                  });
                }}
              />
            </Box>
          </Box>
          <PrimaryButton variant="contained" className="Rephrasebutton" onClick={handleSaveChanges} sx={{ m: "16px 0 0 16px" }}>
            Save Changes
          </PrimaryButton>
        </Box>
      </Modal>
    );
  }

  return (
    <div ref={reference} id="SkillsRequired">
     
      <Box sx={{ ...DivCenter, gap: "24px", justifyContent: "flex-start", pb: "16px" }}>
        <Typography
          style={{
            color: Color.neutralDarkGrey,
            fontSize: "18px",
            fontWeight: 400,
          }}
        >
          Skills Required
        </Typography>
        {/* {review.status_name === "Review" && <> */}
        <Tooltip title = {isEdit ? '' : tooltipText}>
          <Box>

        <IconButton onClick={() => handleModalData()} sx={{ height: "32px", weight: "32px", background: "rgba(56, 193, 223, 0.10)", color: Color.primary1 ,'&:disabled':{background:Color.neutralLightGrey,color: Color.neutralDarkGrey } }} disabled={!isEdit}>
          <EditOutlinedIcon sx={{ fontSize: "14px",  }} />
        </IconButton>
            
          </Box>
        </Tooltip>
        {/* </>} */}
      </Box>

      {/* {review?.skills && review.skills.skills.map((skills_item, index) => {
                return (
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "5rem",
                                textAlign: "left",
                                height: "auto",
                                paddingTop: ".5rem",
                                justifyContent: "flex-start",
                                alignItems: "left",
                                paddingBottom: ".5rem",
                                borderBottom: `1px solid ${Color.neutralLightGrey}`,
                                background: "#E6E6E6",
                                width: "100%",
                                paddingLeft: '1em',
                                paddingRight: "1rem",
                                borderRadius: "30px"
                            }}
                        >
                            <Typography
                                style={{
                                    width: "auto",
                                    color: Color.neutralMidGrey,
                                }}
                            >
                                {skills_item}
                            </Typography>
                        </Box>
                    </>
                )
            })} */}
      {review?.skills && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          {review.skills.skills.map((skills_item, index) => (
            <Box
              key={index}
              sx={{
                textAlign: "left",
                height: "auto",
                paddingTop: ".5rem",
                paddingBottom: ".5rem",
                borderBottom: `1px solid ${Color.neutralLightGrey}`,
                background: "#E6E6E6",
                width: "auto",
                paddingLeft: "1em",
                paddingRight: "1rem",
                borderRadius: "30px",
                display: "flex",
                alignItems: "center", // Align items vertically in the center
              }}
            >
              <Typography
                style={{
                  width: "auto",
                  color: Color.neutralMidGrey,
                }}
              >
                {skills_item}
              </Typography>
            </Box>
          ))}
        </div>
      )}

      {/* Modal Component */}
      <FieldEditModal />
    </div>
  );
};

// eligiblity criteria
export const SubComponentsEligibilityCriteria = ({ review, setReview, reference,isEdit }) => {
  const [modalData, setModalData] = useState({ open: false, close: () => setModalData((prev) => ({ ...prev, open: false })) });
  const handleModalData = () => {
    let temp_modalData = { ...modalData };
    temp_modalData.open = true;
    setModalData(temp_modalData);
  };
  const { tooltipText} = useSelector((store) => store.rights)
  function FieldEditModal() {
    let temp_formInput = { ...review };
    temp_formInput.eligibility_criteria = temp_formInput.eligibility_criteria?.length > 0 || temp_formInput.eligibility_criteria !== null ? temp_formInput.eligibility_criteria : [];
    const [formInput, setFormInput] = useState(temp_formInput);

    console.log("Comparing values:", formInput, review);
    const [criteria, setCriteria] = useState([]);
    const [gender, setGender] = useState(["Male", "Female", "Others"]);
    const [criteriaLogicPairs, setCriteriaLogicPairs] = useState([{ criterion: null, logic: null, value: "", is_saved: false, is_edit: false, description: "" }]);
    const service = new CCService();
    const [criteriaLogicOptions, setCriteriaLogicOptions] = useState({});
    const [home_state, setHome_state] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [allDreamRoles, setAllDreamRoles] = useState([]);
    const [selectedDreamRoles, setSelectedDreamRoles] = useState(
      review.dream_role_criteria ?? {
        api_url: null,
        logic_id: null,
        field_name: "",
        logic_name: "",
        description: "",
        data_structure: {
          x: null,
          salary: "",
        },
        field_logic_id: null,
      }
    );
    const [isDreamRole, setIsDreamRole] = useState(review.dream_role_criteria === null ? false : true);
    const handleSaveChanges = async () => {
      let put_body = {
        po_job_id: formInput.po_job_id,
        eligibility_criteria: formInput.eligibility_criteria,
        dream_role_criteria: isDreamRole ? { ...selectedDreamRoles, description: selectedDreamRoles.field_name.replace("X", selectedDreamRoles.data_structure.x).replaceAll("salary", selectedDreamRoles.data_structure.salary) } : null,
      };
      try {
        const put_response = await service.put(`/po/ca/applications`, put_body);
        console.log("put_response:", put_response);
        //change page
        let temp = {
          ...formInput,
          dream_role_criteria: isDreamRole ? { ...selectedDreamRoles, description: selectedDreamRoles.field_name.replace("X", selectedDreamRoles.data_structure.x).replaceAll("salary", selectedDreamRoles.data_structure.salary) } : null,
        };
        setReview((prev) => (prev = temp));
        setModalData((prev) => ({ ...prev, open: false }));
      } catch (error) {
        console.error("Error while putting job/basic_info:", error);
      }
    };

    const handleChangeCriterion = async (index, selectedCriterion) => {
      const updatedPairs = [...criteriaLogicPairs];
      updatedPairs[index].criterion = selectedCriterion;
      setCriteriaLogicPairs(updatedPairs);

      let logicsGetter = await logicsDataGetter(selectedCriterion.id);
      console.log("logics getter", logicsGetter, logicsGetter.api_url);
      if (selectedCriterion.id === 2) {
        setHome_state(gender);
      }
      if (logicsGetter.length === 1 && logicsGetter[0].api_url !== null) {
        try {
          const logics_api_url = await logicsApiUrlDataGetter(logicsGetter[0].api_url, "home_state", selectedCriterion.id);
          console.log("logics", logics_api_url);

          const homeStateArray = Array.isArray(logics_api_url) ? logics_api_url.filter((item) => item !== null) : [logics_api_url];

          console.log("homeStateArray", homeStateArray);
          setHome_state(homeStateArray || []);
        } catch (error) {
          console.error("Error setting home_state:", error);
          setHome_state([]);
        }
      }

      // Update the logicOptions for the selected criterion
      setCriteriaLogicOptions((prevOptions) => ({
        ...prevOptions,
        [selectedCriterion.id]: logicsGetter,
      }));
    };
    const handleChangeLogic = (index, selectedLogic) => {
      const updatedPairs = [...criteriaLogicPairs];
      updatedPairs[index].logic = selectedLogic;
      updatedPairs[index].value = updatedPairs[index]?.logic?.data_structure?.multiSelectJson ? [{ major: "", minor: "" }] : "";
      setCriteriaLogicPairs(updatedPairs);
    };

    // from backend
    const setCriteriaFromBackend = (backendData) => {
      console.log("backend data", backendData);
      const criteriaPairs = backendData?.map((item) => {
        const { api_url, logic_id, field_name, logic_name, data_structure, field_logic_id, description } = item;
        console.log("backend item", item);
        return {
          criterion: { id: field_logic_id, field_name },
          logic: { id: logic_id, logic: logic_name, data_structure, api_url: api_url },
          value: data_structure?.multiSelect || data_structure?.singleSelect || data_structure?.range || data_structure?.min || data_structure?.max || data_structure?.equal,
          is_saved: true,
          is_edit: false,
          description: description,
        };
      });
      console.log("what is being set", criteriaPairs);
      setCriteriaLogicPairs(criteriaPairs);
    };

    const transformCriteriaForSave = (criteriaPair) => {
      let dataStructure = {};

      if (criteriaPair.value) {
        if (criteriaPair.logic.logic === "is_between") {
          // Handle 'is_between' logic with range
          dataStructure = {
            range: {
              min: parseInt(criteriaPair.value.min),
              max: parseInt(criteriaPair.value.max),
            },
          };
        } else if (criteriaPair.value !== undefined) {
          let name = Object.keys(criteriaPair.logic.data_structure)[0];
          // Handle 'min' logic
          dataStructure = {
            [name]: name === "singleSelect" ? criteriaPair.value : parseInt(criteriaPair.value),
          };
        } else if (Array.isArray(criteriaPair.value)) {
          // Handle 'max' logic
          dataStructure = {
            multiSelect: criteriaPair.value,
          };
        }
      }

      return {
        field_logic_id: criteriaPair.criterion.id,
        field_name: criteriaPair.criterion.field_name,
        logic_id: criteriaPair.logic.id,
        logic_name: criteriaPair.logic.logic,
        data_structure: dataStructure,
        api_url: criteriaPair.logic.api_url,
        description: criteriaPair.description,
      };
    };

    const handleChangeValue = (index, property, value) => {
      console.log("value inside handle change value", value);
      const updatedPairs = [...criteriaLogicPairs];

      // Check if 'value' is an object (min and max)
      if (updatedPairs[index]?.logic?.logic === "is_between") {
        updatedPairs[index].value = {
          min: null,
          max: null,
          ...updatedPairs[index].value,
          [property]: value,
        };
        updatedPairs[index].description = `${updatedPairs[index].criterion.field_name} ${updatedPairs[index].logic.logic} ${updatedPairs[index].value.min} and ${updatedPairs[index].value.max}`;
      } else if (updatedPairs[index]?.logic?.data_structure?.multiSelectJson) {
        if (!Array.isArray(updatedPairs[index].value)) {
          updatedPairs[index].value = [];
        }
        updatedPairs[index].value = updatedPairs[index].value.map((item) => ({
          ...item,
          [property]: value,
        }));
        // Append the JSON {major:"", minor:""} for a new object
        updatedPairs[index].description = updatedPairs[index].description = `${updatedPairs[index].criterion.field_name} ${updatedPairs[index].logic.logic} ${updatedPairs[index].value.map((obj) => `${obj.major} and ${obj.minor}`).join(", ")}`;
      } else {
        updatedPairs[index].value = value;
        updatedPairs[index].description = `${updatedPairs[index].criterion.field_name} ${updatedPairs[index].logic.logic} ${updatedPairs[index].value}`;
      }

      setCriteriaLogicPairs(updatedPairs);
      // setEligibilityInfoData((prevData) => ({ ...prevData, eligibility_criteria: updatedPairs }))
      setFormInput((prevData) => ({
        ...prevData,
        eligibility_criteria: criteriaLogicPairs.map(transformCriteriaForSave).filter((transformedCriteria) => transformedCriteria !== null),
      }));
    };

    console.log("eligibility criteria sending to backend", formInput);

    const handleAddMore = () => {
      setCriteriaLogicPairs([...criteriaLogicPairs, { criterion: null, logic: null, value: "", is_saved: false, is_edit: false, description: "" }]);
    };

    const handleSaveCriteria = (index, is_edit) => {
      const updatedPairs = [...criteriaLogicPairs];
      if (is_edit) {
        updatedPairs[index].is_edit = false;
      }
      updatedPairs[index].is_saved = true;
      setCriteriaLogicPairs(updatedPairs);
    };

    const handleEditCriteria = async (index) => {
      setEditingIndex(index);
      const editingCriteriaPair = criteriaLogicPairs[index];
      const updatedPairs = criteriaLogicPairs.map((pair, i) => ({
        ...pair,
        is_edit: i === index,
      }));
      // updatedPairs[index].is_edit = true;

      console.log("editingCriteriaPair", editingCriteriaPair);
      console.log("updatedPairs", updatedPairs);

      // Pre-fill Parameter (Criterion)
      const selectedCriterion = criteria.find((criterion) => criterion.id === editingCriteriaPair.criterion.id);
      console.log("selected criteria", selectedCriterion, updatedPairs);

      // Fetch logicsGetter
      let logicsGetter = await logicsDataGetter(selectedCriterion.id);
      if (selectedCriterion.id === 2) {
        setHome_state(gender);
      }

      // Update criteriaLogicOptions with the new logicsGetter
      const updatedOptions = {
        ...criteriaLogicOptions,
        [selectedCriterion.id]: logicsGetter,
      };

      // Pre-fill Logic using the updated criteriaLogicOptions
      const selectedLogic = updatedOptions[selectedCriterion.id]?.find((logic) => logic.id === editingCriteriaPair.logic.id);
      console.log("selectedLogic", selectedLogic);

      if (selectedLogic) {
        updatedPairs[index].criterion = selectedCriterion;
        updatedPairs[index].logic = selectedLogic;

        // Pre-fill Value based on Logic Type
        if (selectedLogic.logic === "is_between") {
          updatedPairs[index].value = {
            min: editingCriteriaPair.value.min,
            max: editingCriteriaPair.value.max,
          };
        } else if (selectedLogic.data_structure?.multiSelect) {
          // Assuming `editingCriteriaPair.value` is an array like ['West Bengal']
          updatedPairs[index].value = editingCriteriaPair.value;
        } else if (selectedLogic.data_structure?.singleSelect) {
          updatedPairs[index].value = editingCriteriaPair.value;
        } else {
          updatedPairs[index].value = editingCriteriaPair.value;
        }

        if (logicsGetter.length === 1 && logicsGetter[0].api_url !== null) {
          try {
            const logics_api_url = await logicsApiUrlDataGetter(logicsGetter[0].api_url, "home_state", selectedCriterion.id);
            console.log("logics", logics_api_url);

            const homeStateArray = Array.isArray(logics_api_url) ? logics_api_url.filter((item) => item !== null) : [logics_api_url];

            console.log("homeStateArray", homeStateArray);
            setHome_state(homeStateArray || []);
          } catch (error) {
            console.error("Error setting home_state:", error);
            setHome_state([]);
          }
        }
      } else {
        // Handle the case where selectedLogic is undefined
        console.error("Selected logic is undefined");
        // You might want to set default values or handle this case appropriately
      }
      setCriteriaLogicPairs(updatedPairs);
      setCriteriaLogicOptions(updatedOptions); // Update criteriaLogicOptions outside the conditional block
      setFormInput((prevData) => ({
        ...prevData,
        eligibility_criteria: updatedPairs.map(transformCriteriaForSave).filter((transformedCriteria) => transformedCriteria !== null),
      }));
    };

    const handleDeleteCriteria = (index) => {
      const updatedPairs = criteriaLogicPairs.filter((pair, i) => i !== index);
      setCriteriaLogicPairs(updatedPairs);
      setFormInput((prevData) => ({
        ...prevData,
        eligibility_criteria: updatedPairs,
      }));
    };

    console.log("criteriaLogicPairs", criteriaLogicPairs);

    useEffect(() => {
      console.log("Brother");
      const handleEffect = async () => {
        console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL);
        const allDreamRoles = await service.get("/po/ca/dreamRole/criteria");
        setAllDreamRoles(allDreamRoles.DroleCriterias);
        let criteria_getter = await criteriaDataGetter();
        setCriteria(criteria_getter);
        setCriteriaFromBackend(formInput.eligibility_criteria);
      };
      handleEffect();
    }, []);

    const criteriaDataGetter = async () => {
      try {
        const res = await service.get(`/po/ca/eligibility/criteria`);
        console.log("posting opportunity data response:", res.eligibilityCriterias);
        return res.eligibilityCriterias;
      } catch (err) {
        console.log(err);
      }
    };

    const logicsDataGetter = async (logic_id) => {
      try {
        const res = await service.get(`/po/ca/eligibility/logics?criteria_id=${logic_id}`);
        console.log("posting opportunity data response:", res.eligibilityLogics);
        return res.eligibilityLogics;
      } catch (err) {
        console.log(err);
      }
    };

    function isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    }

    const logicsApiUrlDataGetter = async (api_url, column_name, criteria_id) => {
      try {
        const res = await service.get(`/${api_url}${api_url.includes("?") ? "&" : "?"}po_job_id=${formInput.po_job_id}`);
        console.log("posting opportunity data response:", res.data);
        if (criteria_id === 18) {
          return {
            major: res.major,
            minor: res.minor,
          };
        }
        if (isJsonString(res.data) === true) {
          return JSON.parse(res.data);
        } else {
          return res.data;
        }
      } catch (err) {
        console.error(err);
        return [];
      }
    };

    const handleAndMajorAndMinor = (index, property, value) => {
      const updatedPairs = [...criteriaLogicPairs];
      const currentPair = updatedPairs[index];

      // Update the current pair's value to an array if it's not already an array
      if (!Array.isArray(currentPair.value)) {
        currentPair.value = [currentPair.value];
      }

      // Push the new major and minor object to the value array
      currentPair.value.push({ major: "", minor: "" });

      // Update the state with the updated pairs
      setCriteriaLogicPairs(updatedPairs);

      // Update eligibility_criteria
      setFormInput((prevData) => ({
        ...prevData,
        eligibility_criteria: updatedPairs.map(transformCriteriaForSave).filter((transformedCriteria) => transformedCriteria !== null),
      }));
    };

    const handleDelete = (event, indexToDelete) => () => {
      event.stopPropagation();
      console.log("delete is clicked ", indexToDelete);
      setCriteriaLogicPairs((prevPairs) => {
        const updatedPairs = [...prevPairs];
        const updatedPair = { ...updatedPairs[indexToDelete] };
        updatedPair.value = updatedPair.value.filter((value, index) => index !== indexToDelete);
        updatedPairs[indexToDelete] = updatedPair;
        return updatedPairs;
      });
    };

    const getDreamRoleData = async (id) => {
      const logicData = await service.get(`/po/ca/eligibility/logics?criteria_id=${id}`);
      if (logicData.eligibilityLogics.length > 0) {
        let data = logicData.eligibilityLogics[0];
        setSelectedDreamRoles((prev) => {
          return {
            ...prev,
            api_url: null,
            logic_id: data.id,
            logic_name: data.logic,
            description: "",
            data_structure: {
              x: null,
              salary: "",
            },
          };
        });
      } else {
        setSelectedDreamRoles((prev) => {
          return {
            ...prev,
            api_url: null,
            logic_id: null,
            logic_name: null,
            description: "",
            data_structure: {
              x: null,
              salary: "",
            },
          };
        });
      }
    };
    const handleDreamRole = (e, key) => {
      if (key === "key") {
        setSelectedDreamRoles((prev) => {
          return {
            ...prev,
            description: e.target.value.field_name.replace("X", prev.data_structure.x).replaceAll("salary", prev.data_structure.salary),
            field_name: e.target.value.field_name,
            field_logic_id: e.target.value.id,
          };
        });
        getDreamRoleData(e.target.value.id);
      } else if (key === "x") {
        setSelectedDreamRoles((prev) => {
          return {
            ...prev,
            description: prev.description.replace("X", e.target.value).replaceAll("salary", prev.data_structure.salary),
            data_structure: {
              salary: prev.data_structure.salary,
              x: e.target.value,
            },
          };
        });
      } else if (key === "salary") {
        setSelectedDreamRoles((prev) => {
          return {
            ...prev,
            description: prev.description.replace("X", prev.data_structure.x).replaceAll("salary", e.target.value),
            data_structure: {
              x: prev.data_structure.x,
              salary: e.target.value,
            },
          };
        });
      }
    };

    return (
      <Modal open={modalData.open} onClose={modalData.close}>
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", padding: "2rem", borderRadius: "40px", width: "800px", height: "auto" }}>
          <Box sx={{ ...DivCenter, justifyContent: "space-between" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                m: "0 0 0 16px",
              }}
            >
              {modalData.open && `Edit`}
            </Typography>
            <IconButton onClick={modalData.close}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              ...DivCenter,
              width: "100%",
              justifyContent: "flex-start",
              gap: "1.5rem",
              overflowY: "auto",
              flexWrap: "wrap",
              padding: "16px",
            }}
          >
            <Box className="textfield__basicInfo">
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography class="typographyOfHeader" style={{ width: "auto" }}>
                  Eligibility Criteria
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox checked={isDreamRole} onChange={(e) => setIsDreamRole(e.target.checked)} />

                  <Typography style={{ width: "auto" }}>Is this a dream Job?</Typography>
                </Box>
              </Box>
              {criteriaLogicPairs?.map(
                (savedInfo, index) =>
                  savedInfo.is_saved && (
                    <>
                      <TableContainer component={Paper} key={index} gap="1rem">
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                          <TableBody sx={{ marginBottom: "1rem" }} className="customTableBody">
                            <TableRow
                              key={savedInfo.criterion.id}
                              sx={{
                                marginBottom: "1rem !important",
                                "&:last-child td, &:last-child th": { border: savedInfo.is_edit ? `2px solid ${Color.primary1}` : 0 },
                                background: savedInfo.is_edit ? "transparent" : Color.neutralLightGrey,
                                gap: "1rem",
                                boxShadow: savedInfo.is_edit ? `inset 0 0 1.5px 1.5px ${Color.primary1}` : "none",
                              }}
                              className="customTableBody"
                              style={{ gap: "1rem" }}
                            >
                              <TableCell component="th" align="left" width="100%">
                                {savedInfo?.description?.replace(/_/g, " ")}
                              </TableCell>
                              <TableCell align="right" sx={{ border: 0, outline: "none" }}>
                                <EditOutlinedIcon sx={{ cursor: "pointer", color: savedInfo.is_edit ? Color.primary1 : "black" }} onClick={() => handleEditCriteria(index)} />
                              </TableCell>
                              <TableCell align="right" sx={{ border: "none", outline: "none" }}>
                                <DeleteOutlineOutlinedIcon sx={{ cursor: "pointer", color: savedInfo.is_edit ? Color.primary1 : "black" }} onClick={() => handleDeleteCriteria(index)} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {index !== criteriaLogicPairs.length - 1 && ( // Add margin after every row except the last one
                        <Box sx={{ height: ".5rem", background: "transparent" }} />
                      )}
                    </>
                  )
              )}
              {criteriaLogicPairs?.map(
                (pair, index) =>
                  (pair.is_saved === false || pair.is_edit) && (
                    <div key={index}>
                      <div style={{ display: "flex", flexDirection: "row", gap: "1.5rem", marginTop: "1.5rem" }}>
                        <FormControl fullWidth sx={{ width: "50%" }}>
                          <InputLabel id={`criterion-label-${index}`}>Parameter</InputLabel>
                          <Select labelId={`criterion-label-${index}`} id={`criterion-select-${index}`} label="Parameter" onChange={(e) => handleChangeCriterion(index, e.target.value)} value={pair.criterion}>
                            {criteria.map((criteriaItem, criteriaIndex) => (
                              <MenuItem key={criteriaIndex} value={criteriaItem}>
                                {criteriaItem?.field_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ width: "50%" }}>
                          <InputLabel id={`logic-label-${index}`}>Logic</InputLabel>
                          <Select labelId={`logic-label-${index}`} id={`logic-select-${index}`} label="Logic" onChange={(e) => handleChangeLogic(index, e.target.value)} value={pair.logic}>
                            {criteriaLogicOptions[pair.criterion?.id]?.map((logicItem, logicIndex) => (
                              <MenuItem key={logicIndex} value={logicItem}>
                                {logicItem?.logic.replace(/_/g, " ")}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      {pair?.logic && (
                        <div>
                          {pair?.logic?.logic === "is_between" && (
                            <div style={{ display: "flex", flexDirection: "row", gap: "1.5rem" }}>
                              <TextField sx={{ marginTop: "2.5rem" }} className="textfield__basicInfo" name={`value-min-${index}`} label="Minimum Value" required type="number" value={pair.value.min} onChange={(e) => handleChangeValue(index, "min", e.target.value)} />
                              <TextField sx={{ marginTop: "2.5rem" }} className="textfield__basicInfo" name={`value-max-${index}`} label="Maximum Value" required type="number" value={pair.value.max} onChange={(e) => handleChangeValue(index, "max", e.target.value)} />
                            </div>
                          )}
                          {pair?.logic?.data_structure?.multiSelect && (
                            <FormControl fullWidth sx={{ marginTop: "2.5rem" }}>
                              <InputLabel id={`value-label-${index}`}>Select Value(s)</InputLabel>
                              <Select
                                labelId={`value-label-${index}`}
                                id={`value-select-${index}`}
                                label="Select Value(s)"
                                multiple
                                onChange={(e) => handleChangeValue(index, "", e.target.value)}
                                value={pair.value || []}
                                renderValue={(selected) => (
                                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {selected.map((value) => (
                                      <Chip
                                        key={value}
                                        label={value}
                                        onDelete={(e) => handleDelete(e, index)}
                                        deleteIcon={<Cancel />} // Remove icon
                                        sx={{ margin: "2px" }} // Adjust spacing between chips
                                        onMouseDown={(event) => {
                                          event.stopPropagation();
                                        }}
                                      />
                                    ))}
                                  </div>
                                )}
                                sx={{
                                  "& .MuiSelect-outlined": {
                                    border: "1px solid #ccc", // border style
                                    borderRadius: "4px", // border radius
                                  },
                                  "& .MuiSelect-selectMenu": {
                                    minHeight: "auto", // override minHeight
                                  },
                                  "& .MuiSelect-outlined.Mui-focused": {
                                    border: "1px solid #1976d2", // focused border color
                                  },
                                  "& .MuiSelect-outlined.Mui-focused.MuiSelect-select": {
                                    backgroundColor: "#f5f5f5", // focused background color
                                  },
                                }}
                              >
                                {pair?.logic?.api_url
                                  ? home_state.map((option, optionIndex) => (
                                      <MenuItem key={optionIndex} value={option}>
                                        <ListItemText primary={option} />
                                      </MenuItem>
                                    ))
                                  : home_state.map((option, optionIndex) => (
                                      <MenuItem key={optionIndex} value={option}>
                                        <ListItemText primary={option} />
                                      </MenuItem>
                                    ))}
                              </Select>
                            </FormControl>
                          )}

                          {pair?.logic?.data_structure?.singleSelect && (
                            <FormControl fullWidth sx={{ marginTop: "2.5rem" }}>
                              <InputLabel id={`value-label-${index}`}>Select Value(s)</InputLabel>
                              <Select
                                labelId={`value-label-${index}`}
                                id={`value-select-${index}`}
                                label="Select Value(s)"
                                onChange={(e) => handleChangeValue(index, "", e.target.value)}
                                value={pair.value || ""}
                                renderValue={(selected) => <Box sx={{ borderRadius: "20px" }}>{selected}</Box>}
                                sx={{
                                  "& .MuiSelect-outlined": {
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                  },
                                  "& .MuiSelect-selectMenu": {
                                    minHeight: "auto",
                                  },
                                  "& .MuiSelect-outlined.Mui-focused": {
                                    border: "1px solid #1976d2",
                                  },
                                  "& .MuiSelect-outlined.Mui-focused.MuiSelect-select": {
                                    backgroundColor: "#f5f5f5",
                                  },
                                }}
                              >
                                {home_state.map((option, optionIndex) => (
                                  <MenuItem key={optionIndex} value={option}>
                                    <ListItemText primary={option} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}

                          {pair?.logic?.data_structure?.multiSelectJson && (
                            <>
                              {pair.value.map((majorMinor, innerIndex) => (
                                <div key={innerIndex} style={{ display: "flex", flexDirection: "row", gap: "1.5rem" }}>
                                  <FormControl fullWidth sx={{ marginTop: "2.5rem" }}>
                                    <InputLabel id={`value-label-${index}-${innerIndex}`}>Select Major</InputLabel>
                                    <Select labelId={`value-label-${index}-${innerIndex}`} id={`value-select-${index}-${innerIndex}`} label="Select Major" onChange={(e) => handleChangeValue(index, "major", e.target.value)} value={majorMinor.major || ""}>
                                      {home_state[0]?.major?.map((option, optionIndex) => (
                                        <MenuItem key={optionIndex} value={option}>
                                          <ListItemText primary={option} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <FormControl fullWidth sx={{ marginTop: "2.5rem" }}>
                                    <InputLabel id={`value-label-${index}-${innerIndex}`}>Select Minor</InputLabel>
                                    <Select labelId={`value-label-${index}-${innerIndex}`} id={`value-select-${index}-${innerIndex}`} label="Select Minor" onChange={(e) => handleChangeValue(index, "minor", e.target.value)} value={majorMinor.minor || ""}>
                                      {home_state[0]?.minor?.map((option, optionIndex) => (
                                        <MenuItem key={optionIndex} value={option}>
                                          <ListItemText primary={option} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  {innerIndex === pair.value.length - 1 && (
                                    <button
                                      className="submitBtn"
                                      type="button"
                                      style={{
                                        height: "44px",
                                        background: "transparent",
                                        border: "1px solid #38C1DF",
                                        color: "black",
                                        width: "300px",
                                        marginTop: "2.7rem",
                                      }}
                                      onClick={() => handleAndMajorAndMinor(index)}
                                    >
                                      <AddCircleOutlinedIcon />
                                    </button>
                                  )}
                                </div>
                              ))}
                            </>
                          )}

                          {pair?.logic?.logic !== "is_between" && !pair?.logic?.data_structure?.multiSelect && !pair?.logic?.data_structure?.singleSelect && !pair?.logic?.data_structure?.multiSelectJson && <TextField sx={{ marginTop: "2.5rem" }} className="textfield__basicInfo" name={`value-${index}`} label="Value" required type={(pair.logic.data_structure.min || pair.logic.data_structure.max) === "number" ? "number" : "text"} value={pair.value} onChange={(e) => handleChangeValue(index, "", e.target.value)} />}
                          <button
                            className="submitBtn"
                            type="button"
                            style={{
                              height: "44px",
                              background: "transparent",
                              border: "1px solid #38C1DF",
                              color: "black",
                              width: "300px",
                              marginTop: "1.5rem",
                            }}
                            onClick={() => handleSaveCriteria(index, pair.is_edit)}
                          >
                            {pair.is_edit ? "Update Criteria" : "Save Criteria"}
                          </button>
                        </div>
                      )}
                    </div>
                  )
              )}
              <button
                className="submitBtn"
                type="button"
                style={{
                  height: "44px",
                  background: criteriaLogicPairs?.some((pair) => pair.is_edit || !pair.is_saved) ? Color.neutralLightGrey : "transparent",
                  border: criteriaLogicPairs?.some((pair) => pair.is_edit || !pair.is_saved) ? `1px solid ${Color.neutralLightGrey}` : "1px solid #38C1DF",
                  color: criteriaLogicPairs?.some((pair) => pair.is_edit || !pair.is_saved) ? Color.neutralDarkGrey : "black",
                  width: "300px",
                  marginTop: "1.5rem",
                }}
                onClick={handleAddMore}
                disabled={criteriaLogicPairs?.some((pair) => pair.is_edit || !pair.is_saved)}
              >
                + Add more criteria and logic
              </button>

              {isDreamRole && (
                <Box className="textfield__basicInfo" sx={{ marginTop: "20px" }}>
                  <FormControl fullWidth>
                    <InputLabel id="dream-rule">Dream Rule</InputLabel>
                    <Select labelId="dream-rule" id="dream-rule" value={allDreamRoles.find((item) => item.id === selectedDreamRoles.field_logic_id) ?? ""} label="Dream Rule" onChange={(e) => handleDreamRole(e, "key")}>
                      {allDreamRoles.map((item) => (
                        <MenuItem value={item}>{item.field_name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {selectedDreamRoles.logic_id !== null && (
                    <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "1rem", gap: "1rem" }}>
                      <TextField type="number" fullWidth value={selectedDreamRoles.data_structure.x} onChange={(e) => handleDreamRole(e, "x")} label="X" />
                      <FormControl fullWidth>
                        <InputLabel id="salary">Salary</InputLabel>
                        <Select labelId="salary" id="salary" value={selectedDreamRoles.data_structure.salary} label="Salary" onChange={(e) => handleDreamRole(e, "salary")}>
                          {["CTC", "Fixed Component"].map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <PrimaryButton variant="contained" className="Rephrasebutton" onClick={handleSaveChanges} sx={{ m: "16px 0 0 16px" }}>
            Save Changes
          </PrimaryButton>
        </Box>
      </Modal>
    );
  }

  return (
    <div ref={reference} id="EligibilityCriteria">
      <Box sx={{ ...DivCenter, gap: "24px", justifyContent: "flex-start", pb: "16px" }}>
        <Typography
          style={{
            color: Color.neutralDarkGrey,
            fontSize: "18px",
            fontWeight: 400,
          }}
        >
          Eligibility Criteria
        </Typography>
        {review.status_name === "Review" && (
          <>
           <Tooltip title = {isEdit ? '' : tooltipText}>
          <Box>

            
            <IconButton onClick={() => handleModalData()} sx={{ height: "32px", weight: "32px", background: "rgba(56, 193, 223, 0.10)", color: Color.primary1 ,'&:disabled':{background:Color.neutralLightGrey,color: Color.neutralDarkGrey }}}  disabled={!isEdit}>
              <EditOutlinedIcon sx={{ fontSize: "14px",  }} /> 
            </IconButton>
          </Box>
        </Tooltip>
          </>
        )}
      </Box>

      {review.eligibility_criteria?.map((savedInfo, index) => (
        <>
          <TableContainer component={Paper} key={index}>
            <Table sx={{ minWidth: 650, borderCollapse: "separate" }} size="small" aria-label="a dense table">
              <TableBody sx={{ marginBottom: "1rem" }}>
                <TableRow
                  key={savedInfo.field_logic_id}
                  sx={{
                    marginBottom: "1rem !important",
                    "&:last-child td, &:last-child th": { border: savedInfo.is_edit ? `2px solid ${Color.primary1}` : 0 },
                    background: Color.neutralLightGrey,
                    gap: "1rem",
                    boxShadow: savedInfo.is_edit ? "2px 2px 4px rgba(0, 0, 0, 0.5)" : "none",
                  }}
                  className="customTableBody"
                  style={{ gap: "1rem" }}
                >
                  <TableCell component="th" align="left" width="100%">
                    {savedInfo?.description?.replace(/_/g, " ")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {index !== review.eligibility_criteria.length - 1 && ( // Add margin after every row except the last one
            <Box sx={{ height: "1rem", background: "transparent" }} />
          )}
        </>
      ))}
      {review.dream_role_criteria !== null && (
        <Box sx={{ padding: "8px 16px", background: Color.neutralLightGrey }}>
          <Typography>{review.dream_role_criteria?.description}</Typography>
        </Box>
      )}
      {/* Modal Component */}
      <FieldEditModal />
    </div>
  );
};

export const SubComponentsCostToCompany = ({ review, setReview, reference, formErrors, setFormErrors ,isEdit }) => {
  const [modalData, setModalData] = useState({ open: false, close: () => setModalData((prev) => ({ ...prev, open: false })) });
  const handleModalData = () => {
    let temp_modalData = { ...modalData };
    temp_modalData.open = true;
    setModalData(temp_modalData);
  };
  const { tooltipText} = useSelector((store) => store.rights)
  function FieldEditModal() {
    let temp_formInput = { ...review };
    const [interval, setInterval] = useState([
      { interval_id: 1, interval_name: "Per Annum" },
      { interval_id: 12, interval_name: "Per Month" },
      { interval_id: 4, interval_name: "Per Quarter" },
      { interval_id: 2, interval_name: "half Yearly" },
    ]);

    temp_formInput.currency = "INR";

    const [formInput, setFormInput] = useState(temp_formInput);

    const [toBeAnnounced, setToBeAnnounced] = useState(false);

    console.log("Comparing values:", formInput, review);

    const handleIsUnpaid = (e) => {
      const temp_checked = e.target.checked;
      console.log("temp unpaid checked", temp_checked);
      setFormInput((prevData) => ({ ...prevData, is_unpaid: temp_checked === true ? 1 : 0, min_ctc: "", max_ctc: "", currency: "", interval: "" }));
      setToBeAnnounced(false);
    };

    const handleCheckToBeAnnounced = (e) => {
      const temp_checked = e.target.checked;
      console.log("to be announced true", temp_checked);
      setToBeAnnounced(temp_checked);
      setFormInput((prevData) => ({ ...prevData, is_unpaid: temp_checked ? 2 : 0, min_ctc: "", max_ctc: "", currency: "", interval: "" }));
    };
    const handleSaveChanges = async () => {
      const service = new CCService();
      let formError = Validations(formInput);
      try {
        setFormErrors(formError);
      } catch (error) {
        console.log("Error While form Error ", formError);
      }
      console.log("formInput logs", formInput);
      const put_body = {
        po_job_id: formInput.po_job_id,
        ...(formInput?.currency !== "" &&
          formInput?.currency !== null && {
            currency: formInput?.currency,
          }),
        ...(formInput.min_ctc !== "" &&
          formInput.min_ctc !== null && {
            min_ctc: parseFloat(formInput.min_ctc) * formInput.interval_per_year,
          }),
        ...(formInput.max_ctc !== "" &&
          formInput.max_ctc && {
            max_ctc: parseFloat(formInput.max_ctc) * formInput.interval_per_year,
          }),
        ...(formInput.interval !== "" &&
          formInput.interval !== null && {
            interval: formInput.interval,
            interval_per_year: formInput.interval_per_year,
          }),
        ...(formInput.other_incentive !== "" &&
          formInput.other_incentive !== null && {
            other_incentive: formInput.other_incentive,
          }),
        is_unpaid: formInput.is_unpaid,
      };
      if (Object.keys(formError).length === 0) {
        try {
          const put_response = await service.put(`/po/ca/applications`, put_body);
          console.log("put_response:", put_response);
          //change page
          setReview((prev) => (prev = formInput));
          setModalData((prev) => ({ ...prev, open: false }));
        } catch (error) {
          console.error("Error while putting job/basic_info:", error);
        }
      }
    };

    const handleFormChange = ({ target }) => {
      const { name, value } = target;

      if (["min_ctc", "max_ctc"].includes(name)) {
        let temp = value.replaceAll(",", "");
        const numberPattern = new RegExp("^\\d+$");

        if (numberPattern.test(temp)) {
          setFormInput((prev) => ({ ...prev, [name]: parseFloat(temp) }));
        } else if (value === "") {
          setFormInput((prev) => ({ ...prev, [name]: 0 }));
        }
      } else {
        setFormInput((prev) => ({ ...prev, [name]: value }));
      }
    };

    const handleChangeCriteria = async (event) => {
      const { name, value } = event.target;
      console.log("name of sector", name, event);
      if (name === "sector") {
        const selectedValue = value;
        setFormInput((prevData) => ({ ...prevData, sector_id: selectedValue }));
      } else {
        const selectedValue = value;
        setFormInput((prevData) => ({ ...prevData, interval: selectedValue.interval_name, interval_per_year: selectedValue.interval_id }));
      }
    };

    const onChange = (ev) => {
      const { value } = ev.target;
      // setCurrencyAbbrev(value);
      console.log("value is", value);
      setFormInput((prevData) => ({ ...prevData, currency: value }));
    };

    return (
      <Modal open={modalData.open} onClose={modalData.close}>
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", padding: "2rem", borderRadius: "40px" }}>
          <Box sx={{ ...DivCenter, justifyContent: "space-between" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                m: "0 0 0 16px",
              }}
            >
              {modalData.open && `Edit`}
            </Typography>
            <IconButton onClick={modalData.close}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              ...DivCenter,
              width: "100%",
              justifyContent: "flex-start",
              gap: "1.5rem",
              overflowY: "auto",
              flexWrap: "wrap",
              padding: "16px",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>
              <Box sx={{ ...DivCenter, width: "100%", justifyContent: "space-between" }}>
                <Typography>
                  Cost to Company (CTC)<span style={{ color: "red" }}>*</span>
                </Typography>
                <Box sx={{ ...DivCenter, gap: "8px" }}>
                  <Checkbox checked={formInput.is_unpaid === 2} onChange={(e) => handleCheckToBeAnnounced(e)} />
                  To be announced
                  <InfoOutlined />
                  <Checkbox checked={formInput.is_unpaid === 0 ? false : formInput.is_unpaid === 1 ? true : false} onChange={(e) => handleIsUnpaid(e)} />
                  Unpaid
                  <InfoOutlined />
                </Box>
              </Box>
              <Box sx={{ ...DivCenter, flexDirection: "column", width: "100%", height: "100%", gap: "24px" }}>
                <Box sx={{ ...DivCenter, width: "100%", gap: "24px" }}>
                  <Box sx={{ width: "100%" }}>
                    <Typography>
                      Fixed Component<span style={{ color: "red" }}>*</span>
                    </Typography>

                    <TextField disabled={formInput.is_unpaid === 1 || formInput.is_unpaid === 2 ? true : false} style={{ background: formInput.is_unpaid === 1 || formInput.is_unpaid === 2 ? Color.neutralLightGrey : "transparent" }} sx={{ width: "100%" }} name="min_ctc" value={formInput.is_unpaid === 1 || formInput.is_unpaid === 2 ? 0 : new Intl.NumberFormat("en-IN").format(formInput.min_ctc)} onChange={(e) => handleFormChange(e)} InputProps={{ startAdornment: <Box sx={{ paddingRight: "12px", marginRight: "8px", borderRight: "1px solid #8B8B8B" }}>₹</Box> }}></TextField>
                    <Typography sx={{ fontSize: "12px", color: Color.red }}>{formErrors?.min_ctc ? "Min CTC cannot be empty" : ""}</Typography>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Typography>Variable Component</Typography>
                    <TextField disabled={formInput.is_unpaid === 1 || formInput.is_unpaid === 2 ? true : false} style={{ background: formInput.is_unpaid === 1 || formInput.is_unpaid === 2 ? Color.neutralLightGrey : "transparent" }} sx={{ width: "100%" }} name="max_ctc" value={formInput.is_unpaid === 1 || formInput.is_unpaid === 2 ? 0 : new Intl.NumberFormat("en-IN").format(formInput.max_ctc)} onChange={(e) => handleFormChange(e)} InputProps={{ startAdornment: <Box sx={{ paddingRight: "12px", marginRight: "8px", borderRight: "1px solid #8B8B8B" }}>₹</Box> }}></TextField>
                  </Box>
                </Box>
                <Box sx={{ ...DivCenter, width: "100%", gap: "24px" }}>
                  <Box sx={{ width: "100%", gap: "24px" }}>
                    <Typography>
                      Currency<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <SelectCurrency disabled={true} id="select-currency" name="currency" value={"INR"} onChange={onChange} style={{ height: "55px", width: "100%", background: formInput.is_unpaid === 1 || formInput.is_unpaid === 2 ? Color.neutralLightGrey : "transparent" }} />
                    </form>
                    <Typography sx={{ fontSize: "12px", color: Color.red }}>{formErrors?.currency ? "Currency should be selected" : ""}</Typography>
                  </Box>
                  <Box sx={{ width: "100%", gap: "24px" }}>
                    <Typography>Interval</Typography>
                    <FormControl fullWidth sx={{}} disabled={formInput.is_unpaid === 1 || formInput.is_unpaid === 2 ? true : false} style={{ background: formInput.is_unpaid === 1 || formInput.is_unpaid === 2 ? Color.neutralLightGrey : "transparent" }}>
                      <Select name="interval" onChange={handleChangeCriteria} value={interval.find((item) => formInput.interval === item.interval_name)}>
                        {interval.map((IntervalItem, index) => (
                          <MenuItem key={IntervalItem.interval_id} value={IntervalItem}>
                            {IntervalItem.interval_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography sx={{ fontSize: "12px", color: Color.red }}>{formErrors?.interval ? "Interval should be selected" : ""}</Typography>
                  </Box>
                </Box>
                <Box sx={{ width: "100%", gap: "24px" }}>
                  <Typography>Other incentives and benefits</Typography>
                  <TextField multiline rows={5} name="other_incentive" placeholder="Mention all other instructions regarding CTC you want to convey to the student" sx={{ width: "100%" }} value={formInput.other_incentive ?? ""} onChange={(e) => handleFormChange(e)}></TextField>
                </Box>
              </Box>
            </Box>
          </Box>
          <PrimaryButton variant="contained" className="Rephrasebutton" onClick={handleSaveChanges} sx={{ m: "16px 0 0 16px" }}>
            Save Changes
          </PrimaryButton>
        </Box>
      </Modal>
    );
  }

  return (
    <div ref={reference} id="CostToCompany">
      <Box sx={{ ...DivCenter, gap: "24px", justifyContent: "flex-start", pb: "16px" }}>
        <Typography
          style={{
            color: Color.neutralDarkGrey,
            fontSize: "18px",
            fontWeight: 400,
          }}
        >
          Cost to Company
        </Typography>
        <Tooltip title = {isEdit ? '' : tooltipText}>
          <Box>

            
        <IconButton onClick={() => handleModalData()} sx={{ height: "32px", weight: "32px", background: "rgba(56, 193, 223, 0.10)" , color: Color.primary1 ,'&:disabled':{background:Color.neutralLightGrey,color: Color.neutralDarkGrey }}} disabled={!isEdit}> 
          <EditOutlinedIcon sx={{ fontSize: "14px", }} />
        </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <div
        style={{
          ...DivCenter,
          flexDirection: "column",
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "5rem",
            textAlign: "left",
            height: "auto",
            paddingTop: ".5rem",
            justifyContent: "space-between",
            alignItems: "left",
            paddingBottom: ".5rem",
            borderBottom: `1px solid ${Color.neutralLightGrey}`,
          }}
        >
          <Typography
            style={{
              width: "30%",
              color: Color.neutralMidGrey,
            }}
          >
            CTC
          </Typography>
          {console.log("ANIMESJHHS", review.min_ctc, typeof review.min_ctc, typeof parseInt(review.min_ctc))}
          {review.is_unpaid === 1 ? (
            <Typography sx={{ width: "65%" }}>Unpaid</Typography>
          ) : isNaN(parseInt(review.min_ctc)) ? (
            <Typography sx={{ width: "65%" }}>To be announced</Typography>
          ) : (
            <Typography sx={{ width: "65%" }}>
              {/* ₹{new Intl.NumberFormat("en-IN").format(review.min_ctc)} {review.max_ctc ? ` - ₹${new Intl.NumberFormat("en-IN").format(review.max_ctc + review.min_ctc)}` : ""} {review.interval} */}
              {`₹${convertToIndianFormat(review.max_ctc + review.min_ctc)}`} {review.interval}
            </Typography>
          )}
        </Box>
      </div>
      <div
        style={{
          ...DivCenter,
          flexDirection: "column",
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "5rem",
            textAlign: "left",
            height: "auto",
            paddingTop: ".5rem",
            justifyContent: "space-between",
            alignItems: "left",
            paddingBottom: ".5rem",
            borderBottom: `1px solid ${Color.neutralLightGrey}`,
          }}
        >
          <Typography
            style={{
              width: "30%",
              color: Color.neutralMidGrey,
            }}
          >
            Other Incentives and Benefits
          </Typography>
          <Typography sx={{ width: "65%" }}>{review?.other_incentive}</Typography>
        </Box>
      </div>
      <FieldEditModal />
    </div>
  );
};
// documents
export const SubComponentsOtherDocuments = ({ review, setReview, reference,isEdit }) => {
  const [modalData, setModalData] = useState({ open: false, close: () => setModalData((prev) => ({ ...prev, open: false })) });
  const handleModalData = () => {
    let temp_modalData = { ...modalData };
    temp_modalData.open = true;
    setModalData(temp_modalData);
  };
  const { tooltipText} = useSelector((store) => store.rights)
  function FieldEditModal() {
    const [formInput, setFormInput] = useState({ ...review });
    console.log("Comparing values:", formInput, review);

    const handleImageUploadSubSection = async (selectedFileName) => {
      console.log("selectedFileName", selectedFileName[0]);
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
        "Content-Type": "multipart/form-data",
      };

      const blob_image = new Blob([selectedFileName[0]], {
        type: "application/pdf",
      });

      const image_data = new FormData();
      image_data.append("image$$" + `${selectedFileName[0].name}`, blob_image, `${selectedFileName[0].name}`);

      try {
        const imageRes = await axios.post(`${process.env.REACT_APP_API_URL}/blog/blog/upload`, image_data, { headers });

        if (imageRes && imageRes.data && imageRes.data.fileInfoArray && imageRes.data.fileInfoArray.length > 0) {
          if (imageRes.data.fileInfoArray[0].image_name !== "undefined") {
            const imageUrl = imageRes.data.fileInfoArray[0].url;
            console.log("Image uploaded:", imageUrl);
            return imageUrl; // Return the URL here
          } else {
            console.error("Invalid image upload response: no URL");
            // Handle the case where the image upload response is missing the URL
            return null; // or throw an error
          }
        } else {
          console.error("Invalid response format for image upload.");
          // Handle the case where the image upload response is not as expected
          return null; // or throw an error
        }
      } catch (err) {
        console.error("Error uploading image:", err);
        // Handle the error as needed
        return null; // or throw an error
      }
    };
    const handleFileChange = async (event) => {
      const files = event.target.files;
      console.log("files...", files);
      if (files) {
        try {
          const fileUrl = await handleImageUploadSubSection(files);
          console.log("fileUrl", fileUrl);
          setFormInput((prevData) => ({
            ...prevData,
            other_docs: {
              ...prevData.other_docs,
              other_docs: [
                ...(prevData.other_docs?.other_docs || []), // Check if other_docs is null, use an empty array if true
                { url: fileUrl },
              ],
            },
          }));
        } catch (error) {
          console.error("Error handling image upload:", error);
        }
      }
    };
    const handleSaveChanges = async () => {
      const service = new CCService();
      const put_body = {
        po_job_id: formInput.po_job_id,
        other_docs: formInput.other_docs.other_docs,
      };
      try {
        const put_response = await service.put(`/po/ca/applications`, put_body);
        console.log("put_response:", put_response);
        //change page
        setReview((prev) => (prev = formInput));
        setModalData((prev) => ({ ...prev, open: false }));
      } catch (error) {
        console.error("Error while putting job/basic_info:", error);
      }
    };

    const handleFileRemove = (index) => {
      setFormInput((prevData) => {
        const newOtherDocs = [...prevData.other_docs.other_docs];
        newOtherDocs.splice(index, 1);

        return {
          ...prevData,
          other_docs: {
            ...prevData.other_docs,
            other_docs: newOtherDocs,
          },
        };
      });
    };

    return (
      <Modal open={modalData.open} onClose={modalData.close}>
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", padding: "2rem", borderRadius: "40px", width: "700px", height: "360px" }}>
          <Box sx={{ ...DivCenter, justifyContent: "space-between", height: "10%" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                m: "0 0 0 16px",
              }}
            >
              {modalData.open && `Edit`}
            </Typography>
            <IconButton onClick={modalData.close}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              ...DivCenter,
              width: "100%",
              height: "70%",
              justifyContent: "flex-start",
              gap: "1.5rem",
              flexWrap: "wrap",
              padding: "16px",
            }}
          >
            <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", gap: "16px" }}>
              <Box sx={{ ...DivCenter, justifyContent: "space-between" }}>
                <Typography>
                  Documents<span style={{ color: "red" }}>*</span>
                </Typography>
                <Box sx={{ ...DivCenter, gap: "8px" }}>
                  <InfoOutlined />
                </Box>
              </Box>
              <Box sx={{ ...DivCenter, flexDirection: "column", width: "100%", height: "100%", gap: "24px" }}>
                <Box sx={{ ...DivCenter, width: "100%", justifyContent: "space-between" }}>
                  <Box sx={{ ...DivCenter, gap: "8px" }}>
                    <Box sx={{ ...DivCenter }}>
                      <input type="file" accept=".pdf" multiple onChange={handleFileChange} style={{ display: "none" }} id="fileInput" />
                      <Button
                        sx={{ textTransform: "none", color: Color.primary1 }}
                        onClick={() => {
                          document.getElementById("fileInput").click();
                        }}
                      >
                        + Add New File
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ ...DivCenter, width: "100%", flexDirection: "column", gap: "16px" }}>
                  {formInput.other_docs ? (
                    formInput.other_docs.other_docs.map((file, index) => (
                      <Box className="box__fileUpload">
                        <Typography sx={{ ml: "5px", textTransform: "none", color: Color.neutralBlack, fontSize: "14px" }}>
                          {index + 1}. {file.url.includes("undefined/") ? file.url.split("undefined/")[1]?.split("/")[0] : null}
                        </Typography>
                        <DeleteOutlined onClick={() => handleFileRemove(index)} sx={{ cursor: "pointer" }} />
                      </Box>
                    ))
                  ) : (
                    <>
                      <Typography sx={{ color: Color.neutralMidGrey }}>You have not attached any documents yet</Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%", height: "20%", m: "16px 0 0 16px" }}>
            <PrimaryButton variant="contained" className="Rephrasebutton" onClick={handleSaveChanges} sx={{}}>
              Save Changes
            </PrimaryButton>
          </Box>
        </Box>
      </Modal>
    );
  }

  return (
    <div ref={reference} id="OtherDocuments">
      <Box sx={{ ...DivCenter, gap: "24px", justifyContent: "flex-start", pb: "16px" }}>
        <Typography
          style={{
            color: Color.neutralDarkGrey,
            fontSize: "18px",
            fontWeight: 400,
          }}
        >
          Documents
        </Typography>

        <Tooltip title = {isEdit ? '' : tooltipText}>
          <Box>

            
        <IconButton onClick={() => handleModalData()} sx={{ height: "32px", weight: "32px", background: "rgba(56, 193, 223, 0.10)", color: Color.primary1 ,'&:disabled':{background:Color.neutralLightGrey,color: Color.neutralDarkGrey } }} disabled={!isEdit}>
          <EditOutlinedIcon sx={{ fontSize: "14px",  }} />
        </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <div
        // key={fieldIndex}
        style={{
          ...DivCenter,
          flexDirection: "column",
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "5rem",
            textAlign: "left",
            height: "auto",
            paddingTop: ".5rem",
            justifyContent: "space-between",
            alignItems: "left",
            paddingBottom: ".5rem",
            borderBottom: `1px solid ${Color.neutralLightGrey}`,
          }}
        >
          <Box sx={{ ...DivCenter, width: "100%", flexDirection: "column", gap: "16px" }}>
            {review.other_docs ? (
              review.other_docs.other_docs.map((file, index) => (
                <Box
                  className="box__fileUpload"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open(file?.url);
                  }}
                >
                  <Typography sx={{ ml: "5px", textTransform: "none", color: Color.neutralBlack, fontSize: "14px" }}>
                    {index + 1}. {file.url.includes("undefined/") ? file.url.split("undefined/")[1]?.split("/")[0] : null}
                  </Typography>
                  {/* <DeleteOutlined onClick={() => handleFileRemove(index)} sx={{ cursor: 'pointer' }} /> */}
                </Box>
              ))
            ) : (
              <>
                <Typography sx={{ color: Color.neutralMidGrey }}>You have not attached any documents yet</Typography>
              </>
            )}
          </Box>
        </Box>
      </div>
      {/* Modal Component */}
      <FieldEditModal />
    </div>
  );
};

export const SubComponentsAdditionalInformation = ({ review, setReview, reference,isEdit }) => {
  const [modalData, setModalData] = useState({ open: false, close: () => setModalData((prev) => ({ ...prev, open: false })) });
  const handleModalData = () => {
    let temp_modalData = { ...modalData };
    temp_modalData.open = true;
    setModalData(temp_modalData);
  };
  const { tooltipText} = useSelector((store) => store.rights)

  function FieldEditModal() {
    const [formInput, setFormInput] = useState({ ...review });
    console.log("Comparing values:", formInput, review);

    const handleSaveChanges = async () => {
      const service = new CCService();
      const put_body = {
        po_job_id: formInput.po_job_id,
        eligibility_description: send_specialCharReplace(formInput.eligibility_description),
      };
      try {
        const put_response = await service.put(`/po/ca/applications`, put_body);
        console.log("put_response:", put_response);
        //change page
        setReview((prev) => (prev = formInput));
        setModalData((prev) => ({ ...prev, open: false }));
      } catch (error) {
        console.error("Error while putting job/basic_info:", error);
      }
    };

    return (
      <Modal open={modalData.open} onClose={modalData.close}>
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", padding: "2rem", borderRadius: "40px", width: "700px", height: "360px" }}>
          <Box sx={{ ...DivCenter, justifyContent: "space-between", height: "10%" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                m: "0 0 0 16px",
              }}
            >
              {modalData.open && `Edit`}
            </Typography>
            <IconButton onClick={modalData.close}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              ...DivCenter,
              width: "100%",
              height: "70%",
              justifyContent: "flex-start",
              gap: "1.5rem",
              flexWrap: "wrap",
              padding: "16px",
            }}
          >
            <Box className="textfield__basicInfo">
              <Typography class="typographyOfHeader">Additional Information</Typography>
              <Box sx={{ width: "100%", height: "100%" }}>
                <ReactQuill
                  theme="snow"
                  style={{ marginBottom: "20px", height: "100px" }}
                  value={get_specialCharReplace(formInput.eligibility_description)}
                  onChange={(value) => setFormInput((prevData) => ({ ...prevData, eligibility_description: value }))}
                  modules={{
                    clipboard: {
                      matchVisual: false,
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%", height: "20%" }}>
            <PrimaryButton variant="contained" className="Rephrasebutton" onClick={handleSaveChanges} sx={{ m: "16px 0 0 16px" }}>
              Save Changes
            </PrimaryButton>
          </Box>
        </Box>
      </Modal>
    );
  }

  return (
    <div ref={reference} id="AdditionalInformation">
      <Box sx={{ ...DivCenter, gap: "24px", justifyContent: "flex-start", pb: "16px" }}>
        <Typography
          style={{
            color: Color.neutralDarkGrey,
            fontSize: "18px",
            fontWeight: 400,
          }}
        >
          Additional Information
        </Typography>
        <Tooltip title = {isEdit ? '' : tooltipText}>
          <Box>
          <IconButton onClick={() => handleModalData()} sx={{ height: "32px", weight: "32px", background: "rgba(56, 193, 223, 0.10)", color: Color.primary1 ,'&:disabled':{background:Color.neutralLightGrey,color: Color.neutralDarkGrey } }} disabled={!isEdit}>
          <EditOutlinedIcon sx={{ fontSize: "14px", }} />
        </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <div
        // key={fieldIndex}
        style={{
          ...DivCenter,
          flexDirection: "column",
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "5rem",
            textAlign: "left",
            height: "auto",
            paddingTop: ".5rem",
            justifyContent: "space-between",
            alignItems: "left",
            paddingBottom: ".5rem",
            borderBottom: `1px solid ${Color.neutralLightGrey}`,
          }}
        >
          <Typography sx={{ width: "100%" }}>{makeTextBold(get_specialCharReplace(review?.eligibility_description))}</Typography>
        </Box>
      </div>
      {/* Modal Component */}
      <FieldEditModal />
    </div>
  );
};
