import React, { useState } from 'react'
import { Box, Button, Input, Tooltip, Typography, Modal, TextField, IconButton } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { extractDateFromDateString } from '../../../../services/specialCharReplace';
import { formatConvertermonthfirst } from '../../../../services/specialCharReplace';
import { Color, DivCenter } from '../../../../GlobalStyles';
import { Close, Login } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';

const AllTheGroupChats = (props) => {
    const { postingOpportunityDetails } = props
    let chatsList = (postingOpportunityDetails.filter((item) => ![1,2,8].includes(item.status_id)))
    console.log("what i am getting here", chatsList);
    const [showAll, setShowAll] = useState(false);
    return (
        <Modal open={props.modalOpen} onClose={props.handleModalClose} style={{ width: "100%" }}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 'auto', maxHeight: '80vh', bgcolor: 'white', padding: '1rem 2rem 2rem 2rem', borderRadius: "30px", overflow: 'auto', display: 'flex', flexDirection: "column", gap: "1.5rem", justifyContent: 'space-between' }}>
                <Typography sx={{ alignItems: "center", justifyContent: "center", textAlign: "center", fontSize: "20px" }}>Select the forum you would like to send a message in</Typography>
                <Box style={{ maxHeight: showAll ? 'none' : '60vh', overflowY: 'auto', marginBottom:".5rem", cursor:"pointer"}}>
                    {chatsList &&
                        (showAll ? (chatsList) : chatsList?.slice(0, 4)).map((status, index) => (
                            <Box onClick={() => props.handleGroupChatOpenInReview(status.po_job_id)} key={index} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding:".5rem" , background:"#FAFAFA", marginBottom:".5rem", borderRadius:"8px" }} value={status.po_job_id}>
                                <Box>
                                    <Typography sx={{ color: Color.primary1, fontSize: "18px" }}>{status.company_name} - {status.designation}</Typography>
                                    <Typography>{status.batch_name}</Typography>
                                </Box>
                                <EastIcon sx={{ color: Color.primary1 }} onClick={() => props.handleGroupChatOpenInReview(status.po_job_id)} />
                            </Box>
                        ))}
                </Box>
                {!showAll && postingOpportunityDetails?.length > 4 && (
                    <button style={{border:"none", color:Color.primary1, background:"transparent"}} onClick={() => setShowAll(true)}>View More</button>
                )}
                {/* <Button sx={{ marginTop: "1.5rem" }} onClick={props.handleSaveChanges}>Clone</Button> */}
            </Box>
        </Modal>
    )
}
export default AllTheGroupChats