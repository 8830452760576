import { CheckCircle, CheckCircleOutline, Close, Edit, EmojiEvents, ErrorOutlineOutlined, LogoutOutlined, MoreHoriz, Person, PersonOutline, RadioButtonUnchecked, WorkHistoryOutlined } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Modal,
  // Select,
  TextField,
  Tooltip,
  // Tooltip,
  Typography,
} from "@mui/material";
import { Color, DivCenter, PrimaryButton, PrimaryButtonOutlined } from "../../../GlobalStyles";
import { ActivityLoader, SmallPageLoader } from "../../reusables/Loader/Loader";
import { myProfileData } from "./MyProfileJSON";

import { UnauthorizedLoginAgainModal } from "../../../services/ErrorFiles";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { get_specialCharReplace, send_specialCharReplace } from "../../../services/specialCharReplace";
import { filterSameValues } from "../../reusables/DataManipulation/datamanipulation";
import { DialogueBox } from "../../reusables/DialogueBox/DialogueBox";
import SideNavigationBar from "../../reusables/SideNavigationBar/SideNavigationBar";
import "./MyProfile.css";
import { student_status } from "./StudentStatus";
import { useSelector } from "react-redux";

const ProfileVerification = (props) => {
  //Requisites
  const service = new CCService();
  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  const batch_id = location.state?.batch_id ? location.state.batch_id : searchParam.get("batch_id");
  const { accessRights, tooltipText } = useSelector((store) => store.rights);
  const user_id = location.state?.user_id ? location.state.user_id : searchParam.get("user_id");

  const [loading, setLoading] = useState({
    page_loading: false,
    activity_loading: false,
  });

  const [toggelValue, setToggelValue] = useState({
    placement: true,
    verification: true,
    editable: false,
    toggleModal: false, //open modal
    clickedToggle: null, //Which toggle button is clicked It is used to change the value after clicking on confirmation modal
    tempStorage: null, //to store the value temporary of options which is clicked
    selectedToggle: null, // this is handle the Display of that doropdown option
    displayText: "",
  });

  const [batch_name, setBatchName] = useState("");
  const [profileVerification, setProfileVerification] = useState(false);
  const toggelOptions = {
    Placed: ["Mark as Unplaced"],
    Unplaced: ["Mark as Placed", "Add to Blacklist", "Opt-out"],
    Opt_out: ["Remove from Opt outs"],
    Blacklist: ["Remove from Blacklist"],
  };

  const VerifiedToggleOptions = (id) => {
    if ([5, 6].includes(id)) {
      return [{ text: "Mark Unverified", value: false }];
    } else {
      return [{ text: "Mark Verified", value: true }];
    }
  };

  const getEditableToggle = (id) => {
    if ([1, 3, 5].includes(id)) {
      return [{ text: "Restrict Editing", value: true }];
    } else {
      return [{ text: "Allow Editing", value: false }];
    }
  };

  //dialogue box
  const [dialogue, setDialogue] = useState({ open: false, content: "", actions: { label: "OK", handler: () => {}, color: Color.neutralBlack, variant: "contained" } });
  console.log("ProfileVerification ~ dialogue:", dialogue);

  console.log("ProfileVerification ~ loading:", loading);
  const [error, setError] = useState({ unauthorized: false });

  //mainData ~ Profile Data
  const [mainData, setMainData] = useState([...myProfileData]);
  console.log("mainData:", mainData);

  const [picture, setPicture] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png");

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    onClose: () => setDeleteModal((prev) => ({ ...prev, show: false })),
    deleteBodyData: {
      item_id: null,
      group_id: null,
      sec_type_id: null,
    },
  });
  const [markStudentAsVerifiedModal, setMarkStudentAsVerifiedModal] = useState({
    open: false,
    onClose: () => setMarkStudentAsVerifiedModal((prev) => ({ ...prev, open: false })),
    flag: "",
    heading: "",
    body: "",
  });
  const [pv_confirmationModal, setPvConfirmationModal] = useState({
    open: false,
    onClose: () => setPvConfirmationModal((prev) => ({ ...prev, open: false })),
    heading: "",
    body: "",
  });
  const [allVerified, setAllVerified] = useState(false);
  const [studentStatusModal, setStudentStatusModal] = useState({
    open: false,
    onClose: () => setStudentStatusModal((prev) => ({ ...prev, open: false })),
    modalFlag: "",
  });

  //Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpeners = Boolean(anchorEl);

  //MAIN PAGE DATA
  const [activeIndexes, setActiveIndexes] = useState({
    sectionIndex: 0,
    ssIndex: 0,
    itemIndex: 0,
    fieldIndex: 0,
  });

  const [userDetails, setUserDetails] = useState({
    user_id: null,
    full_name: "",
    roll_no: null,
    student_status_name: "",
    profile_verification_status_name: "",

    po_job_id: null,
  });
  const currentStudentStatus = student_status.find((data) => data.backend_status_name === userDetails.student_status_name);

  const [openedSections, setOpenedSections] = useState([0]);

  const [extraData, setExtraData] = useState({
    sectors: [{ id: null, value: "" }],
    majors: [{ id: null, value: "" }],
    minors: [{ id: null, value: "" }],
    userDetails: {
      user_id: null,
      roll_no: null,
      first_name: "",
      last_name: "",
    },
  });

  //selected section
  const selectedSectionMainData = mainData.find((section) => section.is_selected === true);

  // MODAL
  const [verifyConfirmationModal, setVerifyConfirmationModal] = useState({
    open: false,
    onClose: () => setVerifyConfirmationModal((prev) => ({ ...prev, open: false })),
    flag: 0,
    heading: "",
    body: "",
  });

  console.log("selectedSectionMainData:", accessRights);

  //FUNCTIONS

  useEffect(() => {
    const handleEffect = async () => {
      setLoading((prev) => ({ ...prev, page_loading: true }));
      await fetchStudentPicture();
      await fetchStudentProfileStatus();
      const batch = await service.get(`/po/ca/batches?batch_id=${batch_id}`);
      const { profile_verification, user_group_name } = batch.batches.find((item) => item.id === parseInt(batch_id));

      setBatchName(user_group_name);
      setProfileVerification(profile_verification === 1 ? true : false);
      const sector_data = await fetchSectors();
      const { major_data, minor_data } = await fetchMajorMinor(user_id);

      // let temp_mainData = JSON.parse(JSON.stringify(mainData));

      //fetch all data
      const main_data = await fetchAllStudentData();
      const remade_main_data = remakingAllMainData(main_data);
      const formatted_main_data = formattingAllMainData(remade_main_data, mainData, major_data, minor_data, sector_data);
      console.log("handleEffect ~ formatted_main_data:", formatted_main_data);
      setMainData(formatted_main_data);
      setLoading((prev) => ({ ...prev, page_loading: false }));
    };
    handleEffect();
  }, []);

  //TODO:fetch all student data : INCOMPLETE
  const fetchAllStudentData = async () => {
    try {
      const response = await service.get(`/po/student/sTypeWiseGetAll?user_id=${user_id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching all student data:", error);
    }
  };

  const remakingAllMainData = (main_data) => {
    let modifiedData = {};
    let initialData = {
      1: {
        1: [],
      },
      2: {
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        16: [],
      },
      3: {
        7: [],
        8: [],
        9: [],
        10: [],
      },
      4: {
        11: [],
        12: [],
      },
      5: {
        13: [],
        14: [],
        15: [],
      },
    };

    for (let key in initialData) {
      modifiedData[key] = {};

      if (main_data[key] && main_data[key].length) {
        console.log("maindata key:", main_data[key]);
        // Iterate through each sec_type_id in initialData[key]
        for (let secTypeId in initialData[key]) {
          if (parseInt(secTypeId) === 1) {
            modifiedData[key][secTypeId] = main_data[key];
          } else {
            // Check if the sec_type_id is present in main_data[key]
            const matchingObj = main_data[key].filter((obj) => parseInt(obj.section_type_id) === parseInt(secTypeId));
            modifiedData[key][secTypeId] = [...matchingObj];
            // // If present, push the data into modifiedData[key]
            // if (matchingObj) {

            // } else {
            //   // If not present, insert an empty array in modifiedData[key]
            //   modifiedData[key][secTypeId] = [];
            // }
          }
        }
      } else {
        modifiedData[key] = initialData[key];
      }
    }

    console.log("remakingAllMainData ~ modifiedData:", modifiedData);
    return modifiedData;
  };

  const formattingAllMainData = (remade_main_data, main_data, major_data, minor_data, sector_data) => {
    let temp_remade_main_data = JSON.parse(JSON.stringify(remade_main_data));
    console.log("formattingAllMainData ~ temp_remade_main_data:", temp_remade_main_data);
    let temp_main_data = JSON.parse(JSON.stringify(main_data));
    temp_main_data.forEach((section, section_index) => {
      section.subSections.forEach((subsection, subsection_index) => {
        const fetched_data = temp_remade_main_data[subsection.group_id][subsection.sec_type_id];
        const lastItem = subsection.items[subsection.items.length - 1];
        if (fetched_data.length)
          for (let i = 0; i < fetched_data.length - 1; i++) {
            subsection.items.push(JSON.parse(JSON.stringify(lastItem)));
          }

        subsection.items.forEach((item, item_index) => {
          if (fetched_data.length) {
            item.item_id = fetched_data[item_index]?.id;

            // Check if mainItem.item_name exists
            if (item.item_name) {
              // Use regular expression to extract numeric part and replace it with itemIndex + 1
              item.item_name = item.item_name.replace(/\d+$/, item_index + 1);
              //handling is_verified -> item level (condition: if item_name is not available)
              item.is_verified = fetched_data[item_index].is_verified === 1 ? true : fetched_data[item_index].is_verified === 0 ? false : null; //for handling fieldwise is_verified (if it is undefined)
            } else {
              item.item_name = null;
              //handling is_verified -> subsection level (condition: if item_name is not available)
              // HERE ~ extra change
              item.is_verified = fetched_data[item_index].is_verified === 1 ? true : fetched_data[item_index].is_verified === 0 ? false : null; //for handling fieldwise is_verified (if it is undefined)
              subsection.is_verified = fetched_data[item_index].is_verified === 1 ? true : fetched_data[item_index].is_verified === 0 ? false : null; //for handling fieldwise is_verified (if it is undefined)
            }

            item.fields.forEach((field, field_index) => {
              if (subsection.group_id === 1 && subsection.sec_type_id === 1) {
                const json = fetched_data[item_index][field.backend_field_name];
                const groupOne_values = json ? JSON.parse(fetched_data[item_index][field.backend_field_name]) : { value: "", is_verified: false };
                console.log("check field is_verified:", field, json);
                field.value = field.data_type === "document_url" ? ([null, "", undefined, "[]"].includes(json) ? [] : JSON.parse(json).map((item) => item.url)) : get_specialCharReplace(groupOne_values?.value ?? "");
                field.is_verified = groupOne_values?.is_verified ?? false;

                console.log("field is_verified check for is_mandatory check:", field.value, field.is_mandatory, field.is_verified);
                //dont show verified button (is_verified === null) if is_mandary === false and value === ''
                if (field.is_mandatory === false && field.value === "") {
                  field.is_verified = null;
                }

                if (["on_sabbatical"].includes(field.backend_field_name)) {
                  if (![null, undefined].includes(groupOne_values.value)) {
                    console.log("Check this value for on_sabbatical:", groupOne_values);
                    // field.value = parseInt(groupOne_values.value) === 0 ? "No" : parseInt(groupOne_values.value) === 1 ? "Yes" : "";
                    field.value = groupOne_values.value;
                  }
                }

                //updating user details
                const this_user_id = fetched_data[0].user_id;
                const this_roll_no = fetched_data[0].roll_no;
                const this_full_name = JSON.parse(fetched_data[0].full_name).value;
                setUserDetails((prev) => ({
                  ...prev,
                  user_id: this_user_id,
                  roll_no: this_roll_no,
                  full_name: this_full_name,
                }));
              } else {
                const groupOther_values = fetched_data[item_index][field.backend_field_name];
                const groupOther_is_verified = fetched_data[item_index].is_verified;

                //condition for major and minor
                if (field.backend_field_name === "specialization_major") {
                  if (![null, undefined].includes(groupOther_values)) {
                    const majors_data = major_data.find((major) => major.id === groupOther_values);
                    field.options = major_data; //will be initialized with default if no data
                    field.specialization_major_id = groupOther_values;
                    field.value = majors_data ? majors_data.value : "";
                  }
                } else if (field.backend_field_name === "specialization_minor") {
                  if (![null, undefined].includes(groupOther_values)) {
                    const minors_data = minor_data.find((minor) => minor.id === groupOther_values);
                    field.options = minor_data; //will be initialized with default if no data
                    field.specialization_minor_id = groupOther_values;
                    field.value = minors_data ? minors_data.value : "";
                  }
                } else if (["final_result_declared"].includes(field.backend_field_name)) {
                  if (![null, undefined].includes(groupOther_values)) {
                    field.value = parseInt(groupOther_values) === 0 ? "No" : "Yes";
                  }
                }
                //condition for sector_id
                else if (field.backend_field_name === "sector_id") {
                  console.log("item.fields.forEach ~ sector_data:", sector_data);
                  const sector = sector_data.find((sector) => sector.id === groupOther_values);
                  field.options = sector_data; //will be initialized with default if no data
                  field.sector_id = groupOther_values;
                  field.value = sector ? sector.value : "";
                } else if (field.backend_field_name === "backlogs") {
                  field.value = [null, "", undefined, "[]"].includes(groupOther_values) ? [] : get_specialCharReplace(groupOther_values);
                } else {
                  field.value = field.data_type === "document_url" ? ([null, "", undefined, "[]"].includes(groupOther_values) ? [] : JSON.parse(groupOther_values).map((item) => item.url)) : get_specialCharReplace(groupOther_values);
                  // field.is_verified = groupOther_is_verified ? true : false;
                }
              }
            });
          } else {
            //filling options
            item.fields.forEach((field) => {
              if (field.backend_field_name === "specialization_major") {
                field.options = major_data; //will be initialized with default if no data
              } else if (field.backend_field_name === "specialization_minor") {
                field.options = minor_data; //will be initialized with default if no data
              }
              //condition for sector_id
              else if (field.backend_field_name === "sector_id") {
                field.options = sector_data; //will be initialized with default if no data
              }
            });
          }

          //is_verified of subsection is handled -> if all items' is_verified is true then the subsections' is_verified becomes true
          const allItemsVerified = subsection.items.every((item) => item.is_verified === true);
          if (allItemsVerified) {
            subsection.is_verified = true;
          } else {
            subsection.is_verified = subsection.is_verified !== null ? false : null;
          }
          // }
        });

        //for non-mandatory subsections, do not show Mark as Verified if there is no data filled
        if (subsection.items[0].is_mandatory === false && subsection.items[0].item_id === null) {
          subsection.is_verified = null;
        }

        const allSSVerified = calculateSectionVerification(section);
        section.is_verified = allSSVerified;
        console.log("allSSVerified ~ allSSVerified:", allSSVerified);
      });
    });
    console.log("temp_main_data.forEach ~ temp_main_data:", temp_main_data);
    return temp_main_data;
  };

  //check first render section is_verifieds
  const calculateSectionVerification = (section) => {
    let sectionVerification = [];

    section.subSections.forEach((subSection, subSection_index) => {
      if (subSection.is_verified === false) sectionVerification[subSection_index] = false;
      else if (subSection.is_verified === true) sectionVerification[subSection_index] = true;
      else if (subSection.is_verified === null && subSection.group_id !== 1) {
        sectionVerification[subSection_index] = null;
      } else if (subSection.is_verified === null && subSection.group_id === 1) {
        const fields_no_doc_verify = subSection.items[0].fields.filter((field) => !["supported_docs", "work_ex_docs"].includes(field.backend_field_name));
        const field_isVerified = fields_no_doc_verify.map((field) => field.is_verified);
        if (field_isVerified.includes(false)) {
          sectionVerification[subSection_index] = false;
        } else if (field_isVerified.includes(true)) sectionVerification[subSection_index] = true;
        else sectionVerification[subSection_index] = null;
      }
    });

    if (sectionVerification.includes(false)) return false;
    else if (sectionVerification.includes(true)) return true;
    else return null;
  };

  //check for if every section is verified
  function handleCheckAllVerification(data) {
    console.log("handleCheckAllVerification ~ data:", data);
    if (!data || typeof data !== "object") {
      return true; // If data is null or not an object, consider it as verified
    }

    for (const key in data) {
      if (key === "is_verified" && data[key] !== null && data[key] !== true) {
        return false; // If is_verified is not null and not true, return false
      }

      if (Array.isArray(data[key])) {
        for (const item of data[key]) {
          if (!handleCheckAllVerification(item)) {
            return false; // Recursively check each item in the array
          }
        }
      } else if (typeof data[key] === "object") {
        if (!handleCheckAllVerification(data[key])) {
          return false; // Recursively check nested objects
        }
      }
    }

    return true; // If no false condition is met, consider it as verified
  }

  //check all sections if verified
  const checkAllVerification = (data = mainData) => {
    let check_verification = [];
    // for (const data of mainData) {
    // const result = handleCheckAllVerification(data);
    check_verification = data.map((section) => section.is_verified);
    console.log("checkAllVerification ~ check_verification:", check_verification);
    // }
    console.log("check_verification:", check_verification);
    return !check_verification.includes(false);
  };

  function checkMandatorySectionsFilled() {
    /*  
           mainData is made with is_mandatory and value - values, so we just need to check if its a group_id 1, 
           then if field_is_mandatory is true or not, if it is then check value;
           similarly if not group_id 1, then check item.is_mandatory is true or not, if it is then check value
       */
    let flag = true;
    for (const section in mainData) {
      const subsections = mainData[section].subSections;
      for (const subsection in subsections) {
        const items = subsections[subsection].items;
        console.log("checkMandatorySectionsFilled ~ items:", items);
        for (const item in items) {
          console.log("checkMandatorySectionsFilled ~ (items[item]:", items[item]);
          if (subsections[subsection].group_id === 1) {
            const fields = items[item].fields;
            for (const field in fields) {
              console.log("Is madatory check ~ field:", fields[field].is_mandatory, fields[field].value ?? "N/A", flag);
              if (fields[field].is_mandatory === true && ["", null, undefined].includes(fields[field].value)) {
                flag = false;
              }
              // else flag = true;
            }
          } else {
            if (items[item].is_mandatory === true && ["", null, undefined].includes(items[item].item_id)) {
              flag = false;
            }
            console.log("Is madatory check ~ item", items[item].is_mandatory, items[item].item_name, items[item].item_id ?? "N/A", flag);
            // else flag = true;
          }
        }
      }
    }
    console.log("checkMandatorySectionsFilled ~ flag:", flag);
    return flag;
  }

  const fetchSectors = async () => {
    try {
      const sectors = await service.get(`/po/ca/sector?section_type_id=8`);
      console.log("replaceSectorIdWithOptions ~ sectors:", sectors);
      const remade_sector = sectors.sectors.map((item) => ({
        id: item.id,
        value: item.sector_name,
      }));
      setExtraData((prev) => ({
        ...prev,
        sectors: remade_sector,
      }));
      return remade_sector;
    } catch (error) {
      console.error("Error with fetching sectors:", error);
    }
  };

  const fetchMajorMinor = async (user_id) => {
    try {
      const majorminorResponse = await service.get(`/po/ca/poMajorMinor?user_id=${user_id}`);

      const majors = majorminorResponse.data.map((item) => ({
        id: item.id,
        value: item.major,
      }));
      const minors = majorminorResponse.data.map((item) => ({
        id: item.id,
        value: item.minor,
      }));

      setExtraData((prev) => ({ ...prev, majors, minors }));
      return { major_data: majors, minor_data: minors };
    } catch (error) {
      console.error("Error with fetching sectors:", error);
    }
  };

  //FIXME: Use this to get the studnet pofile picture
  const fetchStudentPicture = async () => {
    try {
      const user_picture = await service.get(`/user/pictureById?user_id=${user_id}`);
      console.log("fetchStudentPicture ~ user_picture:", user_picture.url);
      if (user_picture.url) setPicture(user_picture.url);
    } catch (error) {
      console.error("Error with fetching sectors:", error);
    }
  };

  const fetchStudentProfileStatus = async () => {
    try {
      const response = await service.get(`/po/ca/allStudentsOfABatch?ug_id=${batch_id}&user_id=${user_id}&page=1&limit=100`);
      console.log("fetchStudentProfileStatus ~ response:", response);
      setUserDetails((prev) => ({
        ...prev,
        student_status_name: response.data[0].student_status_name,
        po_profile_verfication_id: response.data[0].po_profile_verfication_id,
        po_student_status_id: response.data[0].po_student_status_id,

        profile_verification_status_name: response.data[0].po_profile_verfication_status_name,
        po_job_id: response.data[0].po_student_status_id === 7 ? response.data[0].po_job_id : null,
      }));
      setToggelValue((prev) => {
        return {
          placement: response.data[0].student_status_name,
          verification: [5, 6].includes(response.data[0].po_profile_verfication_id) ? true : false,
          editable: [1, 3, 5].includes(response.data[0].po_profile_verfication_id) ? true : false,
          toggleModal: false,
          clickedToggle: null,
          tempStorage: null,
        };
      });
    } catch (error) {
      console.error("Error fetching student status and profile verification status:", error);
    }
  };

  const handleSideNavClick = (index) => {
    let temp_mainData = JSON.parse(JSON.stringify(mainData));
    setActiveIndexes((prev) => ({ ...prev, sectionIndex: index }));
    if (!openedSections.includes(index)) {
      setOpenedSections((prev) => [...prev, index]);
    }
    //main
    temp_mainData.forEach((section) => {
      if (section.is_selected === true) section.is_selected = false;
    });
    temp_mainData[index].is_selected = true;
    setMainData(temp_mainData);
  };

  //keep this for future reference
  const requestBodyMaker = (currentFields, mainData, current_subsection, item_index, field_index) => {
    const temp_currentFields = [...currentFields];
    const temp_mainData = [...mainData];
    console.log("ANIMESHHHH123", temp_currentFields);
    const reqJSON = {
      Group1: () => {
        console.log("ANIMEHSHSHSHSHS", currentFields[field_index]?.backend_field_name, currentFields[field_index].value, typeof currentFields[field_index].value);

        if (["supported_docs", "work_ex_docs"].includes(currentFields[field_index]?.backend_field_name)) {
          console.log("BODY!#$");
          const body = {
            user_id: userDetails.user_id ?? extraData.userDetails.user_id,
            [currentFields[field_index]?.backend_field_name]:
              [null, "", "[]", [""]].includes(currentFields[field_index]?.value) || currentFields[field_index]?.value.length === 0
                ? []
                : Array.isArray(currentFields[field_index]?.value) && currentFields[field_index]?.value.length > 0
                ? currentFields[field_index]?.value.value.map((item) => {
                    return {
                      is_verified: true,
                      url: item,
                    };
                  })
                : [{ is_verified: true, url: currentFields[field_index]?.value }],
          };

          return {
            ...body,
          };
        } else {
          console.log("BODY!#$12");
          return {
            user_id: userDetails.user_id ?? extraData.userDetails.user_id,
            [currentFields[field_index]?.backend_field_name]: {
              value: currentFields[field_index]?.backend_field_name === "on_sabbatical" ? (parseInt(send_specialCharReplace(currentFields[field_index]?.value)) === 0 ? "No" : "Yes") : send_specialCharReplace(currentFields[field_index]?.value),
              is_verified: !currentFields[field_index]?.is_verified,
            },
          };
        }
      },
      Group2: () => {
        return {
          id: current_subsection.items[item_index].item_id,
          ...temp_currentFields.reduce((acc, field) => {
            if (field.backend_field_name === "specialization_major") {
              const major = extraData.majors.find((major) => major.value === field.value);
              acc[field.backend_field_name] = major ? major.id : null;
            } else if (field.backend_field_name === "specialization_minor") {
              const minor = extraData.majors.find((minor) => minor.value === field.value);
              acc[field.backend_field_name] = minor ? minor.id : null;
            } else if (["final_result_declared"].includes(field.backend_field_name)) {
              acc[field.backend_field_name] = field.value.toLowerCase() === "no" ? 0 : 1;
            } else if (field.data_type === "document_url") {
              console.log("ANIESBJHSBFNBF", field);
              acc[field.backend_field_name] =
                Array.isArray(field.value) && field.value.length > 0
                  ? field.value.map((item) => {
                      return {
                        is_verified: true,
                        url: item,
                      };
                    })
                  : [null, "", "[]"].includes(field.value) || field.value.length === 0
                  ? []
                  : [{ is_verified: true, url: field.value }];
            } else {
              acc[field.backend_field_name] = send_specialCharReplace(field.value);
            }
            return acc;
          }, {}),
          is_verified: current_subsection.items[item_index].is_verified ? 0 : 1,
        };
      },
      Group3: () => {
        return {
          id: current_subsection.items[item_index].item_id,
          ...temp_currentFields.reduce((acc, field) => {
            if (field.backend_field_name === "sector_id") {
              const sector = extraData.sectors.find((sector) => sector.value === field.value);
              acc[field.backend_field_name] = sector ? sector.id : null;
            } else if (field.data_type === "document_url") {
              acc[field.backend_field_name] =
                Array.isArray(field.value) && field.value.length > 0
                  ? field.value.map((item) => {
                      return {
                        is_verified: true,
                        url: item,
                      };
                    })
                  : [null, "", "[]"].includes(field.value) || field.value.length === 0
                  ? []
                  : [{ is_verified: true, url: field.value }];
            } else acc[field.backend_field_name] = send_specialCharReplace(field.value);
            return acc;
          }, {}),
          is_verified: current_subsection.items[item_index].is_verified ? 0 : 1,
        };
      },

      Group4: () => {
        return {
          id: current_subsection.items[item_index].item_id,
          ...temp_currentFields.reduce((acc, field) => {
            if (field.data_type === "document_url") {
              acc[field.backend_field_name] =
                Array.isArray(field.value) && field.value.length > 0
                  ? field.value.map((item) => {
                      return {
                        is_verified: true,
                        url: item,
                      };
                    })
                  : [null, "", "[]"].includes(field.value) || field.value.length === 0
                  ? []
                  : [{ is_verified: true, url: field.value }];
            } else acc[field.backend_field_name] = send_specialCharReplace(field.value);

            return acc;
          }, {}),
          is_verified: current_subsection.items[item_index].is_verified ? 0 : 1,
        };
      },

      Group5: () => {
        return {
          id: current_subsection.items[item_index].item_id,
          ...temp_currentFields.reduce((acc, field) => {
            if (field.data_type === "document_url") {
              acc[field.backend_field_name] =
                Array.isArray(field.value) && field.value.length > 0
                  ? field.value.map((item) => {
                      return {
                        is_verified: true,
                        url: item,
                      };
                    })
                  : [null, "", "[]"].includes(field.value) || field.value.length === 0
                  ? []
                  : [{ is_verified: true, url: field.value }];
            } else {
              acc[field.backend_field_name] = send_specialCharReplace(field.value);
            }
            return acc;
          }, {}),
          is_verified: current_subsection.items[item_index].is_verified ? 0 : 1,
        };
      },
    };

    // Determine the group_id from current_subsection
    const group_id = current_subsection.group_id;

    // Get the corresponding group object from reqJSON
    let groupObject = reqJSON[`Group${group_id}`]();
    if (!current_subsection.items[item_index].item_id && group_id !== 1) {
      delete groupObject.id;
      groupObject.user_id = userDetails.user_id;
      groupObject.sec_type_id = current_subsection.sec_type_id;
    }

    // Create the body object based on the group
    const body = {
      group_id: group_id,
      sec_type_id: current_subsection.sec_type_id,
      body: groupObject || {}, // Use the groupObject, or an empty object if not found
    };
    console.log("requestBodyMaker ~ body:", body);
    return body;
  };

  const handleVerifySubSection = (ss_index) => {
    const check_mandatorySections_filled = checkMandatorySectionsFilled();
    console.log({ check_mandatorySections_filled });

    if (check_mandatorySections_filled) {
      const is_verified = mainData[activeIndexes.sectionIndex].subSections[ss_index].is_verified;
      setVerifyConfirmationModal((prev) => ({ ...prev, open: true, flag: 0, body: is_verified ? "Are you sure you want to unverify this section?" : "Are you sure you want to verify this section?" }));
      setActiveIndexes((prev) => ({ ...prev, ssIndex: ss_index }));
    } else {
      setDialogue((prev) => ({
        ...prev,
        open: true,
        title: "Data not filled",
        content: "Student has not completed their Profile",
        actions: { ...prev.actions, handler: () => closeDialogue() },
      }));
    }
  };

  const handleVerifyItem = (item_index, ss_index) => {
    const check_mandatorySections_filled = checkMandatorySectionsFilled();
    console.log({ check_mandatorySections_filled });

    if (check_mandatorySections_filled) {
      console.log("THIS IS CALLED");
      setActiveIndexes((prev) => ({ ...prev, ssIndex: ss_index, itemIndex: item_index }));
      const temp_mainData = [...mainData];
      let is_verified = temp_mainData[activeIndexes.sectionIndex].subSections[ss_index].items[item_index].is_verified;
      const subsection = temp_mainData[activeIndexes.sectionIndex].subSections[ss_index];
      const item = subsection.items[item_index];
      let putVerify_body = {};
      if (subsection.group_id === 2) {
        putVerify_body = {
          group_id: subsection.group_id,
          sec_type_id: subsection.sec_type_id,
          body: {
            id: item.item_id,
            ...item.fields.reduce((acc, field) => {
              if (field.backend_field_name === "specialization_major") {
                const major = extraData.majors.find((major) => major.value === field.value);
                acc[field.backend_field_name] = major ? major.id : null;
              } else if (field.backend_field_name === "specialization_minor") {
                const minor = extraData.majors.find((minor) => minor.value === field.value);
                acc[field.backend_field_name] = minor ? minor.id : null;
              } else if (["final_result_declared"].includes(field.backend_field_name)) {
                acc[field.backend_field_name] = field.value.toLowerCase() === "no" ? 0 : 1;
              } else if (field.data_type === "document_url") {
                acc[field.backend_field_name] = [null, "[]"].includes(field.value) ? [] : field.value;
              } else {
                acc[field.backend_field_name] = send_specialCharReplace(field.value);
              }
              return acc;
            }, {}),
            is_verified: is_verified === true ? 0 : 1, //we want the opp value
          },
        };
      } else if (subsection.group_id === 3) {
        console.log("ANIMESH!@#", item.fields);
        putVerify_body = {
          group_id: subsection.group_id,
          sec_type_id: subsection.sec_type_id,
          body: {
            id: item.item_id,
            ...item.fields.reduce((acc, field) => {
              if (field.backend_field_name === "sector_id") {
                const sector = extraData.sectors.find((sector) => sector.value === field.value);
                acc[field.backend_field_name] = sector ? sector.id : null;
              } else if (field.data_type === "document_url") {
                acc[field.backend_field_name] = [null, "[]"].includes(field.value) ? [] : field.value;
              } else acc[field.backend_field_name] = send_specialCharReplace(field.value);
              return acc;
            }, {}),
            is_verified: is_verified === true ? 0 : 1, //we want the opp value
          },
        };
      } else {
        putVerify_body = {
          group_id: subsection.group_id,
          sec_type_id: subsection.sec_type_id,
          body: {
            id: item.item_id,
            ...item.fields.reduce((acc, field) => {
              if (field.data_type === "document_url") {
                acc[field.backend_field_name] =
                  Array.isArray(field.value) && field.value.length > 0
                    ? field.value.map((item) => {
                        return {
                          is_verified: true,
                          url: item,
                        };
                      })
                    : [null, "", "[]"].includes(field.value) || field.value.length === 0
                    ? []
                    : [{ is_verified: true, url: field.value }];
              } else {
                acc[field.backend_field_name] = send_specialCharReplace(field.value);
              }
              return acc;
            }, {}),
            is_verified: is_verified === true ? 0 : 1, //we want the opp value
          },
        };
      }
      try {
        const response = service.put(`/po/student/sTypeWisePut`, putVerify_body);
        console.log("STUDENTDETAILDATA", userDetails);

        if ([1, 2].includes(userDetails.po_profile_verfication_id)) {
          const body = {
            ug_id: batch_id,
            user_id: user_id,
            po_profile_verfication_id: getverificationID(toggelValue),
          };
          console.log("ANIMESHHH1", body);

          const data = service.put(`/po/ca/updateProfileVerfication`, body);
          setUserDetails((prev) => {
            return {
              ...prev,
              po_profile_verfication_id: getverificationID(toggelValue),
            };
          });
        }
        console.log("handleVerifyField ~ response:", response);
        //on SUCCESS
        temp_mainData[activeIndexes.sectionIndex].subSections[ss_index].items[item_index].is_verified = !is_verified;
        const items = temp_mainData[activeIndexes.sectionIndex].subSections[ss_index].items;

        //changing ss is_verified based on item is_verified
        if (items.filter((item) => item.is_verified === true).length === items.length) {
          if (temp_mainData[activeIndexes.sectionIndex].subSections[ss_index].is_verified !== null) {
            // handleVerifySubSection(ss_index);
            temp_mainData[activeIndexes.sectionIndex].subSections[ss_index].is_verified = true;
          }
        } else {
          if (temp_mainData[activeIndexes.sectionIndex].subSections[ss_index].is_verified !== null) {
            // handleVerifySubSection(ss_index);
            temp_mainData[activeIndexes.sectionIndex].subSections[ss_index].is_verified = false;
          }
        }
        const check_verification = handleCheckAllVerification(temp_mainData[activeIndexes.sectionIndex].subSections);
        if (check_verification) {
          // if (temp_mainData[activeIndexes.sectionIndex].is_verified !== null) {
          temp_mainData[activeIndexes.sectionIndex].is_verified = true;
          // }
        } else {
          // if (temp_mainData[activeIndexes.sectionIndex].is_verified !== null) {
          temp_mainData[activeIndexes.sectionIndex].is_verified = false;
          // }
        }
        setMainData(temp_mainData);
      } catch (error) {
        console.error("Error while verifying an item:", error);
      }
      //check for all parts to be verified
      const check = checkAllVerification();
      setAllVerified(check);
      if (check && !is_verified) {
        setMarkStudentAsVerifiedModal((prev) => ({ ...prev, open: true, flag: 1, heading: "Verification Complete", body: "You have verified all the fields. Would you wanna verify the student?" }));
      } else if (!check && is_verified) {
        handleUnverifyStudent();
        fetchStudentProfileStatus();
      }
    } else {
      setDialogue((prev) => ({
        ...prev,
        open: true,
        title: "Data not filled",
        content: "Student has not completed their Profile",
        actions: { ...prev.actions, handler: () => closeDialogue() },
      }));
    }
  };

  const handleMainSectionVerificationUpdate = (data) => {
    let tempFlag = true;
    data.map((item, index) => {
      if (index === activeIndexes.sectionIndex) {
        tempFlag = true;
        let temp = item.subSections.find((item) => item.is_verified === false);
        if (temp === undefined) {
          item.subSections.map((ele) => {
            temp = ele.items.find((x) => x.is_verified === false);
            if (temp === undefined) {
              ele.items.map((field) => {
                temp = field.fields.find((x) => x.is_verified === false && x.field_name !== "Attach Documents");

                if (temp !== undefined) {
                  tempFlag = false;
                }
              });
            } else {
              tempFlag = false;
            }
          });
        } else {
          tempFlag = false;
        }
      }
    });

    return tempFlag;
  };

  const handleVerifyField = async (field_index, item_index, ss_index) => {
    const check_mandatorySections_filled = checkMandatorySectionsFilled();
    console.log({ check_mandatorySections_filled });

    if (check_mandatorySections_filled) {
      setActiveIndexes((prev) => ({ ...prev, ssIndex: ss_index, itemIndex: item_index, fieldIndex: field_index }));
      const temp_mainData = [...mainData];
      let is_verified = temp_mainData[activeIndexes.sectionIndex].subSections[ss_index].items[item_index].fields[field_index].is_verified;
      const subsection = temp_mainData[activeIndexes.sectionIndex].subSections[ss_index];
      // const items = temp_mainData[activeIndexes.sectionIndex].subSections[ss_index].items;
      // const fields = subsection.items[item_index].fields;
      const field = subsection.items[item_index].fields[field_index];
      const value = field.backend_field_name === "on_sabbatical" ? (parseInt(field.value) === 0 ? "No" : "Yes") : field.value;
      const putVerify_body = {
        group_id: subsection.group_id,
        sec_type_id: subsection.sec_type_id,
        body: {
          user_id: user_id,
          [field.backend_field_name]: ["document_url"].includes(field.data_type)
            ? value.map((item) => {
                return { is_verified: !is_verified, url: item };
              })
            : { value: value, is_verified: !is_verified },
        },
      };
      // const body = requestBodyMaker(subsection.items[item_index].fields, temp_mainData, subsection);

      try {
        const response = service.put(`/po/student/sTypeWisePut`, putVerify_body);
        console.log("STUDENTDETAILDATA", userDetails);
        if ([1, 2].includes(userDetails.po_profile_verfication_id)) {
          const body = {
            ug_id: batch_id,
            user_id: user_id,
            po_profile_verfication_id: getverificationID(toggelValue),
          };
          console.log("ANIMESHHH2", body);

          const data = service.put(`/po/ca/updateProfileVerfication`, body);
          setUserDetails((prev) => {
            return {
              ...prev,
              po_profile_verfication_id: getverificationID(toggelValue),
            };
          });
        }
        console.log("handleVerifyField ~ response:", response);
        temp_mainData[activeIndexes.sectionIndex].subSections[ss_index].items[item_index].fields[field_index].is_verified = !is_verified;
        console.log("handleVerifySubSection ~ is_verified:", is_verified, temp_mainData);

        //set section is_verifid accordingly
        const check_verification = handleCheckAllVerification(temp_mainData[activeIndexes.sectionIndex].subSections);
        if (check_verification) {
          if (temp_mainData[activeIndexes.sectionIndex].is_verified !== null) {
            temp_mainData[activeIndexes.sectionIndex].is_verified = true;
          }
        } else {
          if (temp_mainData[activeIndexes.sectionIndex].is_verified !== null) {
            temp_mainData[activeIndexes.sectionIndex].is_verified = false;
          }
        }

        temp_mainData[activeIndexes.sectionIndex].is_verified = handleMainSectionVerificationUpdate(temp_mainData);
        setMainData(temp_mainData);
      } catch (error) {
        console.error("Error while putting verification in Fields:", error);
      }

      const check = checkAllVerification();
      setAllVerified(check);
      console.log("handleVerifyField ~ check && !is_verified:", check, is_verified, !check && is_verified);
      if (check && !is_verified) {
        setMarkStudentAsVerifiedModal((prev) => ({ ...prev, open: true, flag: 1, heading: "Verification Complete", body: "You have verified all the fields. Would you wanna verify the student?" }));
      } else if (!check && is_verified) {
        await handleUnverifyStudent();
        await fetchStudentProfileStatus();
      }
    } else {
      setDialogue((prev) => ({
        ...prev,
        open: true,
        title: "Data not filled",
        content: "Student has not completed their Profile",
        actions: { ...prev.actions, handler: () => closeDialogue() },
      }));
    }
  };

  //?Verify Everything
  const handleVerifyEverything = async (data) => {
    let temp_main_data = JSON.parse(JSON.stringify(data));
    console.log("handleVerifyEverything ~ temp_main_data:", temp_main_data);
    //searching section for is_verified false
    temp_main_data.forEach(async (section, section_index) => {
      if (section.is_verified === false) {
        section.subSections.forEach(async (subsection, subsection_index) => {
          if ([null, false].includes(subsection.is_verified)) {
            subsection.items.forEach(async (item, item_index) => {
              if (item.item_id) {
                if (item.is_verified === false) {
                  try {
                    const body = requestBodyMaker(item.fields, temp_main_data, subsection, item_index);
                    console.log("handleVerifyEverything ~ body ~ item:", body);
                    await service.put(`po/student/sTypeWisePut`, body);

                    item.is_verified = true;
                    if (subsection.items.every((item) => item.is_verified === true)) subsection.is_verified = true;
                    if (section.subSections.every((subsection) => [true, null].includes(subsection.is_verified))) section.is_verified = true;
                  } catch (error) {
                    console.error("Error while putting verification in Items:", error);
                  }
                }
              } else {
                if (subsection.group_id === 1) {
                  item.fields.forEach(async (field, field_index) => {
                    if (field.is_verified === false) {
                      try {
                        const body = requestBodyMaker(item.fields, temp_main_data, subsection, item_index, field_index);

                        await service.put(`po/student/sTypeWisePut`, body);

                        field.is_verified = true;

                        const fields_except_AttachDocs = item.fields.filter((field) => !["supported_docs", "work_ex_docs"].includes(field.backend_field_name));
                        if (fields_except_AttachDocs.every((field) => [true].includes(field.is_verified))) section.is_verified = true;
                      } catch (error) {
                        console.error("Error while putting verification in Items123:", error);
                      }
                    }
                  });
                }
              }
            });
          }
        });
      }
    });
    console.log("temp_main_data.forEach ~ temp_main_data:", temp_main_data);

    setMainData(temp_main_data);
    return temp_main_data;
  };

  const handleMarkProfileVerified = async () => {
    let temp_data = await handleVerifyEverything(JSON.parse(JSON.stringify(mainData)));
    console.log("handleMarkProfileVerified ~ temp_data:", temp_data);
    try {
      const body = {
        ug_id: batch_id, //ug_id is batch_id
        user_id: user_id,
        po_profile_verfication_id: [1, 3].includes(userDetails.po_profile_verfication_id) ? 5 : 6,
      };
      console.log("ANIMESHHH5", body);
      const response = await service.put(`/po/ca/updateProfileVerfication`, body);
      console.log("response:", response);
      fetchStudentProfileStatus();
      markStudentAsVerifiedModal.onClose();
      // window.reload();
    } catch (error) {
      console.error("Error while verifying student:", error);
    }
  };

  //STUDENT VERIFICAITON
  const handleMarkStudent_as_verified = async (data = mainData) => {
    console.log("consthandleMarkStudent_as_verified= ~ data:", data);
    //check if everything is verified
    const check_verification = checkAllVerification(data);
    console.log("consthandleMarkStudent_as_verified= ~ check_verification:", check_verification);
    //!TEST: test if this works
    if (check_verification) {
      try {
        const body = {
          ug_id: batch_id, //ug_id is batch_id
          user_id: user_id,
          po_profile_verfication_id: 6,
        };
        console.log("ANIMESHHH6", body);
        const response = await service.put(`/po/ca/updateProfileVerfication`, body);
        console.log("response:", response);
        markStudentAsVerifiedModal.onClose();
        // window.reload();
      } catch (error) {
        console.error("Error while verifying student:", error);
      }
    } else {
      setMarkStudentAsVerifiedModal((prev) => ({
        ...prev,
        open: true,
        body: "It seems you have NOT verified every section for this student.Verifying the profile will automatically mark all sections as verified.What'd you like to do?",
      }));
    }
  };

  const getEditId = () => {
    console.log("USERDEATAISL", userDetails);
    return userDetails.po_profile_verfication_id === 1 ? 2 : userDetails.po_profile_verfication_id === 2 ? 1 : userDetails.po_profile_verfication_id === 3 ? 4 : userDetails.po_profile_verfication_id === 4 ? 3 : userDetails.po_profile_verfication_id === 5 ? 6 : 5;
  };

  const handleAllowEditing = async () => {
    //check if everything is verified
    try {
      const body = {
        ug_id: batch_id, //ug_id is batch_id
        user_id: user_id,
        po_profile_verfication_id: getEditId(),
      };
      console.log("ANIMESHHH7", body);
      const response = await service.put(`/po/ca/updateProfileVerfication`, body);
      console.log("response:", response);
      setUserDetails((prev) => {
        return {
          ...prev,
          po_profile_verfication_id: getEditId(),
        };
      });
    } catch (error) {
      console.error("Error while verifying student:", error);
    }
  };

  const handleRestrictEditing = async () => {
    //check if everything is verified

    try {
      let temp = { ...toggelValue };
      temp.verification = false;
      const body = {
        ug_id: batch_id, //ug_id is batch_id
        user_id: user_id,
        po_profile_verfication_id: getEditId(),
      };
      console.log("ANIMESHHH8", body);
      const response = await service.put(`/po/ca/updateProfileVerfication`, body);
      console.log("response:", response);
      setUserDetails((prev) => {
        return {
          ...prev,
          po_profile_verfication_id: getEditId(),
        };
      });
    } catch (error) {
      console.error("Error while verifying student:", error);
    }
  };

  const handleUnverifyStudent = async () => {
    try {
      let temp = { ...toggelValue };
      temp.verification = false;
      const body = {
        ug_id: batch_id, //ug_id is batch_id
        user_id: user_id,
        po_profile_verfication_id: newGetVerificationID(),
      };
      console.log("ANIMESHHH9", body);
      const response = await service.put(`/po/ca/updateProfileVerfication`, body);
      setUserDetails((prev) => {
        return {
          ...prev,
          po_profile_verfication_id: newGetVerificationID(),
        };
      });
      console.log("response1:", response);
      // window.reload();
    } catch (error) {
      console.error("Error while verifying student:", error);
    }
  };

  const handleOpenStudentStatusChangeMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseStudentStatusChangeMenu = () => {
    setAnchorEl(null);
  };

  //MAJOR FUCTION
  const handleChangeStudentStatus = async (option) => {
    console.log({ option });
    //Todo:opening the particular modal
    // if (option.name === "Remove from Blacklist") {
    //     await removefromBlacklist();
    //     await fetchStudentProfileStatus();
    // }
    // else if (option.name === "Remove from Opt outs") {
    //     await removefromOptOuts();
    //     await fetchStudentProfileStatus();
    // }
    // else
    setStudentStatusModal((prev) => ({
      ...prev,
      open: true,
      modalFlag: option.name,
    }));
    handleCloseStudentStatusChangeMenu();
  };

  const closeDialogue = () => {
    setDialogue((prev) => ({ ...prev, open: false }));
  };

  function VerifyConfirmationModal({ open, onClose, heading, body }) {
    const modalBoxStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "white",
      padding: "2rem",
      borderRadius: "40px",
      width: "35%",
    };

    const handleVerifySSYes = async () => {
      const temp_mainData = [...mainData];
      const subsection = temp_mainData[activeIndexes.sectionIndex].subSections[activeIndexes.ssIndex];
      const putVerifyPromises = [];
      const is_verified = subsection.is_verified;
      let putVerify_body = {};
      try {
        for (const item of subsection.items) {
          if (subsection.group_id === 2) {
            putVerify_body = {
              group_id: subsection.group_id,
              sec_type_id: subsection.sec_type_id,
              body: {
                id: item.item_id,
                ...item.fields.reduce((acc, field) => {
                  if (field.backend_field_name === "specialization_major") {
                    const major = extraData.majors.find((major) => major.value === field.value);
                    acc[field.backend_field_name] = major ? major.id : null;
                  } else if (field.backend_field_name === "specialization_minor") {
                    const minor = extraData.majors.find((minor) => minor.value === field.value);
                    acc[field.backend_field_name] = minor ? minor.id : null;
                  } else if (["final_result_declared"].includes(field.backend_field_name)) {
                    acc[field.backend_field_name] = field.value.toLowerCase() === "no" ? 0 : 1;
                  } else if (field.data_type === "document_url") {
                    acc[field.backend_field_name] =
                      Array.isArray(field.value) && field.value.length > 0
                        ? field.value.map((item) => {
                            return {
                              is_verified: true,
                              url: item,
                            };
                          })
                        : [null, "", "[]"].includes(field.value) || field.value.length === 0
                        ? []
                        : [{ is_verified: true, url: field.value }];
                  } else {
                    acc[field.backend_field_name] = send_specialCharReplace(field.value);
                  }
                  return acc;
                }, {}),
                is_verified: is_verified === true ? 0 : 1,
              },
            };
          } else if (subsection.group_id === 3) {
            putVerify_body = {
              group_id: subsection.group_id,
              sec_type_id: subsection.sec_type_id,
              body: {
                id: item.item_id,
                ...item.fields.reduce((acc, field) => {
                  if (field.backend_field_name === "sector_id") {
                    const sector = extraData.sectors.find((sector) => sector.value === field.value);
                    acc[field.backend_field_name] = sector ? sector.id : null;
                  } else if (field.data_type === "document_url") {
                    acc[field.backend_field_name] =
                      Array.isArray(field.value) && field.value.length > 0
                        ? field.value.map((item) => {
                            return {
                              is_verified: true,
                              url: item,
                            };
                          })
                        : [null, "", "[]"].includes(field.value) || field.value.length === 0
                        ? []
                        : [{ is_verified: true, url: field.value }];
                  } else {
                    acc[field.backend_field_name] = send_specialCharReplace(field.value);
                  }
                  return acc;
                }, {}),
                is_verified: is_verified === true ? 0 : 1,
              },
            };
          } else {
            putVerify_body = {
              group_id: subsection.group_id,
              sec_type_id: subsection.sec_type_id,
              body: {
                id: item.item_id,
                ...item.fields.reduce((acc, field) => {
                  if (field.data_type === "document_url") {
                    acc[field.backend_field_name] =
                      Array.isArray(field.value) && field.value.length > 0
                        ? field.value.map((item) => {
                            return {
                              is_verified: true,
                              url: item,
                            };
                          })
                        : [null, "", "[]"].includes(field.value) || field.value.length === 0
                        ? []
                        : [{ is_verified: true, url: field.value }];
                  } else acc[field.backend_field_name] = send_specialCharReplace(field.value);

                  return acc;
                }, {}),
                is_verified: is_verified === true ? 0 : 1,
              },
            };
          }

          const response = await service.put(`/po/student/sTypeWisePut`, putVerify_body);
          console.log("STUDENTDETAILDATA", userDetails);

          if ([1, 2].includes(userDetails.po_profile_verfication_id)) {
            const body = {
              ug_id: batch_id,
              user_id: user_id,
              po_profile_verfication_id: getverificationID(toggelValue),
            };
            console.log("ANIMESHHH10", body);

            const data = service.put(`/po/ca/updateProfileVerfication`, body);
            setUserDetails((prev) => {
              return {
                ...prev,
                po_profile_verfication_id: getverificationID(toggelValue),
              };
            });
          }

          // Update is_verified for the current item
          temp_mainData[activeIndexes.sectionIndex].subSections[activeIndexes.ssIndex].is_verified = !is_verified;

          putVerifyPromises.push(response);
        }

        // Update is_verified for all items based on ss is_verified
        //changing item is_verified based on ss is_verified
        if (temp_mainData[activeIndexes.sectionIndex].subSections[activeIndexes.ssIndex].is_verified)
          temp_mainData[activeIndexes.sectionIndex].subSections[activeIndexes.ssIndex].items.forEach((item) => {
            item.is_verified = item.is_verified !== null ? true : null;
          });
        else if (temp_mainData[activeIndexes.sectionIndex].subSections[activeIndexes.ssIndex].is_verified === false) {
          temp_mainData[activeIndexes.sectionIndex].subSections[activeIndexes.ssIndex].items.forEach((item) => {
            item.is_verified = item.is_verified !== null ? false : null;
          });
        }

        //set section is_verifid accordingly
        const check_verification = handleCheckAllVerification(temp_mainData[activeIndexes.sectionIndex].subSections);
        if (check_verification) {
          if (temp_mainData[activeIndexes.sectionIndex].is_verified !== null) {
            temp_mainData[activeIndexes.sectionIndex].is_verified = true;
          }
        } else {
          if (temp_mainData[activeIndexes.sectionIndex].is_verified !== null) {
            temp_mainData[activeIndexes.sectionIndex].is_verified = false;
          }
        }
        setMainData(temp_mainData);
        onClose();
      } catch (error) {
        console.error("Error verifying subsection:", error);
      }

      // You can handle the responses as needed (e.g., logging or error handling)
      await Promise.all(putVerifyPromises);
      const check = checkAllVerification();
      setAllVerified(check);
      if (check && !is_verified) {
        setMarkStudentAsVerifiedModal((prev) => ({ ...prev, open: true, flag: 1, heading: "Verification Complete", body: "You have verified all the fields. Would you wanna verify the student?" }));
      } else if (!check && [5, 6].includes(userDetails.po_profile_verfication_id)) {
        handleUnverifyStudent();
        fetchStudentProfileStatus();
      }
    };

    const handleVerifySSCancel = () => {
      onClose();
    };

    return (
      <Modal open={open} onClose={onClose}>
        <Box sx={{ ...modalBoxStyle }}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "8px",
              // paddingLeft: "16px",
            }}
          >
            Verify
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "normal",
              marginBottom: "8px",
              // paddingLeft: "16px",
            }}
          >
            {body}
          </Typography>
          <Box
            sx={{
              ...DivCenter,
              alignItems: "flex-start",
              flexDirection: "row",
              width: "100%",
              mt: "16px",
              gap: "10px",
            }}
          >
            <PrimaryButtonOutlined
              variant="outlined"
              onClick={() => handleVerifySSCancel()}
              sx={{
                textTransform: "none",
                borderColor: Color.neutralMidGrey,
                color: Color.neutralMidGrey,
                borderRadius: 0,
                boxShadow: "none",
                width: "90px",
                "&:hover": {
                  borderColor: Color.neutralMidGrey,
                  color: Color.neutralMidGrey,
                },
              }}
            >
              Cancel
            </PrimaryButtonOutlined>
            <PrimaryButton
              variant="outlined"
              onClick={() => handleVerifySSYes()}
              sx={{
                textTransform: "none",
                borderColor: Color.primary1,
                background: Color.primary1,
                color: Color.white,
                borderRadius: 0,
                boxShadow: "none",
                width: "90px",
                "&:hover": {
                  background: Color.primary1,
                  borderColor: Color.primary1,
                  color: Color.white,
                },
              }}
            >
              Yes
            </PrimaryButton>
          </Box>
        </Box>
      </Modal>
    );
  }

  function MarkStudentAsVerified({ open, onClose, flag, heading, body }) {
    const modalBoxStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "white",
      padding: "2rem",
      borderRadius: "40px",
      width: "35%",
    };
    return (
      <Modal open={open} onClose={onClose}>
        <Box sx={{ ...modalBoxStyle }}>
          <Box sx={{ ...DivCenter, width: "100%", alignItems: "space-between", justifyContent: "space-between", marginBottom: "8px" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                // paddingLeft: "16px",
              }}
            >
              {heading}
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "normal",
              marginBottom: "8px",
              // paddingLeft: "16px",
            }}
          >
            {body}
          </Typography>
          <Box
            sx={{
              ...DivCenter,
              alignItems: "flex-start",
              justifyContent: "flex-end",
              flexDirection: "row",
              width: "100%",
              mt: "16px",
              // gap: "10px",
            }}
          >
            {flag ? (
              <Box sx={{ ...DivCenter, width: "100%", gap: "16px" }}>
                <PrimaryButtonOutlined
                  variant="outlined"
                  onClick={() => onClose()}
                  sx={{
                    textTransform: "none",
                    borderColor: Color.neutralMidGrey,
                    color: Color.neutralMidGrey,
                    borderRadius: 0,
                    boxShadow: "none",
                    width: "90px",
                    "&:hover": {
                      borderColor: Color.neutralMidGrey,
                      color: Color.neutralMidGrey,
                    },
                  }}
                >
                  Cancel
                </PrimaryButtonOutlined>
                <PrimaryButton
                  variant="outlined"
                  onClick={async () => {
                    // await handleMarkStudent_as_verified();
                    await handleMarkProfileVerified();

                    await fetchStudentProfileStatus();
                  }}
                  sx={{
                    textTransform: "none",
                    borderColor: Color.primary1,
                    background: Color.primary1,
                    color: Color.white,
                    borderRadius: 0,
                    boxShadow: "none",
                    width: "90px",
                    "&:hover": {
                      background: Color.primary1,
                      borderColor: Color.primary1,
                      color: Color.white,
                    },
                  }}
                >
                  Yes
                </PrimaryButton>
              </Box>
            ) : (
              <Box sx={{ ...DivCenter, width: "100%", gap: "16px" }}>
                <PrimaryButtonOutlined
                  variant="outlined"
                  onClick={() => onClose()}
                  sx={{
                    textTransform: "none",
                    borderColor: Color.neutralMidGrey,
                    color: Color.neutralMidGrey,
                    borderRadius: 0,
                    boxShadow: "none",
                    width: "90px",
                    "&:hover": {
                      borderColor: Color.neutralMidGrey,
                      color: Color.neutralMidGrey,
                    },
                  }}
                >
                  Go Back
                </PrimaryButtonOutlined>
                <PrimaryButton
                  variant="outlined"
                  onClick={async () => handleMarkProfileVerified()}
                  sx={{
                    textTransform: "none",
                    borderColor: Color.primary1,
                    background: Color.primary1,
                    color: Color.white,
                    borderRadius: 0,
                    boxShadow: "none",
                    width: "auto",
                    "&:hover": {
                      background: Color.primary1,
                      borderColor: Color.primary1,
                      color: Color.white,
                    },
                  }}
                >
                  Mark Profile Verified
                </PrimaryButton>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    );
  }

  function StudentStatusChangeModal({ open, onClose, modalFlag }) {
    console.log("StudentStatusChangeModal ~ modalFlag:", modalFlag);
    const modalBoxStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "white",
      padding: "1.5rem 2rem",
      borderRadius: "20px",
      width: "600px",
      height: "auto",
      minHeight: "250px",
    };
    const secondaryButtonStyles = {
      padding: "15px",
      width: "190px",
      color: Color.neutralBlack,
      background: Color.white,
      border: `1px solid ${Color.neutralBlack}`,
      borderRadius: "0px",
      "&:hover": { color: Color.neutralBlack, background: Color.white, border: `1px solid ${Color.neutralBlack}` },
    };
    const primaryButtonStyles = {
      padding: "15px",
      width: "120px",
      color: Color.white,
      background: Color.neutralBlack,
      border: `1px solid ${Color.white}`,
      borderRadius: "0px",
      "&:hover": { color: Color.white, background: Color.neutralBlack, border: `1px solid ${Color.white}` },
    };
    //Data variables
    const [modal, setModal] = useState({
      heading: "",
      flag: 0,
    });
    const [companyDropdown, setCompanyDropdown] = useState({ data: [{ company_name: "", designation: "", job_id: null }], selectedCompany: { company_name: "", designation: "", po_job_id: null } });
    console.log("StudentStatusChangeModal ~ companyDropdown:", companyDropdown, !companyDropdown.selectedCompany.company_name);
    const [placedStudents, setPlacedStudents] = useState([]);
    const [optedOutStudents, setOptedOutStudents] = useState([]);
    const [blacklistedStudents, setBlacklistedStudents] = useState([]);
    const [studentStatusLoading, setStudentStatusLoading] = useState(false);
    const [blacklistingRules, setBlacklistingRules] = useState({
      days: null,
      published_jobs: null,
      eligible_jobs: null,
      block_live_opportunities: null,
      block_live_apply: null,
      second_checkbox: false,
      activeOption: null,
    });
    console.log("blacklistingRules", blacklistingRules);

    const handleCheckboxChange = (event) => {
      const { name, checked } = event.target;
      if (name === "block_live_opportunities") {
        setBlacklistingRules((prevState) => ({
          ...prevState,
          [name]: checked ? 1 : 0,
        }));
      } else if (name === "second_checkbox") {
        setBlacklistingRules((prevState) => ({
          ...prevState,
          [name]: checked,
        }));
      } else if (name === "block_live_apply") {
        setBlacklistingRules((prevState) => ({
          ...prevState,
          [name]: checked ? 1 : 0,
        }));
      }
    };
    //FUNCTIONS
    useEffect(() => {
      handleModalEffect();
    }, []);

    useEffect(() => {
      const handleOnCompanyChange = async () => {
        try {
          setStudentStatusLoading(true);
          //students who are already placed
          const response_placed = await service.get(`/po/ca/allStudentsOfABatch?ug_id=${batch_id}&page=1&limit=100&student_status_id=7`);
          console.log("handleModalEffect ~ response_placed:", response_placed);
          setPlacedStudents(response_placed.data);
        } catch (error) {
          console.error("Error while fetching placed students:", error);
        }
        setStudentStatusLoading(false);
      };
      companyDropdown.selectedCompany.company_name ? handleOnCompanyChange() : <></>;
    }, [companyDropdown.selectedCompany.company_name]);

    const handleModalEffect = async () => {
      if (modalFlag === "Mark as Placed") {
        setStudentStatusLoading(true);
        //data
        let temp_companyDropdown = { ...companyDropdown };
        //Functions
        //fetching placed details
        const selected_company = await fetchPlacedDetials();
        if (Object.keys(selected_company).length) {
          temp_companyDropdown.selectedCompany = {
            ...temp_companyDropdown.selectedCompany,
            company_name: selected_company.company_name,
            designation: selected_company.role,
          };
        }

        try {
          //Pre-requisites
          const response = await service.get(`/po/ca/companyDropdownForUG?ug_id=${batch_id}&user_id=${user_id}`);
          const corrected_data = response.data.map((company) => ({ ...company, company_name: get_specialCharReplace(company.company_name) }));
          temp_companyDropdown.data = filterSameValues(corrected_data, "company_name", "designation");
          setCompanyDropdown(temp_companyDropdown);

          //modal renders
          setModal((prev) => ({
            ...prev,
            open: true,
            heading: "Mark this student as Placed",
          }));
          setStudentStatusLoading(false);
        } catch (error) {
          setStudentStatusLoading(false);
          console.log(error);
        }
      } else if (modalFlag === "Add to Blacklist") {
        //PREREQUISITES
        try {
          setStudentStatusLoading(true);
          //students who have already opted-out
          const response_blacklisted = await service.get(`/po/ca/allStudentsOfABatch?ug_id=${batch_id}&page=1&limit=100&student_status_id=9`);
          console.log("handleModalEffect ~ response_blacklisted:", response_blacklisted);
          setBlacklistedStudents(response_blacklisted.data);
        } catch (error) {
          console.error("Error while fetching blacklisted students:", error);
        }
        setModal((prev) => ({
          ...prev,
          open: true,
          heading: "Blacklist a student",
        }));
        setStudentStatusLoading(false);
      } else if (modalFlag === "Opt-out") {
        //PREREQUISITES
        try {
          setStudentStatusLoading(true);
          //students who have already opted-out
          const response_opted_out = await service.get(`/po/ca/allStudentsOfABatch?ug_id=${batch_id}&page=1&limit=100&student_status_id=10`);
          console.log("handleModalEffect ~ response_opted_out:", response_opted_out);
          setOptedOutStudents(response_opted_out.data);
        } catch (error) {
          console.error("Error while fetching opted out students:", error);
        }
        setModal((prev) => ({
          ...prev,
          open: true,
          heading: "Opt-out a student",
        }));
        setStudentStatusLoading(false);
      } else if (modalFlag === "Mark as Unplaced") {
        setStudentStatusLoading(true);
        //fetching placed details
        const selected_company = await fetchPlacedDetials();
        setCompanyDropdown((prev) => ({
          ...prev,
          selectedCompany: {
            ...prev.selectedCompany,
            company_name: selected_company.company_name,
            designation: selected_company.role,
          },
        }));
        setModal((prev) => ({
          ...prev,
          open: true,
          heading: "Mark a student unplaced",
        }));
        setStudentStatusLoading(false);
      } else if (modalFlag === "Remove from Blacklist") {
        setStudentStatusLoading(true);
        setModal((prev) => ({
          ...prev,
          open: true,
          heading: "Remove from Blacklist",
        }));
        // await removefromBlacklist();
        // await fetchStudentProfileStatus();
        setStudentStatusLoading(false);
      } else if (modalFlag === "Remove from Opt outs") {
        setStudentStatusLoading(true);
        setModal((prev) => ({
          ...prev,
          open: true,
          heading: "Remove from Opt-Out",
        }));
        // await removefromOptOuts();
        // await fetchStudentProfileStatus();
        setStudentStatusLoading(false);
      }
    };

    const fetchPlacedDetials = async () => {
      try {
        const response = await service.get(`/studentModule/placedStudentDetails?user_id=${user_id}`);
        console.log("handleModalEffect ~ response:", response);
        if (response.data.length) {
          setCompanyDropdown((prev) => ({
            ...prev,
            selectedCompany: {
              ...prev.selectedCompany,
              company_name: response.data[0].company_name,
              designation: response.data[0].role,
            },
          }));
          return response.data[0];
        } else return {};
      } catch (error) {
        console.error("Error while fetching placed details:", error);
      }
    };

    //MODAL ACTIVITY FUNCTIONS
    const handleSelectCompany = ({ target }) => {
      const { value } = target;
      console.log("handleSelectCompany ~ value:", value);
      setCompanyDropdown((prev) => ({ ...prev, selectedCompany: value }));
    };

    //MODAL BUTTON FUNCTIONS
    const handlePageChange = () => {
      setModal((prev) => ({ ...prev, flag: !prev.flag }));
    };

    const handleMarkAsPlaced = async () => {
      console.log("handleMarkAsPlaced ~ companyDropdown:", companyDropdown);
      try {
        const response = await service.put(`/po/ca/updateUserUgStudentStatus`, {
          ug_id: batch_id,
          user_id: user_id,
          po_student_status_id: 7,
          company_name: send_specialCharReplace(companyDropdown.selectedCompany.company_name),
          role: companyDropdown.selectedCompany.designation,
          po_job_id: companyDropdown.selectedCompany.po_job_id,
        });
        console.log("handleMarkAsPlaced ~ response:", response);
        onClose();
      } catch (error) {
        console.error("Error while marking student placed:", error);
      }
    };

    const handleMarkAsUnplaced = async () => {
      console.log("handleMarkAsPlaced ~ companyDropdown:", companyDropdown);
      try {
        const response = await service.put(`/po/ca/updateUserUgStudentStatus`, {
          ug_id: batch_id,
          user_id: user_id,
          po_student_status_id: 8,
          po_job_id: userDetails.po_job_id ?? null,
        });
        console.log("handleMarkAsPlaced ~ response:", response);
        onClose();
      } catch (error) {
        console.error("Error while marking student placed:", error);
      }
    };

    const handleOptOut = async () => {
      console.log("handleMarkAsPlaced ~ companyDropdown:", companyDropdown);
      try {
        const response = await service.put(`/po/ca/updateUserUgStudentStatus`, {
          ug_id: batch_id,
          user_id: user_id,
          po_student_status_id: 10,
        });
        console.log("handleMarkAsPlaced ~ response:", response);
        onClose();
      } catch (error) {
        console.error("Error while marking student placed:", error);
      }
    };

    const handleAddToBlacklist = async () => {
      console.log("handleMarkAsPlaced ~ companyDropdown:", companyDropdown);
      try {
        const response = await service.put(`/po/ca/updateUserUgStudentStatus`, {
          ug_id: batch_id,
          user_id: user_id,
          po_student_status_id: 9,
          // ...(![null].includes(blacklistingRules.days) && {
          days: parseInt(blacklistingRules.days),
          // }),
          // ...(![null].includes(blacklistingRules.published_jobs) && {
          published_jobs: parseInt(blacklistingRules.published_jobs),
          // })
          // ,...(![null].includes(blacklistingRules.eligible_jobs) && {
          eligible_jobs: parseInt(blacklistingRules.eligible_jobs),
          // })
          // ...(![null].includes(blacklistingRules.block_live_opportunities) && {
          block_live_opportunities: blacklistingRules.block_live_opportunities,
          // }),
        });
        console.log("handleMarkAsPlaced ~ response:", response);
        onClose();
      } catch (error) {
        console.error("Error while marking student placed:", error);
      }
    };

    const removefromBlacklist = async () => {
      try {
        const response = await service.put(`/po/ca/updateUserUgStudentStatus`, {
          ug_id: batch_id,
          user_id: user_id,
          po_student_status_id: 8,
          // ...(![null].includes(blacklistingRules.block_live_apply) && {
          //   block_live_apply: blacklistingRules.block_live_apply === 1 ? 0 : 1,
          // }),
          block_live_apply: blacklistingRules.block_live_apply === 1 ? 0 : 1,
        });
        console.log("removefromBlacklist ~ response:", response);
        onClose();
        //showing a confirmation dialogue box
        setDialogue((prev) => ({
          ...prev,
          open: true,
          title: "Remove from Blacklist",
          content: (
            <Typography style={{ color: Color.neutralMidGrey }}>
              <span style={{ color: Color.neutralBlack }}>{userDetails.full_name}</span> has been removed from Blacklist
            </Typography>
          ),
          actions: { ...prev.actions, handler: () => closeDialogue() },
        }));
      } catch (error) {
        console.error("Error while removing from blacklist:", error);
      }
    };

    const removefromOptOuts = async () => {
      try {
        const response = await service.put(`/po/ca/updateUserUgStudentStatus`, {
          ug_id: batch_id,
          user_id: user_id,
          po_student_status_id: 8,
        });
        console.log("removefromOptOuts ~ response:", response);
        onClose();
        //showing a confirmation dialogue box
        setDialogue((prev) => ({
          ...prev,
          open: true,
          title: "Remove from Opt-outs",
          content: (
            <Typography style={{ color: Color.neutralMidGrey }}>
              <span style={{ color: Color.neutralBlack }}>{userDetails.full_name}</span> has been removed from Opt-outs
            </Typography>
          ),
          actions: { ...prev.actions, handler: () => closeDialogue() },
        }));
      } catch (error) {
        console.error("Error while removing from opt-outs:", error);
      }
    };

    const handleGoBack = () => {
      onClose();
    };

    return (
      <Modal open={open} onClose={onClose}>
        {studentStatusLoading ? (
          <Box sx={{ ...modalBoxStyle, ...DivCenter }}>
            <CircularProgress size={25} />
          </Box>
        ) : (
          <Box sx={{ ...modalBoxStyle, justifyContent: "space-between" }}>
            {modalFlag === "Mark as Placed" ? (
              <>
                {/*page_1 - Mark as Placed*/}
                {modal.flag === 0 ? (
                  <>
                    <Box sx={{ width: "100%" }}>
                      {modal.heading ? (
                        <Box sx={{ ...DivCenter, flexDirection: "column", width: "100%", alignItems: "space-between", justifyContent: "space-between", marginBottom: "8px" }}>
                          <Typography sx={{ fontSize: "24px" }}>{modal.heading}</Typography>
                          <hr style={{ width: "100%", margin: "8px 0" }} />
                        </Box>
                      ) : (
                        <></>
                      )}
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ width: "100%" }}>
                          <Typography>Choose the Job to Place the Student</Typography>
                          <TextField select value={companyDropdown.selectedCompany} onChange={handleSelectCompany} sx={{ width: "95%" }}>
                            {companyDropdown.data.map((company) => (
                              <MenuItem key={company.company_name} value={company}>
                                {company.designation + " - " + company.company_name}
                              </MenuItem>
                            ))}
                          </TextField>
                          <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "8px", mt: "24px" }}>
                            <Typography>Students who are already placed in this company: </Typography>
                            <Box sx={{ width: "100%" }}>
                              {placedStudents.length ? (
                                <Box sx={{ width: "100%" }}>
                                  {placedStudents.map((student, student_index) => (
                                    <Box sx={{ display: "flex" }}>
                                      <Typography style={{ width: "30%", color: Color.neutralMidGrey }}>
                                        {student_index + 1}.{student.first_name + " " + ([null, "null"].includes(student.last_name) ? "" : student.last_name)}
                                      </Typography>
                                      <Typography style={{ width: "70%", color: Color.neutralMidGrey }}>{student.roll_no}</Typography>
                                    </Box>
                                  ))}
                                </Box>
                              ) : (
                                <Typography style={{ fontSize: "16px", color: Color.neutralMidGrey }}>No students are placed in this company</Typography>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <hr style={{ margin: "8px 0", width: "100%" }} />
                      <Box sx={{ ...DivCenter, alignItems: "flex-start", justifyContent: "center", flexDirection: "row", width: "100%", mt: "16px", gap: "16px" }}>
                        <Button onClick={() => handleGoBack()} sx={{ ...primaryButtonStyles }}>
                          Go back
                        </Button>
                        <Button disabled={!companyDropdown.selectedCompany.company_name} onClick={() => handlePageChange()} sx={{ ...secondaryButtonStyles }}>
                          Mark as Placed
                        </Button>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    {" "}
                    {/*page_2 - Mark as Placed*/}
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <Box sx={{ width: "100%", height: "100%" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%", height: "100%" }}>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", width: "100%" }}>
                            <Typography style={{ fontSize: "18px" }}>Are you certain about this action?</Typography>
                            <Typography style={{ color: Color.neutralMidGrey }}>
                              Mark <span style={{ color: Color.neutralBlack }}>{userDetails.full_name}</span> as placed for the following role:
                            </Typography>
                            <Typography style={{ color: Color.neutralMidGrey }}>
                              {companyDropdown.selectedCompany.designation} - {companyDropdown.selectedCompany.company_name} ({batch_name})
                            </Typography>
                          </Box>
                          <Typography style={{ color: Color.neutralMidGrey }}>
                            <span style={{ color: Color.red }}>Note: </span>Marking <span style={{ color: Color.neutralBlack }}>{userDetails.full_name}</span> as placed will remove this student from all other active shortlists and applications (if any).
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <hr style={{ margin: "8px 0", width: "100%" }} />
                      <Box sx={{ ...DivCenter, alignItems: "flex-start", justifyContent: "center", flexDirection: "row", width: "100%", mt: "16px", gap: "16px" }}>
                        <Button onClick={() => handleGoBack()} sx={{ ...primaryButtonStyles }}>
                          Cancel
                        </Button>
                        <Button
                          disabled={!companyDropdown.selectedCompany.company_name}
                          onClick={async () => {
                            await handleMarkAsPlaced();
                            await fetchStudentProfileStatus();
                          }}
                          sx={{ ...secondaryButtonStyles }}
                        >
                          {loading.button_loading ? <CircularProgress size={25} /> : "Confirm"}
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </>
            ) : modalFlag === "Add to Blacklist" ? (
              <>
                {/*page_1 - Add to Blacklist*/}
                {modal.flag === 0 ? (
                  <>
                    <Box sx={{ width: "100%" }}>
                      {modal.heading ? (
                        <Box sx={{ ...DivCenter, flexDirection: "column", width: "100%", alignItems: "space-between", justifyContent: "space-between", marginBottom: "8px" }}>
                          <Typography sx={{ fontSize: "24px" }}>{modal.heading}</Typography>
                          <hr style={{ width: "100%", margin: "8px 0" }} />
                        </Box>
                      ) : (
                        <></>
                      )}
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ width: "100%" }}>
                          <Typography style={{ color: Color.neutralMidGrey }}>
                            You are about to blacklist <span style={{ color: Color.neutralBlack }}>{userDetails.full_name}</span>
                          </Typography>
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                              <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <Checkbox name="block_live_opportunities" checked={blacklistingRules.block_live_opportunities === 1} onClick={handleCheckboxChange} sx={{ color: Color.blueBlack, "&.Mui-checked": { color: Color.blueBlack } }} />
                                <Typography sx={{ margin: "auto 0" }}>Remove the student from all live opportunity processes</Typography>
                              </Box>
                              <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <Checkbox name="second_checkbox" checked={blacklistingRules.second_checkbox} onClick={handleCheckboxChange} sx={{ color: Color.blueBlack, "&.Mui-checked": { color: Color.blueBlack } }} />
                                <Typography sx={{ margin: "auto 0" }}>When to auto-remove student from blacklist</Typography>
                              </Box>
                              <Box>
                                {blacklistingRules.second_checkbox && (
                                  <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", marginLeft: "1rem" }}>
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <Radio
                                          value={blacklistingRules.days}
                                          name="auto_remove_option"
                                          checked={blacklistingRules.activeOption === "days"}
                                          onChange={() => {
                                            setBlacklistingRules((prevState) => ({
                                              ...prevState,
                                              activeOption: "days",
                                            }));
                                          }}
                                          sx={{ color: Color.blueBlack, "&.Mui-checked": { color: Color.blueBlack } }}
                                        />
                                        <Typography sx={{ margin: "auto 0", fontSize: "14px" }}>After X days</Typography>
                                      </Box>
                                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <Radio
                                          value={blacklistingRules.published_jobs}
                                          name="auto_remove_option"
                                          checked={blacklistingRules.activeOption === "published_jobs"}
                                          onChange={() => {
                                            setBlacklistingRules((prevState) => ({
                                              ...prevState,
                                              activeOption: "published_jobs",
                                            }));
                                          }}
                                          sx={{ color: Color.blueBlack, "&.Mui-checked": { color: Color.blueBlack } }}
                                        />
                                        <Typography sx={{ margin: "auto 0", fontSize: "14px" }}>After X Published Jobs</Typography>
                                      </Box>
                                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <Radio
                                          value={blacklistingRules.eligible_jobs}
                                          name="auto_remove_option"
                                          checked={blacklistingRules.activeOption === "eligible_jobs"}
                                          onChange={() => {
                                            setBlacklistingRules((prevState) => ({
                                              ...prevState,
                                              activeOption: "eligible_jobs",
                                            }));
                                          }}
                                          sx={{ color: Color.blueBlack, "&.Mui-checked": { color: Color.blueBlack } }}
                                        />
                                        <Typography sx={{ margin: "auto 0", fontSize: "14px" }}>After X eligible jobs</Typography>
                                      </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                      <Typography sx={{ marginRight: ".5rem" }}>What's X :</Typography>
                                      <input
                                        type="number"
                                        width="50%"
                                        value={blacklistingRules[blacklistingRules.activeOption]}
                                        onChange={(event) => {
                                          setBlacklistingRules((prevState) => ({
                                            ...prevState,
                                            [prevState.activeOption]: event.target.value,
                                          }));
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <hr style={{ width: "100%", margin: "8px 0" }} />
                        <Box sx={{ ...DivCenter, alignItems: "flex-start", justifyContent: "center", flexDirection: "row", width: "100%", mt: "16px", gap: "16px" }}>
                          <Button onClick={() => handleGoBack()} sx={{ ...primaryButtonStyles }}>
                            Go back
                          </Button>
                          <Button onClick={() => handlePageChange()} sx={{ ...secondaryButtonStyles }}>
                            Blacklist
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    {" "}
                    {/*page_2 - Add to Blacklist*/}
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <Box sx={{ width: "100%", height: "100%" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%", height: "100%" }}>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", width: "100%" }}>
                            <Typography style={{ fontSize: "18px" }}>Are you certain about this action?</Typography>
                            <Typography style={{ color: Color.neutralMidGrey }}>
                              Blacklist <span style={{ color: Color.neutralBlack }}>{userDetails.full_name}</span> from placements of batch {batch_name}
                            </Typography>
                          </Box>
                          <Typography style={{ color: Color.neutralMidGrey }}>
                            <span style={{ color: Color.red }}>Note: </span>Blacklisting <span style={{ color: Color.neutralBlack }}>{userDetails.full_name}</span> from placements will restrain the student from applying for any further opportunities and remove them from all other acitve shortlists and applications (if any).
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <hr style={{ margin: "8px 0", width: "100%" }} />
                      <Box sx={{ ...DivCenter, alignItems: "flex-start", justifyContent: "center", flexDirection: "row", width: "100%", mt: "16px", gap: "16px" }}>
                        <Button onClick={() => handleGoBack()} sx={{ ...primaryButtonStyles }}>
                          Cancel
                        </Button>
                        <Button
                          onClick={async () => {
                            await handleAddToBlacklist();
                            await fetchStudentProfileStatus();
                          }}
                          sx={{ ...secondaryButtonStyles }}
                        >
                          Confirm
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </>
            ) : modalFlag === "Opt-out" ? (
              <>
                {/*page_1 - Opt-out*/}
                {modal.flag === 0 ? (
                  <>
                    <Box sx={{ width: "100%" }}>
                      {modal.heading ? (
                        <Box sx={{ ...DivCenter, flexDirection: "column", width: "100%", alignItems: "space-between", justifyContent: "space-between", marginBottom: "8px" }}>
                          <Typography sx={{ fontSize: "24px" }}>{modal.heading}</Typography>
                          <hr style={{ width: "100%", margin: "8px 0" }} />
                        </Box>
                      ) : (
                        <></>
                      )}
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ width: "100%" }}>
                          <Typography style={{ color: Color.neutralMidGrey }}>
                            You are about to opt <span style={{ color: Color.neutralBlack }}>{userDetails.full_name}</span> out of placements
                          </Typography>

                          <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "8px", mt: "24px" }}>
                            <Typography>Students who have already opted-out of placements </Typography>
                            <Box sx={{ width: "100%" }}>
                              {optedOutStudents.length ? (
                                <Box sx={{ width: "100%", display: "flex" }}>
                                  {optedOutStudents.map((student, student_index) => (
                                    <Box sx={{ display: "flex", gap: "1rem" }}>
                                      <Typography sx={{}}>
                                        {student_index + 1}.{student.first_name + " " + ([null, "null"].includes(student.last_name) ? "" : student.last_name)}
                                      </Typography>
                                      <Typography sx={{}}>{student.roll_no}</Typography>
                                    </Box>
                                  ))}
                                </Box>
                              ) : (
                                <Typography sx={{ fontSize: "16px", color: Color.neutralMidGrey }}>No students have opted out yet</Typography>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <hr style={{ width: "100%", margin: "8px 0" }} />
                      <Box sx={{ ...DivCenter, alignItems: "flex-start", justifyContent: "center", flexDirection: "row", width: "100%", mt: "16px", gap: "16px" }}>
                        <Button onClick={() => handleGoBack()} sx={{ ...primaryButtonStyles }}>
                          Go back
                        </Button>
                        <Button onClick={() => handlePageChange()} sx={{ ...secondaryButtonStyles }}>
                          Opt-Out
                        </Button>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    {" "}
                    {/*page_2 - Opt-out*/}
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ width: "100%" }}>
                          <Typography style={{ fontSize: "18px" }}>Are you certain about this action?</Typography>
                          <br />
                          <Typography style={{ color: Color.neutralMidGrey }}>
                            Removing <span style={{ color: Color.neutralBlack }}>{userDetails.full_name}</span> from placement processes of batch {batch_name}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <hr style={{ margin: "8px 0", width: "100%" }} />
                      <Box sx={{ ...DivCenter, alignItems: "flex-start", justifyContent: "center", flexDirection: "row", width: "100%", mt: "16px", gap: "16px" }}>
                        <Button onClick={() => handleGoBack()} sx={{ ...primaryButtonStyles }}>
                          Cancel
                        </Button>
                        <Button
                          onClick={async () => {
                            await handleOptOut();
                            await fetchStudentProfileStatus();
                          }}
                          sx={{ ...secondaryButtonStyles }}
                        >
                          Confirm
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </>
            ) : modalFlag === "Remove from Opt outs" ? (
              <>
                {" "}
                {/*page_2 - Remove from Opt outs*/}
                <Box sx={{ height: "100%", width: "100%" }}>
                  <Box sx={{ height: "100%", width: "100%" }}>
                    <Box sx={{ ...DivCenter, height: "100%", flexDirection: "column", justifyContent: "space-between", alignItems: "space-between", width: "100%" }}>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                        <Typography style={{ fontSize: "18px" }}>Are you certain about this action?</Typography>
                        <Typography style={{ color: Color.neutralMidGrey }}>
                          Remove <span style={{ color: Color.neutralBlack }}>{userDetails.full_name}</span> from the Opt-outs list
                        </Typography>
                      </Box>
                      <Typography style={{ color: Color.neutralMidGrey }}>
                        <span style={{ color: Color.red }}>Note: </span>Removing <span style={{ color: Color.neutralBlack }}>{userDetails.full_name}</span> from the Opt-outs list will mark the student unplaced.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <hr style={{ margin: "8px 0", width: "100%" }} />
                  <Box sx={{ ...DivCenter, alignItems: "flex-start", justifyContent: "center", flexDirection: "row", width: "100%", mt: "16px", gap: "16px" }}>
                    <Button onClick={() => handleGoBack()} sx={{ ...primaryButtonStyles }}>
                      Cancel
                    </Button>
                    <Button
                      onClick={async () => {
                        await removefromOptOuts();
                        await fetchStudentProfileStatus();
                      }}
                      sx={{ ...secondaryButtonStyles }}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Box>
              </>
            ) : modalFlag === "Remove from Blacklist" ? (
              <>
                {" "}
                {/*page_2 - Remove from Blacklist*/}
                <Box sx={{ height: "100%", width: "100%" }}>
                  <Box sx={{ height: "100%", width: "100%" }}>
                    <Box sx={{ ...DivCenter, height: "100%", flexDirection: "column", gap: "2rem", width: "100%", alignItems: "left" }}>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                        <Typography style={{ fontSize: "18px" }}>Are you certain about this action?</Typography>
                        <Typography style={{ color: Color.neutralMidGrey }}>
                          Remove <span style={{ color: Color.neutralBlack }}>{userDetails.full_name}</span> from the Blacklist
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Checkbox name="block_live_apply" checked={blacklistingRules.block_live_apply === 1} onClick={handleCheckboxChange} sx={{ color: Color.blueBlack, "&.Mui-checked": { color: Color.blueBlack }, paddingLeft: "0px" }} />
                        <Typography style={{ color: Color.neutralMidGrey, margin: "auto 0" }}>Allow the student to apply to existing live opportunities </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <hr style={{ margin: "8px 0", width: "100%" }} />
                  <Box sx={{ ...DivCenter, alignItems: "flex-start", justifyContent: "center", flexDirection: "row", width: "100%", mt: "16px", gap: "16px" }}>
                    <Button onClick={() => handleGoBack()} sx={{ ...primaryButtonStyles }}>
                      Cancel
                    </Button>
                    <Button
                      onClick={async () => {
                        await removefromBlacklist();
                        await fetchStudentProfileStatus();
                      }}
                      sx={{ ...secondaryButtonStyles }}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                {/*page_1 - Mark as Unplaced*/}
                {modal.flag === 0 ? (
                  <Box sx={{ width: "100%", height: "100%" }}>
                    <Box sx={{ width: "100%", height: "75%" }}>
                      {modal.heading ? (
                        <Box sx={{ ...DivCenter, flexDirection: "column", width: "100%", height: "20%", alignItems: "space-between", justifyContent: "space-between", marginBottom: "8px" }}>
                          <Typography sx={{ fontSize: "24px" }}>{modal.heading}</Typography>
                          <hr style={{ width: "100%", margin: "8px 0" }} />
                        </Box>
                      ) : (
                        <></>
                      )}
                      <Box sx={{ width: "100%", height: "80%" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", justifyContent: "space-between" }}>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                            <Typography style={{ color: Color.neutralMidGrey }}>
                              <span style={{ color: Color.neutralBlack }}>{userDetails.full_name}</span> is curently placed for the following opportunity
                            </Typography>
                            <Typography style={{ color: Color.neutralMidGrey }}>
                              {companyDropdown.selectedCompany.designation} - {companyDropdown.selectedCompany.company_name} ({batch_name})
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", padding: "8px 0" }}>
                            <Typography style={{ color: Color.neutralMidGrey }}>
                              Marking <span style={{ color: Color.neutralBlack }}>{userDetails.full_name}</span> as unplaced will allow the student to again to apply for new opportunities.
                            </Typography>
                            <Typography style={{ color: Color.neutralMidGrey }}>What would you like to do?</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ width: "100%", height: "25%" }}>
                      <hr style={{ margin: "8px 0", width: "100%" }} />
                      <Box sx={{ ...DivCenter, alignItems: "flex-start", justifyContent: "center", flexDirection: "row", width: "100%", mt: "16px", gap: "16px" }}>
                        <Button onClick={() => handleGoBack()} sx={{ ...primaryButtonStyles }}>
                          Go back
                        </Button>
                        <Button onClick={() => handlePageChange()} sx={{ ...secondaryButtonStyles }}>
                          Mark As Unplaced
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <>
                    {" "}
                    {/*page_2 - Mark as Unplaced*/}
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <Box sx={{ width: "100%", height: "100%" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%", height: "100%" }}>
                          <Box sx={{}}>
                            <Typography style={{ color: Color.neutralBlack, fontSize: "18px" }}>Are you certain about this action?</Typography>
                            <Typography style={{ color: Color.neutralMidGrey }}>
                              Mark <span style={{ color: Color.neutralBlack }}>{userDetails.full_name}</span> as unplaced, removing the student from the final offer list for the following role:
                            </Typography>
                          </Box>
                          <Typography style={{ color: Color.neutralMidGrey }}>
                            {companyDropdown.selectedCompany.designation} - {companyDropdown.selectedCompany.company_name} ({batch_name})
                          </Typography>
                          <Typography style={{ color: Color.neutralMidGrey }}>
                            <span style={{ color: "red" }}>Note: </span>Marking <span style={{ color: Color.neutralBlack }}>{userDetails.full_name}</span> as unplaced will remove the student from the final offer list of {companyDropdown.selectedCompany.designation} - {companyDropdown.selectedCompany.company_name} ({batch_name}). It will allow them to apply for new opportunities
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <hr style={{ margin: "8px 0", width: "100%" }} />
                      <Box sx={{ ...DivCenter, alignItems: "flex-start", justifyContent: "center", flexDirection: "row", width: "100%", mt: "16px", gap: "16px" }}>
                        <Button onClick={() => handleGoBack()} sx={{ ...primaryButtonStyles }}>
                          Cancel
                        </Button>
                        <Button
                          onClick={async () => {
                            await handleMarkAsUnplaced();
                            await fetchStudentProfileStatus();
                          }}
                          sx={{ ...secondaryButtonStyles }}
                        >
                          Confirm
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        )}
      </Modal>
    );
  }

  function ProfileVerificationConfirmationModal({ open, onClose, heading, body, flag }) {
    console.log("FLAGGG", flag);
    const modalBoxStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "white",
      padding: "2rem",
      borderRadius: "40px",
      width: "35%",
    };

    const handleConfirmation = async () => {
      console.log("handleConfirmation ~ flag:", flag);
      if (flag === "In_process") {
        await handleMarkStudent_as_verified();
        await fetchStudentProfileStatus();
        pv_confirmationModal.onClose();
      } else if (flag === "verified") {
        await handleAllowEditing();
        await fetchStudentProfileStatus();
        pv_confirmationModal.onClose();
      } else if (flag === "Restrict Editing") {
        await handleRestrictEditing();
        await fetchStudentProfileStatus();
        pv_confirmationModal.onClose();
      } else if (flag === "Unverified") {
        await handleUnverifyStudent();
        pv_confirmationModal.onClose();
      } else if (flag === "Allow Editing") {
        await handleAllowEditing();

        pv_confirmationModal.onClose();
      }
    };

    return (
      <Modal open={open} onClose={onClose}>
        <Box sx={{ ...modalBoxStyle }}>
          <Box sx={{ ...DivCenter, width: "100%", alignItems: "space-between", justifyContent: "space-between", marginBottom: "8px" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                // paddingLeft: "16px",
              }}
            >
              {heading}
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "normal",
              marginBottom: "8px",
              // paddingLeft: "16px",
            }}
          >
            {body}
          </Typography>
          <Box
            sx={{
              ...DivCenter,
              alignItems: "flex-start",
              justifyContent: "flex-end",
              flexDirection: "row",
              width: "100%",
              mt: "16px",
              // gap: "10px",
            }}
          >
            <Box sx={{ ...DivCenter, width: "100%", gap: "16px" }}>
              <PrimaryButtonOutlined
                variant="outlined"
                onClick={() => onClose()}
                sx={{
                  textTransform: "none",
                  borderColor: Color.neutralMidGrey,
                  color: Color.neutralMidGrey,
                  borderRadius: 0,
                  boxShadow: "none",
                  width: "90px",
                  "&:hover": {
                    borderColor: Color.neutralMidGrey,
                    color: Color.neutralMidGrey,
                  },
                }}
              >
                Cancel
              </PrimaryButtonOutlined>
              <PrimaryButton
                variant="outlined"
                onClick={async () => handleConfirmation()}
                sx={{
                  textTransform: "none",
                  borderColor: Color.primary1,
                  background: Color.primary1,
                  color: Color.white,
                  borderRadius: 0,
                  boxShadow: "none",
                  width: "90px",
                  "&:hover": {
                    background: Color.primary1,
                    borderColor: Color.primary1,
                    color: Color.white,
                  },
                }}
              >
                Yes
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  }

  const handleMainMarkStudentVerify = async (flag) => {
    const check_mandatorySections_filled = checkMandatorySectionsFilled();
    console.log({ check_mandatorySections_filled });
    if (flag === "Unverified") {
      setPvConfirmationModal((prev) => ({
        ...prev,
        open: true,
        flag: flag,
        heading: "Unverified",
        body: flag === "In_process" ? "Mark this Student as verified" : flag === "verified" ? "Allow editing for this student" : flag === "editable" ? "Restrict editing for this student" : flag === "Unverified" ? "Mark student as unverified" : "...",
      }));
    } else {
      if (check_mandatorySections_filled) {
        const check_verification = checkAllVerification();
        //!TEST: test if this works
        if (check_verification) {
          setPvConfirmationModal((prev) => ({
            ...prev,
            open: true,
            flag: flag,
            heading: "Verify Confimation",
            body: flag === "In_process" ? "Mark this Student as verified" : flag === "verified" ? "Allow editing for this student" : flag === "editable" ? "Restrict editing for this student" : "...",
          }));
        } else {
          setMarkStudentAsVerifiedModal((prev) => ({
            ...prev,
            open: true,
            heading: "Verification Incomplete",
            body: "It seems you have NOT verified every section for this student.Verifying the profile will automatically mark all sections as verified.What'd you like to do?",
          }));
        }
      } else {
        setDialogue((prev) => ({
          ...prev,
          open: true,
          title: "Data not filled",
          content: "Student has not completed their Profile",
          actions: { ...prev.actions, handler: () => closeDialogue() },
        }));
      }
    }
    setToggelValue((prev) => {
      return {
        ...prev,
        selectedToggle: null,
      };
    });
  };

  const handleToggleChange = (e, value) => {
    console.log("1234567", e, value);
    if (value === "placement") {
      // setToggelValue((prev) => {
      //   return {
      //     ...prev,
      //     clickedToggle: value,
      //     tempStorage: e,
      //     displayText: `mark student ${e}`,
      //   };
      // });
      handleChangeStudentStatus({ name: e });
    } else {
      if (value === "verification") {
        e ? handleMainMarkStudentVerify("In_process") : handleMainMarkStudentVerify("Unverified");
      } else {
        // setToggelValue((prev) => {
        //   return {
        //     ...prev,
        //     clickedToggle: value,
        //     toggleModal: true,
        //     tempStorage: !prev[value],
        //     displayText: value === "verification" ? `mark student as ${!prev[value] ? "verified" : "unverified"}` : `${!prev[value] ? "allow editing" : "restrict editing"} for the student`,
        //   };

        // });

        setPvConfirmationModal((prev) => ({
          ...prev,
          open: true,
          flag: e,
          heading: "Editable",
          body: e === "In_process" ? "Mark this Student as verified" : e === "verified" ? "Allow editing for this student" : e === "Restrict Editing" ? "Restrict editing for this student" : e === "Unverified" ? "Mark student as unverified" : e === "Allow Editing" ? "Allow student to edit " : "...",
        }));

        // handleAllowEditing();
      }
    }
  };

  const getverificationID = (temp) => {
    console.log("ANIMESJKJSJD", temp);
    if (temp.editable && temp.verification) return 5;
    if (!temp.editable && temp.verification) return 6;
    if (temp.editable && !temp.verification) return 3;
    if (!temp.editable && !temp.verification) return 4;
  };

  const newGetVerificationID = () => {
    return userDetails.po_profile_verfication_id === 5 ? 3 : userDetails.po_profile_verfication_id === 6 ? 4 : userDetails.po_profile_verfication_id;
  };

  const getverificationName = (temp) => {
    if (temp.editable && temp.verification) return "verified&edit";
    if (!temp.editable && temp.verification) return "verified&noEdit";
    if (temp.editable && !temp.verification) return "inProcess&edit";
    if (!temp.editable && !temp.verification) return "inProcess&noEdit";
  };

  const handleToggleSave = async () => {
    let temp = { ...toggelValue };

    temp[temp.clickedToggle] = temp.tempStorage; // change the value
    temp.clickedToggle = null; //Nothing is clicked
    temp.toggleModal = false; //switch modal off
    temp.tempStorage = null;
    temp.selectedToggle = null;
    temp.displayText = "";
    try {
      if (toggelValue.clickedToggle === "placement") {
        const body = {
          ug_id: batch_id,
          user_id: user_id,
          po_student_status_id: temp.placement === "Placed" ? 7 : temp.placement === "Unplaced" ? 8 : temp.placement === "Blacklist" ? 9 : 10,
        };

        const data = await service.put(`/po/ca/updateUserUgStudentStatus`, body);
        setUserDetails((prev) => ({
          ...prev,
          student_status_name: temp.placement,

          profile_verification_status_name: getverificationName(temp),
        }));
        setToggelValue(temp);
      } else {
        if ([5, 6].includes(getverificationID(temp))) {
          handleMainMarkStudentVerify("In_process");
        } else {
          const body = {
            ug_id: batch_id,
            user_id: user_id,
            po_profile_verfication_id: getverificationID(temp),
          };

          const data = await service.put(`/po/ca/updateProfileVerfication`, body);
          console.log("ANIMESHHH", mainData, getverificationID(temp));
          setToggelValue(temp);
        }
      }
    } catch (error) {
      console.log("Error occured ");
    }
  };

  return (
    <>
      {loading.page_loading ? (
        <SmallPageLoader show={loading.page_loading} />
      ) : (
        <div>
          <SideNavigationBar />
          <UnauthorizedLoginAgainModal show={error.unauthorized} />
          <ActivityLoader show={loading.activity_loading} />
          <Box sx={{ paddingLeft: "100px", display: "flex", alignItems: "center", gap: "20px" }}>
            <Box sx={{ ...DivCenter, borderRadius: "100px", border: "1px dotted black" }}>
              <img src={picture} alt="student_profile_picture" style={{ width: "60px", aspectRatio: "1/1", borderRadius: "50%" }} />
            </Box>
            <Box>
              <span style={{ fontSize: "24px", fontWeight: 500, height: "20%" }}>{userDetails.full_name ?? "N/A"}</span>
              {userDetails.roll_no && <span style={{ color: Color.neutralMidGrey, fontSize: "18px", height: "30%" }}>{userDetails.roll_no}</span>}
            </Box>
          </Box>
          <Box sx={{ paddingInline: "100px 60px", display: "flex", gap: "1rem", marginBlock: "10px" }}>
            <Box sx={{ flex: 1, boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)", borderRadius: "5px", padding: "5px 12px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                {userDetails?.student_status_name === "Placed" ? <EmojiEvents sx={{ color: Color.green }} /> : userDetails?.student_status_name === "Unplaced" ? <WorkHistoryOutlined sx={{ color: Color.accent }} /> : userDetails?.student_status_name === "Blacklist" ? <PersonOutline sx={{ color: Color.white, background: "#000", borderRadius: "50%" }} /> : <LogoutOutlined sx={{ color: Color.darkslategray }} />}
                <Typography sx={{ fontSize: "20px" }}>{userDetails?.student_status_name}</Typography>
              </Box>

              <Box sx={{ position: "relative" }}>
                <Box
                  sx={{ padding: "1px", border: `1px solid ${Color.primary1}`, borderRadius: "50%", "&:hover": { cursor: "pointer" } }}
                  onClick={() =>
                    setToggelValue((prev) => {
                      return {
                        ...prev,
                        selectedToggle: prev.selectedToggle === "placement" ? null : "placement",
                      };
                    })
                  }
                >
                  <MoreHoriz sx={{ color: Color.primary1, padding: 0 }} />
                </Box>
                <Box sx={{ position: "absolute", padding: "10px", zIndex: 999, background: "#fff", boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)", width: "160px", right: 10, display: toggelValue.selectedToggle === "placement" ? "block" : "none" }}>
                  {toggelOptions[userDetails?.student_status_name]?.map((item) => (
                    <Tooltip title={accessRights.find((item) => item.access_right_id === 2)?.is_activated === 1 ? "" : tooltipText}>
                      <Typography sx={{ textAlign: "center", marginBlock: "5px", "&:hover": { cursor: "pointer", background: Color.gainsboro_200 } }} onClick={() => accessRights.find((item) => item.access_right_id === 2)?.is_activated === 1 && handleToggleChange(item, "placement")}>
                        {item}
                      </Typography>
                    </Tooltip>
                  ))}
                </Box>
              </Box>

              {/* <IOSSwitch checked={toggelValue.placement} onChange={(e) => handleToggleChange(e, "placement")} /> */}
            </Box>

            <Box sx={{ flex: 1, boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)", borderRadius: "5px", padding: "5px 12px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Person sx={{ color: [5, 6].includes(userDetails.po_profile_verfication_id) ? Color.green : Color.red }} />
                <Typography sx={{ fontSize: "20px", color: [5, 6].includes(userDetails.po_profile_verfication_id) ? Color.green : Color.red }}>{[5, 6].includes(userDetails.po_profile_verfication_id) ? "Verified" : "Unverified"}</Typography>
              </Box>
              {/* <IOSSwitch checked={toggelValue.verification} onChange={(e) => handleToggleChange(e, "verification")} /> */}

              <Box sx={{ position: "relative" }}>
                <Tooltip title={profileVerification ? "" : "Profile Verification is not allowed this batch"}>
                  <Box
                    sx={{ padding: "1px", border: `1px solid ${Color.primary1}`, borderRadius: "50%", "&:hover": { cursor: "pointer" } }}
                    onClick={() =>
                      profileVerification &&
                      setToggelValue((prev) => {
                        return {
                          ...prev,
                          selectedToggle: prev.selectedToggle === "verification" ? null : "verification",
                        };
                      })
                    }
                  >
                    <MoreHoriz sx={{ color: Color.primary1, padding: 0 }} />
                  </Box>
                </Tooltip>
                <Box sx={{ position: "absolute", padding: "10px", zIndex: 999, background: "#fff", boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)", width: "150px", right: 10, display: toggelValue.selectedToggle === "verification" ? "block" : "none" }}>
                  {VerifiedToggleOptions(userDetails.po_profile_verfication_id).map((item) => (
                    <Tooltip title={accessRights.find((item) => item.access_right_id === 1)?.is_activated === 1 ? "" : tooltipText}>
                      <Typography sx={{ textAlign: "center", marginBlock: "5px", "&:hover": { cursor: "pointer", background: Color.gainsboro_200 } }} onClick={() => accessRights.find((item) => item.access_right_id === 1)?.is_activated === 1 && handleToggleChange(item.value, "verification")}>
                        {item.text}
                      </Typography>
                    </Tooltip>
                  ))}
                </Box>
              </Box>
            </Box>

            <Box sx={{ flex: 1, boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)", borderRadius: "5px", padding: "5px 12px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Edit sx={{ color: Color.neutralDarkGrey }} />
                <Typography sx={{ fontSize: "20px" }}>{[1, 3, 5].includes(userDetails.po_profile_verfication_id) ? "Edit Unlocked" : "Edit Locked"}</Typography>
              </Box>
              {/* <IOSSwitch checked={toggelValue.editable} onChange={(e) => handleToggleChange(e, "editable")} /> */}

              <Box sx={{ position: "relative" }}>
                <Box
                  sx={{ padding: "1px", border: `1px solid ${Color.primary1}`, borderRadius: "50%", width: "fit-content", "&:hover": { cursor: "pointer" } }}
                  onClick={() =>
                    setToggelValue((prev) => {
                      return {
                        ...prev,

                        selectedToggle: prev.selectedToggle === "editable" ? null : "editable",
                      };
                    })
                  }
                >
                  <MoreHoriz sx={{ color: Color.primary1, padding: 0 }} />
                </Box>
                <Box sx={{ position: "absolute", padding: "10px", zIndex: 999, background: "#fff", boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)", width: "150px", right: 10, display: toggelValue.selectedToggle === "editable" ? "block" : "none" }}>
                  {getEditableToggle(userDetails.po_profile_verfication_id).map((item) => (
                    <Tooltip title={accessRights.find((item) => item.access_right_id === 3)?.is_activated === 1 ? "" : tooltipText}>
                      <Typography sx={{ textAlign: "center", marginBlock: "5px", "&:hover": { cursor: "pointer", background: Color.gainsboro_200 } }} onClick={() => accessRights.find((item) => item.access_right_id === 3)?.is_activated === 1 && handleToggleChange(item.text, "editable")}>
                        {item.text}
                      </Typography>
                    </Tooltip>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            {/* Displayed Data - Left Navigation Part */}
            <Box
              sx={{
                alignItems: "flex-start",
                width: "30%",
                maxHeight: "80%",
                padding: "",
                overflowY: "auto",
                borderRight: `1px solid ${Color.neutralLightGrey}`,
                position: "fixed",

                // minHeight: '826px',
              }}
            >
              {mainData.map((step, step_index) => (
                <Box
                  key={step_index}
                  onClick={() => handleSideNavClick(step_index)}
                  sx={{
                    ...DivCenter,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "7.5px 0",
                    gap: "8px",
                  }}
                >
                  <Typography
                    className="typography__stepperTitle"
                    sx={{
                      ...DivCenter,
                      width: "100%",
                      textAlign: "center",
                      padding: "7.5px 0",
                      fontSize: "20px",
                      cursor: "pointer",
                      fontWeight: 500,
                      color: step.is_selected === true ? Color.primary1 : Color.blueBlack,
                      background: step.is_selected === true ? "rgba(56, 193, 223, 0.10)" : Color.white,
                    }}
                  >
                    <span style={{ width: "10%", textAlign: "left" }}></span>
                    <span style={{ width: "80%" }}>{step.section_name}</span>
                    {profileVerification ? <span style={{ width: "10%", textAlign: "left" }}>{step.is_verified === true ? <CheckCircle sx={{ height: "15px", width: "15px", color: Color.green }} /> : step.is_verified === false ? <ErrorOutlineOutlined sx={{ height: "15px", width: "15px", color: Color.red }} /> : <></>}</span> : <span style={{ width: "10%", textAlign: "left" }}></span>}
                  </Typography>
                </Box>
              ))}
            </Box>

            {/* Displayed Data - Right Data Part*/}
            <Box
              sx={{
                width: "70%",
                borderTop: `1px solid ${Color.neutralLightGrey}`,
                marginLeft: "30%",
                overflowY: "auto",
                maxHeight: "80vh",
                // borderLeft: `1px solid ${Color.neutralLightGrey}`,
              }}
            >
              {mainData.length &&
                !error.unauthorized &&
                mainData
                  .filter((data) => data.is_selected === true)[0]
                  .subSections.map((stepperItem, ss_index) => {
                    return (
                      <Box
                        key={stepperItem.ss_id}
                        sx={{
                          ...DivCenter,
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            ...DivCenter,
                            justifyContent: "flex-start",
                            width: "100%",
                            height: "20%",
                            padding: "12px 12px 12px 24px",
                            borderBottom: `1px solid ${Color.neutralMidGrey}`,
                          }}
                        >
                          <Box sx={{ ...DivCenter, gap: "1.5rem", width: "100%" }}>
                            <Box sx={{ display: "flex", width: "87%", justifyContent: "flex-start", gap: "16px" }}>
                              <Typography style={{ fontWeight: "bold", fontSize: "20px", width: "37%" }}>{stepperItem.ss_name}</Typography>
                            </Box>
                            {profileVerification ? (
                              <Box sx={{ width: "13%" }}>
                                {stepperItem.is_verified === false ? (
                                  <Tooltip title={accessRights.find((item) => item.access_right_id === 1)?.is_activated === 1 ? "" : tooltipText}>
                                    <Box onClick={() => accessRights.find((item) => item.access_right_id === 1)?.is_activated === 1 && handleVerifySubSection(ss_index)} sx={{ ...DivCenter, border: "1.5px solid green", gap: "4px", padding: "5px 0", cursor: "pointer" }}>
                                      <CheckCircleOutline fontSize="10px" sx={{ color: Color.green }} /> <span style={{ ...DivCenter, color: Color.green, fontSize: "12px" }}>Mark as Verified</span>
                                    </Box>
                                  </Tooltip>
                                ) : stepperItem.is_verified === true ? (
                                  <Tooltip title={accessRights.find((item) => item.access_right_id === 1)?.is_activated === 1 ? "" : tooltipText}>
                                    <Box onClick={() => accessRights.find((item) => item.access_right_id === 1)?.is_activated === 1 && handleVerifySubSection(ss_index)} sx={{ ...DivCenter, background: Color.green, gap: "4px", padding: "5px 0", cursor: "pointer" }}>
                                      <CheckCircleOutline fontSize="10px" sx={{ color: Color.white }} /> <span style={{ ...DivCenter, color: Color.white, fontSize: "12px" }}>You verified this</span>
                                    </Box>
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}
                              </Box>
                            ) : (
                              <Box sx={{ width: "13%" }} />
                            )}
                          </Box>
                        </Box>
                        <Box sx={{ ...DivCenter, flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", width: "100%", height: "80%", padding: "12px 12px 12px 24px", gap: "16px" }}>
                          {stepperItem.items.map((ss_item, ss_item_index) => (
                            <div key={ss_item_index} style={{ ...DivCenter, flexDirection: "column", width: "100%", height: "auto" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  width: "100%",
                                  borderBottom: ss_item.item_name !== null && ss_item.is_mandatory !== false ? `1px solid ${Color.neutralLightGrey}` : "none",
                                }}
                              >
                                {ss_item.item_name !== null ? (
                                  ss_item.item_id === null && ss_item.is_mandatory === false ? (
                                    <Typography
                                      style={{
                                        width: "100%",
                                        textAlign: "left",
                                        color: Color.neutralMidGrey,
                                        fontStyle: "italic",
                                        paddingTop: "8px",
                                      }}
                                    >
                                      Not added any data yet
                                    </Typography>
                                  ) : (
                                    <Box
                                      sx={{
                                        ...DivCenter,
                                        width: "100%",
                                        padding: "8px 0px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          ...DivCenter,
                                          width: "95%",
                                          justifyContent: "flex-start",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            color: Color.neutralMidGrey,
                                            fontSize: "18px",
                                            fontWeight: 500,
                                            width: "30%",
                                          }}
                                        >
                                          {ss_item.item_name}
                                        </Typography>
                                        <Box sx={{ width: "70%" }}>
                                          {ss_item.fields.map(
                                            (field, index) =>
                                              field.field_name === "Attach Documents" && (
                                                <Typography
                                                  sx={{
                                                    width: "65%",
                                                    color: Color.primary1,
                                                    alignItems: "center",
                                                    cursor: field.value !== "{}" || field.value !== "" ? "pointer" : "none",
                                                  }}
                                                  disabled={field.value !== "{}" || field.value !== "" ? false : true}
                                                >
                                                  {field.value === "{}" || field.value === "" || field.value === "[]" || field.value === null ? (
                                                    ""
                                                  ) : typeof field.value === "string" ? (
                                                    <a href={field.value} target="_blank" rel="noreferrer">
                                                      {field.value.split("/").at(-1)}
                                                    </a>
                                                  ) : (
                                                    field.value.map((item) => (
                                                      <a href={item} target="_blank" rel="noreferrer">
                                                        {item.split("/").at(-1)}
                                                      </a>
                                                    ))
                                                  )}
                                                </Typography>
                                              )
                                          )}
                                        </Box>
                                      </Box>
                                      {profileVerification ? (
                                        <Box sx={{ width: "5%" }}>
                                          {ss_item.is_verified === false ? (
                                            <Tooltip title={accessRights.find((item) => item.access_right_id === 1)?.is_activated === 1 ? "" : tooltipText}>
                                              <Box>
                                                <IconButton onClick={() => handleVerifyItem(ss_item_index, ss_index)} disabled={accessRights.find((item) => item.access_right_id === 1)?.is_activated === 1 ? false : true}>
                                                  <RadioButtonUnchecked sx={{ color: Color.green }} />
                                                </IconButton>
                                              </Box>
                                            </Tooltip>
                                          ) : ss_item.is_verified === true ? (
                                            <Tooltip title={accessRights.find((item) => item.access_right_id === 1)?.is_activated === 1 ? "" : tooltipText}>
                                              <Box>
                                                <IconButton onClick={() => handleVerifyItem(ss_item_index, ss_index)} disabled={accessRights.find((item) => item.access_right_id === 1)?.is_activated === 1 ? false : true}>
                                                  <CheckCircleIcon sx={{ color: Color.green }} />
                                                </IconButton>
                                              </Box>
                                            </Tooltip>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      ) : (
                                        <Box sx={{ width: "5%" }} />
                                      )}
                                    </Box>
                                  )
                                ) : (
                                  ss_item.is_mandatory === false &&
                                  ss_item.item_id === null && (
                                    <Typography
                                      style={{
                                        width: "100%",
                                        textAlign: "left",
                                        color: Color.neutralMidGrey,
                                        fontStyle: "italic",
                                        paddingTop: "8px",
                                      }}
                                    >
                                      Not added any data yet
                                    </Typography>
                                  )
                                )}
                              </Box>

                              {(ss_item.item_id || ss_item.is_mandatory) && (
                                <>
                                  {ss_item.fields.map((field, fieldIndex) =>
                                    field.field_name !== "Attach Documents" ? (
                                      <div
                                        key={fieldIndex}
                                        style={{
                                          ...DivCenter,
                                          flexDirection: "column",
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            ...DivCenter,
                                            width: "100%",
                                            gap: "5rem",
                                            textAlign: "left",
                                            height: "auto",
                                            minHeight: "57px",
                                            paddingTop: ".5rem",
                                            // justifyContent: "space-between",
                                            // alignItems: "left",
                                            paddingBottom: ".5rem",
                                            borderBottom: `1px solid ${Color.neutralLightGrey}`,
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              width: "30%",
                                              color: Color.neutralMidGrey,
                                            }}
                                          >
                                            {field.field_name}
                                          </Typography>
                                          <Typography sx={{ width: "65%" }}>{["string", "number"].includes(typeof field.value) ? field.value.toString() : (field.value || []).map((data, index) => (index !== field.value.length - 1 ? data + ", " : data)) ?? "N/A"}</Typography>

                                          {profileVerification ? (
                                            <Box sx={{ width: "5%" }}>
                                              {field.is_verified === false ? (
                                                <Tooltip title={accessRights.find((item) => item.access_right_id === 1)?.is_activated === 1 ? "" : tooltipText}>
                                                  <Box>
                                                    <IconButton onClick={() => handleVerifyField(fieldIndex, ss_item_index, ss_index)} disabled={accessRights.find((item) => item.access_right_id === 1)?.is_activated === 1 ? false : true}>
                                                      <RadioButtonUnchecked sx={{ color: Color.green }} />
                                                    </IconButton>
                                                  </Box>
                                                </Tooltip>
                                              ) : field.is_verified === true ? (
                                                <Tooltip title={accessRights.find((item) => item.access_right_id === 1)?.is_activated === 1 ? "" : tooltipText}>
                                                  <Box>
                                                    <IconButton onClick={() => handleVerifyField(fieldIndex, ss_item_index, ss_index)} disabled={accessRights.find((item) => item.access_right_id === 1)?.is_activated === 1 ? false : true}>
                                                      <CheckCircleIcon sx={{ color: Color.green }} />
                                                    </IconButton>
                                                  </Box>
                                                </Tooltip>
                                              ) : (
                                                <></>
                                              )}
                                            </Box>
                                          ) : (
                                            <Box sx={{ width: "5%" }} />
                                          )}
                                        </Box>
                                      </div>
                                    ) : (
                                      <div style={{ display: "flex", width: "100%", paddingBlock: "8px" }}>
                                        <Typography
                                          style={{
                                            width: "30%",
                                            color: Color.neutralMidGrey,
                                          }}
                                        >
                                          {field.field_name}
                                        </Typography>

                                        <Box sx={{ width: "63%", marginLeft: "30px" }}>
                                          <Typography
                                            sx={{
                                              width: "65%",
                                              color: Color.primary1,
                                              alignItems: "center",
                                              cursor: field.value !== "{}" || field.value !== "" ? "pointer" : "none",
                                            }}
                                            disabled={field.value !== "{}" || field.value !== "" ? false : true}
                                          >
                                            {field.value === "{}" || field.value === "" || field.value === "[]" || field.value === null || field.value.length === 0 ? (
                                              <Typography sx={{ color: "red" }}>-</Typography>
                                            ) : typeof field.value === "string" ? (
                                              <a href={field.value} target="_blank" rel="noreferrer" style={{ color: Color.primary1, textDecoration: "none" }}>
                                                {field.value.split("/").at(-1)}
                                              </a>
                                            ) : (
                                              field.value.map((item) => (
                                                <a href={item} target="_blank" rel="noreferrer" style={{ color: Color.primary1, textDecoration: "none" }}>
                                                  <Typography>{item.split("/").at(-1).replaceAll("+", "_")}</Typography>
                                                </a>
                                              ))
                                            )}
                                          </Typography>
                                        </Box>
                                      </div>
                                    )
                                  )}
                                </>
                              )}
                            </div>
                          ))}
                        </Box>
                        {/* <Box sx={{ width: "100%", height: "20%" }} /> */}
                      </Box>
                    );
                  })}
            </Box>

            {/* Modals */}
            <VerifyConfirmationModal open={verifyConfirmationModal.open} onClose={verifyConfirmationModal.onClose} body={verifyConfirmationModal.body} />
            <MarkStudentAsVerified open={markStudentAsVerifiedModal.open} onClose={markStudentAsVerifiedModal.onClose} heading={markStudentAsVerifiedModal.heading} body={markStudentAsVerifiedModal.body} flag={markStudentAsVerifiedModal.flag} />
            <StudentStatusChangeModal
              open={studentStatusModal.open}
              onClose={studentStatusModal.onClose}
              // modalData={studentStatusModal.modalData}
              modalFlag={studentStatusModal.modalFlag}
            />
            <DialogueBox
              open={dialogue.open}
              onClose={dialogue.actions.handler}
              title={dialogue.title}
              actions={dialogue.actions}
              content={
                <>
                  <p>{dialogue.content}</p>
                </>
              }
            />
            <ProfileVerificationConfirmationModal open={pv_confirmationModal.open} onClose={pv_confirmationModal.onClose} heading={pv_confirmationModal.heading} body={pv_confirmationModal.body} flag={pv_confirmationModal.flag} />
          </Box>
          )
          <Modal
            open={toggelValue.toggleModal}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            onClose={() =>
              setToggelValue((prev) => {
                return {
                  ...prev,
                  clickedToggle: null,
                  toggleModal: false,
                };
              })
            }
          >
            <Box sx={{ background: "#fff", height: "25vh", borderRadius: "20px", padding: "20px", width: "30vw" }}>
              <Typography sx={{ fontSize: "22px" }}>Are you sure you want to {toggelValue.displayText} </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", gap: "1rem", marginTop: "1.5rem" }}>
                <Button
                  sx={{
                    padding: "2px 8px",
                    textTransform: "none",
                    border: `1px solid ${Color.primary1}`,
                    color: Color.primary1,
                    "&:hover": {
                      color: Color.primary1,
                    },
                  }}
                  onClick={() =>
                    setToggelValue((prev) => {
                      return {
                        ...prev,
                        toggleModal: false,
                        clickedToggle: null,
                      };
                    })
                  }
                >
                  Cancel
                </Button>

                <Button
                  sx={{
                    padding: "2px 8px",
                    textTransform: "none",
                    border: `1px solid ${Color.primary1}`,
                    background: Color.primary1,
                    color: Color.white,
                    "&:hover": {
                      background: Color.primary1,
                      color: Color.white,
                    },
                  }}
                  onClick={() => handleToggleSave()}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ProfileVerification;
