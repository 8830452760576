import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography, Skeleton } from "@mui/material";
import { Color, DivCenter, styles } from "../../GlobalStyles";
import { AddOutlined, GetApp, Search, UploadFile } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { convertEpochToNormalTime, formatConvertermonthfirst } from "../../services/specialCharReplace";
import { SearchFunction } from "../reusables/TextFunctions/SearchFunctions"
import CCService from "../../services/httpServiceWithoutSuperAdmin";
const service = new CCService();
export default function StudentMentorDetailsModule({ studentMentorDetails, setStudentMentorDetails, datesFilter, getStudentMentorDetails, setChooseMentor, mentorStatList, handleMainEffect, getStatLists, dialogue, setDialogue }) {
    console.log("StudentMentorDetailsModule ~ studentMentorDetails:", studentMentorDetails)

    const [loading, setLoading] = useState({ lazyLoading: false, search: false });

    const scrollToTargetId = (targetId) => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            const yOffset = -150; // Adjust this value to offset the scroll position if needed
            const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    function getStatus(order_flag, is_absent, student_availability) {
        console.log("order_flag:", order_flag, is_absent)
        if (is_absent === 1) {
            return (
                <div
                    style={{
                        padding: "8px 10px",
                        backgroundColor: "#E7E7E7",
                        color: Color.neutralDarkGrey,
                        fontSize: "12px",
                        borderRadius: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div>Absent</div>
                </div>
            );
        } else if (order_flag === "Purchased") {
            return (
                <div
                    style={{
                        // padding: "8px 10px",
                        // backgroundColor: "#FCEFD8",
                        color: "#E58A0A",
                        fontSize: "12px",
                        // borderRadius: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div>Specialization not selected</div>
                </div>
            );
        } else if (order_flag === "Scheduled")
            return (
                <div
                    style={{
                        padding: "8px 10px",
                        backgroundColor: "#E8F3EA",
                        color: Color.green,
                        fontSize: "12px",
                        borderRadius: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }} >
                    <div>Scheduled</div>
                </div>
            );
        else if (order_flag === "Initialized") {
            return (
                <div
                    style={{
                        // padding: "8px 10px",
                        // backgroundColor: "#FCEFD8",
                        color: "#E58A0A",
                        fontSize: "12px",
                        // borderRadius: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    }}
                >
                    <div>{student_availability || student_availability===0 ? "Slot not selected yet" : "Classes not selected"}</div>
                </div>
            );
        } else if (order_flag === "Done") {
            return (
                <div>
                    <div

                        style={{
                            fontSize: "12px",
                            marginBottom: "12px",
                            color: Color.green,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Completed
                    </div>

                </div>
            );
        } else if (order_flag === "In process") {
            return (
                <div
                    style={{
                        padding: "8px 10px",
                        backgroundColor: "#FCEFD8",
                        color: "#E58A0A",
                        fontSize: "12px",
                        borderRadius: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    }}
                >
                    <div>In process</div>
                </div>
            );
        } else if (order_flag === "Feedback") {
            return (
                <div>
                    <div
                        // onClick={() =>
                        //   navigate(`/FeedbackReview?order_id=${id}`, {
                        //     state: order_data[0],
                        //   })
                        // }
                        style={{
                            fontSize: "12px",
                            marginBottom: "12px",
                            color: Color.red,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Pending Feedback
                    </div>

                </div>
            );
        }
    }
    //data variable
    const [searchTerm, setSearchTerm] = useState();
    const [filterButtons, setFilterButtons] = useState({ 'All': true });
    const [filteredMainData, setFilteredMainData] = useState([...studentMentorDetails]);


    //lazy loading
    const [currentPage, setCurrentPage] = useState(1);
    const [endArray, setEndArray] = useState(false);


    //FUNCTIONS
    useEffect(() => {
        if (currentPage !== 1) loadMoreData(currentPage);
    }, [currentPage]);

    useEffect(() => {
        const handleFilter = () => {
            let temp_addon = { ...filterButtons }
            if (datesFilter && datesFilter.length >= 1) {
                datesFilter?.map(({ session_date }) => {
                    temp_addon[parseInt(session_date)] = false
                }
                )
            }
            console.log("dates inside temp :", temp_addon)
            setFilterButtons(temp_addon)
        }
        handleFilter();
    }, [datesFilter])

    const loadMoreData = async (page) => {
        setLoading((prev) => ({ ...prev, lazyLoading: true }));
        const response = await getStudentMentorDetails(page, searchTerm, Object.keys(filterButtons).find((item) => filterButtons[item] == true));
        console.log("loadMoreData ~ response:", response, studentMentorDetails);
        if (response.length === 0) {
            setEndArray(true);
        }
        if (page !== 1) {
            setStudentMentorDetails([...studentMentorDetails, ...response]);
            setFilteredMainData([...filteredMainData, ...response]);
        }
        // console.count('[GET] call');
        setLoading((prev) => ({ ...prev, lazyLoading: false }));
    }

    const handleScroll = (event) => {
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        console.log("handleScroll ~ event:", event, scrollTop, clientHeight, scrollHeight)

        if (scrollTop + clientHeight + 30 >= scrollHeight && !loading.lazyLoading) {
            if (!endArray) {
                setCurrentPage((prevPage) => prevPage + 1);
                loadMoreData(currentPage + 1);
            }
            // else window.alert('Page end')
        }
    };

    const handleChangeSearch = (e, newValue) => {
        console.log("handleChangeSearch ~ newValue:", e.target.value, newValue)
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        // if (searchTerm) {
        //     // // FRONTEND - SEARCH
        //     // const filtered = SearchFunction(studentMentorDetails,
        //     //     ['student_name', 'mentor_name', 'student_email', 'student_ph_no',
        //     //         'spe_name', 'mentor_email', 'mentor_ph_no'], searchTerm);
        //     // console.log("handleChangeSearch ~ filtered:", filtered)
        //     // setFilteredMainData(filtered);
        // }
        // else {
        //     setFilteredMainData([...studentMentorDetails]);
        // }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleClickSearchButton();
        }
    }

    const handleClickSearchButton = async () => {
        setLoading((prev) => ({ ...prev, search: true }));
        if (searchTerm) {
            // BACKEND - SEARCH
            const filtered = await getStudentMentorDetails(undefined, searchTerm, Object.keys(filterButtons).find((item) => filterButtons[item] == true));
            console.log("handleClickSearchButton ~ filtered:", filtered)
            setFilteredMainData(filtered);
        }
        else {
            setFilteredMainData([...studentMentorDetails]);
        }
        setLoading((prev) => ({ ...prev, search: false }));
    }

    const handleFilterButton = async (flag, scroll) => {
        let temp_filteredData = [...studentMentorDetails];
        const temp_filter = {};
        for (let key in filterButtons) {
            if (key === flag) temp_filter[key] = true;
            else temp_filter[key] = false;
        }
        setFilterButtons(temp_filter);
        if (flag === 'All') {
            temp_filteredData = [...studentMentorDetails];
        } else {
            temp_filteredData = await getStudentMentorDetails(undefined, searchTerm, flag)
        }
        setFilteredMainData(temp_filteredData);
        if (scroll) scrollToTargetId('StudentsList');
    }

    const confirmHandleReleaseSlot = (student) => {
        setDialogue((prev) => ({
            ...prev,
            open: true,
            actions: [
                { width: '40%', label: 'Reject', handler: () => { dialogue.onClose() }, color: Color.red, variant: "outlined" },
                { width: '40%', label: 'Accept', handler: () => { handleReleaseSlot(student) }, color: Color.green, background: Color.green_highlight, variant: "outlined" }
            ],
            title: 'Confirmation',
            content: <p style={{ textAlign: 'center', lineHeight: '25px' }}>Are you sure you want to release this slot?</p>,
        }))
    }

    const handleReleaseSlot = async (student) => {
        try {
            const post_body = {
                "order_id": student.id,
                "slot_start_time": student.slot_start_time,
                "slot_end_time": student.slot_end_time,
            }
            await service.post(`/os/superAdmin/action/releaseBooking?order_id=${student.id}`, post_body);
            const data = await getStatLists();
            handleMainEffect(data);
        } catch (error) {
            console.error("Error while releasing slot:", error);
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ ...DivCenter, width: '100%', mb: '16px' }}>
                <TextField
                    name="search_colleges"
                    // label="Search Student"
                    placeholder='Search Students'
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleChangeSearch}
                    onKeyDown={handleKeyPress}
                    fullWidth
                    sx={{ width: '80%', borderRadius: "12px" }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search sx={{ color: Color.neutralMidGrey }} />
                            </InputAdornment>
                        ),
                        sx: { borderRadius: "12px", height: "58px" }
                    }}
                />
                <Box sx={{ ...DivCenter, width: '10%' }}><Button variant='contained' onClick={() => handleClickSearchButton()} sx={{ ...styles.button_styles.contained }}>Search</Button></Box>
                <Box sx={{ width: '10%', minWidth: '121px' }}>
                    {/* <IconButton><AddOutlined /></IconButton> */}
                    <IconButton><GetApp /></IconButton>
                    {/* <IconButton><UploadFile /></IconButton> */}
                </Box>
            </Box>
            <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-start', gap: '8px', mb: '24px' }}>
                {Object.keys(filterButtons).map((filter) => <Button onClick={() => { handleFilterButton(filter, true); }} sx={{
                    ...styles.filter_buttons,
                    background: filterButtons[filter] ? 'rgba(195, 236, 245, 1)' : Color.white,
                }}>{filter !== "All" ? formatConvertermonthfirst(parseInt(filter)) : filter}</Button>)}
            </Box>
            <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', background: 'rgba(195, 236, 245, 0.1)', minHeight: '113px', p: '16px' }}>
                <Typography style={{ width: '100%', textAlign: 'left', fontSize: '22px', color: Color.primary1, }}>Student details</Typography>
                <Typography style={{ width: '100%', textAlign: 'left', fontSize: '22px', color: Color.primary1, }}>Specialization</Typography>
                <Typography style={{ width: '100%', textAlign: 'left', fontSize: '22px', color: Color.primary1, }}>Date and Time</Typography>
                <Typography style={{ width: '100%', textAlign: 'left', fontSize: '22px', color: Color.primary1, }}>Mentor Details</Typography>
                <Typography style={{ width: '100%', textAlign: 'center', fontSize: '22px', color: Color.primary1, }}>Status</Typography>
            </Box>
            {loading.search ?
                [...Array(1)].fill(null).map((_, index) => (
                    <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', minHeight: '113px', p: '16px', }}>
                        <Box>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                        </Box>
                        <Box>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                        </Box>
                        <Box>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                        </Box>
                        <Box>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                        </Box>
                        <Box sx={{ ...DivCenter, gap: '16px' }}>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                        </Box>
                    </Box>
                ))
                : <Box onScroll={handleScroll} sx={{ width: '100%', height: '500px', overflowY: 'auto', }}>
                    {filteredMainData.length === 0 && !loading.lazyLoading && (
                        <p>No data available.</p>
                    )}

                    {filteredMainData.map((student, student_index) =>
                        <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', background: student_index % 2 ? 'rgba(251, 251, 251, 1)' : Color.white, minHeight: '113px', p: '16px' }}>
                            <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', justifyContent: 'flex-start' }}>
                                <Typography style={{ width: '100%', color: Color.primary1, fontSize: '16px' }}>{student.student_name}</Typography>
                                <Typography style={{ width: '100%', color: Color.neutralMidGrey, fontSize: '16px' }}>{student.student_email}</Typography>
                                <Typography style={{ width: '100%', color: Color.neutralMidGrey, fontSize: '16px' }}>{student.student_ph_no}</Typography>
                            </Box>
                            <Box sx={{ ...DivCenter, width: '100%', }}>
                                <Typography style={{ textAlign: 'center', width: '100%', color: Color.neutralMidGrey, fontSize: '16px', flexWrap: 'wrap' }}>{student.spe_name ?? "-"}</Typography>
                            </Box>
                            <Box sx={{ ...DivCenter, width: '100%', }}>
                                <Typography style={{ textAlign: 'center', width: '100%', color: Color.neutralMidGrey, fontSize: '16px' }}>
                                    {student?.start_time ? formatConvertermonthfirst(student.start_time, true, true, true) : "-"}
                                    <Typography>
                                        {student?.start_time ? convertEpochToNormalTime(student.start_time) + "-" + convertEpochToNormalTime(student.end_time) : "-"}
                                    </Typography>
                                </Typography>
                            </Box>
                            <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', }}>
                                <Typography style={{ width: '100%', textAlign: 'left', color: Color.primary1, fontSize: '16px' }}>{student.mentor_name ?? '-'}</Typography>
                                <Typography style={{ width: '100%', textAlign: 'left', color: Color.neutralMidGrey, fontSize: '16px' }}>{student.mentor_email ?? '-'}</Typography>
                                <Typography style={{ width: '100%', textAlign: 'left', color: Color.neutralMidGrey, fontSize: '16px' }}>{student.mentor_ph_no ?? '-'}</Typography>
                            </Box>
                            <Box sx={{ ...DivCenter, width: '100%', gap: '24px' }}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 8,
                                    }}
                                >
                                    {getStatus(student.order_flag, student.is_absent, student.student_availability)}
                                </div>
                            </Box>

                        </Box>
                    )}

                    {loading.lazyLoading &&
                        [...Array(1)].fill(null).map((_, index) => (
                            <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', minHeight: '113px', p: '16px', }}>
                                <Box>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                </Box>
                                <Box>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                </Box>
                                <Box>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                </Box>
                                <Box>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                </Box>
                                <Box sx={{ ...DivCenter, gap: '16px' }}>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                </Box>
                            </Box>
                        ))
                    }
                </Box>}
        </Box>
    )
} 