import { Add, Business, Close, DeleteOutlineOutlined, Edit } from "@mui/icons-material";
import { Autocomplete, Box, Button, Chip, IconButton, Modal, TextField, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { Color } from "../../../GlobalStyles";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import ImageCropper from "./ImageCropper";
import NewImageCropper from "./NewImageCrop";
import { ToastContainer, toast } from "react-toastify";
function NewCompany({ college_id, setAllCompanies, close, data }) {
  const service = new CCService();
  const [formData, setFormData] = useState({
    name: [null, undefined].includes(data) ? "" : data.company_name,
    about: [null, undefined].includes(data) ? "" : data.about,
    location: [null, undefined].includes(data) ? [] : data.location,
    poc: [null, undefined].includes(data) ? [] : data.poc,
    imageUrl: [null, undefined].includes(data) ? "" : data.company_image,
  });

  const [collegID, setCollegeId] = useState(college_id);

  const [formError, setFormError] = useState({
    name: "",
    about: "",
    location: [],
    poc: [],
  });

  const [newPoc, setNewPoc] = useState({
    name: "",
    email: "",
    ph_no: "",
  });

  const [newPocError, setnewPocError] = useState({
    name: "",
    email: "",
    ph_no: "",
  });
  const [editImageModal, setEditImageModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addMore, setAddMore] = useState(false);
  const [selectedPOC, setSelectedPOC] = useState(null);

  const POCValidation = () => {
    let flag = true;
    if (newPoc.name === "") {
      setnewPocError((prev) => {
        return {
          ...prev,
          name: "Name is required",
        };
      });
      flag = false;
    }

    if (newPoc.email === "") {
      setnewPocError((prev) => {
        return {
          ...prev,
          email: "Email is required",
        };
      });
      flag = false;
    }

    if (newPoc.ph_no === "") {
      setnewPocError((prev) => {
        return {
          ...prev,
          ph_no: "Phone number is required",
        };
      });
      flag = false;
    }

    return flag;
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    let temp = { ...formData };
    temp[name] = value;
    setFormData(temp);
  };

  const handleNewPOC = () => {
    if (POCValidation()) {
      setFormData((prev) => {
        return {
          ...prev,
          poc: [...prev.poc, { ...newPoc }],
        };
      });
      setNewPoc({
        name: "",
        email: "",
        ph_no: "",
      });
      setnewPocError({
        name: "",
        email: "",
        ph_no: "",
      });
      setAddMore(false);
    }
  };

  const handlePocDelete = (index) => {
    setFormData((prev) => {
      return {
        ...prev,
        poc: prev.poc.filter((item, i) => i !== index),
      };
    });
  };

  const handleCancel = () => {
    setNewPoc({
      name: "",
      email: "",
      ph_no: "",
    });

    setnewPocError({
      name: "",
      email: "",
      ph_no: "",
    });
    setAddMore(false);
  };

  const validations = () => {
    let flag = true;
    if (formData.name === "") {
      flag = false;
      setFormError((prev) => {
        return {
          ...prev,
          name: "name is required",
        };
      });
    }

    if (formData.about === "") {
      flag = false;
      setFormError((prev) => {
        return {
          ...prev,
          about: "About compnay is required",
        };
      });
    }
    if (formData.about === "") {
      flag = false;
      setFormError((prev) => {
        return {
          ...prev,
          location: "Location is required",
        };
      });
    }

    return flag;
  };

  const imageURL = useRef("");

  const handleImageUpload = (image) => {
    imageURL.current = image;
    handlePostRequest(image);
    setEditImageModal(false);
  };

  const handlePostRequest = async (image) => {
    const byteString = window.atob(image.split(",")[1]);
    const mimeString = image.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blobFile = new Blob([ia], { type: mimeString });
    const formData = new FormData();
    formData.append("company_logo", blobFile, `${formData.name}`);

    let headers = {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "multipart/form-data",
    };
    const response = await axios.post(
      `
    ${process.env.REACT_APP_API_URL}/jobs/uploadCompanyLogo`,
      formData,
      { headers }
    );

    if (response.status === 200) {
      console.log("Response: ", response);
      setFormData((prev) => {
        return {
          ...prev,
          imageUrl: response.data.company_logos.url,
        };
      });
    }
  };

  const handleCompanySave = async () => {
    if (validations()) {
      let body = {
        college_id: parseInt(collegID),
        company_name: formData.name,
        about: formData.about,
        location: formData.location,
        poc: formData.poc,
      };
      if (formData.imageUrl !== "") {
        body = {
          ...body,
          company_image: formData.imageUrl,
        };
      }

     

      try {
        const put = await service.post(`/po/ca/applications/company`, body);

      if (setAllCompanies) {
        setAllCompanies((prev) => {
          return [
            ...prev,
            {
              id: put.data.data,
              college_id: collegID,
              company_name: formData.name,
              about: formData.about,
              poc: formData.poc,
              company_image: formData.imageUrl ? formData.imageUrl : null,
              location: formData.location,
            },
          ];
        });
        close();
      }

      setFormError({
        name: "",
        about: "",
        location: [],
        poc: [],
      }); 
      } catch (error) {
      toast.error(`Error: ${error.response.data.message}`,{theme:"colored"})
        
      }
    }
  };

  const handleCompanyUpdate = async () => {
    let body = {
      id: data.id,
      college_id: data.college_id,
      company_name: formData.name,
      about: formData.about,
      location: formData.location,
      poc: formData.poc,
    };
    if (![null, ""].includes(formData.imageUrl)) {
      body = {
        ...body,
        company_image: formData.imageUrl,
      };
    }
    try {
      console.log("fdnksfdsf")
      const put = await service.put(`/po/ca/applications/company`, body);

      setAllCompanies((prev) => {
        return prev.map((item) => {
          if (item.id === data.id) {
            return {
              ...item,
              company_name: formData.name,
              about: formData.about,
              location: formData.location,
              poc: formData.poc,
              company_image: formData.imageUrl,
            };
          } else {
            return item;
          }
        });
      });
      close();
    } catch (error) {
      toast.error(`Error: ${error.response.data.message}`,{theme:"colored"})
    }

   
  };

  const handleEditPOC = () => {
    let temp = { ...formData };
    temp.poc = temp.poc.map((item, i) => {
      if (i === selectedPOC) {
        return { ...newPoc };
      } else {
        return { ...item };
      }
    });

    setFormData(temp);
    setSelectedPOC(null);
    setNewPoc({
      name: "",
      email: "",
      ph_no: "",
    });
  };

  useEffect(() => {
    const handleCollegeID = async () => {
      const data = await service.get("collegeAdmin/college");
     
      setCollegeId(data.collegDetail[0].college_id);
    };
    if ([null, undefined, "undefined", "null", ""].includes(collegID)) {
      handleCollegeID();
    }
  }, []);
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Box sx={{ height: "100vh", padding: "1rem", borderRadius: "8px 0px 0px 8px", width: "min(700px, 50vw)", background: "#fff", overflow: "auto" }}>
        <IconButton onClick={() => close()}>
          <Close />
        </IconButton>

        <ToastContainer />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {[null, ""].includes(formData.imageUrl) ? (
            <Box sx={{ padding: "10px", border: `1px solid ${Color.neutralMidGrey}`, borderRadius: "50%", position: "relative" }}>
              <Business sx={{ color: Color.primary1, fontSize: "100px" }} />
              <Box sx={{ position: "absolute", right: 2, bottom: -5, background: Color.neutralLightGrey, borderRadius: "50%", padding: "2px" }} onClick={() => setEditImageModal(true)}>
                <Edit sx={{}} />
              </Box>
            </Box>
          ) : (
            <Box sx={{ position: "relative" }}>
              <img src={formData.imageUrl} alt={formData.name} style={{ width: "150px", aspectRatio: "1/1", borderRadius: "50%" }} />
              <Box sx={{ background: Color.neutralLightGrey, position: "absolute", padding: "2px", borderRadius: "50%", right: 0, bottom: -10 }} onClick={() => setEditImageModal(true)}>
                <Edit />
              </Box>
            </Box>
          )}
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Typography sx={{ fontSize: "32px", fontWeight: "600" }}>Add New Company</Typography>
          <Box>
            <Typography sx={{ marginBlock: "2px", fontSize: "20px" }}>
              Company name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField placeholder="Company Name" required value={formData.name} name="name" onChange={(e) => handleChange(e)} fullWidth error={formError.name === "" ? false : true} helperText={formError.name} />
          </Box>
          <Box>
            <Typography sx={{ marginBlock: "2px", fontSize: "20px" }}>
              About Company <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField placeholder="About company" required multiline value={formData.about} name="about" onChange={(e) => handleChange(e)} fullWidth error={formError.about === "" ? false : true} helperText={formError.about} />
          </Box>

          <Box>
            <Typography sx={{ marginBlock: "2px", fontSize: "20px" }}>
              Location <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              multiple
              autoComplete="off"
              placeholder="Locations"
              options={[]}
              value={formData.location}
              freeSolo
              renderTags={(value, getTagProps) => value.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)}
              renderInput={(params) => <TextField {...params} />}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(e, values) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    location: values,
                  };
                });
              }}
            />
            {formError.location !== "" && <p style={{ color: "red", fontSize: "14px" }}>{formError.location}</p>}
          </Box>

          {formData.poc.length > 0 || addMore ? (
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
                <Typography sx={{ fontSize: "20px" }}>Point of Contact</Typography>
                <Box sx={{ display: "flex", gap: "2px", alignItems: "center", "&:hover": { cursor: "pointer" } }} onClick={() => !addMore && setAddMore(true)}>
                  <Add sx={{ background: Color.primary1, color: Color.white, padding: "2px", borderRadius: "50%", fontSize: "16px" }} />
                  <Typography sx={{ color: Color.primary1, fontSize: "16px" }}>Add More</Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                {formData.poc.map((item, index) =>
                  selectedPOC === index ? (
                    <Box>
                      <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                        <TextField
                          sx={{ flex: 1 }}
                          value={newPoc.name}
                          inputProps={{ style: { height: "1rem", padding: "10px" } }}
                          placeholder="Name"
                          error={newPocError.name === "" ? false : true}
                          helperText={newPocError.name}
                          onChange={(e) =>
                            setNewPoc((prev) => {
                              return {
                                ...prev,
                                name: e.target.value,
                              };
                            })
                          }
                        />
                        <TextField
                          sx={{ flex: 1 }}
                          value={newPoc.email}
                          inputProps={{ style: { height: "1rem", padding: "10px" } }}
                          placeholder="Email"
                          error={newPocError.email === "" ? false : true}
                          helperText={newPocError.email}
                          type="email"
                          onChange={(e) =>
                            setNewPoc((prev) => {
                              return {
                                ...prev,
                                email: e.target.value,
                              };
                            })
                          }
                        />
                        <TextField
                          sx={{ flex: 1 }}
                          value={newPoc.ph_no}
                          inputProps={{ style: { height: "1rem", padding: "10px" } }}
                          placeholder="Phone number"
                          error={newPocError.ph_no === "" ? false : true}
                          helperText={newPocError.ph_no}
                          onChange={(e) =>
                            setNewPoc((prev) => {
                              return {
                                ...prev,
                                ph_no: e.target.value,
                              };
                            })
                          }
                        />
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem", marginBlock: "10px" }}>
                        <Button
                          sx={{ textTransform: "none", border: `1px solid ${Color.primary1}`, padding: "1px 8px", color: Color.primary1, borderRadius: "8px" }}
                          onClick={() => {
                            setSelectedPOC(null);
                            setNewPoc({ name: "", email: "", ph_no: "" });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button sx={{ textTransform: "none", padding: "1px 8px", background: Color.primary1, borderRadius: "8px", color: Color.white, "&:hover": { background: Color.primary1, color: Color.white } }} onClick={() => handleEditPOC()}>
                          Save
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ flex: 1 }}>{item.name}</Typography>
                      <Typography sx={{ flex: 1, textAlign: "center" }}>{item.email}</Typography>
                      <Typography sx={{ flex: 1, textAlign: "center" }}>{item.ph_no}</Typography>
                      <IconButton
                        sx={{ padding: "2px", marginInline: "2px" }}
                        onClick={() => {
                          setSelectedPOC(index);
                          setNewPoc({ ...item });
                        }}
                      >
                        <Edit sx={{ color: Color.primary1 }} />
                      </IconButton>
                      <IconButton sx={{ padding: "2px" }} onClick={() => handlePocDelete(index)}>
                        <DeleteOutlineOutlined sx={{ color: Color.red }} />
                      </IconButton>
                    </Box>
                  )
                )}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", "&:hover": { cursor: "pointer" } }}
              onClick={() => {
                setAddMore(true);
              }}
            >
              <Add />
              <Typography>Add Point of contact</Typography>
            </Box>
          )}

          {addMore && (
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <TextField
                  sx={{ flex: 1 }}
                  value={newPoc.name}
                  inputProps={{ style: { height: "1rem", padding: "10px" } }}
                  placeholder="Name"
                  error={newPocError.name === "" ? false : true}
                  helperText={newPocError.name}
                  onChange={(e) =>
                    setNewPoc((prev) => {
                      return {
                        ...prev,
                        name: e.target.value,
                      };
                    })
                  }
                />
                <TextField
                  sx={{ flex: 1 }}
                  value={newPoc.email}
                  inputProps={{ style: { height: "1rem", padding: "10px" } }}
                  placeholder="Email"
                  error={newPocError.email === "" ? false : true}
                  helperText={newPocError.email}
                  type="email"
                  onChange={(e) =>
                    setNewPoc((prev) => {
                      return {
                        ...prev,
                        email: e.target.value,
                      };
                    })
                  }
                />
                <TextField
                  sx={{ flex: 1 }}
                  value={newPoc.ph_no}
                  inputProps={{ style: { height: "1rem", padding: "10px" } }}
                  placeholder="Phone number"
                  error={newPocError.ph_no === "" ? false : true}
                  helperText={newPocError.ph_no}
                  onChange={(e) =>
                    setNewPoc((prev) => {
                      return {
                        ...prev,
                        ph_no: e.target.value,
                      };
                    })
                  }
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem", marginBlock: "10px" }}>
                <Button sx={{ textTransform: "none", border: `1px solid ${Color.primary1}`, padding: "1px 8px", color: Color.primary1, borderRadius: "8px" }} onClick={() => handleCancel()}>
                  Cancel
                </Button>
                <Button sx={{ textTransform: "none", padding: "1px 8px", background: Color.primary1, borderRadius: "8px", color: Color.white, "&:hover": { background: Color.primary1, color: Color.white } }} onClick={() => handleNewPOC()}>
                  Save
                </Button>
              </Box>
            </Box>
          )}

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button sx={{ textTransform: "none", padding: "8px 32px", background: Color.primary1, borderRadius: "8px", color: Color.white, "&:hover": { background: Color.primary1, color: Color.white }, fontSize: "16px" }} onClick={() => ([null, undefined].includes(data) ? handleCompanySave() : handleCompanyUpdate())}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
      <Modal open={editImageModal} onClose={() => setEditImageModal(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <ImageCropper handleImageUpload={(data) => handleImageUpload(data)} imageUrl={formData.imageUrl} close={() => setEditImageModal(false)} loading={loading} />
      </Modal>
    </Box>
  );
}

export default NewCompany;
