export function convertToIndianFormat(number) {
  if (number >= 10 ** 7) {
    return formatNumber(number / 10 ** 7) + "Cr";
  } else if (number >= 10 ** 5) {
    return formatNumber(number / 10 ** 5) + "L";
  } else if (number >= 10 ** 3) {
    return formatNumber(number / 10 ** 3) + "K";
  } else {
    return number.toString();
  }
}

function formatNumber(num) {
  return num % 1 === 0 ? num.toString() : num.toFixed(2);
}
