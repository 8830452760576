import { AddOutlined, GetApp, Search, UploadFile } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Skeleton, Grid } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Color, DivCenter, styles } from "../../GlobalStyles"
// import { div } from 'antd';
// import { EditStudentSpecilization } from './StudentSummayModals';
// import CCService from '../../../../services/httpServiceWithoutSuperAdmin';
// import { ActivityLoader } from '../../../reusables/Loader/Loader';
// import { SearchFunction }  from "../reusables/TextFunctions"
import Papa from "papaparse";
import CCService from '../../services/httpServiceWithoutSuperAdmin';
import Loader from '../reusables/Loader/Loader';
const StudentSummaryModule = ({ process_id, studentDetails, pageData, setStudentDetails, getStudentDetails, specializationData }) => {
    console.log("StudentSummaryModule ~ studentDetails:", process_id, studentDetails, pageData)

    //DATA variables
    const service = new CCService();
    const [loading, setLoading] = useState({ button: false, lazyLoading: false, search: false });
    const [searchTerm, setSearchTerm] = useState('');
    const [filterButtons, setFilterButtons] = useState({
        'All': true,
        'HR': false,
        'Finance': false,
        'IT': false,
        'Sales and Marketing': false,
        'Operations': false,
        'Analytics': false,
        'Consulting': false,
        'Not yet selected': false,
        'Student not interested': false
    });

    //data
    const [filteredStudentDetails, setFilteredStudentDetails] = useState([...studentDetails]);

    console.log("filtere")
    const [editSpecilization, setEditSpecilization] = useState({
        open: false,
        onClose: () => setEditSpecilization((prev) => ({ ...prev, open: false })),
        extraData: {
            is_edit: true,
            details: {
                first_name: '', last_name: '', provided_spe_name: '', sku_name: '', college_name: '', batch_name: '',
            },
            spe: {
                selected: { id: null, name: '' },
                options: [],
                resume: { url: '', name: '', },
                other_docs: [{ url: '', name: '', }],
            }
        }
    });

    //lazy loading
    const [currentPage, setCurrentPage] = useState(1);
    const [endArray, setEndArray] = useState(false);
    //FUNCTIONS
    useEffect(() => {
        if (currentPage !== 1) loadMoreData(currentPage);
    }, [currentPage]);

    const updateSpec = (response) => {
        setStudentDetails([...response]);
        setFilteredStudentDetails([...response]);
    }
    const loadMoreData = async (page) => {
        setLoading((prev) => ({ ...prev, lazyLoading: true }));
        const response = await getStudentDetails(page);
        console.log("loadMoreData ~ response:", response);
        if (response.length === 0) {
            setEndArray(true);
        }
        if (page !== 1 && !(
            filterButtons.Analytics ||
            filterButtons.Consulting ||
            filterButtons.Finance ||
            filterButtons.HR ||
            filterButtons['Not yet selected'] ||
            filterButtons.Operations ||
            filterButtons['Sales and Marketing'] ||
            filterButtons['Student not interested'] ||
            filterButtons.Technology
        )) {
            setStudentDetails([...studentDetails, ...response]);
            setFilteredStudentDetails([...studentDetails, ...response]);
        }
        // console.count('[GET] call');
        setLoading((prev) => ({ ...prev, lazyLoading: false }));
    }

    const handleScroll = (event) => {
        console.log("handleScroll ~ event:", event)
        const { scrollHeight, scrollTop, clientHeight } = event.target;

        if (scrollTop + clientHeight >= scrollHeight && !loading.lazyLoading) {
            if (!endArray) {
                setCurrentPage((prevPage) => prevPage + 1);
                loadMoreData(currentPage + 1);
            }
            // else window.alert('Page end')
        }
    };
    console.log("filtered arr:", filteredStudentDetails);
    const downloadCSV = async(details) => {

        const response = await service.get(`/os/superadmin/order?process_id=${process_id}`);
        let temp_filteredData =[...response.data];
        if (filterButtons.All) {
            temp_filteredData = [...response.data];
        } else if (filterButtons.HR) {
            temp_filteredData = response.data.filter((student) => student.spe_name === 'HR');
        } else if (filterButtons.Finance) {
            temp_filteredData = response.data.filter((student) => student.spe_name === 'Finance');
        } else if (filterButtons.IT) {
            temp_filteredData = response.data.filter((student) => student.spe_name === 'Technology');
        } else if (filterButtons['Sales and Marketing']) {
            temp_filteredData = response.data.filter((student) => student.spe_name === 'Sales and Marketing');
        } else if (filterButtons.Operations) {
            temp_filteredData = response.data.filter((student) => student.spe_name === 'Operations');
        } else if (filterButtons.Analytics) {
            temp_filteredData = response.data.filter((student) => student.spe_name === 'Analytics');
        } else if (filterButtons.Consulting) {
            temp_filteredData = response.data.filter((student) => student.spe_name === 'Consulting');
        } else if (filterButtons['Not yet selected']) {
            temp_filteredData = response.data.filter((student) => student.spe_name === null && !student.student_not_interested);
        } else if (filterButtons['Student not interested']) {
            temp_filteredData = response.data.filter((student) => student.student_not_interested);
        }
        const temp = temp_filteredData.map((item) => {

            const other_docs_flat = []

            JSON.parse(item?.other_docs)?.files?.map((obj, index) => {
                other_docs_flat.push(obj.url)
                console.log("object:", obj)
            });
            let temp_resume = item.resume
            console.log("other_docs_flat:", other_docs_flat)
            delete item["specialization_deadline"];
            delete item["id"]
            delete item["user_id"];
            delete item["spe_id"]
            delete item["resume"]
            delete item["student_not_interested"]
            return {
                ...item,
                resume_name: temp_resume ? JSON.parse(temp_resume)?.name : [],
                resume_url: temp_resume ? JSON.parse(temp_resume)?.url : [],
                other_docs: other_docs_flat,

            }
        })
        //   const outputObject = assessment_key.reduce((result, currentObj) => {
        //     const key = Object.keys(currentObj)[0]; // Assuming each object has only one key
        //     const value = currentObj[key];
        //     result[key] = value;
        //     return result;
        //   }, {});

        //   return {
        //     [`Candidate Name`]: `${item.first_name} ${item.last_name}`,
        //     College: item.college,
        //     ...outputObject,
        //     Competency: item.competency_score,
        //     Resume: item.resume_urls,
        //   };
        // });
        console.log("temp:", temp,);
        const csvData = Papa.unparse(temp);

        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Student_Summary.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handleCSVUpload = (event) => {
        let bulkUploadData = [];
        console.log("event", event);
        console.log("csv upload at student data");
        let parsedData = {};
        if (event && event.target) {
            let file = event.target?.files[0];
            let reader = new FileReader();
            reader.readAsText(file);
            reader.onload = async () => {
                setLoading((prev) => ({ ...prev, activity: true }));
                parsedData = Papa.parse(reader.result, { delimiter: ",", dynamicTyping: true }).data;
                let roll_nos = parsedData.filter((item) => item[0] !== "roll_no").flatMap((row) => (row[0] ? [row[0].toString()] : []));
                console.log("data inside bulk upload", parsedData, roll_nos);
                bulkUploadData = parsedData?.map((item, index) => {
                    if (index > 0) {
                        return ({ spe_id: item[1], roll_no: JSON.stringify(item[0]) })
                    }
                }).slice(1, -1);
                console.log("bulkdata:", bulkUploadData);
                if (bulkUploadData.length > 0 || bulkUploadData.includes("null") || bulkUploadData.includes("undefined")) {
                    console.log("in the upload body:", { "process_id": parseInt(process_id), "bulkData": [...bulkUploadData] });
                    await service.put("/os/superadmin/uploadBulkData", { process_id: parseInt(process_id), bulkData: [...bulkUploadData] });
                }

                const response = await getStudentDetails();
                setStudentDetails([...response]);
                setFilteredStudentDetails([...response]);
                console.log("res in handlecsv:", response)
                setLoading((prev) => ({ ...prev, activity: false }));
                console.log("already out of ")
            };
        }
    }



    //FRONTEND - SEARCH
    useEffect(() => {
        handleFilterEffect();
    }, [filterButtons['All'], filterButtons['HR'], filterButtons['Finance'], filterButtons['IT']]);

    const handleFilterEffect = () => {
        let flag = '';
        for (const key in filterButtons) {
            if (filterButtons[key] === true) flag = key;
        }
        handleFilterButton(flag, false);
    }

    const handleChangeSearch = async (e, newValue) => {
        const search_term = e.target.value;
        setSearchTerm(search_term);
        if (search_term) {
            // FRONTEND - SEARCH
            // const filtered = SearchFunction(studentDetails, ['first_name', 'last_name', 'email_id', 'ph_no', 'spe_name'], searchTerm);
            // console.log("handleChangeSearch ~ filtered:", filtered);
            // BACKEND - SEARCH
            // const filtered = await getStudentDetails(null, search_term);
            // setFilteredStudentDetails(filtered);
        }
        else {
            setFilteredStudentDetails([...studentDetails]);
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleClickSearchButton();
        }
    }

    const handleClickSearchButton = async () => {
        setLoading((prev) => ({ ...prev, search: true }));
        if (searchTerm) {
            // BACKEND - SEARCH
            const filtered = await getStudentDetails(undefined, searchTerm);
            console.log("handleClickSearchButton ~ filtered:", filtered)
            setFilteredStudentDetails(filtered);
        }
        else {
            setFilteredStudentDetails([...studentDetails]);
        }
        setLoading((prev) => ({ ...prev, search: false }));
    }

    const handleFilterButton = (flag, scroll) => {
        // if (scroll) handleViewStudents();
        let temp_filteredData = [...studentDetails];
        const temp_filter = {};
        for (let key in filterButtons) {
            if (key === flag) temp_filter[key] = true;
            else temp_filter[key] = false;
        }
        setFilterButtons(temp_filter);
        if (flag === 'All') {
            temp_filteredData = [...studentDetails];
        } else if (flag === 'HR') {
            temp_filteredData = temp_filteredData.filter((student) => student.spe_name === 'HR');
        } else if (flag === 'Finance') {
            temp_filteredData = temp_filteredData.filter((student) => student.spe_name === 'Finance');
        } else if (flag === 'IT') {
            temp_filteredData = temp_filteredData.filter((student) => student.spe_name === 'Technology');
        } else if (flag === 'Sales and Marketing') {
            temp_filteredData = temp_filteredData.filter((student) => student.spe_name === 'Sales and Marketing');
        } else if (flag === 'Operations') {
            temp_filteredData = temp_filteredData.filter((student) => student.spe_name === 'Operations');
        } else if (flag === 'Analytics') {
            temp_filteredData = temp_filteredData.filter((student) => student.spe_name === 'Analytics');
        } else if (flag === 'Consulting') {
            temp_filteredData = temp_filteredData.filter((student) => student.spe_name === 'Consulting');
        } else if (flag === 'Not yet selected') {
            temp_filteredData = temp_filteredData.filter((student) => student.spe_name === null && !student.student_not_interested);
        } else if (flag === 'Student not interested') {
            temp_filteredData = temp_filteredData.filter((student) => student.student_not_interested);
        }
        setFilteredStudentDetails(temp_filteredData);
    }


    const handleOpenEditSpecilizationModal = async (student) => {
        console.log("handleOpenEditSpecilizationModal ~ student order:", student, Date.now())

        setLoading((prev) => ({ ...prev, activity: true }));
        const get_specialization = [...specializationData];
        setEditSpecilization((prev) => ({
            ...prev, open: true,
            extraData: {
                is_edit: student.spe_name ? true : false,
                // is_edit: student.spe_name ? false : true,
                details: {
                    first_name: student.first_name, last_name: student.last_name,
                    provided_spe_name: student.spe_name,
                    sku_name: pageData.sku_name, college_name: pageData.college_code,
                    batch_name: pageData.user_group_name, batch_type: pageData.name,
                },
                order: student,
                spe: {
                    selected: get_specialization.find((spe) => spe.name === student.spe_name),
                    options: get_specialization,
                    resume: { url: '', name: '', },
                    other_docs: [{ url: '', name: '', }],
                }
            }
        }));
        setLoading((prev) => ({ ...prev, activity: false, }));

    }

    //Styles
    const local_styles = {
        filter_buttons: {
            minWidth: '100px',
            width: 'auto',
            background: Color.white,
            color: Color.primary1,
            borderRadius: '14px',
            border: `1px solid ${Color.primary1}`,
            textTransform: 'none',
            '&:hover': {
                background: 'rgba(195, 236, 245, 1)',
                color: Color.primary1,
            }
        }
    }
    const fileInputRef = useRef(null);
    return (

      
            <Box sx={{ pt: '48px', width: '100%' }}>

                <Box sx={{ ...DivCenter, width: '100%', mb: '16px' }}>
                    <TextField
                        name="search_colleges"
                        // label="Search Student"
                        placeholder='Search Students'
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleChangeSearch}
                        onKeyDown={handleKeyPress}
                        fullWidth
                        sx={{ width: '80%', borderRadius: "12px" }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search sx={{ color: Color.neutralMidGrey }} />
                                </InputAdornment>
                            ),
                            sx: { borderRadius: "12px", height: "58px" }
                        }}
                    />
                    <Box sx={{ ...DivCenter, width: '10%' }}><Button variant='contained' onClick={() => handleClickSearchButton()} sx={{ ...styles.button_styles.contained }}>Search</Button></Box>
                    <Box sx={{ width: '10%', minWidth: '121px' }}>

                        <IconButton>
                            <AddOutlined />
                        </IconButton>
                        <IconButton onClick={() => downloadCSV()}><GetApp /></IconButton>
                        <IconButton >  <input
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => handleCSVUpload(e)}
                            ref={fileInputRef}
                        /><UploadFile onClick={() => fileInputRef.current.click()} /></IconButton>
                    </Box>
                </Box>
                <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-start', gap: '8px', mb: '24px' }}>
                    {Object.keys(filterButtons).map((filter) =>
                        <Button onClick={() => { handleFilterButton(filter, true); }}
                            sx={{
                                ...local_styles.filter_buttons,
                                background: filterButtons[filter] ? 'rgba(195, 236, 245, 1)' : Color.white,
                            }}>{filter}</Button>)}
                </Box>
                <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', background: 'rgba(195, 236, 245, 0.1)', minHeight: '113px', p: '16px' }}>
                    <Box><div style={{ fontSize: '22px', color: Color.primary1, }}>Student details </div></Box>
                    <Box><div style={{ fontSize: '22px', color: Color.primary1, }}>Specialization</div></Box>
                    <Box><div style={{ fontSize: '22px', color: Color.primary1, }}>Action</div></Box>
                </Box>
                {loading.search ?
                    [...Array(3)].fill(null).map((_, index) => (
                        <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', minHeight: '113px', p: '16px', }}>
                            <Box>
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                            </Box>
                            <Box>
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                            </Box>
                            <Box>
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                            </Box>
                        </Box>
                    ))
                    : <Box onScroll={handleScroll} sx={{ width: '100%', height: '300px', overflowY: 'auto', }}>

                        {filteredStudentDetails.length === 0 && !loading.lazyLoading && (
                            <p>No data available.</p>
                        )}

                        {filteredStudentDetails.map((student, student_index) =>
                            <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', background: student_index % 2 ? 'rgba(251, 251, 251, 1)' : Color.white, minHeight: '113px', p: '16px' }}>
                                <Box>
                                    <div style={{ color: Color.primary1, fontSize: '16px' }}>{student.first_name + " " + student.last_name}</div>
                                    <div style={{ color: Color.neutralMidGrey, fontSize: '16px' }}>{student.email_id}</div>
                                    <div style={{ color: Color.neutralMidGrey, fontSize: '16px' }}>{student.ph_no}</div>
                                </Box>
                                <Box>
                                    <div style={{ color: Color.neutralMidGrey, fontSize: '16px' }}>{student.spe_name ?? "-"}</div>
                                </Box>
                                <Box>
                                    <Button disabled={loading.button} variant='text' onClick={() => handleOpenEditSpecilizationModal(student)}
                                        style={{ color: student.specialization_deadline > Date.now() ? Color.primary1 : Color.neutralMidGrey, fontSize: '16px', textTransform: 'none' }}>
                                        {loading.button ?
                                            <CircularProgress size={24} sx={{ color: Color.primary1, }} />
                                            : student.spe_name ? 'Edit' : 'Add'}
                                    </Button>
                                </Box>
                            </Box>)
                        }

                        {loading.lazyLoading &&
                            [...Array(1)].fill(null).map((_, index) => (
                                <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', minHeight: '113px', p: '16px', }}>
                                    <Box>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                    </Box>
                                    <Box>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                    </Box>
                                    <Box>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" width="120px" />
                                    </Box>
                                </Box>
                            ))
                        }

                        {/* {loading.lazyLoading && <p>Loading more...</p>} */}
                    </Box>}

            </Box >

        // <Box></Box>
    )
}

export default StudentSummaryModule