import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ForumIcon from "@mui/icons-material/Forum";
import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Color } from "../../GlobalStyles";
import Loader from "../../components/reusables/Loader/Loader";
import CCService from "../../services/httpServiceWithoutSuperAdmin";
import "../Dashboard/OngoingEvents/OngoingEvents.css";
import SideNavigationBar from "../reusables/SideNavigationBar/SideNavigationBar";
// import { AllTheGroupChats } from "./PostingOpportunityModule/NoJobsAvailableModal/AllTheGroupChats";
import ReactApexChart from "react-apexcharts";
import { ellipsisFunction } from "../../services/EllipsisFunction";
import GroupChatModal from "./GroupChatModal/GroupChatModal";
import AllTheGroupChats from "./PostingOpportunityModule/NoJobsAvailableModal.js/AllTheGroupChats";
import { Button, Modal } from "@mui/material";
import NewCompany from "./Company/NewCompany";
import { useSelector } from "react-redux";

const Batches = () => {
  const service = new CCService();
  const navigate = useNavigate();
  const location = useLocation();
  const querry = new URLSearchParams(location.search);
  let [college_id, setCollegeId] = useState(querry.get("college_id"));
 
  const [batchData, setBatchData] = useState([]);
  const [companyModal, setCompanyModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [chatRoom, setCharRoom] = useState(false);
  const [postingOpportunityDetails, setPostingOpportunityDetails] = useState([]);
  const [groupChat, setGroupChat] = useState(false);
  const [job_id, setJob_id] = useState(null);
  const goBack = () => {
    console.log("Back Button is Clicked Now");
    navigate(-1);
  };
  const [studentRatings, setStudentRatings] = useState({
    series: [44, 55, 41, 17, 15],

    options: {
      chart: {
        type: "donut",
      },
      responsive: [
        {
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      colors: ["#00894F", "#FF9807", "#8B8B8B", "#000000"],
      tooltip: {
        enabled: true,
        y: {
          formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
            const labels = ["Placed", "Unplaced", "opt-out", "blacklist"];
            return labels[dataPointIndex] + ": " + value;
          },
        },
      },
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "left",
        fontSize: "12px",
        width: 250,
        offsetX: 130,
      },
      dataLabels: {
        show: false,
        style: {
          fontSize: "14px",
          colors: ["transparent"],
        },
        background: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            show: false,
            offset: 35,
          },
          donut: {
            width: "100%", // Adjust the width of the donut chart
          },
          labels: {
            show: false,
          },
        },
      },
    },
  });
  useEffect(() => {
    const handleEffect = async () => {
      if (Cookies.get("token") === null || Cookies.get("token") === undefined) {
        navigate("/");
      }

      const data = await service.get("collegeAdmin/college");
      console.log("ANIEMLKSNKJNKJd", data);
      setCollegeId(data.collegDetail[0].college_id);
      //batch data getter
      const batchData = await batchDataGetter();
      console.log("batch data", batchData);
      setBatchData(batchData);
      const updatedStudentRatings = {};
      batchData.forEach((batch) => {
        updatedStudentRatings[batch.id] = {
          series: [batch.placed_count, batch.Unplaced_count, batch.opt_out_count, batch.blacklist_count],
          options: {
            chart: {
              type: "donut",
            },
            responsive: [
              {
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
            colors: ["#00894F", "#FF9807", "#8B8B8B", "#000000"],
            tooltip: {
              enabled: true,
              y: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                  const labels = ["Placed", "Unplaced", "opt-out", "blacklist"];
                  return labels[dataPointIndex] + ": " + value;
                },
              },
            },
            legend: {
              show: false,
              position: "bottom",
              horizontalAlign: "left",
              fontSize: "12px",
              width: 250,
              offsetX: 130,
            },
            dataLabels: {
              show: false,
              style: {
                fontSize: "14px",
                colors: ["transparent"],
              },
              background: {
                enabled: false,
              },
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  show: false,
                  offset: 35,
                },
                donut: {
                  width: "100%", // Adjust the width of the donut chart
                },
                labels: {
                  show: false,
                },
              },
            },
          },
        };
      });
      setStudentRatings(updatedStudentRatings);
      console.log("odonil", { batchData });
      setLoading(false);
    };

    handleEffect();
  }, []);
 

  const batchDataGetter = async () => {
    const headers = {
      Authorization: `Bearer ${Cookies.get("token")}`,
    };
    try {
      const res = await service.get("/po/ca/batches", { headers });
      console.log("batches get data RESPONSE:", res.batches);
      // setBatchData([...res.batches])
      return res.batches;
    } catch (err) {
      console.log(err);
    }
  };

  const handleJobOpportunityClick = (batch_id, batch_name) => {
    navigate("/JobsPostingPage", { state: { batch_id: batch_id, batch_name: batch_name, college_id: college_id } });
  };

  const handleSettingsClick = (batch_id, batch_name) => {
    navigate("/Settings", { state: { batch_id: batch_id, batch_name: batch_name } });
  };

  const handleModalClose = () => {
    setCharRoom(false);
  };

  const postingOpportunityDataGetter = async (batch_id) => {
    try {
      const res = await service.get(`/po/ca/applications?user_group_id=${batch_id}`);
      return res.applications;
    } catch (err) {}
  };

  const handleChatClick = async () => {
    let allOpportunityDetails = [];
    batchData.forEach(async (batch) => {
      let opportunityGetter = await postingOpportunityDataGetter(batch?.id);
      if (opportunityGetter) {
        opportunityGetter.forEach((opportunity) => {
          opportunity.batch_name = batch.user_group_name;
        });
        allOpportunityDetails.push(...opportunityGetter);
        setPostingOpportunityDetails(allOpportunityDetails);
      }
    });
    setPostingOpportunityDetails((prevDetails) => [...prevDetails, ...allOpportunityDetails]);
    setCharRoom(true);
  };

  const handleGroupChatOpenInReview = (po_job_id) => {
    setGroupChat(true);
    setJob_id(po_job_id);
  };

  const handleGroupChatCloseInReview = () => {
    setGroupChat(false);
  };

  return (
    <div>
      <SideNavigationBar />
      {loading ? (
        <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: "0 auto" }}>
          <Loader show={loading} />
        </div>
      ) : (
        <>
          <div className="OngoingEventsCard" style={{ marginTop: "2rem", marginLeft: "10rem" }}>
            <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Typography style={{ marginBottom: "2rem", marginLeft: ".5rem", marginTop: "1rem" }} sx={{ fontSize: 22 }}>
                Batches
              </Typography>
              <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                <Button sx={{ color: Color.primary1, border: `1px solid ${Color.primary1}`, padding: "5px 16px", borderRadius: "8px" }} onClick={() => navigate("/companies")}>
                  Company
                </Button>
                <Box style={{ marginRight: "10%" }}>
                  <ForumIcon style={{ cursor: "pointer", color: Color.primary1 }} onClick={() => handleChatClick()} />
                </Box>
              </Box>
            </Box>

            <div className="cardsInsideFlex">
              {batchData.map((batchInfo, i) => {
                return (
                  <Box sx={{ width: 255, marginRight: "1rem", boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)", borderRadius: "10px" }}>
                    <CardContent className="cardContainer" disableGutters>
                      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Box>
                          <Typography variant="body2" sx={{ fontSize: 20 }} gutterBottom>
                            {batchInfo?.user_group_name ? ellipsisFunction(batchInfo?.user_group_name) : ""}
                          </Typography>
                          <Typography variant="h5" component="div"></Typography>
                        </Box>
                        <SettingsIcon style={{ float: "right", cursor: "pointer", color: Color.primary1 }} onClick={() => handleSettingsClick(batchInfo?.id, batchInfo?.user_group_name)} />
                      </Box>
                      <Box style={{ marginTop: "0rem", position: "relative" }}>
                        <ReactApexChart options={studentRatings[batchInfo.id].options} series={studentRatings[batchInfo.id].series} type="donut" height={200} width={220} />
                      </Box>
                      <div className="container" style={{ justifyContent: "center" }}>
                        <Typography variant="body2" color={Color.neutralDarkGrey} style={{ margin: "auto 0" }}>
                          Total Students:
                        </Typography>
                        <Typography variant="body2" color={Color.neutralDarkGrey} class="providingSpaceForSubDetails">
                          {batchInfo?.student_count}
                        </Typography>
                      </div>
                      <CardActions sx={{ paddingBottom: "0px" }}>
                        <button className="submitBtn" type="button" style={{ height: "44px" }} onClick={() => handleJobOpportunityClick(batchInfo?.id, batchInfo?.user_group_name)}>
                          Job Opportunities
                        </button>
                      </CardActions>
                      <CardActions sx={{ paddingBottom: "0px" }}>
                        <button className="submitBtn" type="button" style={{ height: "44px", background: "transparent", border: "1px solid #38C1DF", color: "black" }} onClick={() => navigate(`/ProfileVerification/Batches/Students?batch_id=${batchInfo?.id}`, { state: { batch_name: batchInfo?.user_group_name, college_id:college_id } })}>
                          Student list
                        </button>
                      </CardActions>
                      <CardActions sx={{ paddingBottom: "0px" }}>
                        <button className="submitBtn" type="button" style={{ height: "44px", background: "transparent", border: "1px solid #38C1DF", color: "black" }}>
                          Mark Student(s) Placed
                        </button>
                      </CardActions>
                    </CardContent>
                  </Box>
                );
              })}
            </div>
          </div>
        </>
      )}

      <AllTheGroupChats modalOpen={chatRoom} handleModalClose={handleModalClose} handleJobOpportunityClick={handleJobOpportunityClick} postingOpportunityDetails={postingOpportunityDetails} handleGroupChatOpenInReview={handleGroupChatOpenInReview} />
      {groupChat && <GroupChatModal modalOpen={groupChat} handleModalClose={handleGroupChatCloseInReview} job_id={job_id} handleCloseApplications={handleGroupChatCloseInReview} />}

      <Modal open={companyModal} onClose={() => setCompanyModal(false)}>
        <NewCompany college_id={college_id} close={() => setCompanyModal(false)} />
      </Modal>
    </div>
  );
};

export default Batches;
