import React, { useState, useEffect } from 'react'
import SideNavigationBar from '../reusables/SideNavigationBar/SideNavigationBar'
import Loader from '../reusables/Loader/Loader';

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { emphasize, styled } from '@mui/material/styles';
import { Color } from '../../GlobalStyles';
import Chip from '@mui/material/Chip';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CCService from '../../services/httpServiceWithoutSuperAdmin';
import Cookies from 'js-cookie';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Divider } from '@mui/material';
import { convertEpochToNormalTime, ddmmm } from '../../services/specialCharReplace';

function OfflineSessionList() {
    const [loading, setLoading] = useState(true);
    const [processData, setProcessData] = useState([]);
    const [batchData, setBatchData] = useState([]);
    const [college_name, setCollegeName] = useState("");
    const withoutSuperAdmin = new CCService()
    const navigate = useNavigate();

    const goBack = () => {
        console.log("Back Button is Clicked Now");
        navigate(-1);
    };

    const StyledBatchName = styled(Box)(() => {
        const backgroundColor = Color.chipGreyBg;
        return {
            backgroundColor,
            padding: "1.5rem",
            borderRadius: "8px",
            width: "300px",
            marginRight: "12px",
            marginTop: "20px",
            //   height: theme.spacing(3),
            color: Color.textDarkGrey,
            fontWeight: "16px",
            '&:hover, &:focus': {
                backgroundColor: emphasize(backgroundColor, 0.06),
                color: Color.textDarkGrey,
            },
            '&:active': {
                // boxShadow: theme.shadows[1],
                backgroundColor: emphasize(backgroundColor, 0.12),
                color: Color.textDarkGrey,
            },
        };
    });

    const batchDataGetter = async () => {
        const headers = {
            Authorization: `Bearer ${Cookies.get("token")}`,
        };
        try {
            const res = await withoutSuperAdmin.get("/clgAdmin/Program", { headers })
            console.log("1x1 data RESPONSE:", res.data)
            setCollegeName(res.college_name);
            setBatchData([...res.data]);
            setLoading(false);
            return res.data
        } catch (err) {
            console.log(err)
        }
    }


    const getprocessData = async () => {
        const headers = {
            Authorization: `Bearer ${Cookies.get("token")}`,
        };
        try {
            const res = await withoutSuperAdmin.get("/os/collegeadmin/allProcess", { headers })
            const ex_res = [
                {
                    sku_name: "Expert Connect",
                    process_id: 399,
                    user_group_name: "TT 1",
                    user_group_id: 47,
                    college_name: "test admin rd",
                    college_id: 43,
                    start_date: null,
                    end_date: null
                },
                {
                    sku_name: "SIP Success",
                    process_id: 447,
                    user_group_name: "TT 1",
                    user_group_id: 47,
                    college_name: "test admin rd",
                    college_id: 43,
                    start_date: null,
                    end_date: null
                },
                {
                    sku_name: "Case Connect",
                    process_id: 448,
                    user_group_name: "TT 2",
                    user_group_id: 47,
                    college_name: "test admin rd",
                    college_id: 43,
                    start_date: 1719952065,
                    end_date: null
                }
            ]
            const groupedData = res.data.reduce((acc, item) => {
                if (!acc[item.user_group_name]) {
                    acc[item.user_group_name] = [];
                }
                acc[item.user_group_name].push(item);
                return acc;
            }, {});
            console.log("process RESPONSE:", res)
            setProcessData(groupedData);
            setLoading(false);
            return res.data
        } catch (err) {
            console.log(err)
        }
    }


    const getDaysUntil = (epochTime) => {
        if (epochTime < 10000000000) { // Check if the epoch time is likely in seconds
            epochTime *= 1000;
        }

        const currentDate = new Date();
        const targetDate = new Date(epochTime);

        // Debugging output
        console.log("targetDate", targetDate);
        console.log("currentDate", currentDate);

        const diffInTime = targetDate - currentDate;
        const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

        // Debugging output
        console.log("diffInDays", diffInDays);

        return diffInDays;
    };

    console.log("pro data:", processData)

    useEffect(() => {
        batchDataGetter();
        getprocessData();
    }, [])

    return (
        <div>
            <SideNavigationBar />
            {loading ? (
                <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: "0 auto" }}>
                    <Loader show={loading} />
                </div>
            ) : (<div style={{ marginTop: "2rem", marginLeft: "10rem" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />
                    <Typography style={{ marginBottom: "1rem", marginLeft: ".5rem", marginTop: "1rem", color:Color.primary1 }} sx={{ fontSize: 22 }}>
                        Offline Sessions
                    </Typography>
                </Box>
                <Box>
                    <Box style={{}}>
                        <Typography style={{marginTop:"20px", fontSize:"32px", fontWeight:500, textUnderlineOffset:"4px", textDecoration:"underline", textDecorationColor:Color.primary1, textDecorationThickness:"1px"}}>{college_name}</Typography>
                    </Box>
                    {
                        Object.keys(processData).map((item, index) => {
                            return (
                                <div style={{ marginBottom: "24px" }} key={index}>
                                    <Typography>
                                        {item}
                                        <Divider sx={{ marginTop: "10px", marginBottom: "12px", borderWidth: 1 }} />
                                    </Typography>
                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}>
                                        {processData[item].map((process_item, ind) => (
                                            <StyledBatchName
                                                onClick={() => {
                                                    navigate(`/StudentSummary?process_id=${process_item.process_id}`, { state: { user_group_name: process_item.user_group_name, college_name: process_item.college_name } })
                                                }}
                                                key={ind}>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                    <div >
                                                        <Typography style={{ fontSize: 20 }}>{process_item?.sku_name}</Typography>
                                                        <Typography
                                                            style={{ fontSize: 16, color: Color.primary1 }}>
                                                            {process_item?.start_date ? `${ddmmm(process_item?.start_date)} onwards , ${getDaysUntil(process_item?.start_date)} days` : "Yet to start"}
                                                        </Typography>
                                                    </div>
                                                    <ChevronRightIcon />
                                                </div>
                                            </StyledBatchName>
                                        ))}</div>
                                </div>
                            );
                        })
                    }
                </Box>
            </div>)}
        </div>
    )
}

export default OfflineSessionList