import { ArrowDropDown, Check, Download, DownloadOutlined, KeyboardArrowDown, Notifications, Search, UnfoldMoreSharp, UploadOutlined } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import { Box, Button, Checkbox, CircularProgress, IconButton, MenuItem, Modal, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { motion } from "framer-motion";
import Cookies from "js-cookie";
import Papa from "papaparse";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Color, DivCenter, PrimaryButton, PrimaryButtonOutlined } from "../../../GlobalStyles";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { HandleResumeDownload, get_specialCharReplace } from "../../../services/specialCharReplace";
import ConfirmationAndValidationModal from "../../reusables/ConfirmationAndValidationModal/ConfirmationAndValidationModal";
import { ActivityLoader } from "../../reusables/Loader/Loader";
import SideNavigationBar from "../../reusables/SideNavigationBar/SideNavigationBar";
import { AllApplicantsSkeletonLoader } from "../../reusables/SkeletonLoader/SkeletonLoader";
import ResumeDownloadModal from "../ResumeDowloadToZip/ResumeDownloadModal";
import { SlideInRightModal } from "../Settings/SlidingRightModal";
import "./AllApplicants.css";
import { useDebounce } from "../../../services/debounce";
const NewAllApplicants = () => {
  const [loading, setLoading] = useState({ page_loader: false, activity_loader: false, allApplicants: false, post_button: false, diminish_loader: false, eligible_non_applicants_loader: false });
  const navigate = useNavigate();
  const location = useLocation();
  const service = new CCService();
  const [onHoldRemarks, setOnHoldRemarks] = useState({ show: false, onClose: () => setOnHoldRemarks((prev) => ({ ...prev, show: false })), content: "" });
  const { review, modeOfShortList, shortListDetails, batch_id } = location.state;
  const [bulkUploadModal, setbulkUploadModal] = useState(false);
  const [flags, setFlags] = useState({ downloadResumeDropDown: false });
  const [allApplicants, setAllApplicants] = useState([]);
  const [backup_allApplicants, setBackup_allApplicants] = useState([]);
  const [nonApplicants, setNonApplicants] = useState([]);
  const [formInputs, setFormInputs] = useState("");
  const { accessRights, tooltipText } = useSelector((store) => store.rights)
  const [shortListCandidates, setShortListCandidates] = useState([]);
  const [shortListId, setShortListId] = useState(null);
  const [mode, setMode] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [originalApplicants, setOriginalApplicants] = useState("");
  const [searchString, setSearchString] = useState("");
  const [shortlistDropDownData, setShortlistDropDownData] = useState([]);
  const [resumeDownloadApplications, setResumeDownloadApplications] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [nonApplicantSearch, setNonApplicantSearch] = useState("")
  const debouncedValue = useDebounce(nonApplicantSearch)
  //json data
  const [shortlistDropDown, setShortlistDropDown] = useState([
    {
      id: null,
      shortlist_name: "All Applicants",
      student_status_id: [3, 4, 5, 7],
      shortlist_no: null,
      is_selected: false,
      disable_function: () => { },
    },
  ]);
  const scrollRef = useRef(null)
  const applicantsScrollRef = useRef(null)
  const [nonApplicantsPage, setNonApplicantsPage] = useState(1)
  const [uploadedCSV, setUploadedCSV] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [page, setPage] = useState(1);
  const [changePage, setChangePage] = useState(true)
  const [pastPage, setPastPage] = useState(0);
  const [shortlistPage, setShortlistPage] = useState(1);
  const [responseDataEmpty, setResponseDataEmpty] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [focused, setFocused] = useState(false);
  const fileInputRef = useRef(null);
  const shortlist_status = shortlistDropDown?.find((dd) => dd.is_selected === true)?.shortlist_no + 1;
  const [customizedApplicantsSheet, setCustomizedApplicantsSheet] = useState({
    open: false,
    onClose: () => setCustomizedApplicantsSheet((prev) => ({ ...prev, open: false })),
  });
  const [successConfirmationModal, setSuccessConfirmationModal] = useState({
    open: false,
    onClose: () => setSuccessConfirmationModal((prev) => ({ ...prev, open: false })),
  });
  const [header, setHeader] = useState("");
  const [bulkData, setBulkData] = useState([]);

  console.log("NewAllApplicants ~ shortlistDropDown:", shortlistDropDown);
  // use effect
  useEffect(() => {
    handleEffect();
  }, []);

  const handleDownloadFormat = () => {
    // Convert the data to CSV format
    const csv = Papa.unparse([{ roll_nos: "" }]);

    // Create a blob from the CSV
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a link element and click it to start the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "format.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
  };

  const nonApplicantGetter = async () => {
    try {
      setLoading((prev) => ({ ...prev, eligible_non_applicants_loader: true }));
      const nonApplicantData = await service.get(`po/ca/getApplicants?po_job_id=${review.po_job_id}&student_status_ids=[1]&page=${nonApplicantsPage}&limit=10`);

      if (nonApplicantsPage === 1) {
        setNonApplicants(nonApplicantData.data);

      } else {
        setNonApplicants(prev => [...prev, ...nonApplicantData.data]);
      }
      setNonApplicantsPage(prev => prev + 1)
      setLoading((prev) => ({ ...prev, eligible_non_applicants_loader: false }));
    }
    catch (err) {
      window.alert(err.response.data.message)
      setLoading((prev) => ({ ...prev, eligible_non_applicants_loader: false }));
    }
  };

  const handleEffect = async () => {
    setLoading((prev) => ({ ...prev, page_loader: true }));

    //login check
    if (Cookies.get("token") === null || Cookies.get("token") === undefined) {
      navigate("/");
    }
    //shortlist data getter
    let po_shortListGetter = await poShortListGetter();
    if (selectedTab === 1) {

      await nonApplicantGetter();
    }
    po_shortListGetter?.forEach((element) => {
      element.is_selected = false;
    });

    // making the shortlist dropdown data
    const dropdown_data = makingShortlistDropDownData(po_shortListGetter);
    dropdown_data.forEach((dd, dd_index) => {
      if (dd.id && modeOfShortList === "From Closed For Applicants" && dd.id === shortListDetails.id) {
        dd.is_selected = true;
      } else {
        dd.is_selected = dd_index === dropdown_data.length - 1 ? true : false;
      }
    });

    setShortlistDropDown(dropdown_data);
    setFormInputs("Applicants Only");
    // appllicants data getter
    console.log("CAll check 1")
    // let all_applicants_getter = await allApplicantsGetter(1);
    const reviewData = await service.get(`/po/ca/shortlistNames`);

    setShortlistDropDownData(reviewData.data);

    // if (all_applicants_getter.length) {
    //   all_applicants_getter.forEach((element) => {
    //     element.is_checked = false; //if the particular student is selected or ticked or checked
    //     element.is_disabled = ![4, 5, 7].includes(element.student_status_id) ? false : true;
    //   });
    //   setAllApplicants(all_applicants_getter);
    //   setOriginalApplicants(all_applicants_getter);
    // }
    // setLoading((prev) => ({ ...prev, allApplicants: false }));
    handleChangeShortlist(
      dropdown_data.find((dd) => dd.is_selected === true),
      dropdown_data
    );
    setLoading((prev) => ({ ...prev, page_loader: false }));
  };

  //main data getter
  const allApplicantsGetter = async (page) => {
    try {
      setLoading((prev) => ({ ...prev, allApplicants: true }));
      const res = await service.get(`/po/ca/getApplicants?po_job_id=${review.po_job_id}&student_status_ids=[3,4,7]&page=${page}&limit=10`);

      let all_applicants_getter = res.data;
      if (all_applicants_getter.length) {
        all_applicants_getter.forEach((element) => {
          element.is_checked = false; //if the particular student is selected or ticked or checked
          element.is_disabled = ![4, 5, 7].includes(element.student_status_id) ? false : true;
        });

        setAllApplicants((prevApplicants) => [...prevApplicants, ...all_applicants_getter]);
      } else {
        setResponseDataEmpty(true);
      }
      setLoading((prev) => ({ ...prev, allApplicants: false }));
      return all_applicants_getter;
    } catch (err) {
      console.log(err);
      setLoading((prev) => ({ ...prev, allApplicants: false }));
    }
  };
  console.log("getting applicants data response:12", allApplicants);
  // get of po shortlist
  const poShortListGetter = async () => {
    try {
      const res = await service.get(`/po/ca/shortlist?po_job_id=${review.po_job_id}`);
      console.log("getting opportunity data response:", res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  //remaking
  const makingShortlistDropDownData = (data) => {
    console.log("makingShortlistDropDownData ~ data:", data);
    const temp_shortlistdd = [...shortlistDropDown];
    console.log("makingShortlistDropDownData ~ temp_shortlistdd:", temp_shortlistdd);
    data.forEach((element) => {
      temp_shortlistdd.push({
        id: element.id,
        shortlist_name: element.shortlist_name,
        student_status_id: [4],
        shortlist_no: element.shortlist_no,
        is_selected: false,
        disable_function: () => { },
      });
    });
    return temp_shortlistdd;
  };

  const allApplicantsShortListGetter = async (shortListId, shortlistPage) => {
    console.log("what i'm getting", shortListId, shortlistPage);
    setLoading((prev) => ({ ...prev, allApplicants: true }));
    try {
      const res = await service.get(`/po/ca/getApplicants?po_job_id=${review.po_job_id}&shortlist_id=${shortListId}&page=${shortlistPage}&limit=10`);
      console.log("getting applicants data response:", res.data);
      let temp_change_short_list = res.data;

      if (temp_change_short_list.length) {
        temp_change_short_list?.forEach((element) => {
          element.is_checked = false;
          element.is_disabled = false;
        });
        setAllApplicants((prevApplicants) => [...prevApplicants, ...temp_change_short_list]);
      } else {
        setResponseDataEmpty(true);
      }
      setLoading((prev) => ({ ...prev, allApplicants: false }));
      return temp_change_short_list;
    } catch (err) {
      console.log(err);
      setLoading((prev) => ({ ...prev, allApplicants: false }));
    }
  };

  const handleInProcessApplications = async () => {
    try {
      await service
        .put(`/po/ca/jobStatusUpdate`, {
          po_job_id: review.po_job_id,
          job_status_id: 6,
        })
        .then((res) => {
          console.log("response", res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  // FUNCTIONS
  const handleCheckRows = ({ target }, key, index) => {
    console.log("checked:", target, target.checked);
    let temp_allRequestData = [...allApplicants];
    temp_allRequestData[index].is_checked = target.checked;
    setAllApplicants(temp_allRequestData);
  };

  //dropdown change
  const handleChangeShortlist = async (value, dropdowndata, main_data) => {
    console.log("logging out short list", value);
    setResponseDataEmpty(false);
    try {
      let temp_dd = [...(dropdowndata ?? shortlistDropDown)];

      if (value.shortlist_name === "All Applicants") {
        setPage(1);

        setPastPage(0);
        temp_dd.forEach((dd) => (dd.is_selected = false));
        console.log("handleChangeShortlist ~ temp_dd:", temp_dd);
        temp_dd[0].is_selected = true;
        console.log("CAll check ")

        let all_applicants_getter = await allApplicantsGetter(1);
        console.log("handleEffect ~ all_applicants_getter:", all_applicants_getter);
        setAllApplicants(all_applicants_getter);
        setOriginalApplicants(all_applicants_getter);
        setSearchString("");
      } else if (value.shortlist_name === "Eligible Non-Applicants") {
        temp_dd.forEach((dd) => (dd.is_selected = false));

        setShortlistPage(1);
        setPastPage(0);
        temp_dd.find((dd) => dd.shortlist_no === value.shortlist_no).is_selected = true;
        let temp_change_short_list = await allApplicantsShortListGetter(value.id, 1);

        setAllApplicants(temp_change_short_list);
        setOriginalApplicants(temp_change_short_list);
        setSearchString("");
      } else {
        temp_dd.forEach((dd) => (dd.is_selected = false));

        setShortlistPage(1);
        setPastPage(0);
        temp_dd.find((dd) => dd.shortlist_no === value.shortlist_no).is_selected = true;
        let temp_change_short_list = await allApplicantsShortListGetter(value.id, 1);

        setAllApplicants(temp_change_short_list);
        setOriginalApplicants(temp_change_short_list);
        setSearchString("");
      }

      setShortlistDropDown(temp_dd);
    } catch (error) {
      console.error("Error in handlechangeshortlist:", error);
    }
  };

  const handleModalOpen = (allApplicants, selectedItemId) => {
    console.log("shortlist id", selectedItemId);
    const temp_applicants = allApplicants.filter((checked) => checked.is_checked);
    console.log("temp_applicants checked", temp_applicants, selectedItemId);
    setShortListCandidates(temp_applicants);
    setShortListId(selectedItemId);
    setOnHoldRemarks((prev) => ({ ...prev, show: true }));
  };

  const goBack = () => {
    console.log("Back Button is Clicked Now");
    navigate(-1);
  };

  const searchIconRef = useRef(null);

  // useEffect(() => {
  //   if (searchIconRef.current) {
  //     searchIconRef.current.style.transform = searchString || focused ? "translateX(+255px)" : "translateX(0px)"; // Adjust x value for desired movement
  //   }
  // }, [searchString]);

  const extractNextIndexId = () => {
    if (shortlistDropDown.length > 1) {
      // Find the index of the selected object in shortlistDropDown
      const selectedIndex = shortlistDropDown.findIndex((dd) => dd.is_selected === true);

      // Ensure that the selected object is found
      if (selectedIndex !== -1) {
        // Check if the next object exists in the array
        if (selectedIndex + 1 < shortlistDropDown.length) {
          // Get the id of the next object
          const nextObjectId = shortlistDropDown[selectedIndex + 1].id;
          console.log("nextObjectId", nextObjectId);
          return nextObjectId;
          // Call the handleModalOpen function with the next object's id
        } else {
          // Handle the case where the selected object is the last one in the array
          console.log("The selected object is the last one in the array");
          return null;
        }
      } else {
        // Handle the case where no object with is_selected true is found
        console.log("No object with is_selected true found in shortlistDropDown");
      }
    }
  };

  // bulk upload
  const handleCsvUpload = async (event) => {
    setMode("Bulk Upload");
    console.log("event", event);
    console.log("csv upload at student data");
    let parsedData = {};
    let data;
    if (event && event.target) {
      let file = event.target?.files[0];
      let reader = new FileReader();
      reader.readAsText(file);
      reader.onload = async () => {
        parsedData = Papa.parse(reader.result, { delimiter: ",", dynamicTyping: true }).data;
        let roll_nos = parsedData.filter((item) => item[0] !== "roll_nos").flatMap((row) => (row[0] ? [row[0].toString()] : []));

        const dataToSend = parsedData.slice(1);
        setUploadedCSV(roll_nos);
        let temp_shortList_id = extractNextIndexId();

        console.log("data inside bulk upload", data, parsedData, roll_nos);
        if (roll_nos.length) {
          await bulkUpload(roll_nos);
          setbulkUploadModal(true);
        }
      };
    }
  };

  const bulkUpload = async (uploadedCSV) => {
    if (uploadedCSV !== null) {
      try {
        let temp_shortList_id = extractNextIndexId();
        let data;

        if (temp_shortList_id) {
          data = {
            roll_nos: [...uploadedCSV],

            po_job_id: review.po_job_id,
            shortlist_id: temp_shortList_id,
          };
          // bulkUpload(data, dataToSend);
        } else {
          data = {
            roll_nos: [...uploadedCSV],
            po_job_id: review.po_job_id,
          };

          // bulkUpload(data, dataToSend);
        }

        setLoading((prev) => ({ ...prev, diminish_loader: true }));
        const res = await service.put(`/po/ca/bulkUploadShortlistStudents`, data);

        console.log("Response from backend is", JSON.stringify(res, null, 2));
        setBulkData(res.data.usersData);
        setLoading((prev) => {
          return {
            ...prev,
            diminish_loader: false,
          };
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const postShortList = async () => {
    try {
      const res = await service.post(`/po/ca/shortlist`, {
        po_job_id: review.po_job_id,
        shortlist_no: shortlistDropDown.length > 0 ? shortlistDropDown[shortlistDropDown.length - 1].shortlist_no + 1 : 1,
        shortlist_name: ![null, "", undefined].includes(selectedValue) ? selectedValue : `Shortlist ${shortlistDropDown.length}`,
      });
      if (res.data && res.data.id) {
        setShortListId(res.data.id);
        handleInProcessApplications();
        return res.data.id;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleBulkUploadShortlist = async () => {
    console.log("shortListCandidates", selectedValue, shortListId);

    setLoading((prev) => ({ ...prev, post_button: true }));
    if (shortListId === null) {
      let temp_shortlist = await postShortList();

      setShortListId(temp_shortlist);
      handleInProcessApplications();
      handleBulkUploadElevateStudent(temp_shortlist);
    } else {
      handleBulkUploadElevateStudent(shortListId);
    }
  };

  // search icon animations

  const handleBulkUploadElevateStudent = async (shortListId) => {
    let temp_candidates = bulkData.map((item) => item.user_id);
    console.log("shortListId inside", shortListId);
    try {
      await service
        .put(`/po/ca/elevateStudentStatus`, {
          user_ids: temp_candidates,
          po_job_id: review.po_job_id,
          shortlist_id: shortListId,
          student_status_id: 4,
        })
        .then((res) => {
          console.log("response", res);
          window.location.reload();
        });
    } catch (err) {
      console.error("Error in elevating students:", err);
    }
  };

  function OnHoldRemarksModal({ open, onClose, shortListCandidates, shortListId, dropdown_data, setSelectedValue }) {
    const [isHovered, setIsHovered] = useState(false);
    const handlePostShortlist = async () => {
      console.log("shortListCandidates", selectedValue, shortListId);

      setLoading((prev) => ({ ...prev, post_button: true }));
      if (shortListId === null) {
        let temp_shortlist = await postShortList();

        setShortListId(temp_shortlist);
        handleInProcessApplications();
        handlePutShortlist(temp_shortlist);
      } else {
        handlePutShortlist(shortListId);
      }
    };

    // search icon animations

    const handlePutShortlist = async (shortListId) => {
      let temp_candidates = shortListCandidates.map((item) => item.user_id);
      console.log("shortListId inside", shortListId);
      try {
        await service
          .put(`/po/ca/elevateStudentStatus`, {
            user_ids: temp_candidates,
            po_job_id: review.po_job_id,
            shortlist_id: shortListId,
            student_status_id: 4,
          })
          .then((res) => {
            console.log("response", res);
            window.location.reload();
          });
      } catch (err) {
        console.error("Error in elevating students:", err);
      }
    };

    const handlePutDiminishShortlist = async () => {
      setLoading((prev) => ({ ...prev, diminish_loader: true }));
      let temp_candidates = shortListCandidates.map((item) => item.user_id);
      try {
        await service
          .put(`/po/ca/diminishStudentStatus`, {
            user_ids: temp_candidates,
            po_job_id: review.po_job_id,
            shortlist_id: shortListId ? shortListId : null,
            student_status_id: shortListId ? 4 : 3,
          })
          .then((res) => {
            console.log("response", res);
            if (temp_candidates.length === allApplicants.length) {
              window.location.reload();
            }
            const updatedApplicants = allApplicants?.map((element) => ({ ...element, is_checked: false }));
            setAllApplicants(updatedApplicants);
            setBackup_allApplicants(updatedApplicants);
            setOnHoldRemarks((prev) => ({ ...prev, show: false }));
            handleChangeShortlist(shortlistDropDown.find((dd) => dd.is_selected === true));
          });
      } catch (err) {
        console.log(err);
      }
      setLoading((prev) => ({ ...prev, diminish_loader: false }));
    };
    useEffect(() => {
      if (shortListId !== null) {
        const dd_data = dropdown_data.find((item) => item.id === shortListId);

        if (dd_data) {
          let data = shortlistDropDownData.find((item) => item.name === dd_data.shortlist_name);

          if (data) {
            setSelectedValue(data);
          }
        }
      }
    }, []);
    return (
      <Modal open={open} onClose={() => (loading.post_button ? {} : onClose())}>
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", padding: "2rem", borderRadius: "40px", display: "flex", flexDirection: "column" }}>
          <Typography sx={{ alignItems: "left", justifyContent: "left", textAlign: "left", paddingBottom: "1rem", marginBottom: "1rem", borderBottom: `1px solid ${Color.neutralLightGrey}` }}>{mode === "Remove from Shortlist" ? "Remove these students from current shortlist" : "Shortlist these students for next round?"}</Typography>
          <Typography sx={{ marginBottom: "1rem", width: "400px" }}>Review the students</Typography>
          {shortListCandidates?.map((candidate, index) => {
            return (
              <Box sx={{}}>
                <Typography textAlign="left">
                  {index + 1}. {[null, undefined, "", " "].includes(candidate.full_name) ? "N/A" : candidate.full_name}
                </Typography>
              </Box>
            );
          })}
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginBlock: "1rem" }}>
            <Typography>Shortlisted for</Typography>

            {shortListId === null ? (
              <Select
                value={selectedValue}
                sx={{ height: "30px", width: "300px" }}
                onChange={(e) => {
                  e.stopPropagation();
                  setSelectedValue(e.target.value);
                }}
                disabled={shortListId === null ? false : true}
              >
                {shortlistDropDownData?.map((item) => (
                  <MenuItem key={item} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Box sx={{ padding: "2px 16px", border: `1px solid ${Color.neutralMidGrey}`, display: "flex", gap: "1rem", alignItems: "center", background: Color.neutralLightGrey }}>
                <Typography sx={{ fontSize: "16px" }}>{selectedValue.name}</Typography>
                <ArrowDropDown sx={{ color: Color.neutralDarkGrey }} />
              </Box>
            )}
          </Box>
          <Box style={{ marginTop: "2rem", position: "relative" }}>
            <Box
              sx={{ padding: "16px", border: `1px dashed ${Color.neutralDarkGrey}`, background: "#fff", "&:hover": { boxShadow: "4px 8px 15.9px 6px rgba(0, 0, 0, 0.15)" }, zIndex: "999" }}
              onMouseEnter={(e) => {
                setIsHovered(true);
              }}
              onMouseLeave={(e) => {
                setIsHovered(false);
              }}
            >
              <Box className="PreviousAndSubmit">
                <Box style={{ textAlign: "center", background: Color.neutralMidGrey, padding: "2px 3px", borderRadius: "8px", marginRight: "5px" }}>
                  <Notifications sx={{ color: Color.white, transform: "rotate(30deg)", fontSize: "20px" }} />
                </Box>
                <Typography>Notify students about this shortlist?</Typography>
              </Box>
              <Box className="PreviousAndSubmit">
                <Checkbox disabled />
                <Typography class="alignInCenter">Only above mentioned</Typography>
                <Checkbox disabled />
                <Typography class="alignInCenter">Only Applicants</Typography>
                <Checkbox disabled />
                <Typography class="alignInCenter">Entire Batch</Typography>
              </Box>
            </Box>
            <Box sx={{ zIndex: "1" }}>
              {isHovered && (
                <motion.div style={{ display: "flex", gap: "2px", position: "absolute", background: "rgba(232, 80, 91, 1)", padding: "5px 12px", right: 1, borderRadius: "8px 8px 0px 0px", zIndex: "-10" }} transition={{ duration: 0.5, type: "spring", delay: 0.1 }} initial={{ top: "0px" }} animate={isHovered ? { top: "-30px" } : { top: "0px" }}>
                  <img src="https://ccicons.s3.amazonaws.com/po/eggCracked.svg" style={{ objectFit: "contain", height: "20px", aspectRatio: "1/1" }} />
                  <Typography sx={{ color: Color.white, fontSize: "14px", fontWeight: "600" }}>Cracking Soon!</Typography>
                  <Typography sx={{ color: Color.white, fontSize: "14px" }}>Notification Control Center</Typography>
                </motion.div>
              )}
            </Box>
          </Box>
          <div
            className="PreviousAndSubmit"
            style={{
              justifyContent: "center",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "5%",
              marginTop: "16px",
            }}
          >
            <PrimaryButtonOutlined
              name="previous-button"
              disabled={loading.post_button}
              sx={{
                textTransform: "none",
                borderColor: Color.neutralMidGrey,
                color: Color.neutralMidGrey,
                borderRadius: 2,
                boxShadow: "none",
                width: "50%",
                height: "44px",
                "&:hover": {
                  borderColor: Color.neutralMidGrey,
                  color: Color.neutralMidGrey,
                },
              }}
              className="Previousbutton"
              onClick={onClose}
            >
              Go Back
            </PrimaryButtonOutlined>
            {mode === "Add to Shortlist" ? (
              <>
                <Button
                  name="next-button"
                  className="Rephrasebutton"
                  disabled={loading.post_button}
                  sx={{
                    textTransform: "none",
                    borderColor: Color.neutralMidGrey,
                    color: "#fff",
                    borderRadius: 2,
                    boxShadow: "none",
                    width: "50%",
                    height: "44px",
                    "&:hover": {
                      borderColor: Color.neutralMidGrey,
                      color: "#fff",
                    },
                  }}
                  onClick={() => handlePostShortlist()}
                >
                  {loading.post_button ? <CircularProgress size={24} sx={{ color: Color.white }} /> : `Post Shortlist-${shortlist_status}`}
                  {/* `Post Shortlist-${shortlist_status} */}
                </Button>
              </>
            ) : (
              <>
                <PrimaryButton
                  name="next-button"
                  className="Rephrasebutton"
                  disabled={loading.diminish_loader}
                  sx={{
                    textTransform: "none",
                    borderColor: Color.neutralMidGrey,
                    color: "#fff",
                    borderRadius: 2,
                    boxShadow: "none",
                    width: "50%",
                    height: "44px",
                    "&:hover": {
                      borderColor: Color.neutralMidGrey,
                      color: "#fff",
                    },
                  }}
                  onClick={() => handlePutDiminishShortlist()}
                >
                  {loading.diminish_loader ? <CircularProgress size={24} sx={{ color: Color.white }} /> : `Update Shortlist-${shortlist_status - 1}`}
                </PrimaryButton>
              </>
            )}
          </div>
        </Box>
      </Modal>
    );
  }

  const handleScroll = () => {




    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;



    if (responseDataEmpty === false && windowHeight + scrollTop >= documentHeight && !isFetching) {
      let temp_shortlist_selected = shortlistDropDown.filter((item) => item.is_selected);

      if (temp_shortlist_selected && temp_shortlist_selected[0]?.shortlist_name === "All Applicants") {
        setPage((prev) => prev + 1);
      } else {
        setShortlistPage((prev) => prev + 1);
      }
      setIsFetching(true);
    }
  };




  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   if ((responseDataEmpty === false && isFetching && page !== pastPage) || (responseDataEmpty === false && isFetching && shortlistPage !== pastPage)) {
  //     let temp_shortlist_selected = shortlistDropDown.filter((item) => item.is_selected);
  //     console.log("temp_shortlist_selected", temp_shortlist_selected);
  //     if (temp_shortlist_selected && temp_shortlist_selected[0]?.shortlist_name === "All Applicants") {
  //       allApplicantsGetter(page);
  //       setPastPage(page);
  //     } else {
  //       allApplicantsShortListGetter(temp_shortlist_selected[0]?.id, shortlistPage);
  //       setPastPage(shortlistPage);
  //     }
  //     setIsFetching(false);
  //   }

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [isFetching, responseDataEmpty]);

  const handleShortlistingData = () => {
    setMode("Add to Shortlist");
    if (shortlistDropDown.length > 1) {
      // Find the index of the selected object in shortlistDropDown
      const selectedIndex = shortlistDropDown.findIndex((dd) => dd.is_selected === true);
      if (selectedIndex !== -1) {
        if (selectedIndex + 1 < shortlistDropDown.length) {
          const nextObjectId = shortlistDropDown[selectedIndex + 1].id;
          handleModalOpen(allApplicants, nextObjectId);
        } else {
          console.log("The selected object is the last one in the array");
          handleModalOpen(allApplicants, null);
        }
      } else {
        console.log("No object with is_selected true found in shortlistDropDown");
      }
    } else {
      handleModalOpen(allApplicants, null);
    }
  };

  const handleShortlistingRemoveData = () => {
    if (shortlistDropDown.length > 1) {
      const selectedIndex = shortlistDropDown.findIndex((dd) => dd.is_selected === true);
      console.log({ selectedIndex });
      if (selectedIndex !== -1) {
        if (selectedIndex - 1 < shortlistDropDown.length) {
          const previousObjectId = shortlistDropDown[selectedIndex - 1].id;
          console.log("previous id", previousObjectId);
          handleModalOpen(allApplicants, previousObjectId);
        }
      }
    } else {
      handleModalOpen(allApplicants, null);
    }
    setMode("Remove from Shortlist");
  };

  const handleSheetDownload = async () => {
    setCustomizedApplicantsSheet((prev) => ({ ...prev, open: true }));
  };

  const handleSearch = async (searchString) => {
    if (searchString === "") {
      setAllApplicants(originalApplicants);
    }
    setSearchString(searchString);
    console.log({ searchString });
  };

  const handleEnterSearch = (e) => {
    if (e.key === "Enter") {
      requestSearch(e.target.value);
    }
  };

  const requestSearch = async (searchString) => {
    try {
      let res;
      let temp_shortlist_selected = shortlistDropDown.filter((item) => item.is_selected);
      console.log("temp_shortlist_selected", temp_shortlist_selected);
      if (temp_shortlist_selected.length > 0 && temp_shortlist_selected[0].shortlist_name === "All Applicants") {
        res = await service.get(`/po/ca/getApplicants?po_job_id=${review.po_job_id}&searchString=${searchString}`);
        const updatedData = res.data.map((element) => ({
          ...element,
          is_checked: false,
          is_disabled: ![4, 5, 7].includes(element.student_status_id) ? false : true,
        }));
        const filtered = allApplicants.filter((applicant) => updatedData.some((result) => result.user_id === applicant.user_id));
        setAllApplicants(filtered.length > 0 ? updatedData : allApplicants);
      } else {
        res = await service.get(`/po/ca/getApplicants?po_job_id=${review.po_job_id}&shortlist_id=${temp_shortlist_selected[0]?.id}&searchString=${searchString}`);
        const updatedData = res.data.map((element) => ({
          ...element,
          is_checked: false,
          is_disabled: false,
        }));
        const filtered = allApplicants.filter((applicant) => updatedData.some((result) => result.user_id === applicant.user_id));
        setAllApplicants(filtered.length > 0 ? updatedData : allApplicants);
      }
      console.log("StudentResumeDataGet", res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseApplicationsModalOpen = () => {
    setResumeDownloadApplications(true);
  };

  const handleCloseApplicationsModalClose = () => {
    setResumeDownloadApplications(false);
  };

  const handleCloseApplications = async (e) => {
    try {
      await service.get(`/po/ca/resumesToZip?po_job_id=${review?.po_job_id}`).then((res) => {
        console.log("response", res);
        HandleResumeDownload(e, res.url);
        handleCloseApplicationsModalClose();
      });

      // navigate("/JobsPostingPage", { state: { batch_id: batch_id } })
    } catch (err) {
      console.log(err);
      if (err.response?.data?.success) {
        const headerContent = err.response.data.message;
        setHeader(<div>{headerContent}</div>);
        handleCloseApplicationsModalClose();
        setSuccessConfirmationModal((prev) => ({ ...prev, open: true }));
        console.log("successful");
      } else {
        const headerContent = err.response?.data?.message;
        setHeader(<div>{headerContent}</div>);
        handleCloseApplicationsModalClose();
        console.log("Failed");
        setSuccessConfirmationModal((prev) => ({ ...prev, open: true }));
      }
    }
  };
  const handleNonApplicantSearch = async () => {
    const data = await service.get(`/po/ca/getApplicants?po_job_id=${review.po_job_id}&searchString=${nonApplicantSearch}&student_status_ids=[1]`)
    console.log("ASKJDhuhf", data)
    setNonApplicants(data.data)
  }

  useEffect(() => {
    const timeOut = setTimeout(() => {
      // setPage((prev) => prev + 1);
      console.log("fdjskflkdsjflksd", page)
      if (selectedTab === 0 && !responseDataEmpty && allApplicants.length > 0) {

        let temp_page = page + 1
        setPage(temp_page)
        allApplicantsGetter(temp_page);
      } else if (selectedTab === 1) {

        nonApplicantGetter()
      }
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [changePage, selectedTab])

  useEffect(() => {
    if (nonApplicantSearch !== "") {
      handleNonApplicantSearch()
    }
  }, [debouncedValue])

  useEffect(() => {

    const div = selectedTab === 0 ? applicantsScrollRef.current : scrollRef.current;
    const handleScroll = () => {
      console.log("fdskjlfjlsdjflsdf", div.scrollTop + div.clientHeight >= div.scrollHeight - 1)
      if (div.scrollTop + div.clientHeight >= div.scrollHeight - 1) {
        runFunctionAtBottom();
      }
    };

    const runFunctionAtBottom = () => {
      setChangePage((prev) => !prev);
    };

    if (div) {
      div.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
      }
    };
  }, [nonApplicants, selectedTab, allApplicants]);

  return (
    <Box className="main_box" sx={{ userSelect: "none", background: "#F5F5F5" }}>
      <SideNavigationBar />
      <ActivityLoader show={loading.activity_loader || loading.page_loader} />
      <Box className="box__fillerBox" sx={{ width: "12%" }}></Box>
      <Box className="box__mainContent" sx={{ width: "80%", height: "100%", display: "flex", flexDirection: "column", gap: "20px", alignItems: "flex-start", paddingBottom: "1rem" }}>
        <Box sx={{ mt: "10px", mb: "10px", display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
          <Box sx={{ display: "flex" }}>
            <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem", marginTop: ".5rem" }} onClick={() => goBack()} />
            <Typography sx={{ fontSize: "32px", color: "black" }}>{`Applications of ${get_specialCharReplace(review.designation)} - ${get_specialCharReplace(review.company_name)}`}</Typography>
          </Box>
        </Box>
        <Box sx={{ width: "100%", background: "#fff", padding: "1rem", borderRadius: "12px", flex: 1, boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.08)" }}>
          <Box sx={{ borderBottom: "1px solid red", borderColor: "divider", marginBottom: "1rem" }}>
            <Tabs
              value={selectedTab}
              onChange={(e, value) => {
                setSelectedTab(value);

              }}
              textColor={Color.primary1}
              TabIndicatorProps={{
                style: {
                  backgroundColor: Color.primary1, // Replace 'your-indicator-color' with your desired color
                },
              }}
              sx={{ minHeight: "40px", height: "40px" }}
            >
              <Tab value={0} label="Applicants" sx={{ textTransform: "none", padding: "0px 8px", color: selectedTab !== 0 ? Color.neutralMidGrey : Color.primary1 }}></Tab>
              <Tab value={1} label="Eligible-Non Applicants" sx={{ textTransform: "none", padding: "0px 8px", color: selectedTab !== 1 ? Color.neutralMidGrey : Color.primary1 }}></Tab>
            </Tabs>
          </Box>

          {selectedTab === 0 ? (
            <Box>
              <Box id="box__filtersRow" sx={{ ...DivCenter, alignItems: "flex-start", justifyContent: "space-between", width: "100%", gap: "1.5rem", marginBottom: "8px" }}>
                {shortlistDropDown.find((data) => data.is_selected === true) ? (
                  <FormControl fullWidth sx={{ width: "30%", height: "38px" }}>
                    {/* <InputLabel id="demo-simple-select-label">All Student Lists</InputLabel> */}

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // label="Applicants Only"
                      onChange={(event) => handleChangeShortlist(event.target.value)}
                      value={shortlistDropDown.find((data) => data.is_selected === true)}
                      sx={{ height: "38px" }}
                    >
                      {shortlistDropDown.map((shortListItem, index) => (
                        <MenuItem key={index} value={shortListItem}>
                          {shortListItem.shortlist_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <></>
                )}
                <Box sx={{ flex: 1.5, height: "38px" }}>
                  <TextField
                    name="SearchApplicants"
                    placeholder="Type a phrase or keyword"
                    autoComplete="off"
                    value={searchString}
                    onChange={(e) => handleSearch(e.target.value)}
                    sx={{
                      width: "100%",
                      height: "100%",
                      fontSize: "20px",
                      background: "transparent",
                      borderRadius: "8px",
                    }}
                    onKeyDown={handleEnterSearch}
                    onFocus={() => setFocused(true)}
                    InputProps={{
                      startAdornment: (
                        <IconButton onClick={() => requestSearch(searchString)}>
                          <Search
                            sx={{
                              marginRight: "10px",
                            }}
                            ref={searchIconRef} // Reference to access the icon element
                          />
                        </IconButton>
                      ),
                      sx: { borderRadius: "30px", height: "38px" },
                    }}
                  />
                </Box>

                <Box>
                  <Box sx={{ ...DivCenter, height: "38px", border: `1px solid ${Color.primary1}`, paddingLeft: "8px" }}>
                    <Box onClick={() => handleSheetDownload()} sx={{ ...DivCenter, cursor: "pointer", height: "100%", borderRight: `1px solid ${Color.primary1}`, paddingRight: "8px" }}>
                      <Typography>Download Applicants Sheet</Typography>
                    </Box>
                    <Button onClick={() => setFlags((prev) => ({ ...prev, downloadResumeDropDown: !prev.downloadResumeDropDown }))} sx={{ height: "100%", padding: "0px" }}>
                      <KeyboardArrowDown sx={{ color: Color.primary1, padding: 0, margin: 0 }} />
                    </Button>
                  </Box>
                  {review?.status_id >= 5 && flags.downloadResumeDropDown && (
                    <Box
                      onClick={handleCloseApplicationsModalOpen}
                      sx={{
                        ...DivCenter,
                        cursor: "pointer",
                        background: "rgba(56, 193, 223, 0.20)",
                        border: `1px solid ${Color.primary1}`,
                        width: "100%",
                        height: "35px",
                      }}
                    >
                      Download applicants' resumes
                    </Box>
                  )}
                </Box>
              </Box>
              {allApplicants?.some((pair) => pair?.is_checked) ? (
                <Box sx={{ ...DivCenter, justifyContent: "flex-end", width: "100%" }}>
                  <Box sx={{ cursor: "pointer", display: "flex", flexDirection: "row" }}>
                    <Typography style={{ color: Color.neutralMidGrey, marginRight: "1rem", marginTop: ".5rem" }}>Action for selected students:</Typography>
                    {shortlistDropDown.filter((dd) => dd.shortlist_name !== "All Applicants").some((item) => item.is_selected) ? (
                      <Tooltip title={accessRights.find((item) => item.access_right_id === 14)?.is_activated === 1 ? '' : tooltipText}>
                        <Box sx={{ marginRight: "16px" }}>
                          <Button
                            className="submitBtn"
                            type="button"
                            sx={{ borderRadius: "5px", width: "fit-content", height: "40px", padding: "5px 8px", textTransform: "none", background: "#fff", color: Color.red, marginTop: 0, marginBottom: 0, "&:hover": { background: "#fff", color: Color.red }, border: `1px solid ${Color.red}`, "&:disabled": { background: Color.neutralMidGrey, color: "#000" } }}
                            onClick={() => {
                              handleShortlistingRemoveData();
                            }}
                            disabled={accessRights.find((item) => item.access_right_id === 14)?.is_activated === 1 ? false : true}
                          >
                            Remove from {shortlistDropDown.find((data) => data.is_selected === true).shortlist_name}
                          </Button>
                        </Box>
                      </Tooltip>
                    ) : (
                      <></>
                    )}

                    <Tooltip title={accessRights.find((item) => item.access_right_id === 14)?.is_activated === 1 ? '' : tooltipText}>
                      <Box>
                        <Button
                          sx={{ borderRadius: "5px", width: "fit-content", height: "40px", padding: "5px 8px", textTransform: "none", background: Color.green, color: "#fff", marginTop: 0, marginBottom: 0, "&:hover": { background: Color.green, color: "#fff" }, "&:disabled": { background: Color.neutralMidGrey, color: "#000" } }}

                          onClick={() => {
                            handleShortlistingData();
                          }}
                          disabled={accessRights.find((item) => item.access_right_id === 14)?.is_activated === 1 ? false : true}
                        >
                          Post to Shortlist-{shortlist_status}
                        </Button>
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ ...DivCenter, justifyContent: "flex-end", width: "100%", mb: "25px" }}>
                  {["Closed Applications", "In process"].includes(review.status_name) && (
                    <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                      <Box>
                        <Box sx={{ color: Color.primary1, display: "flex", gap: "8px" }} onClick={() => handleDownloadFormat()}>
                          <DownloadOutlined />
                          <Typography>Download Format</Typography>
                        </Box>
                      </Box>
                      <Tooltip title={accessRights.find((item) => item.access_right_id === 14)?.is_activated === 1 ? '' : tooltipText}>
                        <Box>
                          <label htmlFor="file-input" style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                            <input id="file-input" type="file" style={{ display: "none" }} onChange={(e) => handleCsvUpload(e)} ref={fileInputRef} onClick={(e) => (e.target.value = "")} disabled={accessRights.find((item) => item.access_right_id === 14)?.is_activated === 1 ? false : true} />
                            <IconButton >
                              <UploadOutlined style={{ color: accessRights.find((item) => item.access_right_id === 14)?.is_activated === 1 ? Color.primary1 : Color.neutralMidGrey }} />
                            </IconButton>
                            <Typography style={{ alignItems: "center", justifyContent: "center", margin: "auto 0", color: accessRights.find((item) => item.access_right_id === 14)?.is_activated === 1 ? Color.primary1 : Color.neutralMidGrey }}>Bulk Upload Shortlist-{shortlist_status}</Typography>
                          </label>
                        </Box>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              )}
              {allApplicants?.length === 0 || allApplicants === undefined ? (
                <>
                  <div className="noUpcomingBookings" style={{ width: "80%", justifyContent: "center", alignItems: "center", margin: "0 auto", marginTop: "1rem" }}>
                    <div className="noUpcomingBookingsIcon">
                      <PersonOffOutlinedIcon
                        sx={{
                          color: "#1C8EA8",
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    </div>
                    <div className="noUpcomingBookingsTexts">
                      <p className="noUpcomingBookingsText">No Students Shortlisted yet</p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Box sx={{ width: "100%" }}>
                    <Box className="box__header" sx={{ gap: "1px", marginBottom: "1rem", paddingInline: "5px" }}>
                      <Box sx={{ width: "25%", paddingLeft: "8px" }}>
                        <Typography>
                          Student <UnfoldMoreSharp />
                        </Typography>
                      </Box>
                      <Typography style={{ width: "30%", paddingLeft: "20px" }}>
                        Contact <UnfoldMoreSharp />
                      </Typography>
                      <Typography style={{ width: "22.5%", paddingLeft: "20px" }}>
                        Opportunity Status <UnfoldMoreSharp />
                      </Typography>

                      <Typography style={{ width: "22.5%", paddingLeft: "20px" }}>
                        Resume <UnfoldMoreSharp />
                      </Typography>
                    </Box>

                    <Box className="box__postings" sx={{ pb: "24px" }}>
                      {loading.allApplicants && (page === 1 || shortlistPage === 1) ? (
                        <Box sx={{ flexDirection: "column", width: "100%" }}>
                          <AllApplicantsSkeletonLoader />
                          <AllApplicantsSkeletonLoader />
                          <AllApplicantsSkeletonLoader />
                          <AllApplicantsSkeletonLoader />
                          <AllApplicantsSkeletonLoader />
                        </Box>
                      ) : (
                        <Box sx={{ height: "52vh", overflowY: "auto", padding: "5px", borderRadius: "8px" }} ref={applicantsScrollRef}>
                          {allApplicants?.map((applicantsList, index) => (
                            <Box
                              key={index}
                              disabled={applicantsList.is_disabled || [2, 3, 4].includes(review.status_id) || [7, 9, 10].includes(applicantsList.po_student_status_id)}
                              sx={{
                                ...DivCenter,
                                width: "100%",
                                display: "flex",
                                paddingTop: "1rem",
                                paddingBottom: "1rem",
                                borderBlock: `1px solid ${Color.neutralLightGrey}`,
                                background: applicantsList.is_disabled || [2, 3, 4].includes(review.status_id) || [7, 9, 10].includes(applicantsList.po_student_status_id) ? Color.neutralLightGrey : "transparent",
                              }}
                            >
                              {/* <Typography style={{ ...DivCenter, flex: 1 }}>
                            <Box sx={{ ...DivCenter, flexDirection: "column", alignItems: "center", width: "80%" }}>
                              {[null, undefined, "", " "].includes(applicantsList.full_name) ? "N/A" : applicantsList.full_name}
                              <br />
                              {applicantsList?.roll_number}
                            </Box>
                          </Typography> */}
                              <Box sx={{ display: "flex", alignItems: "center", width: "25%" }}>
                                {review.status_id !== 7 ? (
                                  <Box sx={{ ...DivCenter, width: "20%" }}>
                                    <Checkbox disabled={applicantsList.is_disabled || [2, 3, 4].includes(review.status_id) || [7, 9, 10].includes(applicantsList.po_student_status_id)} checked={applicantsList?.is_checked} onChange={(e) => handleCheckRows(e, applicantsList, index)} />
                                  </Box>
                                ) : (
                                  <></>
                                )}

                                <Box sx={{ paddingLeft: "8px" }}>
                                  <Typography align="left"> {[null, undefined, "", " "].includes(applicantsList.full_name) ? "N/A" : applicantsList.full_name}</Typography>

                                  <Typography align="left"> {applicantsList?.roll_number}</Typography>
                                </Box>
                              </Box>

                              <Box sx={{ width: "30%", justifyContent: "center", paddingLeft: "20px" }}>
                                <Typography sx={{}} align="left" noWrap>{applicantsList.email_id}</Typography>
                                <Typography sx={{}} align="left">{applicantsList.ph_no}</Typography>
                              </Box>
                              <Box sx={{ width: "22.5%", display: "flex", gap: "8px", paddingLeft: "20px" }}>
                                <Typography style={{}} align="left">{applicantsList?.shortlist_name ? applicantsList?.shortlist_name : applicantsList?.student_status_name}</Typography>
                                {applicantsList?.student_status_id === 3 && (
                                  <Box sx={{ borderRadius: "50px", background: Color.green, height: "20px", width: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Check sx={{ color: Color.white, padding: "0px", fontSize: "12px" }} />
                                  </Box>
                                )}
                              </Box>

                              <Box sx={{ width: "22.5%", paddingLeft: "20px" }}>
                                {applicantsList?.resume_url === null ? "N/A" : <Button
                                  sx={{
                                    textAlign: "left",
                                    textTransform: "none",
                                    padding: 0,
                                    color: Color.primary1,
                                    borderRadius: "22px",
                                    "&:hover": {
                                      color: Color.primary1,
                                    },
                                  }}
                                  onClick={() => {
                                    window.open(applicantsList?.resume_url);
                                  }}
                                >
                                  View Resume
                                </Button>}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                    {loading.allApplicants && (
                      <Box sx={{ ...DivCenter, flexDirection: "column", width: "100%" }}>
                        <AllApplicantsSkeletonLoader />
                        <AllApplicantsSkeletonLoader />
                        <AllApplicantsSkeletonLoader />
                        <AllApplicantsSkeletonLoader />
                        <AllApplicantsSkeletonLoader />
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
          ) : (
            <Box>
              <Box sx={{ display: "flex", gap: "1rem", alignItems: "center", marginBlock: "1rem" }}>
                <TextField fullWidth sx={{ flex: 1, borderRadius: "8px" }} InputProps={{ style: { height: "40px" }, startAdornment: <Search sx={{ marginRight: "8px" }} /> }} placeholder="Type a name or contact info" onChange={(e) => {
                  setNonApplicantSearch(e.target.value)
                  setNonApplicantsPage(1)
                  if (e.target.value === '') {
                    nonApplicantGetter()
                  }
                }} value={nonApplicantSearch} />
                <Button sx={{ border: `1px solid ${Color.primary1}`, padding: "5px 8px", textTransform: "none", color: Color.primary1, "&:hover": { color: Color.primary1 } }} onClick={() => handleSheetDownload()}>
                  <Download sx={{ color: Color.primary1 }} />
                  CSV of Eligible Non-Applicants
                </Button>
              </Box>

              <Box>
                {loading.eligible_non_applicants_loader ? <AllApplicantsSkeletonLoader /> : nonApplicants.length > 0 ? (
                  <Box sx={{ width: "100%", height: "52vh", paddingLeft: "8px" }}>
                    <Box className="box__header" sx={{ gap: "1px", marginBottom: "1rem", paddingRight: "12px" }}>
                      <Typography style={{ width: "25%" }}>Student</Typography>
                      <Typography style={{ width: "30%", paddingLeft: "20px" }}>Contact</Typography>
                      <Typography style={{ width: "22.5%", paddingLeft: "20px" }}>Opportunity Status</Typography>
                      <Typography style={{ width: "22.5%", paddingLeft: "20px" }}>Resume</Typography>
                    </Box>

                    <Box ref={scrollRef} sx={{ height: "100%", overflowY: "auto", paddingRight: "12px" }}>
                      {nonApplicants.map((item) => (
                        <Box sx={{ borderBlock: "1px solid #E0E0E0" }}>
                          <Box className="box__header" sx={{ gap: "1px", paddingBlock: "8px" }}>
                            <Typography style={{ width: "25%" }}>
                              {item.full_name}
                              <br />
                              {item.roll_no}
                            </Typography>
                            <Box sx={{ width: "30%", justifyContent: "center", paddingLeft: "20px" }}>
                              <Typography sx={{}} noWrap>{item.email_id}</Typography>
                              <Typography sx={{}}>{item.ph_no}</Typography>
                            </Box>

                            <Typography style={{ width: "22.5%", paddingLeft: "20px" }}>Eligible - Not Applied</Typography>
                            <Box sx={{ width: "22.5%", paddingLeft: "20px" }}>
                              {item?.resume_url === null ? "N/A" : <Button
                                sx={{
                                  textTransform: "none",
                                  padding: 0,
                                  color: Color.primary1,
                                  borderRadius: "22px",
                                  "&:hover": {
                                    color: Color.primary1,
                                  },
                                }}
                                onClick={() => {
                                  window.open(item?.resume_url);
                                }}
                              >
                                View Resume
                              </Button>}
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ) : (
                  <>
                    <div className="noUpcomingBookings" style={{ width: "80%", justifyContent: "center", alignItems: "center", margin: "0 auto", marginTop: "1rem" }}>
                      <div className="noUpcomingBookingsIcon">
                        <PersonOffOutlinedIcon
                          sx={{
                            color: "#1C8EA8",
                            width: "50px",
                            height: "50px",
                          }}
                        />
                      </div>
                      <div className="noUpcomingBookingsTexts">
                        <p className="noUpcomingBookingsText">No Students Shortlisted yet</p>
                      </div>
                    </div>
                  </>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box className="box__fillerBox" sx={{ width: "8%" }}></Box>
      <OnHoldRemarksModal open={onHoldRemarks.show} onClose={onHoldRemarks.onClose} shortListCandidates={shortListCandidates} shortListId={shortListId} dropdown_data={shortlistDropDown} setSelectedValue={setSelectedValue} />
      <SlideInRightModal open={customizedApplicantsSheet.open} onClose={customizedApplicantsSheet.onClose} batch_id={batch_id} job_id={review?.po_job_id} tab={selectedTab} />
      <ConfirmationAndValidationModal open={successConfirmationModal.open} onClose={successConfirmationModal.onClose} header={header} primaryButton={"Ok"} handleConfirmation={successConfirmationModal.onClose} />

      <Modal open={bulkUploadModal} onClose={() => setbulkUploadModal(false)}>
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", padding: "2rem", borderRadius: "40px", display: "flex", flexDirection: "column", width: "40vw" }}>
          <Typography sx={{ alignItems: "left", justifyContent: "left", textAlign: "left", paddingBottom: "1rem", marginBottom: "1rem", borderBottom: `1px solid ${Color.neutralLightGrey}` }}>{mode === "Remove from Shortlist" ? "Remove these students from current shortlist" : "Shortlist these students for next round?"}</Typography>
          <Typography sx={{ marginBottom: "1rem", width: "400px" }}>Review the students</Typography>
          {/* <label htmlFor="file-input" style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
            <input id="file-input" type="file" style={{ display: "none" }} onChange={(e) => handleCsvUpload(e)} ref={fileInputRef} />
            <IconButton>
              <UploadOutlined style={{ color: Color.primary1 }} />
            </IconButton>
            <Typography style={{ alignItems: "center", justifyContent: "center", margin: "auto 0", color: Color.primary1 }}>Bulk Upload Shortlist-{shortlist_status}</Typography>
          </label> */}


          <Box sx={{ overflowY: "auto", maxHeight: "20vh" }}>
            {bulkData.map((item, index) => (
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography textAlign="left" sx={{ flex: 1 }}>
                  {index + 1}. {[null, undefined, "", " "].includes(item.name) ? "N/A" : item.name}
                </Typography>
                <Typography textAlign="left" sx={{ flex: 1 }}>
                  {[null, undefined, "", " "].includes(item.roll_no) ? "N/A" : item.roll_no}
                </Typography>
                <Typography textAlign="center" sx={{ flex: 2, color: item.status === 200 ? Color.green : Color.red }}>
                  {item.message}
                </Typography>
              </Box>
            ))}
          </Box>

          {bulkData.every((item) => item.status === 200) ? (
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginBlock: "1rem" }}>
                <Typography>Shortlisted for</Typography>

                {shortListId === null ? (
                  <Select
                    value={selectedValue}
                    sx={{ height: "30px", width: "300px" }}
                    onChange={(e) => {
                      e.stopPropagation();
                      setSelectedValue(e.target.value);
                    }}
                    disabled={shortListId === null ? false : true}
                  >
                    {shortlistDropDownData?.map((item) => (
                      <MenuItem key={item} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Box sx={{ padding: "2px 16px", border: `1px solid ${Color.neutralMidGrey}`, display: "flex", gap: "1rem", alignItems: "center", background: Color.neutralLightGrey }}>
                    <Typography sx={{ fontSize: "16px" }}>{selectedValue.name}</Typography>
                    <ArrowDropDown sx={{ color: Color.neutralDarkGrey }} />
                  </Box>
                )}
              </Box>
              <Box style={{ marginTop: "2rem", position: "relative" }}>
                <Box
                  sx={{ padding: "16px", border: `1px dashed ${Color.neutralDarkGrey}`, background: "#fff", "&:hover": { boxShadow: "4px 8px 15.9px 6px rgba(0, 0, 0, 0.15)" }, zIndex: "999" }}
                  onMouseEnter={(e) => {
                    setIsHovered(true);
                  }}
                  onMouseLeave={(e) => {
                    setIsHovered(false);
                  }}
                >
                  <Box className="PreviousAndSubmit">
                    <Box style={{ textAlign: "center", background: Color.neutralMidGrey, padding: "2px 3px", borderRadius: "8px", marginRight: "5px" }}>
                      <Notifications sx={{ color: Color.white, transform: "rotate(30deg)", fontSize: "20px" }} />
                    </Box>
                    <Typography>Notify students about this shortlist?</Typography>
                  </Box>
                  <Box className="PreviousAndSubmit">
                    <Checkbox />
                    <Typography class="alignInCenter">Only above mentioned</Typography>
                    <Checkbox />
                    <Typography class="alignInCenter">Only Applicants</Typography>
                    <Checkbox />
                    <Typography class="alignInCenter">Entire Batch</Typography>
                  </Box>
                </Box>

                <Box sx={{ zIndex: "1" }}>
                  {isHovered && (
                    <motion.div style={{ display: "flex", gap: "2px", position: "absolute", background: "rgba(232, 80, 91, 1)", padding: "5px 12px", right: 1, borderRadius: "8px 8px 0px 0px", zIndex: "-10" }} transition={{ duration: 0.5, type: "spring", delay: 0.1 }} initial={{ top: "0px" }} animate={isHovered ? { top: "-30px" } : { top: "0px" }}>
                      <img src="https://ccicons.s3.amazonaws.com/po/eggCracked.svg" style={{ objectFit: "contain", height: "20px", aspectRatio: "1/1" }} />
                      <Typography sx={{ color: Color.white, fontSize: "14px", fontWeight: "600" }}>Cracking Soon!</Typography>
                      <Typography sx={{ color: Color.white, fontSize: "14px" }}>Notification Control Center</Typography>
                    </motion.div>
                  )}
                </Box>
              </Box>
              <div
                className="PreviousAndSubmit"
                style={{
                  justifyContent: "center",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "5%",
                  marginTop: "16px",
                }}
              >
                <PrimaryButtonOutlined
                  name="previous-button"
                  disabled={loading.post_button}
                  sx={{
                    textTransform: "none",
                    borderColor: Color.neutralMidGrey,
                    color: Color.neutralMidGrey,
                    borderRadius: 2,
                    boxShadow: "none",
                    width: "50%",
                    height: "44px",
                    "&:hover": {
                      borderColor: Color.neutralMidGrey,
                      color: Color.neutralMidGrey,
                    },
                  }}
                  className="Previousbutton"
                  onClick={() => setbulkUploadModal(false)}
                >
                  Go Back
                </PrimaryButtonOutlined>
                <PrimaryButton
                  name="next-button"
                  className="Rephrasebutton"
                  disabled={loading.diminish_loader}
                  sx={{
                    textTransform: "none",
                    borderColor: Color.neutralMidGrey,
                    color: "#fff",
                    borderRadius: 2,
                    boxShadow: "none",
                    width: "50%",
                    height: "44px",
                    "&:hover": {
                      borderColor: Color.neutralMidGrey,
                      color: "#fff",
                    },
                  }}
                  onClick={() => handleBulkUploadShortlist()}
                >
                  {loading.diminish_loader ? <CircularProgress size={24} sx={{ color: Color.white }} /> : `Update Shortlist-${shortlist_status - 1}`}
                </PrimaryButton>
              </div>
            </Box>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography sx={{ fontSize: "16px", textAlign: "center", marginBlock: "1rem" }}>The upload failed for some students - review above-mentioned issues in your file and try again.</Typography>
              <Button
                sx={{
                  backgroundColor: Color.primary1,
                  color: Color.white,
                  padding: "8px 12px",
                  alignSelf: "center",
                  borderRadius: "8px",

                  "&:hover": {
                    backgroundColor: Color.primary1,
                    color: Color.white,
                  },
                }}
                onClick={() => {
                  setBulkData([]);

                  setbulkUploadModal(false);
                }}
              >
                Ok
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
      <ResumeDownloadModal modalOpen={resumeDownloadApplications} handleModalClose={handleCloseApplicationsModalClose} job_id={review?.po_job_id} handleCloseApplications={handleCloseApplications} />
    </Box >
  );
};

export default NewAllApplicants;
