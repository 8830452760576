import { Box, Modal, Typography } from '@mui/material';
import React from 'react';
import { Color } from '../../../GlobalStyles';

const ResumeDownloadModal = (props) => {
    const { postingOpportunityDetails } = props
    console.log("postingOpportunityDetails in modal", postingOpportunityDetails);
    return (
        <div>
            <Modal open={props.modalOpen} onClose={props.handleModalClose}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'white', padding: '2rem', borderRadius: "40px", display: 'flex', flexDirection: "column", }}>
                    <Typography sx={{ alignItems: "left", justifyContent: "left", textAlign: "left", paddingBottom: "1rem", marginBottom: "1rem", borderBottom: `1px solid ${Color.neutralDarkGrey}`, fontSize: "20px" }}>Download Resumes</Typography>
                    <Typography sx={{ marginBottom: "1rem", width: "550px" }}>Generate link to download resume of all applicants</Typography>

                    <Box className='PreviousAndSubmit'>
                        <Typography class='alignInCenter'> <span style={{ color: Color.red }}>Note:</span> Rendering high quality resume files require heavy processing which can take upto 5 minutes to complete. Please click on generate and come back after 5 minutes to download the resumes ZIP file</Typography>
                    </Box>
                    <div
                        className="PreviousAndSubmit"
                        style={{
                            justifyContent: "space-between",
                            width: "100%",
                            display: "flex",
                            flexDirection: props.Mobile ? "column" : "row",
                        }}
                    >
                        <button
                            name="previous-button"
                            style={{ margin: "auto", marginTop: "1rem", background: "transparent", height: '40px' }}
                            className="Previousbutton"
                            onClick={() => props.handleModalClose()}
                        >
                            Go Back
                        </button>
                        <button
                            name="next-button"
                            className="Rephrasebutton"
                            style={{
                                width: "auto",
                                margin: "auto",
                                marginTop: "1rem",
                                border: "none", height: '40px'
                            }}
                            onClick={() => props.handleCloseApplications()}
                        >
                            Generate Download Link
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default ResumeDownloadModal
