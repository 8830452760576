import { DescriptionOutlined, Done, RadioButtonUnchecked } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Tooltip, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Color } from "../../../GlobalStyles";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import Loader from "../../reusables/Loader/Loader";
import { allmonths } from "../../../services/specialCharReplace";

function Verification() {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(null);
  const location = useLocation();
  console.log("location:", location);
  const searchParams = new URLSearchParams(location.search);
  const resumeId = searchParams.get("resume_id");
  const userId = searchParams.get("user_id");
  const service = new CCService();
  const [resume, setResume] = useState();
  const [loading, setLoading] = useState(true);
  const { supported_doc_required } = location.state;

  //style
  const divCenter = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const resumeRender = async (resume_id, user_id) => {
    try {
      const res = await service.get(`/user/resume/render/admin?resume_id=${resume_id}&user_id=${user_id}`);
      console.log("resumeRender", res);
      // setCardDetails([...res.data])
      return res;
    } catch (err) {
      console.log(err);
    }
  };
  //ADD LOADER
  const verify = async (section_type_id, bullet_ids, isVerified) => {
    console.log("section_type_id, bullet_ids, isVerified:", section_type_id, bullet_ids, isVerified);
    try {
      const res = await service.put(`/user/resumeVerification`, {
        section_type_id,
        bullet_ids,
        verify: isVerified,
      });
      console.log("verify", res);
      handleEffect();
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  const extract_filetype = (docUrl) => {
    // Extracting the filename from the URL
    const startIndex = docUrl.lastIndexOf("/") + 1;
    const endIndex = docUrl.indexOf(".pdf") + 4; // Assuming the file extension is always '.pdf'
    const fileName = docUrl.substring(startIndex, endIndex);

    // Extracting the file type
    const fileType = fileName.split(".").pop();

    // Checking if it's a .doc file
    const isDocFile = ["doc/", "docx/"].includes(fileType.toLowerCase());

    // Returning 'doc' if it's a .doc file
    const result = isDocFile ? "doc" : "not a doc";
    if (result === "doc") {
      const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(docUrl)}`;
      window.open(viewerUrl, "_blank", "width=600,height=800");
    } else {
      window.open(docUrl);
    }
  };

  const handleEffect = async () => {
    const resume = await resumeRender(resumeId, userId);
    setResume(resume.resume_data.sectionsData.filter((res) => ![0].includes(res.section_detail.section_type_id)));
    setLoading(false);
  };

  const formatTime = (time) => {
    const date = new Date(time);

    let formatedTime = `${allmonths[date.getMonth()]} ${date.getFullYear()}`;

    return formatedTime;
  };
  useEffect(() => {
    handleEffect();
  }, []);

  return loading ? (
    <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: "0 auto", marginLeft: "50%", marginTop: "20%" }}>
      <Loader show={loading} />
    </div>
  ) : (
    <div className="student-details-parent" style={{ marginTop: "2rem" }}>
      <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <IconButton onClick={() => navigate(-1)}>
          <ChevronLeftIcon fontSize="32px" />
        </IconButton>
        <Box>
          <Typography sx={{ fontSize: "28px" }}>Your Resume</Typography>
          <Typography sx={{ fontSize: "18px" }}>All tick marked bullet points are verified. All other bullet points are unverified.</Typography>
        </Box>
      </Box>

      {resume.map((section) => {
        switch (section.section_detail.section_type_id) {
          // EDUCATION
          case 9:
            return (
              <Box sx={{ border: "1px solid #DBDBDB", borderRadius: "8px", marginBlock: "16px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "rgba(43, 9, 48, 0.75)", padding: "8px 12px", borderRadius: "8px 8px 0px 0px" }}>
                  <Typography sx={{ color: "#fff", fontSize: "20px" }}>Education</Typography>
                  <Checkbox
                    checked={section.section_data.every((acad) => acad.verified_by !== 0)}
                    disabled={section.section_data.length === 0}
                    icon={<RadioButtonUnchecked sx={{ color: "#fff" }} />}
                    checkedIcon={
                      <Box sx={{ background: Color.rdprimary, height: "22px", width: "22px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Done sx={{ color: "#fff", fontSize: "18px" }} />
                      </Box>
                    }
                    color="success"
                    onClick={() =>
                      section.section_data.every((acad) => acad.verified_by !== 0)
                        ? verify(
                            section.section_detail.section_type_id,
                            section.section_data.map((acad) => acad.acad_id),
                            0
                          )
                        : verify(
                            section.section_detail.section_type_id,
                            section.section_data.map((acad) => acad.acad_id),
                            1
                          )
                    }
                  />
                </Box>
                <Box>
                  {section.section_data.map((sub_section, index) => (
                    <Box sx={{ display: "flex", alignItems: "center", background: "#F7F7F7" }}>
                      <Box sx={{ flex: 1, paddingLeft: "8px", borderRight: "1px solid #8B8B8B" }}>
                        <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>Degree</Typography>
                        <Typography sx={{ fontSize: "16px", color: "#545454" }}>{sub_section.degree}</Typography>
                      </Box>
                      <Box sx={{ flex: 2.5, display: "flex", justifyContent: "center", borderRight: "1px solid #8B8B8B" }}>
                        <Box>
                          <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>Institution</Typography>
                          <Typography sx={{ fontSize: "16px", color: "#545454" }}>{sub_section.board_univ}</Typography>
                        </Box>
                      </Box>
                      <Box sx={{ flex: 2.5, display: "flex", justifyContent: "center", borderRight: "1px solid #8B8B8B" }}>
                        <Box>
                          <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>From</Typography>
                          <Typography sx={{ fontSize: "16px", color: "#545454" }}>{sub_section.start_year}</Typography>
                        </Box>
                      </Box>{" "}
                      <Box sx={{ flex: 2.5, display: "flex", justifyContent: "center", borderRight: "1px solid #8B8B8B" }}>
                        <Box>
                          <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>To</Typography>
                          <Typography sx={{ fontSize: "16px", color: "#545454" }}>{sub_section.end_year}</Typography>
                        </Box>
                      </Box>
                      <Box sx={{ flex: 1.5, padding: "8px 12px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Box>
                          <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>Score</Typography>
                          <Typography sx={{ fontSize: "16px", color: "#545454" }}>{sub_section.score}</Typography>
                        </Box>
                        <Box>
                          <Checkbox
                            checked={sub_section.verified_by !== 0}
                            icon={<RadioButtonUnchecked sx={{ color: "#545454" }} />}
                            checkedIcon={
                              <Box sx={{ background: Color.rdprimary, height: "22px", width: "22px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Done sx={{ color: "#fff", fontSize: "18px" }} />
                              </Box>
                            }
                            color="success"
                            onClick={() => (sub_section.verified_by !== 0 ? verify(section.section_detail.section_type_id, [sub_section.acad_id], 0) : verify(section.section_detail.section_type_id, [sub_section.acad_id], 1))}
                          />
                          {supported_doc_required && (
                            <Tooltip title={sub_section.supported_doc === null ? "No Proof submitted" : "Uploaded Proof"} placement="top">
                              <Box>
                                <IconButton disabled={sub_section.supported_doc === null} onClick={() => extract_filetype(sub_section.supported_doc)}>
                                  <DescriptionOutlined />
                                </IconButton>
                              </Box>
                            </Tooltip>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            );
          // WORKEX SIP UGINTERNSHIP
          case 4:
          case 3:
          case 10:
            return (
              <Box sx={{ border: "1px solid #DBDBDB", borderRadius: "8px", paddingBottom: "4px", marginBlock: "16px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "rgba(43, 9, 48, 0.75)", padding: "8px 12px", borderRadius: "8px 8px 0px 0px" }}>
                  <Typography sx={{ color: "#fff", fontSize: "20px" }}>{section.section_detail.section_name}</Typography>
                  <Checkbox
                    checked={section.sub_sections?.every((subsection) => subsection.projects?.every((project) => project.project_data?.every((bullet) => bullet.verified_by !== 0)))}
                    icon={<RadioButtonUnchecked sx={{ color: "#fff" }} />}
                    disabled={section.sub_sections.length === 0}
                    checkedIcon={
                      <Box sx={{ background: Color.rdprimary, height: "22px", width: "22px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Done sx={{ color: "#fff", fontSize: "18px" }} />
                      </Box>
                    }
                    color="success"
                    onClick={() =>
                      section.sub_sections.every((subsection) => subsection.projects.every((project) => project.project_data.every((bullet) => bullet.verified_by !== 0)))
                        ? verify(
                            section.section_detail.section_type_id,
                            section.sub_sections
                              .map((subsection) => subsection.projects.map((project) => project.project_data.map((bullet) => bullet.bullet_id)))
                              .flat()
                              .flat(),
                            0
                          )
                        : verify(
                            section.section_detail.section_type_id,
                            section.sub_sections
                              .map((subsection) => subsection.projects.map((project) => project.project_data.map((bullet) => bullet.bullet_id)))
                              .flat()
                              .flat(),
                            1
                          )
                    }
                  />
                </Box>
                <Box sx={{ paddingInline: "8px" }}>
                  {section.sub_sections.map((subSection) => (
                    <Box>
                      <Accordion expanded={expanded === subSection.sub_section_detail.sub_section_id ? true : false} onChange={() => setExpanded(subSection.sub_section_detail.sub_section_id === expanded ? null : subSection.sub_section_detail.sub_section_id)} sx={{ boxShadow: "none", padding: "0px", margin: "0px" }}>
                        <AccordionSummary expandIcon={null} aria-controls="panel1bh-content" id="panel1bh-header" sx={{ padding: "0px" }}>
                          <Box sx={{ width: "100%" }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "#F4F4F4", padding: "4px", borderRadius: "4px", marginBottom: "12px" }}>
                              <Typography sx={{ color: "#545454", fontSize: "16px" }}>Project {1}</Typography>
                              <Box>
                                <Checkbox
                                  checked={subSection.projects?.every((project) => project.project_data?.every((bullet) => bullet.verified_by !== 0))}
                                  icon={<RadioButtonUnchecked sx={{ color: "#545454" }} />}
                                  checkedIcon={
                                    <Box sx={{ background: Color.rdprimary, height: "22px", width: "22px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                      <Done sx={{ color: "#fff", fontSize: "18px" }} />
                                    </Box>
                                  }
                                  color="success"
                                  // disabled={subsection.projects.length === 0}
                                  onClick={() => (subSection.projects.every((project) => project.project_data.every((bullet) => bullet.verified_by !== 0)) ? verify(section.section_detail.section_type_id, subSection.projects.map((project) => project.project_data.map((bullet) => bullet.bullet_id)).flat(), 0) : verify(section.section_detail.section_type_id, subSection.projects.map((project) => project.project_data.map((bullet) => bullet.bullet_id)).flat(), 1))}
                                />
                                <div style={{ ...divCenter, width: "3%" }}>
                                  {supported_doc_required && (
                                    <Tooltip title={subSection.sub_section_detail.supported_doc === null ? "No Proof submitted" : "Uploaded Proof"} placement="top">
                                      <Box>
                                        <IconButton disabled={subSection.sub_section_detail.supported_doc === null} onClick={() => extract_filetype(subSection.sub_section_detail.supported_doc)}>
                                          <DescriptionOutlined />
                                        </IconButton>
                                      </Box>
                                    </Tooltip>
                                  )}
                                </div>
                              </Box>
                            </Box>
                            <Box sx={{ display: "flex", width: "100%", border: "1px solid #DBDBDB", padding: "4px", borderRadius: "8px" }}>
                              <Box sx={{ flex: 1, borderRight: "1px solid #8B8B8B", paddingLeft: "8px" }}>
                                <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>Name</Typography>
                                <Typography sx={{ fontSize: "16px", color: "#545454" }}>{subSection.basic_info.company_name}</Typography>
                              </Box>
                              <Box sx={{ flex: 2.3, borderRight: "1px solid #8B8B8B ", display: "flex", justifyContent: "center" }}>
                                <Box>
                                  <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>Designation</Typography>
                                  <Typography sx={{ fontSize: "16px", color: "#545454" }}>
                                    {subSection.basic_info.designation} {subSection.basic_info.domain}
                                  </Typography>
                                </Box>
                              </Box>{" "}
                              <Box sx={{ flex: 1.2, borderRight: "1px solid  #8B8B8B", display: "flex", justifyContent: "center" }}>
                                <Box>
                                  <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>From</Typography>
                                  <Typography sx={{ fontSize: "16px", color: "#545454" }}>{formatTime(subSection.basic_info.start_date)}</Typography>
                                </Box>
                              </Box>{" "}
                              <Box sx={{ flex: 1.8, display: "flex", justifyContent: "space-between", paddingInline: "8px" }}>
                                <Box>
                                  <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>To</Typography>
                                  <Typography sx={{ fontSize: "16px", color: "#545454" }}>{formatTime(subSection.basic_info.end_date)}</Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: "0px" }}>
                          <Box>
                            {subSection.projects.map((project) => (
                              <Box>
                                {project.project_data.map((bullet) => (
                                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "4px 4px 4px 12px" }}>
                                    <Typography>{bullet.bullet_content}</Typography>
                                    <Checkbox
                                      checked={bullet.verified_by !== 0}
                                      icon={<RadioButtonUnchecked sx={{ color: "#545454" }} />}
                                      checkedIcon={
                                        <Box sx={{ background: Color.rdprimary, height: "22px", width: "22px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                          <Done sx={{ color: "#fff", fontSize: "18px" }} />
                                        </Box>
                                      }
                                      color="success"
                                      onClick={() => (bullet.verified_by !== 0 ? verify(section.section_detail.section_type_id, [bullet.bullet_id], 0) : verify(section.section_detail.section_type_id, [bullet.bullet_id], 1))}
                                    />
                                  </Box>
                                ))}
                              </Box>
                            ))}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  ))}
                </Box>
              </Box>
            );
          default:
            return (
              <Box sx={{ border: "1px solid #DBDBDB", borderRadius: "8px", paddingBottom: "4px", marginBlock: "16px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "rgba(43, 9, 48, 0.75)", padding: "8px 12px", borderRadius: "8px 8px 0px 0px" }}>
                  <Typography sx={{ color: "#fff", fontSize: "20px" }}>{section.section_detail.section_name}</Typography>
                  <Checkbox
                    checked={section.sub_sections.every((subsection) => subsection.bullets.every((bullet) => bullet.verified_by !== 0))}
                    icon={<RadioButtonUnchecked sx={{ color: "#fff" }} />}
                    disabled={section.sub_sections.length === 0}
                    checkedIcon={
                      <Box sx={{ background: Color.rdprimary, height: "22px", width: "22px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Done sx={{ color: "#fff", fontSize: "18px" }} />
                      </Box>
                    }
                    color="success"
                    onClick={() => (section.sub_sections.every((subsection) => subsection.bullets.every((bullet) => bullet.verified_by !== 0)) ? verify(section.section_detail.section_type_id, section.sub_sections.map((subsection) => subsection.bullets.map((bullet) => bullet.bullet_id)).flat(), 0) : verify(section.section_detail.section_type_id, section.sub_sections.map((subsection) => subsection.bullets.map((bullet) => bullet.bullet_id)).flat(), 1))}
                  />
                </Box>
                <Box sx={{ paddingInline: "8px" }}>
                  {section.sub_sections.map((sub_section, index) => (
                    <Box sx={{ marginBlock: "4px" }}>
                      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "2px 4px", background: "#F4F4F4", borderRadius: "4px" }}>
                        <Typography sx={{ color: "#545454" }}>{sub_section.basic_info.tag}</Typography>
                        <Box>
                          <Checkbox
                            checked={sub_section.bullets.every((bullet) => bullet.verified_by !== 0)}
                            icon={<RadioButtonUnchecked sx={{ color: "#545454" }} />}
                            checkedIcon={
                              <Box sx={{ background: Color.rdprimary, height: "22px", width: "22px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Done sx={{ color: "#fff", fontSize: "18px" }} />
                              </Box>
                            }
                            disabled={sub_section.bullets.length === 0}
                            color="success"
                            onClick={() =>
                              sub_section.bullets.every((bullet) => bullet.verified_by !== 0)
                                ? verify(
                                    section.section_detail.section_type_id,
                                    sub_section.bullets.map((bullet) => bullet.bullet_id),
                                    0
                                  )
                                : verify(
                                    section.section_detail.section_type_id,
                                    sub_section.bullets.map((bullet) => bullet.bullet_id),
                                    1
                                  )
                            }
                          />
                          <div style={{ ...divCenter, width: "3%" }}>
                            {supported_doc_required && (
                              <Tooltip title={sub_section.sub_section_detail.supported_doc === null ? "No Proof submitted" : "Uploaded Proof"} placement="top">
                                <Box>
                                  <IconButton disabled={sub_section.sub_section_detail.supported_doc === null} onClick={() => extract_filetype(sub_section.sub_section_detail.supported_doc)}>
                                    <DescriptionOutlined />
                                  </IconButton>
                                </Box>
                              </Tooltip>
                            )}
                          </div>
                        </Box>
                      </Box>
                      <Box>
                        {sub_section.bullets.map((bullet) => (
                          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBlock: "4px", padding: "4px 4px 4px 12px" }}>
                            <Typography>{bullet.bullet_content}</Typography>
                            <Checkbox
                              checked={bullet.verified_by !== 0}
                              icon={<RadioButtonUnchecked sx={{ color: "#545454" }} />}
                              checkedIcon={
                                <Box sx={{ background: Color.rdprimary, height: "22px", width: "22px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <Done sx={{ color: "#fff", fontSize: "18px" }} />
                                </Box>
                              }
                              color="success"
                              onClick={() => (bullet.verified_by !== 0 ? verify(section.section_detail.section_type_id, [bullet.bullet_id], 0) : verify(section.section_detail.section_type_id, [bullet.bullet_id], 1))}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            );
        }
      })}
    </div>
  );
}

export default Verification;
