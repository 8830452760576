import React, { useState, useEffect } from 'react'
import SideNavigationBar from '../reusables/SideNavigationBar/SideNavigationBar'
import Loader from '../reusables/Loader/Loader';

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AddOutlined, GetApp, UploadFile, LinkOff, Search, AttachFile, EqualizerOutlined, DnsOutlined, EditOutlined, FilePresentOutlined, DeleteOutlined } from "@mui/icons-material";
import Box from "@mui/material/Box";
import axios from 'axios'
import { IconButton, Tooltip, Modal, Menu, MenuItem, Button, FormControlLabel, Checkbox, TextField, CircularProgress, InputAdornment } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ReactApexChart from 'react-apexcharts'
import Link from '@mui/material/Link';
import { emphasize, styled } from '@mui/material/styles';
import { Color, DivCenter, styles } from "../../GlobalStyles";
import Chip from '@mui/material/Chip';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CCService from '../../services/httpServiceWithoutSuperAdmin';
import Cookies from 'js-cookie';
import { ArrowBackIosNew } from '@mui/icons-material';
import StepperOS from './StepperOS';
import { DialogueBox } from '../reusables/DialogueBox/DialogueBox';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridRowsProp, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import StudentSummaryModule from './StudentSummaryModule';
import { convertEpochToNormalTime, extractDateFromDateString, formatConvertermonthfirst } from '../../services/specialCharReplace';
import { SearchFunction } from '../reusables/TextFunctions/SearchFunctions';
import SessionSchedule from './SessionSchedule';
function StudentSummary() {

    const [loading, setLoading] = useState(true);

    const [studentSummPage, setStudentSummPage] = useState(1);
    const [releaseNextListData, setReleaseNextListData] = useState([]);
    const location = useLocation();
    const [studentSummaryData, setStudentSummary] = useState({
        "specialization_deadline": null,
        "spe_count": [],
        "total_count": 0
    });

    const queryParams = new URLSearchParams(location.search);
    const process_id = queryParams.get("process_id");

    const college_name = location.state?.college_name;
    const user_group_name = location.state?.user_group_name;
    // const college_name = location.state?.college_name;
    // const program_name = location.state?.program_name;
 
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    };

    const [studentDetails, setStudentDetails] = useState([{
        "user_id": null,
        "first_name": "",
        "last_name": "",
        "email_id": "",
        "ph_no": "",
        "spe_id": null,
        "spe_name": "",
        "resume": { "url": "", "name": "" },
        "other_docs": [{ "url": "", "name": "" }],
        "student_availability": null,
        "student_availability_proof": { "url": "", "name": "" }
    }]);
    //MENTOR INTEREST
    const [mentorInterestData, setMentorInterestData] = useState({
        "process_id": null,
        "specialization_deadline": null,
        "session_date_slot_count_mapping": [
            {
                "session_date": null,
                "per_mentor_slot_count": ""
            },
        ],
        "mentor_cities": [],
        "likely_schedule": "",
        "trip_details": "",
        "mentor_interest_deadline": null,
        "mentor_compensation": "",
        "departure_cities": [],
        "arrival_cities": [],
        "student_availability_deadline": null,
        "slot_selection_deadline": null,
        "no_of_student_per_list": null,
        "percent_cutoff_for_new_list": null
    });
    const [mentorCities, setMentorCities] = useState([]);
    useEffect(() => {
        setLoading(false);
    }, []);

    const [pageData, setPageData] = useState({
        "college_code": "",
        "user_group_name": "",
        "name": "",
        "sku_name": ""
    });

    const withoutSuperAdmin = new CCService()

    const getStatLists = async () => {
        try {
            const response = await withoutSuperAdmin.get(`/os/superadmin/studentDetails?process_id=${process_id}`);
            console.log("getStatLists ~ response:", response);
            return response.data;
        } catch (error) {
            console.error('Error while fetching stat data:', error);
        }
    }

    const getSpecializationData = async () => {
        try {
            const response = await withoutSuperAdmin.get(`/superAdmin/specialization`);
            return response.data;
        } catch (error) {
            console.error('Error while fetching specialization:', error);
        }
    }
    const getStudentSummary = async () => {
        try {
            const response = await withoutSuperAdmin.get(`/os/superadmin/specializationCount?process_id=${process_id}`);
            console.log("getStudentSummary ~ response:", response);
            setPageData((prev) => ({
                ...prev,
                "college_code": response.description.college_code,
                "user_group_name": response.description.user_group_name,
                "name": response.description.name,
                "sku_name": response.description.sku_name
            }))
            return response;
        } catch (err) {
            console.error("Error while fetching student summary:", err);
        }
    }

    const getStudentDetails = async (page = 1, searchQuery) => {
        try {
            if (page) {
                const response = searchQuery ?
                    await withoutSuperAdmin.get(`/os/superadmin/order?process_id=${process_id}&page=${page}&limit=100&searchQuery=${searchQuery}`)
                    : await withoutSuperAdmin.get(`/os/superadmin/order?process_id=${process_id}&page=${page}&limit=100`);
                console.log("getStudentDetails ~ response:", response.data);
                if (response?.data) {
                    const data = response.data;
                    if (data.length)
                        data.forEach((order) => {
                            order.resume = JSON.parse(order.resume);
                            order.other_docs = JSON.parse(order.other_docs);
                            order.student_availability_proof = JSON.parse(order.student_availability_proof);
                        });
                    return data;
                } else return [];
            }
        } catch (err) {
            console.error("Error while fetching student details:", err);
        }
    }


    const representationData = (studentSummary_data) => {
        /* 
            This function is mainly just to set representation data in itAllAboutNumbers, 
                but we're returning just in case in the future there is any required change in data structure 
        */

        let data = { ...studentSummary_data };
        let temp_itAllNumbers = { ...itAllAboutNumbers };
        temp_itAllNumbers.options.series[0].data = data.spe_count.map((item) => item.student_count ?? item.total_student_not_interested);
        temp_itAllNumbers.options.xaxis.categories = data.spe_count.map((item) => (item.total_student_not_interested ||  item.total_student_not_interested == 0)  ? "Students not interested" : item.spe_name ?? 'Not yet selected');console.log("representationData ~ temp_itAllNumbers:", temp_itAllNumbers)
        setitAllAboutNumbers(temp_itAllNumbers);
        return { remade_student_summary: data };
    }


    //MENTOR INTEREST
    const getMentorInterest = async () => {
        try {
            const response = await withoutSuperAdmin.get(`/os/superadmin/properties?process_id=${process_id}`);
            console.log("Mentor Interest:", response.data);
            const format_data = formatMentorInterest(response.data);
            return format_data;
        } catch (error) {
            console.error("Error while fetching mentor interest:", error);
        }
    }

    const formatMentorInterest = (fetched_data) => {
        let data = { ...fetched_data };
        data = {
            ...data,
            session_date_slot_count_mapping: data.session_date_slot_count_mapping?.session_date_slot_count_mapping ?? [],
            mentor_cities: data.mentor_cities?.mentor_cities ?? [],
            departure_cities: data.departure_cities?.departure_cities ?? [],
            arrival_cities: data.arrival_cities?.arrival_cities ?? [],
        }
        return data;
    }

    const apiRef = useGridApiRef();


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDownloadCSV = () => {
        apiRef.current.exportDataAsCsv();
    };

    const getMentorCities = async () => {
        try {
            const response = await withoutSuperAdmin.get(`os/superadmin/mentorCities`);
            const cities = response.cities.map((city) => city.city);
            return cities;
        } catch (error) {
            console.error("Error while fetching mentor cities:", error);
        }
    }

    const [pageState, setPageState] = useState({
        student_summary: 0,
        list_view: 0
    });


    const [specializationData, setSpecializationData] = useState([{ id: null, name: '' }])

    const [activeStep, setActiveStep] = useState({ step: 1, sub_page: 0 });
    const [lastActivePage, setLastActive] = useState();
    const navigate = useNavigate();
    const [stepper, setStepper] = useState([
        { id: 1, content: 'Batch Specialization Summary', active: 1 },
        { id: 2, content: 'Batch Availability', completed: 0 },
        { id: 3, content: 'Session Schedule', completed: 0 }
    ]);

    useEffect(() => {
        const updatedSteps = stepper?.map((step, index) => {
            switch (index) {
                case 0:
                    return { ...step, completed: studentSummaryData.specialization_deadline < Date.now() ? 1 : 0 }
                    break;
                case 1: return { ...step, completed: mentorInterestData.student_availability_deadline && mentorInterestData.student_availability_deadline < Date.now() ? 1 : 0 }
                    break;
                case 2: return { ...step, completed: mentorInterestData.session_date_slot_count_mapping.at(-1)?.session_date < Date.now() ? 1 : 0 } // last session_date
                default: return { ...step }
                    break;
            }

            return step;
        });
        // const lastCompletedIndex = updatedSteps.reduceRight((acc, step, index) => step.completed === 1 ? acc === -1 ? index : acc : acc, -1);
        // const temp_value = lastCompletedIndex === 1 ? lastCompletedIndex + 3 : lastCompletedIndex + 2;
        setActiveStep({ step: 1, sub_page: 0 });
        console.log("hotdd:", mentorInterestData.session_date_slot_count_mapping.at(-1)?.session_date, Date.now())

        const last_active = releaseNextListData && releaseNextListData[0]?.length > 0 ? 2 : 1;
        setLastActive(last_active);
        setStepper(updatedSteps);
        console.log("releaseNextListData:", releaseNextListData);
    }, [studentSummaryData, mentorInterestData, releaseNextListData]);

    const [dialogue, setDialogue] = useState({
        open: false,
        onClose: () => { setDialogue((prev) => ({ ...prev, open: false })) },
        content: "",
        actions: { label: 'OK', handler: () => { }, color: Color.primary1, variant: "contained" }
    });

    const handleReleaseNextSlot = async () => {
        try {
            await withoutSuperAdmin.put(`os/superadmin/launchSchedule`, { "process_id": parseInt(process_id) });
            setReleaseNextListData(getStatLists());
            dialogue.onClose();
        } catch (error) {
            console.error('Error while putting release next list:', error);
        }
    }

    const handleStepChange = (event, newActiveStep, newActivePage) => {
        console.log("handleStepChange ~ newActiveStep, newActivePage:", newActiveStep, newActivePage)
        if (![null, undefined].includes(newActiveStep) && [null, undefined].includes(newActivePage))
            setActiveStep((prev) => ({ ...prev, step: newActiveStep }));
        else if ([null, undefined].includes(newActiveStep) && ![null, undefined].includes(newActivePage))
            setActiveStep((prev) => ({ ...prev, sub_page: newActivePage }));
        else if (![null, undefined].includes(newActiveStep) && ![null, undefined].includes(newActivePage))
            setActiveStep((prev) => ({ ...prev, step: newActiveStep, sub_page: newActivePage }));
    };
    console.log("active step:", activeStep)
    useEffect(() => {
        handleMainEffect();
    }, []);

    const handleMainEffect = async () => {

        const specilization_data = await getSpecializationData();
        setSpecializationData(specilization_data);
        //STUDENT SUMMARY
        try {
            const student_summary = await getStudentSummary();      //Specialization count
            console.log("handleMainEffect ~ student_summary:", student_summary);
            //data-representation data
            const { remade_student_summary } = representationData(student_summary);
            console.log("handleMainEffect ~ remade_data:", remade_student_summary);
            setStudentSummary((prev) => ({
                ...prev,
                "specialization_deadline": remade_student_summary.specialization_deadline,
                "spe_count": remade_student_summary.spe_count,
                "total_count": remade_student_summary.total_count
            }));
            const student_details = await getStudentDetails(studentSummPage);      //ORDER

            console.log("student_details.forEach ~ student_details:", student_details)
            setStudentDetails(student_details);
        } catch (error) {
            console.error("Student Summary ~ error:", error);
        }

        //MENTOR INTEREST
        try {
            const mentor_interest = await getMentorInterest();      //Properties [GET]
            setMentorInterestData(mentor_interest);
            const cities = await getMentorCities();
            setMentorCities(cities);
        } catch (error) {
            console.error("Mentor Interest ~ error:", error);
        }

        //MENTOR SHOWN INTEREST
        //In Main shown interest page

        const response_stat_data = await getStatLists();
        setReleaseNextListData(response_stat_data);

        setLoading(false);
    }



    const [itAllAboutNumbers, setitAllAboutNumbers] = useState({
        options: {
            series: [{
                name: 'Student Count',
                data: [],

            }],
            chart: {
                height: '100%',
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    borderRadius: 20,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                    columnWidth: "40px",
                    colors: {
                        ranges: [{
                            from: 1,
                            to: 1000,
                            color: Color.primary1
                        }],
                        backgroundBarColors: [],
                        backgroundBarOpacity: 1,
                        backgroundBarRadius: 0,
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val;
                },
                offsetY: -20,
                style: {
                    fontSize: '14px',
                    colors: [Color.primary1]
                }
            },

            xaxis: {
                categories: [],
                position: 'bottom',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                    formatter: function (val) {
                        return val;
                    }
                },
                // crosshairs: {
                //     show: true,
                //     position: 'back',
                //     stroke: {
                //         color: '#b6b6b6',
                //         width: 1,
                //         dashArray: 0,
                //     },
                // },

            },
            title: {
                text: '',
                floating: true,
                offsetY: 330,
                align: 'center',
                style: {
                    color: '#444'
                }
            }
        }
    });

    return (

        <>
            {/* <StepperOS/> */}
            {loading ?
                <Loader show={loading.page} />
                :
                <Box sx={{ p: '16px 90px 16px 138px', minWidth: '1311px', }}>
                    <SideNavigationBar />
                    <Box sx={{display:"flex" , flexDirection:"row",width: '5%', justifyContent: 'flex-start', alignItems:"center", width: '100%' }}>
                        <Box sx={{ display:"flex" , flexDirection:"row",width: '5%', justifyContent: 'flex-start', }}>
                            <ArrowBackIosNew onClick={() => navigate(-1)} sx={{ width: '34px', height: '34px', cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '55%' }}>
                            <Typography style={{ fontSize: '24px', color: Color.primary1 }}>Mock Interview</Typography>
                            <Typography style={{ fontSize: '16px', color: Color.neutralMidGrey }}>{college_name} , {user_group_name}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ ...DivCenter, width: '100%', padding: '48px 0' }}>
                        <StepperOS
                            steps={stepper}
                            setSteps={setStepper}
                            activeStep={activeStep}
                            lastActivePage={lastActivePage}
                            setActiveStep={setActiveStep}
                            connectorColor="blue"
                            stepBackgroundColor={Color.primary1} // Example customization
                            completedColor="green" // Example customization (optional)
                            onStepChange={(z, a, b) => handleStepChange(z, a, b)}
                        />

                    </Box>


                    {
                        activeStep.step === 1 ?
                            <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', padding: '24px 0 24px 0', gap: '10px' }}>
                                <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between' }}>
                                    <Typography style={{ fontSize: '24px', color: Color.neutralBlack }}>Batch Specialization Preference</Typography>
                                    <Typography style={{ fontSize: '16px', color: Color.neutralBlack }}>Deadline: { formatConvertermonthfirst(studentSummaryData.specialization_deadline)}, {convertEpochToNormalTime( studentSummaryData.specialization_deadline)}</Typography>

                                </Box>
                                <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between' }}>
                                    <Typography style={{ fontSize: '20px', color: Color.neutralMidGrey }}>
                                        {!pageState.student_summary ?
                                            "Number of students opted for different specialization"
                                            : "Student wise specialization preference data"}</Typography>
                                    <IconButton onClick={() => setPageState((prev) => ({ ...prev, list_view: !prev.list_view }))} style={{ fontSize: '16px', cursor: 'pointer' }}>
                                        {!pageState.student_summary ? !pageState.list_view ? <DnsOutlined sx={{ color: Color.primary1 }} /> : <EqualizerOutlined sx={{ color: Color.primary1 }} /> : <></>}
                                    </IconButton>
                                </Box>
                                <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-start' }}>
                                    <Typography
                                        onClick={() => setPageState((prev) => ({ ...prev, student_summary: !prev.student_summary }))}
                                        style={{
                                            fontSize: '16px',
                                            color: Color.primary1,
                                            cursor: 'pointer',
                                            textDecoration: 'none', // Remove default underline
                                            '&:hover': {
                                                textDecoration: 'underline',
                                            },
                                        }}>
                                        {!pageState.student_summary ? "View student summary" : "View batch summary"}
                                    </Typography>

                                </Box>
                                {pageState.student_summary ?
                                    <StudentSummaryModule process_id={process_id} studentDetails={studentDetails} pageData={pageData} setStudentDetails={setStudentDetails} getStudentDetails={getStudentDetails} specializationData={specializationData} />
                                    :
                                    !pageState.list_view ?
                                        <Box sx={{ ...DivCenter, width: '100%', flexDirection: 'column' }}>
                                            <Box sx={{ display: 'flex', width: '60%', justifyContent: 'flex-end' }}>
                                                <Typography style={{ fontSize: '16px', color: Color.primary1, }}>Total Students: {studentSummaryData.total_count}</Typography>
                                            </Box>
                                            <ReactApexChart

                                                options={itAllAboutNumbers.options}
                                                series={itAllAboutNumbers.options.series}
                                                type="bar"
                                                height={itAllAboutNumbers.options.series[0].data.length * 70}
                                                width={950}
                                            />
                                        </Box>
                                        : <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "flex-start",

                                                '& .super-app-theme--header': {
                                                    backgroundColor: 'rgba(239, 246, 255, 1)',
                                                },
                                            }} >

                                            <DataGrid columns={[
                                            {
                                                field: 'Specialization',
                                                flex:0.5,
                                                headerClassName: 'super-app-theme--header',
                                                // headerAlign: 'center',
                                                // width: 140,
                                            },
                                            {
                                                field: 'Applicants',
                                                flex:0.5,
                                                headerClassName: 'super-app-theme--header',
                                                // headerAlign: 'center',
                                                // width: 140,
                                            },
                                         
                                        ]}
                                        
                                                apiRef={apiRef}
                                                getRowClassName={(params) =>
                                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                                }
                                                components={{
                                                    Toolbar: CustomToolbar,
                                                }}
                                                rows={[...studentSummaryData.spe_count, { spe_name: "Total students", student_count: studentSummaryData.total_count }].map((spe, spe_index) => ({
                                                    id: spe_index,
                                                    Specialization: (spe.total_student_not_interested || spe.total_student_not_interested ==0) ? "Students not interested" : spe.spe_name ?? 'Not yet selected',
                                                    Applicants: spe.student_count ?? spe.total_student_not_interested
                                                }))} 
                                                 />

                                            <div>
                                                <Button
                                                    id="basic-button"
                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleClick}
                                                    variant="text"

                                                    sx={{ marginBottom: 2, color: Color.darkerGrey }}>
                                                    <MenuIcon />
                                                </Button>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                // MenuListProps={{
                                                //     'aria-labelledby': 'basic-button',
                                                // }}
                                                >
                                                    <MenuItem onClick={() => { handleDownloadCSV(); handleClose(); }}>Download CSV</MenuItem>
                                                </Menu>
                                            </div>

                                        </Box>
                                } </Box>

                            : activeStep.step === 2 ?
                                <LaunchStudentAvailabilityPage
                                    process_id={process_id}
                                    mentorInterestData={mentorInterestData}
                                    orderDetails={studentDetails}
                                    setOrderDetails={setStudentDetails}
                                    getStudentDetails={getStudentDetails}
                                    specilizationCountData={studentSummaryData}
                                    onStepChange={(z, a, b) => handleStepChange(z, a, b)}
                                />

                                : activeStep.step === 3 ?
                                    // <>
                                    //     Listss
                                    // </>
                                    <SessionSchedule
                                        process_id={process_id}
                                        releaseNextListData={releaseNextListData}
                                        getStatLists={getStatLists}
                                        onStepChange={(z, a, b) => handleStepChange(z, a, b)}
                                    />
                                    : <></>}
                    {/* <DialogueBox
                        open={dialogue.open}
                        onClose={dialogue.onClose}
                        title={"modal if required"}
                        // actions={dialogue.actions}
                        content={<>
                            <p>{dialogue.content}</p>
                        </>}
                    /> */}
                </Box >}
        </>
    )
}

export default StudentSummary

export const LaunchStudentAvailabilityPage = ({
    mentorShownInterestData,
    setMentorShownInterestData,
    getMentorShownInterest,
    mentorInterestData,
    process_id,
    mentorSummary,
    orderDetails,
    setOrderDetails,
    getStudentDetails,
    specilizationCountData, onStepChange }) => {
    console.log("onStepChange:", onStepChange)
    // const [orderDetails, setOrderDetails] = useState([
    //     {
    //         "id": 1,
    //         "first_name": "OFC_student4",
    //         "last_name": "Offline",
    //         "email_id": "forobiv344@sfpixel.com",
    //         "ph_no": "1234567890",
    //         "spe_id": 3,
    //         "spe_name": "Finance",
    //         "student_availability": null,
    //         "student_availability_proof": null
    //     },
    //     {
    //         "id": 2,
    //         "first_name": "OFC_student4",
    //         "last_name": "Offline",
    //         "email_id": "forobiv344@sfpixel.com",
    //         "ph_no": "1234567890",
    //         "spe_id": 3,
    //         "spe_name": "Finance",
    //         "student_availability": 9,
    //         "student_availability_proof": {
    //             "url": "https://zxcvbnm.xyz",
    //             "name": "dummy"
    //         }
    //     },
    //     {
    //         "id": 3,
    //         "first_name": "OFC_student4",
    //         "last_name": "Offline",
    //         "email_id": "forobiv344@sfpixel.com",
    //         "ph_no": "1234567890",
    //         "spe_id": 1,
    //         "spe_name": "HR",
    //         "student_availability": 2,
    //         "student_availability_proof": {
    //             "url": "https://zxcvbnm.xyz",
    //             "name": "dummy"
    //         }
    //     },
    //     {
    //         "id": 4,
    //         "first_name": "OFC_student4",
    //         "last_name": "Offline",
    //         "email_id": "forobiv344@sfpixel.com",
    //         "ph_no": "1234567890",
    //         "spe_id": 2,
    //         "spe_name": "Sales & Marketing",
    //         "student_availability": 4,
    //         "student_availability_proof": {
    //             "url": "https://zxcvbnm.xyz",
    //             "name": "dummy"
    //         }
    //     },
    //     {
    //         "id": 5,
    //         "first_name": "OFC_student4",
    //         "last_name": "Offline",
    //         "email_id": "forobiv344@sfpixel.com",
    //         "ph_no": "1234567890",
    //         "spe_id": 4,
    //         "spe_name": "IT",
    //         "student_availability": 6,
    //         "student_availability_proof": {
    //             "url": "https://zxcvbnm.xyz",
    //             "name": "dummy"
    //         }
    //     },
    //     {
    //         "id": 6,
    //         "first_name": "OFC_student4",
    //         "last_name": "Offline",
    //         "email_id": "forobiv344@sfpixel.com",
    //         "ph_no": "1234567890",
    //         "spe_id": 5,
    //         "spe_name": "Analytics",
    //         "student_availability": 13,
    //         "student_availability_proof": {
    //             "url": "https://zxcvbnm.xyz",
    //             "name": "dummy"
    //         }
    //     },
    //     {
    //         "id": 7,
    //         "first_name": "OFC_student4",
    //         "last_name": "Offline",
    //         "email_id": "forobiv344@sfpixel.com",
    //         "ph_no": "1234567890",
    //         "spe_id": 5,
    //         "spe_name": "Analytics",
    //         "student_availability": 12,
    //         "student_availability_proof": {
    //             "url": "https://zxcvbnm.xyz",
    //             "name": "dummy"
    //         }
    //     },
    //     {
    //         "id": 8,
    //         "first_name": "OFC_student4",
    //         "last_name": "Offline",
    //         "email_id": "forobiv344@sfpixel.com",
    //         "ph_no": "1234567890",
    //         "spe_id": 6,
    //         "spe_name": "Consulting",
    //         "student_availability": 12,
    //         "student_availability_proof": {
    //             "url": "https://zxcvbnm.xyz",
    //             "name": "dummy"
    //         }
    //     },
    // ]);
    console.log("LaunchStudentAvailabilityPage2:", { orderDetails }, { specilizationCountData });
    const [loading, setLoading] = useState({ button: false });
    const [classesData, setClassesData] = useState({});
    const [speJSON, setSpeJSON] = useState({});

    //FUNCTIONS
    useEffect(() => {
        handleEffect();
    }, [])

    const handleEffect = async () => {
        setLoading((prev) => ({ ...prev, page: true }));
        const data = remakeStudentAvailabilityData(orderDetails);
        console.log("handleEffect ~ data:", data)
        setClassesData(data);
        // const fetched_student_availability_deadline = await fetchStudentAvailabilityDeadline();
        // setStudent_availability_deadline(fetched_student_availability_deadline.student_availability_deadline);
        setLoading((prev) => ({ ...prev, page: false }));
    }

    function sortJsonObject(obj) {
        const sortedKeys = Object.keys(obj).sort((a, b) => parseInt(b) - parseInt(a));
        const sortedObject = {};
        for (const key of sortedKeys) {
            sortedObject[key] = obj[key];
        }
        console.log("sortJsonObject ~ sortedObject:", sortedObject)
        return sortedObject;
    }

    const remakeStudentAvailabilityData = (data) => {
        const main_data = [...data];
        console.log("data in launch:", data);
        // 1. Remove students with no specialization
        const actual_studentsData = main_data.filter((student) => student.spe_id !== null);

        // 2. Sort data by student_availability in descending order
        const sorted_studentsData = actual_studentsData.sort((a, b) => b.student_availability - a.student_availability);

        // 3. Make base_json
        let spe_json = {};
        sorted_studentsData.forEach((data) => {
            const { spe_name } = data;
            spe_json[spe_name ?? ''] = 0;
        });
        spe_json = { 'No of Classes': 0, ...spe_json, 'Total': 0 };
        setSpeJSON(spe_json);
        // 4. Make class count module
        const main_json = {};

        // 5. Separate top 6 and remaining students
        const nullStudentsData = sorted_studentsData.filter((student) => student.student_availability === null);
        const notNullStudentsData = sorted_studentsData.filter((student) => student.student_availability !== null);
        const unique_arr = [];
        const uniqueStudentData = notNullStudentsData.reduce((acc, student) => {
            // Check if the current student is not already in the accumulator
            if (!unique_arr.includes(student.student_availability)) {
                // Add the current student to the accumulator if it's not already present
                unique_arr.push(student.student_availability);
                acc.push(student);
            }
            return acc;
        }, []);
        console.log("unique:", uniqueStudentData);
        const topSix = uniqueStudentData.slice(0, 6); // Get top 6 availabilities
        let index_of_last_top_six = 0;
        const till_top_6 = notNullStudentsData.filter((ele, index) => {
            if (ele.student_availability >= topSix[topSix.length - 1].student_availability) {
                index_of_last_top_six++;
            }
            return ele.student_availability >= topSix[topSix.length - 1].student_availability;
        })

        const remainingStudents = notNullStudentsData.slice(index_of_last_top_six);
        console.log("top 6:", topSix);
        console.log("top 6 till :",)
        // 6. Process top 6 availabilities
        till_top_6.forEach((data) => {
            const { student_availability } = data;
            main_json[student_availability] = { ...spe_json, 'No of Classes': student_availability };
        });
        console.log("main jsn:", main_json);
        till_top_6.forEach((student) => {
            const { student_availability, spe_name } = student;
            // Find the corresponding availability group in main_json
            const availabilityGroup = main_json[student_availability] || {};

            // Check if the spe_name already exists in the availability group
            if (availabilityGroup[spe_name]) {
                availabilityGroup[spe_name]++; // Increment existing count
            } else {
                availabilityGroup[spe_name] = 1; // Create new key-value pair for spe_name with count 1
            }

            // Update main_json with the modified availability group
            main_json[student_availability] = availabilityGroup;
        });


        // 7. Calculate counts for remaining availabilities
        const remainingAvailability = till_top_6.at(-1)?.student_availability - 1;  // Get the lowest availability from remaining
        console.log("remaining :", till_top_6.at(-1));
        const remainingCount = remainingStudents.length;
        main_json[`${remainingAvailability} or less`] = { ...spe_json, 'No of Classes': `${remainingAvailability ?? 0} or less` };

        remainingStudents.forEach((student) => {
            const { student_availability, spe_name } = student;
            // Find the corresponding availability group in main_json
            const availabilityGroup = main_json[`${remainingAvailability} or less`] || {};
            // Check if the spe_name already exists in the availability group
            if (availabilityGroup[spe_name]) {
                availabilityGroup[spe_name]++; // Increment existing count
            } else {
                availabilityGroup[spe_name] = 1; // Create new key-value pair for spe_name with count 1
            }
            // Update main_json with the modified availability group
            main_json[`${remainingAvailability} or less`] = availabilityGroup;
        });

        main_json['Not yet submitted'] = { ...spe_json, 'No of Classes': 'Not yet submitted' };

        nullStudentsData.forEach((student) => {
            const { student_availability, spe_name } = student;
            // Find the corresponding availability group in main_json
            const availabilityGroup = main_json['Not yet submitted'] || {};
            // Check if the spe_name already exists in the availability group
            if (availabilityGroup[spe_name]) {
                availabilityGroup[spe_name]++; // Increment existing count
            } else {
                availabilityGroup[spe_name] = 1; // Create new key-value pair for spe_name with count 1
            }
            // Update main_json with the modified availability group
            main_json['Not yet submitted'] = availabilityGroup;
        })


        // 8. Calculate 'Total' for each key in main_json
        for (const availability in main_json) {
            const availabilityData = main_json[availability];
            const totalCount = Object.values(availabilityData)
                .reduce((sum, value) => {
                    console.log(".reduce ~ value:", value)
                    // Check if value is a number before adding
                    if (typeof value === 'number') {
                        return sum + value;
                    }
                    return sum; // Ignore string values
                }, 0)
                - (typeof availabilityData["No of Classes"] === "number" ? (availabilityData["No of Classes"] || 0) : 0);   // only when no of classes is a number then subtract
            availabilityData.Total = totalCount;
        }


        const returning = Object.values(main_json).sort((a, b) => {
            const aValue = Number(a['No of Classes']);
            const bValue = Number(b['No of Classes']);

            // Check if both values are numbers before sorting
            if (!isNaN(aValue) && !isNaN(bValue)) {
                return bValue - aValue; // Sort in descending order
            } else {
                // If one or both values are not numbers, maintain the original order
                return 0;
            }
        });
        return returning;
    }


    function sumValuesByKey(jsonArray, key) {
        let sum = 0;
        for (let i = 0; i < jsonArray.length; i++) {
            const jsonObj = jsonArray[i];
            if (jsonObj.hasOwnProperty(key)) {
                sum += parseFloat(jsonObj[key]) || 0;
            }
        }
        return sum;
    }

    // const fetchStudentAvailabilityDeadline = async () => {
    //     try {
    //         const response = await service.get(`/os/superadmin/properties?process_id=${process_id}&reqParams=["student_availability_deadline"]`)
    //         return response?.data ?? null;
    //     } catch (error) {
    //         console.error("Error fetching student availability:", error);
    //     }
    // }


    return (
        <Box sx={{ width: '100%', gap: '32px', marginTop:"24px" }}>
            <Box sx={{ marginBottom:"24px", display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '16px' }}>
                <span style={{ fontSize: '24px', fontWeight: 500 }}>Student Availability</span>
                <span style={{ fontSize: '16px', }}>
                Deadline: { formatConvertermonthfirst(mentorInterestData.student_availability_deadline)}, {convertEpochToNormalTime( mentorInterestData.student_availability_deadline)}
                    {/* {formatConvertermonthfirst(mentorInterestData.student_availability_deadline, false, true)} */}
                </span>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
                <span style={{ fontSize: '24px', }}>Summary of responses</span>
                <Button
                    //  onClick={() => scrollToTargetId('StudentsList')} 
                    variant='text' sx={{ color: Color.primary1, textTransform: 'none' }}>View students</Button>
            </Box>
            <Box sx={{ gap: '16px', width: '100%', }}>
                <Box sx={{ ...DivCenter, width: '100%', gap: '16px', minHeight: '76px', background: 'rgba(249, 253, 254, 1)' }}>
                    {/* <Typography style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>No of Classes</Typography> */}
                    {Object.keys(speJSON).map((spe_name, spe_index) =>
                        <Typography key={spe_index} style={{ fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>{spe_name}</Typography>
                    )}
                </Box>
                <Box sx={{ ...DivCenter, flexDirection: 'column', justifyContent: 'flex-start', width: '100%', gap: '24px' }}>
                    {classesData.length > 0 && classesData.map((availability, availability_index) =>
                        <Box key={availability_index} sx={{ ...DivCenter, flexDirection: 'row', justifyContent: 'flex-start', width: '100%', gap: '16px', background: availability_index % 2 ? 'rgba(251, 251, 251, 1)' : '', minHeight: '76px', }}>
                            {Object.keys(availability).map((spe, spe_index) =>
                                <Typography key={spe_index} style={{ color: Color.primary1, fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>
                                    {/* {availability[spe] === 0 ? '-' : availability[spe]} */} {availability[spe]}
                                </Typography>
                            )}
                        </Box>
                    )}
                    <Box sx={{ ...DivCenter, flexDirection: 'row', justifyContent: 'flex-start', width: '100%', gap: '16px', minHeight: '76px', background: classesData.length % 2 ? 'rgba(251, 251, 251, 1)' : '', }}>
                        <Typography style={{ color: Color.primary1, fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>
                            Total
                        </Typography>
                        {Object.keys(speJSON).filter((spe) => spe !== 'No of Classes').map((spe, spe_index) =>
                            <Typography style={{ color: Color.primary1, fontSize: '18px', fontWeight: 500, width: '100%', textAlign: 'center' }}>
                                {sumValuesByKey(classesData, spe)}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Box>
            <StudentAvailabilityModule
                orderDetails={orderDetails}
                setOrderDetails={setOrderDetails}
                getStudentDetails={getStudentDetails}
                process_id={process_id}
                mentorInterestData={mentorInterestData}
            />

        </Box>)
}

export const StudentAvailabilityModule = ({ orderDetails, setOrderDetails, getStudentDetails, process_id, mentorInterestData }) => {

    const [loading, setLoading] = useState({ activity: false });
    const service = new CCService();
    //data variables
    const [searchTerm, setSearchTerm] = useState('');
    const [filterButtons, setFilterButtons] = useState({
        'All': false,
        'Yet to submit': true,
        '9 Classes': false,
        '8 Classes': false,
        '7 Classes': false,
        'HR': false,
        'Finance': false
    });
    const [filteredOrderDetails, setFilteredOrderDetails] = useState([...orderDetails]);
    const slotCount = (mentorInterestData.session_date_slot_count_mapping.map((slot) => slot?.session_date));
    //MODALS
    const [SAModalData, setSAModalData] = useState({
        open: false,
        onClose: () => setSAModalData((prev) => ({ ...prev, open: false })),
        extraData: {}
    });


    //FUNCTIONS
    useEffect(() => {
        handleFilterEffect();
    }, [filterButtons['All'], filterButtons['Yet to submit'], filterButtons['9 Classes'], filterButtons['8 Classes'], filterButtons['7 Classes'], filterButtons['HR'], filterButtons['Finance']]);

    const scrollToTargetId = (targetId) => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            const yOffset = -150; // Adjust this value to offset the scroll position if needed
            const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    const handleFilterEffect = () => {
        let flag = '';
        for (const key in filterButtons) {
            if (filterButtons[key] === true) flag = key;
        }
        handleFilterButton(flag, false);
    }

    const handleChangeSearch = (e, newValue) => {
        console.log("handleChangeSearch ~ newValue:", e.target.value, newValue)
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        if (searchTerm) {
            const filtered = SearchFunction(orderDetails, ['first_name', 'last_name', 'email_id', 'ph_no', 'spe_name', 'student_availability'], searchTerm);
            console.log("handleChangeSearch ~ filtered:", filtered)
            setFilteredOrderDetails(filtered);
        }
        else {
            setFilteredOrderDetails([...orderDetails]);
        }
    }


    const handleAvailabilityModal = (student) => {
        console.log("handleAvailabilityModal ~ student:", student)
        setSAModalData((prev) => ({
            ...prev,
            open: true,
            extraData: {
                ...prev.extraData,
                first_name: student.first_name,
                last_name: student.last_name,
                student: student
            }
        }))
    }



    const handleFilterButton = (flag, scroll) => {
        let temp_filteredData = [...orderDetails];
        const temp_filter = {};
        for (let key in filterButtons) {
            if (key === flag) temp_filter[key] = true;
            else temp_filter[key] = false;
        }
        setFilterButtons(temp_filter);
        if (flag === 'All') {
            temp_filteredData = [...orderDetails];
        } else if (flag === 'Yet to submit') {
            temp_filteredData = temp_filteredData.filter((student) => student.student_availability === null);
        } else if (flag === '9 Classes') {
            temp_filteredData = temp_filteredData.filter((student) => student.student_availability === 9);
        } else if (flag === '8 Classes') {
            temp_filteredData = temp_filteredData.filter((student) => student.student_availability === 8);
        } else if (flag === '7 Classes') {
            temp_filteredData = temp_filteredData.filter((student) => student.student_availability === 7);
        } else if (flag === 'HR') {
            temp_filteredData = temp_filteredData.filter((student) => student.spe_name === 'HR');
        } else if (flag === 'Finance') {
            temp_filteredData = temp_filteredData.filter((student) => student.spe_name === 'Finance');
        }
        setFilteredOrderDetails(temp_filteredData);
        if (scroll) scrollToTargetId('StudentsList');
    }


    function AddStudentAvailability({ open, onClose, extraData }) {
        //Data Variables
        const [formInputs, setFormInputs] = useState({
            no_classes: 0,
            student_availability: extraData.student?.student_availability,
            student_availability_proof: extraData.student?.student_availability_proof
        });
        const [loading, setLoading] = useState({ upload: false, button: false, })

        //FUNCTIONS
        const handleChangeStudentAvailability = ({ target }) => {
            const { name, value } = target;
            setFormInputs((prev) => ({ ...prev, [name]: value }));
        }

        const handleNoClassesChange = ({ target }) => {
            const { checked } = target;
            console.log("handleNoClassesChange ~ checked:", checked)
            setFormInputs((prev) => ({
                ...prev,
                no_classes: checked ? 1 : 0,
                student_availability: checked ? '' : prev.student_availability,
                student_availability_proof: checked ? null : prev.student_availability_proof
            }));
        }

        const handleFileChange = async (event) => {
            const files = event.target.files;
            const { name } = event.target;
            console.log("files...", files);
            if (files) {
                setLoading((prev) => ({ ...prev, upload: true }));
                try {
                    const fileUrl_response = await uploadFile(files, name);
                    console.log("fileUrl_response:", fileUrl_response);
                    let temp_formInput = { ...formInputs };
                    if (fileUrl_response?.student_availability_proof) {
                        temp_formInput.student_availability_proof = fileUrl_response.student_availability_proof
                    }
                    setFormInputs(temp_formInput);
                    setLoading((prev) => ({ ...prev, upload: false }));
                } catch (error) {
                    console.error("Error handling image upload:", error);
                    setLoading((prev) => ({ ...prev, upload: false }));
                }
            }
        };

        const uploadFile = async (selectedFileName, key) => {
            console.log("selectedFileName", selectedFileName[0]);
            const headers = {
                Authorization: `Bearer ${Cookies.get("token")}`,
                "Content-Type": "multipart/form-data",
            };

            const blob_image = new Blob([selectedFileName[0].name], {
                type: 'application/pdf'
            });

            const image_data = new FormData();
            if (key.toLowerCase() === 'other_docs')
                image_data.append(
                    key + ("_" + formInputs.other_docs.length + 1) + `${selectedFileName[0].name}`,
                    blob_image,
                    `${selectedFileName[0].name}`
                );
            else image_data.append(
                key + `${selectedFileName[0].name}`,
                blob_image,
                `${selectedFileName[0].name}`
            );

            try {
                const imageRes = await axios.post(
                    `${process.env.REACT_APP_API_URL}/os/superadmin/order/file?user_id=${extraData.student.user_id}`,
                    image_data,
                    { headers }
                );
                console.log("uploadFile ~ imageRes:", imageRes)
                if (imageRes?.data) return imageRes.data;
                else return null;

            } catch (err) {
                console.error("Error uploading image:", err);
                // Handle the error as needed
                return null; // or throw an error
            }

        }


        const handleFileRemove = () => {
            setFormInputs((prev) => ({ ...prev, student_availability_proof: null }))
        }

        const handleSubmitStudentAvailability = async () => {
            try {
                let body;
                if (formInputs.no_classes) {
                    body = {
                        "order_id": extraData.student.id,
                        // "spe_id": extraData.student.spe_id,
                        "student_availability": 0,
                    }
                } else {
                    body = {
                        "order_id": extraData.student.id,
                        // "spe_id": extraData.student.spe_id,
                        "student_availability": parseInt(formInputs.student_availability),
                        "student_availability_proof": formInputs?.student_availability_proof ?? undefined
                    }
                }
                await service.put(`/os/superadmin/studentAvailibility`, body);
                onClose();
                const data = await getStudentDetails();
                setOrderDetails(data);
                setFilteredOrderDetails(data);
            } catch (error) {
                console.error("Error while putting student availability:", error);
            }
        }


        return (
            <Modal open={open} onClose={loading.button ? {} : onClose}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "white",
                    padding: "1.5rem 2rem",
                    borderRadius: "20px",
                    width: 'auto',
                    minWidth: "800px",
                    height: "auto",
                    minHeight: '250px',
                    justifyContent: 'flex-start',
                    alignItems: 'space-between',
                    height: 'auto',
                    minHeight: '250px'
                }}>
                    <Box sx={{ ...DivCenter, flexDirection: 'column', height: '20%', justifyContent: 'flex-start', }}>
                        <Typography style={{ width: '100%', textAlign: 'center', fontSize: '24px' }}>
                            Student details
                        </Typography>
                        <Typography style={{ width: '100%', textAlign: 'center', fontSize: '16px' }}>
                            {extraData.first_name + " " + (extraData.last_name ?? "")}
                        </Typography>
                    </Box>
                    <Box sx={{ ...DivCenter, flexDirection: 'column', width: '100%', height: '80%', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '16px' }}>
                        <Box sx={{ ...DivCenter, width: '100%', flexDirection: 'column', justifyContent: 'flex-start' }}>
                            <FormControlLabel control={<Checkbox checked={formInputs.no_classes}
                                onChange={handleNoClassesChange} sx={{ color: Color.neutralMidGrey }} />} label={<span style={{ color: Color.neutralBlack }}>I have no classes</span>} sx={{ width: '100%' }} />
                            <Typography style={{ width: '100%', color: Color.neutralMidGrey, fontSize: '12px' }}>Check this box, if you’ve no classes.</Typography>
                        </Box>
                        {!formInputs.no_classes ?
                            <>
                                <Box sx={{ ...DivCenter, width: '100%', flexDirection: 'row', alignItems: 'flex-start' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '50%', gap: '8px' }}>
                                        <Typography style={{ width: '100%', fontSize: '16px' }}>No of classes ({slotCount.map((session_date, session_index) => formatConvertermonthfirst(session_date, false, false, false) + (slotCount.length - 1 === session_index ? "" : ", "))}):</Typography>
                                        <TextField name='student_availability' type='number' value={formInputs.student_availability} onChange={handleChangeStudentAvailability} />
                                    </Box>
                                    <Box sx={{ ...DivCenter, alignItems: 'flex-end', flexDirection: 'column', width: '50%', }}>
                                        <Typography style={{ fontSize: '18px', }}>{extraData.sku_name ?? 'SKU'}</Typography>
                                        <Typography style={{ color: Color.neutralMidGrey, fontSize: '12px' }}>{(extraData.college_name ?? 'College') + ", " + (extraData.batch_name ?? 'Batch') + ", " + (extraData.batch_type ?? "Batch Type") + ", "}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start', gap: '16px' }}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 500 }}>Upload the schedule (Optional)</Typography>
                                    {!formInputs.student_availability_proof?.name ?
                                        <Box onClick={() => {
                                            document.getElementById('fileInput').click();
                                        }} sx={{ ...DivCenter, flexDirection: 'column', width: '100%', height: '200px', borderRadius: '8px', background: 'rgba(244, 244, 244, 1)', cursor: 'pointer', gap: '8px' }}>
                                            {loading.upload ?
                                                <CircularProgress size={24} sx={{ color: Color.primary1 }} />
                                                : <>
                                                    <FilePresentOutlined sx={{ width: '33px', height: '33px', color: 'rgba(90, 90, 90, 1)' }} />
                                                    <input
                                                        name='student_availability_proof'
                                                        type="file"
                                                        accept=".pdf"
                                                        multiple
                                                        onChange={(e) => handleFileChange(e)}
                                                        style={{ display: 'none' }}
                                                        id="fileInput"
                                                    />
                                                    <Typography style={{ fontSize: '16px', fontWeight: 500 }}>Upload the schedule</Typography>
                                                </>}
                                        </Box>
                                        : <Box sx={{ ...DivCenter, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', height: '200px', borderRadius: '8px', cursor: 'pointer', gap: '8px' }}>
                                            <Box className='box__fileUpload' sx={{ width: '100%', }}>
                                                <a href={formInputs.student_availability_proof?.url ? formInputs.student_availability_proof?.url : "#"} target="_blank" style={{ textDecoration: "none" }}>
                                                    <Typography sx={{ ml: '5px', textTransform: 'none', color: Color.neutralBlack, fontSize: '14px' }}>
                                                        {formInputs.student_availability_proof.name}
                                                    </Typography>
                                                </a>
                                                <DeleteOutlined onClick={() => handleFileRemove()} sx={{ cursor: 'pointer' }} />
                                            </Box>
                                        </Box>}
                                </Box>
                            </>
                            : <></>
                        }
                        <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between' }}>
                            <Button variant='outlined' onClick={onClose} sx={{ ...styles.button_styles.outlined, width: '45%' }}>Cancel</Button>
                            <Button variant='contained' onClick={handleSubmitStudentAvailability} sx={{ ...styles.button_styles.contained, width: '45%' }}>Submit</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal >
        )
    }


    return (

        loading.activity ?
            <Loader show={loading.activity} /> :
            <Box sx={{ pt: '48px', width: '100%', }}>
                <Box sx={{ ...DivCenter, width: '100%', mb: '16px' }}>
                    <TextField
                        name="search_colleges"
                        // label="Search Student"
                        placeholder='Search Students'
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleChangeSearch}
                        fullWidth
                        sx={{ width: '90%', borderRadius: "12px" }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search sx={{ color: Color.neutralMidGrey }} />
                                </InputAdornment>
                            ),
                            sx: { borderRadius: "12px", height: "58px" }
                        }}
                    />
                    <Box sx={{ width: '10%', minWidth: '121px' }}>
                        <IconButton><AddOutlined /></IconButton>
                        <IconButton><GetApp /></IconButton>
                        <IconButton><UploadFile /></IconButton>
                    </Box>
                </Box>
                <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-start', gap: '8px', mb: '24px' }}>
                    {Object.keys(filterButtons).map((filter) => <Button onClick={() => { handleFilterButton(filter, true); }} sx={{
                        ...styles.filter_buttons,
                        background: filterButtons[filter] ? 'rgba(195, 236, 245, 1)' : Color.white,
                    }}>{filter}</Button>)}
                </Box>
                <Box id="StudentsList" sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', background: 'rgba(195, 236, 245, 0.1)', minHeight: '113px', p: '16px' }}>
                    <Box sx={{ ...DivCenter, width: filterButtons['Yet to submit'] ? '23.33%' : '25%', justifyContent: 'center' }}>
                        <Typography style={{ fontSize: '22px', color: Color.primary1, }}>Student</Typography>
                    </Box>
                    <Box sx={{ ...DivCenter, width: filterButtons['Yet to submit'] ? '53.33%' : '25%', }}>
                        <Typography style={{ fontSize: '22px', color: Color.primary1, }}>Specialization</Typography>
                    </Box>
                    {!filterButtons['Yet to submit'] ? <Box sx={{ ...DivCenter, width: filterButtons['Yet to submit'] ? '53.33%' : '25%', }}>
                        <Typography style={{ fontSize: '22px', color: Color.primary1, }}>No of Classes</Typography>
                    </Box> : <></>}
                    {filterButtons['Yet to submit'] ?
                        <Box sx={{ ...DivCenter, width: filterButtons['Yet to submit'] ? '23.33%' : '25%', justifyContent: 'center' }}>
                            <Typography style={{ fontSize: '22px', color: Color.primary1, }}>Update</Typography>
                        </Box>
                        : <Box sx={{ ...DivCenter, width: filterButtons['Yet to submit'] ? '23.33%' : '25%', justifyContent: 'center' }}>
                            <Typography style={{ fontSize: '22px', color: Color.primary1, }}>Proof</Typography>
                        </Box>}
                </Box >
                {filteredOrderDetails.map((student, student_index) =>
                    <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'space-between', background: student_index % 2 ? 'rgba(251, 251, 251, 1)' : Color.white, minHeight: '113px', p: '16px' }}>
                        <Box sx={{ ...DivCenter, flexDirection: 'column', alignItems: 'center', gap: '8px', width: filterButtons['Yet to submit'] ? '23.33%' : '25%' }}>
                            <Typography style={{ color: Color.primary1, fontSize: '18px' }}>{student.first_name + " " + (student.last_name ?? '')}</Typography>
                            <Typography style={{ color: Color.neutralMidGrey, fontSize: '16px' }}>{student.email_id}</Typography>
                            <Typography style={{ color: Color.neutralMidGrey, fontSize: '16px' }}>{student.ph_no}</Typography>
                        </Box>
                        <Box sx={{ ...DivCenter, width: filterButtons['Yet to submit'] ? '53.33%' : '25%' }}>
                            <Typography style={{ textAlign: 'center', color: Color.neutralMidGrey, fontSize: '16px' }}>
                                {student.spe_name}
                            </Typography>
                        </Box>
                        {!filterButtons['Yet to submit'] ?
                            student.student_availability !== null ? <Box sx={{ ...DivCenter, flexDirection: 'column', width: '25%' }}>
                                <Typography style={{ textAlign: 'center', color: Color.neutralMidGrey, fontSize: '16px' }}>{student.student_availability}</Typography>
                                <Typography onClick={() => handleAvailabilityModal(student)} style={{ textAlign: 'center', color: Color.primary1, fontSize: '16px', cursor: 'pointer' }}>Edit</Typography>
                            </Box>
                                : <Box sx={{ ...DivCenter, flexDirection: 'column', width: '25%' }}>
                                    <Button onClick={() => handleAvailabilityModal(student)} sx={{ ...styles.button_styles.outlined, background: 'rgba(195, 236, 245, 1)', '&:hover': { background: 'rgba(147, 211, 231, 1)', } }}>
                                        Add availability
                                    </Button>
                                </Box>
                            : <></>}
                        {filterButtons['Yet to submit'] ?
                            <Box sx={{ ...DivCenter, flexDirection: 'column', alignItems: 'center', width: filterButtons['Yet to submit'] ? '23.33%' : '25%', }}>
                                <Box sx={{ ...DivCenter, gap: '16px' }}>
                                    {!student.student_availability ? <>
                                        <Button onClick={() => handleAvailabilityModal(student)} sx={{ ...styles.button_styles.outlined, background: 'rgba(195, 236, 245, 1)', '&:hover': { background: 'rgba(147, 211, 231, 1)', } }}>Add availability</Button>
                                    </>
                                        : <Box sx={{ ...DivCenter, }}>
                                            <Typography style={{ fontSize: '20px', color: Color.neutralMidGrey, background: '', padding: '3px 5px' }}>
                                                {student.student_availability}
                                            </Typography>
                                            <span>
                                                <Tooltip title='Edit Availability' placement='top'>
                                                    <IconButton onClick={() => handleAvailabilityModal(student)}>
                                                        <EditOutlined sx={{ fontSize: '20px', color: Color.neutralMidGrey, }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </span>
                                        </Box>}
                                </Box>
                            </Box>
                            : <Box sx={{ ...DivCenter, flexDirection: 'column', alignItems: 'center', width: filterButtons['Yet to submit'] ? '23.33%' : '25%', }}>
                                {/* <IconButton onClick={window.open(student.student_availability_proof)}><AttachFile /></IconButton> */}
                                {student?.student_availability_proof?.url ?
                                    <Tooltip title='Open Attachment' placement='top'>
                                        <a href={student?.student_availability_proof?.url ? student.student_availability_proof.url : "#"} target="_blank" style={{ textDecoration: "none" }}>
                                            <span style={{ color: Color.neutralMidGrey }}><AttachFile /></span>
                                        </a>
                                    </Tooltip>
                                    : <Tooltip title='No attachment available' placement='top'><span style={{ color: Color.neutralMidGrey, transform: 'rotate(90deg)' }}><LinkOff /></span></Tooltip>}
                            </Box>}
                    </Box>)
                }
                {/* MODALS */}
                <AddStudentAvailability
                    open={SAModalData.open}
                    onClose={SAModalData.onClose}
                    extraData={SAModalData.extraData}
                /></Box >
    )
}