import React from 'react'
import { Modal, Box } from '@mui/material'
import GroupChat from '../ClosedForApplication/GroupChat';

const GroupChatModal = (props) => {
    return (
        <div>
            <Modal open={props.modalOpen} onClose={props.handleModalClose}>
                <Box sx={{ overflowY: 'auto', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'white', padding: '2rem', borderRadius: "40px", display: 'flex', flexDirection: "column", maxHeight: "1000px", maxWidth: "850px" }}>
                    <GroupChat job_id={props.job_id} review={props.review} path="OtherSource"/>
                </Box>
            </Modal>
        </div>
    )
}

export default GroupChatModal
