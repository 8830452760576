import { Autocomplete, Box, Button, Chip, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Color, DivCenter } from "../../../../GlobalStyles";
import "./POBasicInfo.css";
import { Add } from "@mui/icons-material";
import NewCompany from "../../Company/NewCompany";
import { useSelector } from "react-redux";

const POBasicInfo = ({ basicInfoData, setBasicInfoData, formErrors, allCompanies, college_id, setAllCompanies }) => {
  const { accessRights } = useSelector((store) => store.rights);
  const handleFormChange = ({ target }) => {
    const { name, value } = target;

    setBasicInfoData((prev) => ({ ...prev, [name]: value }));

    if (name === "company_id") {
      let temp_image = allCompanies.find((item) => item.id === value);
      // if (temp_image !== null) {
      setBasicInfoData((prev) => ({ ...prev, company_image: temp_image.company_image, company_name: temp_image.company_name }));
    }
  };

  const [companyModal, setCompanyModal] = useState(false);

  return (
    <Box sx={{ ...DivCenter, flexDirection: "column", width: "70%", gap: "40px" }}>
      <TextField className="textfield__basicInfo" name="designation" label="Profile (Designation)" required value={basicInfoData.designation} onChange={(e) => handleFormChange(e)} error={formErrors.designation}></TextField>
      <hr style={{ width: "100%" }} />
      {/* <TextField className="textfield__basicInfo" name="company_name" label="Company Name" required value={basicInfoData.company_name} onChange={(e) => handleFormChange(e)} error={formErrors.company_name}></TextField> */}

      {/* 
      <TextField className="textfield__basicInfo" name="company_location" label="Company Location" value={basicInfoData.company_location} onChange={(e) => handleFormChange(e)} error={formErrors.company_location}></TextField> */}
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: "2px" }}>
          {accessRights.find((item) => item.access_right_id === 8)?.is_activated === 1 && (
            <Button sx={{ color: Color.primary1, textTransform: "none" }} onClick={() => setCompanyModal(true)}>
              <Add sx={{ padding: "2px", background: Color.primary1, color: Color.white, marginRight: "5px", borderRadius: "50%", fontSize: "20px" }} /> Add New Company
            </Button>
          )}
        </Box>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Company</InputLabel>
          <Select labelId="demo-simple-select-label" id="demo-simple-select" name="company_id" value={basicInfoData.company_id} label="Company" onChange={(e) => handleFormChange(e)}>
            {allCompanies.map((item, index) => (
              <MenuItem value={item.id}>{item.company_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Typography>Location * </Typography>
        <Autocomplete
          multiple
          fullWidth
          autoComplete="off"
          placeholder="Location"
          options={[]}
          value={basicInfoData.company_location}
          freeSolo
          filterOptions={(options, params) => {
            const filtered = options.filter((option) => option.toLowerCase().includes(params.inputValue.toLowerCase()));

            // Add the input value as an option if it doesn't exist
            if (params.inputValue !== "" && !filtered.includes(params.inputValue)) {
              filtered.push(params.inputValue);
            }

            return filtered;
          }}
          renderTags={(value, getTagProps) => value.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)}
          renderInput={(params) => <TextField {...params} />}
          getOptionLabel={(option) => option}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(e, values) => {
            setBasicInfoData((prev) => ({ ...prev, company_location: values }));
          }}
        />
      </Box>

      <TextField
        className="textfield__basicInfo"
        name="date_of_visit"
        label="Date of Visit"
        type="date"
        value={basicInfoData.date_of_visit}
        onChange={(e) => handleFormChange(e)}
        InputLabelProps={{
          shrink: true,
        }}
      ></TextField>

      <Modal open={companyModal} onClose={() => setCompanyModal(false)}>
        <NewCompany college_id={college_id} close={() => setCompanyModal(false)} setAllCompanies={setAllCompanies} />
      </Modal>
    </Box>
  );
};

export default POBasicInfo;
