import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Modal, Checkbox, IconButton, InputAdornment, Tooltip, Box } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Color } from '../../../GlobalStyles';
import { useSelector } from 'react-redux';

const HiringWorkFlow = (props) => {
    const {hiringWorkflowItems, handleEnterPressed, handleDeleteHiringWorkflowItem, handleSaveHiringWorkflowItem,handleAddHiringWorkflowItem,editHiringWorkflowId,hiringWorkflowName, setHiringWorkflowName} = props

    const { accessRights,tooltipText} = useSelector((store) => store.rights)
    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Round</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {hiringWorkflowItems?.map((item) => (
                            <TableRow key={item.round_no}>
                                <TableCell>{item.round_no}</TableCell>
                                <TableCell onKeyDown={(e) => handleEnterPressed(e)}>
                                    {item.round_no === editHiringWorkflowId ? (
                                        <TextField
                                            value={hiringWorkflowName}
                                            onChange={(e) => setHiringWorkflowName(e.target.value)}
                                            variant="outlined"
                                        />
                                    ) : (
                                        item.round_name
                                    )}
                                </TableCell>
                                <TableCell>
                                    {item.round_no === editHiringWorkflowId ? (
                                        <Button style={{ textTransform: "none" }} onClick={handleSaveHiringWorkflowItem}>Save</Button>
                                    ) : (
                                        <>
                                            {/* <Button onClick={() => handleEditItem(item.shortlist_no)}>Edit</Button> */}
                                            <Button onClick={() => handleDeleteHiringWorkflowItem(item.round_no)}><DeleteOutlinedIcon /></Button>
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
          
            <div>
            <Tooltip title={accessRights.find((item) => item.access_right_id === 18)?.is_activated===1 ? '' : tooltipText} placement='bottom-start'>
               <Box>
               <Button className='Previousbutton' sx={{ border: "none", background: "transparent", textTransform:"none",color:Color.primary1, marginTop:"10px","&:disabled":{background: Color.neutralMidGrey, color: "#000", } }} onClick={handleAddHiringWorkflowItem} disabled={accessRights.find((item) => item.access_right_id === 18)?.is_activated===1 ? false: true}>
                    Add new round
                </Button>
               </Box>
            </Tooltip>
            </div>
        </div>
    )
}

export default HiringWorkFlow
