import React, { useState } from "react";
import { Box, Button, Input, Tooltip, Typography, Modal, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import Checkbox from "@mui/material/Checkbox";
import { textAlign } from "@mui/system";
import { Color } from "../../../GlobalStyles";
import { motion } from "framer-motion";
import { Notifications } from "@mui/icons-material";
const CloseApplicationModal = (props) => {
  const { postingOpportunityDetails } = props;
  const [isHovered, setIsHovered] = useState(false);
  console.log("postingOpportunityDetails in modal", postingOpportunityDetails);
  return (
    <div>
      <Modal open={props.modalOpen} onClose={props.handleModalClose}>
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", padding: "2rem", borderRadius: "40px", display: "flex", flexDirection: "column" }}>
          <Typography sx={{ alignItems: "left", justifyContent: "left", textAlign: "left", paddingBottom: "1rem", marginBottom: "1rem", borderBottom: `1px solid ${Color.neutralDarkGrey}`, fontSize: "20px" }}>{props.type ? "Close this Application?" : "Close this opportunity?"}</Typography>
          <Typography sx={{ marginBottom: "1rem", width: "550px" }}>{props.type ? "After closing this application, students of this of this batch will NOT be able to apply for this opportunity. You can re-open an opportunity for applications in future after closing it " : "Closing this opportunity will mark it as complete. You'll still be able to roll out offers to students from the latest shortlist after marking this opportunity as complete. "} </Typography>
          <Box style={{ marginTop: "2rem", position: "relative" }}>
            <Box
              sx={{ padding: "16px", border: `1px dashed ${Color.neutralDarkGrey}`, background: "#fff", "&:hover": { boxShadow: "4px 8px 15.9px 6px rgba(0, 0, 0, 0.15)" }, zIndex: "999" }}
              onMouseEnter={(e) => {
                setIsHovered(true);
              }}
              onMouseLeave={(e) => {
                setIsHovered(false);
              }}
            >
              <Box className="PreviousAndSubmit">
                <Box style={{ textAlign: "center", background: Color.neutralMidGrey, padding: "2px 3px", borderRadius: "8px", marginRight: "5px" }}>
                  <Notifications sx={{ color: Color.white, transform: "rotate(30deg)", fontSize: "20px" }} />
                </Box>
                <Typography color={Color.neutralMidGrey}>Notify students about this shortlist?</Typography>
              </Box>
              <Box className="PreviousAndSubmit">
                <Checkbox disabled />
                <Typography class="alignInCenter" style={{ color: Color.neutralMidGrey }}>
                  Only Eligible Students
                </Typography>
                <Checkbox disabled />
                <Typography class="alignInCenter" style={{ color: Color.neutralMidGrey }}>
                  Entire Batch
                </Typography>
              </Box>
            </Box>

            <Box sx={{ zIndex: "1" }}>
              {isHovered && (
                <motion.div style={{ display: "flex", gap: "2px", position: "absolute", background: "rgba(232, 80, 91, 1)", padding: "5px 12px", right: 1, borderRadius: "8px 8px 0px 0px", zIndex: "-10" }} transition={{ duration: 0.5, type: "spring", delay: 0.1 }} initial={{ top: "0px" }} animate={isHovered ? { top: "-30px" } : { top: "0px" }}>
                  <img src="https://ccicons.s3.amazonaws.com/po/eggCracked.svg" style={{ objectFit: "contain", height: "20px", aspectRatio: "1/1" }} />
                  <Typography sx={{ color: Color.white, fontSize: "14px", fontWeight: "600" }}>Cracking Soon!</Typography>
                  <Typography sx={{ color: Color.white, fontSize: "14px" }}>Notification Control Center</Typography>
                </motion.div>
              )}
            </Box>
          </Box>

          <div
            className="PreviousAndSubmit"
            style={{
              justifyContent: "space-between",
              width: "100%",
              display: "flex",
              flexDirection: props.Mobile ? "column" : "row",
            }}
          >
            <button name="previous-button" style={{ margin: "auto", marginTop: "1rem", background: "transparent", height: "40px" }} className="Previousbutton" onClick={() => props.handleModalClose()}>
              Go Back
            </button>
            <button
              name="next-button"
              className="Rephrasebutton"
              style={{
                width: "auto",
                margin: "auto",
                marginTop: "1rem",
                border: "none",
                height: "40px",
              }}
              onClick={() => props.handleCloseApplications()}
            >
              {props.type ? "Close Application" : "Close Opportunity"}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CloseApplicationModal;
