import { EditOutlined } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import { Button, IconButton, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Color } from "../../../GlobalStyles";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { extractDateFromDateString, formatConvertermonthfirst } from "../../../services/specialCharReplace";

const ShortList = (props) => {
  const { poShortListData, expandedAccordionId, handleAccordionChange, handleFullShortlistData, candidateLoading, applicantsList, setPublishConfirmationModal, setValue, allShortlist, setPoShortListData } = props;
  const [editModal, setEditModal] = useState(false);
  const [editItemData, setEditItemData] = useState(null);
  const { accessRights, tooltipText} = useSelector((store) => store.rights)
  const [selectedValue, setSelectedValue] = useState(null);
  const [error, setError] = useState("");
  const service = new CCService();
  const handleUpdateValue = async () => {
    try {
      if (selectedValue !== null) {
        const body = {
          shortlist_id: editItemData.id,
          shortlist_name: selectedValue,
        };
        const updateValue = await service.put("/po/ca/shortlist", body);
        setEditModal(false);
        setPoShortListData((prev) => {
          return prev.map((item) => {
            if (item.id === editItemData.id) {
              return {
                ...item,
                shortlist_name: selectedValue,
              };
            } else {
              return { ...item };
            }
          });
        });
        setError("");
      } else {
        setError("Please choose the Shortlist Name to Update");
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      {poShortListData.length > 0 ? (
        <>
          <div>
            {poShortListData
              .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
              .map((shortListItem, index) => {
                return (
                  <Accordion key={shortListItem.id} expanded={expandedAccordionId === shortListItem.id} onChange={() => handleAccordionChange(shortListItem.id)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${shortListItem.id}-content`} id={`panel${shortListItem.id}-header`}>
                      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                          <span>{shortListItem.shortlist_name}</span>
                           <Tooltip title={accessRights.find((item) => item.access_right_id === 15)?.is_activated===1 ?'': tooltipText }>
                           <Box>
                          <IconButton
                            onClick={() => {
                              setEditItemData(shortListItem);
                              setEditModal(true);
                            }}
                            disabled={accessRights.find((item) => item.access_right_id === 15)?.is_activated===1 ? false: true}
                            sx={{ background: Color.white, color: Color.primary1, padding: "5px", boxShadow: "0px 0.81px 3.02px 0px #00000033", "&:disabled": { background: Color.neutralLightGrey, color:Color.neutralDarkGrey  } }}
                          >
                            <EditOutlined sx={{ fontSize: "20px" }} />
                          </IconButton>
                          </Box>
                           </Tooltip>
                        </Box>
                        <span style={{ color: Color.neutralMidGrey, marginRight: "2rem" }}>Released on {formatConvertermonthfirst(extractDateFromDateString(shortListItem.timestamp))}</span>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {candidateLoading ? (
                        <CircularProgress sx={{ color: "#41B8D5", margin: "0 auto", width: "100%" }} />
                      ) : (
                        expandedAccordionId === shortListItem.id && (
                          <TableContainer
                            component={Paper}
                            sx={{
                              borderBottom: "none",
                              "&:last-child": {
                                borderBottom: "none",
                              },
                            }}
                          >
                            <Table
                              sx={{
                                borderBottom: "none",
                                "&:last-child": {
                                  borderBottom: "none",
                                },
                              }}
                            >
                              <TableBody
                                sx={{
                                  borderBottom: "none",
                                  "&:last-child": {
                                    borderBottom: "none",
                                  },
                                }}
                              >
                                {applicantsList?.slice(0, 4)?.map((list, index) => {
                                  return (
                                    <TableRow
                                      sx={{
                                        borderBottom: "none",
                                        "&:last-child": {
                                          borderBottom: "none",
                                        },
                                      }}
                                      key={list.id}
                                    >
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>{list.full_name}</TableCell>
                                      <TableCell>{list.roll_no}</TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )
                      )}
                      <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
                        <button
                          className="submitBtn"
                          type="button"
                          style={{ borderRadius: "5px", width: "250px", height: "35px", border: `1px solid ${Color.primary1}`, marginRight: "1rem", background: "transparent", color: "black", cursor: "pointer" }}
                          onClick={() => {
                            handleFullShortlistData(shortListItem);
                          }}
                        >
                          View Full Shortlist
                        </button>
                        {index === 0 && (
                          <>
                            <Tooltip title={accessRights.find((item) => item.access_right_id === 16)?.is_activated===1  ? '' : tooltipText}>
                            <Box>
                            <Button
                              disabled={applicantsList.length === 0 || accessRights.find((item) => item.access_right_id === 16)?.is_activated!==1  ? true : false}
                              className="submitBtn"
                              type="button"
                              sx={{ borderRadius: "5px", width: "250px", height: "35px", border: `1px solid ${Color.primary1}`, marginRight: "1rem", background: "transparent", color: "black", textTransform:"none",cursor: "pointer", "&:disabled":{background: Color.neutralLightGrey}}}
                              onClick={() => {
                                setPublishConfirmationModal((prev) => ({ ...prev, open: true, extraData: { value: shortListItem } }));
                              }}
                            >
                              Delete Shortlist
                            </Button>
                            </Box>
                            </Tooltip>
                            <button
                              className="submitBtn"
                              type="button"
                              style={{ borderRadius: "5px", width: "250px", height: "35px", border: `1px solid ${Color.primary1}`, marginRight: "1rem", background: "transparent", color: "black", cursor: "pointer" }}
                              onClick={() => {
                                setValue(3);
                              }}
                            >
                              View Group Chat
                            </button>
                          </>
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </div>
        </>
      ) : (
        <>
          <div className="noUpcomingBookings" style={{ width: "80%", justifyContent: "center", alignItems: "center", margin: "0 auto", marginTop: "1rem" }}>
            <div className="noUpcomingBookingsIcon">
              <PersonOffOutlinedIcon
                sx={{
                  color: "#1C8EA8",
                  width: "50px",
                  height: "50px",
                }}
              />
            </div>
            <div className="noUpcomingBookingsTexts">
              <p className="noUpcomingBookingsText">No Students Shortlisted yet</p>
            </div>
          </div>
        </>
      )}

      <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={editModal} onClose={() => setEditModal(false)}>
        <Box sx={{ padding: "20px", background: "#fff", width: "30vw", borderRadius: "20px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Box>
            <Typography sx={{ fontSize: "22px", fontWeight: 600 }}>Rename {editItemData?.shortlist_name}</Typography>

            <Select
              value={selectedValue}
              sx={{ height: "30px", width: "300px", marginBlock: "1rem" }}
              onChange={(e) => {
                e.stopPropagation();
                setSelectedValue(e.target.value);
              }}
            >
              {allShortlist?.map((item) => (
                <MenuItem key={item} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {error !== "" && <p style={{ color: Color.red }}>{error}</p>}
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", marginTop: "8px" }}>
            <Button
              sx={{ padding: "5px 8px", border: `1px solid ${Color.primary1}`, color: Color.primary1 }}
              onClick={() => {
                setEditModal(false);
                setEditItemData(null);
              }}
            >
              Cancel
            </Button>

            <Button sx={{ padding: "5px 8px", border: `1px solid ${Color.primary1}`, background: Color.primary1, color: Color.white, "&:hover": { background: Color.primary1, color: Color.white } }} onClick={() => handleUpdateValue()}>
              Update
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ShortList;
