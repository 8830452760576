import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { initializeApp } from "firebase/app";
import { getAuth, OAuthProvider, signInWithPopup } from "firebase/auth";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import Alert from "../../components/Modal/Alert";
import CCService from "../../services/httpServiceWithoutSuperAdmin";
// import { useTimer } from "../ResendButton/ResendButton";
import { handleSubmit } from "../Validations/Validations";
import Service from "../../services/httpService";
import "../Login/Login.css";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/reducers";
const Login = () => {
  const navigate = useNavigate();
  const services = new CCService();
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const firebaseConfig = {
    apiKey: "AIzaSyBbrNdmcQY1vAhXy3Zg0gHUJ3zWJCuAbRI",
    authDomain: "careercarvelogin.firebaseapp.com",
    projectId: "careercarvelogin",
    storageBucket: "careercarvelogin.appspot.com",
    messagingSenderId: "151843323088",
    appId: "1:151843323088:web:000a09c7a3d8067ae072d5",
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const [changeTemplate, setChangeTemplate] = useState(false);

  useEffect(() => {
        // setFirst(preUser.data.userDetail.first_name);
    const handleEffect =  async () => {
      const user_type = Cookies.get("user_type");
      const token = Cookies.get("token");
      const headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
      };
      if (
        token !== null &&
        token !== undefined &&
        token !== "null" &&
        token !== "undefined"
      ) {
        navigate("/CollegeInfoPage", {
          state: { formValues: formValues, user_type: user_type },
        });
      }

    };
    handleEffect();
  });

  // handle sign in click
  async function handleSignInClick() {
    setIsLoading(true);
    try {
      const result = await signInWithPopup(
        auth,
        new OAuthProvider("apple.com")
      );
      // The signed-in user info.
      const user = result?.user;
      console.log("apple user deatils", user);
      // Apple credential
      const credential = OAuthProvider?.credentialFromResult(result);
      const accessToken = credential.accessToken;
      console.log("apple accessToken deatils", accessToken);
      console.log("user name", result.user.displayName);
      console.log("user email", result.user.email);

      const idToken = credential.idToken;
      console.log("apple idToken deatils", idToken);
      if (idToken !== null && idToken !== undefined) {
        if (result.user.email !== null && result.user.email !== undefined) {
          const res = await services.post(`/appleAuth`, {
            id_token: idToken,
            signup_platform: "CareerCarve College Admin",
          });

          if (res.status === 200) {
            Cookies.set("token", res.headers.jwt, { expires: 182.5 });
            Cookies.set("user_type", res.data.user_type, { expires: 182.5 });
            console.log("user type right now at login", res.data.user_type);
            navigate("/CollegeInfoPage", {
              state: {
                formValues: formValues,
              },
            });
            // alert.box(<h1>DASHBOARD</h1>);
            console.log("Dashboard");
            setErrMsg("Login successful.");
            const cookies = res.headers.jwt;
            console.log({ cookies });
          }
          console.log(res);
        } else {
          if (result.user.email === null || result.user.email === undefined) {
            toast.error("Email ID is required ");
          }
        }
      }
    } catch (err) {
      if (
        err?.response.status === 400 &&
        err?.response.data.message === "Unverified account"
      ) {
        setErrMsg("This email is Unverified.");
      } else if (
        err?.response.status === 400 &&
        err?.response.data.message ===
        "Seems like your account dosn't exist with us. Please Signup using CareerCarve App."
      ) {
        setChangeTemplate(true);
        setErrMsg(
          "Seems like your account doesn't exist with us. Please Signup using CareerCarve App."
        );
      } else if (
        err?.response.status === 400 &&
        err?.response.data.message === "Alternate email already existing."
      ) {
        setErrMsg("Alternate email already existing.");
      } else if (
        err?.response.status === 400 &&
        err?.response.data.message === "Email already exists as alternate."
      ) {
        setErrMsg(
          "This email already exists as an secondary email. Please login with your primary email."
        );
      } else if (
        err?.response.status === 500 &&
        err?.response.data.message === "Alternate email entry doesn't exists"
      ) {
        setErrMsg("Alternate email entry doesn't exists.");
      }else if (
        err?.response.status === 500 &&
        err?.response.data.message === "Unknown Error Occurred"
      ) {
        setErrMsg(<p>You are not a mentor</p>);
      }
      console.log("apple sign in error", err);
      const errorCode = err.code;
      console.log("apple sign in error", errorCode);

      const errorMessage = err.message;
      console.log("apple sign in error", errorMessage);

      const email = err.customData.email;
      console.log("apple sign in email", email);

      const credential = OAuthProvider.credentialFromError(err);
      console.log("apple sign in credential", credential);
    } finally {
      setIsLoading(false);
    }
  }

  const errRef = useRef();
  const clientId =
    "331878265619-n1b1ia44qjlp90amsdg2trohjqt9m51o.apps.googleusercontent.com";

  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  console.log("isSubmit", formErrors);

  const [errMsg, setErrMsg] = useState("");
  //for validation
  console.log("isSubmit", errMsg);
  const [condition, setCondition] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  console.log("isSubmit", isSubmit);

  // const [showPassword, setShowPassword] = useState(false);
  // const [id_token, setId_token] = useState(null)

  // const handleResend = async e => {
  //   e.preventDefault();
  //   setResendTime(10);
  //   await service
  //     .post(`/resendVerify`, { email: formValues.email })
  //     .then(res => {
  //       if (res.status === 200) {
  //         setErrMsg(
  //           <p>
  //             Link successfully resent to {formValues.email}
  //             <br />
  //             Didn't get the link?
  //             <button
  //               className="signup-button"
  //               type="button"
  //               onClick={e => handleResend(e)}
  //             >
  //               Resend
  //             </button>
  //           </p>
  //         );
  //       }
  //     });
  // };

  const handleFormSubmit = e => {
    console.log("login");
    const signUpButtonText = "Sign up now"; // Replace this with your desired dynamic text
    const endpoint = "/login"; // Replace this with your desired dynamic endpoint
    handleSubmit(
      e,
      formValues,
      setFormErrors,
      setIsSubmit,
      navigate,
      setErrMsg,
      services,
      signUpButtonText,
      endpoint
    );
  };


  const handleChange = async e => {
    const { name, value } = e.target;
    console.log(e.target.value);
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({});
    setErrMsg("");
    console.log("email", formValues.email);
    console.log("password", formValues.password);
  };

  const alertResponse = () => {
    navigate("/SignUp");
  };

  const handleForgotPassword = e => {
    setCondition("ForgotPassword");
    navigate("/ForgotPassword", { state: { condition: "ForgotPassword" } });
  };
  console.log(condition);

  const text_alignment = "center";
  const googleButtonWidth = 465;

  return (
    <div className="login-Container">
      <ToastContainer position="top-center" />
      <div className="login-Resumes_Image">
        <img
          className="imageFlexible"
          src="backgroundImage.png"
          alt="background"
          style={{
            backgroundRepeat: "none",
            float: "left",
            margin: "0",
            width: "100%",
            height: "100%",
            position: "static",
          }}
        />
        <img
          className="imageFlexible"
          src="logo_welcome.png"
          alt="background"
          style={{
            position: "absolute",
            height: "150px",
            width: "150px",
            top: "max(30%,200px)",
            left: "20%",
          }}
        />
        <img
          src="CareerCarve.png"
          alt="background"
          style={{
            position: "absolute",
            top: "max(52%,350px)",
            left: "13%",
          }}
        />
        <p
          style={{
            position: "absolute",
            top: "max(62%, 430px)",
            left: "13.5%",
            fontSize: "20px",
          }}
        >
          {" "}
          Placement | Preparedness | Perfected
        </p>
      </div>
      <div className="login-Main-content">
        <p className="login-name">Login</p>
        <div className="login-l-form">
          <form action="" class="login-form">
            <div className="outlined-basic">
              <TextField
                className=""
                onChange={handleChange}
                value={formValues.email}
                name="email"
                id="outlined-basic"
                label="Email "
                variant="outlined"
                sx={{ width: 400, mb: 3 }}
                InputProps={{ sx: { height: 50.4 } }}
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  borderRadius: "5px",
                }}
              />
              <br />
              {/* <TextField
                onChange={handleChange}
                value={formValues.password}
                name="password"
                id="outlined-basic"
                label="Password"
                variant="outlined"
                sx={{ width: 400, mb: 3 }}
                InputProps={{ sx: { height: 50.4 } }}
              /> */}
              <FormControl
                sx={{ width: 400, mb: 3 }}
                InputProps={{ sx: { height: 50.4 } }}
                variant="outlined"
                name="password"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  onChange={handleChange}
                  value={formValues.password}
                  id="outlined-adornment-password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

              <button
                className="Login-button"
                style={{
                  textAlign: "center",
                  marginBottom: "1.5rem",
                  width: "400px",
                }}
                onClick={e => handleFormSubmit(e)}
              >
                Login
              </button>
              <div style={{ textAlign: "left" }}>
                <button
                  type="button"
                  onClick={() => handleForgotPassword()}
                  className="forgotPassword"
                >
                  Forgot Password?
                </button>
              </div>
              <div>
                <p
                  ref={errRef}
                  className="alert-errMsg-button"
                  aria-live="assertive"
                >
                  {errMsg}
                </p>
              </div>

              <div className="loginOrlogin">
                <div className="line-login"></div>
                <p
                  style={{
                    marginTop: "0",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  or
                </p>
                <div className="line-login"></div>
              </div>
            </div>
            <div>
              <div className="signInButton" id="signInGoogleButton">
                <GoogleOAuthProvider
                  width={googleButtonWidth}
                  clientId={clientId}
                  text="Login with Google"
                >
                  <GoogleLogin
                    width={googleButtonWidth}
                    text="Login with Google"
                    logo_alignment={text_alignment}
                    style={{ border: "1px solid #545454" }}
                    onSuccess={async credentialResponse => {
                      console.log("Google Auth Sucess.");
                      console.log(
                        "token that is sent to bcknd:",
                        credentialResponse.credential
                      );
                      console.log(
                        "token that is sent to bcknd:",
                        jwt_decode(credentialResponse.credential)
                      );
                      try {
                        const response = await services.post(`/googleAuth`, {
                          id_token: credentialResponse.credential,
                          signup_platform: "CareerCarve College Admin",
                        });
                        console.log({ response });

                        if (response.status === 200) {
                          Cookies.set("token", response.headers.jwt, {
                            expires: 182.5,
                          });

                          navigate("/CollegeInfoPage", {
                            state: {
                              formValues: formValues,
                              user_type: response.data.user_type,
                            },
                          });
                          // alert.box(<h1>DASHBOARD</h1>);
                          console.log("Dashboard");
                          setErrMsg("Login successful.");
                          const cookies = response.headers.jwt;
                          console.log({ cookies });
                        }
                      } catch (err) {
                        console.log(err);
                        if (
                          err?.response.status === 400 &&
                          err?.response.data.message === "Unverified account"
                        ) {
                          setErrMsg("This email is Unverified.");
                        } else if (
                          err?.response.status === 400 &&
                          err?.response.data.message ===
                          "Alternate email already existing."
                        ) {
                          setErrMsg("Alternate email already existing.");
                        } else if (
                          err?.response.status === 400 &&
                          err?.response.data.message ===
                          "Seems like your account dosn't exist with us. Please Signup using CareerCarve App."
                        ) {
                          setChangeTemplate(true);
                          setErrMsg(
                            "Seems like your account doesn't exist with us. Please Signup using CareerCarve App."
                          );

                          console.log("sdfghjkl", changeTemplate);
                        } else if (
                          err?.response.status === 400 &&
                          err?.response.data.message ===
                          "Email already exists as alternate."
                        ) {
                          setErrMsg(
                            "This email already exists as an secondary email. Please login with your primary email."
                          );
                        } else if (
                          err?.response.status === 500 &&
                          err?.response.data.message ===
                          "Alternate email entry doesn't exists"
                        ) {
                          setErrMsg("Alternate email entry doesn't exists.");
                        }
                      }
                    }}
                    onError={() => {
                      window.alert("Login Failed");
                    }}
                  />
                </GoogleOAuthProvider>
              </div>
            </div>

            <div
              style={{
                marginTop: "2rem",
                textAlign: "center",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              <button
                className="apple-sign-up-button"
                onClick={handleSignInClick}
                disabled={isLoading}
              >
                <img className="appleIcon" src="apple.svg" />
                Sign in with Apple
              </button>
            </div>
          </form>
        </div>
      </div>
      <Alert
        show={changeTemplate}
        title="Alert"
        body="You are not a registered user with us. Please sign using Google/Apple on the CareerCarve App to continue."
        yesButton="Signup"
        alertResponse={alertResponse}
        onHide={() => setChangeTemplate(false)}
      />
      ;
    </div>
  );
};

export default Login;
