import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Color } from '../../../GlobalStyles'
import '../OngoingEvents/OngoingEvents.css'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import Service from "../../../services/httpService";
import ActivatedStatus from '../ActivatedStatus/ActivatedStatus'
import EditIcon from '@mui/icons-material/Edit';
import EventBusyIcon from "@mui/icons-material/EventBusy";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { extractDateFromDateString, calculateEpochTimeInLocalTime } from "../../../services/specialCharReplace";
import { get_specialCharReplace } from "../../../services/specialCharReplace";
export default function UpcomingPrograms(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const collegeService = new Service()
  const { college_id, college_name, batchName, activityTypeData, durationData, mentorData, GroupSessionData, collegeData, batchData } = props
  console.log("college_name", college_name);
  const [show, setShow] = useState(false)
  const [to_modal_json, setTo_modal_json] = useState({});
  const [action, setAction] = useState("")

  // let college_id = location.state?.college_id
  // let college_name = location.state?.college_name || ""
  // const [BatchData, setBatchData] = useState([])



  useEffect(() => {
    console.log("Brother")
    const handleEffect = async () => {

      if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
        navigate('/');
      }
    }
    handleEffect();
  }, [])

  // const batchDataGetter = async () => {
  //   try {
  //     const res = await collegeData.get(`/groupSession`)
  //     console.log("group session RESPONSE:", res.data)
  //     setBatchData([...res.data])
  //     return res.data
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }
  // console.log("batch data", BatchData);

  const callModalInput = (e) => {
    console.log("Yaay its here", props.path)
    props.data_getter("", undefined, undefined, "add")
    setShow(true);
  }

  const callModalEditInput = (e, id, index, from) => {
    console.log("TableComponent-callModalInput2:", { id }, { index })
    setTo_modal_json({ ...to_modal_json, id: id, index: index })
    if (from === "edit") {
      setShow(true);
      props.data_getter("", id, index, "edit")
      setAction("edit")
    }
  }

  return (
    <div className='OngoingEventsCard' style={{ marginTop: "2rem" }}>
      <Typography style={{ marginBottom: ".5rem" }} sx={{ fontSize: 20 }}>Ongoing Group Sessions</Typography>
      {props.path === "/GroupSession" ? <>
        <CardActions sx={{ justifyContent: 'flex-start', bottom: 0, marginBottom: ".5rem" }}>
          <button
            className="submitBtn"
            type="button"
            style={{ height: "44px", bottom: 0, width: "200px" }}
            onClick={(e) => callModalInput(e)}
          >
            Add new Group Session
          </button>
        </CardActions></> : <></>}
      <div className="cardsInsideFlex">
        {props.entireGroupSessionData?.length === 0 ? <>
          <div className="noUpcomingBookings" style={{ width: "80%", justifyContent: "center", alignItems: 'center', margin: "0 auto", marginTop: '1rem' }}>
            <div className="noUpcomingBookingsIcon">
              <EventBusyIcon
                sx={{
                  color: "#1C8EA8",
                  width: "50px",
                  height: "50px",
                }}
              />
            </div>
            <div className="noUpcomingBookingsTexts">
              <p className="noUpcomingBookingsText">
                No Ongoing Group Sessions
              </p>

            </div>
          </div>
        </> : <>
          {(props.path === "/GroupSession" ? GroupSessionData : props.entireGroupSessionData)?.map((batchInfo, i) => {
            return (
              <Box key={i} sx={{ width: "80%", marginRight: '1rem', boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)", borderRadius: "10px" , border:`1px solid ${Color.primary1}`}}>
                <CardContent className="cardContainer" disableGutters>
                  <div style={{ display: "flex", flexDirection: 'column', gap: '.5rem' }}>
                    <Typography variant="body2" sx={{ fontSize: 22 }} color={Color.primary1} gutterBottom>
                      {batchInfo?.name}
                    </Typography>
                    {(props.path === "/GroupSession" || props.path === "/College1x1BatchDetails") && (
                      <ModeEditOutlinedIcon sx={{ color: Color.primary1, cursor: 'pointer' }} onClick={(e) => { callModalEditInput(e, batchInfo.id, i, "edit") }} />
                    )}
                  </div>
                  <Typography component="div" sx={{ color: Color.neutralMidGrey, fontSize: '12px' }}>{get_specialCharReplace(batchInfo.description)}</Typography>
                  <div className="group-container">
                    <Typography variant="body2" className="group-providingSpace1">Session:</Typography>
                    <Typography variant="body2" className="group-providingSpaceForSubDetailsBlack">{batchInfo?.sku_name}</Typography>
                  </div>
                  
                  <div className="group-container">
                    <Typography variant="body2" className="group-providingSpace1">Date & Time:</Typography>
                    <Typography variant="body2" className="group-providingSpaceForSubDetailsBlack" >{`${extractDateFromDateString(batchInfo.date_time)} | ${calculateEpochTimeInLocalTime(batchInfo.date_time)}`}</Typography>
                  </div>
                  <div className="group-container">
                    <Typography variant="body2" className="group-providingSpace1">Duration:</Typography>
                    <Typography variant="body2" className="group-providingSpaceForSubDetailsBlack" >{batchInfo?.duration_name}</Typography>
                  </div>
                  <div className="group-container">
                    <Typography variant="body2" className="group-providingSpace1">Mentor:</Typography>
                    <Typography variant="body2" className="group-providingSpaceForSubDetails" >{mentorData.filter((item_s) => item_s.user_id === parseInt(batchInfo?.mentor_id))[0]?.first_name} {mentorData.filter((item_s) => item_s.user_id === parseInt(batchInfo?.mentor_id))[0]?.last_name}</Typography>
                  </div>
                  <div className="group-container">
                    <Typography variant="body2" className="group-providingSpace1">Session venue:</Typography>
                    <Typography variant="body2" className="group-providingSpaceForSubDetails" sx={{color: batchInfo?.link.startsWith("http://") || batchInfo?.link.startsWith("https://") ? Color.primary1 : Color.blueBlack, cursor : batchInfo?.link.startsWith("http://") || batchInfo?.link.startsWith("https://") ? "pointer" : "none"}}
                    onClick={(event) => {
                      event.stopPropagation();
                      const mentorLinkedInLink = batchInfo.link;
                      if (mentorLinkedInLink.startsWith("http://") || mentorLinkedInLink.startsWith("https://")) {
                        window.open(mentorLinkedInLink);
                      } else {
                        // window.open("https://" + mentorLinkedInLink);
                      }
                    }}
                     >{batchInfo?.link}</Typography>
                  </div>
                </CardContent>
              </Box>
            )
          })}
        </>}

      </div>
      {show === true && props.path === "/GroupSession" ?
        (props.mainFile_modal(show, setShow, "Add")) : (<></>)
      }
    </div>
  )
}

