import { Box, Grid, Stepper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../Analytics.css";
import Chart from "react-apexcharts";
import { CheckCircle, AccountCircle, WatchLater, EventAvailable, Done } from "@mui/icons-material";
import { BatchTrackerSkeleton, SkeletonLoader1, SkeletonLoader2 } from "../../reusables/SkeletonLoader/SkeletonLoader";
import SouthIcon from "@mui/icons-material/South";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import axios from "axios";
import Cookies from "js-cookie";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ComingSoonAnalytics from "../ComingSoonAnalytics";
function ActivityReport(props) {
  const service = new CCService();
  const [loading, setLoading] = useState(false);
  const [progressTracker, setProgressTracker] = useState([]);
  const [activityReportOverviewSkeletonLoader, setActivityReportOverviewSkeletonLoader] = useState(true);

  const [testOnApp, setTestOnApp] = useState([
    { description: "total 1X1s done", value: 0, icons: <CheckCircle sx={{ color: "#1F8FA9" }}></CheckCircle>, name: "total" },
    { description: "mentors involved", value: 0, icons: <AccountCircle sx={{ color: "#1F8FA9" }}></AccountCircle>, name: "mentors_involved" },
    { description: "total hours of training", value: 0, icons: <WatchLater sx={{ color: "#1F8FA9" }}></WatchLater>, name: "total_hours_of_training" },
    { description: "total active days", value: 0, icons: <EventAvailable sx={{ color: "#1F8FA9" }}></EventAvailable>, name: "total_active_days" },
  ]);

  const [top_performer, setTopPerformer] = useState([]);
  const [bottom_performer, setBottomPerformer] = useState([]);

  const [rolePreferenceOptions, setRolePreferenceOptions] = useState({
    chart: {
      id: "apexchart-example",
    },
    xaxis: {
      categories: ["a", "b", "c", "d", "e", "f", "g", "h", "i"],
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#1f8fa9"],
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "25%",

        dataLabels: {
          position: "top",
          offsetY: 30000,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#333"],
        },
        offsetY: 30000,
      },
    },
    yaxis: {
      show: true,
      title: {
        text: "No. of students",
        style: {
          fontSize: "12px",
          fontWeight: 600,
        },
      },
    },
    colors: ["#41B8D5"],
    legend: {
      show: true,
      position: "top",
      showForSingleSeries: true,
      fontSize: "14px",
      customLegendItems: ["Role Preference"],
    },
  });

  const [rolePreferenceSeries, setRolePreferenceSeries] = useState([
    {
      data: [],
    },
  ]);

  const customSort = (a, b) => {
    const stateOrder = { Done: 0, Ongoing: 1, Planned: 2 };
    const stateA = stateOrder[a.status.state];
    const stateB = stateOrder[b.status.state];

    return stateA - stateB;
  };

  const batchProcessManipulator = (data) => {
    let tempData = data.map((item) => {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      const startDate = item.start_date === null ? tomorrow : new Date(item.start_date);

      const endDate = new Date(item?.end_date);

      let status = { state: "Planned", color: "#7F7F7F" };
      if (today < startDate) {
        status = { state: "Planned", color: "#7F7F7F" };
      } else if (today >= startDate && today <= endDate) {
        status = { state: "Ongoing", color: "#E88F09" };
      } else {
        status = { state: "Done", color: "#45A834" };
      }

      return {
        process_id: item.process_id,
        done_count: item.done_count ? item.done_count : 0,
        name: item.sku_name ? item.sku_name : "",
        start_date: item.start_date ? item.start_date : tomorrow,
        end_date: item?.end_date ? item?.end_date : null,
        status: status,
      };
    });

    tempData.sort(customSort);

    console.log("TempData", tempData);

    if (tempData.length > 0) {
      let startDate = new Date(tempData[0].start_date);
      let endDate = new Date(tempData[1]?.end_date);

      tempData.map((item) => {
        if (item.status.state === "Done") {
          endDate = new Date(item?.end_date);
        } else if (item.status.state === "Ongoing") {
          endDate = new Date();
        }
      });

      let totalDays = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
      setTestOnApp((prev) => {
        let temp_value = prev.map((item) => {
          if (item.name === "total_active_days") {
            return {
              ...item,
              value: totalDays,
            };
          } else {
            return { ...item };
          }
        });

        return temp_value;
      });
    }
    return tempData;
  };

  const activityReportManipulator = (data) => {
    let temp_test_data = data?.activityReport.length !== 0 && data?.activityReport[0]?.batch_int_count ? JSON.parse(data.activityReport[0].batch_int_count) : {};
    if (temp_test_data) {
      let TempTestOnApp = testOnApp.map((item) => {
        if (item.name === "total_active_days") {
          return {
            ...item,
          };
        } else {
          return {
            ...item,
            value: temp_test_data[item.name],
          };
        }
      });

      setTestOnApp(TempTestOnApp);
    } else {
      setTestOnApp([
        { description: "total 1X1s done", value: 0, icons: <CheckCircle sx={{ color: "#1F8FA9" }}></CheckCircle>, name: "total" },
        { description: "mentors involved", value: 0, icons: <AccountCircle sx={{ color: "#1F8FA9" }}></AccountCircle>, name: "mentors_involved" },
        { description: "total hours of training", value: 0, icons: <WatchLater sx={{ color: "#1F8FA9" }}></WatchLater>, name: "total_hours_of_training" },
        { description: "total active days", value: 0, icons: <EventAvailable sx={{ color: "#1F8FA9" }}></EventAvailable>, name: "total_active_days" },
      ]);
    }

    let temp_rolePreference = data.activityReport.length !== 0 && data.activityReport[0].role_preferences ? JSON.parse(data.activityReport[0].role_preferences) : null;

    if (temp_rolePreference) {
      setRolePreferenceSeries([{ data: Object.values(temp_rolePreference) }]);
      setRolePreferenceOptions((prev) => {
        return {
          ...prev,
          xaxis: {
            categories: Object.keys(temp_rolePreference),
          },
        };
      });
    }
    setTopPerformer(data.user_performance_top_bottom_5.slice(5));
    setBottomPerformer(data.user_performance_top_bottom_5.slice(0, 5));
  };

  useEffect(() => {
    const getProgressData = async () => {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can remove this line if you don't want a smooth scroll
      });
      // window.scrollTo(0, 0);

      const config = {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      };
      const batchProcessData = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/user/batchProcesses?user_group_id=${props.program_id}`, config);
      const activityReport = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/user/batchProcessOverview?user_group_id=${props.program_id}`);
      console.log("batchProcessData", batchProcessData);
      activityReportManipulator(activityReport.data.prepared_data);
      setProgressTracker(batchProcessManipulator(batchProcessData.data.data));
      setActivityReportOverviewSkeletonLoader(false);
    };

    getProgressData();
  }, [props.program_id]);

  return (
    <Box className="main-container">
      {activityReportOverviewSkeletonLoader ? (
        <BatchTrackerSkeleton />
      ) : (
        <>
          {(progressTracker.filter((item) => item.status.state === "Planned"))?.every((item) => item.status.state === "Planned") ? <><ComingSoonAnalytics /></> : <>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "1rem", marginTop: "1.5rem" }}>
                <Box sx={{ height: "2px", width: "100%", background: "#E88F09" }}></Box>
                <Typography variant="h1" sx={{ color: "#E88F09", textAlign: "center", fontWeight: "600", width: "800px", letterSpacing: "2.5px" }}>
                  Activity Report
                </Typography>
                <Box sx={{ height: "2px", width: "100%", background: "#E88F09" }}></Box>
              </Box>
              <Box borderRadius={5} sx={{ border: "1px solid rgba(255, 165, 0, 0.3)", padding: "1rem" }}>
                <Typography variant="h2" style={{ fontWeight: "bold", textAlign: "center", marginBottom: "1rem" }}>
                  {props.college_name} | {props.program_name} | {props?.activated_students + props?.non_activated_students} students
                </Typography>
                <Typography variant="body1" style={{ color: "#FF3131", textAlign: "center", fontSize: "10px" }}>
                  DISCLAIMER:
                </Typography>
                <Typography sx={{ textAlign: "center", fontSize: "10px" }}>All information in these reports are provided “as is”, with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind. All qualifications or comments in all reports have no bearing on actual performance of any individual or group. These scores may vary from 1 session to another. The company will not be liable to you or anyone else for any decision made or action taken in reliance on the information given by these reports or for any consequential, special or similar damages, even if advised of the possibility of such damages.</Typography>
              </Box>
              <Box borderRadius={5} sx={{ border: "1px solid rgba(255, 165, 0, 0.3)", padding: "1rem", display: "flex", flexDirection: "column", gap: "1rem" }}>
                <Typography variant="h4" sx={{}}>
                  Batch Progress Overview
                </Typography>
                <Typography variant="h5" sx={{ textAlign: "center", color: "#41B8D5" }}>
                  Online Sessions
                </Typography>
                {/* progress Tracker */}
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "5px" }}>
                    {progressTracker?.length > 0 ? (
                      progressTracker?.map((item, index) => (
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                            {<Box sx={{ height: "3px", background: index === 0 ? "#fff" : item?.status?.color, width: "60px" }}></Box>}
                            <Box sx={{ borderRadius: "50%", height: "40px", width: "40px", borderWidth: "1px", borderStyle: "solid", borderColor: item.status.color, background: item.status.state === "Done" ? "#45A834" : "#fff", display: "flex", justifyContent: "center", alignItems: "center" }}>{item.status.state === "Done" ? <Done sx={{ fontSize: "24px", color: "#fff", stroke: "#fff", strokeWidth: 2 }} /> : ""}</Box>
                            {<Box sx={{ height: "3px", background: index === progressTracker.length - 1 ? "#fff" : progressTracker[index + 1].status.color, width: "60px" }}></Box>}
                          </Box>
                          <Typography variant="h6" sx={{ textAlign: "center", color: item?.status?.color, fontWeight: "600" }}>
                            {item.name}
                          </Typography>
                          <Typography style={{ textAlign: "center", color: item?.status?.color, fontSize: "9px", fontStyle: "italic" }}>{item.status.state === "Done" ? `(${item.start_date} to ${item?.end_date})` : item.status.state}</Typography>
                          <Typography variant="h5" sx={{ textAlign: "center", color: item?.status?.color, fontSize: "12px" }}>
                            {item.status.state === "Planned" ? `` : `${item.done_count} sessions completed`}
                          </Typography>
                        </Box>
                      ))
                    ) : (
                      <Typography variant="h4" sx={{ color: "#e6e6e6e" }}>
                        No Data Available
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Typography variant="h5" sx={{ textAlign: "center", color: "#41B8D5" }}>
                  Sessions Summary
                </Typography>
                <Box sx={{ padding: "0px 5rem" }}>
                  <Grid container rowGap={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {testOnApp.map((item) => (
                      <Grid item sm={6} md={3} sx={{ display: "flex", flexDirection: "column", gap: "2px", alignItems: "center" }}>
                        <Box>
                          <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                            {item.icons}
                            <Typography style={{ fontSize: "20px", color: "#41B8D5", textAlign: "left" }}>{item.value}</Typography>
                          </Box>
                          <Typography variant="h5">{item.description}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between", gap: "1.5rem" }}>
                <Box borderRadius={5} sx={{ border: "1px solid rgba(255, 165, 0, 0.3)", padding: "1rem", display: "flex", flexDirection: "column", gap: "1rem", width: "100%", justifyContent: "space-between" }}>
                  <Typography variant="h4" sx={{}}>
                    Promptness Leaderboard
                  </Typography>
                  {top_performer.length > 0 ? (
                    <>
                      <Box sx={{ paddingInline: "5rem" }}>
                        {top_performer.length > 0 &&
                          top_performer.map((item, index) => (
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "#41B8D5", marginBottom: "5px", padding: "2px 10px" }}>
                              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                <Typography sx={{ fontSize: "14px", color: "#194752" }}>#{index + 1}</Typography>
                                {item.user_image ? <img src={item.user_image} alt={item.user_image} style={{ width: "35px", height: "35px", borderRadius: "100%", border: "1px solid #fff" }} /> : <AccountCircleIcon style={{ width: "35px", height: "35px", borderRadius: "100%", color: "#fff" }} />}
                                <Typography variant="h5" sx={{ color: "#fff" }}>
                                  {item.name}
                                </Typography>
                              </Box>
                              <Typography variant="h5" sx={{ color: "#fff" }}>
                                {`${(item.total_p * 100).toFixed(2)}`}%
                              </Typography>
                            </Box>
                          ))}
                      </Box>
                      <Typography style={{ textAlign: "justify", fontSize: "10px" }}>Percentage mentioned above is the level of promptness of top 5 students of the batch in terms of the following facets: Time taken to activate their accounts, feedback provided to respective mentors, time taken to book their interviews, and time taken to join scheduled sessions. </Typography>
                    </>
                  ) : (
                    <Typography variant="h4" sx={{ color: "#e6e6e6e" }}>
                      No Data Available
                    </Typography>
                  )}
                </Box>
                <Box borderRadius={5} sx={{ border: "1px solid rgba(255, 165, 0, 0.3)", padding: "1rem", display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
                  <Typography variant="h4" sx={{}}>
                    Promptness: Critical-Level
                  </Typography>
                  <Box sx={{ paddingInline: "5rem" }}>
                    {bottom_performer.length > 0 ? (
                      bottom_performer.length > 0 &&
                      bottom_performer.map((item, index) => (
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "#E8E8E8", marginBottom: "5px", padding: "2px 10px" }}>
                          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            <SouthIcon sx={{ fontWeight: "000", color: "red", fontSize: "21px", stroke: "red", strokeWidth: 2 }} />
                            {item.user_image ? <img src={item.user_image} alt={item.user_image} style={{ width: "35px", height: "35px", borderRadius: "100%", border: "1px solid #fff" }} /> : <AccountCircleIcon style={{ width: "35px", height: "35px", borderRadius: "100%", color: "#a3a3a3f6" }} />}

                            <Typography variant="h5" sx={{ color: "#000" }}>
                              {item.name}
                            </Typography>
                          </Box>
                          <Typography variant="h5" sx={{ color: "#000" }}>
                            {`${(item.total_p * 100).toFixed(2)}`}%
                          </Typography>
                        </Box>
                      ))
                    ) : (
                      <Typography variant="h4" sx={{ color: "#e6e6e6e" }}>
                        No Data Available
                      </Typography>
                    )}
                  </Box>
                  <Typography sx={{ textAlign: "justify", fontSize: "10px" }}>Percentage mentioned above is the level of promptness of bottom 5 students of the batch in terms of the following facets: Time taken to activate their accounts, feedback provided to respective mentors, time taken to book their interviews, and time taken to join scheduled sessions.  </Typography>
                </Box>
              </Box>

              <Box borderRadius={5} sx={{ display: "flex", flexDirection: "column", gap: "1.5rem", border: "1px solid rgba(255, 165, 0, 0.3)", padding: "1rem" }}>
                <Box>
                  <Typography variant="h4" sx={{ marginBottom: "3px" }}>
                    Role Preferences
                  </Typography>
                  <Typography sx={{ textAlign: "justify", color: "#525252", fontStyle: "italic", fontSize: "10px" }}>Roles preferences displayed below are the top 10 roles that were most preferred by students of this batch while choosing roles for mock 1x1 sessions. NOTE: Students having multiple preferences are represented more than once across roles. Refer to individual activity reports to check unique student preferences per activity. </Typography>
                </Box>
                {console.log("roleeeee", rolePreferenceSeries)}
                {rolePreferenceSeries[0].data.length > 0 ? (
                  <Chart options={rolePreferenceOptions} series={rolePreferenceSeries} type="bar" width={"100%"} height={320} />
                ) : (
                  <Typography variant="h4" sx={{ color: "#e6e6e6e" }}>
                    No Data Available
                  </Typography>
                )}
              </Box>
            </Box>
          </>}

        </>
      )}
    </Box>
  );
}

export default ActivityReport;
