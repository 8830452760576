import React, { useState, useEffect } from 'react'
import defaultSetting from '../Settings/DefaultSettings.json'
import { Color, PrimaryButton, PrimaryButtonOutlined } from '../../../GlobalStyles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ButtonGroup, Button, Typography, Tooltip } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReorderIcon from '@mui/icons-material/Reorder';
import './BatchProfileExcelTemplate.css'
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CCService from '../../../services/httpServiceWithoutSuperAdmin';
import ConfirmationAndValidationModal from '../../reusables/ConfirmationAndValidationModal/ConfirmationAndValidationModal';
import { HandleDownloadFile } from '../../../services/specialCharReplace';
import { useSelector } from 'react-redux';
const BatchProfileExcelTemplate = (props) => {
    const { batch_id, origin, job_id, onClose ,defaultSettingsArray, setDefaultSettingsArray} = props
    console.log("batch_id", batch_id, job_id);
    const services = new CCService()
    const [defaultSettings, setDefaultSettings] = useState(defaultSetting)
    const { accessRights , tooltipText} = useSelector((store) => store.rights)
    // const [defaultSettingsArray, setDefaultSettingsArray] = useState(defaultSettings?.download_logic)
    const [subSections, setSubSections] = useState([])
    const [showSubSections, setShowSubSections] = useState(false)
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [successConfirmationModal, setSuccessConfirmationModal] = useState({
        open: false,
        onClose: () => setSuccessConfirmationModal((prev) => ({ ...prev, open: false })),
    })

    useEffect(() => {
        if(origin === "customize") {
            const fetchDefaultSettings = async () => {
                try {
                    const defaultSettings = await defaultSettingsGetter();
                    console.log("checking what I'm getting", defaultSettings);
                    if (defaultSettings.length !== 0) {
                        setDefaultSettingsArray(defaultSettings);
                    }
                } catch (error) {
                    console.error("Error fetching default settings:", error);
                }
            };
            fetchDefaultSettings();
        }
        
    }, []);

    const defaultSettingsGetter = async () => {
        try {
            const res = await services.get(`/po/ca/poBatchMapping?ug_id=${batch_id}`);
            console.log("Batch processes response:", res.data);
            if (res.data[0].download_logic && res.data[0].download_logic.length > 0) {
                return JSON.parse(res.data[0].download_logic);
            } else {
                return [];
            }
        } catch (error) {
            console.error("Error fetching default settings:", error);
            throw error;
        }
    };



    const handleDragEnd = (result) => {
        if (!result.destination) return; // if dropped outside the list
        const items = Array.from(defaultSettingsArray);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        const updatedItems = items.map((item, index) => ({
            ...item,
            index_no: index + 1
        }));
        setDefaultSettingsArray(updatedItems);
    };

    console.log("default settings details", defaultSettingsArray);
    const handleChangeIsHidden = (index) => {
        const updatedSettingsArray = [...defaultSettingsArray];
        updatedSettingsArray[index].is_hidden = !updatedSettingsArray[index].is_hidden;
        setDefaultSettingsArray(updatedSettingsArray);
    };

    const handleChangeExcelName = (index, newValue) => {
        const updatedSettingsArray = [...defaultSettingsArray];
        updatedSettingsArray[index].excel_name = newValue;
        setDefaultSettingsArray(updatedSettingsArray);
    };

    const handleIncrement = (index) => {
        const updatedSettingsArray = [...defaultSettingsArray];
        updatedSettingsArray[index].count++;
        setDefaultSettingsArray(updatedSettingsArray);
    };

    const handleDecrement = (index) => {
        const updatedSettingsArray = [...defaultSettingsArray];
        if (updatedSettingsArray[index].count > 1) {
            updatedSettingsArray[index].count--;
            setDefaultSettingsArray(updatedSettingsArray);
        }
    };

    const handleArrowClick = (index, subSections) => {
        console.log("subsections", subSections);
        setSelectedRowIndex(selectedRowIndex === index ? null : index);
        setSubSections(subSections);
        setShowSubSections(true);
    };

    const handleVisibilityToggle = (subIndex, isHidden) => {
        if (selectedRowIndex !== null) {
            const updatedSettingsArray = defaultSettingsArray.map((application, index) => {
                if (index === selectedRowIndex) {
                    const updatedSubSections = application.sub_sections.map((subSection, sIndex) => {
                        if (sIndex === subIndex) {
                            return { ...subSection, s_is_hidden: isHidden };
                        }
                        return subSection;
                    });
                    return { ...application, sub_sections: updatedSubSections };
                }
                return application;
            });
            setDefaultSettingsArray(updatedSettingsArray);
            const updatedSubSections = [...subSections];
            updatedSubSections[subIndex] = { ...updatedSubSections[subIndex], s_is_hidden: isHidden };
            setSubSections(updatedSubSections);
        }
    };

    const handleSaveDefaultSettings = async () => {
        try {
            const res = await services.post(`/po/ca/poBatchMapping`,
                {
                    ug_id: batch_id,
                    download_logic: defaultSettingsArray
                })
            if (res.status === 200) {
                setSuccessConfirmationModal((prev) => ({ ...prev, open: true }))
            }
        } catch (err) {
            console.log(err);
        }
    }

    

    const handleDownloadApplllllicantsExcel = async () => {
        try {
            if(props.tab === 0){
                const res = await services.post(`/po/ca/downloadApplicants`,
                    {
                        ug_id: batch_id,
                        po_job_id: job_id,
                        download_logic: defaultSettingsArray
                    })
                if (res.status === 200) {
                    setSuccessConfirmationModal((prev) => ({ ...prev, open: true }))
                    onClose()
                    HandleDownloadFile(res.data.file_url)
                }
            }else{
               
                const res = await services.post(`/po/ca/downloadApplicants`,
                    {
                        ug_id: batch_id,
                        po_job_id: job_id,
                        download_logic: defaultSettingsArray.filter((item) => item.group_id !== 101),
                        onlyEligible: true
                    })
                if (res.status === 200) {
                    setSuccessConfirmationModal((prev) => ({ ...prev, open: true }))
                    onClose()
                    HandleDownloadFile(res.data.file_url)
                }
            }
           
        } catch (err) {
            console.log(err);
        }
    }

    console.log("defaultSettings right now", defaultSettings);
    return (
        <div style={{ width: "100%" }}>
            {origin === "default" && <Box sx={{ display: 'flex', }}>
                <Box class="settingsHeader" style={{ borderBottom: `1px solid ${Color.neutralMidGrey}` }}>
                    <Typography class="settingsHeadingsTypography" style={{ color: Color.primary1, margin:"auto 0" }} variant='h1'>Batch Profile Excel Template</Typography>
                    <Tooltip title={accessRights.find((item) => item.access_right_id === 7)?.is_activated===1 ?"":tooltipText}>
                      <Box>
                      <Button variant="outlined"
                        sx={{
                            textTransform: "none",
                            borderColor: Color.primary1,
                            color: Color.primary1,
                            borderRadius: 0,
                            boxShadow: "none",
                            width: "90px",
                            "&:hover": {
                                borderColor: Color.primary1,
                                color: Color.primary1,
                            },
                        }}  disabled={accessRights.find((item) => item.access_right_id === 7)?.is_activated===1 ? false:true} onClick={() => handleSaveDefaultSettings()}>Save</Button>
                      </Box>
                    </Tooltip>
                </Box>
            </Box>}

            <Box>
                <div style={{ display: "flex", alignItems: "center", width: "100%", marginBottom: "10px" }}>
                    <div style={{ width: "40%", textAlign: "left", height: "60px" }}>
                        Column Name
                    </div>
                    <div style={{ width: "30%", textAlign: "left", height: "60px" }}>
                        Column Name in excel
                    </div>
                    <div style={{ width: "15%", textAlign: "left", height: "60px" }}>
                        How many?
                    </div>
                    <div style={{ width: "10%", textAlign: "left", height: "60px" }}>
                        Hide/Unhide
                    </div>
                </div>
            </Box>
            <div style={{ display: 'flex', flexDirection: 'column', border: "none", boxShadow: "none" }}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <table className="resume-template-content-modal1" style={{ borderCollapse: "collapse", width: "100%" }}>
                        <Droppable droppableId="droppable-1">
                            {(provider, snapshot) => (
                                <tbody {...provider.droppableProps} ref={provider.innerRef}>
                                    {defaultSettingsArray?.map((application, index) => (
                                        <Draggable key={application.column_name} draggableId={application.column_name} index={index} >
                                            {(provided, snapshot) => (
                                                <div style={{ width: "100%" }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} id="resume-template-tr">
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} id={`resume-template-tr-${index}`} style={{ display: "flex", alignItems: "center", width: "100%", marginBottom: "10px" }}>
                                                        <div {...provided.dragHandleProps} style={{ width: application?.sub_sections ? "1%" : "2%", height: "60px", marginRight: application?.sub_sections ? "1rem" : "2rem" }}>
                                                            <ReorderIcon sx={{ marginRight: application?.sub_sections ? "1rem" : "2rem" }} />
                                                        </div>
                                                        {application?.sub_sections ?
                                                            <div style={{ width: "2.5%", marginBottom: "auto" }}>
                                                                {(showSubSections && selectedRowIndex === index) ? <ArrowDropDownIcon sx={{ width: "15px", height: "20px" }}/> : <ArrowRightIcon sx={{ width: "15px", height: "20px" }} onClick={() => handleArrowClick(index, application.sub_sections)} />}
                                                            </div> :
                                                            <div style={{ width: "0%", height: "60px", margin: "0px", padding: '0px' }}></div>}
                                                        <div style={{ width: "35%", height: "60px", textAlign: "left" }}>
                                                            <Typography sx={{ color: Color.blueBlack }}>{application?.column_name}</Typography>
                                                        </div>
                                                        <div style={{ width: "30%", textAlign: "left", height: "60px" }}>
                                                            <TextField
                                                                hiddenLabel
                                                                id="filled-hidden-label-small"
                                                                defaultValue="Small"
                                                                size="small"
                                                                value={application?.excel_name}
                                                                sx={{ border: "1px solid black", "& fieldset": { border: 'none' }, background: "transparent" }}
                                                                onChange={(e) => handleChangeExcelName(index, e.target.value)}
                                                            />
                                                        </div>
                                                        <div style={{ width: "15%", textAlign: "left", height: "60px" }}>
                                                            {application.count && (
                                                                <ButtonGroup size="small" style={{ justifyContent: "center", alignItems: "center" }} className="pricingSingleACtivityOrder" aria-label="small outlined button group">
                                                                    <Button onClick={() => handleDecrement(index)} sx={{  fontSize: "18px", color: Color.blueBlack}}>-</Button>
                                                                    <Button disabled sx={{  fontSize: "16px", color: Color.blueBlack, border:`1px solid ${Color.primary2} !important`, padding:'.263rem'}}>{application.count}</Button>
                                                                    <Button onClick={() => handleIncrement(index)} sx={{  fontSize: "18px", color: Color.blueBlack}}>+</Button>
                                                                </ButtonGroup>
                                                            )}
                                                        </div>
                                                        <div style={{ width: "10%", textAlign: "center", height: "60px" }}>
                                                            {application.is_hidden ?
                                                                <VisibilityOffIcon onClick={() => handleChangeIsHidden(index)} /> :
                                                                <VisibilityIcon onClick={() => handleChangeIsHidden(index)} />}
                                                        </div>
                                                    </div>
                                                    {(showSubSections && selectedRowIndex === index) && (
                                                        <div style={{ display: "flex", flexDirection: "column", gap: "2rem", width: "100%", marginBottom: "2rem" }}>
                                                            {subSections.map((subSection, subIndex) => (
                                                                <div style={{ display: "flex", alignItems: "center", marginBottom: `1px solid ${Color.neutralMidGrey}` }}>
                                                                    <div style={{ width: "5%", textAlign: "left" }}></div>
                                                                    <div style={{ width: "80%", textAlign: "left" }}>
                                                                        <Typography sx={{ color: Color.blueBlack }}>{subSection.s_column_name}</Typography>
                                                                    </div>
                                                                    <div style={{ width: "10%", textAlign: "center" }}>
                                                                        {subSection.s_is_hidden ?
                                                                            <VisibilityOffIcon onClick={() => handleVisibilityToggle(subIndex, true)} style={{ cursor: "pointer" }} /> :
                                                                            <VisibilityIcon onClick={() => handleVisibilityToggle(subIndex, false)} style={{ cursor: "pointer" }} />}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provider.placeholder}
                                </tbody>
                            )}
                        </Droppable>
                    </table>
                </DragDropContext>
            </div>
            {origin === "customize" &&
                <Box sx={{ margin: "2rem 5rem 0 0", width: "100%", display: 'flex', flexDirection: "row", justifyContent: "flex-end", position: 'absolute', zIndex: 1000, bottom: 0, right: "5rem" }}>
                    <PrimaryButton onClick={() => handleDownloadApplllllicantsExcel()}>Download Applicants Excel</PrimaryButton>
                </Box>}
            <ConfirmationAndValidationModal open={successConfirmationModal.open}
                onClose={successConfirmationModal.onClose}
                header={"Default settings has been updated successfully"}
                primaryButton={"Ok"}
                handleConfirmation={successConfirmationModal.onClose}
            />
        </div>

    )
}

export default BatchProfileExcelTemplate
