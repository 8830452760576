import React, { useState, useEffect } from 'react'
import { Container, Typography, Card } from "@mui/material";
import '../../Dashboard/OngoingEvents/OngoingEvents.css'
import CardContent from '@mui/material/CardContent';
import Menu from "@mui/material/Menu";
import { Color } from '../../../GlobalStyles'
import Service from '../../../services/httpService';
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';

const MentorStatus = (props) => {
  const service = new Service()
  const navigate = useNavigate();
  const [mentorData, setMentorData] = useState([])

  useEffect(() => {
    console.log("Brother mentor")
    const handleEffect = async () => {
      console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
      if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
        navigate('/');
      }
      //college getter
      let mentor = await mentorDataGetter();
      setMentorData(mentor)
      // forming the data
      console.log("mentor details", { mentor })
    }
    handleEffect();
  }, [])

  console.log("mentor details getter", mentorData);

  const mentorDataGetter = async () => {
    try {
      const res = await service.get("/getMentorStatus")
      console.log("mentor details response:", res.data)
      // setCardDetails([...res.data])
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="cardsInsideFlex">
      {Object.entries(mentorData).map(([key, value], index) => {
  let displayValue = value;
  let textColor = "";
  if (index === 1) {
    const valueAtIndex0 = mentorData[Object.keys(mentorData)[0]];
    displayValue = value - valueAtIndex0;
  }
  if (index === 0) {
    textColor = Color.green;
  } else if (index === 1) {
    textColor = Color.primary1;
  } else {
    textColor = Color.accent;
  }

  return (
    <Card
      key={key}
      sx={{
        width: 200,
        marginRight: '1rem',
        marginLeft: '.2rem',
        marginBottom: '.2rem',
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)",
        borderRadius: "10px",
        border: 'none'
      }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 16 }} align="center" variant="body1">
          {index === 0 ? 'Activated' : index === 1 ? "Not Activated" : index === 2 ? "Approval Awaited" : key}
        </Typography>
        <p className='topHeadings1' style={{ color: textColor }} align="center" variant="body1">
          {displayValue}
        </p>
      </CardContent>
    </Card>
  );
})}

    </div>

  )
}

export default MentorStatus
