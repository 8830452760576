import { AccountCircle, AddBoxOutlined, CalendarMonth, ChatBubbleOutline, Check, Clear, CopyAllOutlined, CreateNewFolder, DriveFolderUploadOutlined, EventAvailable, Notifications, RemoveOutlined, Search } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControl, IconButton, MenuItem, Modal, Select, Skeleton, TextField, Tooltip, tooltipClasses, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Color } from "../../../GlobalStyles";
import Calender from "../../../ReusableModals/Calender";
import { useDebounce } from "../../../services/debounce";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { SmallPageLoader } from "../../reusables/Loader/Loader";
import SideNavigationBar from "../../reusables/SideNavigationBar/SideNavigationBar";
import StudentChatModal from "./StudentChatModal";
function PVStudentBatch() {
  const location = useLocation();
  const timeoutRef = useRef(null);
  const searchParams = new URLSearchParams(location.search);
  const { accessRights, tooltipText } = useSelector((store) => store.rights);
  const batch_id = searchParams.get("batch_id");
  const { batch_name, college_id } = location.state;
  const navigate = useNavigate();
  const services = new CCService();
  const scrollRef = useRef(null);
  const [isHovered, setIsHovered] = useState(null);
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const status_name = {
    Blacklist: "Blacklisted",
    Opt_out: "Opted Out",
    Placed: "Placed",
    Unplaced: "Unplaced",
  };
  const [scheduleModal, setScheduleModal] = useState(false);
  const [profileFilters, setProfileFilters] = useState({
    studentStatusData: [],
    profileVerficationData: [],
  });
  const [studentData, setStudentData] = useState([]);
  const [scheduleTime, setScheduleTime] = useState(null);
  const [loaders, setLoaders] = useState({
    page: false,
    lazyLoader: true,
    loaderComplete: false,
  });
  const [allAssignee, setAllAssignee] = useState([]);
  const [assigneeSearch, setAssigneeSearch] = useState("");
  const [notificationHover, setNotificationHover] = useState(false);
  const [batchData, setBatchData] = useState({});
  const [page, setPage] = useState(1);
  const [changePage, setChangePage] = useState(true);
  const [markedStudents, setMarkedStudents] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [selectPostAssignee, setSelectPostAssignee] = useState({ id: 0, name: "Assign to" });
  const [postAssigneeModal, setPostAssigneeModal] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [chatModal, setChatModal] = useState(false);
  const [cancelScheduleModal, setCancelScheduleModal] = useState(false);
  const [showToolTip, setShowToolTip] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const debounceValue = useDebounce(searchString);

  const studentDataGetter = async () => {
    if (!loaders.loaderComplete) {
      setLoaders((prev) => {
        return {
          ...prev,
          lazyLoader: true,
        };
      });
      const getAllStudents = await services.get(`po/ca/allStudentsOfABatch?ug_id=${batch_id}&page=${page}&limit=8`);
      let temp = getAllStudents.data.map((item) => {
        return {
          ...item,
          checked: false,
        };
      });
      if (page === 1) {
        setStudentData((prev) => [...temp]);
      } else {
        setStudentData((prev) => [...prev, ...temp]);
      }
      if (getAllStudents.data.length === 0) {
        setLoaders((prev) => {
          return {
            ...prev,
            loaderComplete: true,
          };
        });
      }

      setLoaders((prev) => {
        return {
          ...prev,
          lazyLoader: false,
        };
      });
    }
  };

  const pageOneGetter = async () => {
    setLoaders((prev) => {
      return {
        ...prev,
        lazyLoader: true,
      };
    });
    const getAllStudents = await services.get(`po/ca/allStudentsOfABatch?ug_id=${batch_id}&page=${page}&limit=8`);
    let temp = getAllStudents.data.map((item) => {
      return {
        ...item,
        checked: false,
      };
    });

    setStudentData((prev) => [...temp]);

    setLoaders((prev) => {
      return {
        ...prev,
        lazyLoader: false,
      };
    });
  };
  const studentDataGetterBySearch = async () => {
    const dataGetter = await services.get(`po/ca/allStudentsOfABatchSearch?ug_id=${batch_id}&searchString=${searchString}`);
    let temp = dataGetter.data.map((item) => {
      return {
        ...item,
        checked: false,
      };
    });
    setStudentData(temp);
  };

  const handleMarkStudents = (e, item) => {
    setMarkedStudents((prev) => {
      let checkStudentPresent = prev.find((student) => student.user_id === item.user_id);
      if (checkStudentPresent) {
        return prev.filter((student) => student.user_id !== item.user_id);
      } else {
        return [...prev, item];
      }
    });

    setStudentData((prev) => {
      return prev.map((student) => {
        if (student.user_id === item.user_id) {
          return {
            ...student,
            checked: !student.checked,
          };
        } else {
          return student;
        }
      });
    });
  };

  const handleSetTime = async (value) => {
    try {
      if (value < new Date().getTime() - 5.5 * 60 * 60 * 1000) {
        toast.error(`Selected time cannot be from past`, {
          toastId: "custom-id-yes",
          position: "top-center",
          // theme: "dark",
        });
        return;
      }
      if (scheduleTime !== null && scheduleTime > value) {
        toast.error(`Cannot reschedule before the pervious time`, {
          toastId: "custom-id-yes",
          position: "top-center",
          // theme: "dark",
        });
        return;
      }

      const body = {
        ug_id: batch_id,
        allow_editing: 1,
        remove_editing_timestamp: value,
      };

      const postRequest = await services.post("/po/ca/poBatchMapping", body);
      setScheduleTime(value);
      setScheduleModal(false);
    } catch (error) {
      toast.error(`Error: ${error.response.data.message}`, {
        toastId: "custom-id-yes",
        position: "top-center",
        // theme: "dark",
      });
    }
  };

  const handleAddAssignee = async (value) => {
    try {
      if ([null, undefined].includes(value)) {
        toast.error("Please select the Assignee");
        return;
      }

      const body = {
        assigneeData: markedStudents.map((student) => {
          return {
            user_id: student.user_id,
            assigned_to: value.user_id,
          };
        }),
      };

      const postAssignee = services.post(`/po/ca/assignee`, body);

      let markedIDs = markedStudents.map((i) => i.user_id);
      let temp = studentData.map((item) => {
        if (markedIDs.includes(item.user_id)) {
          return {
            ...item,
            checked: false,
            assignee: value.user_id,
            assignee_name: value.college_admin_name,
          };
        } else {
          return item;
        }
      });

      setStudentData(temp);

      setMarkedStudents([]);
    } catch (error) {
      toast.error(`Error: ${error.response.data.message}`);
    }
  };

  const cancelSchedule = async () => {
    try {
      const body = {
        ug_id: batch_id,
        allow_editing: 0,
      };

      const postRequest = await services.post("/po/ca/poBatchMapping", body);

      const schedule = await services.put(`/po/ca/removeEditTimeScheduler`, { ug_id: batch_id });
      setScheduleTime(null);
      setCancelScheduleModal(false);
    } catch (error) {
      toast.error(`Error: ${error.response.data.map.message}`);
    }
  };

  const convertToTimeFormat = (time) => {
    const date = new Date(time);
    let day = String(date.getDate()).padStart(2, "0");
    let month = months[date.getMonth()]; // Months are zero-based
    let year = String(date.getFullYear());
    let hours = String(date.getHours()).padStart(2, "0");
    let minutes = String(date.getMinutes()).padStart(2, "0");
    let AMPM = "am";

    if (day === "01") {
      day = day + "st";
    } else if (day === "02") {
      day = day + "nd";
    } else if (day === "03") {
      day = day + "rd";
    } else day = day + "th";

    if (parseInt(hours) > 12) {
      hours = `${parseInt(hours) - 12}`;
      AMPM = "pm";
    } else if (parseInt(hours) === 12) {
      AMPM = "pm";
    } else if (parseInt(hours) === 0) {
      hours = 12;
    }

    return `${month} ${day} ${year}, ${hours}:${minutes}${AMPM}`;
  };

  const handleStatusFilter = async (page) => {
    // setSelectedStatus(e.target.value);

    if (loaders.loaderComplete) {
      return;
    }

    let url = `/po/ca/allStudentsOfABatch?ug_id=${batch_id}&page=${page}&limit=10`;
    if (selectedStatus !== null) {
      url = url + `&student_status_id=${selectedStatus.id}`;
    }
    if (selectedAssignee !== null) {
      url = url + `&assignee=${selectedAssignee.user_id}`;
    }

    if (searchString !== "") {
      url = url + `&searchString=${searchString}`;
    }

    const studentData = await services.get(url);
    if (studentData.data.length === 0) {
      setLoaders((prev) => {
        return {
          ...prev,
          loaderComplete: true,
        };
      });
    }
    let temp = studentData.data.map((item) => {
      return {
        ...item,
        checked: false,
      };
    });
    if (page === 1) {
      setStudentData(temp);
    } else {
      setStudentData((prev) => [...prev, ...temp]);
    }

    setLoaders((prev) => {
      return {
        ...prev,
        lazyLoader: false,
      };
    });
  };

  const handleUpdateAssignee = async (s, assignee) => {
    try {
      const body = {
        assigneeData: [
          {
            user_id: s.user_id,
            assigned_to: assignee.user_id,
          },
        ],
      };

      const postAssignee = services.post(`/po/ca/assignee`, body);

      let temp = studentData.map((item) => {
        if (item.user_id === s.user_id) {
          return {
            ...item,
            checked: false,
            assignee: assignee.user_id,
            assignee_name: assignee.college_admin_name,
          };
        } else {
          return item;
        }
      });

      setStudentData(temp);
      setIsHovered(null);
    } catch (error) {
      toast.error(`Error: ${error.response.data.map.message}`);
    }
  };

  const handleUnAssign = async (s) => {
    try {
      const body = {
        assigneeData: [
          {
            user_id: s.user_id,
            assigned_to: null,
          },
        ],
      };

      const postAssignee = services.post(`/po/ca/assignee`, body);

      let temp = studentData.map((item) => {
        if (item.user_id === s.user_id) {
          return {
            ...item,
            checked: false,
            assignee: null,
            assignee_name: null,
          };
        } else {
          return item;
        }
      });

      setStudentData(temp);
      setIsHovered(null);
    } catch (error) {
      toast.error(`Error: ${error.response.data.map.message}`);
    }
  };

  useEffect(() => {
    const handleEffect = async () => {
      setLoaders((prev) => {
        return {
          ...prev,
          page: true,
        };
      });
      const getProfileFilters = await services.get("/po/ca/profileVerficationFilter");
      const getAssigneeName = await services.get(`/po/ca/collegeAdmin?ug_id=${batch_id}`);
      const preUser = await services.get("/user/userDetail");
      const allbatch = await services.get(`/po/ca/batches?ug_id=${batch_id}`);

      setBatchData(allbatch.batches.find((item) => batch_id == item.id));

      setUserDetails(preUser.userDetail);
      setAllAssignee(getAssigneeName.data.college_admin);
      setScheduleTime(getAssigneeName.data.remove_editing_timestamp);
      setProfileFilters({
        studentStatusData: getProfileFilters.studentStatusData,
        profileVerficationData: getProfileFilters.profileVerficationData,
      });
      setLoaders((prev) => {
        return {
          ...prev,
          page: false,
        };
      });
    };

    handleEffect();
  }, []);

  useEffect(() => {
    if (selectedAssignee !== null || selectedStatus !== null || searchString !== "") {
      handleStatusFilter(page);
      setPage((prev) => prev + 1);
      setLoaders((prev) => {
        return {
          ...prev,
          loaderComplete: false,
        };
      });
    } else {
      const timeOut = setTimeout(() => {
        setPage((prev) => prev + 1);
        studentDataGetter();
      }, 2000);

      return () => clearTimeout(timeOut);
    }
  }, [changePage]);

  // USEEFFECT TO HANDLE Search Strings
  useEffect(() => {
    if (debounceValue !== "") {
      handleStatusFilter(page);
    } else if (selectedAssignee !== null || selectedStatus !== null) {
      handleStatusFilter(page);
      setPage((prev) => prev + 1);
      setLoaders((prev) => {
        return {
          ...prev,
          loaderComplete: false,
        };
      });
    }
  }, [debounceValue]);

  const handleMouseEnter = (userId) => {
    setShowToolTip(userId);
  };

  const handleMouseLeave = () => {
    setShowToolTip(null);
  };

  //SCROLL HANDLE FUNCTIONS
  useEffect(() => {
    const div = scrollRef.current;
    const handleScroll = () => {
      if (div.scrollTop + div.clientHeight >= div.scrollHeight - 1) {
        console.log("Reached the bottom of the div");
        runFunctionAtBottom();
      }
    };

    const runFunctionAtBottom = () => {
      if (!loaders.loaderComplete) {
        setLoaders((prev) => {
          return {
            ...prev,
            lazyLoader: true,
          };
        });
      }
      setChangePage((prev) => !prev);
    };

    if (div) {
      div.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener("scroll", handleScroll);
      }
    };
  }, [studentData]);

  return (
    <div>
      <SideNavigationBar />
      <ToastContainer />
      {loaders.page ? (
        <SmallPageLoader show={loaders.page} />
      ) : (
        <Box sx={{ paddingLeft: "5rem", height: "100vh", paddingBottom: "1rem" }}>
          <Typography sx={{ padding: "1rem", borderBottom: `1px solid ${Color.neutralMidGrey}`, fontSize: "32px" }}>Students - {batchData.user_group_name}</Typography>
          <Box sx={{ display: "flex", alignItems: "stretch" }}>
            <Box sx={{ flex: 1, padding: "1rem", display: "flex", flexDirection: "column", gap: "1rem", borderRight: `1px solid #E6E6E6` }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <img src="https://ccicons.s3.amazonaws.com/po/Group.svg" style={{ width: "100px", aspectRatio: "1/1" }} />
                <Typography sx={{ fontSize: "28px" }}>{batchData?.student_count} students</Typography>
              </Box>
              <hr />
              <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>Important links</Typography>
                <Typography sx={{ color: Color.primary1, fontSize: "16px", "&:hover": { cursor: "pointer" } }} onClick={() => navigate("/CollegeInfoPage")}>
                  Dashboard
                </Typography>
                <Typography sx={{ color: Color.primary1, fontSize: "16px", "&:hover": { cursor: "pointer" } }} onClick={() => navigate("/JobsPostingPage", { state: { batch_id: batch_id, batch_name: batch_name, college_id: college_id } })}>
                  Opportunities
                </Typography>
                <Typography sx={{ color: Color.primary1, fontSize: "16px", "&:hover": { cursor: "pointer" } }} onClick={() => navigate("/companies")}>
                  Companies
                </Typography>
                {/* <Typography sx={{ color: Color.primary1, fontSize: '16px' }}>Placed Students</Typography>
								<Typography sx={{ color: Color.primary1, fontSize: '16px' }}>Group Chats</Typography> */}
              </Box>
              <hr />
              <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>Profile Completion</Typography>

                {scheduleTime === null ? (
                  <Box sx={{ display: "flex", padding: "40px", border: `1px solid ${Color.neutralLightGrey}}`, justifyContent: "center", alignItems: "center" }}>
                    <Button sx={{ color: Color.red, border: `1px solid ${Color.red}`, width: "fit-content", textTransform: "none", alignSelf: "center" }} onClick={() => setScheduleModal(true)}>
                      <CalendarMonth sx={{ color: Color.red, marginRight: "2px", fontSize: "20px" }} /> Set Due Date
                    </Button>
                  </Box>
                ) : new Date(scheduleTime) > new Date() ? (
                  <Box sx={{ padding: "16px", border: `1px solid ${Color.neutralLightGrey}}` }}>
                    <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
                      <CalendarMonth sx={{ color: Color.neutralMidGrey, marginRight: "2px", fontSize: "20px" }} />
                      <Typography sx={{ color: Color.neutralMidGrey, fontSize: "12px" }}>Due Date:</Typography>
                    </Box>
                    <Typography sx={{ color: Color.primary1, fontSize: "12px" }}>{convertToTimeFormat(scheduleTime)}</Typography>
                    <Box sx={{ display: "flex", gap: "2px" }}>
                      <IconButton sx={{ display: "flex", gap: "2px", "&:hover": { background: "none" } }} onClick={() => setScheduleModal(true)}>
                        <AddBoxOutlined sx={{ color: Color.primary1, marginRight: "2px", fontSize: "20px" }} />
                        <Typography sx={{ color: Color.primary1, fontSize: "14px" }}>Extend</Typography>
                      </IconButton>

                      <IconButton sx={{ display: "flex", gap: "2px", "&:hover": { background: "none" } }} onClick={() => setCancelScheduleModal(true)}>
                        <EventAvailable sx={{ color: Color.primary1, marginRight: "2px", fontSize: "20px" }} />
                        <Typography sx={{ color: Color.primary1, fontSize: "14px" }}>Close</Typography>
                      </IconButton>
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ padding: "16px", border: `1px solid ${Color.neutralLightGrey}}` }}>
                    <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
                      <CalendarMonth sx={{ color: Color.neutralMidGrey, marginRight: "2px", fontSize: "20px" }} />
                      <Typography sx={{ color: Color.neutralMidGrey, fontSize: "12px" }}>Deadline Ended:</Typography>
                    </Box>
                    <Typography sx={{ color: Color.red, fontSize: "12px" }}>{convertToTimeFormat(scheduleTime)}</Typography>
                    <Box sx={{ display: "flex", gap: "2px", justifyContent: "center" }}>
                      <IconButton sx={{ display: "flex", gap: "2px", "&:hover": { background: "none" } }} onClick={() => setScheduleModal(true)}>
                        <AddBoxOutlined sx={{ color: Color.primary1, marginRight: "2px", fontSize: "20px" }} />
                        <Typography sx={{ color: Color.primary1, fontSize: "14px" }}>Set New Due Date</Typography>
                      </IconButton>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>

            <Box sx={{ flex: 4, padding: "1rem", display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: "1rem", alignItems: "center" }}>
                <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                  <Typography sx={{ color: Color.neutralDarkGrey }}>Status:</Typography>
                  <FormControl sx={{ width: "200px", height: "40px", padding: 0 }}>
                    <Select
                      labelId="select-status"
                      id="select-status"
                      value={selectedStatus}
                      sx={{ height: "40px", fontSize: "14px" }}
                      onChange={(e) => {
                        setSelectedStatus(e.target.value);
                        setPage(1);
                        setLoaders((prev) => {
                          return {
                            ...prev,
                            loaderComplete: false,
                          };
                        });
                        setChangePage((prev) => !prev); //This is to run the useEffect and fetch data for new Filters
                      }}
                      inputProps={{ style: { padding: "0px" } }}
                    >
                      {profileFilters.studentStatusData.map((item) => (
                        <MenuItem value={item}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedStatus && (
                    <IconButton
                      onClick={() => {
                        setSelectedStatus(null);
                        setPage(1);
                        setChangePage((prev) => !prev); //
                        setLoaders((prev) => {
                          return {
                            ...prev,
                            loaderComplete: false,
                          };
                        });
                        // studentDataGetter();
                      }}
                      size="small"
                      style={{}}
                    >
                      <Clear />
                    </IconButton>
                  )}
                </Box>

                <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                  <Typography sx={{ color: Color.neutralDarkGrey }}>Assignee:</Typography>
                  <FormControl sx={{ width: "200px", height: "40px", padding: 0 }}>
                    <Select
                      labelId="select-status"
                      id="select-status"
                      value={selectedAssignee}
                      sx={{ height: "40px", fontSize: "14px" }}
                      inputProps={{ style: { padding: "0px" } }}
                      onChange={(e) => {
                        setSelectedAssignee(e.target.value);
                        setPage(1);
                        setChangePage((prev) => !prev); //
                        setLoaders((prev) => {
                          return {
                            ...prev,
                            loaderComplete: false,
                          };
                        });
                      }}
                    >
                      {allAssignee.map((item) => (
                        <MenuItem value={item}>{item.college_admin_name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedAssignee && (
                    <IconButton
                      onClick={() => {
                        setSelectedAssignee(null);
                        setPage(1);
                        setChangePage((prev) => !prev); //
                        setLoaders((prev) => {
                          return {
                            ...prev,
                            loaderComplete: false,
                          };
                        });
                        // studentDataGetter();
                      }}
                      size="small"
                      style={{}}
                    >
                      <Clear />
                    </IconButton>
                  )}
                </Box>

                <TextField
                  sx={{ width: "100%" }}
                  inputProps={{ style: { height: "40px", padding: "0px 16px" } }}
                  InputProps={{ startAdornment: <Search /> }}
                  placeholder="Type a name or contact info"
                  value={searchString}
                  onChange={(e) => {
                    setSearchString(e.target.value);
                    setPage(1);
                    if (e.target.value === "" && selectedAssignee === null && selectedStatus === null) {
                      setStudentData([]);
                      pageOneGetter();
                    } else if (e.target.value === "") {
                      handleStatusFilter(1);

                      setLoaders((prev) => {
                        return {
                          ...prev,
                          loaderComplete: false,
                        };
                      });
                    }
                  }}
                />
              </Box>

              {markedStudents.length > 0 && (
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography sx={{ color: "#E8505B" }}>{markedStudents.length} student selected</Typography>

                  {accessRights.find((item) => item.access_right_id === 4)?.is_activated === 1 && (
                    <FormControl sx={{ width: "200px", height: "40px", padding: 0 }} error>
                      <Select labelId="assignee-label" id="assignee-label" value={selectPostAssignee} sx={{ fontSize: "14px", height: "40px" }} inputProps={{ style: { padding: "0px" } }} onChange={(e) => handleAddAssignee(e.target.value)} renderValue={(value) => <Typography sx={{ color: "red" }}>Assign to</Typography>}>
                        {allAssignee.map((item) => (
                          <MenuItem value={item}>{item.college_admin_name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Box>
              )}

              <Box sx={{}}>
                {studentData.length > 0 ? (
                  <Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "8px", paddingBottom: "1rem", gap: "16px" }}>
                      <Box sx={{ width: "25%", display: "flex", alignItems: "center", gap: "2px" }}>
                        <Checkbox
                          checked={markedStudents.length > 0 ? true : false}
                          sx={{ "&.Mui-checked": { color: "#E8505B" }, padding: "" }}
                          checkedIcon={<RemoveOutlined sx={{ color: "#fff", background: "#E8505B", fontSize: "18px", borderRadius: "2px" }} />}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setMarkedStudents([...studentData]);
                              setStudentData((prev) => {
                                return prev.map((student) => {
                                  return {
                                    ...student,
                                    checked: true,
                                  };
                                });
                              });
                            } else {
                              setStudentData((prev) => {
                                return prev.map((student) => {
                                  return {
                                    ...student,
                                    checked: false,
                                  };
                                });
                              });
                              setMarkedStudents([]);
                            }
                          }}
                        />
                        <Typography sx={{ fontSize: "14px", color: "#545454" }}>Student</Typography>
                      </Box>
                      <Box sx={{ width: "30%", display: "flex", alignItems: "center", gap: "2px" }}>
                        <Typography sx={{ fontSize: "14px", color: "#545454" }}>Contact</Typography>
                      </Box>

                      <Box sx={{ width: "15%", display: "flex", alignItems: "center", gap: "2px" }}>
                        <Typography sx={{ fontSize: "14px", color: "#545454" }}>Status</Typography>{" "}
                      </Box>

                      <Box sx={{ width: "15%", display: "flex", alignItems: "center", gap: "2px" }}>
                        <Typography sx={{ fontSize: "14px", color: "#545454" }}>Verification</Typography>
                      </Box>

                      <Box sx={{ width: "15%", display: "flex", alignItems: "center", gap: "2px" }}>
                        <Typography sx={{ fontSize: "14px", color: "#545454" }}>Assignee</Typography>
                      </Box>
                    </Box>
                    <Box
                      ref={scrollRef}
                      sx={{
                        overflowY: "auto",
                        paddingRight: "8px",
                        height: "63vh",
                        paddingBottom: "20px",
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "transparent",
                          borderRadius: "15px",
                          height: "30%",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: Color.primary1.concat(90),
                          borderRadius: "15px",
                          backgroundClip: "padding-box",
                        },
                        "&::-webkit-scrollbar": {
                          width: "4px",
                        },
                      }}
                    >
                      {studentData.map((student) => (
                        <Box sx={{ gap: "16px", display: "flex", justifyContent: "space-between", alignItems: "center", paddingBlock: "1rem", borderTop: `1px solid ${Color.neutralLightGrey}`, background: student.checked ? "rgba(247, 247, 247, 1)" : "#fff", paddingRight: "8px" }}>
                          <Box sx={{ width: "25%", display: "flex", alignItems: "center", gap: "2px", overflow: "hidden" }}>
                            <Checkbox sx={{ "&.Mui-checked": { color: "#E8505B" } }} onChange={(e) => handleMarkStudents(e, student)} checked={student.checked} />
                            {student.url === null ? <AccountCircle sx={{ fontSize: "40px", marginRight: "5px" }} /> : <img src={student.url} style={{ width: "40px", aspectRatio: "1/1", objectFit: "contain", borderRadius: "50%", marginRight: "5px" }} />}
                            <Typography sx={{ fontSize: "14px", color: [9, 10].includes(student.po_student_status_id) ? Color.neutralMidGrey : "#000" }}>{`${student.first_name} ${student.last_name}`}</Typography>
                          </Box>
                          <Box sx={{ width: "30%", display: "flex", justifyContent: "space-between", gap: "2px", overflow: "hidden", position: "relative" }} onMouseEnter={() => handleMouseEnter(student.user_id)} onMouseLeave={() => handleMouseLeave()}>
                            <Typography sx={{ fontSize: "14px", color: [9, 10].includes(student.po_student_status_id) ? Color.neutralMidGrey : "#000", zIndex: 10 }} noWrap>
                              {student.email_id}
                              <br />
                              {student.ph_no}
                            </Typography>
                            {showToolTip === student.user_id && (
                              <Button
                                sx={{ width: "10%", position: "absolute", background: Color.white, borderRadius: "4px", right: 0.2, boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;", padding: "8px 2px", zIndex: 990, "&:hover": { zIndex: 990, background: Color.white } }}
                                onClick={() => {
                                  try {
                                    navigator.clipboard.writeText(student.email_id);
                                    toast.info("Email Copied", { autoClose: 1000, style: { height: "40px" }, icon: <Check sx={{ color: Color.primary1 }} /> });
                                  } catch (error) {
                                    toast.error("Failed to copy Email");
                                  }
                                }}
                              >
                                <CopyAllOutlined sx={{ color: Color.primary1, fontSize: "16px", zIndex: 999 }} />
                              </Button>
                            )}
                          </Box>

                          <Box sx={{ width: "15%", display: "flex", alignItems: "center", gap: "2px" }}>
                            <Typography sx={{ fontSize: "14px", color: student.po_student_status_id === 7 ? Color.green : student.po_student_status_id === 8 ? Color.accent : Color.neutralMidGrey }}>{status_name[student.student_status_name]}</Typography>
                          </Box>

                          <Box sx={{ width: "15%", display: "flex", alignItems: "center", gap: "2px" }}>
                            <Button
                              sx={{
                                color: [1, 2].includes(student.po_profile_verfication_id) ? Color.redhex : [3, 4].includes(student.po_profile_verfication_id) ? Color.primary1 : Color.green,
                                background: [1, 2].includes(student.po_profile_verfication_id) ? Color.redhex.concat(20) : [3, 4].includes(student.po_profile_verfication_id) ? "#fff" : Color.green.concat(20),
                                borderRadius: "12px",
                                textTransform: "none",
                                padding: "2px 12px",
                                width: "100px",
                                border: [3, 4].includes(student.po_profile_verfication_id) ? `1px solid ${Color.primary1}` : "none",
                                "&:hover": {
                                  color: [1, 2].includes(student.po_profile_verfication_id) ? Color.redhex : [3, 4].includes(student.po_profile_verfication_id) ? Color.primary1 : Color.green,
                                  background: [1, 2].includes(student.po_profile_verfication_id) ? Color.redhex.concat(20) : [3, 4].includes(student.po_profile_verfication_id) ? "#fff" : Color.green.concat(20),
                                },
                              }}
                              onClick={() =>
                                navigate(`/ProfileVerification`, {
                                  state: {
                                    batch_id: batch_id,
                                    batch_name: batch_name,
                                    user_id: student.user_id,
                                    student: { ...student },
                                  },
                                })
                              }
                            >
                              {[1, 2].includes(student.po_profile_verfication_id) ? "Start" : [3, 4].includes(student.po_profile_verfication_id) ? "Continue" : "Verified"}
                            </Button>
                          </Box>

                          <Box sx={{ width: "15%", display: "flex", alignItems: "center", gap: "4px", justifyContent: "space-between" }}>
                            <Box sx={{ position: "relative", width: "100%", padding: "4px 8px", borderRadius: "8px", "&:hover": { boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)", cursor: "pointer" } }}>
                              <Tooltip title={accessRights.find((item) => item.access_right_id === 4)?.is_activated === 1 ? "" : tooltipText}>
                                <Typography sx={{ fontSize: "14px" }} onClick={() => setIsHovered((prev) => (prev === student.user_id ? null : student.user_id))}>
                                  {student.assignee_name === null ? "N/A" : `${student.assignee_name.split(" ")[0]} ${student.assignee_name.split(" ")[1] && student.assignee_name.split(" ")[1][0]}`}
                                </Typography>
                              </Tooltip>

                              {isHovered === student.user_id && accessRights.find((item) => item.access_right_id === 4)?.is_activated === 1 && (
                                <Box sx={{ position: "absolute", padding: "10px", background: "#fff", zIndex: 10, borderRadius: "8px", width: "180px", boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)", left: "-10px" }}>
                                  <TextField placeholder="Search assignee" InputProps={{ style: { height: "30px", padding: "0px", margin: "0px", width: "150px", fontSize: "14px" } }} sx={{ padding: "0px", margin: "0px" }} value={assigneeSearch} onChange={(e) => setAssigneeSearch(e.target.value)} />
                                  <hr style={{ margin: "10px 0px 0px 0px" }} />
                                  <Typography sx={{ padding: "5px", "&:hover": { background: Color.primary2 } }} onClick={() => handleUnAssign(student)}>
                                    Unassigned
                                  </Typography>
                                  {assigneeSearch === ""
                                    ? allAssignee.slice(0, 2).map((item) => (
                                        <Typography sx={{ padding: "5px", "&:hover": { background: Color.primary2 } }} onClick={() => handleUpdateAssignee(student, item)}>
                                          {item.college_admin_name}
                                        </Typography>
                                      ))
                                    : allAssignee
                                        .filter((i) => i.college_admin_name.toLowerCase().includes(assigneeSearch))
                                        .map((item) => (
                                          <Typography sx={{ padding: "5px", "&:hover": { background: Color.primary2 } }} onClick={() => handleUpdateAssignee(student, item)}>
                                            {item.college_admin_name}
                                          </Typography>
                                        ))}
                                </Box>
                              )}
                            </Box>
                            {
                              <Box
                                sx={{ position: "relative" }}
                                onClick={() => {
                                  if (accessRights.find((item) => item.access_right_id === 5)?.is_activated === 1) {
                                    setSelectedStudent(student);
                                    setChatModal(true);
                                  }
                                }}
                              >
                                {student.chat_status_name === "open" && <Box sx={{ position: "absolute", width: "8px", height: "8px", background: "#E8505B", borderRadius: "50%", right: 0 }}></Box>}
                                <Tooltip title={accessRights.find((item) => item.access_right_id === 5)?.is_activated === 1 ? "" : tooltipText}>
                                  <ChatBubbleOutline sx={{ color: Color.primary1 }} />
                                </Tooltip>
                              </Box>
                            }
                          </Box>
                        </Box>
                      ))}

                      {!loaders.page && loaders.lazyLoader && <StudentLoader />}
                    </Box>
                  </Box>
                ) : (
                  !loaders.lazyLoader && <Box>No students </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Modal open={scheduleModal} onClose={() => setScheduleModal(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Calender handleSave={(value) => handleSetTime(value)} handleModalClose={() => setScheduleModal(false)} scheduleTime={scheduleTime} />
      </Modal>

      <Modal open={postAssigneeModal} onClose={() => setPostAssigneeModal(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ padding: "1rem", borderRadius: "12px", background: "#fff", width: "500px" }}>
          <Typography>Assign the following student(s) a assignee:</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", marginLeft: "16px", marginBlock: "16px" }}>
            {markedStudents.map((item, index) => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ fontSize: "14px" }}>
                  {index + 1}. {item.first_name} {item.last_name}
                  {item.roll_no}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Typography sx={{ color: Color.neutralDarkGrey }}>Assign To:</Typography>
            <FormControl sx={{ width: "200px", height: "40px", padding: 0 }}>
              <Select labelId="select-status" id="select-status" value={selectPostAssignee} sx={{ height: "40px", fontSize: "14px" }} inputProps={{ style: { padding: "0px" } }} onChange={(e) => setSelectPostAssignee(e.target.value)}>
                {allAssignee.map((item) => (
                  <MenuItem value={item}>{item.college_admin_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem", marginTop: "16px" }}>
            <Button
              sx={{ border: `1px solid ${Color.primary1}`, color: Color.primary1, padding: "5px 8px", width: "fit-content", textTransform: "none" }}
              onClick={() => {
                setPostAssigneeModal(false);
                setSelectPostAssignee(null);
              }}
            >
              Cancel
            </Button>
            <Button sx={{ background: Color.primary1, color: "#fff", padding: "5px 8px", width: "fit-content", textTransform: "none", "&:hover": { background: Color.primary1, color: "#fff" } }} onClick={() => handleAddAssignee()}>
              Assign
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={chatModal} onClose={() => setChatModal(false)} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", padding: "8px" }}>
        <StudentChatModal selectedStudent={selectedStudent} setSelectedStudent={setSelectedStudent} setStudentData={setStudentData} userDetails={userDetails} close={() => setChatModal(false)} batch_id={batch_id} />
      </Modal>

      <Modal open={cancelScheduleModal} onClose={() => setCancelScheduleModal(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ padding: "1rem", borderRadius: "12px", background: "#fff", width: "500px" }}>
          <Typography sx={{ fontSize: "24px" }}>Close/Lock all profiles now? </Typography>
          <hr style={{ marginBlock: "8px" }} />
          <Typography sx={{ fontSize: "16px" }}>On confirming, students of this batch will NOT be able to update/edit their profiles. Later, student profiles can be unlocked from batch settings.</Typography>

          <Box style={{ marginTop: "2rem", position: "relative" }}>
            <Box
              sx={{ padding: "16px", border: `1px dashed ${Color.neutralDarkGrey}`, background: "#fff", "&:hover": { boxShadow: "4px 8px 15.9px 6px rgba(0, 0, 0, 0.15)" }, zIndex: "999", position: "relative" }}
              onMouseEnter={(e) => {
                setNotificationHover(true);
              }}
              onMouseLeave={(e) => {
                setNotificationHover(false);
              }}
            >
              <Box className="PreviousAndSubmit" sx={{ background: "#fff", zIndex: "100" }}>
                <Box style={{ textAlign: "center", background: Color.neutralMidGrey, padding: "2px 3px", borderRadius: "8px", marginRight: "5px" }}>
                  <Notifications sx={{ color: Color.white, transform: "rotate(30deg)", fontSize: "20px" }} />
                </Box>
                <Typography>Notify students about this shortlist?</Typography>
              </Box>
              <Box className="PreviousAndSubmit" sx={{ background: "#fff", zIndex: "100" }}>
                <Checkbox disabled />
                <Typography class="alignInCenter">Entire Batch</Typography>
              </Box>
            </Box>
            <Box sx={{ zIndex: "1" }}>
              {notificationHover && (
                <motion.div style={{ display: "flex", gap: "2px", position: "absolute", background: "rgba(232, 80, 91, 1)", padding: "5px 12px", right: 1, borderRadius: "8px 8px 0px 0px", zIndex: "10" }} transition={{ duration: 0.1, type: "spring" }} initial={{ top: "0px" }} animate={notificationHover ? { top: "-30px" } : { top: "0px" }}>
                  <img src="https://ccicons.s3.amazonaws.com/po/eggCracked.svg" style={{ objectFit: "contain", height: "20px", aspectRatio: "1/1" }} />
                  <Typography sx={{ color: Color.white, fontSize: "14px", fontWeight: "600" }}>Cracking Soon!</Typography>
                  <Typography sx={{ color: Color.white, fontSize: "14px" }}>Notification Control Center</Typography>
                </motion.div>
              )}
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem", marginTop: "16px" }}>
            <Button
              sx={{ border: `1px solid ${Color.primary1}`, color: Color.primary1, padding: "5px 8px", width: "fit-content", textTransform: "none" }}
              onClick={() => {
                setCancelScheduleModal(false);
              }}
            >
              Cancel
            </Button>
            <Button sx={{ background: Color.primary1, color: "#fff", padding: "5px 8px", width: "fit-content", textTransform: "none", "&:hover": { background: Color.primary1, color: "#fff" } }} onClick={() => cancelSchedule()}>
              Lock All Profiles
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default PVStudentBatch;

const StudentLoader = () => {
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "8px", paddingBottom: "1rem" }}>
        <Box sx={{ flex: 1.4, display: "flex", alignItems: "center", gap: "2px" }}>
          <Skeleton width={20} height={40} />

          <Skeleton width={120} height={50} />
        </Box>
        <Box sx={{ flex: 1.5, display: "flex", alignItems: "center", gap: "2px" }}>
          <Skeleton width={120} height={50} />
        </Box>

        <Box sx={{ flex: 1, display: "flex", alignItems: "center", gap: "2px" }}>
          <Skeleton width={80} height={50} />
        </Box>

        <Box sx={{ flex: 1, display: "flex", alignItems: "center", gap: "2px" }}>
          <Skeleton width={80} height={50} />
        </Box>

        <Box sx={{ flex: 1, display: "flex", alignItems: "center", gap: "2px", justifyContent: "space-between" }}>
          <Skeleton width={80} height={50} />
          <Skeleton width={30} height={40} />
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "8px", paddingBottom: "1rem" }}>
        <Box sx={{ flex: 1.4, display: "flex", alignItems: "center", gap: "2px" }}>
          <Skeleton width={20} height={40} />
          <Skeleton width={120} height={50} />
        </Box>
        <Box sx={{ flex: 1.5, display: "flex", alignItems: "center", gap: "2px" }}>
          <Skeleton width={120} height={50} />
        </Box>

        <Box sx={{ flex: 1, display: "flex", alignItems: "center", gap: "2px" }}>
          <Skeleton width={80} height={50} />
        </Box>

        <Box sx={{ flex: 1, display: "flex", alignItems: "center", gap: "2px" }}>
          <Skeleton width={80} height={50} />
        </Box>

        <Box sx={{ flex: 1, display: "flex", alignItems: "center", gap: "2px", justifyContent: "space-between" }}>
          <Skeleton width={80} height={50} />
          <Skeleton width={30} height={40} />
        </Box>
      </Box>
    </Box>
  );
};
