import React, { useRef, useState, useEffect, useContext } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import "../../Dashboard/College1x1StudentDetails/ModalSt";
import Modal from "react-bootstrap/Modal";
import httpService from "../../../services/httpService";
import { UserTypeContext as RowsContext } from '../../RowsContext/RowsContext'
import { green } from "@mui/material/colors";
// import ChatModal from "./ModalChat";
// import ReScheduleConfirmation from "./ReScheduleConfirmationModal";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import './ModalSt.css'
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import {List , ListItem} from '@mui/material'
import { get_specialCharReplace } from "../../../services/specialCharReplace";
import { extractDateFromDateString } from "../../../services/specialCharReplace";
import { calculateEpochTimeInLocalTime } from "../../../services/specialCharReplace";
export default function ModalSt(props) {
    const service = new httpService();
    const [user_type_id, SetUser_type_id] = useState(null);
    const rowsData = useContext(RowsContext);
    const [openChat, setOpenChat] = useState(false);
    const [openRescheduleModal, setRescheduleModal] = useState(false);

    const [rowsD, setRowsD] = useState(rowsData);
    const { show, setShow, headCells, from_table, path, studentsDetailsClick, feedbackFields, studentFeedbackFields,mcqFeedbackFields } = props;
    console.log("info.................", studentsDetailsClick);
    const [name, setName] = useState(rowsData[from_table?.index]?.name);
    const [special, setSpecial] = useState()
    const [jd, setJD] = useState()
    const modalHide = () => {
        setShow(!show);
        console.log(!show + "bruh --------");
        setName("")
        setSpecial("")
        setJD("")
    };


    useEffect(() => {
        console.log("Brother")
        console.log("from table inside useEffect of modal input:", from_table)
        function handleEffect() {
            if (from_table?.index !== undefined || rowsData[from_table?.index]?.name !== "") {
                console.log("Inside if cond of useEffect",)
                setName(rowsData[from_table?.index]?.name)
                setSpecial(rowsData[from_table?.index]?.specialization_id)
                setJD(rowsData[from_table?.index]?.default_jd)
            }
            else if (from_table?.index === undefined || rowsData[from_table?.index]?.name === "") {
                console.log("Inside else if cond of useEffect")
                setName("")
            }

            console.log("bhoomikaaaaa", from_table?.index);
        }
        handleEffect();
    }, [from_table])






    //Handle Change for Add button-chat-now
    const handleChangeAddbutton = (e, index) => {

        //Logs for entering values
        console.log("Name is: ", name);
        console.log("name from form  is:", e.target.name);
        console.log("value from form  is:", e.target.value);


        //Conditions for storing values
        if (e.target.name === "specialization-input") {
            setName(e.target.value)
            console.log("add-specialization-input")
        }
        else if (e.target.name === "specialization-select") {
            setSpecial(e.target.value)
            console.log("add-specialization-select")

        } else if (e.target.name === "normal-input") {
            setName(e.target.value)
            console.log("add-normal-input")
        } else if (e.target.name === "jd-select") {
            setJD(parseInt(e.target.value))
            console.log("add-normal-input")
        }
    };


    //Handle Change for Edit button-chat-now
    const handleChange = (e, index) => {

        //Logs for entering values
        console.log("Name is: ", name);
        console.log("name from form  is:", e.target);
        console.log("value from form  is:", e.target.value);

        //Conditions for storing values
        if (e.target.name === "specialization-input") {
            setName(e.target.value)
            console.log("add-specialization-input")
        } else if (e.target.name === "specialization-select") {
            setSpecial(e.target.value)
            console.log("add-specialization-select")

        } else if (e.target.name === "jd-select") {
            setJD(parseInt(e.target.value))
            console.log("add-normal-input")
        } else if (e.target.name === "normal-input") {
            setName(e.target.value)
            console.log("add-normal-input")
        }

    };




    const get_data_from_form = (name) => {
        console.log("HandleEditUser ~ special", special)
        console.log("🚀HandleEditUser ~ name", name)
        console.log("🚀HandleEditUser ~ id", from_table?.id)
        // name.preventDefault();

        if (path === "/RoleType") {
            if (from_table?.id === null || from_table?.id === undefined) {
                console.log("POST")
                props.data_getter(name.trim(), undefined, undefined, special.trim(), jd)
                setName("")
                setSpecial("")
                setJD("")
            }
            else {
                console.log("PUT")
                console.log("PUT - special value:", special)
                props.data_getter(name.trim(), from_table?.id, from_table?.index, special, jd)
            }
        }

        else if (path === "/UserType" || path === "/ProgramType" || path === "/BatchType" || path === "/SpecilizationType") {
            if (from_table?.id === null || from_table?.id === undefined) {
                console.log("POST")
                props.data_getter(name.trim())
                setName("")
            }
            else {
                console.log("PUT")
                props.data_getter(name.trim(), from_table?.id, from_table?.index)
            }
        }

        setShow(false)
    }

    const [TogglechatName, setTogglechatName] = useState("Chat now")
    const OpenChatiing = (text) => {
        setOpenChat(true);
        if (text === TogglechatName) {
            setTogglechatName("Close Chat")
        }
        else {
            setTogglechatName(text)

        }

    }

    console.log("rows data in modal input file", rowsData);
    console.log("spec_data", props.spec_data);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            console.log('inside handlepress')
            get_data_from_form(name)
        }
    }

    const MarkResolve = () => {
        setRescheduleModal(true);
    }
    // const slotsData = JSON.parse(studentsDetailsClick?.order_details?.slots);
    // const slots_selected =  slotsData?.timing?.map(slot => slot?.slot_name)
    // console.log("slots selected", slots_selected);

    const slotsDataJson = studentsDetailsClick?.order_details?.slots;
    const slotsData = slotsDataJson ? JSON.parse(slotsDataJson) : {};
    const slotNames = slotsData?.timing?.map(timing => timing?.slot_name) ?? [];

    const resumeDataJson = studentsDetailsClick?.order_details?.resume
    const resumeData = resumeDataJson ? JSON.parse(resumeDataJson) : {};
    const resumeName = resumeData.name ?? " ";
    // console.log("slots selected", slotNames);

    console.log("studentsDetailsClick", studentsDetailsClick);



    return (
        <>
            <Modal
                className="ModalSt-contain"
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                // style={{ backdropFilter: "blur(5px)" }}
                onHide={() => modalHide()}
            >
                {console.log("Value of state name in Modal Input:", name)}
                {console.log("Value of state special in Modal Input:", special)}
                <Modal.Body
                    style={{
                        position: "absolute",
                        top: "-24px",
                        left: "127px",
                        height: "auto",
                        lineHeight: "1.4",
                        padding: "14px 28px",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        width: "880px",
                        float: "right",
                    }}>
                    {/* {studentsDetailsClick.map((details, index) => { */}
                    <div>
                        <CloseIcon className="closeIcon" style={{ float: "right" }} onClick={() => setShow(false)} />
                        <div style={{ marginTop: "30px", width: "100%" }}>
                            <p className="st-ticket-number">{studentsDetailsClick?.order_details?.act_type_name}</p>
                            <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex" }}>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: '200px' }}>
                                    <p className="label-grey-text pTagsHere" >Date:</p>
                                    <p className="label-grey-text pTagsHere">Time:</p>
                                    <p className="label-grey-text pTagsHere">Role:</p>
                                    <p className="label-grey-text pTagsHere">Specialization:</p>
                                    <p className="label-grey-text pTagsHere">Session Recording:</p>

                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: "100%" }}>
                                    <p className="pTagsHere">{extractDateFromDateString(studentsDetailsClick?.order_details?.start_time)}</p>
                                    <p className="pTagsHere">{calculateEpochTimeInLocalTime(studentsDetailsClick?.order_details?.start_time)} - {calculateEpochTimeInLocalTime(studentsDetailsClick?.order_details?.end_time)}</p>
                                    <p className="pTagsHere" >{studentsDetailsClick?.order_details?.role_name}</p>
                                    <p className="pTagsHere" >{studentsDetailsClick?.order_details?.specialization_name}</p>
                                    {studentsDetailsClick?.order_details?.recording_url !== null ? <>
                                        <button
                                            className="pTagsHere" style={{  border: "none", height: "15px", color: "#1C8EA8", background: "transparent", textAlign: "left", outline: "none", marginTop: 'auto', fontSize: '16px', marginBottom:"auto" }}
                                            onClick={() => {
                                                window.open(studentsDetailsClick?.order_details?.recording_url);
                                            }}
                                        >
                                            Recording
                                        </button>
                                    </> : <>
                                        <p className="providingSpaceForSubDetails textTagsHere" style={{ color: "black" }}>-- Recording is not available --</p>
                                    </>}
                                </div>
                            </div>

                        </div>
                        {console.log("initializing....", JSON.stringify(studentsDetailsClick?.order_details?.slots?.timing)?.map(slot => slot.slot_name))}
                        <hr />
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "100%" }}>
                                <p className="modal-subtitles-style">Student Details</p>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: '200px' }}>
                                        <p className="label-grey-text pTagsHere">Student Name:</p>
                                        <p className="label-grey-text pTagsHere">Student Email:</p>
                                        <p className="label-grey-text pTagsHere">Student Contact:</p>
                                        <p className="label-grey-text pTagsHere">Student Roll Number:</p>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: "100%" }}>
                                        <p className="pTagsHere">{studentsDetailsClick?.student_details?.first_name}{" "}{studentsDetailsClick?.student_details?.last_name}</p>
                                        <p className="pTagsHere">{studentsDetailsClick?.student_details?.email_id ? studentsDetailsClick?.student_details?.email_id : "N/A"}</p>
                                        <p className="pTagsHere">{studentsDetailsClick?.student_details?.ph_no ? studentsDetailsClick?.student_details?.ph_no : "N/A"}</p>
                                        <p className="pTagsHere">{studentsDetailsClick?.student_details?.roll_no ? studentsDetailsClick?.student_details?.roll_no : "N/A"}</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: "50%" }}>
                                <p className="modal-subtitles-style">Documents</p>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                    <div className="inner-div-container" style={{ height: 'auto', marginTop: '.5rem', marginBottom: '.5rem', width: "100%" }}>
                                        <div style={{ alignItems: "center", display: "flex", padding: "5px", border: "1.02857px solid rgba(42, 168, 196, 0.59)", borderRadius: "10px", backgroundColor: "rgba(56, 193, 223, 0.1)", overflowWrap: "break-word", width: "100%", height: "auto", margin: '0 auto' }}>
                                            <PictureAsPdfIcon style={{ color: "#1C8EA8", marginRight: ".5rem" }} />
                                            {/* <p style={{ width: "100%", marginTop: 'auto', marginBottom: 'auto', fontSize: '12px' }}>{resumeName}</p> */}
                                            <button
                                                className="textTagsHere" style={{ border: "none", height: "15px", color: "#1C8EA8", background: "transparent", textAlign: "left", outline: "none", marginBottom: '.5rem', fontSize: '16px' }}
                                                onClick={() => {

                                                    window.open(resumeData?.url);

                                                }}
                                            >
                                                {resumeName}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div style={{ width: "100%" }}>
                            <p className="modal-subtitles-style">Mentor Details</p>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: '200px' }}>
                                    <p className="label-grey-text pTagsHere">Mentor Name:</p>
                                    <p className="label-grey-text pTagsHere">LinkedIn:</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "16px", width: "100%" }}>
                                    <p className="pTagsHere">{studentsDetailsClick?.mentor_details?.first_name}{" "}{studentsDetailsClick?.mentor_details?.last_name}</p>
                                    <p className="pTagsHere">{studentsDetailsClick?.mentor_details?.linkedin ? studentsDetailsClick?.mentor_details?.linkedin : "N/A"}</p>
                                </div>
                            </div>
                        </div>
                        <hr />

                        <div>
                            <p className="modal-subtitles-style1">Student Notes</p>
                            {studentsDetailsClick?.student_feedback != null ? <>
                                {studentsDetailsClick?.student_feedback?.notes != null && (
                                    <Card sx={{ maxWidth: "100%", marginRight: "20px", marginBottom: "1.5rem" }}>
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">
                                                {studentsDetailsClick?.student_feedback?.notes}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )}
                            </> : <><p className="feedbackNotProvided">Student Notes has not been provided yet</p></>}

                        </div>
                        <div>
                            <p className="modal-subtitles-style1">Mentor Feedback</p>
                            {studentsDetailsClick.mentor_feedback !== null ? (
                                <>
                                    <Card sx={{ maxWidth: "100%", marginRight: "20px", marginBottom: "1.5rem" }}>
                                        {console.log("feedback fields", feedbackFields)}
                                        <CardContent>
                                            {feedbackFields?.responses?.map((item, index) => {
                                                const isType1 = typeof item.description === 'string';
                                                const isType2 = typeof item.description === 'object' && item.description.bullets;
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Typography variant="body2" color="text.primary" fontSize={17}>
                                                            {item.field_name} :
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {isType1 ? (
                                                                get_specialCharReplace(item.description)
                                                                    ?.split('\n')
                                                                    .map((line, index) => (
                                                                        <React.Fragment key={index}>
                                                                            {line}
                                                                            <br />
                                                                        </React.Fragment>
                                                                    ))
                                                            ) : isType2 ? (
                                                                <List>
                                                                    {item.description.bullets.map((bullet, index) => (
                                                                        <ListItem key={index} sx={{ display: 'list-item', listStyleType: 'disc', paddingLeft:"0px" }}>
                                                                            {get_specialCharReplace(bullet)}
                                                                        </ListItem>
                                                                    ))}
                                                                </List>
                                                            ) : (
                                                                "No Description Provided"
                                                            )}
                                                        </Typography>
                                                        {isType1 && (
                                                            <>
                                                                <Typography variant="body2" color="text.primary" fontSize={17} style={{ marginTop: "1rem" }}>
                                                                    Rating :
                                                                </Typography>
                                                                <Box
                                                                    className="feedbackRating1"
                                                                    sx={{
                                                                        "& > legend": { mt: 2 },
                                                                    }}
                                                                >
                                                                    <Rating
                                                                        sx={{ color: "#1C8EA8" }}
                                                                        className="feedbackRating"
                                                                        name="simple-controlled"
                                                                        value={item.rating}
                                                                        disabled
                                                                        icon={<StarIcon sx={{ color: "#1C8EA8" }} />}
                                                                        emptyIcon={<StarBorderIcon sx={{ color: "#1C8EA8" }} />}
                                                                    />
                                                                </Box>
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                            {mcqFeedbackFields === null ? (
                                                <></>
                                            ) : (
                                                <>
                                                    <p className="modal-subtitles-style1" style={{ fontSize: "18px", marginTop: "1rem", marginBottom: "0.5rem" }}>Mentor Mcq Feedback</p>
                                                    {mcqFeedbackFields?.responses?.map((item, index) => (
                                                        <React.Fragment key={index}>
                                                            <Typography variant="body2" color="text.primary" fontSize={17}>
                                                                {item.question} :
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {item.ans ? item.ans : "No Answer provided"}
                                                            </Typography>
                                                        </React.Fragment>
                                                    ))}
                                                </>
                                            )}
                                        </CardContent>
                                    </Card>
                                </>
                            ) : (
                                <>
                                    <p className="feedbackNotProvided">Mentor Feedback has not been provided yet</p>
                                </>
                            )}
                        </div>

                        <hr />
                    </div>
                    {/* })} */}
                </Modal.Body>
            </Modal>


        </>
    );
}


