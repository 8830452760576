import ListItemButton from "@mui/material/ListItemButton";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import Cookies from "js-cookie";
import { FaLayerGroup } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { setUser, updateActionRights } from "../../../store/reducers";
import "../SideNavigationBar/SideNavigationBar.css";
import { getAccessRights } from "../../../ReusableModals/Rights";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
const SideNavigationBar = (props) => {
  const navigate = useNavigate();
  const path = window.location.pathname;
  const service = new CCService()

  const { college_id, college_name } = props;
  const { userDetails, accessRights } = useSelector((store) => store.rights)
  const dispatch = useDispatch()
  console.log("form inputs in side", college_id);

  //OPEN collapsibles

  //LOGOUT
  const handleLogout = (e) => {
    console.log("Logout loader!!1");
    Cookies.remove("token");
    console.log("Logout loader!!2");
    navigate("/");
    console.log("Logout loader!!3");
  };

  //NAVIGATE functions

  const handleNavigateOfflineSession = (e) => {
    e.preventDefault();
    navigate("/OfflineSession", { state: { college_id: college_id, college_name: college_name } });
  };


  const handleNavigateCollegeAnalytics = (e) => {
    e.preventDefault();
    navigate("/CollegeAnalytics", { state: { college_id: college_id, college_name: college_name } });
  };

  const handleNavigateResumeAnalytics = (e) => {
    e.preventDefault();
    navigate("/ResumeAnalytics", { state: { college_id: college_id, college_name: college_name , fromResumesTemplatePath : true} });
  };

  const handleNavigatePlacementOps = (e) => {
    e.preventDefault();
    navigate(`/Batches`, { state: { college_id: college_id, college_name: college_name } });
  };


  const handleEffect = async () => {
    try {
      const preUser = await service.get("/user/userDetail");

      dispatch(setUser(preUser.userDetail))
    } catch (error) {
      console.log("Error: ", error)
    }
  }

  useEffect(() => {
    if (userDetails.user_id === null) {
      handleEffect()
    }
  }, [userDetails.user_id])



  const getRightsData = async () => {
    const data = await getAccessRights(userDetails.user_id)

    dispatch(updateActionRights(data))
  }


  useEffect(() => {
    if (userDetails.user_id !== null && accessRights.length === 0) {
      getRightsData()
    }
  }, [userDetails.user_id])




  console.log("ACCESSRIGHST", accessRights)

  return (
    <div>
      <div className="area">
        <nav className="main-menu">
          <ul>
            <li>
              <a href="/">
                <div style={{ padding: "10px" }}>
                  <img
                    alt="logo"
                    src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/Career_Carve_Logo__1_-removebg-preview+2.png"
                    style={{
                      margin: "17px 6px 75px 12px",
                      marginLeft: "6%",
                      width: "fit-content",
                      height: "50px",
                      float: "left",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </a>
            </li>
          </ul>
          <ul>
            <li class="has-subnav">
              <a href={"/CollegeInfoPage"}>
                <ListItemButton style={{ padding: "0px" }}>
                  <div style={{ display: "flex", width: "100%" }} className={path === "/CollegeInfoPage" ? "sidenav-image-a-dashboard" : ""}>
                    <div
                      style={{
                        padding: "15px",
                        margin: 16,
                        marginRight: 10,
                        paddingRight: "29px",
                        marginTop: 0,
                        marginBottom: 0,
                        borderRight: "none",
                      }}
                      className="avtive-icons"
                    >
                      {/* <div style={{ padding: "15px", margin:16,marginRight: 10, paddingRight:'29px', marginTop:0, marginBottom:0 ,borderRight: '2px solid rgba(0, 0, 0, 1)'}} > */}
                      <FaLayerGroup />
                    </div>
                    <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                      <span class="nav-text">Dashboard</span>
                    </div>
                  </div>
                </ListItemButton>
              </a>
            </li>
            <li class="has-subnav">
              {/* <a href={"/CollegeAnalytics"}> */}
              <div style={{ display: "flex", width: "100%" }} onClick={(e) => handleNavigateCollegeAnalytics(e)} className={path === "/CollegeAnalytics" || path === "/BatchWiseAnalytics" || path === "/StudentProcess" || path === "/DiagnosticInterview" || path === "/StudentWiseAnalytics" ? "sidenav-image-a-dashboard" : ""}>
                <ListItemButton style={{ padding: "0px" }}>
                  {/* <div style={{ padding: "15px", margin:16, marginRight: 10, paddingRight:'29px', marginTop:0, marginBottom:0, borderRight: '2px solid rgba(0, 0, 0, 1)'}} > */}
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 10,
                      paddingRight: "29px",
                      marginTop: 0,
                      marginBottom: 0,
                      borderRight: "none",
                    }}
                  >
                    <img
                      alt="logo"
                      src={path === "/CollegeAnalytics" || path === "/BatchWiseAnalytics" || path === "/StudentProcess" || path === "/DiagnosticInterview" || path === "/CollegeAnalytics" || path === "/BatchWiseAnalytics" || path === "/StudentProcess" || path === "/StudentWiseAnalytics" ? "https://ccicons.s3.amazonaws.com/analytics/icons8-analytics-90+2.svg" : "https://ccicons.s3.amazonaws.com/analytics/icons8-analytics-90.svg"}
                      style={{
                        // margin: "17px 6px 75px 12px",
                        // marginLeft: "6%",
                        width: "18px",
                        height: "18px",
                        // float: "left",
                        // cursor: "pointer",
                      }}
                    />
                  </div>
                  <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                    <span class="nav-text">Analytics</span>
                  </div>
                </ListItemButton>
              </div>
              {/* </a> */}
            </li>
            <li class="has-subnav">
              {/* <a href={"/CollegeAnalytics"}> */}
              <div style={{ display: "flex", width: "100%" }} onClick={(e) => handleNavigateResumeAnalytics(e)} className={path === "/ResumeAnalytics" || path === "/BatchWiseAnalytics" || path === "/StudentProcess" || path === "/DiagnosticInterview" || path === "/StudentWiseAnalytics" ? "sidenav-image-a-dashboard" : ""}>
                <ListItemButton style={{ padding: "0px" }}>
                  {/* <div style={{ padding: "15px", margin:16, marginRight: 10, paddingRight:'29px', marginTop:0, marginBottom:0, borderRight: '2px solid rgba(0, 0, 0, 1)'}} > */}
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 10,
                      paddingRight: "29px",
                      marginTop: 0,
                      marginBottom: 0,
                      borderRight: "none",
                    }}
                  >
                    <img
                      alt="logo"
                      src={path === "/ResumeAnalytics" || path === "/ResumesDigitalAnalytics"  ? "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/RDLogo.png" : "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/RDLogo.png"}
                      style={{
                        // margin: "17px 6px 75px 12px",
                        // marginLeft: "6%",
                        width: "18px",
                        height: "18px",
                        // float: "left",
                        // cursor: "pointer",
                      }}
                    />
                  </div>
                  <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                    <span class="nav-text">Resume Analytics</span>
                  </div>
                </ListItemButton>
              </div>
              {/* </a> */}
            </li>
            <li class="has-subnav">
              <div
                style={{ display: "flex", flexDirection: "column", width: "100%" }}
                // onClick={(e) => handleOpenPO(e)}
                onClick={(e) => handleNavigatePlacementOps(e)}
                className={path === "/Batches" || path === "/PostingOpportunity" || path === "/JobsPostingPage" || path === "/AllApplicants" ? "sidenav-image-a-dashboard" : ""}
              >
                <ListItemButton style={{ padding: "0px" }}>
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 10,
                      paddingRight: "29px",
                      marginTop: 0,
                      marginBottom: 0,
                      borderRight: "none",
                    }}
                  >
                    <WorkOutlineIcon sx={{ margin: "0px", padding: "0px", fontSize: "18px" }} />
                    {/* <img src="https://ccicons.s3.amazonaws.com/po/placementOps.webp" style={{width:"20px", aspectRatio:"1/1", objectFit:"contain" , borderRadius:"50%", mixBlendMode:""}} /> */}
                  </div>
                  <div style={{ padding: "10px 0px 10px 0px" }}>
                    <span class="nav-text">Placement Ops</span>
                  </div>
                </ListItemButton>
                {/* <Collapse in={openCollapsables.po} timeout="auto" unmountOnExit sx={{ marginTop: 0 }}>
                  <List component="div">
                    <ListItemButton sx={{ pl: 2, ml: 10, mt: 0, p: "4px" }}>
                      <ListItemText primary="Opportunities" onClick={(e) => handleNavigatePlacementOps(e)} className={path === "/Batches" ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 2, ml: 10, p: "5px" }}>
                      <ListItemText primary="Profile Verification" onClick={(e) => navigate('/ProfileVerification/Batches')} className={["/ProfileVerification", "/ProfileVerification/Batches", "/ProfileVerification/Batches/Studnets"].includes(path) ? "sidenav-image-a-dashboard" : ""} />
                    </ListItemButton>
                  </List>
                </Collapse> */}
              </div>
            </li>
            <li class="has-subnav">
              {/* <a href={"/CollegeAnalytics"}> */}
              <div style={{ display: "flex", width: "100%" }} onClick={(e) => handleNavigateOfflineSession(e)} className={path === "/OfflineSession" || path === "/StudentSummary" ? "sidenav-image-a-dashboard" : ""}>
                <ListItemButton style={{ padding: "0px" }}>
                  {/* <div style={{ padding: "15px", margin:16, marginRight: 10, paddingRight:'29px', marginTop:0, marginBottom:0, borderRight: '2px solid rgba(0, 0, 0, 1)'}} > */}
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 10,
                      paddingRight: "29px",
                      marginTop: 0,
                      marginBottom: 0,
                      borderRight: "none",
                    }}
                  >
                    <img
                      alt="logo"
                      src={path === "/OfflineSession" || path === "/StudentSummary"  ?
                        "https://ccicons.s3.amazonaws.com/offline/OfflineCollAdminIcon.svg" :
                        "https://ccicons.s3.amazonaws.com/offline/OfflineCollAdminBlack.svg"}
                      style={{
                        // margin: "17px 6px 75px 12px",
                        // marginLeft: "6%",
                        width: "18px",
                        height: "18px",
                        // float: "left",
                        // cursor: "pointer",
                      }}
                    />
                  </div>
                  <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                    <span class="nav-text">Offline Sessions</span>
                  </div>
                </ListItemButton>
              </div>
              {/* </a> */}
            </li>
            {/* Profile  */}
            {/* <li class="has-subnav">
              <div style={{ display: "flex", width: "100%" }} onClick={(e) => navigate("/Profile")}
                className={(path === "/Profile") ? "sidenav-image-a-dashboard" : ""}>
                <ListItemButton style={{ padding: "0px" }}>
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 10,
                      paddingRight: "29px",
                      marginTop: 0,
                      marginBottom: 0,
                      borderRight: "none",
                    }}
                  >
                    <Person />
                  </div>
                  <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                    <span class="nav-text">Profile</span>
                  </div>
                </ListItemButton>
              </div>
            </li> */}
          </ul>
          <ul class="logout" onClick={(e) => handleLogout(e)}>
            <li class="has-subnav">
              <a className="sidenav-image-a">
                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ padding: "18px 0px 18px 30px", width: "100px" }}>
                    <i class="fa fa-power-off"></i>
                  </div>
                  <div style={{ padding: "14px 0px 9px 0px", width: "80%" }}>
                    <span class="nav-text">Logout</span>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SideNavigationBar;
