import { Add, ApartmentOutlined, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Modal, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Color } from "../../../GlobalStyles";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import SideNavigationBar from "../../reusables/SideNavigationBar/SideNavigationBar";
import NewCompany from "./NewCompany";
import { useSelector } from "react-redux";

function AllCompanies() {
  const [allCompanies, setAllCompanies] = useState([]);
  const services = new CCService();
  const [selectedItem, setSelectedItem] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [searchQuerry, setSearchQuerry] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const { accessRights, tooltipText} = useSelector((store) => store.rights)
  console.log("dskhdsadh", accessRights)
  const handleEditModal = (data) => {
    setSelectedItem(data);
    setEditModal(true);
  };
  useEffect(() => {
    const handleEffect = async () => {
      const companies = await services.get(`/po/ca/applications/company`);
      let temp = companies.data.map((item) => {
        return {
          ...item,
          poc: [null, 'null'].includes(item.poc) ? [] : JSON.parse(item.poc).poc,
          location: JSON.parse(item.location),
        };
      });
      setAllCompanies(temp);
      setFilteredCompanies(temp);
    };

    handleEffect();
  }, []);

  useEffect(() => {
    console.log("ANIJjdf");
    if (searchQuerry === "") {
      setFilteredCompanies(allCompanies);
    } else {
      let temp = allCompanies.filter((item) => item.company_name.toLowerCase().includes(searchQuerry.toLowerCase()));

      setFilteredCompanies(temp);
    }
  }, [searchQuerry]);

  return (
    <div>
      <SideNavigationBar />
      <Box sx={{ padding: "3rem 6rem" }}>
        <Typography sx={{ fontSize: "28px", marginBottom: "20px" }}>All Companies Data</Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: "7rem", marginBlock: "2rem", alignItems: "center" }}>
          <Box sx={{ flex: 1 }}>
            <TextField InputProps={{ style: { height: "40px", borderRadius: "12px" } }} fullWidth placeholder="Search your company here " value={searchQuerry} onChange={(e) => setSearchQuerry(e.target.value)} />
          </Box>

         <Tooltip title={accessRights.find((item) => item.access_right_id === 8)?.is_activated===1 ? '':tooltipText}>
         <Box >
         <Button sx={{ color: Color.primary1, textTransform: "none" }} onClick={() => setEditModal(true)} disabled={accessRights.find((item) => item.access_right_id === 8)?.is_activated===1 ? false:true}>
            <Add sx={{ padding: "2px", background: Color.primary1, color: Color.white, marginRight: "5px", borderRadius: "50%", fontSize: "20px" }} /> Add New Company
          </Button>
         </Box>
         </Tooltip>
        </Box>
        {filteredCompanies.length > 0 ? (
          <Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ flex: 1 }}>Company</Typography>
              <Typography sx={{ flex: 1, textAlign: "center" }}>Name</Typography>
              <Typography sx={{ flex: 1, textAlign: "center" }}>Email</Typography>
              <Typography sx={{ flex: 1, textAlign: "center", marginRight: "3rem" }}>Phone No.</Typography>
            </Box>
            <hr style={{ marginBlock: "20px" }} />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              {filteredCompanies.map((item) => (
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ display: "flex", flex: 1, gap: "10px" }}>
                    {item.company_image === null ? (
                      <Box sx={{ background: "#E8505B", width: "50px", height: "50px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography sx={{fontSize:"20px", color:"#fff", fontWeight:"600"}}>{item.company_name[0]}</Typography>
                      </Box>
                    ) : (
                      <img src={item.company_image} style={{ width: "50px", aspectRatio: "1/1", borderRadius: "50%" }} />
                    )}
                    <Typography sx={{ fontSize: "20px", color: Color.primary1 }}>{item.company_name}</Typography>
                  </Box>
                  <Typography sx={{ flex: 1, textAlign: "center", fontSize: "20px" }}>{item.poc.length > 0 ? item.poc[0].name : "-"}</Typography>
                  <Typography sx={{ flex: 1, textAlign: "center", fontSize: "20px" }}>{item.poc.length > 0 ? item.poc[0].email : "-"}</Typography>

                  <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Typography sx={{ flex: 1, textAlign: "center", fontSize: "20px" }}>{item.poc.length > 0 ? item.poc[0].ph_no : "-"}</Typography>
                    <Tooltip title={ accessRights.find((item) => item.access_right_id === 8)?.is_activated===1 ? '' : tooltipText}>
                    <Box sx={{ display: "flex" }}>
                      <IconButton onClick={() => handleEditModal(item)} disabled={ accessRights.find((item) => item.access_right_id === 8)?.is_activated===1 ? false  : true    }  sx={{ color: Color.primary1 ,'&:disabled':{color:Color.neutralMidGrey} }}>
                        <Edit  />
                      </IconButton>
                    </Box>
                    </Tooltip>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          <Typography sx={{ color: Color.primary1, fontSize: "20px", textAlign: "center" }}>No data Avaialble</Typography>
        )}
      </Box>
      <Modal open={editModal} onClose={() => setEditModal(false)}>
        <NewCompany
          data={selectedItem}
          close={() => {
            setEditModal(false);
            setSelectedItem(null);
          }}
          setAllCompanies={setAllCompanies}
        />
      </Modal>
    </div>
  );
}

export default AllCompanies;
