import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Color, DivCenter } from '../../../GlobalStyles';
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import '../../../index.css';
import BatchProfileExcelTemplate from './BatchProfileExcelTemplate';
import { Box, Typography } from '@mui/material';
import ComingSoonComponent from './ComingSoonComponent';
import CCService from '../../../services/httpServiceWithoutSuperAdmin';
import ConfirmationAndValidationModal from '../../reusables/ConfirmationAndValidationModal/ConfirmationAndValidationModal';
import defaultSetting from '../Settings/DefaultSettings.json'
const Settings = () => {
  const location = useLocation();
  const { batch_id, batch_name } = location.state;
  const navigate = useNavigate();
  const services = new CCService()

  const goBack = () => {
    console.log('Back Button is Clicked Now');
    navigate(-1);
  };

  const [settingsTabs, setSettingsTab] = useState([
    { name: 'General', is_selected: true, settings: [{ "Allow unverified students to apply for PlaceO jobs": false }, { "Allow editing of all sections": false }] },
    { name: 'Batch Rules', is_selected: false, settings: null },
    { name: 'Dream Rules', is_selected: false, settings: null },
    { name: 'Batch Profile Excel Template', is_selected: false, settings: null }
  ]);
  const [defaultSettings, setDefaultSettings] = useState(defaultSetting)

  const [defaultSettingsArray, setDefaultSettingsArray] = useState(defaultSettings?.download_logic)

  const [successConfirmationModal, setSuccessConfirmationModal] = useState({
    open: false,
    onClose: () => setSuccessConfirmationModal((prev) => ({ ...prev, open: false })),
  })

  const handleListItemClick = index => {
    const updatedSettingsTabs = settingsTabs.map((tab, i) =>
      i === index ? { ...tab, is_selected: true } : { ...tab, is_selected: false }
    );
    setSettingsTab(updatedSettingsTabs);
  };

  const handleGeneralSettings = async () => {
    try {
      const temp_settings_tab = settingsTabs.find((filter_item) => filter_item.is_selected);
      if (temp_settings_tab && temp_settings_tab.settings) {

        const allowUnverified = temp_settings_tab.settings[0]["Allow unverified students to apply for PlaceO jobs"] ? 1 : 0;
        const allowEditing = temp_settings_tab.settings[1]["Allow editing of all sections"] ? 1 : 0;

        const res = await services.post(`/po/ca/poBatchMapping`,
          {
            ug_id: batch_id,
            allow_unverified_students_to_apply: allowUnverified,
            allow_editing: allowEditing
          });

        if (res.status === 200) {
          setSuccessConfirmationModal((prev) => ({ ...prev, open: true }));
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const fetchDefaultSettings = async () => {
      try {
        const defaultSettings = await defaultSettingsGetter();
        console.log("checking what I'm getting", defaultSettings);
        if (defaultSettings[0].download_logic && defaultSettings[0].download_logic.length > 0) {
          const temp_download_settings = JSON.parse(defaultSettings[0].download_logic);
          setDefaultSettingsArray(temp_download_settings);
        }
        setSettingsTab((prevTabs) => prevTabs.map(tab => {
          if (tab.name === 'General' && tab.settings) {
              const newSettings = tab.settings.map(setting => {
                  if ("Allow unverified students to apply for PlaceO jobs" in setting) {
                      setting["Allow unverified students to apply for PlaceO jobs"] = defaultSettings[0].allow_unverified_students_to_apply ? true : false;
                  }
                  if ("Allow editing of all sections" in setting) {
                      setting["Allow editing of all sections"] = defaultSettings[0].allow_editing ? true : false;
                  }
                  return setting;
              });
              return { ...tab, settings: newSettings };
          }
          return tab;
      }));
      } catch (error) {
        console.error("Error fetching default settings:", error);
      }
    };
    fetchDefaultSettings();
  }, []);

  const defaultSettingsGetter = async () => {
    try {
      const res = await services.get(`/po/ca/poBatchMapping?ug_id=${batch_id}`);
      console.log("Batch processes response:", res.data);
      return res.data
    } catch (error) {
      console.error("Error fetching default settings:", error);
      throw error;
    }
  };

  const handleToggleSwitch = (tabIndex, settingIndex) => {
    const updatedSettingsTabs = settingsTabs.map((tab, i) => {
      if (i === tabIndex && tab.settings) {
        const updatedSettings = tab.settings.map((setting, j) => {
          if (j === settingIndex) {
            const settingKey = Object.keys(setting)[0];
            return { [settingKey]: !setting[settingKey] };
          }
          return setting;
        });
        return { ...tab, settings: updatedSettings };
      }
      return tab;
    });
    setSettingsTab(updatedSettingsTabs);
  };

  const renderRightComponent = () => {
    const selectedTab = settingsTabs.find(tab => tab.is_selected);
    switch (selectedTab.name) {
      case 'General':
        return <ComingSoonComponent header={"General Settings"} index={0} settingsTabs={selectedTab.settings} tabIndex={settingsTabs.findIndex(tab => tab.is_selected)} onToggleSwitch={handleToggleSwitch} handleGeneralSettings={handleGeneralSettings} id={6}/>;
      case 'Batch Rules':
        return <ComingSoonComponent header={"Batch Rules"} index={1} id={6} />;
      case 'Dream Rules':
        return <ComingSoonComponent header={"Dream Rules"} index={2} id={6} />;
      case 'Batch Profile Excel Template':
        return <BatchProfileExcelTemplate batch_id={batch_id} origin="default" defaultSettingsArray={defaultSettingsArray} setDefaultSettingsArray={setDefaultSettingsArray} />;
      default:
        return null;
    }
  };

  console.log("settingsTabs", settingsTabs);


  return (
    <div>
      <SideNavigationBar />
      <div style={{ marginLeft: '5rem', marginTop: '2rem' }}>
        <div className="topHeadings" style={{ display: 'flex', marginLeft: '1rem', marginBottom: '1rem' }}>
          <ArrowBackIcon
            className="Arrow-back-btn"
            style={{ height: '30px', width: '30px', marginRight: '1.5rem', marginTop: '0.5rem' }}
            onClick={() => goBack()}
          />
          <p style={{ color: Color.neutralMidGrey }}>Settings for {batch_name}</p>
        </div>
        <Box style={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
          <List
            sx={{
              width: '35%',
              maxWidth: 300,
              bgcolor: 'background.paper',
              boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
              borderRadius: '1rem',
              height: '210px',
              marginLeft: "3rem",
            }}
            aria-label="contacts"
          >
            {settingsTabs.map((settingsItem, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  onClick={() => handleListItemClick(index)}
                  style={{ background: settingsItem.is_selected ? "#eee" : 'transparent' }}
                >
                  <ListItemText inset primary={settingsItem.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Box sx={{ marginLeft: '5rem', width: '80%', right: 0, float: 'right', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            {renderRightComponent()}
          </Box>
        </Box>
      </div>
      <ConfirmationAndValidationModal open={successConfirmationModal.open}
        onClose={successConfirmationModal.onClose}
        header={"General settings has been updated successfully"}
        primaryButton={"Ok"}
        handleConfirmation={successConfirmationModal.onClose}
      />
    </div>
  );
};

export default Settings;
