import { Close } from "@mui/icons-material";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { DivCenter } from "../../../../GlobalStyles";
const NoJobsAvailableModal = (props) => {
  const { postingOpportunityDetails } = props;
  console.log("postingOpportunityDetails in modal", postingOpportunityDetails);
  return (
    <Modal open={props.modalOpen} onClose={props.handleModalClose} style={{ width: "100%" }}>
      <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "30%", height: "150px", bgcolor: "white", padding: "1rem 2rem 2rem 2rem", borderRadius: "30px", display: "flex", flexDirection: "column", gap: "1.5rem", justifyContent: "space-between" }}>
        <Box sx={{ ...DivCenter, justifyContent: "space-between" }}>
          <Typography style={{ fontSize: "20px", fontWeight: "500" }}>Add New Job</Typography>
          <IconButton onClick={props.handleModalClose}>
            <Close />
          </IconButton>
        </Box>

        <button sx={{}} className="submitBtn" onClick={props.handleJobOpportunityClick}>
          Post New Opportunity
        </button>
        {/* <div className="loginOrlogin" >
                    <div className="line-login"></div>
                    <p
                        style={{
                            marginTop: "0",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                        }}
                    >
                        or
                    </p>
                    <div className="line-login"></div>
                </div> */}
        {/* <Typography sx={{ alignItems: "center", justifyContent: "center", textAlign: "center" }}>Clone an existing opportunity</Typography>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={searched}
                    label="Status"
                    sx={{ height: "42px" }}
                >
                    {postingOpportunityDetails.map((status, index) => (
                        <MenuItem onClick={() => {
                            // requestSearch(status.value, !status.isSelected)
                        }} value={status.po_job_id}>
                            {status.designation} {`previously created on ${status.date_of_visit ? formatConvertermonthfirst(
                                extractDateFromDateString(status.date_of_visit)
                            ) : "no date provided"}`}
                        </MenuItem>
                    ))}
                </Select>
                <Button sx={{ marginTop: "1.5rem" }} onClick={props.handleSaveChanges}>Clone</Button> */}
      </Box>
    </Modal>
  );
};
export default NoJobsAvailableModal;
