import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Cookies from "js-cookie";
import Service from "../../services/httpService";
import CCService from "../../services/httpServiceWithoutSuperAdmin";
// import { useTimer } from "../ResendButton/ResendButton";
// import { handleSubmit } from "../Validations/Validations";
import "../Login/Login.css";
import "react-toastify/dist/ReactToastify.css";
const Login = () => {
  const location = useLocation();
  const { condition } = location.state;
  console.log("condition", condition);
  const navigate = useNavigate();
  const services = new CCService();
  const errRef = useRef();

  const initialValues = { email: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  console.log("isSubmit", formErrors);

  const [errMsg, setErrMsg] = useState("");
  //for validation
  console.log("isSubmit", errMsg);

  const [errorMessage, SeterrorMessage] = useState(""); //error message for the request
  console.log(errorMessage);
  const [Confirmation_mgs, setConfirmation_mgs] = useState(""); //error mesage for the text input
  const handleResendForgotPassword = async e => {
    console.log("message inside resend forgot password");

    e.preventDefault();
    try {
      await services
        .post("/resendForgetPassword", {
          email: formValues.email,
          signup_platform: "CareerCarve College Admin",
        })
        .then(res => {
          if (res.status === 200) {
            console.log("message inside resend forgot password");
            setConfirmation_mgs(
              <p>
                {" "}
                Another Reset Password Link has been sent to {formValues.email}
                <br />
                Didn't receive the link?{" "}
                <button
                  className="signup-button"
                  type="button"
                  variant="link"
                  onClick={handleResendForgotPassword}
                >
                  Resend
                </button>
              </p>
            );
          }
        });
    } catch (err) {
      console.log(err);
      setErrMsg(err.response.data.message);
    }
  };

  const handleResendVerify = async e => {
    console.log("message inside resend forgot password");

    e.preventDefault();
    try {
      await services
        .post("/resendVerify", {
          email: formValues.email,
          signup_platform: "CareerCarve",
        })
        .then(res => {
          if (res.status === 200) {
            console.log("message inside resend forgot password");
            setConfirmation_mgs(
              <p>
                {" "}
                Another Verification Link has been sent to {formValues.email}
                <br />
                Didn't receive the link?{" "}
                <button
                  className="signup-button"
                  type="button"
                  variant="link"
                  onClick={handleResendVerify}
                >
                  Resend
                </button>
              </p>
            );
          }
        });
    } catch (err) {
      console.log(err);
      setErrMsg(err.response.data.message);
    }
  };


  const handleSubmit = async e => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (formValues.email === "") {
      SeterrorMessage("Email cannot be blank");
      console.log("Email is Is blank");
    }
    if (!re.test(String(formValues.email).toLowerCase())) {
      SeterrorMessage("Email is Invalid");
      console.log("Email is Invalid");
    } else {
      services
        .post(
          condition === "ForgotPassword" ? "/forgotPassword" : "/resendVerify",
          {
            email: formValues.email,
            signup_platform: "CareerCarve College Admin",
            //   device_type: "App",
          }
        )
        .then(res => {
          console.log("Response from backend is", res?.content);
          console.log("Response from backend is", JSON.stringify(res, null, 2));
          res.data.success
            ? console.log("Success in forgotPassword")
            : console.log("Failed in forgotPassword, Email doesn't exist");

          if (res.data.message === "Verify first") {
            console.log("Successful");
            setConfirmation_mgs(
              <p>
                Your account is not activated yet. Please check{" "}
                {formValues.email} to activate your account.
                <br /> Can't find activation link?
                <button
                  className="signup-button"
                  type="button"
                  onClick={e => handleResendVerify(e)}
                >
                  Resend
                </button>
              </p>
            );
          } else {
            console.log("Successful");

            setConfirmation_mgs(
              condition === "ForgotPassword" ? (
                <p>
                  A password reset link has been sent to {formValues?.email}
                  <br />
                  Didn't receive the link?{" "}
                  <button
                    className="signup-button"
                    type="button"
                    variant="link"
                    onClick={e => handleResendForgotPassword(e)}
                  >
                    Resend
                  </button>
                </p>
              ) : (
                <p>
                  Link successfully resent to {formValues?.email}
                  <br />
                  Didn't receive the link?{" "}
                  <button
                    className="signup-button"
                    type="button"
                    variant="link"
                    onClick={e => handleSubmit(e)}
                  >
                    Resend
                  </button>
                </p>
              )
            );
          }
        })
        .catch(err => {
          setConfirmation_mgs(err.response.data.message);

          if (err.response.data.message === "URL already sent") {
            setConfirmation_mgs(
              <p>
                Reset Password url already sent to your mail <br />
                Didn't receive the link?{" "}
                <button
                  className="signup-button"
                  type="button"
                  variant="link"
                  onClick={e => handleResendForgotPassword(e)}
                >
                  Resend
                </button>
              </p>
            );
          } else if (err.response.data.message === "User does not exists") {
            setConfirmation_mgs(
              <p>
                You have not signed up yet. Please sign up first to create your
                account.
              </p>
            );
          } else if (
            err.response.data.message ===
            "Can not reset the password if signed up via. Google or Apple auth"
          ) {
            setConfirmation_mgs(
              <p>
                Since this email is already signed up using Google /Apple, you
                can continue using the same to login. Click below if you still
                wish to reset your password.
                <button
                  className="signup-button"
                  type="button"
                  onClick={e => handleSubmit(e)}
                >
                  Reset your Password
                </button>
              </p>
            );
          } else if (
            err.response.data.message ===
            "No primary account found from this email ID."
          ) {
            setConfirmation_mgs(
              <p>
                You have not signed up yet. Please sign up first to create your
                account.

              </p>
            );
          }
          console.log("Error Msg: ", err.response);
          console.log("failed To Send request");
        });
    }
  };

  useEffect(() => {
    const handleEffect = () => {
      const user_type = Cookies.get("user_type");
      const token = Cookies.get("token");
      if (
        token !== null &&
        token !== undefined &&
        token !== "null" &&
        token !== "undefined"
      ) {
        console.log("token not null", token);
        navigate("/CollegeInfoPage", {
          state: { formValues: formValues, user_type: user_type },
        });
      }
    };
    handleEffect();
  });



  const handleChange = async e => {
    const { name, value } = e.target;
    console.log(e.target.value);
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({});
    setErrMsg("");
    console.log("email", formValues.email);
    console.log("password", formValues.password);
  };

  return (
    <div className="login-Container">
      <div className="login-Resumes_Image">
        <img
          className="imageFlexible"
          src="backgroundImage.png"
          alt="background"
          style={{
            backgroundRepeat: "none",
            float: "left",
            margin: "0",
            width: "100%",
            height: "100%",
            position: "static",
          }}
        />
        <img
          className="imageFlexible"
          src="logo_welcome.png"
          alt="background"
          style={{
            position: "absolute",
            height: "150px",
            width: "150px",
            top: "max(30%,200px)",
            left: "20%",
          }}
        />
        <img
          src="CareerCarve.png"
          alt="background"
          style={{
            position: "absolute",
            top: "max(52%,350px)",
            left: "13%",
          }}
        />
        <p
          style={{
            position: "absolute",
            top: "max(62%, 430px)",
            left: "13.5%",
            fontSize: "20px",
          }}
        >
          {" "}
          Placement | Preparedness | Perfected
        </p>
      </div>
      <div className="login-Main-content">
        <div className="fprgotPasswordCenter">
          <p className="login-name">
            {condition === "ForgotPassword"
              ? "Forgot your Password?"
              : "Resend activation link"}
          </p>
          <div className="login-l-form">
            <form action="" class="login-form">
              <div className="outlined-basic">
                <p className="forgotpasswordtext">
                  {condition === "ForgotPassword"
                    ? "Enter your email id and we shall send you a verification link to reset your password"
                    : "Enter your email id and we shall send you a activation link to activate your account"}
                </p>
                <TextField
                  className=""
                  onChange={handleChange}
                  value={formValues.email}
                  name="email"
                  id="outlined-basic"
                  label="Email "
                  variant="outlined"
                  sx={{ width: 400, mb: 3 }}
                  InputProps={{ sx: { height: 50.4 } }}
                  style={{
                    marginTop: "20px",
                    textAlign: "center",
                    borderRadius: "5px",
                  }}
                />

                <br />
                <button
                  type="button"
                  className="Login-button"
                  style={{
                    textAlign: "center",
                    marginBottom: "1.5rem",
                    width: "400px",
                  }}
                  onClick={e => handleSubmit(e)}
                >
                  {condition === "ForgotPassword"
                    ? "Reset your password"
                    : "Resend activation Link"}
                </button>
                <div>
                  <p
                    ref={errRef}
                    className="alert-errMsg-button"
                    aria-live="assertive"
                  >
                    {Confirmation_mgs}
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
