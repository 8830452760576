import React from 'react'
import { Box, Button, Input, Tooltip, Typography, Modal, TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import Checkbox from '@mui/material/Checkbox';
import { textAlign } from '@mui/system';
import { Color } from '../../../../GlobalStyles';

const NullOrVoidModal = (props) => {
    const { postingOpportunityDetails } = props
    console.log("postingOpportunityDetails in modal", postingOpportunityDetails);
    return (
        <div>
            <Modal open={props.modalOpen} onClose={props.handleModalClose}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'white', padding: '2rem', borderRadius: "40px", display: 'flex', flexDirection: "column", width:"40%"}}>
                    <Typography sx={{ alignItems: "left", justifyContent: "left", textAlign: "left", paddingBottom: "1rem", marginBottom: "1rem", borderBottom: `1px solid ${Color.neutralDarkGrey}`, fontSize: "20px", color:"red" }}>Caution</Typography>
                    <Typography sx={{ marginBottom: "1rem" }}>This is an <span style={{color:"red"}}>irreversible step</span>. Please read the information given below extremely carefully before acting</Typography>
                    <Typography sx={{ marginBottom: "1rem", color:Color.neutralMidGrey  }}>Once an opportunity has been made null and void, there is no way to edit or publish it again. An already published opportunity will not be accessible to the students after this step.</Typography>
                    <Box className="PreviousAndSubmit">
                        <Typography sx={{ marginBottom: "1rem", color:Color.neutralMidGrey  }} >However, you can reuse a null opportunity by cloning it.</Typography>
                    </Box>
                        <Typography class='alignInCenter' style={{marginBottom: ".5rem"}}>Are you sure you would like to continue?</Typography>
                    <div
                        className="PreviousAndSubmit"
                        style={{
                            justifyContent: "space-between",
                            width: "100%",
                            display: "flex",
                            flexDirection: props.Mobile ? "column" : "row",
                        }}
                    >
                        <button
                            name="previous-button"
                            style={{ margin: "auto", marginTop: "1rem", height: '52px', width:'auto', background: "rgba(255, 87, 51, 10%)", color:Color.red , border:`1px solid ${Color.red}`}}
                            className="Previousbutton"
                            onClick={() => props.handleCloseApplications()}
                        >
                            Make it Null and Void
                        </button>
                        <button
                            name="next-button"
                            className="Previousbutton"
                            style={{
                                margin: "auto",
                                marginTop: "1rem",
                                 height: '52px',
                                background:"transparent",
                            }}
                            onClick={() => props.handleModalClose()} 
                        >
                            Go Back
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default NullOrVoidModal
