import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SideNavigationBar from "../reusables/SideNavigationBar/SideNavigationBar";
import { Color } from "../../GlobalStyles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useNavigate } from "react-router-dom";
import NoJobsAvailableModal from "./PostingOpportunityModule/NoJobsAvailableModal.js/NoJobsAvailableModal";
import Cookies from "js-cookie";
import CCService from "../../services/httpServiceWithoutSuperAdmin";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { get_specialCharReplace } from "../../services/specialCharReplace";
import { SmallPageLoader } from "../reusables/Loader/Loader";
import { ellipsisFunction } from "../../services/EllipsisFunction";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import { HandleResumeDownload } from "../../services/specialCharReplace";
import DownloadIcon from "@mui/icons-material/Download";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useSelector } from "react-redux";
import { Button, Tooltip } from "@mui/material";
const drawerWidth = 340;
function JobsPostingPage(props) {
  const service = new CCService();
  const [loading, setLoading] = useState({ page: false });
  const location = useLocation();
  const { batch_id, batch_name, college_id } = location.state;
  const { accessRights , tooltipText} = useSelector((store) => store.rights)
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [newOpportunityModal, setNewOpportunityModal] = useState(false);
  const [postingOpportunityDetails, setPostingOpportunityDetails] = useState([
    {
      ug_id: null,
      po_job_id: null,
      status_id: null,
      status_name: "",
      designation: "",
      company_name: "",
      company_location: [],
      date_of_visit: null,
      skills: {
        skills: [],
      },
      eligibility_criteria: [
        {
          api_url: null,
          logic_id: null,
          field_name: "",
          logic_name: "",
          description: "",
          data_structure: {
            min: null,
          },
          field_logic_id: null,
        },
      ],
      eligibility_description: "",
      job_mode: "",
      placement_type: "",
      sector_id: null,
      sector_name: "",
      is_unpaid: 0,
      job_description: "",
      currency: "INR",
      min_ctc: null,
      max_ctc: null,
      interval: "",
      other_incentive: null,
      other_docs: {
        other_docs: [],
      },
      application_deadline: null,
      notification_gorup_id: null,
      image_url: null,
      last_page: "",
      hiring_workflow: null,
    },
  ]);
  const [copyList, setCopyList] = useState(postingOpportunityDetails);

  const [searched, setSearched] = useState("All");
  const [searchTerms, setSearchTerms] = useState("");
  const initialApplication = {
    po_job_id: null,
    designation: "",
    company_name: "",
    company_location: [],
    dream_role_criteria: null,
    date_of_visit: null,
    skills: [],
    eligibility_description: "",
    eligibility_criteria: [],
    placement_type: "",
    job_mode: "",
    sector_id: null,
    job_description: "",
    currency: "INR",
    min_ctc: "",
    max_ctc: "",
    interval: "",
    other_incentive: "",
    other_docs: [],
    image_url: "",
    last_page: "",
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleJobOpportunityClick = () => {
    setNewOpportunityModal(true);
    navigate(`/PostingOpportunity?batch_id=${batch_id}&college_id=${college_id}`, { state: { batch_id: batch_id, passed_postedJob: initialApplication, newJob: "newJob", college_id: college_id } });
  };

  const handleModalOpen = (batch_id) => {
    setNewOpportunityModal(true);
  };

  const handleModalClose = () => {
    setNewOpportunityModal(false);
  };

  const handleJobNavigate = (application) => {
    if ([2, 3, 4].includes(application.status_id)) {
      navigate(`/POReview?batch_id=${batch_id}&job_id=${application.po_job_id}`, { state: { batch_id: batch_id } }); //make batch_id id in route
    } else if ([5, 6, 7].includes(application.status_id)) {
      navigate(`/ClosedForApplication?batch_id=${batch_id}&job_id=${application.po_job_id}`, { state: { batch_id: batch_id } }); //make batch_id id in route
    } else {
      navigate(`/PostingOpportunity?batch_id=${batch_id}&job_id=${application.po_job_id}`, { state: { batch_id: batch_id, passed_postedJob: application ?? initialApplication } });
    }
  };

  const [sideNavBar, setSideNavBar] = useState([
    { text: "Dashboard", is_selected: true },
    { text: "List: job offers", is_selected: false },
    { text: "List: Students Placed", is_selected: false },
    { text: "List: Students Unplaced", is_selected: false },
    { text: "List: Null and Void", is_selected: false },
  ]);

  const statusFunctions = {
    All: { action: () => handleFilters("All"), color: Color.red },
    Incomplete: { action: () => handleFilters("Incomplete"), color: Color.red },
    Review: { action: () => handleFilters("Review"), color: Color.accent },
    "Accepting Applications": { action: () => handleFilters("Accepting Applications"), color: Color.primary1 },
    "Extended Applications": { action: () => handleFilters("Extended Applications"), color: Color.primary1 },
    "Closed Applications": { action: () => handleFilters("Closed Applications"), color: Color.neutralDarkGrey },
    "In process": { action: () => handleFilters("In process"), color: Color.accent },
    Completed: { action: () => handleFilters("Completed"), color: Color.green },
  };
  // make an array of json and in that name will be key and function will be the value{"All": function} . object.keys(json) ==> drop down do same with side nav bar
  useEffect(() => {
    const handleEffect = async () => {
      if (Cookies.get("token") === null || Cookies.get("token") === undefined) {
        navigate("/");
      }
      setLoading((prev) => ({ ...prev, page: true }));
      let opportunity_getter = await postingOpportunityDataGetter();
      setPostingOpportunityDetails(opportunity_getter);
      setCopyList(opportunity_getter);
      setLoading((prev) => ({ ...prev, page: false }));
    };
    handleEffect();
  }, []);

  const postingOpportunityDataGetter = async () => {
    try {
      const res = await service.get(`/po/ca/applications?user_group_id=${batch_id}`);
      if (res?.applications) return res.applications.reverse();
      else return [];
    } catch (err) {
      // handel error here
      console.log("errors handled", err);
    }
  };

  const handleItemClick = (index) => {
    const updatedSideNavBar = sideNavBar.map((item, i) => {
      return {
        ...item,
        is_selected: i === index,
      };
    });
    setSideNavBar(updatedSideNavBar);
  };

  const drawer = (
    <div>
      <SideNavigationBar />
      <div style={{ marginLeft: "5rem", background: "#F2F2F2" }}>
        <Toolbar />
        <Divider />
        <Typography sx={{ marginLeft: "1rem", fontSize: "20px" }}>Important Links</Typography>
        <List>
          {sideNavBar.map((text, index) => (
            <ListItem key={text} disablePadding sx={{ background: text.is_selected ? Color.neutralLightGrey : "transparent" }}>
              <ListItemButton onClick={() => handleItemClick(index)}>
                <ListItemText primary={text.text} sx={{ color: Color.primary1 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  const handleFilters = (searched) => {
    setSearched(searched);
    let filteredList;
    if (searched === "All") {
      filteredList = postingOpportunityDetails;
      setCopyList(filteredList);
    } else {
      filteredList = postingOpportunityDetails.filter((item) => item.status_name === searched);
      setCopyList(filteredList);
    }
  };

  const handleSearch = (searched) => {
    // y two search
    setSearchTerms(searched);
    let filteredList = postingOpportunityDetails.filter((item) => item.status_name.toLowerCase().includes(searched.toLowerCase()) || item.designation.toLowerCase().includes(searched.toLowerCase()) || item.company_name.toLowerCase().includes(searched.toLowerCase()));
    if (filteredList.length === 0) {
      setCopyList([]);
    } else {
      setCopyList(filteredList);
    }
  };

  const handleEnterSearch = (e) => {
    if (e.key === "Enter") {
      handleSearch(e.target.value);
    }
  };

  return (
    <Box>
      {loading.page ? (
        <SmallPageLoader show={loading.page} />
      ) : (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: 0,
              background: "#fff",
            }}
          >
            <Toolbar>
              <IconButton color="transparent" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div" style={{ color: Color.blueBlack, fontSize: "24px" }}>
                {batch_name}
              </Typography>
            </Toolbar>
          </AppBar>
          <Box component="nav" sx={{ width: { sm: drawerWidth }, height: "100vh", flexShrink: { sm: 0 } }} aria-label="mailbox folders">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth, height: "100vh", background: "#F2F2F2" },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth, height: "100vh", background: "#F2F2F2" },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box
              component="main"
              sx={{
                p: 3,
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "flex-start",
                marginTop: "5rem",
              }}
            >
              <Box sx={{ minWidth: 320, width: "35%", marginRight: "1.5rem" }}>
                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography style={{ width: "130px" }}> Status :</Typography>
                  <FormControl fullWidth sx={{ height: "35px" }}>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={searched} label="Status" sx={{ height: "42px" }} onChange={(e) => statusFunctions[e.target.value] && statusFunctions[e.target.value].action()}>
                      {Object.keys(statusFunctions).map((status, index) => (
                        <MenuItem key={index} value={status}>
                          <span>{status}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", borderRadius: "25px", background: Color.neutralLightGrey, width: "35%", marginRight: "1.5rem" }}>
                <InputBase
                  sx={{ ml: 1, flex: 1, height: "42px" }}
                  placeholder="Type a  phrase or keyword"
                  inputProps={{ "aria-label": "Type a  phrase or keyword" }}
                  value={searchTerms}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                  onKeyDown={handleEnterSearch}
                />
                <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={() => handleSearch(searched)}>
                  <SearchIcon />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              </Box>
             <Tooltip title={ accessRights.find((item) => item.access_right_id === 9)?.is_activated===1 ? '': tooltipText}>
             <Box style={{ width: "35%", alignItems: "center", margin: "0 auto", marginRight: "1.5rem", justifyContent: "center" }}>
               <Button
                  className="submitBtn"
                  type="button"
                  sx={{
                    height: "44px",
                    background: "transparent",
                    border: "1px solid #38C1DF",
                    color: "black",
                    textTransform: "none",
                    width: "300px",
                    '&:disabled':{
                      color:Color.neutralMidGrey
                    }
                  }}
                  onClick={() => handleModalOpen()}
                  disabled={accessRights.find((item) => item.access_right_id === 9)?.is_activated===1 ? false: true}
                >
                  Add New Job
                </Button>
              </Box>
             </Tooltip>
            </Box>
            {postingOpportunityDetails.length === 0 ? (
              <>
                <Box sx={{ justifyContent: "center", alignItems: "center", margin: "auto auto" }}>
                  <img alt="nojobsImage" src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/5928292_3024049+(2)+1.jpg" />
                  <Typography sx={{ textAlign: "center" }}>No jobs floated for this batch yet. </Typography>
                  <Typography sx={{ textAlign: "center" }}> Click on image to float job</Typography>
                </Box>
              </>
            ) : (
              <>
                <div style={{ width: "80%", margin: "2rem auto 0 auto" }}>
                  <div style={{ display: "flex", flexDirection: "column", border: "none", boxShadow: "none" }}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", paddingBottom: "1rem", justifyContent: "space-around", borderBottom: `1px solid ${Color.neutralLightGrey}` }}>
                      <div style={{ display: "flex", width: "33.33%", textAlign: "center", color: Color.neutralMidGrey }}>
                        <Box sx={{ width: "20%", userSelect: "none" }}>
                          <AccountCircleIcon style={{ width: "35px", height: "35px", borderRadius: "50%", color: "transparent" }} />
                        </Box>
                        <Typography sx={{ width: "80%" }}>Company</Typography>
                      </div>
                      <div style={{ width: "33.33%", textAlign: "center", color: Color.neutralMidGrey }}>Profile</div>
                      <div style={{ width: "33.33%", textAlign: "center", color: Color.neutralMidGrey }}>Status</div>
                      <div style={{ width: "10.33%", textAlign: "center", color: Color.neutralMidGrey }}>Resumes</div>
                    </div>
                    {sideNavBar.map((item, index) => {
                      if ((item.text === "Dashboard" && item.is_selected) || (item.text === "List: Null and Void" && item.is_selected)) {
                        const filteredApplications = item.text === "Dashboard" ? copyList.filter((app) => app.status_id !== 8) : copyList.filter((app) => app.status_id === 8);
                        console.log("filteredApplications", filteredApplications);
                        return (
                          <ul style={{ width: "100%", padding: 0 }}>
                            {filteredApplications.map((application, index) => (
                              <li style={{ listStyleType: "none" }}>
                                <Box
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    paddingTop: ".5rem",
                                    paddingBottom: ".5rem",
                                    cursor: application.status_id !== 8 ? "pointer" : "default",
                                    width: "100%",
                                    justifyContent: "space-around",
                                    borderBottom: `1px solid ${Color.neutralLightGrey}`,
                                    "&:hover": {
                                      background: application.status_id !== 8 ? Color.primary_hover : "transparent",
                                    },
                                  }}
                                  onClick={() => {
                                    application.status_id !== 8 && handleJobNavigate(application);
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "33.33%",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      height: "50px",
                                      alignItems: "center",
                                      margin: "0 auto",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Box sx={{ width: "20%" }}>{application.image_url !== null ? <img src={application.image_url} alt={application.image_url} style={{ width: "35px", height: "35px", borderRadius: "50%", border: "1px solid #fff", objectFit: "cover" }} /> : <AccountCircleIcon style={{ width: "35px", height: "35px", borderRadius: "50%", color: "#D9D9D9" }} />}</Box>
                                    <Box sx={{ marginTop: "auto", marginBottom: "auto", color: Color.primary1, width: "80%" }}>{ellipsisFunction(get_specialCharReplace(application.company_name))}</Box>
                                  </Box>

                                  <div
                                    style={{
                                      width: "33.33%",
                                      textAlign: "center",
                                    }}
                                  >
                                    {get_specialCharReplace(application.designation)}
                                  </div>

                                  <div
                                    style={{
                                      width: "33.33%",
                                      textAlign: "center",
                                      color: item.text === "Dashboard" ? statusFunctions[application.status_name]?.color || Color.green : Color.red,
                                    }}
                                  >
                                    {application.schedulerTime !== null && application.status_name === "Review" ? "Scheduled" : application.status_name}
                                  </div>
                                  {application?.zip_url ? (
                                    <div
                                      style={{
                                        width: "10.33%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <FileDownloadOutlinedIcon onClick={(e) => HandleResumeDownload(e, application?.zip_url)} style={{ width: "30px", height: "30px", color: Color.primary1 }} />
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        width: "10.33%",
                                        textAlign: "center",
                                        color: Color.neutralLightGrey,
                                      }}
                                    >
                                      N/A
                                    </div>
                                  )}
                                </Box>
                              </li>
                            ))}
                          </ul>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              </>
            )}
          </Box>
          <NoJobsAvailableModal modalOpen={newOpportunityModal} handleModalClose={handleModalClose} handleJobOpportunityClick={handleJobOpportunityClick} postingOpportunityDetails={postingOpportunityDetails} />
        </Box>
      )}
    </Box>
  );
}

JobsPostingPage.propTypes = {
  window: PropTypes.func,
};

export default JobsPostingPage;
