import { Close, Notifications, Search, UnfoldMoreSharp } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import { Button, Checkbox, IconButton, InputAdornment, Modal, TextField, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import io from "socket.io-client";
import { Color, DivCenter, PrimaryButton, PrimaryButtonOutlined } from "../../../GlobalStyles";
import Service from "../../../services/httpService";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { ActivityLoader } from "../../reusables/Loader/Loader";
import CloseApplicationModal from "../CloseApplicationModal/CloseApplicationModal";
import ApplicationDeadlineModal from "../PostingOpportunityModule/ApplicationDeadlineModal/ApplicationDeadlineModal";
import { SubComponentsAdditionalInformation, SubComponentsCompanyInfo, SubComponentsCostToCompany, SubComponentsEligibilityCriteria, SubComponentsJobDescription, SubComponentsOpportunityOutline, SubComponentsOtherDocuments, SubComponentsSkillsRequired } from "../PostingOpportunityModule/PostingOpportunityReview/SubComponents.js/SubComponents";
// import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import Chart from "react-apexcharts";
import NullOrVoidModal from "../PostingOpportunityModule/PostingOpportunityReview/NullOrVoidModal";
import GroupChat from "./GroupChat";
import HiringWorkFlow from "./HiringWorkFlow";
import ShortList from "./ShortList";
import { positions } from "@mui/system";
import { useSelector } from "react-redux";
import SideNavigationBar from "../../reusables/SideNavigationBar/SideNavigationBar";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  const location = useLocation();

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ClosedForApplication() {
  const [rolloutOffersLoader, setRolloutOffersLoader] = useState(false);

  const { accessRights, tooltipText} = useSelector((store) => store.rights)
  const socketRef = useRef();
  const chatDivRef = useRef();
  const services = new Service();
  const [candidateLoading, setCandidateLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [onHoldRemarks, setOnHoldRemarks] = useState({ show: false, onClose: () => setOnHoldRemarks((prev) => ({ ...prev, show: false })), content: "" });
  const [allShortlist, setAllShortlist] = useState([]);
  const [closeApplications, setCloseApplications] = useState(false);
  const [random, setRandom] = useState("");
  const [messageLoading, setMessageLoading] = useState(false);
  const [allowMessages, setAllowMessages] = useState(true);
  const randomColors = [Color.green, Color.red, Color.blueBlack, Color.accent];
  const randomColor = randomColors[Math.floor(Math.random() * randomColors.length)];
  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  const job_id = ![null, "null", undefined, "undefined"].includes(searchParams.get("job_id")) ? searchParams.get("job_id") : location.state.job_id;
  const batch_id = location.state?.batch_id ? location.state?.batch_id : searchParam.get("batch_id");
  const [participantsList, setParticipantsList] = useState([]);
  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    userType: "",
    user_id: null,
  });
  // messages
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [userId, setUserId] = useState();
  const [imageName, setImageName] = useState("");
  const [selectedImage, setSelectedImage] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);
  const [showBox, setShowBox] = useState(false);

  const handleMoreOptionsClick = () => {
    setShowBox(true);
  };
  // console.log("socket", socket);
  const [nullOrVoid, setNullOrVoid] = useState(false);

  const [review, setReview] = useState({
    ug_id: null,
    po_job_id: null,
    status_id: null,
    status_name: "",
    designation: "",
    company_name: "",
    company_location: [],
    date_of_visit: null,
    skills: {
      skills: [""],
    },
    eligibility_criteria: [],
    eligibility_description: "",
    job_mode: "",
    placement_type: "",
    sector_id: null,
    sector_name: "",
    is_unpaid: 0,
    job_description: "",
    currency: "",
    min_ctc: null,
    max_ctc: null,
    interval: "",
    other_incentive: "",
    other_docs: null,
    application_deadline: null,
    notification_gorup_id: null,
    image_url: null,
    last_page: "",
    hiring_workflow: [],
  });
  const [poShortListData, setPoShortListData] = useState([]);
  const [recentShortListId, setRecentShortListId] = useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [page, setPage] = useState(1);
  const handleViewParticipants = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [allCompanies, setAllCompanies] = useState([]);
  const navigate = useNavigate();
  const service = new CCService();
  const [items, setItems] = useState(poShortListData);
  const [hiringWorkflowItems, setHiringWorkflowItems] = useState(review.hiring_workflow === null ? [] : review.hiring_workflow);
  console.log("items listed", items);
  console.log("hiring workflow items", hiringWorkflowItems);
  const [name, setName] = useState("");
  const [hiringWorkflowName, setHiringWorkflowName] = useState("");
  const [editId, setEditId] = useState(null);
  const [editHiringWorkflowId, setEditHiringWorkflowId] = useState(null);
  const [status, setStatus] = useState([
    { title: "Incomplete", color: Color.red },
    { title: "Review", color: Color.accent },
    { title: "Accepting Applications", color: Color.primary1 },
    { title: "Extended Applications", color: Color.primary1 },
    { title: "Closed Applications", color: Color.neutralDarkGrey },
    { title: "In process", color: Color.accent },
    { title: "Completed", color: Color.green },
  ]);
  const [applicantsList, setApplicantsList] = useState([]);
  const [rolloutApplicants, setRolloutApplicants] = useState([]);
  const [expandedAccordionId, setExpandedAccordionId] = useState(null);
  const [reopenApplicationModal, setReopenApplicationModal] = useState(false);
  const [shortListId, setShortListId] = useState(null);
  const [rolloutOffersList, setRolloutOffersList] = useState([]);
  const [placedList, setPlacedList] = useState([]);
  const [latestShortlistStudentCount, setLatestShortlistStudentCount] = useState(null);
  //confiramtion
  const [publishConfirmationModal, setPublishConfirmationModal] = useState({
    open: false,
    onClose: () => setPublishConfirmationModal((prev) => ({ ...prev, open: false })),
    extraData: {
      value: "",
    },
  });
  console.log("ClosedForApplication ~ publishConfirmationModal:", publishConfirmationModal);

  const handleEffect = async () => {
    setLoading(true);
    console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL);
    if (Cookies.get("token") === null || Cookies.get("token") === undefined) {
      navigate("/");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const companies = await service.get(`/po/ca/applications/company`);

    setAllCompanies(companies.data);
    let opportunity_review_getter = await OpportunityReviewDataGetter();
    setReview(opportunity_review_getter);
    setWhereYouStand({ applied: opportunity_review_getter?.applied_count });
    setHiringWorkflowItems(opportunity_review_getter.hiring_workflow === null ? [] : opportunity_review_getter.hiring_workflow);
    let po_shortListGetter = await poShortListGetter();
    const shortlistName = await service.get("po/ca/shortlistNames");
    setAllShortlist(shortlistName.data);

    po_shortListGetter?.forEach((element) => {
      element.is_show = false;
    });

    setPoShortListData(po_shortListGetter);
    const applicantCount = await service.get(`/po/ca/applicantCount?po_job_id=${job_id}`);

    setLatestShortlistStudentCount(applicantCount.count);

    setItems(po_shortListGetter);
    handleAccordionChange(po_shortListGetter?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0]?.id);

    if (opportunity_review_getter.status_id === 5) {
      setValue(1);
    } else if (opportunity_review_getter.status_id === 6) {
      setValue(2);
    } else if (opportunity_review_getter.status_id === 7) {
      handleChange(null, 4);
      // setValue(4)
    }

    const sortedData = [...po_shortListGetter];
    sortedData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    const extractingId = sortedData.length > 0 ? sortedData[0].id : null;
    if (extractingId) {
      const tempChangeShortList = await allApplicantsShortListGetter(extractingId);
      tempChangeShortList?.forEach((element) => {
        element.is_checked = false;
      });
      setRolloutApplicants(tempChangeShortList);
    }
    setRandom("");
    const preUser = await service.get("/user/userDetail");
    console.log("preUser", preUser);
    const temp_formInputs = { ...formInputs };
    temp_formInputs.first_name = preUser?.userDetail.first_name;
    temp_formInputs.user_id = preUser?.userDetail.user_id;
    if (["undefined", "null", undefined, null].includes(temp_formInputs.first_name)) {
      temp_formInputs.first_name = "";
    }

    temp_formInputs.last_name = preUser?.userDetail.last_name;
    if (["undefined", "null", undefined, null].includes(temp_formInputs.last_name)) {
      temp_formInputs.last_name = "";
    }
    temp_formInputs.userType = preUser?.user_type;
    console.log("handleEffect ~ temp_formInputs:", temp_formInputs);
    setFormInputs(temp_formInputs);
    setUserId(temp_formInputs.user_id);
    if (opportunity_review_getter.po_job_id !== null) {
      console.log("in here job id", "po_job_id:", opportunity_review_getter.po_job_id, "req_raised_by:", temp_formInputs.user_id);
      socketRef.current.emit("poFindRoom", { po_job_id: opportunity_review_getter.po_job_id, req_raised_by: temp_formInputs.user_id });
      console.log("Socket connected");
      socketRef.current.on("poFoundRoom", (chatMessages) => {
        setMessages(JSON.parse(chatMessages.chat).messages);
        setParticipantsList(chatMessages.member);
        setLoading(false);
      });
      console.log("error 1:");
    }
    setLoading(false);
  };

  useEffect(() => {
    socketRef.current = io(services.socket_url, {
      autoConnect: true,
      reconnection: true,
      reconnectionDelay: 1500,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 5,
      transports: ["websocket"],
    });
    handleEffect();
  }, []);

  const groupMessagesByDate = (messages) => {
    const groupedMessages = {};
    messages.forEach((message) => {
      let messageDate = new Date(message.time);
      let today = new Date();
      let yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      if (messageDate.toLocaleDateString() === today.toLocaleDateString()) {
        messageDate = "Today";
      } else if (messageDate.toLocaleDateString() === yesterday.toLocaleDateString()) {
        messageDate = "Yesterday";
      } else {
        messageDate = messageDate.toLocaleDateString();
      }

      if (!groupedMessages[messageDate]) {
        groupedMessages[messageDate] = [];
      }
      groupedMessages[messageDate].push(message);
    });
    return groupedMessages;
  };

  const groupedMessages = groupMessagesByDate(messages);

  const handleChangeInputField = (e, index) => {
    console.log("name from form  is:", e.target.name);
    console.log("value from form  is:", e.target.value);
    console.log("something inside", e.target.name);
    if (e.target.name === "chat-input") {
      setMessage(e.target.value);
      console.log("add-specialization-input");
    }
  };

  useEffect(() => {
    console.log("in useeffect:");
    socketRef.current.on("poRoomMessage", (chatMessages) => {
      console.log("messages in poRoomMessage:", chatMessages);
      scrollToBottom();
      setMessages(chatMessages);
    });
  }, [messages]);

  const handleChange = async (event, newValue) => {
  
    if (newValue === 4) {
      let temp_placedList = await rolloutOffers();
   
      setPlacedList(temp_placedList);
      let po_shortListGetter = await poShortListGetter();

      po_shortListGetter?.forEach((element) => {
        element.is_show = false;
      });
      setPoShortListData(po_shortListGetter);
      setItems(po_shortListGetter);
      const sortedData = [...po_shortListGetter];
      sortedData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      const extractingId = sortedData.length > 0 ? sortedData[0].id : null;
      if (extractingId) {
        const tempChangeShortList = await allApplicantsShortListGetter(extractingId, 200);
        tempChangeShortList?.forEach((element) => {
          element.is_checked = false;
        });
        setRolloutApplicants(tempChangeShortList);
      }
    }
    setValue(newValue);
  };

  const handleCheckRows = ({ target }, key, index) => {
    console.log("checked:", target.checked);
    let temp_allRequestData = [...rolloutApplicants];
    temp_allRequestData[index].is_checked = target.checked;
    setRolloutApplicants(temp_allRequestData);
  };

  
  const handleSubmitRollOutOffer = (rolloutApplicants, selectedItemId) => {
    console.log("shortlist id", selectedItemId);
    const temp_applicants = rolloutApplicants?.filter((checked) => checked.is_checked);
    console.log("temp_applicants checked", temp_applicants, selectedItemId);
    setRolloutOffersList(temp_applicants);
    setShortListId(selectedItemId);
    setOnHoldRemarks((prev) => ({ ...prev, show: true }));
  };

  console.log("roll out offers", rolloutApplicants);

  const OpportunityReviewDataGetter = async () => {
    try {
      const res = await service.get(`/po/ca/applications?po_job_id=${job_id}`);
      console.log("getting opportunity data response:", res.applications[0]);
      return res.applications[0];
    } catch (err) {
      console.log(err);
    }
  };

  const handleAccordionChange = (accordionId) => {
    console.log("leknvjvnfngn fj", accordionId, expandedAccordionId);
    if (expandedAccordionId === accordionId) {
      setExpandedAccordionId(null); // Collapse if already expanded
    } else {
      setExpandedAccordionId(accordionId);
      setCandidateLoading(true);
      if (accordionId) {
        handleShortListCandidateData(accordionId);
      }
      setCandidateLoading(false);
    }
  };

  console.log("CandidateLoading", candidateLoading);
  // get of po shortlist
  const poShortListGetter = async () => {
    try {
      const res = await service.get(`/po/ca/shortlist?po_job_id=${job_id}`);
      console.log("getting opportunity data response:", res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const extractPreviousIndexId = (shortListId) => {
    let previousIndexId = null;
    for (let i = poShortListData.length - 1; i >= 0; i--) {
      console.log("poShortListData", poShortListData);
      if (poShortListData[i].id === shortListId) {
        previousIndexId = poShortListData[i + 1]?.id; // Use optional chaining to handle cases where i - 1 might be undefined
        break; // Exit the loop once the first matching ID is found
      }
    }
    return previousIndexId;
  };

  console.log("poShortListData", poShortListData);

  const mockSaveData = async (updatedItems, mode) => {
    console.log("what is the mode", mode, updatedItems);
    try {
      if (mode === "save") {
        const temp_data = updatedItems.filter((item) => !item.id);
        console.log("Saving data to the backend:", updatedItems, temp_data);
        await service
          .post(`/po/ca/shortlist?po_job_id=${job_id}`, {
            po_job_id: job_id,
            shortlists: temp_data,
          })
          .then((res) => {
            console.log("response", res);
          });
      } else {
        let temp_candidates = applicantsList.map((item) => item.user_id);
        console.log("extractPreviousIndexId", poShortListData.length, updatedItems);
        let previousIndexExists = poShortListData.length === 1 ? null : extractPreviousIndexId(updatedItems);
        console.log("previousIndexExists", previousIndexExists);
        await service
          .put(`/po/ca/diminishStudentStatus`, {
            user_ids: temp_candidates,
            po_job_id: review.po_job_id,
            shortlist_id: previousIndexExists ? previousIndexExists : null,
            student_status_id: poShortListData.length === 1 ? 3 : 4,
            deleteShortlist: true,
          })
          .then((res) => {
            console.log("response", res);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const hiringWorkflowSaveData = async (updatedItems, mode) => {
    // Replace this with your actual save logic to the backend
    console.log("what is the mode", mode, updatedItems);
    try {
      if (mode === "save") {
        const temp_data = updatedItems;
        console.log("Saving data to the backend:", updatedItems, temp_data);
        await service
          .put(`/po/ca/hiringWorkflow`, {
            po_job_id: job_id,
            hiring_workflow: updatedItems,
          })
          .then((res) => {
            console.log("response", res);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddHiringWorkflowItem = () => {
    const newShortlistNo = hiringWorkflowItems?.length > 0 ? hiringWorkflowItems[hiringWorkflowItems.length - 1].round_no + 1 : 1;
    const newItem = { round_no: newShortlistNo, round_name: "" };

    setHiringWorkflowItems([...hiringWorkflowItems, newItem]);
    setEditHiringWorkflowId(newShortlistNo);
  };

  const handleSaveHiringWorkflowItem = () => {
    const updatedItems = hiringWorkflowItems.map((item) => (item.round_no === editHiringWorkflowId ? { ...item, round_name: hiringWorkflowName } : item));

    hiringWorkflowSaveData(updatedItems, "save");
    setHiringWorkflowItems(updatedItems);
    setEditHiringWorkflowId(null);
    setHiringWorkflowName("");
  };

  const handleDeleteItem = async (shortlistNo) => {
    console.log("shortlist idsssss", shortlistNo);
    const { id } = shortlistNo;
    if (id) {
      await mockSaveData(id, "delete");
      console.log("Deleting item with id:", id);
      console.log("Deleting item from the backend:", { id });
      const updatedItems = poShortListData.filter((item) => item.id !== shortlistNo.id);
      setPoShortListData(updatedItems);
    }
    // Filter out the item from the local state
  };

  const handleDeleteHiringWorkflowItem = (shortlistNo) => {
    const selectedItem = hiringWorkflowItems.find((item) => item.round_no === shortlistNo);

    if (selectedItem) {
      const updatedItems = hiringWorkflowItems.filter((item) => item.round_no !== shortlistNo);
      setHiringWorkflowItems(updatedItems);
      setEditHiringWorkflowId(null);
      setHiringWorkflowName("");
      const { round_no, round_name } = selectedItem;
      console.log("updated items", updatedItems);
      if (round_no) {
        hiringWorkflowSaveData(updatedItems, "save");
        console.log("Deleting item with id:", round_no);
        console.log("Deleting item from the backend:", { round_no, round_name });
      }
    }
    // Filter out the item from the local state
  };

  const handleAllApplicants = () => {
    navigate(`/AllApplicants?batch_id=${batch_id}&job_id=${review?.po_job_id}`, { state: { review: review, batch_id: batch_id } });
  };

  const StyledLinearProgressChart = styled(Chart)(({ theme }) => ({
    ".apexcharts-bar .apexcharts-bar-area": {
      height: "100%", // Ensure the progress bar fills the entire height of the chart
      borderRadius: "5px", // Apply the same border radius as defined in BorderLinearProgress
      backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800], // Use the same background color
    },
  }));

  // this is for chart
  const StudentApplicationChart = ({ totalStudents, studentsApplied }) => {
    const progress = Math.round((studentsApplied / totalStudents) * 100); // Calculate progress percentage
    const remaining = 100 - progress; // Calculate the remaining students

    const options = {
      chart: {
        type: "bar",
        height: 5, // Adjust height to reduce the size of the progress bar
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "100%", // Set the height of the progress bar
          colors: {
            ranges: [
              {
                from: 0,
                to: progress,
                color: "#1a90ff", // Color for the applied portion
              },
              {
                from: progress,
                to: 100,
                color: "#E8E8E8", // Color for the remaining portion
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["Progress"],
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
    };

    const series = [
      {
        data: [progress, remaining],
      },
    ];

    return <StyledLinearProgressChart options={options} series={series} type="bar" />;
  };

  // correct this
  const handleShortListCandidateData = async (shortListId) => {
    try {
      const tempChangeShortList = await allApplicantsShortListGetter(shortListId);
      console.log("tehe resulting data", tempChangeShortList);
      setApplicantsList(tempChangeShortList);
    } catch (err) {
      console.log(err);
    }
  };

  const allApplicantsShortListGetter = async (shortListId, limit = 8) => {
    try {
      const res = await service.get(`/po/ca/getApplicants?po_job_id=${job_id}&shortlist_id=${shortListId}&page=1&limit=${limit}`);
      console.log("getting applicants data response:", res.data);
      setCandidateLoading(false);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };
  const rolloutOffers = async (shortListId) => {
    try {
      const res = await service.get(`/po/ca/getApplicants?po_job_id=${job_id}&student_status_id=7&page=1&limit=100`);
      console.log("getting applicants data response:", res.data);
      setCandidateLoading(false);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  // reopen application
  const handleApplicationModalOpen = () => {
    setReopenApplicationModal(true);
  };

  const handleApplicationModalClose = () => {
    setReopenApplicationModal(false);
  };

  const handleRollOutApplications = async () => {
    try {
      await service
        .put(`/po/ca/jobStatusUpdate`, {
          po_job_id: review.po_job_id,
          job_status_id: 7,
        })
        .then((res) => {
          console.log("response", res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const onCloseDialog = () => {
    setCloseDialog(false);
  };

  // document
  function MaxWidthDialog({ openDialog, onCloseDialog, handleDocumentUpload }) {
    const [open, setOpen] = React.useState(openDialog);
    console.log("openDialog", openDialog);
    // const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState(350);

    const handleClose = () => {
      setOpen(false);
      setOpen(false);
      setOpenDialog(false);
      setMessage("");
      setSelectedImage(null);
      setImageName("");
    };

    const handleSendDocument = () => {
      handleSendDocument1();
      handleClose();
    };

    React.useEffect(() => {
      setOpen(openDialog);
    }, []);

    return (
      <Box>
        <Dialog maxWidth={maxWidth} open={open} onClose={handleClose} sx={{ width: "50%", marginLeft: "auto", bottom: "3rem", top: "auto", borderRadius: "40px" }}>
          {/* <DialogTitle>Attach Document</DialogTitle> */}
          <DialogContent>
            <DialogContentText>Attach Document for the group chat</DialogContentText>
            <Box
              noValidate
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                // m: 'auto',
                width: "fit-content",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <DescriptionOutlinedIcon />
                <button style={{ border: "none", height: "15px", color: "#1C8EA8", background: "transparent", outline: "none", fontSize: "16px" }}>{imageName}</button>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button style={{ textTransform: "none", color: Color.primary1, background: "transparent" }} onClick={handleClose}>
              Cancel
            </Button>
            <Button style={{ textTransform: "none", color: Color.primary1, background: "transparent" }} onClick={handleSendDocument}>
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  function renderRow(props) {
    const { index, style, participants } = props;
    const participant = participants[index];
    console.log("participant", participant);
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton>
          <ListItemText primary={`${index + 1}. ${participant.name}`} />
        </ListItemButton>
      </ListItem>
    );
  }

  // participants modal
  function ParticipantsModal({ open, onClose, participants }) {
    const modalBoxStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "white",
      padding: "1.5rem 2rem",
      borderRadius: "20px",
      width: "50%",
      height: "500px",
      overflowY: "auto",
      minHeight: "500px",
    };
    const [searchParticipants, setSearchedParticipants] = useState("");
    const [filteredParticipants, setFilterdParticipants] = useState([...participants]);
    const handleSearchParticipants = ({ target }) => {
      const { value } = target;
      console.log("handleSearchParticipants ~ value:", value);
      setSearchedParticipants(value);
      let filteredList = participants.filter(
        (item) => item.name.toLowerCase().includes(value.toLowerCase()) || item.name.toUpperCase().includes(value.toUpperCase()) || item.user_id.toString().toLowerCase().includes(value.toString().toLowerCase())
        // || item?.roll_no.includes(value)
      );

      console.log("filtered list", filteredList);
      if (filteredList.length === 0) {
        setFilterdParticipants([]);
      } else {
        setFilterdParticipants(filteredList);
      }
    };

    return (
      // <div
      //     style={{
      //         position: 'fixed',
      //         top: 0,
      //         right: 0,
      //         width: 'auto',
      //         maxHeight: '100%',
      //         // backgroundColor: 'rgba(0, 0, 0, 0.5)',
      //         zIndex: 999,
      //         display: isOpen ? 'flex' : 'none',
      //         flexDirection: 'column',
      //         alignItems: 'flex-end',
      //         padding: '1rem',
      //     }}>
      //     <Box
      //         ref={modalRef}
      //         sx={{ width: 360, bgcolor: 'background.paper', padding: '1rem', borderRadius: '5px', height: "auto" }}>
      //         <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
      //             <Typography sx={{ fontSize: "18px" }}>Participants List</Typography>
      //             <CloseOutlinedIcon onClick={onClose} style={{ cursor: "pointer" }} />
      //         </Box>
      //         <FixedSizeList
      //             width={360}
      //             height={300}
      //             itemSize={46}
      //             itemCount={participants.length}
      //             overscanCount={5}>
      //             {(props) => renderRow({ ...props, participants })}
      //         </FixedSizeList>

      //     </Box>
      // </div>
      <Modal open={open} onClose={onClose}>
        <Box sx={{ ...modalBoxStyle, justifyContent: "flex-start" }}>
          <Box sx={{ ...DivCenter, width: "100%", alignItems: "space-between", justifyContent: "space-between", marginBottom: "8px" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                // paddingLeft: "16px",
              }}
            >
              Participants List
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Typography>
              <span style={{ color: Color.red }}>Note:</span>
              <span style={{ color: Color.neutralMidGrey }}>
                To add or remove participants from {review.company_name} {review.designation} chat, release a new shortlist or edit the current shortlist
              </span>
            </Typography>
            <Box sx={{ ...DivCenter, width: "100%" }}>
              <Button variant="text" sx={{ color: Color.primary1 }} onClick={() => navigate("/AllApplicants", { state: { review: review } })}>
                Head to shortlist view
              </Button>
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                name="search_colleges"
                variant="outlined"
                placeholder="Search for a student"
                value={searchParticipants}
                onChange={handleSearchParticipants}
                fullWidth
                sx={{
                  width: "100%",
                  height: "100%",
                  fontSize: "20px",
                  background: Color.neutralLightGrey,
                  borderRadius: "30px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  sx: { borderRadius: "30px", height: "44px" },
                }}
              />
            </Box>
            <Box sx={{ ...DivCenter, width: "100%", justifyContent: "flex-start", padding: "0 16px", display: "flex", flexDirection: "column" }}>
              {filteredParticipants.map((participant) => (
                <Box sx={{ ...DivCenter, width: "100%", justifyContent: "space-between", background: "rgba(250, 250, 250, 1)", marginTop: "1rem" }}>
                  <Typography style={{ color: Color.neutralBlack, fontSize: "18px" }}>{participant.name}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  }

  function OnHoldRemarksModal({ open, onClose, shortListCandidates, shortListId }) {
    console.log("shortListCandidates", shortListCandidates, shortListId);
    const [isHovered, setIsHovered] = useState(false);
    const handlePutShortlist = async (shortListId) => {
      let temp_candidates = shortListCandidates.map((item) => item.user_id);
      console.log("shortListId inisde put request", shortListId);
      try {
        await service
          .put(`/po/ca/elevateStudentStatus`, {
            user_ids: temp_candidates,
            po_job_id: review.po_job_id,
            shortlist_id: shortListId,
            student_status_id: 7,
          })
          .then((res) => {
            console.log("response", res);
            setOnHoldRemarks((prev) => ({ ...prev, show: false }));
            const updatedApplicants = rolloutApplicants?.map((element) => ({ ...element, is_checked: false }));
            setRolloutApplicants(updatedApplicants);
            handleRollOutApplications();
            window.location.reload();
            setRandom("");
          });
      } catch (err) {
        console.log(err);
      }
    };

    return (
      <Modal open={open} onClose={onClose}>
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", padding: "2rem", borderRadius: "40px", display: "flex", flexDirection: "column" }}>
          <Typography sx={{ alignItems: "left", justifyContent: "left", textAlign: "left", paddingBottom: "1rem", marginBottom: "1rem", borderBottom: `1px solid ${Color.neutralLightGrey}` }}>Shortlist these students for next round?</Typography>
          <Typography sx={{ marginBottom: "1rem", width: "400px" }}>Review the students</Typography>

          {shortListCandidates?.map((candidate, index) => {
            return (
              <>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography textAlign="left">
                    {index + 1} {candidate.full_name}
                  </Typography>
                  <Typography textAlign="left"> {candidate.roll_no}</Typography>
                </Box>
              </>
            );
          })}
          <Box style={{ marginTop: "2rem", position: "relative" }}>
            <Box
              sx={{ padding: "16px", border: `1px dashed ${Color.neutralDarkGrey}`, background: "#fff", "&:hover": { boxShadow: "4px 8px 15.9px 6px rgba(0, 0, 0, 0.15)" }, zIndex: "999" }}
              onMouseEnter={(e) => {
                setIsHovered(true);
              }}
              onMouseLeave={(e) => {
                setIsHovered(false);
              }}
            >
              <Box className="PreviousAndSubmit">
                <Box style={{ textAlign: "center", background: Color.neutralMidGrey, padding: "2px 3px", borderRadius: "8px", marginRight: "5px" }}>
                  <Notifications sx={{ color: Color.white, transform: "rotate(30deg)", fontSize: "20px" }} />
                </Box>
                <Typography>Notify students about this shortlist?</Typography>
              </Box>
              <Box className="PreviousAndSubmit">
                <Checkbox disabled />
                <Typography class="alignInCenter">Only above mentioned</Typography>
                <Checkbox disabled />
                <Typography class="alignInCenter">Only Applicants</Typography>
                <Checkbox disabled />
                <Typography class="alignInCenter">Entire Batch</Typography>
              </Box>
            </Box>

            <Box sx={{ zIndex: "1" }}>
              {isHovered && (
                <motion.div style={{ display: "flex", gap: "2px", position: "absolute", background: "rgba(232, 80, 91, 1)", padding: "5px 12px", right: 1, borderRadius: "8px 8px 0px 0px", zIndex: "-10" }} transition={{ duration: 0.5, type: "spring", delay: 0.1 }} initial={{ top: "0px" }} animate={isHovered ? { top: "-30px" } : { top: "0px" }}>
                  <img src="https://ccicons.s3.amazonaws.com/po/eggCracked.svg" style={{ objectFit: "contain", height: "20px", aspectRatio: "1/1" }} />
                  <Typography sx={{ color: Color.white, fontSize: "14px", fontWeight: "600" }}>Cracking Soon!</Typography>
                  <Typography sx={{ color: Color.white, fontSize: "14px" }}>Notification Control Center</Typography>
                </motion.div>
              )}
            </Box>
          </Box>
          <div
            className="PreviousAndSubmit"
            style={{
              justifyContent: "space-between",
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <button name="previous-button" style={{ margin: "auto", marginTop: "1rem", background: "transparent" }} className="Previousbutton" onClick={onClose}>
              Go Back
            </button>
            <button
              name="next-button"
              className="Rephrasebutton"
              style={{
                margin: "auto",
                marginTop: "1rem",
                border: "none",
                background: Color.green,
              }}
              onClick={() => handlePutShortlist(null)}
            >
              Rollout Offers
            </button>
          </div>
        </Box>
      </Modal>
    );
  }

  // close applications
  const handleCloseApplicationsModalOpen = () => {
    setCloseApplications(true);
  };

  const handleCloseApplicationsModalClose = () => {
    setCloseApplications(false);
  };

  const handleCloseApplications = async () => {
    try {
      await service
        .put(`/po/ca/jobStatusUpdate`, {
          po_job_id: review.po_job_id,
          job_status_id: 7,
        })
        .then((res) => {
          console.log("response", res);
          handleCloseApplicationsModalClose();
          handleEffect();
        });

      // navigate("/JobsPostingPage", { state: { batch_id: batch_id } })
    } catch (err) {
      console.log(err);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  // messages code

  function sendMessage(temp_message_data) {
    console.log("MESSAGER", temp_message_data);
    try {
      socketRef.current.emit("poSendMessage", temp_message_data, (error, response) => {
        if (error) {
          console.error("errorrrrr", error);
        } else {
          console.log("responseeee", response);
        }
      });
      console.log("this stage");
    } catch (err) {
      console.error("errorrrrr in", err);
    }
  }

  const scrollToBottom = () => {
    if (chatDivRef.current) {
      chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
    }
  };

  const handleAllowMessages = () => {
    let temp_allow_messages = !allowMessages;
    setAllowMessages(!allowMessages);
    if (temp_allow_messages) {
      const temp_message = { text: "All Students can send messages".replaceAll("'", "’").replaceAll('"', "”") };
      console.log("temp me", temp_message);
      const temp_data = { po_job_id: review.po_job_id, message: { text: temp_message.text }, chat_admin_id: 4, user_id: userId, time: Date.now() };
      if (temp_data.message.text.trim() !== "") sendMessage(temp_data);
      scrollToBottom();
    } else {
      const temp_message = { text: "Only the college admin can send messages".replaceAll("'", "’").replaceAll('"', "”") };
      console.log("temp me", temp_message);
      const temp_data = { po_job_id: review.po_job_id, message: { text: temp_message.text }, chat_admin_id: 3, user_id: userId, time: Date.now() };
      if (temp_data.message.text.trim() !== "") sendMessage(temp_data);
      scrollToBottom();
    }
  };

  const handleSend = () => {
    setMessageLoading(true);
    const temp_message = { text: message.replaceAll("'", "’").replaceAll('"', "”") };
    console.log("temp me", temp_message);
    const temp_data = { po_job_id: review.po_job_id, message: { text: temp_message.text }, user_id: userId, time: Date.now() };
    if (temp_data.message.text.trim() !== "") sendMessage(temp_data);
    scrollToBottom();
    setMessage("");
    setMessageLoading(false);
  };
  const handleSendDocument1 = () => {
    setMessageLoading(true);
    const temp_message = { url: message.replaceAll("'", "’").replaceAll('"', "”") };
    console.log("temp me", temp_message);
    const temp_data = { po_job_id: review.po_job_id, message: { url: temp_message.url }, user_id: userId, time: Date.now() };
    if (temp_data.message.url.trim() !== "") sendMessage(temp_data);
    setMessage("");
    setMessageLoading(false);
    scrollToBottom();
  };

  const handleDocumentUpload = async (selectedImage) => {
    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "multipart/form-data",
    };
    console.log("pdf name", selectedImage);
    let pdfFile = new Blob([selectedImage], {
      type: "application/pdf",
    });

    const image_data = new FormData();
    image_data.append("poFile", pdfFile, selectedImage.name);

    try {
      const imageRes = await axios.post(`${process.env.REACT_APP_API_URL}/po/student/poDocumentUpload`, image_data, { headers });
      console.log("imageRes", imageRes);
      return imageRes?.data?.document_url;
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleChangeDocument = async (e) => {
    console.log("ImageUpload", e.target.files[0].name, e.target.files[0]);
    setSelectedImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
    let temp_doc_name = await handleDocumentUpload(e.target.files[0]);
    console.log("temp_doc_name", temp_doc_name);
    setMessage(temp_doc_name);
    setOpenDialog(true);
  };

  function isLastOccurrenceNotThreeOrFour(arr) {
    let lastOccurrenceIndex = -1;
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i].chat_admin_id === 3 || arr[i].chat_admin_id === 4) {
        lastOccurrenceIndex = i; // Update the index if chat_admin_id is 3 or 4
        break; // Exit the loop after finding the last occurrence of 3 or 4
      }
    }
    if (lastOccurrenceIndex === -1) {
      return true; // If no occurrence of 3 or 4 found, return true
    }
    return arr[lastOccurrenceIndex].chat_admin_id !== 3;
  }





  useEffect(() => {
    // Function to scroll to the bottom of the chat container
    const scrollToBottom = () => {
      if (chatDivRef.current) {
        chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
      }
    };

    const lastOccurrenceIndex = isLastOccurrenceNotThreeOrFour(messages);
    console.log("lastOccurrenceIndex", lastOccurrenceIndex);
    setAllowMessages(lastOccurrenceIndex);

    if (value === 3) {
      scrollToBottom();
    }
  }, [value, messages]);

  const handleFullShortlistData = (shortListDetails) => {
    navigate(`/AllApplicants?batch_id=${batch_id}&job_id=${review?.po_job_id}`, { state: { review: review, shortListDetails: shortListDetails, modeOfShortList: "From Closed For Applicants", batch_id: batch_id } });
  };
  console.log("messageLoading", messageLoading);

  const goBack = () => {
    navigate(-1);
  };
  const handleEnterPressed = (e) => {
    if (e.key === "Enter") {
      handleSaveHiringWorkflowItem();
    }
  };
  console.log("messages length", messages);
  console.log("applicantsList list", applicantsList);

  function PublishConfirmationModal({ open, onClose }) {
    const modalBoxStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "white",
      padding: "2rem",
      borderRadius: "40px",
      width: "35%",
    };
    const [modalLoading, setModalLoading] = useState({ button: false });

    //FUNCTIONS
    const handleConfirmation = async () => {
      setModalLoading((prev) => ({ ...prev, button: true }));
      await handleDeleteItem(publishConfirmationModal.extraData.value);
      setModalLoading((prev) => ({ ...prev, button: false }));
      onClose();
    };

    return (
      <Modal open={open} onClose={!modalLoading.button ? onClose : {}}>
        <Box sx={{ ...modalBoxStyle }}>
          <Box sx={{ ...DivCenter, width: "100%", alignItems: "space-between", justifyContent: "space-between", marginBottom: "8px" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                // paddingLeft: "16px",
              }}
            >
              Delete Shortlist
            </Typography>
            <IconButton disabled={modalLoading.button} onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "normal",
              marginBottom: "8px",
              // paddingLeft: "16px",
            }}
          >
            Are you sure you want to delete this shortlist?
          </Typography>
          <Box
            sx={{
              ...DivCenter,
              alignItems: "flex-start",
              justifyContent: "flex-end",
              flexDirection: "row",
              width: "100%",
              mt: "16px",
              // gap: "10px",
            }}
          >
            <Box sx={{ ...DivCenter, width: "100%", gap: "16px" }}>
              <PrimaryButtonOutlined
                disabled={modalLoading.button}
                variant="outlined"
                onClick={() => onClose()}
                sx={{
                  textTransform: "none",
                  borderColor: Color.neutralMidGrey,
                  color: Color.neutralMidGrey,
                  borderRadius: 0,
                  boxShadow: "none",
                  width: "90px",
                  "&:hover": {
                    borderColor: Color.neutralMidGrey,
                    color: Color.neutralMidGrey,
                  },
                }}
              >
                Cancel
              </PrimaryButtonOutlined>
              <PrimaryButton
                disabled={modalLoading.button}
                variant="outlined"
                onClick={() => handleConfirmation()}
                sx={{
                  textTransform: "none",
                  borderColor: Color.primary1,
                  background: Color.primary1,
                  color: Color.white,
                  borderRadius: 0,
                  boxShadow: "none",
                  width: "90px",
                  "&:hover": {
                    background: Color.primary1,
                    borderColor: Color.primary1,
                    color: Color.white,
                  },
                }}
              >
                {modalLoading.button ? <CircularProgress size={24} sx={{ color: Color.white }} /> : "Yes"}
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  }

  const [whereYouStand, setWhereYouStand] = useState({
    applied: 0,
  });
  const handleNullOrVoidModalOpen = () => {
    setNullOrVoid(true);
  };

  const handleNullOrVoidModalClose = () => {
    setNullOrVoid(false);
  };

  const handleNullOrVoid = async () => {
    try {
      await service.delete(`/po/ca/applications?po_job_id=${review.po_job_id}`).then((res) => {
        console.log("response", res);
        handleNullOrVoidModalClose();
      });
      navigate("/JobsPostingPage", { state: { batch_id: batch_id } });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <ActivityLoader show={loading} />
        </>
      ) : (
        <>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "row", gap: "3rem" }}>
            {/* upper part */}
            <Box sx={{display:"none"}}>
              <SideNavigationBar />
            </Box>
            <Box sx={{ width: "40%" }}>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginLeft: "5rem" }} onClick={() => goBack()} />
                <Box></Box>
              </Box>
              <Box sx={{ ...DivCenter, flexDirection: "column", width: "100%", alignItems: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", justifyContent: "center", alignItems: "center" }}>
                  {review.image_url ? <img src={review.image_url} alt="selected_image" id="img" style={{ width: "100px", height: "100px", borderRadius: "100%" }} className="editprofile-img" /> : <AccountCircleIcon style={{ width: "100px", height: "100px", borderRadius: "100%", color: "#a3a3a3f6", margin: "0 auto" }} />}
                  <Typography class="typographyOfHeader" style={{ marginBottom: "0px", fontSize: "32px", fontWeight: "400px", textAlign: "center" }}>
                    {review.designation}
                  </Typography>
                  <Typography style={{ color: Color.neutralMidGrey }}>
                    {review.company_name} - {review.company_location}
                  </Typography>
                  <Typography style={{ color: Color.neutralMidGrey }}>{review.placement_type}</Typography>
                  <Box>
                    {status.map((statusItem, index) => {
                      if (statusItem.title === review.status_name) {
                        return (
                          <button className="submitBtn" type="button" style={{ borderRadius: "1px", height: "26px", width: "auto", paddingLeft: "1rem", paddingRight: "1rem", textAlign: "center", border: `1px solid ${statusItem.color}`, background: "transparent", color: statusItem.color, cursor: "default", fontSize: "12px", textTransform: "uppercase" }} key={index}>
                            {statusItem.title}
                          </button>
                        );
                      }
                      return null;
                    })}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ ...DivCenter, flexDirection: "column", width: "100%", margin: "0 auto", marginTop: "20px" }}>
                <Button  type="button" style={{ borderRadius: "5px", marginBottom: "20px", width: "300px", border: `1px solid transparent`, color: "#fff", cursor: "pointer", marginRight: "0", background:Color.primary1, "&:hover":{ background:Color.primary1, color: "#fff"}, textTransform:"none" }} onClick={() => handleAllApplicants()}>
                  {poShortListData.length > 0 ? `View ${poShortListData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0].shortlist_name} Applicants` : "View Applicants"}
                </Button>

                <LinearProgress
                  variant="determinate"
                  value={whereYouStand?.applied ? whereYouStand?.applied : 0}
                  sx={{
                    height: 18,
                    width: 300,
                    borderRadius: "8px",
                    "&.MuiLinearProgress-colorPrimary": {
                      backgroundColor: Color.neutralLightGrey,
                    },
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: Color.primary2,
                    },
                  }}
                />
                <Typography style={{ marginTop: ".5rem", marginBottom: "20px", color: Color.neutralMidGrey, textAlign: "right" }}>
                  {latestShortlistStudentCount?.all_applicant[0].all_applicants} applied out of {latestShortlistStudentCount?.eligible_not_applied[0].eligible_not_applied} eligible students
                </Typography>
                {review.status_id === 6 ? (
                  <>
                    

                  <Tooltip id="access-rights-tooltip" title={accessRights.find((item) => item.access_right_id === 13)?.is_activated===1 ? '' :  tooltipText}>
                  <Box>
                  <Button className="submitBtn" type="button" onClick={() => handleCloseApplicationsModalOpen()} style={{ borderRadius: "5px", marginBottom: "20px", width: "300px", height: "44px", border: `1px solid black`, background: "transparent", color: "black", textTransform:"none", '&:disabled':{background:Color.neutralLightGrey , color:"#000"}}}   disabled={accessRights.find((item) => item.access_right_id === 13)?.is_activated===1 ? false: true}>
                      Close Opportunity
                    </Button>
                  </Box>

                      </Tooltip>
                  </>
                ) : (
                  <>
                    {review.status_id === 7 ? (
                      <></>
                    ) : (
                         <Tooltip id="access-rights-tooltip" title={accessRights.find((item) => item.access_right_id === 11)?.is_activated===1 ? "":  tooltipText}>
                           <Box sx={{padding:"0", margin:"0"}}><Button className="submitBtn" type="button" onClick={() => handleApplicationModalOpen()} sx={{ borderRadius: "5px", marginBottom: "20px", width: "300px", height: "44px", border: `1px solid black`, background: "transparent", color: "black",textTransform:"none", '&:disabled':{background:Color.neutralLightGrey , color:"#000"} }} disabled={accessRights.find((item) => item.access_right_id === 11)?.is_activated===1 ? false: true}>
                        Reopen Applications
                      </Button></Box>

                      </Tooltip>
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Box sx={{ width: "60%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    "& .MuiTabs-flexContainer": {
                      flex: "1",
                      justifyContent: "space-evenly",
                    },
                  }}
                >
                  <Tab label="Details" {...a11yProps(0)} />
                  <Tab label="Hiring Workflow" {...a11yProps(1)} />
                  <Tab label="Shortlist" {...a11yProps(2)} />
                  <Tab label="Chat" {...a11yProps(3)} />
                  <Tab label="Rollout Offer" {...a11yProps(4)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                {review && (
                  <Box sx={{ ...DivCenter, borderLeft: `1px solid ${Color.neutralLightGrey}`, width: "100%", height: "100%" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", padding: "24px", gap: "32px" }}>
                      <SubComponentsCompanyInfo review={review} setReview={setReview} allCompanies={allCompanies} isEdit={accessRights.find((item) => item.access_right_id === 9)?.is_activated===1 ? true : false}/>
                      <SubComponentsOpportunityOutline review={review} setReview={setReview} isEdit={accessRights.find((item) => item.access_right_id === 9)?.is_activated===1 ? true : false} />
                      <SubComponentsJobDescription review={review} setReview={setReview} isEdit={accessRights.find((item) => item.access_right_id === 9)?.is_activated===1 ? true : false}/>
                      <SubComponentsSkillsRequired review={review} setReview={setReview} isEdit={accessRights.find((item) => item.access_right_id === 9)?.is_activated===1 ? true : false}/>
                      <SubComponentsEligibilityCriteria review={review} setReview={setReview} isEdit={accessRights.find((item) => item.access_right_id === 9)?.is_activated===1 ? true : false}/>
                      <SubComponentsAdditionalInformation review={review} setReview={setReview} isEdit={accessRights.find((item) => item.access_right_id === 9)?.is_activated===1 ? true : false}/>
                      <SubComponentsCostToCompany review={review} setReview={setReview} isEdit={accessRights.find((item) => item.access_right_id === 9)?.is_activated===1 ? true : false}/>
                      <SubComponentsOtherDocuments review={review} setReview={setReview} isEdit={accessRights.find((item) => item.access_right_id === 9)?.is_activated===1 ? true : false}/>
                      {review.status_id !== 7 && (
                        <>
                          {showBox ? (
                            <Box style={{ justifyContent: "center", alignItems: "center", margin: "0 auto", background: "#eee", padding: "20px", borderRadius: "15px" }}>
                              <Typography style={{ textAlign: "center", color: Color.primary1, marginBottom: "1rem" }}>More Options</Typography>
                              <Tooltip title={ accessRights.find((item) => item.access_right_id === 21)?.is_activated===1 ? '': tooltipText}>
                                <Box>
                                <Button type="contained" className="Rephrasebutton" sx={{ width: "auto", height: "44px", alignItems: "right", textTransform: "none", color: Color.red, fontSize: "16px", background: "rgba(255, 87, 51, 10%)","&:disabled":{width:"fit-content" , padding:"8px", background:Color.neutralMidGrey}  }} onClick={() => handleNullOrVoidModalOpen()} disabled={accessRights.find((item) => item.access_right_id === 21)?.is_activated===1 ? false: true}>
                                Make this Opportunity Null and Void
                              </Button>
                                </Box>  
                              </Tooltip>
                            </Box>
                          ) : (
                            <Box style={{ justifyContent: "center", alignItems: "center", margin: "0 auto", background: "#eee", padding: "20px", borderRadius: "15px", cursor: "pointer" }} onClick={handleMoreOptionsClick}>
                              <Typography style={{ textAlign: "center", color: Color.primary1 }}>More Options</Typography>
                            </Box>
                          )}
                        </>
                      )}
                    </Box>
                  </Box>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <HiringWorkFlow hiringWorkflowItems={hiringWorkflowItems} handleSaveHiringWorkflowItem={handleSaveHiringWorkflowItem} handleDeleteHiringWorkflowItem={handleDeleteHiringWorkflowItem} handleEnterPressed={handleEnterPressed} handleAddHiringWorkflowItem={handleAddHiringWorkflowItem} editHiringWorkflowId={editHiringWorkflowId} hiringWorkflowName={hiringWorkflowName} setHiringWorkflowName={setHiringWorkflowName} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <ShortList poShortListData={poShortListData} expandedAccordionId={expandedAccordionId} handleAccordionChange={handleAccordionChange} handleFullShortlistData={handleFullShortlistData} candidateLoading={candidateLoading} setPublishConfirmationModal={setPublishConfirmationModal} applicantsList={applicantsList} setValue={setValue} allShortlist={allShortlist} setPoShortListData={setPoShortListData} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <GroupChat review={review} value={value} path="ClosedForApplication" />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <Box>
                  {rolloutApplicants?.length > 0 || placedList.length > 0 ? (
                    <>
                      <Box>
                        <Typography>Rollout Offers</Typography>
                        <Typography style={{ color: Color.neutralMidGrey }}>Select the students who are to be finalized</Typography>
                        {rolloutApplicants?.some((pair) => pair?.is_checked) ? (
                          <>
                            <Box sx={{ ...DivCenter, justifyContent: "space-between", width: "100%" }}>
                              <Box sx={{ cursor: "pointer", display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                                <Typography sx={{ fontStyle: Color.neutralLightGrey, marginRight: "1rem", marginTop: "1rem" }}>Action for selected students:</Typography>
                                <Tooltip title={accessRights.find((item) => item.access_right_id === 17 )?.is_activated===1 ? '':tooltipText}>
                                <Box>
                                <Button
                                disabled={accessRights.find((item) => item.access_right_id === 17 )?.is_activated===1 ? false: true}
                                  className="Submitbutton"
                                  sx={{ borderRadius: "5px", background: Color.green, width: "250px", height: "35px", border: `1px solid transparent`, color: "#fff", cursor: "pointer", marginTop: "0", textTransform:"none", "&:hover":{background: Color.green, color: "#fff", }, "&:disabled":{background: Color.neutralMidGrey, color: "#000", } }}
                                  type="button"
                                  onClick={() => {
                                    handleSubmitRollOutOffer(rolloutApplicants, null);
                                  }}
                                >
                                  Rollout Offers
                                </Button>
                                </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </>
                        ) : (
                          <></>
                        )}
                        <Box className="box__header" style={{ marginTop: "1rem" }}>
                          <Typography className="typography__header" style={{ width: "25%" }}>
                            Student <UnfoldMoreSharp />
                          </Typography>
                          <Typography className="typography__header" style={{ width: "25%" }}>
                            Opportunity Status <UnfoldMoreSharp />
                          </Typography>
                          <Typography className="typography__header" style={{ width: "25%" }}>
                            Resume <UnfoldMoreSharp />
                          </Typography>
                        </Box>
                        <hr style={{ color: Color.neutralMidGrey, marginBottom: "0px" }} />
                        <Box className="box__postings">
                          {placedList?.map((placedStudentsList, index) => {
                            return (
                              <>
                                <Box key={index} disabled={placedStudentsList.student_status_id === 7 ? true : false} sx={{ ...DivCenter, width: "100%", background: Color.neutralLightGrey, justifyContent: "space-evenly", paddingTop: "1rem", paddingBottom: "1rem", borderBottom: `1px solid ${Color.neutralLightGrey}` }}>
                                  <Typography style={{ ...DivCenter, width: "25%" }}>
                                    <Checkbox disabled style={{ ...DivCenter, width: "20%" }} />
                                    <Box sx={{ ...DivCenter, flexDirection: "column", alignItems: "flex-start", width: "80%" }}>
                                      {placedStudentsList.full_name}
                                      <br />
                                      {placedStudentsList?.roll_number}
                                    </Box>
                                  </Typography>
                                  <Typography style={{ width: "25%", textAlign: "center" }}>{placedStudentsList?.student_status_name}</Typography>
                                  <Typography style={{ width: "25%", textAlign: "center" }}>
                                    <Button
                                      disabled
                                      sx={{
                                        textTransform: "none",
                                        padding: "8px 16px",
                                        background: Color.primary1,
                                        color: Color.white,
                                        borderRadius: "22px",
                                        "&:hover": {
                                          background: Color.primary1,
                                          color: Color.white,
                                        },
                                        "&:disabled": {
                                          background: Color.neutralMidGrey,
                                          color: Color.neutralDarkGrey,
                                        },
                                      }}
                                      onClick={() => {
                                        window.open(placedStudentsList?.resume_url);
                                      }}
                                    >
                                      View Resume
                                    </Button>
                                  </Typography>
                                </Box>
                              </>
                            );
                          })}
                          {rolloutApplicants?.map((applicantsList, index) => {
                            return (
                              <>
                                <Box key={index} disabled={[9, 10].includes(applicantsList.po_student_status_id) ? true : false} sx={{ ...DivCenter, width: "100%", background: [9, 10].includes(applicantsList.po_student_status_id) ? Color.neutralLightGrey : "transparent", justifyContent: "space-evenly", paddingTop: "1rem", paddingBottom: "1rem", borderBottom: `1px solid ${Color.neutralLightGrey}` }}>
                                  <Typography style={{ ...DivCenter, width: "25%" }}>
                                    <Checkbox disabled={[9, 10].includes(applicantsList.po_student_status_id)} style={{ ...DivCenter, width: "20%" }} value={rolloutApplicants?.is_checked} onChange={(e) => handleCheckRows(e, applicantsList, index)} />
                                    <Box sx={{ ...DivCenter, flexDirection: "column", alignItems: "flex-start", width: "80%" }}>
                                      {applicantsList.full_name}
                                      <br />
                                      {applicantsList?.roll_number}
                                    </Box>
                                  </Typography>
                                  <Typography style={{ width: "25%", textAlign: "center" }}>{applicantsList?.student_status_name}</Typography>
                                  <Typography style={{ width: "25%", textAlign: "center" }}>
                                    <Button
                                      disabled={[9, 10].includes(applicantsList.po_student_status_id)}
                                      sx={{
                                        textTransform: "none",
                                        padding: "8px 16px",
                                        background: Color.primary1,
                                        color: Color.white,
                                        borderRadius: "22px",
                                        "&:hover": {
                                          background: Color.primary1,
                                          color: Color.white,
                                        },
                                        "&:disabled": {
                                          background: Color.neutralMidGrey,
                                          color: Color.neutralDarkGrey,
                                        },
                                      }}
                                      onClick={() => {
                                        window.open(applicantsList?.resume_url);
                                      }}
                                    >
                                      View Resume
                                    </Button>
                                  </Typography>
                                </Box>
                              </>
                            );
                          })}
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <>
                      <div className="noUpcomingBookings" style={{ width: "80%", justifyContent: "center", alignItems: "center", margin: "0 auto", marginTop: "1rem" }}>
                        <div className="noUpcomingBookingsIcon">
                          <PersonOffOutlinedIcon
                            sx={{
                              color: "#1C8EA8",
                              width: "50px",
                              height: "50px",
                            }}
                          />
                        </div>
                        <div className="noUpcomingBookingsTexts">
                          <p className="noUpcomingBookingsText">No Students Shortlisted yet</p>
                        </div>
                      </div>
                    </>
                  )}
                </Box>
              </CustomTabPanel>
            </Box>
            <ApplicationDeadlineModal modalOpen={reopenApplicationModal} handleModalClose={handleApplicationModalClose} job_id={job_id} setApplicationDeadlineModal={setReopenApplicationModal} batch_id={batch_id} headerText={"Reopen Applications"} />
            <OnHoldRemarksModal open={onHoldRemarks.show} onClose={onHoldRemarks.onClose} shortListCandidates={rolloutOffersList} shortListId={null} />
            <CloseApplicationModal modalOpen={closeApplications} handleModalClose={handleCloseApplicationsModalClose} job_id={job_id} handleCloseApplications={handleCloseApplications} />
            <NullOrVoidModal modalOpen={nullOrVoid} handleModalClose={handleNullOrVoidModalClose} job_id={job_id} handleCloseApplications={handleNullOrVoid} />
            {openDialog ? <MaxWidthDialog openDialog={openDialog} onCloseDialog={onCloseDialog} handleDocumentUpload={handleDocumentUpload} /> : <></>}
            <ActivityLoader show={rolloutOffersLoader} />
            <PublishConfirmationModal open={publishConfirmationModal.open} onClose={publishConfirmationModal.onClose} />
            <ParticipantsModal open={isModalOpen} onClose={handleCloseModal} participants={participantsList} />
          </Box>
        </>
      )}
    </>
  );
}
