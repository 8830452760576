import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./TableStyling.css"
import ModalSt from "../College1x1StudentDetails/ModalSt"
import { Typography } from '@mui/material/Typography';
import { Color } from '../../../GlobalStyles';
import Service from '../../../services/httpService';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { saveAs } from "file-saver";
import DownloadIcon from '@mui/icons-material/Download';
import CardActions from '@mui/material/CardActions';
import Loader from '../../reusables/Loader/Loader';
import ExcelJS from 'exceljs';

const CreateTable = (props) => {
  const service = new Service()
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [studentDetails, setStudentDetails] = useState([])
  const { batchid, batchName, college_data_name, college_data_id, process_id, sku_name, searched, features } = props
  console.log("process_id in create table", process_id);
  console.log("searched in create table", searched);
  console.log("features in create table", features);
  const temp_features = JSON.parse(features);
  let temp_feedback_includes = temp_features.features.includes(4);

  console.log("temp_features", temp_feedback_includes);
  const [openModal, setOpenModal] = useState(false)
  const tableTitles = ["Student Details", "Roll No", "Mentor Details", "Mentor LinkedIn", "Status"];
  const [studentsDetailsClick, setStudentsDetailsClick] = useState({})
  const callModalToOpen = () => {
    console.log("Modal open fucntion called")
    setOpenModal(true);
  }
  const [warRoomDetailsSelected, setWarRoomDetailsSelected] = useState([])
  const [activityDetails, setActivityDetails] = useState([])
  const [roleTypeData, setRoleTypeData] = useState([])
  const [mentorFeedbackDetails, setMentorFeedbackDetails] = useState([])
  const [studentFeedbackDetails, setStudentFeedbackDetails] = useState([])
  const [copyList, setCopyList] = useState(warRoomDetailsSelected)
  const [activityDownloadDetails, setActivityDownloadDetails] = useState(activityDetails)

  const currentDate = new Date();
  const [loading, setLoading] = useState(true);

  const [feedbackFields, setFeedbackFields] = useState({})
  const [mcqFeedbackFields, setMcqFeedbackFields] = useState({})
  const [studentFeedbackFields, setStudentFeedbackFields] = useState({})

  const [options, setOptions] = useState([
    { label: 'All', value: 'All', isSelected: false },
    { label: 'Done', value: 'Done', isSelected: false },
    { label: 'Scheduled', value: 'Scheduled', isSelected: false },
    { label: 'Not Scheduled', value: 'Not Scheduled', isSelected: false },
    { label: 'Mentor Awaited', value: 'Mentor Awaited', isSelected: false },
    { label: 'Student Absent', value: 'Student Absent', isSelected: false },
    { label: 'No Action Students', value: 'No Action Students', isSelected: false },
    { label: 'Expired Bookings', value: 'Expired Bookings', isSelected: false },
  ]);
  const [mentorFeedbackFields, setMentorFeedbackFields] = useState([]);

  const [mentorFeedbackMCQs, setMentorFeedbackMCQs] = useState([]);
  useEffect(() => {
    console.log("Brother")
    const handleEffect = async () => {
      console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
      if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
        navigate('/');
      }
      let studentsDetails = await collegeDataGetter();
      let warRoomDetails = await warRoomDataGetter();
      let studentFeedback = await studentFeedbackGetter();
      let activityDownloadDetailsGetter = await activityDownloadGetter()
      let mentorFeedback = await mentorFeedbackGetter()

      console.log("warRoomDetails details", warRoomDetails);
      // let mentorFeedback = await mentorFeedbackGetter()
      let roleType = await roleTypeGetter()
      const temp_data = studentsDetails.map(obj => {
        const matchingWarRoom = warRoomDetails.find(warObj => parseInt(obj.order_id) === parseInt(warObj.order_id));
        if (matchingWarRoom) {
          return {
            ...obj,
            role_name: matchingWarRoom.role_name,
            slot_name: matchingWarRoom.slot_name,
            slot_date: matchingWarRoom.slot_date,
            is_absent: matchingWarRoom.is_absent,
            // mentor_feedback: matchingWarRoom.mentor_feedback,
          };
        } else {
          return obj;
        }
      });
      console.log("Modified temp_data:", temp_data);
      console.log("datas", { studentsDetails, warRoomDetails, studentFeedback, roleType })
      // Now temp_data contains the merged data with the first student unchanged
      console.log("temp data", temp_data);
      setWarRoomDetailsSelected(temp_data)
      setMentorFeedbackDetails(mentorFeedback.data)
      setMentorFeedbackFields(mentorFeedback.mentor_feedback_feilds)
      setMentorFeedbackMCQs(mentorFeedback.mentor_feedback_mcqs)
      setStudentFeedbackDetails(studentFeedback)
      //college getter
      setStudentDetails(temp_data)
      let temp_time = new Date().getTime()
      data_manupulator_roleType(roleType)
      if (temp_data?.length != 0 && searched) {
        const updatedOptions = options.map(option => ({
          ...option,
          isSelected: option.value === searched ? true : false
        }));
        setOptions(updatedOptions);
        let filteredList;
        if (searched === "Student Absent") {
          filteredList = temp_data.filter((item) =>
            item.status === "Done" && item.is_absent === 1
          );
        } else if (searched === "Expired Bookings") {
          filteredList = temp_data.filter((item) =>
            (item.status === "Initialized" || item.status === "Purchased") && new Date().getTime() + 19800000 > item.student_last_slot_cutoff
          );
        } else if (searched === "Scheduled") {
          filteredList = temp_data.filter((item) =>
            item.status === "Scheduled"
          );
        } else if (searched === "Not Scheduled") {
          filteredList = temp_data.filter((item) =>
            item.status === "Purchased"
          );
          console.log("Student Absent list", filteredList);
        } else if (searched === "Initialized") {
          filteredList = temp_data.filter((item) =>
            item.status === "Initialized"
          );
        } else if (searched === "Done") {
          filteredList = temp_data.filter((item) =>
            item.status === "Done"
          );
        } else if (searched === "No Action Students") {
          filteredList = temp_data.filter((item) =>
            item.status === "Purchased"
          );
        } else if (searched === "Mentor Awaited") {
          filteredList = temp_data?.filter((item) =>
            item.status === "Initialized" && !(temp_time > item.student_last_slot_cutoff)
          );
        } else if (searched === "All") {
          filteredList = temp_data
          console.log("filtered list", filteredList);
        }
        else {
          filteredList = temp_data.filter((item) =>
            item.student_name?.toLowerCase().includes(searched.toLowerCase()) ||
            item.mentor_name?.toLowerCase().includes(searched.toLowerCase()) ||
            item.student_roll_no?.includes(searched)
          );
        }
        console.log("filtered list", filteredList);
        if (filteredList.length === 0) {
          console.log("i'm here");
          toast.dismiss();
          setCopyList([]);
          setActivityDownloadDetails([])
        } else {
          console.log("now, i'm here");
          toast.dismiss();
          setCopyList(filteredList);
          setActivityDownloadDetails(filteredList)
          setActivityDetails(filteredList)
        }
      }
      setLoading(false)
      // forming the data
    }
    handleEffect();
  }, [])

  console.log("war room details", warRoomDetailsSelected, studentDetails);

  const collegeDataGetter = async () => {
    try {
      const res = await service.get(`/orderDetailsOfAProcess?process_id=${process_id}`)
      console.log("student details response:", res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  const activityDownloadGetter = async () => {
    try {
      const res = await service.get(`/orderDetailsOfAProcessDownload?process_id=${process_id}`)
      console.log("details of download response:", res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  const studentFeedbackGetter = async () => {
    try {
      const res = await service.get(`/student_feedback?process_id=${process_id}`)
      console.log("student feedback response:", res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  const mentorFeedbackGetter = async () => {
    try {
      const res = await service.get(`/mentor_feedback?process_id=${process_id}`)
      console.log("mentor feedback response:", res.data)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  const warRoomDataGetter = async () => {
    try {
      const res = await service.get(`/orderDetailsOfAProcessDownload?process_id=${process_id}`)
      console.log("war room details response:", res.data)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }

  const handleStudentsDetailsClick = async (order_id) => {
    try {
      const res = await service.get(`/activityDetailsOfAStudent?order_id=${order_id}`)
      console.log("student details response:", res.data)
      const temp_data = {
        ...res.data,
        order_details: {
          ...res.data.order_details,
          order_id: order_id
        }
      };

      if (res.data?.mentor_feedback?.fields_feedback != null || res.data?.mentor_feedback != null) {
        const feedback_fields = JSON.parse(res.data?.mentor_feedback?.fields_feedback)
        console.log("fields details", feedback_fields);

        setFeedbackFields(feedback_fields)
      }

      if (res.data?.mentor_feedback?.mcq_feedback != null || res.data?.mentor_feedback != null) {
        const mcq_feedback_fields = JSON.parse(res.data?.mentor_feedback?.mcq_feedback)
        setMcqFeedbackFields(mcq_feedback_fields)
      }

      if (res.data?.student_feedback?.student_feedback != null || res.data?.student_feedback != null) {
        const student_feedback_fields = JSON.parse(res.data?.student_feedback?.student_feedback)
        console.log("student_feedback_fields", student_feedback_fields);
        setStudentFeedbackFields(student_feedback_fields)
      }


      setStudentsDetailsClick(temp_data)
      // return res.data
      callModalToOpen()
    } catch (err) {
      console.log(err)
    }
  }

  const roleTypeGetter = async () => {
    try {
      const res = await service.get('/role')
      console.log("roleTypeGetter", res.data)
      setRoleTypeData([...res.data])
      return res.data
    }

    catch (err) {
      console.log(err)
    }
  }

  const data_manupulator_roleType = (roleType) => {
    console.log("SKU data:", roleType)
    let temp_role = JSON.parse(JSON.stringify(roleType));
    temp_role = temp_role.map((role) => {
      role.table = "sku"
      return role
    });

    console.log("temp role:", temp_role)
    // setRoleTypeDataMulti(temp_sku)
  }




  const requestSearch = (searched, isSelected) => {
    console.log("searched", searched);
    let filteredList;
    const updatedOptions = options.map(option => ({
      ...option,
      isSelected: option.value === searched ? !option.isSelected : false
    }));

    console.log("options selected", updatedOptions);
    setOptions(updatedOptions);
    let temp_time = new Date().getTime()

    if (searched === "Student Absent") {
      filteredList = warRoomDetailsSelected.filter((item) =>
        item.status === "Done" && item.is_absent === 1
      );
    } else if (searched === "Expired Bookings") {
      filteredList = warRoomDetailsSelected.filter((item) =>
        (item.status === "Initialized" || item.status === "Purchased") && new Date().getTime() + 19800000 > item.student_last_slot_cutoff
      );
    } else if (searched === "Scheduled") {
      filteredList = warRoomDetailsSelected.filter((item) =>
        item.status === "Scheduled"
      );
    } else if (searched === "Not Scheduled") {
      filteredList = warRoomDetailsSelected.filter((item) =>
        item.status === "Purchased"
      );
    } else if (searched === "Done") {
      filteredList = warRoomDetailsSelected.filter((item) =>
        item.status === "Done"
      );
    } else if (searched === "No Action Students") {
      filteredList = warRoomDetailsSelected.filter((item) =>
        item.status === "Purchased"
      );
    } else if (searched === "Mentor Awaited") {
      filteredList = warRoomDetailsSelected?.filter((item) =>
        item.status === "Initialized" && !(temp_time > item.student_last_slot_cutoff)
      );
    } else if (searched === "All") {
      filteredList = warRoomDetailsSelected
      console.log("filtered list", filteredList);
    }
    else {
      filteredList = warRoomDetailsSelected.filter((item) =>
        item.student_name?.toLowerCase().includes(searched.toLowerCase()) ||
        item.mentor_name?.toLowerCase().includes(searched.toLowerCase()) ||
        item.student_roll_no?.includes(searched)
      );
    }

    console.log("filtered list", filteredList);
    if (filteredList.length === 0) {
      console.log("i'm here");
      toast.dismiss();
      setCopyList([]);
      setActivityDownloadDetails([])
    } else {
      toast.dismiss();
      console.log("now, i'm here");
      setCopyList(filteredList);
      setActivityDownloadDetails(filteredList)
    }
  }

  console.log("click students data", studentsDetailsClick);
  console.log("copyList", copyList);
  console.log("Rows data now is:  ", studentDetails);




  const fileDownload = () => {
    console.log("Downloading the file");
    console.log("Rows datacopyList now is: ", copyList);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Filtered Activity');

    const hardcodedRow = ["Student Name", "Email ID", "Phone number", "Roll Number", "Mentor Name", "Mentor LinkedIn", "Role Chosen", "Confirmed Slot Time", "Confirmed Slot Date", "Student Absent"];

    // Adding the hardcodedRow as the first row in the worksheet
    worksheet.addRow(hardcodedRow);

    (copyList.length > 0 ? copyList : studentDetails).forEach(row => {
      const values = Object.values(row);

      if (values.length > 10 && values[13] === 0) {
        values[13] = "No";
      } else if (values.length > 10 && values[13] === 1) {
        values[13] = "Yes";
      }


      values.splice(0, 1);

      values.splice(4, 1);
      values.splice(5, 2);
      values.splice(13, 1)

      // Adding the row of values to the worksheet
      worksheet.addRow(values);
    });


    // Save workbook to a Buffer
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Convert Buffer to Blob
      const currentDate = new Date();
      const filename = `${college_data_name}_${batchName}_${sku_name}_${searched}_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`;
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Save the Blob as a downloadable file
      saveAs(blob, filename);
    });
  };




  const completeActivityDownload = () => {
    console.log("Downloading the file");
    console.log("Rows data now is: ", studentDetails);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Complete Activity');

    const hardcodedRow = ["Student Name", "Email ID", "Phone number", "Roll Number", "Mentor Name", "Mentor LinkedIn", "Role Chosen", "Confirmed Slot Time", "Confirmed Slot Date", "Student Absent"];
    const additionalHeaders = new Set(); // Store unique additional headers

    // Adding the hardcodedRow as the first row in the worksheet
    worksheet.addRow(hardcodedRow);

    studentDetails.forEach(row => {
      const values = Object.values(row);

      if (values.length > 10 && values[13] === 0) {
        values[13] = "No";
      } else if (values.length > 10 && values[13] === 1) {
        values[13] = "Yes";
      }


      values.splice(0, 1);

      values.splice(4, 1);
      values.splice(5, 2);

      // Adding the row of values to the worksheet
      worksheet.addRow(values);
    });

    worksheet.getRow(1).values = [
      ...hardcodedRow,
      ...(function () {
        const result = [];
        for (const header of additionalHeaders) {
          result.push(`${header} Description`, `${header} Rating`);
        }
        return result;
      })()
    ];

    // Save workbook to a Buffer
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Convert Buffer to Blob
      const currentDate = new Date();
      const filename = `${college_data_name}_${batchName}_${sku_name}_"Activity Details"_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`;
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Save the Blob as a downloadable file
      saveAs(blob, filename);
    });
  };







  // const generateExcel = () => {
  //   const data = mentorFeedbackDetails;

  //   const filteredData = data.filter(item => Object.keys(item).length > 1);

  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet('Mentor Feedback');

  //   // Create header row
  //   const headerRow = [
  //     'Mentor First Name', 'Mentor Last Name', 'First Name', 'Last Name',
  //     'Email ID', 'Phone Number', ...mentorFeedbackFields.flatMap(field => {
  //       const feedback = filteredData.length > 0 ? JSON.parse(filteredData[0]['0'].fields_feedback).responses.find(response => response.field_name === field.field_name) : null;
  //       if (feedback && typeof feedback.description === 'string') {
  //         return [field.field_name, `${field.field_name} Rating`];
  //       } else {
  //         return [field.field_name];
  //       }
  //     }),
  //     ...mentorFeedbackMCQs.map(mcq => mcq.question)
  //   ];
  //   worksheet.addRow(headerRow);

  //   // Add data rows
  //   filteredData.forEach((item, index) => {
  //     const rowData = [
  //       item.mentor_first_name, item.mentor_last_name,
  //       item.first_name, item.last_name,
  //       item.email_id, item.ph_no,
  //       ...mentorFeedbackFields.flatMap(field => {
  //         const feedback = JSON.parse(item['0'].fields_feedback).responses.find(response => response.field_name === field.field_name);
  //         if (!feedback) {
  //           return ['', ''];
  //         }
  //         if (typeof feedback.description === 'string') {
  //           // Handle type 1
  //           return [feedback.description, feedback.rating];
  //         } else if (typeof feedback.description === 'object' && feedback.description.bullets) {
  //           // Handle type 2
  //           return [feedback.description.bullets.join('\n')];
  //         }
  //         return ['', ''];
  //       }),
  //       ...mentorFeedbackMCQs.map(mcq => {
  //         const feedback = JSON.parse(item['0'].mcq_feedback).responses.find(response => response.question === mcq.question);
  //         return feedback ? feedback.ans : '';
  //       })
  //     ];
  //     worksheet.addRow(rowData);
  //   });

  //   // Generate Excel file
  //   workbook.xlsx.writeBuffer().then(buffer => {
  //     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.download = 
  //       `${college_data_name}_${batchName}_${sku_name}_"Mentor Feedback"_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`;
  //     link.click();
  //   });
  // };

  const generateExcel = () => {
    const data = mentorFeedbackDetails;

    // Filter out items with at least one key
    const filteredData = data.filter(item => Object.keys(item).length > 1);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Mentor Feedback');

    // Create header row
    const headerRow = [
      'Mentor First Name', 'Mentor Last Name', 'First Name', 'Last Name',
      'Email ID', 'Phone Number',
      ...mentorFeedbackFields.flatMap(field => {
        const feedback = filteredData.length > 0 ? JSON.parse(filteredData[0]['0'].fields_feedback).responses.find(response => response.field_name === field.field_name) : null;
        if (feedback && typeof feedback.description === 'object' && feedback.description.bullets) {
          // Type 2 feedback: include only the field name
          return [field.field_name];
        } else if (feedback && typeof feedback.description === 'string') {
          // Type 1 feedback: include both field name and Rating column
          return [field.field_name, `${field.field_name} Rating`];
        }
        return [field.field_name];
      }),
      ...mentorFeedbackMCQs.map(mcq => mcq.question)
    ];
    worksheet.addRow(headerRow);

    // Add data rows
    filteredData.forEach(item => {
      const feedbackResponses = JSON.parse(item['0'].fields_feedback).responses;
      const mcqResponses = JSON.parse(item['0'].mcq_feedback).responses;

      const rowData = [
        item.mentor_first_name, item.mentor_last_name,
        item.first_name, item.last_name,
        item.email_id, item.ph_no,
        ...mentorFeedbackFields.flatMap(field => {
          const feedback = feedbackResponses.find(response => response.field_name === field.field_name);
          if (!feedback) {
            // Handle missing feedback
            return [''];
          }
          if (typeof feedback.description === 'string') {
            // Type 1 feedback
            return [feedback.description, feedback.rating || '']; // Include rating if available
          } else if (typeof feedback.description === 'object' && feedback.description.bullets) {
            // Type 2 feedback
            const bullets = feedback.description.bullets.join('\n'); // Join bullets into a single string
            return [bullets]; // Only include bullets
          }
          return [''];
        }),
        ...mentorFeedbackMCQs.map(mcq => {
          const feedback = mcqResponses.find(response => response.question === mcq.question);
          return feedback ? feedback.ans : '';
        })
      ];

      worksheet.addRow(rowData);
    });

    // Generate Excel file
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download =`${college_data_name}_${batchName}_${sku_name}_"Mentor Feedback"_${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`;
      link.click();
    });
  };
  console.log("loading...", loading);
  return (
    <>
      <div className="student-details-parent" style={{ marginTop: "3rem" }}>
        <ToastContainer position="top-center" limit={1} />
        <div className="topHeadings" style={{ display: "flex" }}>
          <p style={{ color: Color.neutralMidGrey, cursor: "pointer" }} onClick={() => navigate("/CollegeInfoPage")}>{college_data_name}</p>
          <p style={{ marginLeft: '1rem', marginRight: '1rem' }}>{" "} {">"}{" "} </p>
          <p>{batchName}</p>
          <p style={{ marginLeft: '1rem', marginRight: '1rem' }}>{" "} {">"}{" "} </p>
          <p>{sku_name}</p></div>
        {loading ? (<div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto' }}><Loader show={loading} /></div>) : (
          <>
            <div style={{ display: "flex" }}>
              <CardActions sx={{ justifyContent: 'flex-start', bottom: 0, padding: "0px", paddingTop: "1rem", marginRight: '1rem' }}>
                <button
                  className="submitBtn"
                  type="button"
                  style={{ height: "44px", bottom: 0, width: "auto", paddingLeft: "0.5rem", paddingRight: '0.5rem' }}
                  onClick={() => completeActivityDownload()}
                >
                  Download Activity Schedule
                </button>
              </CardActions>
              {temp_feedback_includes ? <>

              </> : <>
                <CardActions sx={{ justifyContent: 'flex-start', bottom: 0, padding: "0px", paddingTop: "1rem" }}>
                  <button
                    className="submitBtn"
                    type="button"
                    style={{ height: "44px", bottom: 0, width: "auto", paddingLeft: "0.5rem", paddingRight: '0.5rem' }}
                    onClick={generateExcel}
                  >
                    Mentor Feedback
                  </button>
                </CardActions>
              </>}

            </div>
            <div style={{ display: "flex", marginTop: "2rem" }}>
              <div className='searchTab' style={{ alignItems: 'center', width: "100%", height: "30px", marginBottom: ".5rem" }}>
                <input style={{ border: "none", height: "30px", width: "100%" }}
                  className='searchingText searchTab'
                  type='text'
                  onChange={(e) => requestSearch(e.target.value)}
                  placeholder='Search for name, roll number, etc'
                />
              </div>
              <DownloadIcon style={{ color: Color.primary1, marginLeft: ".5rem", cursor: "pointer" }} onClick={() => fileDownload()} />
            </div>
            <div>
              {options.map((option, index) => (
                <button
                  key={index}
                  className={option.isSelected === true ? "optionsFocusButton" : "Optionsbutton"}
                  style={{}}
                  onClick={() => {
                    requestSearch(option.value, !option.isSelected)
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableTitles.map((title, index) => (
                      <TableCell align='center'
                        key={index} sx={{ fontSize: 16, color: Color.neutralMidGrey }}
                      >
                        {title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {copyList?.map((details, index) => {
                    return (
                      <>
                        <TableRow onClick={() => handleStudentsDetailsClick(details.order_id)} style={{ cursor: "pointer" }}>
                          <TableCell align='center' sx={{ fontSize: 16, color: Color.neutralMidGrey }}>
                            <p style={{ color: "black", marginBottom: "0px" }}>{details.student_name} </p>
                            {details.student_email}<br />
                            {details.student_phone}
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: 16 }}>
                            {details.student_roll_no}
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: 16, color: Color.neutralMidGrey }}>
                            <p style={{ color: "black", marginBottom: "0px" }}>{details.mentor_name ? details.mentor_name : "-"} </p>
                            {/* {details.mentor_email}<br /> */}
                            {/* {details.mentor_phone} */}
                          </TableCell>

                          <TableCell align='center' sx={{ fontSize: 16, }}>
                            {/* {details.mentor_linkedin ? details.mentor_linkedin : "-"} */}
                            <button
                              className="feedbackFileName" style={{ border: "none", color: "#1C8EA8", background: "transparent" }}
                              onClick={(event) => {
                                event.stopPropagation();

                                const mentorLinkedInLink = details.mentor_linkedin;
                                if (mentorLinkedInLink.startsWith("http://") || mentorLinkedInLink.startsWith("https://")) {
                                  window.open(mentorLinkedInLink);
                                } else {
                                  window.open("https://" + mentorLinkedInLink);
                                }
                              }}>
                              {details.mentor_linkedin === "null" ? "-" : "LinkedIn"}
                            </button>
                          </TableCell>
                          <TableCell align='center' sx={{ fontSize: 16 }}>
                            {details.status}
                          </TableCell>
                        </TableRow>
                      </>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
      <ModalSt mcqFeedbackFields={mcqFeedbackFields} studentFeedbackFields={studentFeedbackFields} feedbackFields={feedbackFields} studentsDetailsClick={studentsDetailsClick} setStudentsDetailsClick={setStudentsDetailsClick} show={openModal} setShow={setOpenModal} />
    </>
  );
};

export default CreateTable;