import React, { useEffect, useState } from 'react'
import { DivCenter, Color, styles } from "../../GlobalStyles"
import { Box, Button, Divider, InputAdornment, TextField, Typography } from '@mui/material';
import { ArrowRight, PlayArrow, Search, TrendingFlat } from '@mui/icons-material';
// import { MentorStatsListView, StudentStatsListView } from './StatsListViews';
import StudentMentorDetailsModule from './StudentMentorDetailsModule';
import CCService from '../../services/httpServiceWithoutSuperAdmin';
import { formatConvertermonthfirst } from '../../services/specialCharReplace';
// import { AvailableSlotsModal, ChooseMentorModal } from './ReleaseNextListModals';
import { DialogueBox } from '../reusables/DialogueBox/DialogueBox';
import Loader from '../reusables/Loader/Loader';
import { StudentStatsListView } from './StudentStatsListView';

const SessionSchedule = ({ releaseNextListData, getStatLists, process_id, onStepChange }) => {
    const service = new CCService();
    const [loading, setLoading] = useState({ page: false });
    const [dialogue, setDialogue] = useState({
        open: false,
        onClose: () => { setDialogue((prev) => ({ ...prev, open: false })); },
        content: "",
        actions: { label: 'OK', handler: () => { }, color: Color.primary1, variant: "contained" }
    });
    const [SMDcurrentPage, setSMDCurrentPage] = useState(1);

    //data varibale
    const [listCount, setListCount] = useState([]);
    const [studentStatList, setStudentStatList] = useState([]);
    const [mentorStatList, setMentorStatList] = useState([]);
    const [waitingSwapped, setWaitingSwapped] = useState([]);
    const [datesFilter, setDatesFilter] = useState([]);
    console.log("SessionSchedule ~ waitingSwapped:", waitingSwapped)
    const [studentMentorDetails, setStudentMentorDetails] = useState([]);

    //other data variable
    const [searchTerm, setSearchTerm] = useState('');

    //MODAL data
    const [chooseMentor, setChooseMentor] = useState({
        open: false,
        onClose: () => setChooseMentor((prev) => ({ ...prev, open: false })),
        mentorSlotDetails: {},
        mentorData: [],
        extraData: {},
    });
    const [availableSlots, setAvailableSlots] = useState({
        open: false,
        onClose: () => setAvailableSlots((prev) => ({ ...prev, open: false })),
        mentorSlotDetails: {},
        availableSlotsData: [],
        extraData: {},
    });

    //FUNCTIONS

    //[GET] useeffect
    useEffect(() => {
        handleMainEffect(releaseNextListData);
    }, [])

    const handleMainEffect = async (releaseNextListData) => {
        setLoading((prev) => ({ ...prev, page: true }));
        const studentStat_response = releaseNextListData;
        console.log("handleMainEffect ~ studentStat_response:", studentStat_response)
        setListCount(studentStat_response[0]);
        setStudentStatList(studentStat_response[1]);
        setMentorStatList(studentStat_response[2]);
        const dates_for_filter = JSON.parse(studentStat_response[5][0].session_date_slot_count_mapping).session_date_slot_count_mapping
        console.log("handleMainEffect ~ studentStat_response[5]", dates_for_filter)
        // console.log("handleMainEffect ~ studentStat_response[3]:", studentStat_response[3], studentStat_response[4])
        setWaitingSwapped([...studentStat_response[3], ...studentStat_response[4]]);
        const studentMentorDetails_response = await getStudentMentorDetails(SMDcurrentPage);
        setStudentMentorDetails(studentMentorDetails_response);
        chooseMentor.onClose();
        availableSlots.onClose();
        setDatesFilter([...datesFilter, ...dates_for_filter])
        setLoading((prev) => ({ ...prev, page: false }));
    }

    const getStudentMentorDetails = async (page = 1, search_query = "", filter = "All") => {
        if (page) {
            try {
                console.log("get order:", page, search_query, filter)
                const filter_str = filter !== 'All' ? `&filterDate=${filter}` : ''
                const serch_str = search_query !== '' ? `&searchQuery=${search_query}` : ''
                const response = await service.get(`/os/superadmin/osOrderTable?process_id=${process_id}&page=${page}&limit=100${serch_str}${filter_str}`)
                console.log("getStatLists ~ response:", response);
                if (response?.data) return response.data;
                else return [];
            } catch (error) {
                console.error('Error while fetching stat data:', error);
            }
        }
    }


    const handleKeyPress = (event) => {

    }
    const handleChangeSearch = () => {

    }

    return (
        <>{loading.page ?
            <Loader />
            : <Box sx={{ ...styles.page, width: '100%', p: '20px 50px', gap: '32px', }}>
                <Box sx={{ gap: '16px', width: '100%' }}>
                    <Box sx={{ width: '50%' }}>
                        <Typography style={{ textAlign: 'left', fontSize: '24px', fontWeight: 500 }}>Scheduling Status </Typography>
                    </Box>

                    {/* <Box sx={{ ...DivCenter, justifyContent: 'flex-end', width: '50%', gap: '8px' }}>
                        <Button variant='text' onClick={() => onStepChange(null, 5, 0)} sx={{ color: Color.primary1, fontSize: '14px', fontWeight: 500, textTransform: 'none' }}>
                            Edit scheduling rules
                        </Button>
                        <TextField
                            name="search_mentors"
                            // label="Search Student"
                            placeholder='Search by mentor or student name'
                            variant="outlined"
                            onKeyPress={handleKeyPress}
                            value={searchTerm}
                            onChange={handleChangeSearch}
                            fullWidth
                            sx={{ width: '50%', borderRadius: "12px" }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search sx={{ color: Color.neutralMidGrey }} />
                                    </InputAdornment>
                                ),
                                sx: { borderRadius: "12px", height: "58px" }
                            }}
                        />
                    </Box> */}
                </Box>
                <Box sx={{ ...DivCenter, justifyContent: 'flex-start', width: '100%', gap: '8px' }}>
                    <Typography style={{ fontStyle: 'italic' }}>1st list (<span style={{ color: Color.accent }}>{listCount[0]?.student_count ?? 'N/A'}</span>) released on {formatConvertermonthfirst(listCount[0]?.list_timestamp, true, false)}</Typography>
                    {listCount[1]?.list_no === 2 ? <>
                        <TrendingFlat />
                        <Typography style={{ fontStyle: 'italic' }}>2nd list (<span style={{ color: Color.accent }}>{listCount[1]?.student_count ?? 'N/A'}</span>) released on{formatConvertermonthfirst(listCount[1]?.list_timestamp, true, false)}</Typography>
                    </> : <></>}
                    {listCount[1]?.list_no === 3 ? <>
                        <TrendingFlat />
                        <Typography style={{ fontStyle: 'italic' }}>3rd list (<span style={{ color: Color.accent }}>{listCount[2]?.student_count ?? 'N/A'}</span>) released on {formatConvertermonthfirst(listCount[2]?.list_timestamp, true, false)}</Typography>
                    </> : <></>}
                </Box>
                <Box style={{ ...DivCenter, width: "100%", }}>
                    <Box sx={{ ...DivCenter, padding: "12px", borderRadius: "20px", boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)", }}>

                        <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", padding: "16px", borderLeftColor: Color.blueBlack }}>
                            <div style={{ fontSize: "16px", fontWeight: 500 }}>Students Scheduled</div>
                            <div style={{ color: Color.primary1, fontSize: "32px" }}>
                                {studentStatList.reduce((acc, obj) => {
                                    return acc + parseInt(obj.scheduled);
                                }, 0)}
                            </div>
                        </div>
                        <Divider sx={{ height: "100px", borderWidth: 1, borderColor: Color.blueBlack, color: Color.blueBlack }} orientation="vertical" />
                        <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", padding: "16px", borderLeftColor: Color.blueBlack }}>
                            <div style={{ fontSize: "16px", fontWeight: 500 }}>Students Swapped</div>
                            <div style={{ color: Color.primary1, fontSize: "32px" }}>{waitingSwapped[1]?.swapped_orders}</div>
                        </div>
                        <Divider sx={{ height: "100px", borderWidth: 1, borderColor: Color.blueBlack, color: Color.blueBlack }} orientation="vertical" />
                        <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", padding: "16px", borderLeftColor: Color.blueBlack }}>
                            <div style={{ fontSize: "16px", fontWeight: 500 }}>Students Waiting</div>
                            <div style={{ color: Color.primary1, fontSize: "32px" }}>{waitingSwapped[0]?.students_waiting}</div>
                        </div>



                        {/* <Box sx={{ width: '65%', height: 'auto' }}>
    <MentorStatsListView mentorStatList={mentorStatList} />
</Box> */}
                    </Box>
                </Box>

                <Box sx={{ ...DivCenter, justifyContent: 'flex-start', width: '100%', gap: '8px' }}>
                    <StudentMentorDetailsModule
                        studentMentorDetails={studentMentorDetails}
                        setStudentMentorDetails={setStudentMentorDetails}
                        datesFilter={datesFilter}
                        setChooseMentor={setChooseMentor}
                        getStudentMentorDetails={getStudentMentorDetails}
                        mentorStatList={mentorStatList}
                        handleMainEffect={(a) => handleMainEffect(a)}
                        getStatLists={getStatLists}
                        setDialogue={setDialogue}
                    />
                </Box>

                {/* MODALS */}
                {/* {chooseMentor.open && <ChooseMentorModal
                    open={chooseMentor.open}
                    onClose={chooseMentor.onClose}
                    mentorSlotDetails={chooseMentor.mentorSlotDetails}
                    mentorData={chooseMentor.mentorData}
                    extraData={chooseMentor.extraData}
                    setAvailableSlots={setAvailableSlots}
                    handleMainEffect={(a) => handleMainEffect(a)}
                    getStatLists={getStatLists}
                />}
                {availableSlots.open && <AvailableSlotsModal
                    open={availableSlots.open}
                    onClose={availableSlots.onClose}
                    mentorSlotDetails={availableSlots.mentorSlotDetails}
                    availableSlotsData={availableSlots.availableSlotsData}
                    extraData={availableSlots.extraData}
                    handleMainEffect={(a) => handleMainEffect(a)}
                    getStatLists={getStatLists}
                    dialogue={dialogue}
                    setDialogue={setDialogue}
                />} */}
                {dialogue.open && <DialogueBox
                    open={dialogue.open}
                    onClose={dialogue.onClose}
                    title={dialogue.title}
                    actions={dialogue.actions}
                    content={<>
                        <p>{dialogue.content}</p>
                    </>}
                />}

            </Box>
        }</>
    )
}

export default SessionSchedule