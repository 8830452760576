import CCService from "../services/httpServiceWithoutSuperAdmin"

const service = new CCService()

export const getAccessRights = async(id) =>{
    //Save all the Access RIghts in Context
   
    const getData = await service.get(`/po/acl/userLevel?user_id=${id}`)

  return getData.data

} 


