import React, { useState, useEffect } from "react";
import { Button, Table, TableContainer, TableRow, Typography, TableCell, TableBody, Paper, TextField, Autocomplete, Chip, Box, ListItemText, Checkbox } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Color, DivCenter } from "../../../../GlobalStyles";
import "../BasicInfo/POBasicInfo.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import CCService from "../../../../services/httpServiceWithoutSuperAdmin";
import { InfoOutlined, Edit as EditIcon, Delete as DeleteIcon, Cancel } from "@mui/icons-material";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
const POEligibility = (props) => {
  console.log("eligibility job id", props.postedJob, props.job_id);
  const service = new CCService();
  const navigate = useNavigate();
  const { BatchData, basicInfoData, eligibilityInfoData, setEligibilityInfoData, batch_id, allDreamRoles, setSelectedDreamRoles, selectedDreamRoles, isDreamRole, setIsDreamRole, formErrors } = props;
  const [criteria, setCriteria] = useState([]);
  const [gender, setGender] = useState(["Male", "Female", "Others"]);
  console.log("ANIMESHDHDBF", formErrors);
  const [criteriaLogicPairs, setCriteriaLogicPairs] = useState([{ criterion: null, logic: null, value: "", is_saved: false, is_edit: false, description: "" }]);
  const [majorAndMinorArray, setMajorAndMinorArray] = useState([{ major: "", minor: "" }]);
  const [criteriaLogicOptions, setCriteriaLogicOptions] = useState({});
  const [home_state, setHome_state] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const handleChangeCriterion = async (index, selectedCriterion) => {
    const updatedPairs = [...criteriaLogicPairs];
    updatedPairs[index].criterion = selectedCriterion;
    setCriteriaLogicPairs(updatedPairs);

    let logicsGetter = await logicsDataGetter(selectedCriterion.id);
    console.log("logics getter", logicsGetter, logicsGetter.api_url);
    if (selectedCriterion.id === 2) {
      setHome_state(gender);
    }
    if (logicsGetter.length === 1 && logicsGetter[0].api_url !== null) {
      try {
        const logics_api_url = await logicsApiUrlDataGetter(logicsGetter[0].api_url, "home_state", selectedCriterion.id);
        console.log("logics", logics_api_url);

        const homeStateArray = Array.isArray(logics_api_url) ? logics_api_url.filter((item) => item !== null) : [logics_api_url];

        console.log("homeStateArray", homeStateArray);
        setHome_state(homeStateArray || []);
      } catch (error) {
        console.error("Error setting home_state:", error);
        setHome_state([]);
      }
    }

    // Update the logicOptions for the selected criterion
    setCriteriaLogicOptions((prevOptions) => ({
      ...prevOptions,
      [selectedCriterion.id]: logicsGetter,
    }));
  };
  const handleChangeLogic = (index, selectedLogic) => {
    const updatedPairs = [...criteriaLogicPairs];
    updatedPairs[index].logic = selectedLogic;
    updatedPairs[index].value = updatedPairs[index]?.logic?.data_structure?.multiSelectJson ? [{ major: "", minor: "" }] : "";
    setCriteriaLogicPairs(updatedPairs);
  };

  // from backend
  const setCriteriaFromBackend = (backendData) => {
    console.log("backend data", backendData);
    const criteriaPairs = backendData.map((item) => {
      const { api_url, logic_id, field_name, logic_name, data_structure, field_logic_id, description } = item;
      console.log("backend item", item);
      return {
        criterion: { id: field_logic_id, field_name },
        logic: { id: logic_id, logic: logic_name, data_structure, api_url: api_url },
        value: data_structure?.multiSelect || data_structure?.singleSelect || data_structure?.multiSelectJson || data_structure?.range || data_structure?.min || data_structure?.max || data_structure?.equal,
        is_saved: true,
        is_edit: false,
        description: description,
      };
    });
    console.log("what is being set", criteriaPairs);
    setCriteriaLogicPairs(criteriaPairs);
  };

  const transformCriteriaForSave = (criteriaPair) => {
    let dataStructure = {};
    console.log("criteriaPair", criteriaPair);
    if (criteriaPair.value) {
      if (criteriaPair.logic.logic === "is_between") {
        // Handle 'is_between' logic with range
        dataStructure = {
          range: {
            min: parseInt(criteriaPair.value.min),
            max: parseInt(criteriaPair.value.max),
          },
        };
      } else if (criteriaPair.value !== undefined && !Array.isArray(criteriaPair.value)) {
        let name = Object.keys(criteriaPair.logic.data_structure)[0];
        console.log("name of single select", name);
        // Handle 'min' logic
        dataStructure = {
          [name]: name === "singleSelect" ? criteriaPair.value : parseInt(criteriaPair.value),
        };
      } else if (Array.isArray(criteriaPair.value)) {
        // Handle 'max' logic
        dataStructure = {
          multiSelect: criteriaPair.value,
        };
      }
    }

    return {
      field_logic_id: criteriaPair.criterion.id,
      field_name: criteriaPair.criterion.field_name,
      logic_id: criteriaPair.logic.id,
      logic_name: criteriaPair.logic.logic,
      data_structure: dataStructure,
      api_url: criteriaPair.logic.api_url,
      description: criteriaPair.description,
    };
  };

  const handleChangeValue = (index, property, value) => {
    console.log("value inside handle change value", value);
    const updatedPairs = [...criteriaLogicPairs];

    // Check if 'value' is an object (min and max)
    if (updatedPairs[index]?.logic?.logic === "is_between") {
      updatedPairs[index].value = {
        min: null,
        max: null,
        ...updatedPairs[index].value,
        [property]: value,
      };
      updatedPairs[index].description = `${updatedPairs[index].criterion.field_name} ${updatedPairs[index].logic.logic} ${updatedPairs[index].value.min} and ${updatedPairs[index].value.max}`;
    } else if (updatedPairs[index]?.logic?.data_structure?.multiSelectJson) {
      if (!Array.isArray(updatedPairs[index].value)) {
        updatedPairs[index].value = [];
      }
      updatedPairs[index].value = updatedPairs[index].value.map((item) => ({
        ...item,
        [property]: value,
      }));
      // Append the JSON {major:"", minor:""} for a new object
      updatedPairs[index].description = updatedPairs[index].description = `${updatedPairs[index].criterion.field_name} ${updatedPairs[index].logic.logic} ${updatedPairs[index].value.map((obj) => `${obj.major} and ${obj.minor}`).join(", ")}`;
    } else {
      updatedPairs[index].value = value;
      updatedPairs[index].description = `${updatedPairs[index].criterion.field_name} ${updatedPairs[index].logic.logic} ${updatedPairs[index].value}`;
    }

    setCriteriaLogicPairs(updatedPairs);
    setEligibilityInfoData((prevData) => ({
      ...prevData,
      eligibility_criteria: criteriaLogicPairs.map(transformCriteriaForSave).filter((transformedCriteria) => transformedCriteria !== null),
    }));
  };

  const handleAddMore = () => {
    setCriteriaLogicPairs([...criteriaLogicPairs, { criterion: null, logic: null, value: "", is_saved: false, is_edit: false, description: "" }]);
  };

  const handleSaveCriteria = (index, is_edit) => {
    const updatedPairs = [...criteriaLogicPairs];
    if (is_edit) {
      updatedPairs[index].is_edit = false;
    }
    updatedPairs[index].is_saved = true;

    setCriteriaLogicPairs(updatedPairs);
  };

  const handleEditCriteria = async (index) => {
    setEditingIndex(index);
    const editingCriteriaPair = criteriaLogicPairs[index];

    const updatedPairs = criteriaLogicPairs.map((pair, i) => ({
      ...pair,
      is_edit: i === index,
    }));
    // updatedPairs[index].is_edit = true;

    console.log("editingCriteriaPair", editingCriteriaPair);
    console.log("updatedPairs", updatedPairs);

    // Pre-fill Parameter (Criterion)
    const selectedCriterion = criteria.find((criterion) => criterion.id === editingCriteriaPair.criterion.id);
    console.log("selected criteria", selectedCriterion, updatedPairs);

    // Fetch logicsGetter
    let logicsGetter = await logicsDataGetter(selectedCriterion.id);
    if (selectedCriterion.id === 2) {
      setHome_state(gender);
    }

    // Update criteriaLogicOptions with the new logicsGetter
    const updatedOptions = {
      ...criteriaLogicOptions,
      [selectedCriterion.id]: logicsGetter,
    };

    // Pre-fill Logic using the updated criteriaLogicOptions
    const selectedLogic = updatedOptions[selectedCriterion.id]?.find((logic) => logic.id === editingCriteriaPair.logic.id);
    console.log("selectedLogic", selectedLogic);

    if (selectedLogic) {
      updatedPairs[index].criterion = selectedCriterion;
      updatedPairs[index].logic = selectedLogic;

      // Pre-fill Value based on Logic Type
      if (selectedLogic.logic === "is_between") {
        updatedPairs[index].value = {
          min: editingCriteriaPair.value.min,
          max: editingCriteriaPair.value.max,
        };
      } else if (selectedLogic?.logic?.data_structure?.multiSelectJson) {
        console.log("editingCriteriaPair", editingCriteriaPair);
        updatedPairs[index].value = [
          {
            major: editingCriteriaPair.value.major,
            minor: editingCriteriaPair.value.minor,
          },
        ];
      } else if (selectedLogic.data_structure?.multiSelect) {
        // Assuming `editingCriteriaPair.value` is an array like ['West Bengal']
        updatedPairs[index].value = editingCriteriaPair.value;
      } else if (selectedLogic.data_structure?.singleSelect) {
        updatedPairs[index].value = editingCriteriaPair.value;
      } else {
        updatedPairs[index].value = editingCriteriaPair.value;
      }

      if (logicsGetter.length === 1 && logicsGetter[0].api_url !== null) {
        try {
          const logics_api_url = await logicsApiUrlDataGetter(logicsGetter[0].api_url, "home_state", selectedCriterion.id);
          console.log("logics", logics_api_url);

          const homeStateArray = Array.isArray(logics_api_url) ? logics_api_url.filter((item) => item !== null) : [logics_api_url];

          console.log("homeStateArray", homeStateArray);
          setHome_state(homeStateArray || []);
        } catch (error) {
          console.error("Error setting home_state:", error);
          setHome_state([]);
        }
      }
    } else {
      // Handle the case where selectedLogic is undefined
      console.error("Selected logic is undefined");
    }
    setCriteriaLogicPairs(updatedPairs);
    setCriteriaLogicOptions(updatedOptions); // Update criteriaLogicOptions outside the conditional block
    setEligibilityInfoData((prevData) => ({
      ...prevData,
      eligibility_criteria: updatedPairs.map(transformCriteriaForSave).filter((transformedCriteria) => transformedCriteria !== null),
    }));
  };

  const handleDelete = (event, indexToDelete) => () => {
    // event.stopPropagation();
    console.log("delete is clicked ", indexToDelete);
    setCriteriaLogicPairs((prevPairs) => {
      const updatedPairs = [...prevPairs];
      const updatedPair = { ...updatedPairs[indexToDelete] };
      updatedPair.value = updatedPair.value.filter((value, index) => index !== indexToDelete);
      updatedPairs[indexToDelete] = updatedPair;
      return updatedPairs;
    });
  };

  const handleDeleteCriteria = (index) => {
    const updatedPairs = criteriaLogicPairs.filter((pair, i) => i !== index);
    setCriteriaLogicPairs(updatedPairs);
    setEligibilityInfoData((prevData) => ({
      ...prevData,
      eligibility_criteria: updatedPairs,
    }));
  };

  useEffect(() => {
    console.log("Brother");
    const handleEffect = async () => {
      console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL);
      if (Cookies.get("token") === null || Cookies.get("token") === undefined) {
        navigate("/");
      }

      let criteria_getter = await criteriaDataGetter();
      setCriteria(criteria_getter);
      setCriteriaFromBackend(eligibilityInfoData.eligibility_criteria);
    };
    handleEffect();
  }, []);

  const criteriaDataGetter = async () => {
    try {
      const res = await service.get(`/po/ca/eligibility/criteria`);
      console.log("posting opportunity data response:", res.eligibilityCriterias);
      return res.eligibilityCriterias;
    } catch (err) {
      console.log(err);
    }
  };

  const logicsDataGetter = async (logic_id) => {
    try {
      const res = await service.get(`/po/ca/eligibility/logics?criteria_id=${logic_id}`);
      console.log("posting opportunity data response:", res.eligibilityLogics);
      return res.eligibilityLogics;
    } catch (err) {
      console.log(err);
    }
  };

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const logicsApiUrlDataGetter = async (api_url, column_name, criteria_id) => {
    try {
      const res = await service.get(`/${api_url}${api_url.includes("?") ? "&" : "?"}po_job_id=${props.postedJob.po_job_id ? props.postedJob.po_job_id : props.job_id}`);
      console.log("posting opportunity data response:", res.data);
      if (criteria_id === 18) {
        return {
          major: res.major,
          minor: res.minor,
        };
      }
      if (isJsonString(res.data) === true) {
        return JSON.parse(res.data);
      } else {
        return res.data;
      }
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  console.log("home state", home_state);
  console.log("eligibility information", eligibilityInfoData);
  const handleAndMajorAndMinor = (index, property, value) => {
    const updatedPairs = [...criteriaLogicPairs];
    const currentPair = updatedPairs[index];

    // Update the current pair's value to an array if it's not already an array
    if (!Array.isArray(currentPair.value)) {
      currentPair.value = [currentPair.value];
    }

    // Push the new major and minor object to the value array
    currentPair.value.push({ major: "", minor: "" });

    // Update the state with the updated pairs
    setCriteriaLogicPairs(updatedPairs);

    // Update eligibility_criteria
    setEligibilityInfoData((prevData) => ({
      ...prevData,
      eligibility_criteria: updatedPairs.map(transformCriteriaForSave).filter((transformedCriteria) => transformedCriteria !== null),
    }));
  };

  const getDreamRoleData = async (id) => {
    const logicData = await service.get(`/po/ca/eligibility/logics?criteria_id=${id}`);
    if (logicData.eligibilityLogics.length > 0) {
      let data = logicData.eligibilityLogics[0];
      setSelectedDreamRoles((prev) => {
        return {
          ...prev,
          api_url: null,
          logic_id: data.id,
          logic_name: data.logic,
          description: "",
          data_structure: {
            x: null,
            salary: "",
          },
        };
      });
    } else {
      setSelectedDreamRoles((prev) => {
        return {
          ...prev,
          api_url: null,
          logic_id: null,
          logic_name: null,
          description: "",
          data_structure: {
            x: null,
            salary: "",
          },
        };
      });
    }
  };

  const handleDreamRole = (e, key) => {
    if (key === "key") {
      setSelectedDreamRoles((prev) => {
        return {
          ...prev,
          description: e.target.value.field_name.replace("X", prev.data_structure.x).replaceAll("salary", prev.data_structure.salary),
          field_name: e.target.value.field_name,
          field_logic_id: e.target.value.id,
        };
      });
      getDreamRoleData(e.target.value.id);
    } else if (key === "x") {
      setSelectedDreamRoles((prev) => {
        return {
          ...prev,
          description: prev.description.replace("X", e.target.value).replaceAll("salary", prev.data_structure.salary),
          data_structure: {
            salary: prev.data_structure.salary,
            x: e.target.value,
          },
        };
      });
    } else if (key === "salary") {
      setSelectedDreamRoles((prev) => {
        return {
          ...prev,
          description: prev.description.replace("X", prev.data_structure.x).replaceAll("salary", e.target.value),
          data_structure: {
            x: prev.data_structure.x,
            salary: e.target.value,
          },
        };
      });
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", gap: "40px", overflowY: "auto", overflowX: "hidden", padding: "66px 79px" }}>
      <TextField sx={{ marginTop: ".5rem", background: Color.neutralLightGrey }} disabled className="textfield__opInfo" name="profile_designation" label="Profile (Designation)" required value={basicInfoData.designation}></TextField>
      <hr style={{ width: "100%" }} />
      <Box className="textfield__basicInfo">
        <Typography class="typographyOfHeader">
          Skills Required<span style={{ color: "red" }}>*</span>
        </Typography>
        <Autocomplete
          multiple
          placeholder="Type Skills..."
          options={[]}
          value={eligibilityInfoData.skills}
          freeSolo
          renderTags={(value, getTagProps) => value.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)}
          renderInput={(params) => <TextField {...params} />}
          getOptionLabel={(option) => option}
          isOptionEqualToValue={(option, value) => option === value}
          filterOptions={(options, params) => {
            const filtered = options.filter((option) => option.toLowerCase().includes(params.inputValue.toLowerCase()));

            // Add the input value as an option if it doesn't exist
            if (params.inputValue !== "" && !filtered.includes(params.inputValue)) {
              filtered.push(params.inputValue);
            }

            return filtered;
          }}
          onChange={(e, values) => {
            // Use the split function to separate values by comma

            const skills = values.map((value) => value.split(",")).flat();
            setEligibilityInfoData((prev) => {
              return {
                ...prev,
                skills: skills,
              };
            });
          }}
        />
      </Box>
      <Box className="textfield__basicInfo">
        <Box sx={{ ...DivCenter, justifyContent: "space-between" }}>
          <Typography class="typographyOfHeader" style={{ width: "auto" }}>
            Application Batches<span style={{ color: "red" }}>*</span>
          </Typography>
          <Box sx={{ ...DivCenter, gap: "8px" }}>
            <InfoOutlined />
          </Box>
        </Box>
        {BatchData.map((batchItem, index) => {
          if (batchItem.id === batch_id) {
            return (
              <Button
                disabled={true}
                sx={{
                  textTransform: "none",
                  marginRight: "1rem",
                  padding: "8px 16px",
                  background: Color.neutralLightGrey,
                  color: "black",
                  borderRadius: "22px",
                }}
                key={index}
              >
                {batchItem.user_group_name}
              </Button>
            );
          }
          return null;
        })}
      </Box>
      <Box className="textfield__basicInfo">
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography class="typographyOfHeader" style={{ width: "auto" }}>
            Eligibility Criteria
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox checked={isDreamRole} onChange={(e) => setIsDreamRole(e.target.checked)} />

            <Typography style={{ width: "auto" }}>Is this a dream Job?</Typography>
          </Box>
        </Box>
        {criteriaLogicPairs?.map(
          (savedInfo, index) =>
            savedInfo.is_saved && (
              <>
                <TableContainer component={Paper} key={index} gap="1rem">
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableBody sx={{ marginBottom: "1rem" }} className="customTableBody">
                      <TableRow
                        key={savedInfo.criterion.id}
                        sx={{
                          marginBottom: "1rem !important",
                          "&:last-child td, &:last-child th": { border: savedInfo.is_edit ? `2px solid ${Color.primary1}` : 0 },
                          background: savedInfo.is_edit ? "transparent" : Color.neutralLightGrey,
                          gap: "1rem",
                          boxShadow: savedInfo.is_edit ? `inset 0 0 1.5px 1.5px ${Color.primary1}` : "none",
                        }}
                        className="customTableBody"
                        style={{ gap: "1rem" }}
                      >
                        <TableCell component="th" align="left" width="100%">
                          {savedInfo.description.replace(/_/g, " ")}
                        </TableCell>
                        <TableCell align="right" sx={{ border: 0, outline: "none" }}>
                          <EditOutlinedIcon sx={{ cursor: "pointer", color: savedInfo.is_edit ? Color.primary1 : "black" }} onClick={() => handleEditCriteria(index)} />
                        </TableCell>
                        <TableCell align="right" sx={{ border: "none", outline: "none" }}>
                          <DeleteOutlineOutlinedIcon sx={{ cursor: "pointer", color: savedInfo.is_edit ? Color.primary1 : "black" }} onClick={() => handleDeleteCriteria(index)} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {index !== criteriaLogicPairs.length - 1 && ( // Add margin after every row except the last one
                  <Box sx={{ height: ".5rem", background: "transparent" }} />
                )}
              </>
            )
        )}
        {criteriaLogicPairs?.map(
          (pair, index) =>
            (pair.is_saved === false || pair.is_edit) && (
              <div key={index}>
                <div style={{ display: "flex", flexDirection: "row", gap: "1.5rem", marginTop: "1.5rem" }}>
                  <FormControl fullWidth sx={{ width: "50%" }}>
                    <InputLabel id={`criterion-label-${index}`}>Parameter</InputLabel>
                    <Select labelId={`criterion-label-${index}`} id={`criterion-select-${index}`} label="Parameter" onChange={(e) => handleChangeCriterion(index, e.target.value)} value={pair.criterion}>
                      {criteria.map((criteriaItem, criteriaIndex) => (
                        <MenuItem key={criteriaIndex} value={criteriaItem}>
                          {criteriaItem?.field_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ width: "50%" }}>
                    <InputLabel id={`logic-label-${index}`}>Logic</InputLabel>
                    <Select labelId={`logic-label-${index}`} id={`logic-select-${index}`} label="Logic" onChange={(e) => handleChangeLogic(index, e.target.value)} value={pair.logic}>
                      {criteriaLogicOptions[pair.criterion?.id]?.map((logicItem, logicIndex) => (
                        <MenuItem key={logicIndex} value={logicItem}>
                          {logicItem?.logic.replace(/_/g, " ")}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {pair?.logic && (
                  <div>
                    {pair?.logic?.logic === "is_between" && (
                      <div style={{ display: "flex", flexDirection: "row", gap: "1.5rem" }}>
                        <TextField sx={{ marginTop: "2.5rem" }} className="textfield__basicInfo" name={`value-min-${index}`} label="Minimum Value" required type="number" value={pair.value.min} onChange={(e) => handleChangeValue(index, "min", e.target.value)} />
                        <TextField sx={{ marginTop: "2.5rem" }} className="textfield__basicInfo" name={`value-max-${index}`} label="Maximum Value" required type="number" value={pair.value.max} onChange={(e) => handleChangeValue(index, "max", e.target.value)} />
                      </div>
                    )}
                    {pair?.logic?.data_structure?.multiSelect && (
                      <FormControl fullWidth sx={{ marginTop: "2.5rem" }}>
                        <InputLabel id={`value-label-${index}`}>Select Value(s)</InputLabel>
                        <Select
                          labelId={`value-label-${index}`}
                          id={`value-select-${index}`}
                          label="Select Value(s)"
                          multiple
                          onChange={(e) => handleChangeValue(index, "", e.target.value)}
                          value={pair.value || []}
                          renderValue={(selected) => (
                            <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                              {selected.map((value) => (
                                <Box key={value} sx={{ p: "10px", background: Color.neutralLightGrey, borderRadius: "20px" }}>
                                  {value}{" "}
                                  <Cancel
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDelete(e, index);
                                    }}
                                    sx={{ fontSize: "16px", color: Color.neutralMidGrey }}
                                  />
                                </Box>
                              ))}
                            </div>
                          )}
                          sx={{
                            "& .MuiSelect-outlined": {
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                            },
                            "& .MuiSelect-selectMenu": {
                              minHeight: "auto",
                            },
                            "& .MuiSelect-outlined.Mui-focused": {
                              border: "1px solid #1976d2",
                            },
                            "& .MuiSelect-outlined.Mui-focused.MuiSelect-select": {
                              backgroundColor: "#f5f5f5",
                            },
                          }}
                        >
                          {home_state.map((option, optionIndex) => (
                            <MenuItem key={optionIndex} value={option}>
                              <ListItemText primary={option} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    {pair?.logic?.data_structure?.singleSelect && (
                      <FormControl fullWidth sx={{ marginTop: "2.5rem" }}>
                        <InputLabel id={`value-label-${index}`}>Select Value(s)</InputLabel>
                        <Select
                          labelId={`value-label-${index}`}
                          id={`value-select-${index}`}
                          label="Select Value(s)"
                          onChange={(e) => handleChangeValue(index, "", e.target.value)}
                          value={pair.value || ""}
                          renderValue={(selected) => <Box sx={{ borderRadius: "20px" }}>{selected}</Box>}
                          sx={{
                            "& .MuiSelect-outlined": {
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                            },
                            "& .MuiSelect-selectMenu": {
                              minHeight: "auto",
                            },
                            "& .MuiSelect-outlined.Mui-focused": {
                              border: "1px solid #1976d2",
                            },
                            "& .MuiSelect-outlined.Mui-focused.MuiSelect-select": {
                              backgroundColor: "#f5f5f5",
                            },
                          }}
                        >
                          {home_state.map((option, optionIndex) => (
                            <MenuItem key={optionIndex} value={option}>
                              <ListItemText primary={option} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    {pair?.logic?.data_structure?.multiSelectJson && (
                      <>
                        {pair.value.map((majorMinor, innerIndex) => (
                          <div key={innerIndex} style={{ display: "flex", flexDirection: "row", gap: "1.5rem" }}>
                            <FormControl fullWidth sx={{ marginTop: "2.5rem" }}>
                              <InputLabel id={`value-label-${index}-${innerIndex}`}>Select Major</InputLabel>
                              <Select labelId={`value-label-${index}-${innerIndex}`} id={`value-select-${index}-${innerIndex}`} label="Select Major" onChange={(e) => handleChangeValue(index, "major", e.target.value)} value={majorMinor.major || ""}>
                                {home_state[0]?.major?.map((option, optionIndex) => (
                                  <MenuItem key={optionIndex} value={option}>
                                    <ListItemText primary={option} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ marginTop: "2.5rem" }}>
                              <InputLabel id={`value-label-${index}-${innerIndex}`}>Select Minor</InputLabel>
                              <Select labelId={`value-label-${index}-${innerIndex}`} id={`value-select-${index}-${innerIndex}`} label="Select Minor" onChange={(e) => handleChangeValue(index, "minor", e.target.value)} value={majorMinor.minor || ""}>
                                {home_state[0]?.minor?.map((option, optionIndex) => (
                                  <MenuItem key={optionIndex} value={option}>
                                    <ListItemText primary={option} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {innerIndex === pair.value.length - 1 && (
                              <button
                                className="submitBtn"
                                type="button"
                                style={{
                                  height: "44px",
                                  background: "transparent",
                                  border: "1px solid #38C1DF",
                                  color: "black",
                                  width: "300px",
                                  marginTop: "2.7rem",
                                }}
                                onClick={() => handleAndMajorAndMinor(index)}
                              >
                                <AddCircleOutlinedIcon />
                              </button>
                            )}
                          </div>
                        ))}
                      </>
                    )}

                    {pair?.logic?.logic !== "is_between" && !pair?.logic?.data_structure?.multiSelect && !pair?.logic?.data_structure?.singleSelect && !pair?.logic?.data_structure?.multiSelectJson && <TextField sx={{ marginTop: "2.5rem" }} className="textfield__basicInfo" name={`value-${index}`} label="Value" required type={(pair.logic.data_structure.min || pair.logic.data_structure.max) === "number" ? "number" : "text"} value={pair.value} onChange={(e) => handleChangeValue(index, "", e.target.value)} />}
                    <button
                      className="submitBtn"
                      type="button"
                      style={{
                        height: "44px",
                        background: "transparent",
                        border: "1px solid #38C1DF",
                        color: "black",
                        width: "300px",
                        marginTop: "1.5rem",
                      }}
                      onClick={() => handleSaveCriteria(index, pair.is_edit)}
                    >
                      {pair.is_edit ? "Update Criteria" : "Save Criteria"}
                    </button>
                  </div>
                )}
              </div>
            )
        )}
        <button
          className="submitBtn"
          type="button"
          style={{
            height: "44px",
            background: criteriaLogicPairs.some((pair) => pair.is_edit || !pair.is_saved) ? Color.neutralLightGrey : "transparent",
            border: criteriaLogicPairs.some((pair) => pair.is_edit || !pair.is_saved) ? `1px solid ${Color.neutralLightGrey}` : "1px solid #38C1DF",
            color: criteriaLogicPairs.some((pair) => pair.is_edit || !pair.is_saved) ? Color.neutralDarkGrey : "black",
            width: "300px",
            marginTop: "1.5rem",
          }}
          onClick={handleAddMore}
          disabled={criteriaLogicPairs.some((pair) => pair.is_edit || !pair.is_saved)}
        >
          + Add more criteria and logic
        </button>
      </Box>

      {console.log("ANIMESHHHHHHH", isDreamRole, selectedDreamRoles)}
      {isDreamRole && (
        <Box className="textfield__basicInfo">
          <FormControl fullWidth>
            <InputLabel id="dream-rule">Dream Rule</InputLabel>
            <Select labelId="dream-rule" id="dream-rule" value={allDreamRoles.find((item) => item.id === selectedDreamRoles?.field_logic_id) ?? ""} label="Dream Rule" onChange={(e) => handleDreamRole(e, "key")}>
              {allDreamRoles.map((item) => (
                <MenuItem value={item}>{item.field_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedDreamRoles?.logic_id !== null && (
            <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "1rem", gap: "1rem" }}>
              <TextField type="number" fullWidth value={selectedDreamRoles.data_structure.x} onChange={(e) => handleDreamRole(e, "x")} label="X" error={[null, undefined, ""].includes(formErrors?.X) ? false : true} helperText={formErrors?.X || ""} />
              <FormControl fullWidth>
                <InputLabel id="salary">Salary</InputLabel>
                <Select labelId="salary" id="salary" value={selectedDreamRoles.data_structure.salary} label="Salary" onChange={(e) => handleDreamRole(e, "salary")} error={[null, undefined, ""].includes(formErrors?.salary) ? false : true}>
                  {["CTC", "Fixed Component"].map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
                {![null, undefined, ""].includes(formErrors?.salary) && <p style={{ color: "red", fontSize: "12px" }}>{formErrors?.salary || ""}</p>}
              </FormControl>
            </Box>
          )}
        </Box>
      )}
      <Box className="textfield__basicInfo">
        <Typography class="typographyOfHeader">Additional Information</Typography>
        <Box sx={{ width: "100%", height: "100%" }}>
          <ReactQuill
            theme="snow"
            style={{ marginBottom: "20px", height: "100px" }}
            value={eligibilityInfoData.eligibility_description}
            onChange={(value) => setEligibilityInfoData((prevData) => ({ ...prevData, eligibility_description: value }))}
            modules={{
              clipboard: {
                matchVisual: false,
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default POEligibility;
