import React, { useEffect, useState } from 'react'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar'
import { useLocation, useNavigate } from 'react-router-dom';
import { Color } from '../../../GlobalStyles';
import CCService from '../../../services/httpServiceWithoutSuperAdmin';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

function VerifyResumes() {
    const navigate = useNavigate()
    const location = useLocation();
    let college_name = location.state?.college_name || ""
    let user_group_id = location.state?.user_group_id;
    const service = new CCService();
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState("")
    const [reachedThreshold, setReachedTreshold] = useState(false)
    const [student_data, setStudent_data] = useState([])
    const tableTitles = ["Student Details", "Roll No", "Bullets Verified", "Bullets Remaining", "Status"];
    const [options, setOptions] = useState([
        { label: 'Verified', value: 0, isSelected: false },
        { label: 'Not Verified', value: 1, isSelected: false },
        { label: 'Partially Verified', value: 2, isSelected: false },
    ]);
    const [page, setPage] = useState(1)

    const StudentResumeDataGet = async (isVerified, page) => {
        console.log({ search })
        try {
            let filter = isVerified === 0 ? `&verifiedOnly=1` : isVerified === 1 ? `&unVerifiedOnly=1` : isVerified === 2 ? `&partiallyVerifiedOnly=1` : ``;
            let searchQuery = !['', undefined].includes(search) ? `&searchQuery=${search}` : ``
            console.log({ filter })
            const res = service.get(`/user/resumeBulletCount?user_group_id=${user_group_id}&size=10&page=${page}${filter}${searchQuery}`)
            console.log("StudentResumeDataGet", res)
            return res
        } catch (err) {
            console.log(err)
        }
    }

    const handleEffect = async (isVerified, page) => {
        setLoading(true)
        const students = [0, 1, 2].includes(isVerified) ? await StudentResumeDataGet(isVerified, page) : await StudentResumeDataGet(null, page);
        if (students.userResumeMapping.length === 0) {
            setLoading(false)
            setReachedTreshold(true)
            return
        }
        setStudent_data([...student_data, ...students.userResumeMapping])
        setLoading(false)
        console.log({ students })
    }

    const handlefilter = async (isVerified, page) => {
        const students = [0, 1, 2].includes(isVerified) ? await StudentResumeDataGet(isVerified, page) : await StudentResumeDataGet(null, page);
        setStudent_data(students.userResumeMapping)
        console.log({ students })
    }
    useEffect(() => {
        if (reachedThreshold === false)
            handleEffect(options.find((val) => val.isSelected == true)?.value, page)
    }, [page])

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (reachedThreshold === false) {
                setReachedTreshold(false)
                handlefilter(options.find((val) => val.isSelected == true)?.value, page)
            }
        }, 2000)
        return () => clearTimeout(timeout)
    }, [search])
    const handelInfiniteScroll = async () => {
        // console.log("scrollHeight" + document.documentElement.scrollHeight);
        // console.log("innerHeight" + window.innerHeight);
        // console.log("scrollTop" + document.documentElement.scrollTop);
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight && !loading
            ) {
                if (reachedThreshold === false) {
                    console.log("ENDEDN")
                    setLoading(true);
                    setPage((prev) => prev + 1);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handelInfiniteScroll);
        return () => window.removeEventListener("scroll", handelInfiniteScroll);
    }, []);

    const redirectToResumeVerification = (resume_id, user_id) => {
        navigate(`./Verify?resume_id=${resume_id}&user_id=${user_id}`, {
            state: {
                supported_doc_required: location.state.supported_doc_required
            }
        })
    }
    return (
        <div>
            <SideNavigationBar />
            <div className='student-details-parent' style={{ marginTop: "2rem" }}>
                <div className="topHeadings" style={{ display: "flex", justifyContent: "space-between", marginRight: '12rem' }} >
                    <div style={{ display: "flex" }}>
                        <p style={{ color: Color.neutralMidGrey }} onClick={() => navigate("/CollegeInfoPage")}>{college_name}</p>
                        <p style={{ marginLeft: '1rem', marginRight: '1rem' }}>{" "} {">"}{" "} </p>
                        <p>Verify Resumes</p>
                    </div>
                </div>
                <div style={{ display: "flex", marginTop: "2rem" }}>
                    <div className='searchTab' style={{ alignItems: 'center', width: "100%", height: "30px", marginBottom: ".5rem" }}>
                        <input style={{ border: "none", height: "30px", width: "100%" }}
                            className='searchingText searchTab'
                            type='text'
                            // onKeyPress={(e) => {
                            //     setSearch(e.target.value)
                            // }}
                            onChange={(e) => {
                                setSearch(e.target.value)
                            }}
                            placeholder='Search for name, roll number, etc'
                        />
                    </div>
                    <DownloadIcon style={{ color: Color.primary1, marginLeft: ".5rem", cursor: "pointer" }} onClick={() => console.log("Download")} />
                </div>
                <div>
                    {options.map((option, index) => (
                        <button
                            key={index}
                            className={option.isSelected === true ? "optionsFocusButton" : "Optionsbutton"}
                            style={option.isSelected === true ? { backgroundColor: Color.rdprimary.concat("40"), borderColor: Color.rdprimary.concat("40") } : { borderColor: Color.rdprimary }}
                            onClick={() => {
                                setOptions(options.map(val => ({
                                    ...val,
                                    isSelected: option.value === val.value ? !option.isSelected : false
                                })))
                                if (option.isSelected === false) {
                                    setReachedTreshold(false)
                                    handlefilter(option.value, 1)
                                }
                                else {
                                    setPage(1)
                                    handleEffect(null, 1)
                                }

                            }}
                        >
                            {option.label}
                        </button>
                    ))}
                </div>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {tableTitles.map((title, index) => (
                                    <TableCell align='center'
                                        key={index} sx={{ fontSize: 16, color: Color.neutralMidGrey }}
                                    >
                                        {title}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {student_data?.map((details, index) => {
                                return (
                                    <>
                                        <TableRow>
                                            <TableCell align='center' sx={{ fontSize: 16, color: Color.neutralMidGrey }}>
                                                <p style={{ color: "black", marginBottom: "0px" }}>{[details.first_name, " ", details?.last_name]} </p>
                                                {details.email_id}<br />
                                                {details.ph_no}
                                            </TableCell>
                                            <TableCell align='center' sx={{ fontSize: 16 }}>
                                                {details.roll_no}
                                            </TableCell>
                                            <TableCell align='center' sx={{ fontSize: 16 }}>
                                                {details.verified_count}
                                            </TableCell>
                                            <TableCell align='center' sx={{ fontSize: 16 }}>
                                                {(details.total_count - details.verified_count)}
                                            </TableCell>
                                            <TableCell align='center' sx={{ fontSize: 16 }}>
                                                {details.total_count === details.verified_count ? <button
                                                    className="submitRdBtn"
                                                    type="button" style={{ color: Color.green, backgroundColor: "transparent", borderColor: "transparent" }}
                                                    onClick={() => redirectToResumeVerification(details.resume_id, details.user_id)}
                                                >Verified</button> : details.verified_count === 0 ? <button
                                                    className="submitRdBtn"
                                                    type="button"
                                                    style={{ height: "auto", bottom: 0, width: "auto", paddingLeft: "3rem", fontSize: 14, paddingRight: '3rem', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
                                                    onClick={() => redirectToResumeVerification(details.resume_id, details.user_id)}
                                                >Start Verification</button> : <button
                                                    className="submitRdBtn"
                                                    type="button"
                                                    style={{ height: "auto", bottom: 0, width: "auto", paddingLeft: "3rem", fontSize: 14, paddingRight: '3rem', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
                                                    onClick={() => redirectToResumeVerification(details.resume_id, details.user_id)}
                                                >Continue</button>}



                                            </TableCell>
                                        </TableRow>
                                    </>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {loading && !reachedThreshold && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>}
                <div style={{ height: "200px" }}></div>
            </div>
        </div>
    )
}

export default VerifyResumes