import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BatchTrackerSkeleton } from "../../reusables/SkeletonLoader/SkeletonLoader";
import { Star, StarBorder, StarHalf } from "@mui/icons-material";
import Chart from "react-apexcharts";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import DoneIcon from "@mui/icons-material/Done";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";
import Cookies from "js-cookie";
import SideNavigationBar from "../../reusables/SideNavigationBar/SideNavigationBar";
import { get_specialCharReplace } from "../../../services/specialCharReplace";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const parseData = (item) => {
  return {
    order_id: [null, "null", undefined].includes(item?.order_id) ? null : item.order_id,
    duration: item?.duration ? item.duration : "",
    spe_name: item?.spe_name ? item.spe_name : "",
    role_name: item?.role_name ? item.role_name : "",
    process_id: item?.process_id ? item.process_id : null,
    fields_feedback: [null, "null", undefined].includes(item?.fields_feedback) ? { responses: [] } : JSON.parse(item.fields_feedback),
    mcq_feedback: [null, "null", undefined].includes(item?.mcq_feedback) ? { responses: [] } : JSON.parse(item.mcq_feedback),
    communication: item?.communication === 1 ? 1 : 0,
    hired: item?.hireable,
    punctuality: item?.student_late_time ? item.student_late_time : null,
  };
};

function DiagnosticInterview_1(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  console.log("location", location);
  const {studentReportDownload} = props

  const user_id = queryParams.get("user_id") ? queryParams.get("user_id") : props.user_id;
  const process_id = queryParams.get("process_id") ? queryParams.get("process_id") : props.process_id;
  const user_group_id = queryParams.get("user_group_id") ? queryParams.get("user_group_id") : props.user_group_id;
  const sku_name = location.state.sku_name || props.sku_name;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [rating, setRating] = useState(0);
  const [RAG, setRAG] = useState("R");
  const [duration, setDuration] = useState("");
  const [spe_name, setSpe_name] = useState("");
  const [role_name, setRole_name] = useState("");
  const services = new CCService();
  const [descriptionText, setDescriptionText] = useState("");
  const [JDData, setJDData] = useState({
    order_id: null,
    process_id: null,
    fields_feedback: { responses: [] },
    mcq_feedback: { responses: [] },
    communication: null,
    hired: null,
  });
  const [whereYouStand, setWhereYouStand] = useState({
    workExp: 0,
    entireBatch: 0,
    tech: 0,
  });
  const [chartOptions, setChartOptions] = useState({
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
      },
    },
    stroke: {
      colors: ["#F44336"],
    },
    dataLabels: {
      enabled: false,
    },
    grid: { show: false },
    colors: "#41B8D5",
    chart: { width: "200px" },
    xaxis: {
      axisBorder: { show: false },
      categories: ["Introduction", "Work Experience", "Practical Work Done", "JD Preparedness"],
    },
    yaxis: {
      max: 5,
      min: 0,
      labels: {
        show: false,
        style: {
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
        },
      },
    },
  });
  const [series, setSeries] = useState([
    {
      data: [0, 0, 0, 0],
    },
  ]);

  const [feedback, setFeedback] = useState([]);

  const userInterviewParser = (data) => {
    let work_exp = [undefined, null].includes(data) ? 0 : data.exp_percentile;
    let entireBatch = [undefined, null].includes(data) ? 0 : data.batch_precentile;
    let tech = [undefined, null].includes(data) ? 0 : data.over_all_percentile;
    let RAG = [undefined, null].includes(data) ? "R" : data.RAG;
    let duration = [undefined, null].includes(data) ? "" : data.duration;
    let spe_name = [undefined, null].includes(data) ? "" : data.spe_name;
    let role_name = [undefined, null].includes(data) ? "" : data.role_name;
    let fresher = [undefined, null].includes(data) ? null : data["Work Experience"];
    let parsedData = parseData(data);
    console.log("letst", parsedData);
    setJDData(parsedData);
    let temp_series = [];
    let temp_label = [];

    parsedData.fields_feedback.responses.map((item) => {
      temp_series.push(parseFloat(item.rating));
      temp_label.push(item.field_name);
    });

    setSeries([{ data: temp_series }]);
    setFeedback(parsedData.fields_feedback.responses);
    setChartOptions((prev) => {
      return {
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        stroke: {
          colors: ["#F44336"],
        },
        dataLabels: {
          enabled: false,
        },
        grid: { show: false },
        colors: "#41B8D5",
        chart: { width: "200px" },
        xaxis: {
          axisBorder: { show: false },
          categories: temp_label,
        },
        yaxis: {
          max: 5,
          min: 0,
          labels: {
            show: true,
            style: {
              fontSize: "14px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
            },
          },
        },
      };
    });

    setRating(temp_series.reduce((acc, val) => acc + val, 0) / temp_series.length);
    console.log("fresherValue", fresher);
    setWhereYouStand({ workExp: work_exp, entireBatch: entireBatch, tech: tech, experience: fresher === null ? null : 1 });
    setRAG(RAG);
    setRole_name(role_name);
    setSpe_name(spe_name);
    setDuration(duration);
  };

  const studentAnalysisContent = (data) => {
    let text = "";
    console.log("Data", data);
    let interViewData = data.userInterviewData[0];
    let analysisData = data.student_analysis_content;

    if (interViewData?.student_late_time > 120) {
      let data = analysisData.filter((item) => item.id === 1)[0];
      let content = data.content;

      content = content.replace("[student joinTime - mentor startTime]", Math.round(interViewData?.student_late_time / 60));
      text = text + " " + content;
    } else {
      let data = analysisData?.filter((item) => item.id === 2)[0];
      text = text + " " + data.content;
    }
    if (interViewData?.hireable === 1) {
      let data = analysisData?.filter((item) => item.id === 3)[0];
      text = text + " " + data.content;
    } else {
      let data = analysisData?.filter((item) => item.id === 4)[0];
      text = text + " " + data.content;
    }

    if (interViewData?.communication === 1) {
      let data = analysisData.filter((item) => item.id === 6)[0];
      text = text + " " + data.content;
    } else {
      let data = analysisData.filter((item) => item.id === 5)[0];
      text = text + " " + data.content;
    }

    setDescriptionText(text);
  };

  useEffect(() => {
    const getFeedbackData = async () => {
      setLoading(true);
      if (location.pathname === "/DiagnosticInterview") {
        window.scroll(0, 0);
      }

      const config = {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      };

      const userInterviewData = await axios.get(`${process.env.REACT_APP_API_URL}/analytics/user/userInterviewData?user_id=${user_id}&process_id=${process_id}`, config);
      console.log("UserInterviewData", userInterviewData);
      //FIXME: particular Studennt

      userInterviewParser(userInterviewData.data.data.userInterviewData[0]);
      studentAnalysisContent(userInterviewData.data.data);

      setLoading(false);
    };

    getFeedbackData();
  }, []);
  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginBlock: "2rem 5rem" }}>
      {queryParams.get("process_id") && <SideNavigationBar />}
      {location.pathname === "/DiagnosticInterview" && <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem", marginBottom: ".5rem" }} onClick={() => navigate(-1)} />}
      <Box className="main-container" style={{ alignSelf: "center", width: queryParams.get("process_id") ? "80%" : "100%", marginTop: "2rem" }}>
        {loading === true ? (
          <BatchTrackerSkeleton />
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "1rem" }}>
              <Box sx={{ height: "2px", width: "80%", background: "#E88F09" }}></Box>
              <Typography variant="h1" sx={{ color: "#E88F09", textAlign: "center", fontWeight: "600", width: "800px", letterSpacing: "2.5px" }}>
                {sku_name}
              </Typography>
              <Box sx={{ height: "2px", width: "80%", background: "#E88F09" }}></Box>
            </Box>
            <Box sx={{ border: "1px solid rgba(232,143,9,0.3)", borderRadius: "30px", padding: "1.5rem", display: "flex", gap: "1rem" }}>
              <Box sx={{ width: "80%" }}>
                <Typography sx={{ marginBottom: "0.5rem", lineHeight: "12px", fontSize: "20px" }}>{sku_name}</Typography>
                <Typography sx={{ color: "#7F7F7F", fontSize: "12px" }}>
                  {duration} | {role_name}
                </Typography>

                <Box sx={{ display: "flex", gap: "3rem", justifyContent: "space-between", alignItems: "center" }}>
                  <Box width={studentReportDownload ? "50%" : "70%"}>
                    <Chart options={chartOptions} series={series} type="bar" width={"100%"} height={series[0].data.length * 50} />
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", width: "20%" }}>
                    <Typography variant="h5" sx={{ marginBottom: "5px", color: "#8A8A8A" }}>
                      Punctuality?
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "20px" }}>
                      <Box sx={{ width: "20px", height: "20px", border: "3px solid", borderColor: JDData?.punctuality && JDData?.punctuality < 120 ? "#46C3E0" : "#E6E6E6", borderRadius: "5px" }}>{JDData?.punctuality && JDData?.punctuality < 120 ? <DoneIcon sx={{ fontSize: "35px", color: "#46C3E0", position: "relative", bottom: "12px", right: "5px" }} /> : <></>}</Box>
                      <Typography variant="h4" sx={{ color: JDData?.punctuality < 120 ? "#46C3E0" : "#E6E6E6", fontWeight: "700" }}>
                        {JDData?.punctuality < 120 ? "On Time" : "Late"}
                      </Typography>
                    </Box>
                    <Typography variant="h5" sx={{ marginBottom: "5px", color: "#8A8A8A" }}>
                      Were you hired?{" "}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "20px" }}>
                      <Box sx={{ width: "20px", height: "20px", border: "3px solid", borderColor: JDData?.hired && JDData?.hired === 1 ? "#46C3E0" : "#E6E6E6", borderRadius: "5px" }}>{JDData?.hired && JDData?.hired === 1 ? <DoneIcon sx={{ fontSize: "35px", color: "#46C3E0", position: "relative", bottom: "12px", right: "5px" }} /> : <></>}</Box>
                      {}
                      <Typography variant="h4" style={{ color: JDData?.hired && JDData?.hired === 1 ? "#46C3E0" : "#E6E6E6", fontWeight: "700" }}>
                        {JDData?.hired === 1 ? "Yes" : "No"}
                      </Typography>
                    </Box>
                    <Typography variant="h5" sx={{ marginBottom: "5px", color: "#8A8A8A" }}>
                      Communication Skills?
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "20px" }}>
                      <Box sx={{ width: "20px", height: "20px", border: "3px solid", borderColor: JDData?.communication && JDData?.communication === 1 ? "#46C3E0" : "#E6E6E6", borderRadius: "5px" }}>{JDData?.communication && JDData.communication === 1 ? <DoneIcon sx={{ fontSize: "35px", color: "#46C3E0", position: "relative", bottom: "12px", right: "5px" }} /> : <></>}</Box>
                      <Typography variant="h4" style={{ color: JDData?.communication && JDData?.communication === 1 ? "#46C3E0" : "#E6E6E6", fontWeight: "700" }}>
                        {JDData?.communication === 1 ? "Perfect" : "Need Work"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ width: "20%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
                <Box>
                  <Typography variant="h5" sx={{ textAlign: "center" }}>
                    Overall Score
                  </Typography>
                  <Box>
                    {[1, 2, 3, 4, 5].map((item) => {
                      if (item <= rating) {
                        return <Star sx={{ fontSize: "30px", color: "#46C3E0" }} />;
                      } else if (item === Math.ceil(rating) && rating % 1 !== 0) {
                        return <StarHalf sx={{ fontSize: "30px", color: "#46C3E0" }} />;
                      } else {
                        return <StarBorder sx={{ fontSize: "30px", color: "#46C3E0" }} />;
                      }
                    })}
                  </Box>
                  <Typography variant="h4" sx={{ color: "#46C3E0", fontWeight: "600", textAlign: "center" }}>
                    {rating.toFixed(1)}/5
                  </Typography>
                </Box>
                <Typography sx={{ fontSize: "10px", textAlign: "justify", marginBottom: "1rem" }}>{descriptionText}</Typography>
              </Box>
            </Box>

            <Box sx={{ border: "1px solid rgba(232,143,9,0.3)", borderRadius: "30px", padding: "1.5rem", display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Typography sx={{ marginBottom: "3px", lineHeight: "12px", fontSize: "14px" }}>Where do you stand?</Typography>

              <Box sx={{ display: "flex", justifyContent: "center", gap: "8rem", paddingInline: "10%" }}>
                <Box sx={{ width: "100%" }}>
                  {whereYouStand.experience === null ? (
                    <Typography style={{ color: "#8A8A8A", textAlign: "center", fontSize: "9px", lineHeight: "12px", marginBottom: "5px" }}>
                      Percentile among <br /> <span style={{ fontWeight: 700 }}>Freshers</span>
                    </Typography>
                  ) : (
                    <Typography style={{ color: "#8A8A8A", textAlign: "center", fontSize: "9px", lineHeight: "12px", marginBottom: "5px" }}>
                      Percentile among students <br /> who have<span style={{ fontWeight: 700 }}> Work Experience</span>
                    </Typography>
                  )}
                  <LinearProgress
                    variant="determinate"
                    value={whereYouStand.workExp}
                    sx={{
                      height: studentReportDownload ? 18 : 25,
                      "&.MuiLinearProgress-colorPrimary": {
                        backgroundColor: "#E8E8E8",
                      },
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#41B8D5",
                      },
                    }}
                  />
                  <Typography variant="h1" style={{ color: "#41B8D5", fontWeight: "bold", textAlign: "center" }}>
                    {whereYouStand.workExp.toFixed(2)}%ile
                  </Typography>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography style={{ color: "#8A8A8A", textAlign: "center", fontSize: "9px", lineHeight: "12px", marginBottom: "5px" }}>
                    Percentile among the <br />
                    <span style={{ fontWeight: 700 }}> Entire Batch</span>
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={whereYouStand.entireBatch}
                    sx={{
                      height: studentReportDownload ? 18 : 25,
                      "&.MuiLinearProgress-colorPrimary": {
                        backgroundColor: "#E8E8E8",
                      },
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#E88F09",
                      },
                    }}
                  />
                  <Typography variant="h1" style={{ color: "#E88F09", fontWeight: "bold", textAlign: "center" }}>
                    {whereYouStand.entireBatch.toFixed(2)}%ile
                  </Typography>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography style={{ color: "#8A8A8A", textAlign: "center", fontSize: "9px", lineHeight: "12px", marginBottom: "5px" }}>
                    Percentile among students <br /> who selected <span style={{ fontWeight: 700 }}>{spe_name}</span>
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={whereYouStand.tech}
                    sx={{
                      height: studentReportDownload ? 18 : 25,
                      "&.MuiLinearProgress-colorPrimary": {
                        backgroundColor: "#E8E8E8",
                      },
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#41B8D5",
                      },
                    }}
                  />
                  <Typography variant="h1" style={{ color: "#41B8D5", fontWeight: "bold", textAlign: "center" }}>
                    {whereYouStand.tech.toFixed(2)}%ile
                  </Typography>
                </Box>
              </Box>
              <Typography variant="h5" sx={{ paddingInline: "3rem" }}>
                A percentile is like a ranking that shows where a student's performance stands compared to their peers. For example, if a student is in the 80th percentile, it means they did better than 80% of the batch. It's a way to see how well they're doing or where do they stand compared to others.
              </Typography>
            </Box>

            <Box sx={{ border: "1px solid rgba(232,143,9,0.3)", borderRadius: "30px", padding: "1.5rem", display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Box>
                <Typography sx={{ lineHeight: "24px", marginBottom: "3px", fontSize: "14px" }}>RAG Report (Red-Amber-Green)</Typography>
                <Typography sx={{ fontSize: "10px" }}>
                  <i>As per the RAG analysis, this student falls under the Amber category. Red segment of students have to work really hard on overall preparedness, Amber segment of students are just around the corner and are advised to go through the mentor feedback thoroughly, and Green segment of students are on the right track.</i>
                </Typography>
              </Box>
              <Box sx={{ paddingInline: "8rem" }}>
                {console.log("RAGVALUE", RAG)}
                <Box sx={{ background: "#F6F6F6", padding: "1rem 2rem", display: "flex", justifyContent: studentReportDownload ? "space-around" : "space-between", borderRadius: "10px" }}>
                  <Box sx={{ width: studentReportDownload ? "40%" : "100%", display: "flex", gap: studentReportDownload ? "10px" : "20px", alignItems: "center" }}>
                    <Box sx={{ border: "5px solid", borderColor: RAG === "R" ? "#FF3131" : "#E8E8E8", borderRadius: "50%", width: "50px", height: "50px", background: RAG === "R" ? "radial-gradient( #F2E2E2,#FF3131)" : "#F6F6F6" }}></Box>
                    <Typography variant="h1" sx={{ fontWeight: "600", color: RAG === "R" ? "#FF3131" : "#E8E8E8" }}>
                      Red
                    </Typography>
                  </Box>
                  <Box sx={{ width: studentReportDownload ? "40%" : "100%", display: "flex", gap: studentReportDownload ? "10px" : "20px", alignItems: "center", justifyContent: "center" }}>
                    <Box sx={{ border: "5px solid", borderColor: RAG === "A" ? "#E88F09" : "#E8E8E8", borderRadius: "50%", width: "50px", height: "50px", background: RAG === "A" ? "radial-gradient( #F2E2E2,#E88F09)" : "#F6F6F6" }}></Box>
                    <Typography variant="h1" sx={{ fontWeight: "600", color: RAG === "A" ? "#E88F09" : "#E8E8E8" }}>
                      Amber
                    </Typography>
                  </Box>{" "}
                  <Box sx={{ width: studentReportDownload ? "40%" : "100%", display: "flex", gap: studentReportDownload ? "10px" : "20px", alignItems: "center", justifyContent: "flex-end" }}>
                    <Box sx={{ border: "5px solid", borderColor: RAG === "G" ? "#00BF63" : "#E8E8E8", borderRadius: "50%", width: "50px", height: "50px", background: RAG === "G" ? "radial-gradient( #F2E2E2,#00BF63)" : "#F6F6F6" }}></Box>
                    <Typography variant="h1" sx={{ fontWeight: "600", color: RAG === "G" ? "#00BF63" : "#E8E8E8" }}>
                      Green
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Typography variant="h6">
                <span style={{ color: "red" }}>NOTE: </span>Assigning Red, Amber or Green flag to a student is strictly based on the analysis data of mentor reviews. It may not prove true caliber, ability and competence of a student or batch. It must be processed in the same context of an activity and not on the basis of any other assumptions whatsoever. CareerCarve holds no responsibility for the actions taken by any party holding access privileges to these reports.
              </Typography>
            </Box>

            <Box sx={{ border: "1px solid rgba(232,143,9,0.3)", borderRadius: "30px", padding: "1.5rem", display: "flex", gap: "1rem", flexDirection: "column" }}>
              <Typography sx={{ lineHeight: "24px", fontSize: "14px" }}>Detailed Feedback from the Mentor </Typography>
              {/* <Grid container columnSpacing={10} sx={{ padding: "30px" }}>
                {feedback.length > 0 &&
                  feedback.map((item, index) => (
                    <Grid item xs={12} sm={12} md={6}>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "20px 10px", borderTop: [0, 1].includes(index) ? "" : "1px solid #41B8D5" }}>
                        <Typography sx={{ fontSize: "16px", color: "#41B8D5" }}>{item.field_name}</Typography>
                        <Typography sx={{ fontSize: "14px" }}>{item.description}</Typography>
                      </Box>
                    </Grid>
                  ))}

                <Grid item xs={12} sm={12} md={6}>
                  {JDData.hired && (
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "20px 10px", borderTop: "1px solid #41B8D5" }}>
                      <Typography sx={{ fontSize: "16px", color: "#41B8D5" }}>{JDData.hired[0].question}</Typography>
                      <Typography sx={{ fontSize: "14px" }}>{JDData.hired[0].ans}</Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {JDData.communication && (
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "20px 10px", borderTop: "1px solid #41B8D5" }}>
                      <Typography sx={{ fontSize: "16px", color: "#41B8D5" }}>{JDData.communication[0].question}</Typography>
                      <Typography sx={{ fontSize: "14px" }}>{JDData.communication[0].ans}</Typography>
                    </Box>
                  )}
                </Grid>
              </Grid> */}
              <Box sx={{ display: "flex", gap: "2rem" }}>
                {/* odd */}
                <Box sx={{ width: "100%" }}>
                  {feedback.length > 0 ? (
                    feedback.map((item, index) => {
                      if (index % 2 === 0) {
                        return (
                          <div>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "20px 10px", borderTop: [0, 1].includes(index) ? "" : "1px solid rgba(65,184,213,0.3)" }}>
                              <Typography sx={{ fontSize: "12px", color: "#41B8D5" }}>{item.field_name}</Typography>
                              <Typography sx={{ fontSize: "12px" }}>
                                {get_specialCharReplace(item.description)
                                  ?.split("\n")
                                  .map((line, index) => (
                                    <React.Fragment key={index}>
                                      {line}
                                      <br />
                                    </React.Fragment>
                                  ))}
                              </Typography>
                            </Box>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <Typography variant="h3" color={{ color: "#929292" }}>
                      No Data Available
                    </Typography>
                  )}
                  {JDData.communication && (
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "20px 10px", borderTop: "1px solid rgba(65,184,213,0.3)" }}>
                      <Typography sx={{ fontSize: "12px", color: "#41B8D5" }}>Does the student have a communication problem?</Typography>
                      <Typography sx={{ fontSize: "12px" }}>{JDData.communication === 0 ? "Yes" : "No"}</Typography>
                    </Box>
                  )}
                </Box>

                {/* even */}
                <Box sx={{ width: "100%" }}>
                  {feedback.length > 0 &&
                    feedback.map((item, index) => {
                      if (index % 2 === 1) {
                        return (
                          <div>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "20px 10px", borderTop: [0, 1].includes(index) ? "" : "1px solid rgba(65,184,213,0.3)" }}>
                              <Typography sx={{ fontSize: "12px", color: "#41B8D5" }}>{item.field_name}</Typography>
                              <Typography sx={{ fontSize: "12px" }}>
                                {get_specialCharReplace(item.description)
                                  ?.split("\n")
                                  .map((line, index) => (
                                    <React.Fragment key={index}>
                                      {line}
                                      <br />
                                    </React.Fragment>
                                  ))}
                              </Typography>
                            </Box>
                          </div>
                        );
                      }
                    })}

                  {
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "20px 10px", borderTop: "1px solid rgba(65,184,213,0.3)" }}>
                      <Typography sx={{ fontSize: "12px", color: "#41B8D5" }}>Would you hire the student for this JD?</Typography>
                      <Typography sx={{ fontSize: "12px" }}>{JDData.hired === 1 ? "Yes" : "No"}</Typography>
                    </Box>
                  }
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default DiagnosticInterview_1;
